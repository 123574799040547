<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem?.id">
	<app-blade-header [title]="'Knapp'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content *ngIf="languages" [menuItems]="menuItems" (indexChange)="selectedIndex = $event; menuChange($event)">
		<mat-card>
			<app-element-blade-generally *ngIf="selectedIndex === 0 && element" [element]="element" [saving]="saving" [menuItems]="menuItems"></app-element-blade-generally>

			<form [formGroup]="itemForm">
				<div *ngIf="activeItem && selectedIndex !== 99 && selectedIndex > 0">
					<mat-form-field class="w-100">
						<mat-label>Knapp typ</mat-label>
						<mat-select formControlName="buttonType">
							<mat-option *ngFor="let buttonType of buttonTypes" [value]="buttonType.value">
								{{buttonType.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field class="w-100">
						<mat-label>Färg</mat-label>
						<mat-select formControlName="color">
							<mat-option *ngFor="let color of colors" [value]="color.value">
								{{color.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field class="w-100">
						<input matInput formControlName="text" placeholder="Text i knappen">
					</mat-form-field>
					<mat-form-field class="w-100">
						<input matInput formControlName="icon" placeholder="Namn på ikon">
					</mat-form-field>
					<mat-form-field class="w-100">
						<input matInput formControlName="link" placeholder="Länk">
					</mat-form-field>

					<div style="background-color: #fafafa;" class="p-2">
						Exempel:
						<ng-container [ngSwitch]="itemForm.get('buttonType')?.value">
							<button *ngSwitchDefault mat-button [color]="itemForm.get('color')?.value">
								{{itemForm.get('text')?.value}}
								<mat-icon *ngIf="itemForm.get('icon')?.value">{{itemForm.get('icon')?.value}}</mat-icon>
							</button>
							<button *ngSwitchCase="'basic'" mat-button [color]="itemForm.get('color')?.value">
								{{itemForm.get('text')?.value}}
								<mat-icon *ngIf="itemForm.get('icon')?.value">{{itemForm.get('icon')?.value}}</mat-icon>
							</button>

							<button *ngSwitchCase="'raised'" mat-raised-button [color]="itemForm.get('color')?.value">
								{{itemForm.get('text')?.value}}
								<mat-icon *ngIf="itemForm.get('icon')?.value">{{itemForm.get('icon')?.value}}</mat-icon>
							</button>
							<button *ngSwitchCase="'stroked'" mat-stroked-button [color]="itemForm.get('color')?.value">
								{{itemForm.get('text')?.value}}
								<mat-icon *ngIf="itemForm.get('icon')?.value">{{itemForm.get('icon')?.value}}</mat-icon>
							</button>
							<button *ngSwitchCase="'flat'" mat-flat-button [color]="itemForm.get('color')?.value">
								{{itemForm.get('text')?.value}}
								<mat-icon *ngIf="itemForm.get('icon')?.value">{{itemForm.get('icon')?.value}}</mat-icon>
							</button>
							<button *ngSwitchCase="'icon'" mat-icon-button [color]="itemForm.get('color')?.value">
								{{itemForm.get('text')?.value}}
								<mat-icon *ngIf="itemForm.get('icon')?.value">{{itemForm.get('icon')?.value}}</mat-icon>
							</button>
							<button *ngSwitchCase="'fab'" mat-fab [color]="itemForm.get('color')?.value">
								{{itemForm.get('text')?.value}}
								<mat-icon *ngIf="itemForm.get('icon')?.value">{{itemForm.get('icon')?.value}}</mat-icon>
							</button>
							<button *ngSwitchCase="'mini-fab'" mat-mini-fab [color]="itemForm.get('color')?.value">
								{{itemForm.get('text')?.value}}
								<mat-icon *ngIf="itemForm.get('icon')?.value">{{itemForm.get('icon')?.value}}</mat-icon>
							</button>
						</ng-container>
					</div>
				</div>
			</form>
			<app-element-blade-styles *ngIf="activeItem && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="activeItem?.id">Spara</span>
			<span *ngIf="!activeItem?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
