import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingCustomerTypeStore } from 'app/admin/stores/tour-booking-customer-type.store.service';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourBookingCustomerTypeBladeComponent } from 'app/admin/blades/tour-booking-customer-type/tour-booking-customer-type-blade/tour-booking-customer-type-blade.component';
import { TourBookingAddonStore } from 'app/admin/stores/tour-booking-addon.store.service';
import { TourBookingAddon } from 'app/models/tour-booking-addon.model';
import { TourBookingAddonBladeComponent } from 'app/admin/blades/tour-booking-addon/tour-booking-addon-blade/tour-booking-addon-blade.component';
import { TourBookingPickupLocationStore } from 'app/admin/stores/tour-booking-pickup-location.store.service';
import { TourBookingPickupLocation } from 'app/models/tour-booking-pickup-location.model';
import { TourBookingPickupLocationBladeComponent } from 'app/admin/blades/tour-booking-pickup-location/tour-booking-pickup-location-blade/tour-booking-pickup-location-blade.component';

@Component({
  selector: 'app-tour-booking-blade-bookings',
  templateUrl: './tour-booking-blade-bookings.component.html',
  styleUrls: ['./tour-booking-blade-bookings.component.scss'],
})
export class TourBookingBladeBookingsComponent implements OnInit {

	public displayedColumns: string[] = ['id', 'customerType', 'quantity', 'created'];
	public dataSource: MatTableDataSource<TourBookingCustomerType>;

	public displayedPickupLocationColumns: string[] = ['id', 'pickupLocation', 'customerType', 'quantity', 'created'];
	public dataSourcePickupLocation: MatTableDataSource<TourBookingPickupLocation>;
	public addonDataSource: MatTableDataSource<TourBookingAddon>;

	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private tourBookingPickupLocationStore: TourBookingPickupLocationStore,
		private tourBookingAddonStore: TourBookingAddonStore,
	) { }

	ngOnInit() { }





	public createNew() {
		// this.bladeService.addBladeItem({
		// 	id: 0,
		// 	component: TourBladeComponent,
		// 	minClass: BladeSize.xs
		// }, this.bladeItem);
	}
}
