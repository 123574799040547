<mat-card>
	<mat-card-title>Inställningar</mat-card-title>
	<form [formGroup]="itemForm">
		<mat-form-field class="w-100">
			<input matInput formControlName="logo" placeholder="Logga">
		</mat-form-field>
		<mat-form-field class="w-100">
			<input matInput formControlName="googleAnalyticsTrackingId" placeholder="Google Analytics TrackingId">
		</mat-form-field>
		<mat-form-field class="w-100">
			<input matInput formControlName="defaultLanguage" placeholder="Prefix på förvalt språk på hemsidan">
		</mat-form-field>
		<mat-form-field class="w-100">
			<input matInput formControlName="dibsCheckoutPath" placeholder="Länk till dibs checkout">
		</mat-form-field>

		<mat-card>
			<mat-card-subtitle>Facebook</mat-card-subtitle>
			<p>För att Facebook chatten ska fungera så måste ni ange "Sid-id". <a href="https://www.facebook.com/help/1503421039731588" target="_blank">Klicka här</a> för att se hur du hittar Sid-id.</p>
			<p>Eran domän måste finnas i listan "Vitlistade domäner". Den hittar ni på eran Facebook sida. Gå till "Sidinställningar". Gå vidare till "Avancerade meddelanden". Här finns "Vitlistade domäner".</p>
			<mat-form-field class="w-100">
				<input matInput formControlName="fbPageId" placeholder="Facebook Sid-id">
			</mat-form-field>
		</mat-card>
	</form>
</mat-card>
