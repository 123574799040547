<mat-card>
	<mat-card-title>
		<div class="d-flex justify-content-between">
			<span>Hämtningar</span>
			<div>
				<button mat-button (click)="accordion.closeAll()"><mat-icon>unfold_less</mat-icon></button>
				<button mat-button (click)="accordion.openAll()"><mat-icon>unfold_more</mat-icon></button>
			</div>
		</div>
	</mat-card-title>
	<mat-card-content>
		<mat-accordion multi>
			<mat-expansion-panel *ngFor="let pickupLocationGroup of pickupLocationGroups$ | async">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<b>{{pickupLocationGroup.pickupLocationId | pickupLocationName | async}}</b>
					</mat-panel-title>
					<mat-panel-description>
						<div class="w-100 mr-4 text-right">
							<span *ngFor="let customerType of pickupLocationGroup.totalCustomerTypes; let isLast = last;">
								{{customerType.customerTypeId | customerTypeName | async}} {{customerType.quantity}}st <span *ngIf="!isLast">, </span>
							</span>
						</div>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-card class="mb-3" *ngFor="let tourBooking of pickupLocationGroup.tourBookings;">
					<div class="d-flex justify-content-between">
						<span><mat-checkbox>{{tourBooking.customerId | customerName | async}}</mat-checkbox></span>
						<div>
							<span *ngFor="let customerType of tourBooking.totalCustomerTypes; let isLast = last;">
								{{customerType.customerTypeId | customerTypeName | async}} {{customerType.quantity}}st <span *ngIf="!isLast">, </span>
							</span>
						</div>
					</div>
				</mat-card>
			</mat-expansion-panel>
		</mat-accordion>
	</mat-card-content>
</mat-card>
