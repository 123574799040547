<ng-container *ngIf="{
	pickupLocationPrice: pickupLocationPriceReplay$ | async,
	productPickupLocationPrice: productPickupLocationPriceReplay$ | async
} as source">
	<span>
		{{customerType.name[0]}}:
		<span class="mr-1" *ngIf="!source.pickupLocationPrice && !source.productPickupLocationPrice"><del>--</del></span>
		<span class="mr-1" *ngIf="source.pickupLocationPrice && !source.productPickupLocationPrice">{{source.pickupLocationPrice.price}}</span>
		<span class="mr-1" *ngIf="source.pickupLocationPrice && source.productPickupLocationPrice"><del>{{source.pickupLocationPrice.price}}</del></span>
		<span class="mr-1" *ngIf="source.productPickupLocationPrice">{{source.productPickupLocationPrice.price}}</span>
	</span>
</ng-container>
