<button
	*ngIf="items?.length || (bladeContentService.tabs$ | async)?.length"
	class="blade-menu-btn"
	type="button"
	aria-label="Toggle sidenav"
	mat-icon-button
	(click)="sidenavToggle()">
	<mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
</button>

<mat-sidenav-container>
		<mat-sidenav
			*ngIf="items?.length || (bladeContentService.tabs$ | async)?.length"
			#sidenav
			opened
			mode="side"
			[attr.role]="((isHandset$ | async) || bladeItem.activeClass === BladeSize.s || bladeItem.activeClass === BladeSize.xs) ? 'dialog' : 'navigation'"
			[mode]="((isHandset$ | async) || bladeItem.activeClass === BladeSize.s || bladeItem.activeClass === BladeSize.xs) ? 'over' : 'side'"
			[opened]="!((isHandset$ | async) || bladeItem.activeClass === BladeSize.s || bladeItem.activeClass === BladeSize.xs)">
			<mat-nav-list [class.has-icon]="hasIcon">
				<ng-container *ngIf="!items">
					<a *ngFor="let item of (bladeContentService.tabs$ | async) | order:'index'" mat-list-item (click)="selectMenuTab(item)" class="{{item.class}}" [class.disabled]="item.disabled" [class.selected]="item.isActive">
						<mat-icon
							*ngIf="item.iconName"
							[fontSet]="item.iconCustom ? item.iconSet : ''"
							[fontIcon]="item.iconCustom ? item.iconName : ''"
							class="route-icon">
							{{!item.iconCustom ? item.iconName : ''}}
						</mat-icon>
						{{item.title}}
					</a>
				</ng-container>
				<ng-container *ngIf="items">
					<a *ngFor="let item of items | order:'index'" mat-list-item (click)="selectMenuItem(item)" [class.disabled]="item.disabled" [class.selected]="menuIndex === item.index">{{item.title}}</a>
				</ng-container>
			</mat-nav-list>
	</mat-sidenav>

		<mat-sidenav-content>

		<mat-card-content>
			<ng-content></ng-content>
		</mat-card-content>

	</mat-sidenav-content>
</mat-sidenav-container>
