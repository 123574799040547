

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from '../base.store.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { ProductFunctionBase } from 'app/models/product-function/product-function-base.model';

@Injectable({
	providedIn: 'root',
})
export class ProductFunctionStore extends BaseStore<ProductFunctionBase> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'productFunction', baseUrl, snackBarService);
	}
}
