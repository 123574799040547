<mat-card>
	<mat-card-title>Generellt</mat-card-title>

	<form [formGroup]="itemForm">
		<div class="row">
			<div class="col-md-7">
				<mat-form-field class="w-100">
					<input matInput formControlName="name" placeholder="Arbetsnamn">
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<mat-form-field class="w-100">
					<input matInput formControlName="timeLength" placeholder="Ange längd i timmar">
				</mat-form-field>
			</div>
			<div class="col-md-2 align-items-end d-flex">
				<mat-checkbox formControlName="active">Aktiv</mat-checkbox>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field class="w-100">
					<mat-label>Välj produkt typ</mat-label>
					<mat-select formControlName="type">
						<mat-option *ngFor="let type of (productTypeStore.items$ | async)" [value]="type.id">
							{{type.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<mat-form-field class="w-100">
					<mat-label>Välj leverantör</mat-label>
					<mat-select formControlName="supplier">
						<mat-option *ngFor="let supplier of (supplierStore.items$ | async)" [value]="supplier.id">
							{{supplier.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<mat-card>
			<mat-card-title>Resurser</mat-card-title>
			<mat-card-subtitle>En del leverantörer har resurser. Dessa resurser kan du aktivera på den här produkten. Samtidigt som du anger hur resurserna fördelas per användare.</mat-card-subtitle>
			<mat-card-subtitle>OBS! När du byter leverantör, inte levernatörsprodukt inom samma leverantör, så nollställs alla angivna resurser som redan är kopplade på produkten.</mat-card-subtitle>
			<ng-container *ngIf="{resources: supplierResourceStore.items$ | async } as source">
				<i *ngIf="!source.resources?.length">Den här leverantören saknar resurser.</i>
				<ng-container *ngIf="source.resources?.length">
					<p>Välj en resurs i listan följtav vilket sätt du vill koppla resursen. Avsluta med "växelkursen".</p>

					<form [formGroup]="resourceForm" class="row">
						<div class="col-6">
							<mat-form-field class="w-100">
								<mat-label>Välj resurs</mat-label>
								<mat-select formControlName="supplierResource">
									<mat-option *ngFor="let resource of (supplierResourceStore.items$ | async)" [value]="resource.id">
										{{resource.supplierResourceTypeId | supplierResourceTypeName | async}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-6">
							<mat-form-field class="w-100">
								<mat-label>Hur ska resursen hanteras</mat-label>
								<mat-select formControlName="supplierResourceProductType">
									<mat-option *ngFor="let supplierResourceProductType of supplierResourceProductTypeStore.items$ | async" [value]="supplierResourceProductType">
										{{supplierResourceProductType.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-12">
							<mat-form-field class="w-100">
								<mat-label>{{supplierResourceProductTypeDescription[resourceForm.value?.supplierResourceProductType?.identifier] || 'Ange hur resursen ska hanteras först'}}</mat-label>
								<input matInput formControlName="course">
							</mat-form-field>
						</div>
					</form>
					<div class="d-flex" [ngClass]="{'justify-content-end': !resourceForm.value.id, 'justify-content-between': resourceForm.value.id}">
						<button mat-stroked-button color="warn" (click)="removeProductResource(resourceForm.value.id)" *ngIf="resourceForm.value.id">Ta bort</button>
						<div>
							<button mat-stroked-button color="accent" class="mr-2" (click)="resetProductResource()" *ngIf="resourceForm.value.id">Avbryt</button>
							<button mat-stroked-button color="accent" (click)="addProductResource()" [disabled]="!resourceForm.valid">
								<span *ngIf="!resourceForm.value.id">Lägg till resurs</span>
								<span *ngIf="resourceForm.value.id">Uppdatera resurs</span>
							</button>
						</div>
					</div>

					<div class="mat-elevation-z3 mt-3">
						<table mat-table [dataSource]="supplierResourceProductStore.items$" matSort class="w-100">
							<ng-container matColumnDef="supplier">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Resurs</th>
								<td mat-cell *matCellDef="let item"><strong>{{item.supplierResourceId | supplierResourceTypeNameFromSupplierResource | async}}</strong></td>
							</ng-container>
							<ng-container matColumnDef="supplierResourceProductType">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Hanteras</th>
								<td mat-cell *matCellDef="let item">{{item.supplierResourceProductTypeId | supplierResourceProductTypeName | async}}</td>
							</ng-container>
							<ng-container matColumnDef="course">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Antal per hantering</th>
								<td mat-cell *matCellDef="let item">{{item.course}}</td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedDateColumns"></tr>
							<tr mat-row *matRowDef="let item; columns: displayedDateColumns;" class="pointer" (click)="selectProductResource(item)" [class.selected]="bladeItem?.child?.id === item?.id"></tr>
						</table>
					</div>
				</ng-container>
			</ng-container>
		</mat-card>
	</form>
</mat-card>
