import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { ProductAddonStore } from 'app/admin/stores/product/product-addon.store.service';
import { AddonStore } from 'app/admin/stores/addon.store.service';
import { Addon } from 'app/models/addon.model';
import { ProductAddon } from 'app/models/product-addon.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProductAddonBladeComponent } from 'app/admin/blades/product-addon/product-addon-blade/product-addon-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-product-blade-addons',
	templateUrl: './product-blade-addons.component.html',
	styleUrls: ['./product-blade-addons.component.scss'],
})
export class ProductBladeAddonsComponent implements OnInit, OnDestroy {
	private addons: Addon[];
	public displayedColumns: string[] = ['select', 'name', 'price', 'action'];
	public dataSource: MatTableDataSource<ExtendAddon>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	private productAddons: ProductAddon[];
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public addonStore: AddonStore,
		public productAddonStore: ProductAddonStore,
		private snackBarService: SnackBarService,
		private bladeService: BladeService,
	) {
		this.addonStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.addons = items;
			this.getAllOnProduct();
		});
	}

	ngOnInit() {}

	ngOnDestroy() {}

	public openAddon(addon: ProductAddon) {
		this.bladeService.addBladeItem({
			id: addon.id,
			component: ProductAddonBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);

	}

	private getAllOnProduct() {
		this.productAddonStore.getAllOnProduct(this.bladeItem.id).subscribe(connectedAddons => {
			this.productAddons = connectedAddons;
			this.handleSelectedAddons();
		});
	}

	private handleSelectedAddons() {
		const extendAddons = [];
		this.addons.forEach(addon => {
			const productAddon = _.find(this.productAddons, p => p.addonId === addon.id);
			extendAddons.push({
				addon,
				productAddon,
			});
		});

		this.dataSource = new MatTableDataSource<ExtendAddon>(extendAddons);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
}

	public toggleProductAddon(item: ExtendAddon) {
		if (item.productAddon?.id) {
			this.productAddonStore.delete(item.productAddon.id).subscribe(deleted => {
				item.productAddon = null;
			});
		} else {
			const newProductPickupLocation = new ProductAddon();
			newProductPickupLocation.productId = this.bladeItem.id;
			newProductPickupLocation.addonId = item.addon.id;
			this.productAddonStore.create(newProductPickupLocation).subscribe(productPickupLocation => {
				item.productAddon = productPickupLocation;
			});
		}
	}
}

interface ExtendAddon {
	addon: Addon;
	productAddon?: ProductAddon;
}
