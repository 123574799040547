import { BaseModel } from './base.model';
import { Addon } from './addon.model';

export class ProductAddon extends BaseModel {
	public productId: number;
	public addonId: number;
	public prio: number;
	public price: number;
	public addon: Addon;
}
