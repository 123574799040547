import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { AlertCardComponent } from './alert-card.component';

@NgModule({
	declarations: [
		AlertCardComponent,
	],
	imports: [
		CommonModule,
		MatCardModule,
		MatIconModule,
	],
	exports: [
		AlertCardComponent,
	],
})
export class AlertCardModule { }
