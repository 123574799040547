import { UserTypeEnum } from 'app/enums/user-type.enum';
import { UserType } from 'app/models/user-type.model';
import { UserService } from './user-service.model';

export class User {
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public phone: string;
	public userType: UserType | UserTypeEnum;
	public userTypeId: number;
	public userServices: UserService[];
	public roles: string[];
	public travelAgencyId: number;
	public requireChangePassword: boolean;
	public password: string;
}
