<div class="login-container" *ngIf="loginModalService.showLoginModalReplay$ | async">
	<ng-container *ngIf="{
		user: userService.user$ | async,
		requireChangePassword: requireChangePasswordSubject$ | async,
		adminText: 'login' | adminText | async,
		newPasswordSent: newPasswordSent$ | async,
		newPasswordSending: newPasswordSending$ | async
	} as source">
		<mat-card *ngIf="!source.user" class="mat-elevation-z10">
			<div class="logo-container">
				<img src="qs_logo_vertical.png" alt="Logo">
			</div>
			<ngx-spinner type="ball-grid-pulse">
				<p style="font-size: 20px; color: white">{{source.adminText?.tryToLogin}}</p>
			</ngx-spinner>
			<ng-container>
				<mat-card-title>{{source.adminText?.title}}</mat-card-title>
				<mat-card-subtitle>{{source.adminText?.subtitle}}</mat-card-subtitle>
				<form [formGroup]="formGroup" (submit)="login()">
					<mat-card-content>
						<mat-form-field appearance="outline" class="w-100">
							<mat-label>{{source.adminText?.email}}</mat-label>
							<input
								matInput
								[placeholder]="source.adminText?.email"
								formControlName="email"
							/>
						</mat-form-field>
						<mat-form-field appearance="outline" class="w-100">
							<mat-label>{{source.adminText?.password}}</mat-label>
							<input
								matInput
								type="password"
								[placeholder]="source.adminText?.password"
								formControlName="password"
							/>
						</mat-form-field>

						<mat-card *ngIf="source.requireChangePassword" class="mt-3 border border-danger">
							<p class="text-danger">{{source.adminText?.updatePasswordDescription}}</p>
							<mat-form-field appearance="outline" class="w-100">
								<mat-label>{{source.adminText?.newPassword}}</mat-label>
								<input
									matInput
									type="password"
									[placeholder]="source.adminText?.newPassword"
									formControlName="newPassword"
								/>
							</mat-form-field>
						</mat-card>

						<div class="text-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
					</mat-card-content>
					<div class="d-flex justify-content-between">
						<span></span>
						<button type="submit" mat-button [disabled]="!formGroup.valid" (click)="login()" mat-raised-button color="primary">
							<span *ngIf="!source.requireChangePassword">{{source.adminText?.login}}</span>
							<span *ngIf="source.requireChangePassword">{{source.adminText?.changePasswordAndLogin}}</span>
						</button>
					</div>
					<div *ngIf="formGroup.get('email').valid">
						<p *ngIf="!source.newPasswordSent" [class.forgot-password]="!source.newPasswordSending" class="mb-0 mt-2 text-right" (click)="sendNewPassword(source.newPasswordSending)">Har du glömt lösenordet?</p>
						<p *ngIf="source.newPasswordSent" class="bg-success mb-0 mt-2 p-2 rounded text-center text-right text-white">Ett nytt lösenord kommer skickas<br>till angivna epost inom 5 minuter.</p>
					</div>
				</form>
			</ng-container>
		</mat-card>

		<mat-card *ngIf="source.user" class="mat-elevation-z10">
			<div class="logo-container">
				<img src="qs_logo_vertical.png" alt="Logo">
			</div>
			<mat-card-title>{{source.adminText?.unauthorizedTitle}}</mat-card-title>
			<mat-card-content>
				<p>{{source.adminText?.unauthorizedDescription1a}} <b>{{source.user.email}}</b> {{source.adminText?.unauthorizedDescription1b}}</p>
				<p>{{source.adminText?.unauthorizedDescription2}}</p>
			</mat-card-content>
			<div class="d-flex justify-content-between">
				<span></span>
				<button mat-button (click)="logut()" mat-raised-button color="primary">{{source.adminText?.logout}}</button>
			</div>
			<p class="company mt-3"><em>QuestSystem</em></p>
		</mat-card>
	</ng-container>
</div>
