import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { TourBookingSpecialDietService } from './services/tour-booking-special-diet.service';

@Component({
  	selector: 'app-tour-booking-special-diet',
	templateUrl: './tour-booking-special-diet.component.html',
  	styleUrls: ['./tour-booking-special-diet.component.scss'],
	providers: [TourBookingSpecialDietService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourBookingSpecialDietComponent implements OnInit {

	constructor(
		public tourBookingSpecialDietService: TourBookingSpecialDietService,
	) { }

	ngOnInit() {
	}
}
