import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { PickupLocation } from 'app/models/page-location.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-pickup-location-blade-generally',
	templateUrl: './pickup-location-blade-generally.component.html',
	styleUrls: ['./pickup-location-blade-generally.component.scss'],
})
export class PickupLocationBladeGenerallyComponent implements OnInit {

	@Input() item: PickupLocation;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		relativeTime: new FormControl(null, [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
	) { }

	ngOnInit() {
		this.itemForm.setValue({
			name: this.item.name,
			relativeTime: this.item.relativeTime,
		});
		this.itemForm.get('name').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.name = value;
		});
		this.itemForm.get('relativeTime').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.relativeTime = value;
		});
	}
}
