import { Component, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';

import { CustomerStore } from 'app/admin/stores/customer.store.service';
import { Customer } from 'app/models/customer.model';
import { BladeItemInjectToken, BladeService } from '../blade/blade.service';
import { BladeItem } from '../blade/models/bladeItem.model';

@UntilDestroy()
@Component({
	selector: 'app-customer-list',
	templateUrl: './customer-list.component.html',
	styleUrls: ['./customer-list.component.scss'],
})
export class CustomerListComponent {

	private allCustomers: Customer[];
	public displayedColumns: string[] = ['id', 'name', 'email', 'phone', 'link'];
	public dataSource: MatTableDataSource<Customer>;
	public itemForm: FormGroup = new FormGroup({
		searchWord: new FormControl(''),
	});

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: CustomerStore,
	) {
		itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			if (this.allCustomers?.length > 0 && items.length > this.allCustomers.length && this.bladeItem?.parent?.callback) {
				this.selectItem(items[items.length - 1]);
			}
			this.allCustomers = _.cloneDeep(items);
			this.setDataSource(items);
		});

		this.itemForm.get('searchWord').valueChanges.pipe(untilDestroyed(this)).subscribe(searchWord => this.search(searchWord));
	}

	public selectItem(customer) {
		if (this.bladeItem.parent && this.bladeItem.parent.callback) {
			this.bladeItem.parent.callback(customer);
			this.bladeItem.bladeItemComponent.closeMe();
		} else {
			window.open('/customer?customerId=' + customer.id, '_blank');
			// this.bladeService.addBladeItem({
			// 	id: row.id,
			// 	component: CustomerBladeComponent,
			// 	minClass: BladeSize.m,
			// 	maxClass: BladeSize.l,
			// }, this.bladeItem);
		}
	}

	private search(searchWord: string) {
		let searchResult = this.allCustomers;
		if (searchWord) {
			searchWord = searchWord.toLowerCase();
			searchResult = this.allCustomers.filter(c =>
				c.firstName?.toLowerCase()?.includes(searchWord) ||
				c.lastName?.toLowerCase()?.includes(searchWord) ||
				c.email?.toLowerCase()?.includes(searchWord) ||
				c.phone?.toLowerCase()?.includes(searchWord) ||
				c.id?.toString().includes(searchWord));
		}
		this.setDataSource(searchResult);
	}

	private setDataSource(items: Customer[]) {
		this.dataSource = new MatTableDataSource<Customer>(items);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
}
