import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { Tour } from '../../models/tour.model';
import { SnackBarService } from '../services/snack-bar.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class TourStore extends BaseStore<Tour> {
	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Tour', baseUrl, snackBarService);
	}

	public getAllFromDate(date: string): Observable<Tour[]> {
		return this.http.get<Tour[]>(`${this.host}/all/date/${date}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllBetweenDates(fromDate: string, toDate: string): Observable<Tour[]> {
		return this.http.get<Tour[]>(`${this.host}/all/fromDate/${fromDate}/toDate/${toDate}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllBetweenDatesWithSpecialDiets(fromDate: string, toDate: string): Observable<Tour[]> {
		return this.http.get<Tour[]>(`${this.host}/all/special-diet/fromDate/${fromDate}/toDate/${toDate}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnGuideBetweenDates(guideId: number, fromDate: string, toDate: string): Observable<Tour[]> {
		return this.http.get<Tour[]>(`${this.host}/all/guide/${guideId}/fromDate/${fromDate}/toDate/${toDate}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnGuideFromDate(guideId: number, fromDate: string): Observable<Tour[]> {
		return this.http.get<Tour[]>(`${this.host}/all/guide/${guideId}/fromDate/${fromDate}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnCustomerBetweenDates(customerId: number, fromDate: string, toDate: string): Observable<Tour[]> {
		return this.http.get<Tour[]>(`${this.host}/all/customer/${customerId}/fromDate/${fromDate}/toDate/${toDate}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnCustomer(customerId: number): Observable<Tour[]> {
		return this.http.get<Tour[]>(`${this.host}/all/customer/${customerId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnSupplier(supplierId: number): Observable<Tour[]> {
		return this.http.get<Tour[]>(`${this.host}/supplier/${supplierId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnSupplierBetweenDates(supplierId: number, fromDate: string, toDate: string): Observable<Tour[]> {
		return this.http.get<Tour[]>(`${this.host}/supplier/${supplierId}/fromDate/${fromDate}/toDate/${toDate}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnProduct(productId: number): Observable<Tour[]> {
		return this.http.get<Tour[]>(`${this.host}/product/${productId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getTotalCustomersOnTour(tourId: number): Observable<number> {
		return this.http.get<number>(`${this.host}/${tourId}/total/customer`);
	}

	public getAvailablePlacesOnTourByDate(productId: number, date: Date, time: string): Observable<number> {
		return this.http.get<number>(`${this.host}/available-places/product/${productId}/date/${moment(date).format('YYYY-MM-DD')}/time/${time}`);
	}
}
