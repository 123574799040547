<ng-container *ngIf="{
	pickupLocationPrice: pickupLocationPriceReplay$ | async,
	productPickupLocationPrice: productPickupLocationPriceReplay$ | async
} as source">
	<form [formGroup]="itemForm">
		<mat-form-field class="w-100">
			<mat-label>
				Pris för {{customerType?.name}}. Orginal pris:
				<span *ngIf="source.pickupLocationPrice">{{source.pickupLocationPrice?.price}}</span>
				<span *ngIf="!source.pickupLocationPrice">Saknas</span>
			</mat-label>
			<input matInput formControlName="price" placeholder="Pris som ska gälla">
			<mat-hint>Ange bara pris om den skilljer sig från orginalet.</mat-hint>
		</mat-form-field>
	</form>
</ng-container>
