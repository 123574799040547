import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { TourMessageStore } from 'app/admin/stores/tour-message.store.service';
import { TourMessage } from 'app/models/tour-message.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourBookingPickupLocationStore } from 'app/admin/stores/tour-booking-pickup-location.store.service';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { TourBooking } from 'app/models/tour-booking.model';
import { MatAccordion } from '@angular/material/expansion';

@UntilDestroy()
@Component({
	selector: 'app-guide-tour-pickup',
	templateUrl: './tour-pickup.component.html',
	styleUrls: ['./tour-pickup.component.scss'],
})
export class GuideTourPickupComponent implements OnInit, OnDestroy {
	@ViewChild(MatAccordion) accordion: MatAccordion;

	private tourBookings: TourBooking[];
	public isCreating = false;
	public itemForm: FormGroup = new FormGroup({
		message: new FormControl('', []),
	});

	private pickupLocationGroups: PickupLocationGroup[] = [];

	public pickupLocationGroups$ = new ReplaySubject<PickupLocationGroup[]>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public supplierStore: SupplierStore,
		public tourBookingStore: TourBookingStore,
		public tourBookingPickupLocationStore: TourBookingPickupLocationStore,
		public tourMessageStore: TourMessageStore,
	) {

		this.tourBookingStore.getAllOnTour(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(tourBookings => {
			_.each(tourBookings, tourBooking => {
				this.tourBookingPickupLocationStore.getAllOnTourBooking(tourBooking.id).pipe(untilDestroyed(this)).subscribe(pickupLocationsOnTourBooking => {
					pickupLocationsOnTourBooking.forEach(pickupLocation => {
						this.addTourBookingToPickup(
							pickupLocation.pickupLocationId, pickupLocation.tourBookingId, tourBooking.customerId, pickupLocation.quantity, pickupLocation.customerTypeId,
						);
						this.addTotalCustomerToPickup(pickupLocation.pickupLocationId, pickupLocation.customerTypeId, pickupLocation.quantity);
					});
				});
			});
		});
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {

	}
	public createMessage() {
		this.isCreating = true;
		const tourMessage = new TourMessage();
		tourMessage.tourId = this.bladeItem.id;
		tourMessage.message = this.itemForm.get('message').value;
		this.tourMessageStore.create(tourMessage).subscribe(() => {
			this.isCreating = false;
			this.itemForm.get('message').setValue('');
		});
	}

	private getPickup(pickupLocationId: number): PickupLocationGroup {
		const pickup = this.pickupLocationGroups.find(x => x.pickupLocationId === pickupLocationId);
		if (pickup) {
			return pickup;
		}

		const newPickup: PickupLocationGroup = {
			pickupLocationId,
			tourBookings: [],
			totalCustomerTypes: [],
		};

		this.pickupLocationGroups.push(newPickup);

		return newPickup;
	}

	private getCustomerTypeOnPickup(pickupLocationId: number, customerTypeId: number): CustomerTypeGroup {
		const pickup = this.getPickup(pickupLocationId);
		if (pickup) {
			if (pickup.totalCustomerTypes) {
				const find = pickup.totalCustomerTypes.find(x => x.customerTypeId === customerTypeId);
				if (find) {
					return find;
				}
			}
			const newCustomerType: CustomerTypeGroup = {
				customerTypeId,
				quantity: 0,
			};

			if (!pickup.totalCustomerTypes) {
				pickup.totalCustomerTypes = [];
			}

			pickup.totalCustomerTypes.push(newCustomerType);
			return newCustomerType;
		}
	}

	private addTotalCustomerToPickup(pickupLocationId: number, customerTypeId: number, quantity: number): void {
		const customerType = this.getCustomerTypeOnPickup(pickupLocationId, customerTypeId);
		if (customerType) {
			customerType.quantity += quantity;
		}
		this.pickupLocationGroups$.next(this.pickupLocationGroups);
	}

	private getTourBookingOnPickup(pickupLocationId: number, tourBookingId: number, customerId: number, quantity: number): TourBookingGroup {
		const pickup = this.getPickup(pickupLocationId);
		if (pickup) {
			if (pickup.tourBookings) {
				const find = pickup.tourBookings.find(x => x.tourBookingId === tourBookingId);
				if (find) {
					return find;
				}
			}
			const newTourBookingGroup: TourBookingGroup = {
				tourBookingId,
				customerId,
				quantity,
				totalCustomerTypes: [],
			};

			if (!pickup.tourBookings) {
				pickup.tourBookings = [];
			}

			pickup.tourBookings.push(newTourBookingGroup);
			return newTourBookingGroup;
		}
	}

	private addTourBookingToPickup(
		pickupLocationId: number, tourBookingId: number, customerId: number, quantity: number, customerTypeId: number,
	): void {
		const tourBooking = this.getTourBookingOnPickup(pickupLocationId, tourBookingId, customerId, quantity);
		if (tourBooking) {
			tourBooking.totalCustomerTypes.push({
				customerTypeId,
				quantity,
			});
		}
		this.pickupLocationGroups$.next(this.pickupLocationGroups);
	}


}

interface PickupLocationGroup {
	pickupLocationId: number;
	tourBookings: TourBookingGroup[];
	totalCustomerTypes: CustomerTypeGroup[];
}

interface CustomerTypeGroup {
	customerTypeId: number;
	quantity: number;
}

interface TourBookingGroup {
	tourBookingId: number;
	customerId: number;
	quantity: number;
	totalCustomerTypes: CustomerTypeGroup[];
}
