import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { ColumnStore } from 'app/admin/stores/column.store.service';
import { Column } from 'app/models/site/column.model';
import { ColumnBladeComponent } from 'app/admin/blades/column/column-blade/column-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';

@UntilDestroy()
@Component({
	selector: 'app-etage-blade-columns',
	templateUrl: './etage-blade-columns.component.html',
	styleUrls: ['./etage-blade-columns.component.scss'],
})
export class EtageBladeColumnsComponent implements OnInit, OnDestroy {
	private pristinePrioList: number[];
	private columns: Column[];
	public prioIsChangedReplay$ = new ReplaySubject<boolean>();
	public savingPrioReplay$ = new ReplaySubject<boolean>();
	public displayedColumns: string[] = ['name', 'modified'];
	public dataSource: MatTableDataSource<Column>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public columnStore: ColumnStore,
	) {}

	ngOnInit() {
		this.columnStore.reset();
		this.columnStore.getAllOnEtage(this.bladeItem.id).subscribe();
		this.columnStore.items$
			.pipe(untilDestroyed(this))
			.subscribe(columns => {
				this.columns = _.sortBy(columns, i => i.prio);
				this.pristinePrioList = this.columns.map(p => p.id);

				this.dataSource = new MatTableDataSource<Column>(this.columns);
				this.dataSource.paginator = this.paginator;
		});
	}

	ngOnDestroy(): void { }

	public selectItem(row: Column) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: ColumnBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

	public onListDrop(event: CdkDragDrop<MatTableDataSource<Column>, any>) {
		const previousIndex = this.columns.findIndex(row => row === event.item.data);
		moveItemInArray(this.columns, previousIndex, event.currentIndex);
		this.columns.forEach((element, index) => element.prio = index);
		this.dataSource = new MatTableDataSource<Column>(this.columns);
		this.dataSource.paginator = this.paginator;

		this.isPrioChanged();
	}

	public savePrio() {
		this.savingPrioReplay$.next(true);
		this.columnStore.updatePrio(this.columns).pipe(untilDestroyed(this)).subscribe(done => {
			this.pristinePrioList = this.columns.map(p => p.id);
			this.savingPrioReplay$.next(false);
			this.isPrioChanged();
		});
	}

	private isPrioChanged() {
		if (this.columns.map(p => p.id).toString() !== this.pristinePrioList.toString()) {
			this.prioIsChangedReplay$.next(true);
		} else {
			this.prioIsChangedReplay$.next(false);
		}
	}
}
