import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { Customer } from 'app/models/customer.model';
import { CustomerStore } from 'app/admin/stores/customer.store.service';

@Component({
	selector: 'app-settings-blade',
	templateUrl: './settings-blade.component.html',
	styleUrls: ['./settings-blade.component.scss'],
})
@IBladeComponent.register
export class SettingsBladeComponent implements OnInit {
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: CustomerStore,
		private bladePositionService: BladePositionService,
	) { }

	ngOnInit() { }
}
