import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SnackBarService } from '../services/snack-bar.service';
import { TourBookingMessage } from 'app/models/tour-booking-message.model';

@Injectable({
	providedIn: 'root',
})
export class TourBookingMessageStore extends BaseStore<TourBookingMessage> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TourBookingMessage', baseUrl, snackBarService);
	}

	public getAllOnTourBooking(tourBookingId: number): Observable<TourBookingMessage[]> {
		return this.http.get<TourBookingMessage[]>(`${this.host}/tourBooking/${tourBookingId}`).pipe(tap(tourBookings => {
			this._items = tourBookings;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnTourBooking$(tourBookingId: number): Observable<TourBookingMessage[]> {
		return this.http.get<TourBookingMessage[]>(`${this.host}/tourBooking/${tourBookingId}`);
	}
}
