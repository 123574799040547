<mat-card *ngIf="{
	adminText: 'blades.tour.tabs.program' | adminText | async
} as source">
	<mat-card-title>{{source.adminText?.title}}</mat-card-title>
	<mat-card-content>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<mat-label>Språk</mat-label>
				<mat-select formControlName="languageId">
					<mat-option *ngFor="let type of (languageStore.items$|async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<button mat-raised-button color="accent" [disabled]="!itemForm?.value?.languageId" (click)="showProgram()">{{source.adminText?.showProgramBtn}} <mat-icon class="ml-2">open_in_new</mat-icon></button>
		</form>
	</mat-card-content>
</mat-card>
