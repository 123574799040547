import { Component, OnInit, OnDestroy } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import { Label, PluginServiceGlobalRegistrationAndOptions } from 'ng2-charts';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { OrderPaymentStore } from 'app/admin/stores/order-payment.store.service';
import { OrderPayment } from 'app/models/order-payment.model';

@UntilDestroy()
@Component({
	selector: 'app-dashboard-widget-order-payments',
	templateUrl: './dashboard-widget-order-payments.component.html',
	styleUrls: ['./dashboard-widget-order-payments.component.scss'],
})
export class DashboardWidgetOrderPaymentsComponent implements OnInit, OnDestroy {
	private orderPayments: OrderPayment[];
	private decimalPipe = new DecimalPipe('sv-SE');
	public barChartOptions: ChartOptions = {
		responsive: true,
		// We use these empty structures as placeholders for dynamic theming.
		scales: {
			yAxes: [{
				ticks: {
					callback: (dataLabel, index) => {
						return this.decimalPipe.transform(dataLabel, '1.0-0');
					},
				},
			}],
			xAxes: [{
			}],
		},
		plugins: {
		  datalabels: {
			anchor: 'end',
			align: 'end',
			formatter: (value, context) => {
				return this.decimalPipe.transform(value, '1.0-0');
			},
		  },
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem, data) => {
					const label = data.datasets[tooltipItem.datasetIndex].label;
					const value = data.labels[tooltipItem.index];
					return `${label}: ${this.decimalPipe.transform(tooltipItem.value, '1.0-0')}`;
				},
			},
		},
	  };
	  public barChartLabels: Label[] = [];
	  public barChartType: ChartType = 'bar';
	  public barChartLegend = true;
	//   public barChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = pluginDataLabels;

	public barChartData: ChartDataSets[] = [];

	public isDone = false;
	constructor(
		private orderPaymentStore: OrderPaymentStore,
	) { }

	ngOnInit() {
		this.orderPaymentStore.getAll$().pipe(untilDestroyed(this)).subscribe(payments => {
			this.orderPayments = payments;
			this.setAllLabels();
			this.setData();
			this.isDone = true;
		});
	}

	ngOnDestroy(): void { }

	private setAllLabels() {
		this.barChartLabels = [];
		_.each(this.orderPayments, payment => {
			const label = moment(payment.created).format('YYYY-MM-DD');
			if (!_.find(this.barChartLabels, barLabel => barLabel === label)) {
				this.barChartLabels.push(label);
			}
		});
	}

	private setData() {
		const data = [];
		const chartData = {};
		_.each(this.barChartLabels, barLabel => {
			const paymentsOnDate = _.filter(this.orderPayments, payment => moment(payment.created).format('YYYY-MM-DD') === barLabel);
			const totalAmountOnDate = _.sumBy(paymentsOnDate, 'amount');
			data.push(totalAmountOnDate);
		});
		this.barChartData = [
			{
				data,
				label: '2019',
			},
		];

	}
}
