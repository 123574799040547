<mat-card>
	<mat-card-title>
		<div class="d-flex justify-content-between">
			<span>Tillägg</span>
			<div>
				<button mat-button (click)="accordion.closeAll()"><mat-icon>unfold_less</mat-icon></button>
				<button mat-button (click)="accordion.openAll()"><mat-icon>unfold_more</mat-icon></button>
			</div>
		</div>
	</mat-card-title>
	<mat-card-content>
		<mat-accordion multi>
			<mat-expansion-panel *ngFor="let addonGroup of addonGroups$ | async">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<b>{{addonGroup.addonId | addonName | async}}</b>
					</mat-panel-title>
					<mat-panel-description>
						<div class="w-100 mr-4 text-right">
							{{addonGroup.quantity}}st
						</div>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-card class="mb-3" *ngFor="let tourBookingAddon of addonGroup.tourBookingAddons;">
					<div class="d-flex justify-content-between">
						<span><mat-checkbox>{{tourBookingAddon.customerId | customerName | async}}</mat-checkbox></span>
						<div>
							{{tourBookingAddon.quantity}}st
						</div>
					</div>
				</mat-card>
			</mat-expansion-panel>
		</mat-accordion>
	</mat-card-content>
</mat-card>
