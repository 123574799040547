<mat-card *ngIf="{
	item: productFunctionService.itemReplay$ | async,
	customerTypes: customerTypeStore.items$ | async,
	selectedCustomerTypes: productFunctionService.selectedCustomerTypes$ | async
} as source">
	<mat-card-title>Hantering</mat-card-title>

	<form [formGroup]="productFunctionService.itemForm">
		<mat-form-field class="w-100">
			<input matInput formControlName="name" placeholder="Arbetsnamn">
		</mat-form-field>
	</form>

	<mat-card>
		<mat-card-title>Användar typer</mat-card-title>
		<mat-card-subtitle>Vilka användartyper ska funktionen räkna med</mat-card-subtitle>

		<mat-checkbox class="mr-3"
			*ngFor="let customerType of source.customerTypes"
			[checked]="source.selectedCustomerTypes?.includes(customerType.id)"
			(change)="productFunctionService.toggleCustomerType(customerType.id)">
				{{customerType.name}}
		</mat-checkbox>
	</mat-card>

</mat-card>
