import { Component, OnInit, Inject } from '@angular/core';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';

@Component({
	selector: 'app-customer-bookings',
	templateUrl: './customer-bookings.component.html',
	styleUrls: ['./customer-bookings.component.scss'],
})
export class CustomerBookingsComponent implements OnInit {
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public tourBookingStore: TourBookingStore,
		private bladeService: BladeService,
	) {
		this.tourBookingStore.reset();
		this.tourBookingStore.getAllOnCustomer(bladeItem.id).subscribe();
	}

	ngOnInit() {}
}
