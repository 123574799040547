import { Component, Inject } from '@angular/core';

import { combineLatest, of, throwError } from 'rxjs';
import * as _ from 'lodash';
import { switchMap, take, catchError } from 'rxjs/operators';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { CreateOrder } from 'app/models/order/CreateOrder';
import { Customer } from 'app/models/customer.model';
import { CustomersBladeComponent } from '../../customer/customers-blade/customers-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { CreateOrderTourBookingBladeService } from './services/create-order-tour-booking-blade.service';
import { CreateOrderModeEnum } from 'app/enums/create-order-mode.enum';
import { SelectProductBladeComponent } from '../../product/select-product-blade/select-product-blade.component';
import { Product } from 'app/models/product/product.model';
import { UserService } from 'app/admin/services/user.service';
import { SelectProductToOrderBladeComponent } from '../select-product-to-order-blade/select-product-to-order-blade.component';
import { OrderPart } from 'app/models/order/OrderPart';
import { TravelAgencyStore } from 'app/admin/stores/travel-agency.store.service';
import { UserTypeEnum } from 'app/enums/user-type.enum';

@Component({
	selector: 'app-create-order-tour-booking-blade',
	templateUrl: './create-order-tour-booking-blade.component.html',
	styleUrls: ['./create-order-tour-booking-blade.component.scss'],
	providers: [CreateOrderTourBookingBladeService],
})
@IBladeComponent.register
export class CreataOrderTourBookingBladeComponent {

	public CreateOrderModeEnum = CreateOrderModeEnum;
	public UserTypeEnum = UserTypeEnum;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public createOrderTourBookingBladeService: CreateOrderTourBookingBladeService,
		private orderStore: OrderStore,
		private bladeService: BladeService,
		public userService: UserService,
		public travelAgencyStore: TravelAgencyStore,
	) {
		this.bladeItem.callback = (item: Customer | OrderPart, type?: string) => {
			if (type === 'orderPart') {
				console.log('orderPart', JSON.stringify(item));
				this.createOrderTourBookingBladeService.setOrderPart(item as OrderPart);
			} else if (!type) {
				this.createOrderTourBookingBladeService.setCustomer(item as Customer);
			}
		};
	}

	public save() {
		this.createOrderTourBookingBladeService.save();
	}

	public selectCustomer() {
		this.bladeService.addBladeItem({
			id: 0,
			component: CustomersBladeComponent,
			minClass: BladeSize.s,
			payload: {
				travelAgencyId: +this.createOrderTourBookingBladeService.formGroup.value?.travelAgencyId,
				closeWhenCreated: true,
			},
		}, this.bladeItem);
	}

	public selectProduct() {
		this.bladeService.addBladeItem({
			id: this.bladeItem.id,
			component: SelectProductToOrderBladeComponent,
			minClass: BladeSize.m,
			payload: { mode: this.bladeItem.payload.mode },
		}, this.bladeItem);
	}

	public editOrderPart(orderPart: OrderPart) {
		this.bladeService.addBladeItem({
			id: this.bladeItem.id,
			component: SelectProductToOrderBladeComponent,
			minClass: BladeSize.m,
			payload: {
				mode: this.bladeItem.payload.mode,
				orderPart,
			},
		}, this.bladeItem);
	}
}
