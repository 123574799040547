import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ProductTextType } from 'app/models/product/product-text-type.model';
import { BaseStore } from '../base.store.service';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ProductTextTypeStore extends BaseStore<ProductTextType> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductTextTypes', baseUrl, snackBarService);
	}
}
