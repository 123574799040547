import { Component, OnInit, Inject } from '@angular/core';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { of, ReplaySubject, Subject } from 'rxjs';
import { OrderPayment } from 'app/models/order-payment.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { OrderPaymentStore } from 'app/admin/stores/order-payment.store.service';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import * as _ from 'lodash';
import { PaymentTypeStore } from 'app/admin/stores/payment-type.store.service';
import { switchMap } from 'rxjs/operators';
import { PaymentType } from 'app/models/payment-type.model';
import { OrderItem } from 'app/models/order-item.model';
import { OrderItemStore } from 'app/admin/stores/order-item.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-payment-blade',
	templateUrl: './payment-blade.component.html',
	styleUrls: ['./payment-blade.component.scss'],
})
@IBladeComponent.register
export class PaymentBladeComponent implements OnInit {
	public orderItem: OrderItem;
	public orderItems: OrderItem[];
	public isSavingSubject$ = new Subject<boolean>();
	private selectedPaymentType: PaymentType;
	public selectedPaymentTypeReplay$ = new ReplaySubject<PaymentType>(1);
	private paymentTypes: PaymentType[];
	private item: OrderPayment;
	public itemReplay$ = new ReplaySubject<OrderPayment>(1);
	public loading = true;
	public canIClose: Function | boolean;

	public itemForm: FormGroup = new FormGroup({
		paymentTypeId: new FormControl('', [Validators.required]),
		amount: new FormControl('', [Validators.required]),
		message: new FormControl(''),
		paymentReference: new FormControl(''),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: OrderPaymentStore,
		private bladePositionService: BladePositionService,
		public paymentTypeStore: PaymentTypeStore,
		private orderItemStore: OrderItemStore,
	) {
		paymentTypeStore.getAll$().pipe(untilDestroyed(this), switchMap(paymentTypes => {
			this.paymentTypes = paymentTypes;

			if (bladeItem.id) {
				return itemStore.get(bladeItem.id);
			}

			const newOrderItem = new OrderPayment();
			newOrderItem.orderId = this.bladeItem.payload?.orderId;
			newOrderItem.sourceTypeId = 2;

			return of(newOrderItem);
		})).subscribe(item => {
			this.item = item;

			this.itemForm.patchValue(this.item);

			if (item.data) {
				this.item.data = JSON.parse(item.data);
			}

			if (this.item?.paymentTypeId) {
				this.selectedPaymentType = this.paymentTypes.find(x => x.id === this.item.paymentTypeId);

				if (this.selectedPaymentType) {
					this.selectedPaymentTypeReplay$.next(this.selectedPaymentType);
				}
			}

			if (this.bladeItem.payload?.orderItem) {
				this.orderItem = this.bladeItem.payload.orderItem as OrderItem;
				this.itemForm.get('amount').setValue(this.orderItem.unitPrice * this.orderItem.quantity);
			} else {
				this.orderItemStore.getAllOnOrder(this.item.orderId).subscribe(orderItems => this.orderItems = orderItems);
			}
	
			this.itemReplay$.next(this.item);
			this.loading = false;
		});
	}

	ngOnInit() {}

	public save() {
		this.isSavingSubject$.next(true);
		this.item = _.assign(this.item, this.itemForm.value);

		if (this.item.id) {
				this.itemStore.update(this.item).subscribe(item => {
					this.item = _.assign(this.item, item);
					this.bladeItem.bladeItemComponent.closeMe();
					this.isSavingSubject$.next(false);
				});
		} else {
			this.itemStore.create(this.item).subscribe(item => {
				this.item = _.assign(this.item, item);
				this.bladeItem.bladeItemComponent.closeMe();
				this.isSavingSubject$.next(false);

				if (this.orderItem) {
					this.orderItem.totalPaid = this.item.amount;
					this.orderItem.data = JSON.stringify(this.orderItem.data);
					this.orderItemStore.update(this.orderItem).subscribe();
				}

				if (this.orderItems?.length) {
					let amountLeft = this.item.amount;
					this.orderItems.forEach(orderItem => {
						const leftToPay = orderItem.grossTotalAmount - orderItem.totalPaid;
						if (leftToPay > 0 && amountLeft > 0) {
							const maxToPay = ((amountLeft) >= leftToPay) ? leftToPay : amountLeft;
							orderItem.totalPaid += maxToPay;
							amountLeft -= maxToPay;

							this.orderItemStore.update(orderItem).subscribe();
						}
					});
				}
			});
		}
	}

	public deleteItem() {
		this.isSavingSubject$.next(true);
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
			this.isSavingSubject$.next(false);
		});
	}
}
