import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { TourGuide } from 'app/models/tour-guide.model';
import { TourGuideStore } from 'app/admin/stores/tour-guide.store.service';
import { UserStore } from 'app/admin/stores/user.store.service';
import { TourGuideTypeStore } from 'app/admin/stores/tour-guide-type.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { User } from 'app/admin/models/user.model';

@UntilDestroy()
@Component({
	selector: 'app-tour-guide-blade',
	templateUrl: './tour-guide-blade.component.html',
	styleUrls: ['./tour-guide-blade.component.scss'],
})
@IBladeComponent.register
export class TourGuideBladeComponent implements OnInit {
	public filterGuides$ = new ReplaySubject<User[]>(1);
	private guides: User[];

	public item: TourGuide;
	public loading = true;
	public canIClose: Function | boolean;
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	public itemForm: FormGroup = new FormGroup({
		guide: new FormControl('', [Validators.required]),
		guideType: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: TourGuideStore,
		private bladeService: BladeService,
		private bladePositionService: BladePositionService,
		public userStore: UserStore,
		public tourGuideTypeStore: TourGuideTypeStore,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.loading = false;
				this.itemForm.get('guide').setValue(this.item.userId);
				this.itemForm.get('guideType').setValue(this.item.tourGuideTypeId);
			});
		} else {
			this.loading = false;
			this.item = new TourGuide();
			this.item.tourId = this.bladeItem.payload.tourId;
		}

		this.userStore.items$.pipe(untilDestroyed(this)).subscribe(users => {
			this.guides = users;
			this.filterGuides$.next(_.orderBy(this.guides, ['firstName', 'lastName'], ['asc', 'asc']));
		});

		this.itemForm.get('guide').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			if (isNaN(value)) {
				this.filterGuides(value);
			} else {
				this.item.userId = value;
			}
		});
	}

	ngOnInit() {
		this.itemForm.get('guide').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			if (isNaN(value)) {
				this.filterGuides(value);
			} else {
				this.item.userId = value;
			}
		});
		this.itemForm.get('guideType').valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.item.tourGuideTypeId = value);
	}

	public displayGuideFn(id): string {
		if (!id) {
			 return '';
		}
		const selectedProduct = _.find(this.guides, product => product.id === id);
		return `${selectedProduct.firstName} ${selectedProduct.lastName}`;
	}

	private filterGuides(value?: string): void {
		const filterValue = value?.toLowerCase();
		let filterGuides = this.guides;
		if (filterValue) {
			filterGuides = _.filter(this.guides, x => x.firstName.toLocaleLowerCase().includes(filterValue) || x.lastName.toLocaleLowerCase().includes(filterValue));
		}
		this.filterGuides$.next(_.orderBy(filterGuides, ['firstName', 'lastName'], ['asc', 'asc']));
	}

	public save() {
		this.loading = true;
		if (this.item.id) {
			this.loading = false;
			this.itemStore.update(this.item).subscribe(item => {
				this.item = _.assign(this.item, item);
			});
		} else {
			this.itemStore.create(this.item).subscribe(item => {
				this.loading = false;
				this.item = _.assign(this.item, item);

				if (this.bladeItem.parent && this.bladeItem.parent.callback) {
					this.bladeItem.parent.callback();
				}
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
				if (this.bladeItem.parent && this.bladeItem.parent.callback) {
					this.bladeItem.parent.callback();
				}
			}
		});
	}
}
