import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/**
 * Order a list
 * Example: items | order:'index'"
 */
@Pipe({ name: 'order' })
export class OrderPipe implements PipeTransform {
	public transform(items: any[], property: string): any {
		return _.orderBy(items, property);
	}
}
