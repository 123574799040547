import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
	private settings = {
		closeButton: true,
		timeOut: 8000,
		extendedTimeOut: 2000,
		progressBar: true,
		enableHtml: true,
		tapToDismiss: false,
	};

	constructor(private toastr: ToastrService) { }

	showSuccess(title: string, message: string) {
		this.toastr.info(message, title, this.settings);
	}

}
