import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NewCustomerTemplateStore } from 'app/admin/stores/new-customer-template.store.service';
import { NewCustomerTemplate } from 'app/models/email/new-customer-template.model';
import { NewCustomerTemplateBladeComponent } from 'app/admin/blades/new-customer-template/new-customer-template-blade/new-customer-template-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-settings-email-template-new-customer',
	templateUrl: './settings-email-template-new-customer.component.html',
	styleUrls: ['./settings-email-template-new-customer.component.scss'],
})
export class SettingsEmailTemplateNewCustomerComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['language', 'subject', 'modified'];
	public dataSource: MatTableDataSource<NewCustomerTemplate>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: NewCustomerTemplateStore,
	) {
		this.itemStore.getAll();
		this.itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<NewCustomerTemplate>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: NewCustomerTemplateBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: NewCustomerTemplateBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}
}
