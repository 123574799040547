import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Tour } from 'app/models/tour.model';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'plannerGuideTourWarning', pure: false, })
export class PlannerGuideTourWarningPipe implements PipeTransform, OnDestroy {
	constructor() {	}
	public transform(tour: Tour): string {
		if (!tour) {
			return '-';
		}
		if (!tour.tourGuides?.length) {
			return 'text-danger';
		}
		return '-';
	}

	ngOnDestroy(): void { }
}
