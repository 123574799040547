import { Component, OnInit } from '@angular/core';
import { SpecialDietReportService } from './services/special-diet-report.service';

@Component({
	selector: 'app-special-diet-report',
	templateUrl: './special-diet-report.component.html',
	styleUrls: ['./special-diet-report.component.scss'],
	providers: [SpecialDietReportService],
})
export class SpecialDietReportComponent implements OnInit {

	constructor(public specialDietReportService: SpecialDietReportService) { }

	ngOnInit() {
	}

}
