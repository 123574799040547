import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { ProductBladeComponent } from '../product-blade/product-blade.component';
import { Product } from 'app/models/product/product.model';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, FormGroup } from '@angular/forms';

@UntilDestroy()
@Component({
	selector: 'app-products-blade',
	templateUrl: './products-blade.component.html',
	styleUrls: ['./products-blade.component.scss'],
})
@IBladeComponent.register
export class ProductsBladeComponent implements OnInit, OnDestroy {
	private products: Product[];

	public displayedColumns: string[] = ['id', 'name', 'supplierID', 'productTypeID', 'isActive'];
	public dataSource: MatTableDataSource<Product>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public itemForm: FormGroup = new FormGroup({
		showInactive: new FormControl(false),
		searchWord: new FormControl(''),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: ProductStore,
	) {
		this.itemForm.get('searchWord').valueChanges.pipe(untilDestroyed(this)).subscribe(searchWord => this.search(searchWord));

		itemStore.getAll$().pipe(untilDestroyed(this)).subscribe(products => {
			this.products = products;
			this.setData(this.products.filter(x => x.isActive === true));
		});
		this.itemForm.get('showInactive').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			if (value) {
				this.setData(this.products);
			} else {
				this.setData(this.products.filter(x => x.isActive === true));
			}
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: ProductBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: ProductBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	private search(searchWord: string) {
		let searchResult = this.products;
		if (searchWord) {
			searchWord = searchWord.toLowerCase();
			searchResult = this.products.filter(c =>
				c.name?.toLowerCase()?.includes(searchWord));
		}
		this.setData(searchResult);
	}

	private setData(products: Product[]) {
		this.dataSource = new MatTableDataSource<Product>(products);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
}
