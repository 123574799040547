import { CreateAddon } from './CreateAddon';
import { CreatePickupLocation } from './CreatePickupLocation';
import { CreateCustomerType } from './CreateCustomerType';
export class OrderPart {
	public uniqueId?: string;
	public productId?: number;
	public supplierId?: number;
	public message?: string;
	public date?: Date;
	public time?: string;
	public customerTypes?: CreateCustomerType[];
	public addons?: CreateAddon[];
	public pickupLocations?: CreatePickupLocation[];
}
