import { Component, Inject } from '@angular/core';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { OrderItem } from 'app/models/order-item.model';
import { OrderItemStore } from 'app/admin/stores/order-item.store.service';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import * as _ from 'lodash';
import { OrderBladeComponent } from '../order-blade/order-blade.component';
import { ProductBladeComponent } from '../../product/product-blade/product-blade.component';
import { AddonBladeComponent } from '../../addon/addon-blade/addon-blade.component';
import { PickupLocationBladeComponent } from '../../pickup-location/pickup-location-blade/pickup-location-blade.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductTypeStore } from 'app/admin/stores/product/product-type.store.service';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { AddonStore } from 'app/admin/stores/addon.store.service';
import { PickupLocationStore } from 'app/admin/stores/pickup-location.store.service';
import * as moment from 'moment';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { PaymentBladeComponent } from '../../payment/payment-blade/payment-blade.component';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';

@UntilDestroy()
@Component({
	selector: 'app-order-item-blade',
	templateUrl: './order-item-blade.component.html',
	styleUrls: ['./order-item-blade.component.scss'],
})
@IBladeComponent.register
export class OrderItemBladeComponent {
	public OrderRowTypeEnum = OrderRowTypeEnum;

	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort orderrader: "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];
	public units = [
		'pcs',
		'group',
	];

	public orderRowTypes: OrderRowType[] = [
		{
			name: 'Kund',
			orderRowTypeEnum: OrderRowTypeEnum.CustomerType,
		},
		{
			name: 'Tillägg',
			orderRowTypeEnum: OrderRowTypeEnum.Addon,
		},
		{
			name: 'Upphämtning',
			orderRowTypeEnum: OrderRowTypeEnum.PickupLocation,
		},
	];
	public item: OrderItem;
	public loading = true;
	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		selectedOrderRowType: new FormControl(null, [Validators.required]),
		productTypeId: new FormControl(0, [Validators.required]),
		customerTypeId: new FormControl(0, [Validators.required]),
		productId: new FormControl(0),
		addonId: new FormControl(0),
		pickupLocationId: new FormControl(0),
		date: new FormControl(null),
		time: new FormControl(null),
		quantity: new FormControl(0),
		unit: new FormControl(0),
		unitPrice: new FormControl(0),
		taxRate: new FormControl(0),
		taxAmount: new FormControl(0),
		netTotalAmount: new FormControl(0),
		grossTotalAmount: new FormControl({ value: 0, disabled: true}),
		totalPaid: new FormControl({ value: 0}),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: OrderItemStore,
		private bladePositionService: BladePositionService,
		private bladeService: BladeService,
		public productTypeStore: ProductTypeStore,
		public customerTypeStore: CustomerTypeStore,
		public productStore: ProductStore,
		public addonStore: AddonStore,
		public pickupLocationStore: PickupLocationStore,

	) {
		if (this.bladeItem.id) {
			itemStore.get(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(item => {
				if (item) {
					this.item = item;
					this.loading = false;
					this.setValue();
				}
			});
		} else {
			this.loading = false;
			this.item = new OrderItem();
			this.item.orderId = this.bladeItem.payload.orderId;
		}

		this.itemForm.get('quantity').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			if (this.itemForm.value.unit === 'group') {
				return;
			}
			this.itemForm.get('grossTotalAmount').setValue(value * this.itemForm.value.unitPrice);
		});
		this.itemForm.get('unitPrice').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			if (this.itemForm.value.unit === 'group') {
				return;
			}
			this.itemForm.get('grossTotalAmount').setValue(this.itemForm.value.quantity * value);
		});
		this.itemForm.get('unit').valueChanges.pipe(untilDestroyed(this)).subscribe(unit => {
			if (unit === 'group') {
				this.itemForm.get('grossTotalAmount').enable();
			} else {
				this.itemForm.get('grossTotalAmount').disable();
			}
		});

		this.itemStore.changedItem$.pipe(untilDestroyed(this)).subscribe(orderItem => {
			if (orderItem as OrderItem && (orderItem as OrderItem).id === this.item.id) {
				this.item = orderItem as OrderItem;
				this.setValue();
			}
		});
	}

	public openOrder() {
		this.bladeService.addBladeItem({
			id: this.item.orderId,
			component: OrderBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public openProduct() {
		this.bladeService.addBladeItem({
			id: this.item.productId,
			component: ProductBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}
	public openAddon() {
		this.bladeService.addBladeItem({
			id: this.item.addonId,
			component: AddonBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}
	public openPickupLocation() {
		this.bladeService.addBladeItem({
			id: this.item.pickupLocationId,
			component: PickupLocationBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public openPayment() {
		this.bladeService.addBladeItem({
			id: 0,
			component: PaymentBladeComponent,
			minClass: BladeSize.s,
			payload: {
				orderItem: this.item,
				orderId: this.item.orderId,
			},
		}, this.bladeItem);
	}

	public save() {
		this.loading = true;
		const value = this.itemForm.getRawValue();
		this.item = _.assign(this.item, value);
		this.item.totalPaid = value.totalPaid.value;

		const date = this.itemForm.value.date;
		const time = this.itemForm.value.time;

		if (date || time) {
			const data = {
				date: this.itemForm.value.date,
				time: this.itemForm.value.time,
			};

			this.item.data = JSON.stringify(data);
		}

		if (this.item.id) {
			this.itemStore.update(this.item).subscribe(item => {
				this.loading = false;
				this.item = _.assign(this.item, item);
			});
		} else {
			this.item.orderId = this.bladeItem.payload.orderId;
			this.itemStore.create(this.item).subscribe(item => {
				this.loading = false;
				this.item = _.assign(this.item, item);

				this.setValue();

				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	private setValue() {
		this.itemForm.patchValue(this.item);
		if (this.item.data !== '') {
			this.item.data = JSON.parse(this.item.data);
		}
		if (this.item.data) {
			this.itemForm.get('date').setValue(moment(this.item.data.date).format('YYYY-MM-DD'));
			this.itemForm.get('time').setValue(this.item.data.time);
		}
		if (this.item.addonId) {
			this.itemForm.get('selectedOrderRowType').setValue(OrderRowTypeEnum.Addon);
		} else if (this.item.pickupLocationId) {
			this.itemForm.get('selectedOrderRowType').setValue(OrderRowTypeEnum.PickupLocation);
		} else {
			this.itemForm.get('selectedOrderRowType').setValue(OrderRowTypeEnum.CustomerType);
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}

enum OrderRowTypeEnum {
	CustomerType,
	Addon,
	PickupLocation,
}

interface OrderRowType {
	name: string;
	orderRowTypeEnum: OrderRowTypeEnum;
}
