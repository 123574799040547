import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';

@Component({
	selector: 'app-emails-blade',
	templateUrl: './emails-blade.component.html',
	styleUrls: ['./emails-blade.component.scss'],
})
@IBladeComponent.register
export class EmailsBladeComponent implements OnInit {
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private bladePositionService: BladePositionService,
	) { }

	ngOnInit() {}

}
