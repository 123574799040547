
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { NewOrderDraftTemplate } from 'app/models/email/new-order-draft-template.model';

@Injectable({
	providedIn: 'root',
})
export class NewOrderDraftTemplateStore extends BaseStore<NewOrderDraftTemplate> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Email', baseUrl, snackBarService);
		this.host = `${baseUrl}api/email/newOrderDraftTemplate`;
	}
}
