import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-tour-pickup',
	templateUrl: './tour-pickup.component.html',
	styleUrls: ['./tour-pickup.component.scss'],
})
export class TourPickupComponent implements OnInit {
	constructor() { }

	ngOnInit() { }
}
