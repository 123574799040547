<app-blade-item [canIClose]="!itemForm.dirty" [loading]="loading" [id]="item?.id">
	<app-blade-header [title]="(item?.id) ? 'Pris' : 'Ny'" [subTitle]="'ProduktPris'"></app-blade-header>
	<app-blade-content *ngIf="item">
		<h3>Pris för {{item.customerTypeId | customerTypeName | async}}</h3>
		<app-alert-card [mode]="'info'">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam placeat possimus laborum expedita, nihil fuga id, officia nam repellendus laboriosam aut voluptate est fugiat recusandae veniam eaque soluta similique officiis!</app-alert-card>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="price" placeholder="Pris">
			</mat-form-field>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<button *ngIf="item?.id" mat-raised-button color="warn" (click)="remove()">
			Radera
		</button>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
