import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TermStore } from 'app/admin/stores/term.store.service';
import { Term } from 'app/models/term.model';
import * as _ from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable()
export class TermBladeService {
	public selectedIndex = 1;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		termTypeId: new FormControl('', [Validators.required]),
	});

	private term: Term;
	private termReplay = new ReplaySubject<Term>();
	public termReplay$ = this.termReplay.asObservable();

	private isLoadingBehavior = new BehaviorSubject<boolean>(true);
	public isLoadingBehavior$ = this.isLoadingBehavior.asObservable();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: TermStore,
		private bladePositionService: BladePositionService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.term = item;
				this.termReplay.next(this.term);
				this.itemForm.patchValue(this.term);
				this.isLoadingBehavior.next(false);
			});
		} else {
			this.term = new Term();
			this.termReplay.next(this.term);
			this.isLoadingBehavior.next(false);
		}
	}

	public save() {
		this.isLoadingBehavior.next(true);
		this.term = Object.assign(this.term, this.itemForm.value);

		if (this.term.id) {
				this.itemStore.update(this.term).subscribe(term => {
					this.isLoadingBehavior.next(false);
					this.term = _.assign(this.term, term);
				});
		} else {
			this.itemStore.create(this.term).subscribe(term => {
				this.isLoadingBehavior.next(false);
				this.term = _.assign(this.term, term);
				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	public deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.isLoadingBehavior.next(true);
		this.itemStore.delete(this.term.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
			this.isLoadingBehavior.next(false);
		});
	}

	public setLoading(isLoading: boolean) {
		this.isLoadingBehavior.next(isLoading);
	}
}
