import { Component, Inject } from '@angular/core';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { PageStore } from 'app/admin/stores/page.store.service';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { PageTypeStore } from 'app/admin/stores/page-type.store.service';
import { OddFunctionService } from './services/odd-function.service';

@Component({
	selector: 'app-odd-function-blade',
	templateUrl: './odd-function-blade.component.html',
	styleUrls: ['./odd-function-blade.component.scss'],
	providers: [OddFunctionService],
})
@IBladeComponent.register
export class OddFunctionBladeComponent {

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: PageStore,
		private bladePositionService: BladePositionService,
		public pageTypeStore: PageTypeStore,
		private bladeService: BladeService,
		public productFunctionService: OddFunctionService,
	) { }

	// public save() {
	// 	this.loading = true;
	// 	this.saveOrUpdate().subscribe(item => {
	// 		this.item = _.assign(this.item, item);
	// 		this.bladeItem.minClass = BladeSize.m;
	// 		this.bladeItem.maxClass = BladeSize.m;
	// 		this.bladeItem.activeClass = this.bladeItem.maxClass;
	// 		this.bladePositionService.selectBladeItem(this.bladeItem, true);
	// 		this.loading = false;
	// 		this.itemForm.markAsPristine();
	// 	});
	// }

	// private saveOrUpdate(): Observable<Page> {
	// 	if (this.item.id > 0) {
	// 		return this.itemStore.update(this.item);
	// 	} else {
	// 		return this.itemStore.create(this.item);
	// 	}
	// }
}
