
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { SupplierResourceProductType } from 'app/models/supplier-resource-product-type.model';

@Injectable({
	providedIn: 'root',
})
export class SupplierResourceProductTypeStore extends BaseStore<SupplierResourceProductType> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SupplierResourceProductType', baseUrl, snackBarService);
	}
}
