import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { SpecialDietReportService } from '../../services/special-diet-report.service';
import { switchMap } from 'rxjs/operators';
import { TourBookingSpecialDiet } from 'app/models/tour-booking-special-diet.model';
import * as _ from 'lodash';
import { CustomerType } from '../../../../../../../models/customer-type.model';
import { CustomerTypeStore } from '../../../../../../stores/customer-type.store.service';
import { Tour } from 'app/models/tour.model';

@Component({
	selector: 'app-special-diet-report-customer-table',
	templateUrl: './special-diet-report-on-customer-table.component.html',
	styleUrls: ['./special-diet-report-on-customer-table.component.scss'],
})
export class SpecialDietReportOnCustomerComponent implements OnInit {
	public showMessages = true;
	public displayedColumns: string[];
	public specialDietOnTour$: Observable<SpecialDietOnCustomer>;
	constructor(
		private specialDietReportService: SpecialDietReportService,
		private customerTypeStore: CustomerTypeStore,
	) {
		this.specialDietOnTour$ = combineLatest([
			this.specialDietReportService.specialDietSummary$,
			this.customerTypeStore.items$,
		]).pipe(switchMap(([specialDietOnTours, customerTypes]) => {
			this.displayedColumns = ['customer'];
			const specialDietOnTour: SpecialDietOnCustomer = { customers: [], specialDietIds: [] };
			const allTourBookingSpecialDiets = _.flatMap(specialDietOnTours, x => x.tourBookingSpecialDiets);
			let index = 1;
			_.forOwn(_.groupBy(allTourBookingSpecialDiets, x => x.groupName), (value, key) => {
				const customer: any = {index};
				index++;
				const customerTypeIdentifier = key.split('_')[0];
				const customerType = _.filter(customerTypes, x => x.identifier === customerTypeIdentifier);
				customer.customerType = customerType;
				customer.specialDiets = this.getAllSpecialDietDetails(value);
				customer.tours = this.getAllTours(key, _.flatMap(specialDietOnTours, x => x.tour));
				specialDietOnTour.customers.push(customer);
			});

			this.setSpecialDietIds(specialDietOnTour);

			specialDietOnTour.specialDietIds.forEach(specialDietId => {
				this.displayedColumns.push('specialDiet-' + specialDietId);
			});

			this.displayedColumns.push('tourName');

			return of(specialDietOnTour);
		}));
	}

	ngOnInit() {
	}

	private getAllSpecialDietDetails(tourBookingSpecialDiets: TourBookingSpecialDiet[]): any {
		const specialDiets = {};

		tourBookingSpecialDiets.forEach(diet => {
			if (!specialDiets[diet.specialDietId]) {
				specialDiets[diet.specialDietId] = {
					specialDietId: diet.specialDietId,
					messages: [],
				};
			}
			if (diet.extraInfo && !specialDiets[diet.specialDietId].messages.includes(diet.extraInfo)) {
				specialDiets[diet.specialDietId].messages.push(diet.extraInfo);
			}
		});
		return specialDiets;
	}

	private setSpecialDietIds(specialDietOnTour: SpecialDietOnCustomer): any {
		const specialDietSum = {};
		specialDietOnTour.specialDietIds = [];
		specialDietOnTour.customers.forEach(customer => {
			_.forOwn(customer.specialDiets, (value, key) => {
				if (!specialDietSum[value.specialDietId]){
					specialDietSum[value.specialDietId] = {
						id: value.specialDietId,
					};
					specialDietOnTour.specialDietIds.push(value.specialDietId);
				}
			});
		});
	}

	private getAllTours(groupName: string, tours: Tour[]): number[] {
		const tourIds: any[] = [];

		tours.forEach(tour => {
			let add = false;
			tour.tourBookings.forEach(tourBooking => {
				if (_.find(tourBooking.tourBookingSpecialDiets, x => x.groupName === groupName)) {
					add = true;
				}
			});

			if (add) {
				tourIds.push({
					id: tour.id,
					date: tour.date,
					time: tour.time,
				});
			}
		});

		return tourIds;
	}
}


interface SpecialDietOnCustomer {
	customers?: {
		index?: number,
		customerType?: CustomerType,
		specialDiets?: {
			[id: number]: {
				specialDietId: number,
				messages: string[],
			},
		},
		tours: {
			id: number,
			date: Date,
			time: string,
		}[],
	}[];
	specialDietIds: number[];
}
