<table>
	<thead>
		<tr>
			<th>Klocka</th>
			<th class="pl-3">Platser</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let time of productDateTimes">
			<td>{{time.time}}</td>
			<td class="pl-3">{{time.quantity}}</td>
		</tr>
	</tbody>
</table>
