import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';
import { Subject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourBooking } from 'app/models/tour-booking.model';
import { Order } from 'app/models/order.model';
import { OrderStore } from 'app/admin/stores/order.store.service';

@Component({
	selector: 'app-tour-booking-blade',
	templateUrl: './tour-booking-blade.component.html',
	styleUrls: ['./tour-booking-blade.component.scss'],
})
@IBladeComponent.register
export class TourBookingBladeComponent implements OnInit {
	public selectedIndex = 1;
	public item: TourBooking;
	public order: Order;
	public loading = true;
	public canIClose: () => {} | boolean;
	public createSubject = new Subject<void>();
	public menuItems: BladeMenuItem[] = [
		{ title: 'Generellt', index: 1 },
		{ title: 'Bokningar', index: 2 },
		{ title: 'Kund', index: 3 },
		{ title: 'Order', index: 4 },
		{ title: 'Meddelanden', index: 5 },
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: TourBookingStore,
		private orderStore: OrderStore,
		private bladeService: BladeService,
		private bladePositionService: BladePositionService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).pipe(switchMap(tour => {
				this.item = tour;
				if (tour.orderId) {
					return this.orderStore.get(tour.orderId);
				} else {
					return of({});
				}
			})).subscribe(item => {
				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new TourBooking();
		}
	}

	ngOnInit() {}

	public create() {
		if (!this.item.id) {
			this.createSubject.next();
		}
	}

	public save() {
		this.loading = true;
		this.itemStore.update(this.item).subscribe(item => {
			this.loading = false;
			this.item = _.assign(this.item, item);
		});
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
