import { Injectable, Injector } from '@angular/core';
import { CustomerProgramPageService } from '../../../services/customer-program-page.service';
import { ReplaySubject, combineLatest, Observable, of } from 'rxjs';
import { Tour } from '../../../../../../../../models/tour.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import _find from 'lodash-es/find';
import { TourBooking } from '../../../../../../../../models/tour-booking.model';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { CustomerType } from 'app/models/customer-type.model';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import { TourBookingPickupLocation } from 'app/models/tour-booking-pickup-location.model';
import * as moment from 'moment';
import { TourBookingAddon } from 'app/models/tour-booking-addon.model';
import { Order } from 'app/models/order.model';
import { ActivatedRoute } from '@angular/router';
import { AdminDataService } from 'app/modules/admin-data/services/admin-data.service';
import { map, switchMap } from 'rxjs/operators';
import { PickupLocationStore } from '../../../../../../../stores/pickup-location.store.service';
import { TourBookingSpecialDiet } from 'app/models/tour-booking-special-diet.model';

@UntilDestroy()
@Injectable()
export class CustomerProgramTourService {
	public specialDiets$ = new ReplaySubject<TourBookingSpecialDietExtended[]>();
	public showTotalPrice$ = new ReplaySubject<boolean>();
	public showInternalMessage$ = new ReplaySubject<boolean>();
	public showCustomerMessage$ = new ReplaySubject<boolean>();
	public showPriceDetails$ = new ReplaySubject<boolean>();
	public showAddon$ = new ReplaySubject<boolean>();
	public showPickup$ = new ReplaySubject<boolean>();
	public showSpecialDiet$ = new ReplaySubject<boolean>();
	public language$: Observable<any>;
	public extendedPickup$: Observable<ExtendedPickup>;
	
	private tourBookings: TourBooking[];
	private tourId$ = new ReplaySubject<number>(1);
	public tour$ = new ReplaySubject<Tour>(1);
	public order$ = new ReplaySubject<Order>(1);
	private extendedBooking = {} as ExtendedBooking;
	public extendedBooking$ = new ReplaySubject<ExtendedBooking>(1);

	constructor(
		public customerProgramPageService: CustomerProgramPageService,
		private injector: Injector,
		private customerTypeStore: CustomerTypeStore,
		private route: ActivatedRoute,
		private adminDataService: AdminDataService,
		private pickupLocationStore: PickupLocationStore,
	) {
		this.language$ = this.route.queryParams.pipe(untilDestroyed(this), switchMap(params => {
			return this.adminDataService.avaiableLanguages$.pipe(map(allLanguages => {
				return _find(allLanguages, lang => lang.id === +params.languageId);
			}));
		}));


		combineLatest([
			this.tourId$,
			this.customerProgramPageService.customer$,
			this.customerTypeStore.items$,
			this.route.queryParams,
		]).pipe(untilDestroyed(this)).subscribe(([tourId, customer, customerTypes, params]) => {
			this.showPriceDetails$.next(params.showPriceDetails === 'true');
			this.showInternalMessage$.next(params.showInternalMessage === 'true');
			this.showCustomerMessage$.next(params.showCustomerMessage === 'true');
			this.showTotalPrice$.next(params.showTotalPrice === 'true');
			this.showAddon$.next(params.showAddon === 'true');
			this.showPickup$.next(params.showPickup === 'true');
			this.showSpecialDiet$.next(params.showSpecialDiet === 'true');

			this.tourBookings = _.filter(customer.tourBookings, x => x.tour?.id === tourId);
			this.tour$.next(_.find(this.tourBookings, x => !!x.tour)?.tour);
			const order = _.find(customer.orders, o => o.id === this.tourBookings[0]?.orderId);
			this.order$.next(order);
			this.tourBookings.forEach(tourBooking => {
				this.handleTourBooking(this.extendedBooking, tourBooking, customerTypes);
			});

			this.handleSpecialDiet(_.flatMap(this.tourBookings, x => x.tourBookingSpecialDiets), customerTypes);

			this.calculatePrice(this.extendedBooking);

			this.extendedBooking$.next(this.extendedBooking);
			this.customerProgramPageService.addTotalAmount(this.extendedBooking.totalAmount);
		});

		this.extendedPickup$ = combineLatest([
			this.language$,
			this.tour$,
			this.extendedBooking$.pipe(untilDestroyed(this), switchMap(booking => combineLatest([
				this.pickupLocationStore.getFull(booking.pickupLocationId),
				of(booking),
			]))),
		]).pipe(untilDestroyed(this), switchMap(([language, tour, [pickupLocation, booking]]) => {
			const text = pickupLocation.pickupLocationTexts.find(x => x.languageId === language.id);
			const extendedPickup = {} as ExtendedPickup;
			extendedPickup.id = pickupLocation.id;
			extendedPickup.headline = text?.name;
			extendedPickup.text = text?.description;

			const relativetimeToUse = booking.relativeTime;

			const tourDateTime = moment(`${moment(tour.date).format('YYYY-MM-DD')} ${tour.time}`);
			extendedPickup.relativeTime = tourDateTime.add(relativetimeToUse, 'minute').format('HH:mm');

			return of(extendedPickup);
		}));
	}

	public setTourId(tourId: number) {
		this.tourId$.next(tourId);
	}

	private handleTourBooking(extendedBooking: ExtendedBooking, tourBooking: TourBooking, customerTypes: CustomerType[]) {
		const firstCustomerTypeOrderItem = tourBooking.tourBookingCustomerTypes?.find(x => !!x.orderItem);

		extendedBooking.unit = firstCustomerTypeOrderItem?.orderItem?.unit ?? 'pcs';
		extendedBooking.bookingStatusId = tourBooking.bookingStatusId;
		extendedBooking.message = tourBooking.message;
		if (tourBooking.tourBookingPickupLocations?.length) {
			extendedBooking.relativeTime = tourBooking.tourBookingPickupLocations[0]?.relativeTime;
		}

		// if (firstCustomerTypeOrderItem?.orderItem?.unit === 'group') {
		// const totalQuantity = _.sum(tourBooking.tourBookingCustomerTypes?.map(x => x?.orderItem?.grossTotalAmount ?? 0));
		// extendedBooking.totalAmount = totalQuantity ?? 0;
		// }

		customerTypes.forEach(customerType => {
			const tourBookingCustomerTypes = tourBooking.tourBookingCustomerTypes.filter(x => x.customerTypeId === customerType.id);
			const tourBookingPickupLocations = tourBooking.tourBookingPickupLocations.filter(x => x.customerTypeId === customerType.id);
			this.handleCustomerType(extendedBooking, tourBookingCustomerTypes, tourBookingPickupLocations, customerType);
		});

		tourBooking.tourBookingAddons.forEach(tourBookingAddon => {
			this.handleAddon(extendedBooking, tourBookingAddon);
		});

		extendedBooking.pickupLocationId = tourBooking.tourBookingPickupLocations?.[0]?.pickupLocationId ?? 0;
	}

	private handleCustomerType(
		extendedBooking: ExtendedBooking,
		tourBookingCustomerTypes: TourBookingCustomerType[],
		tourBookingPickupLocations: TourBookingPickupLocation[],
		customerType: CustomerType,
	): void {
		const quantity = _.sum(tourBookingCustomerTypes?.map(x => x.quantity));
		if (!quantity) {
			return;
		}

		const firstCustomerTypeOrderItem = tourBookingCustomerTypes?.find(x => !!x.orderItem);
		const firstPickupLocationOrderItem = tourBookingPickupLocations?.find(x => !!x.orderItem);

		const bookingCustomerType = {
			customerTypeId: customerType.id,
			quantity,
			price: firstCustomerTypeOrderItem?.orderItem?.unitPrice ?? 0,
			pickupPrice: firstPickupLocationOrderItem?.orderItem?.unitPrice ?? 0,
			totalAmount: (firstCustomerTypeOrderItem?.orderItem?.grossTotalAmount ?? 0) + (firstPickupLocationOrderItem?.orderItem?.grossTotalAmount ?? 0),
			customerTypeAges: this.handleCustomerTypeAges(tourBookingCustomerTypes),
		};

		const find = _.find(extendedBooking.customerTypes, x => x.customerTypeId === bookingCustomerType.customerTypeId);
		if (find) {
			// Append
			find.quantity += bookingCustomerType.quantity;
			find.price = (find.price + bookingCustomerType.price) / find.quantity;
			find.pickupPrice += (find.pickupPrice + bookingCustomerType.pickupPrice) / find.quantity;
			find.totalAmount += bookingCustomerType.totalAmount + find.pickupPrice;
			find.customerTypeAges.push(...bookingCustomerType.customerTypeAges);
		} else {
			if (!extendedBooking.customerTypes?.length) {
				extendedBooking.customerTypes = [];
			}
			extendedBooking.customerTypes.push(bookingCustomerType);
		}

	}

	private handleCustomerTypeAges(tourBookingCustomerTypes: TourBookingCustomerType[]): any[] {
		const ages = [];
		tourBookingCustomerTypes?.forEach(tourBookingCustomerType => {
			tourBookingCustomerType.tourBookingCustomerTypeAges?.forEach(tourBookingCustomerTypeAge => {
				ages.push({
					birthDate: moment(tourBookingCustomerTypeAge.birthDate ?? '').format('YYYY-MM-DD'),
				});
			});
		});
		return ages;
	}

	private handleAddon(extendedBooking: ExtendedBooking, tourBookingAddon: TourBookingAddon): void {
		if (!extendedBooking.addons?.length) {
			extendedBooking.addons = [];
		}
		extendedBooking.addons.push({
			addonId: tourBookingAddon.addonId,
			quantity: tourBookingAddon.quantity,
			price: tourBookingAddon?.orderItem?.unitPrice ?? 0,
			totalAmount: tourBookingAddon?.orderItem?.grossTotalAmount ?? 0,
		});
	}

	private handleSpecialDiet(tourBookingSpecialDiets: TourBookingSpecialDiet[], customerTypes: CustomerType[]): void {
		const specialDiets: TourBookingSpecialDietExtended[] = [];

		_.forOwn(_.groupBy(tourBookingSpecialDiets, x => x.groupName), (value, key, object) => {
			const customerType = _.find(customerTypes, c => c.identifier === key.split('_')[0]);
			specialDiets.push({
				customerTypeId: customerType.id,
				tourBookingSpecialDiets: value,
			});
		});
		this.specialDiets$.next(specialDiets);
	}

	private calculatePrice(extendedBooking: ExtendedBooking): void {
		extendedBooking.totalAmount = 0;
		extendedBooking.customerTypes?.forEach(customerType => {
			extendedBooking.totalAmount += customerType.totalAmount;
		});

		extendedBooking.addons?.forEach(addon => {
			extendedBooking.totalAmount += addon.totalAmount;
		});
	}
}

interface ExtendedPickup {
	id: number;
	headline: string;
	text: string;
	relativeTime: string;
}

interface ExtendedBooking {
	unit: string;
	bookingStatusId: number;
	totalAmount: number;
	pickupLocationId: number;
	relativeTime: number;
	message: string;
	customerTypes: {
		customerTypeId: number;
		quantity: number;
		price: number;
		pickupPrice: number;
		totalAmount: number;
		customerTypeAges: {
			tourBookingCustomerTypeId: number,
			birthDate: string,
		}[];
	}[];
	addons: {
		addonId: number,
		quantity: number,
		price: number,
		totalAmount: number,
	}[];
}

interface TourBookingSpecialDietExtended {
	customerTypeId: number;
	tourBookingSpecialDiets: TourBookingSpecialDiet[];
}
