import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TravelAgency } from 'app/models/travel-agency.model';
import { TravelAgencyStore } from 'app/admin/stores/travel-agency.store.service';

@Component({
	selector: 'app-travel-agency-blade',
	templateUrl: './travel-agency-blade.component.html',
	styleUrls: ['./travel-agency-blade.component.scss'],
})
@IBladeComponent.register
export class TravelAgencyBladeComponent implements OnInit {
	public selectedIndex = 1;
	public item: TravelAgency;
	public loading = true;
	public canIClose: () => {} | boolean;
	public menuItems: BladeMenuItem[] = [
		{ title: 'Generellt', index: 1 },
		{ title: 'Tur-Bokningar', index: 2 },
		{ title: 'Ordrar', index: 3 },
		{ title: 'Kunder', index: 4 },
		{ title: 'Användare', index: 5 },
		{ title: 'Rabatter', index: 6 },
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: TravelAgencyStore,
		private bladePositionService: BladePositionService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new TravelAgency();
		}
	}

	ngOnInit() {}

	public save() {
		this.loading = true;
		if (this.item.id) {
			this.itemStore.update(this.item).subscribe(product => {
				this.loading = false;
				this.item = _.assign(this.item, product);
			});
		} else {
			this.itemStore.create(this.item).subscribe(product => {
				this.loading = false;
				this.item = _.assign(this.item, product);
				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
