import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import {
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { TermText } from 'app/models/term-text.model';
import { TermTextStore } from 'app/admin/stores/term-text.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-term-text-blade',
	templateUrl: './term-text-blade.component.html',
	styleUrls: ['./term-text-blade.component.scss'],
})
@IBladeComponent.register
export class TermTextBladeComponent implements OnInit {
	public loading = true;
	public item: TermText;

	public itemForm: FormGroup = new FormGroup({
		headline: new FormControl('', [Validators.required]),
		description: new FormControl('', []),
		text: new FormControl('', []),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: TermTextStore,
	) {
		if (
			bladeItem.payload &&
			bladeItem.payload.termId &&
			bladeItem.payload.languageId
		) {
			itemStore
				.getOnTermAndLanguage(
					+bladeItem.payload.termId,
					+bladeItem.payload.languageId,
				)
				.pipe(untilDestroyed(this))
				.subscribe(item => {
					this.item = item;
					if (!this.item) {
						this.item = new TermText();
						this.item.termId = +bladeItem.payload.termId;
						this.item.languageId = +bladeItem.payload.languageId;
					}
					this.itemForm.setValue({
						headline: this.item.headline || '',
						description: this.item.description || '',
						text: this.item.text || '',
					});

					this.loading = false;
				});
		} else {
			console.error('Missing termId and/or languageId');
		}
	}

	ngOnInit() {
		this.itemForm.get('headline').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.headline = value;
		});
		this.itemForm.get('description').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.description = value;
		});
		this.itemForm.get('text').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.text = value;
		});
	}

	public save() {
		this.loading = true;
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<TermText> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

}
