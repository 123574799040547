
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from '../base.store.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { SnackBarService } from '../../services/snack-bar.service';
import { ProductPickupLocationPrice } from 'app/models/product/product-pickup-location-price.model';

@Injectable({
	providedIn: 'root',
})
export class ProductPickupLocationPriceStore extends BaseStore<ProductPickupLocationPrice> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductPickupLocationPrice', baseUrl, snackBarService);
	}

	public getAllOnProductPickupLocation$(productPickupLocationId: number): Observable<ProductPickupLocationPrice[]> {
		return this.http.get<ProductPickupLocationPrice[]>(`${this.host}/productPickupLocation/${productPickupLocationId}`);
	}

	public addPickupLocationPrice(productPickupLocationPrice: ProductPickupLocationPrice) {
		this._items.push(productPickupLocationPrice);
		this._itemsSubject.next(this._items);
	}

	public removePickupLocationPrice(productPickupLocationPrice: ProductPickupLocationPrice) {
		_.remove(this._items, item => item.customerTypeId === productPickupLocationPrice.customerTypeId && !item.price);
		this._itemsSubject.next(this._items);
	}
}
