import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { TourBookingCustomerTypeAge } from 'app/models/tour-booking-customer-type-age.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TourBookingCustomerTypeAgeStore extends BaseStore<TourBookingCustomerTypeAge> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TourBookingCustomerTypeAge', baseUrl, snackBarService);
	}

	public getAllOnTourBookingCustomerType$(tourBookingCustomerTypeId: number): Observable<TourBookingCustomerTypeAge[]> {
		return this.http.get<TourBookingCustomerTypeAge[]>(`${this.host}/tourBookingCustomerType/${tourBookingCustomerTypeId}`);
	}
}
