<form [formGroup]="itemForm">
	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Typ</mat-label>
		<mat-select formControlName="formBuilderElementType">
			<mat-option *ngFor="let type of formBuilderElementTypes" [value]="type.identifier">
				{{type.name}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Namn</mat-label>
		<input matInput formControlName="label" placeholder="Namn">
	</mat-form-field>
	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Frivillig beskrivning</mat-label>
		<textarea matInput formControlName="description" placeholder="Frivillig beskrivning"></textarea>
	</mat-form-field>
	<button mat-raised-button color="warn">Ta bort</button>
</form>
