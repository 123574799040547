<app-blade-item [canIClose]="!itemForm.dirty" [loading]="loading" [id]="item?.id">
	<app-blade-header [title]="'SiteData'" [subTitle]="'SiteData på ' + (bladeItem.payload.languageId | languageName | async)"></app-blade-header>
	<app-blade-content *ngIf="item">
		<app-alert-card [mode]="'info'">Tänk på att bara ändra värdet, aldrig propertyn. Då kommer texten aldrig skrivas ut på hemsidan.</app-alert-card>
		<form [formGroup]="itemForm">
			<mat-label>Texter som skrivs ut på eran hemsida!</mat-label>
			<json-editor [options]="editorOptions" formControlName="text"></json-editor>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
