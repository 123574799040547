<mat-card>
	<mat-card-title>Rabatter</mat-card-title>
	<mat-card-content>
		<table mat-table [dataSource]="dataSource" matSort class="w-100">
			<ng-container matColumnDef="product">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Produkt</th>
				<td mat-cell *matCellDef="let item">{{item.productId | productName | async}}</td>
			</ng-container>
			<ng-container matColumnDef="discount">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Rabatt</th>
				<td mat-cell *matCellDef="let item">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Rabatt i procent</mat-label>
						<input matInput type="number" min="0" max="100" [disabled]="item.loading" (keyup.enter)="setDiscount(item)" [(ngModel)]="item.discount">
					</mat-form-field>
				</td>
			</ng-container>
			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let item" class="text-right">
					<button mat-stroked-button [disabled]="item.loading || (!item.discount && item.discount !== 0)" (click)="setDiscount(item)">Ändra</button>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
		</table>

		<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	</mat-card-content>
</mat-card>
