<mat-card *ngIf="{
	addons: addonStore.items$ | async,
	item: productFunctionService.itemReplay$ | async,
	selectedAddons: productFunctionService.selectedAddons$ | async
} as source">
	<mat-card-title>Tillägg</mat-card-title>
	<mat-card-subtitle>Välj vilka tillägg som möjliga för att uppnå kravet.</mat-card-subtitle>

	<form [formGroup]="productFunctionService.itemForm">
		<mat-checkbox formControlName="requireAddon">Krävs tillägg när det är udda antal</mat-checkbox>
		<div class="mt-3 pt-3 border-top">
			<p>Tillägg</p>
			<mat-checkbox class="mr-3 d-block"
				*ngFor="let addon of source.addons"
				[checked]="source.selectedAddons?.includes(addon.id)"
				(change)="productFunctionService.toggleAddon(addon.id)">
					{{addon.name}}
			</mat-checkbox>
		</div>
	</form>

</mat-card>
