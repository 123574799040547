<app-blade-item [canIClose]="true" [loading]="loading" [id]="item?.id">
    <app-blade-header [title]="item?.name||'Ny'" [subTitle]="'Etage'" [menuItems]="headerMenuItems"></app-blade-header>
	<!-- Edit etage content -->
    <app-blade-content *ngIf="item?.id" [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
        <ng-container [ngSwitch]="selectedIndex">
            <ng-container *ngSwitchCase="1">
				<app-etage-blade-generally [item]="item"></app-etage-blade-generally>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                <app-etage-blade-columns></app-etage-blade-columns>
            </ng-container>
            <ng-container *ngSwitchCase="3">
                <app-etage-blade-styles></app-etage-blade-styles>
            </ng-container>
            <ng-container *ngSwitchCase="4">
                <app-etage-blade-background></app-etage-blade-background>
            </ng-container>
        </ng-container>
	</app-blade-content>
	<!-- New etage content -->
	<app-blade-content *ngIf="!item?.id">
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="name" placeholder="Arbetsnamn">
			</mat-form-field>
		</form>
	</app-blade-content>
	<ng-container [ngSwitch]="selectedIndex">
		<app-blade-footer *ngSwitchCase="1">
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="save()">
				<span *ngIf="item?.id">Spara</span>
				<span *ngIf="!item?.id">Skapa</span>
			</button>
		</app-blade-footer>
		<app-blade-footer *ngSwitchCase="2">
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="addNewColumn()">Lägg till ny</button>
		</app-blade-footer>
	</ng-container>
 </app-blade-item>
