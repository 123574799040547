import { Component, Inject, OnDestroy } from '@angular/core';

import { combineLatest, of, ReplaySubject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import * as _ from 'lodash';

import { SelectProductToOrderBladeService } from '../../services/create-order-tour-booking-blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { OrderWrapper } from '../../models/order-wrapper.model';
import { TourBookingPickupLocationStore } from 'app/admin/stores/tour-booking-pickup-location.store.service';
import { TourBookingPickupLocation } from 'app/models/tour-booking-pickup-location.model';
import { PickupLocationOrder } from '../../models/pickup-location-order.model';
import { PickupLocationStore } from 'app/admin/stores/pickup-location.store.service';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { CustomerType } from 'app/models/customer-type.model';
import { CreateOrderModeEnum } from 'app/enums/create-order-mode.enum';
import { UserService } from 'app/admin/services/user.service';
import { PickupLocationPriceStore } from 'app/admin/stores/pickup-location-price.store.service';
import { PickupLocation } from 'app/models/page-location.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderPart } from 'app/models/order/OrderPart';
import { ProductPickupLocationStore } from 'app/admin/stores/product/product-pickup-location.store.service';

@UntilDestroy()
@Component({
	selector: 'app-select-product-to-order-pickup',
	templateUrl: './select-product-to-order-pickup.component.html',
	styleUrls: ['./select-product-to-order-pickup.component.scss'],
})
export class SelectProductToOrderPickupComponent implements OnDestroy {
	public displayedColumns: string[] = ['type', 'quantity', 'unitPrice'];
	private pickupOrder: PickupLocationOrder[] = [];

	public pickupLocations$ = new ReplaySubject<PickupLocation[]>(1);

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public selectProductToOrderBladeService: SelectProductToOrderBladeService,
		private tourBookingPickupLocationStore: TourBookingPickupLocationStore,
		public pickupLocationStore: PickupLocationStore,
		private customerTypeStore: CustomerTypeStore,
		public userService: UserService,
		private pickupLocationPriceStore: PickupLocationPriceStore,
		private productPickupLocationStore: ProductPickupLocationStore,
	) {

		combineLatest([
			this.pickupLocationStore.getAll$(),
			this.selectProductToOrderBladeService.selectedProductIdBehavior$.pipe(switchMap(productId => {
				if (productId) {
					return this.productPickupLocationStore.getAllOnProduct(productId);
				}
				return of([]);
			})),
		])
		.pipe(untilDestroyed(this))
		.subscribe(([pickupLocations, productPickupLocations]) => {
			let availablePickupLocations = [];

			if (productPickupLocations.length) {
				productPickupLocations.forEach(pickupLocation => {
					const availablePickupLocation = _.find(pickupLocations, pl => pl.id === pickupLocation.pickupLocationId);
					if (availablePickupLocation) {
						availablePickupLocations.push(availablePickupLocation);
					}
				});
			} else {
				// Set all pickupLocation available if nothing is available.
				availablePickupLocations = pickupLocations;
			}

			this.pickupLocations$.next(_.orderBy(availablePickupLocations, ['name'], ['asc']));
		});

		combineLatest([
			bladeItem.payload.mode === CreateOrderModeEnum.onTourBooking ? this.tourBookingPickupLocationStore.getAllOnTourBooking(this.bladeItem.id) : of([]),
			this.selectProductToOrderBladeService.ordersBehavior$,
			this.customerTypeStore.getAll$(),
			this.selectProductToOrderBladeService.orderPartBehavior$,
		]).pipe(untilDestroyed(this), take(1)).subscribe(([tourBookingPickups, orders, customerTypes, orderPart]) => {
			this.handlePickupLocations(tourBookingPickups, orders, customerTypes, orderPart);
		});
		this.selectProductToOrderBladeService.customerTypeOrdersReplay$.pipe(untilDestroyed(this)).subscribe(customerTypeOrders => {
			customerTypeOrders.forEach(customerTypeOrder => {
				const findPickupOrder = _.find(this.pickupOrder, x => x.customerTypeId === customerTypeOrder.customerTypeId);
				if (findPickupOrder) {
					findPickupOrder.quantity = customerTypeOrder.quantity;
				}
			});
			this.selectProductToOrderBladeService.setPickupLocationOrders(this.pickupOrder);
		});
	}
	ngOnDestroy(): void { }

	private handlePickupLocations(tourBookingPickups: TourBookingPickupLocation[], orders: OrderWrapper[], customerTypes: CustomerType[], orderPart: OrderPart) {
		this.pickupOrder = [];

		customerTypes.forEach(customerType => {
			const pickupLocationFromOrderPart = this.getPickupLocationFromOrderPart(customerType.id, orderPart);
			if (pickupLocationFromOrderPart) {
				this.pickupOrder.push(pickupLocationFromOrderPart);
			} else {
				this.pickupOrder.push({
					customerTypeId: customerType.id,
					pickupLocationId: 0,
					unitPrice: 0,
				});
			}
		});

		this.selectProductToOrderBladeService.setPickupLocationOrders(this.pickupOrder);
	}

	public setPickupLocation(pickupLocationId: number) {
		this.pickupLocationPriceStore.getAllOnPickupLocation(pickupLocationId).pipe(untilDestroyed(this)).subscribe(pickupLocationPrices => {
			this.pickupOrder.forEach(pickupOrder => {
				const findPickupLocation = _.find(pickupLocationPrices, plp => plp.customerTypeId === pickupOrder.customerTypeId);
				if (findPickupLocation) {
					pickupOrder.pickupLocationId = findPickupLocation.pickupLocationId;
					pickupOrder.unitPrice = findPickupLocation.price;
				}
			});
		});
	}

	private getPickupLocationFromOrderPart(customerTypeId: number, orderPart: OrderPart): PickupLocationOrder {
		const findPickupLocation = _.find(orderPart.pickupLocations, ct => ct.customerTypeId === customerTypeId);
		if (findPickupLocation) {
			return {
				pickupLocationId: findPickupLocation.pickupLocationId,
				customerTypeId,
				unitPrice: findPickupLocation.unitPrice,
				quantity: findPickupLocation.quantity,
			};
		}
		return null;
	}
}
