import { Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingAddonStore } from 'app/admin/stores/tour-booking-addon.store.service';
import { TourBookingAddon } from 'app/models/tour-booking-addon.model';
import * as _ from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TourBookingAddonComponent } from '../tour-booking-addon.component';

@UntilDestroy()
@Injectable()
export class TourBookingAddonService {
	private deleteTourBookingAddonIds: number[] = [];
	private tourBookingAddons: TourBookingAddon[];
	public tourBookingAddonsReplay$ = new ReplaySubject<TourBookingAddon[]>();
	public isSaving$ = new BehaviorSubject<boolean>(false);

	constructor(
		@Inject(MAT_DIALOG_DATA) public tourBookingId: number,
		public tourBookingAddonStore: TourBookingAddonStore,
		public dialogRef: MatDialogRef<TourBookingAddonComponent>,
	) {
		this.tourBookingAddonStore.getAllOnTourBooking$(this.tourBookingId).pipe(untilDestroyed(this)).subscribe(tourBookingAddons => {
			this.tourBookingAddons = tourBookingAddons;
			this.tourBookingAddonsReplay$.next(this.tourBookingAddons);
		});
	}

	public addNew() {
		if (!this.tourBookingAddons) {
			this.tourBookingAddons = [];
		}

		const tourBookingAddon = new TourBookingAddon();
		tourBookingAddon.tourBookingId = this.tourBookingId;
		this.tourBookingAddons.push(tourBookingAddon);
		this.tourBookingAddonsReplay$.next(this.tourBookingAddons);
	}

	public save() {
		this.isSaving$.next(true);
		this.deleteAllAddons()
		.pipe(switchMap(() => this.createAddons()), untilDestroyed(this))
		.subscribe(() => {
			this.isSaving$.next(false);
			this.dialogRef.close(true);
		}, () => this.isSaving$.next(false));
	}

	public removeAddon(tourBookingAddon: TourBookingAddon) {
		const findIndex = _.findIndex(this.tourBookingAddons, tourBookingAddon);
		if (findIndex > -1) {
			this.tourBookingAddons.splice(findIndex, 1);
			if (tourBookingAddon.id) {
				this.deleteTourBookingAddonIds.push(tourBookingAddon.id);
			}
		}
	}

	private deleteAllAddons(): Observable<boolean[]> {
		const observables: Observable<boolean>[] = [of(true)];
		this.deleteTourBookingAddonIds.forEach(deleteTourBookingAddonId => {
			observables.push(this.tourBookingAddonStore.delete(deleteTourBookingAddonId));
		});
		return forkJoin(observables);
	}

	private createAddons(): Observable<TourBookingAddon[]> {
		const observables: Observable<TourBookingAddon>[] = [of(null)];
		this.tourBookingAddons.forEach(tourBookingAddon => {
			if (tourBookingAddon.id) {
				observables.push(this.tourBookingAddonStore.update(tourBookingAddon).pipe(tap(created => tourBookingAddon.id = created.id)));
			} else {
				observables.push(this.tourBookingAddonStore.create(tourBookingAddon).pipe(tap(created => tourBookingAddon.id = created.id)));
			}
		});
		return forkJoin(observables);
	}
}
