<ng-container *ngIf="{
	loadingText: loadingService.loadingText$ | async,
	show: loadingService.show$ | async
} as source">
	<div class="loading-main-content" *ngIf="source.show">
		<div class="loading-overlay"></div>
		<div class="loading-content">
			<p class="loading-text">{{source.loadingText}}</p>
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</div>
	</div>
</ng-container>
