import { BaseModel } from '../base.model';

export class CategoryText extends BaseModel {
	public categoryId: number;
	public languageId: number;
	public language: string;
	public headline: string;
	public information: string;
	public description: string;
	public seoTitle: string;
	public seoDescription: string;
}
