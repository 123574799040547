import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { TokenModel } from '../models/token.model';
import { UserService } from './user.service';
import { LoginModalService } from './login-modal.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private host = '';
	constructor(
		private http: HttpClient,
		private router: Router,
		private loginModalService: LoginModalService,
		private userService: UserService,
		@Inject('BASE_URL') baseUrl: string,
	) {
		this.host = baseUrl;
		if (this.getAuthToken()) {
			this.userService.setUser(this.getAuthToken());
		}
	 }

	public login(email: string, password: string, newPassword: string): Observable<TokenModel> {
		return this.http.post<TokenModel>(this.host + 'api/auth/login', { email, password, newPassword })
			.pipe(
				map(tokenModel => {

					if (tokenModel && tokenModel.accessToken) {
						localStorage.setItem('accessToken', tokenModel.accessToken);
						localStorage.setItem('refreshToken', tokenModel.refreshToken);
					}
					this.userService.setUser(tokenModel.accessToken);
					return tokenModel as TokenModel;
				}));
	}


	public logout() {
		const refreshToken = localStorage.getItem('refreshToken');
		if (refreshToken) {
			this.http.post<TokenModel>(this.host + 'api/auth/logut', { refreshToken }).subscribe();
			this.userService.logutUser();
			localStorage.removeItem('accessToken');
			localStorage.removeItem('refreshToken');
		}

		this.loginModalService.showLoginInModal();
	}

	public sendNewPassword(email: string): Observable<boolean> {
		return this.http.post<boolean>(this.host + 'api/auth/reset/password', { email });
	}

	public refreshToken(): Observable<TokenModel> {
		const refreshToken = localStorage.getItem('refreshToken');

		return this.http.post<TokenModel>(this.host + 'api/auth/token/refresh', { refreshToken })
			.pipe(
				map(tokenModel => {

					if (tokenModel && tokenModel.accessToken) {
						localStorage.setItem('accessToken', tokenModel.accessToken);
					}

					return tokenModel as TokenModel;
				}));
	}

	public getAuthToken(): string {
		return localStorage.getItem('accessToken');
	}
}
