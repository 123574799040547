import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { TourBooking } from 'app/models/tour-booking.model';
import { BookingStatusStore } from 'app/admin/stores/booking-status.store.service';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { Tour } from 'app/models/tour.model';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { Product } from 'app/models/product/product.model';
import { ProductPriceStore } from 'app/admin/stores/product/product-price.store.service';
import { Customer } from 'app/models/customer.model';
import { CustomersBladeComponent } from 'app/admin/blades/customer/customers-blade/customers-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { CustomerType } from 'app/models/customer-type.model';
import { OrderItem } from 'app/models/order-item.model';
import { TourBookingBladeComponent } from '../../tour-booking-blade.component';

@UntilDestroy()
@Component({
  selector: 'app-tour-booking-blade-new',
  templateUrl: './tour-booking-blade-new.component.html',
  styleUrls: ['./tour-booking-blade-new.component.scss'],
})
export class TourBookingBladeNewComponent implements OnInit, OnDestroy {

	@Input() item: TourBooking;
	public tour: Tour;
	public product: Product;
	public selectedCustomer: Customer;
	public itemForm: FormGroup = new FormGroup({});
	public createSubject = new Subject<void>();
	private customerTypes: CustomerType[];

	@Input() set create(subject: Subject<void>) {
		if (subject) {
			if (this.createSubject) {
				this.createSubject.unsubscribe();
			}
			this.createSubject = subject;
			subject.pipe(untilDestroyed(this)).subscribe(() => {
				this.doCreate();
			});
		} else if (this.createSubject) {
			this.createSubject.unsubscribe();
		}

	}

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladePositionService: BladePositionService,
		private bladeService: BladeService,
		public bookingStatusStore: BookingStatusStore,
		public customerTypeStore: CustomerTypeStore,
		public tourStore: TourStore,
		private productStore: ProductStore,
		private productPriceStore: ProductPriceStore,
		private tourBookingStore: TourBookingStore,
	) {
		this.bladeItem.callback = (customer: Customer) => {
			this.selectedCustomer = customer;
		};
	 }

  	ngOnInit() {
		this.customerTypeStore.getAll();
		this.customerTypeStore.items$.pipe(untilDestroyed(this)).subscribe(types => {
			this.customerTypes = types;
			_.each(types, t => {
				this.itemForm.addControl(t.id.toString(), new FormControl(''));
				this.itemForm.addControl(`price${t.id}`, new FormControl(''));
			});
		});
		this.tourStore.get(this.bladeItem.payload.tourId).pipe(switchMap(tour => {
			this.tour = tour;
			return this.productStore.get(tour.productId);
		})).subscribe(product => {
			this.product = product;
			this.getProductPrices();
		});
	}

	ngOnDestroy() { }

	public selectCustomer() {
		this.bladeService.addBladeItem({
			id: 0,
			component: CustomersBladeComponent,
			minClass: BladeSize.s,
		}, this.bladeItem);
	}

	private getProductPrices() {
		this.productPriceStore.getAllOnProduct(this.product.id).subscribe(prices => {
			_.each(prices, price => {
				this.itemForm.get(`price${price.customerTypeId}`).setValue(price.price);
			});
		});
	}

	private doCreate() {
		const tourId = this.bladeItem.payload.tourId;
		const orderItems: OrderItem[] = [];
		_.each(this.customerTypes, type => {
			const orderItem = new OrderItem();
			orderItem.customerTypeId = type.id;
			orderItem.quantity = this.itemForm.get(type.id.toString()).value;
			orderItem.unit = 'pcs';
			orderItem.unitPrice = this.itemForm.get(`price${type.id.toString()}`).value;
			orderItem.taxRate = 0;
			orderItem.grossTotalAmount = orderItem.unitPrice * orderItem.quantity;
			orderItem.productTypeId = 1;
			orderItem.productId = this.tour.productId;
			orderItem.name = this.tour.name;
			orderItems.push(orderItem);
		});

		this.tourBookingStore.createOrder(tourId, this.selectedCustomer.id, orderItems).subscribe(tourBooking => {
			this.bladeService.addBladeItem({
				id: tourBooking.id,
				component: TourBookingBladeComponent,
				minClass: BladeSize.m,
				maxClass: BladeSize.l,
			}, this.bladeItem.parent);
		});
	}
}
