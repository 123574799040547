<app-blade-item [canIClose]="true" [loading]="loading">
    <app-blade-header [title]="'Bläddra'" [subTitle]="'Media'"></app-blade-header>
	<app-blade-content>
		<div class="mat-elevation-z1">
			<form [formGroup]="itemForm">
				<div class="p-2 row">
					<div class="col-12 col-md-8">
						<mat-form-field class="w-100">
							<input matInput placeholder="Sök" formControlName="searchString">
							<!-- <span matSuffix>
								<mat-icon class="pointer mr-1" (click)="listView = false">view_comfy</mat-icon>
								<mat-icon class="pointer" (click)="listView = true">view_list</mat-icon>
							</span> -->
						</mat-form-field>
					</div>
					<div class="col-12 col-md-4">
						<mat-form-field class="w-100">
							<mat-label>Välj typ</mat-label>
							<mat-select formControlName="mediaType">
								<mat-option [value]="0">Alla</mat-option>
								<mat-option *ngFor="let type of (mediaTypeStore.items$|async)" [value]="type.id">
									{{type.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</form>
			<div *ngIf="listView">
				<table mat-table [dataSource]="dataSource" matSort class="w-100">
					<ng-container matColumnDef="select">
						<th mat-header-cell *matHeaderCellDef class="pr-2">
							Välj
						</th>
						<td mat-cell *matCellDef="let item">
							<mat-checkbox (click)="$event.stopPropagation()"
											(change)="$event ? selection.toggle(item) : null"
											[checked]="selection.isSelected(item)">
							</mat-checkbox>
						</td>
					</ng-container>
					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
						<td mat-cell *matCellDef="let item">{{item.id}} </td>
					</ng-container>
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
						<td mat-cell *matCellDef="let item"><strong>{{item.name}}</strong></td>
					</ng-container>
					<ng-container matColumnDef="media">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Media</th>
						<td mat-cell *matCellDef="let item"><img *ngIf="item.mediaTypeId < 4" class="my-1 list-img" [src]="itemStore.imgPath + item.path"></td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selection.toggle(item)" [class.selected]="selection.isSelected(item)"></tr>
				</table>
				<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
			</div>
			<div *ngIf="!listView" class="image-grid">

				<mat-card *ngFor="let item of dataSource?.data" class="d-flex flex-column">
				  	<mat-card-subtitle>{{item.name}}</mat-card-subtitle>
					<img mat-card-image [src]="itemStore.imgPath + item.path">
					<mat-card-content class="flex-grow-1"></mat-card-content>
					<mat-card-actions class="text-right">
					  	<button mat-button (click)="selection.toggle(item)" [color]="selection.isSelected(item) ? 'primary' : ''">
							<span *ngIf="!selection.isSelected(item)">Välj</span>
							<span *ngIf="selection.isSelected(item)">Vald</span>
						</button>
					</mat-card-actions>
				</mat-card>
			</div>
		</div>
	</app-blade-content>
	<app-blade-footer>
		<button mat-raised-button color="accent" (click)="addNewMedia()">
			Ladda upp nya bilder
		</button>
		<span fxFlex></span>
		<button [disabled]="!selection?.selected?.length" mat-raised-button color="primary" (click)="useSelectedMedia()">
			Använd
		</button>
	</app-blade-footer>
</app-blade-item>
