import { Component, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-blade-content-tab',
	templateUrl: './blade-content-tab.component.html',
	styleUrls: ['./blade-content-tab.component.scss'],
})
export class BladeContentTabComponent {
	@Input() title: string;
	@Input() isActive = false;
	@Input() index: number;
	@Input() disabled = false;

	@Input() iconName: string;
	@Input() iconSet: string;
	@Input() iconCustom: boolean;
	@Input() class: string;

	@Input() template: TemplateRef<any>;

	constructor() { }
}
