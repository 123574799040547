<mat-card>
	<mat-card-title>Ny tur-bokning</mat-card-title>
	<mat-card-subtitle>{{tour?.name}}</mat-card-subtitle>
	<mat-card-content>
		<form [formGroup]="itemForm">
			<mat-card class="mb-3 mat-elevation-z5">
				<mat-card-subtitle>Antal</mat-card-subtitle>
				<mat-card-content>
					<div class="row" *ngFor="let customerType of (customerTypeStore.items$ | async)">
						<div class="col-6">
							<mat-form-field class="w-100">
								<input matInput type="number" [placeholder]="'Antal ' + (customerType.id | customerTypeName | async)" [formControlName]="customerType.id">
							</mat-form-field>
						</div>
						<div class="col-6">
							<mat-form-field class="w-100">
								<input matInput type="number" placeholder="á pris" [formControlName]="'price' + customerType.id">
							</mat-form-field>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card class="mat-elevation-z5">
				<mat-card-subtitle>Kund</mat-card-subtitle>
				<mat-card-content>
					<p *ngIf="!selectedCustomer">Ingen vald</p>
					<div *ngIf="selectedCustomer">
						<p class="mb-0">ID: <strong>{{selectedCustomer?.id}}</strong></p>
						<p class="mb-0">Namn: <strong>{{selectedCustomer?.firstName}} {{selectedCustomer?.lastName}}</strong></p>
						<p class="mb-0">Epost: <strong>{{selectedCustomer?.email}}</strong></p>
					</div>
				</mat-card-content>
				<mat-card-actions class="text-right">
					<button mat-raised-button (click)="selectCustomer()">
						<span *ngIf="!selectedCustomer">Välj kund</span>
						<span *ngIf="selectedCustomer">Ändra kund</span>
					</button>
				</mat-card-actions>
			</mat-card>
		</form>

	</mat-card-content>
</mat-card>
