import { Component, OnInit, Inject } from '@angular/core';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { Order } from 'app/models/order.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import * as _ from 'lodash';

@Component({
	selector: 'app-order-blade',
	templateUrl: './order-blade.component.html',
	styleUrls: ['./order-blade.component.scss'],
})
@IBladeComponent.register
export class OrderBladeComponent implements OnInit {
	public showPaymentBalance$ = new BehaviorSubject<boolean>(true);
	public selectedIndex = 1;
	public item: Order;
	public loading = true;
	public canIClose: () => {} | boolean;
	public menuItems: BladeMenuItem[] = [
		{ title: 'Generellt', index: 1 },
		{ title: 'Betalning', index: 2 },
		{ title: 'Bokningar', index: 3 },
		{ title: 'Kundprogram', index: 4 },
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];


	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: OrderStore,
		private bladePositionService: BladePositionService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new Order();
		}
	}

	ngOnInit() {}

	public save() {
		if (this.item.id) {
			this.itemStore.update(this.item).subscribe(product => {
				this.item = _.assign(this.item, product);
			});
		} else {
			this.loading = true;
			this.itemStore.create(this.item).subscribe(product => {
				this.loading = false;
				this.item = _.assign(this.item, product);
				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	public publishOrder() {
		this.item.isDraft = false;

		this.save();
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
				this.bladeItem.parent?.callback();
			}
		});
	}

	public reloadPaymentBalance() {
		this.showPaymentBalance$.next(false);
		this.item.paymentBalance = null;
		setTimeout(() => {
			this.showPaymentBalance$.next(true);
		}, 500);
	}
}
