export class AdminData {
	public text = {
		sv: {
			dashboard: {
				title: 'Dashboard',
				createNew: 'Skapa ny',
				edit: 'Redigera',
				changeName: 'Ändra namn',
				saveName: 'Spara namn',
				add: 'Lägg till widget',
				useAsStandard: 'Använd som standard',
				makeThisGlobal: 'Gör denna global',
				delete: 'Radera',
				stopEdit: 'Avsluta redigeringen',
			},
			widget: {
				delete: 'Ta bort',
				draftOrders: {
					customer: 'Kund',
					restAmount: 'Rest belopp',
					source: 'Källa',
					created: 'Skapad',
				},
				modal: {
					title: 'Är du säker?',
					closeBtn: 'Avbryt',
					saveBtn: 'Spara',
				},
				orders: {
					customer: 'Kund',
					created: 'Skapad',
				},
				orderRest: {
					description: 'Hämtar på tures som ska genomföras mellan',
					descriptionAnd: 'och',
					customer: 'Kund',
					travelAgency: 'Resebyrå',
					date: 'Turens datum',
					restAmount: 'Rest belopp',
					goToTour: 'Gå till tur',
					goToOrder: 'Gå till order',
					goToCustomer: 'Gå till kunden',
				},
				tourBookings: {
					customer: 'Kund',
					source: 'Källa',
					status: 'Status',
					created: 'Skapad',
				},
			},
			sideNav: {
				menu: {
					homepage: 'Hemsidan',
					wiki: 'Wiki',
					homepageSettings: 'Inställningar',
					homepagePages: 'Sidor',
					homepageWYSIWYG: 'WYSIWYG',
					guideTours: 'Mina turer',
					planner: 'Plannering',
					plannerResource: 'Resurser',
					plannerGuides: 'Guider',
					products: 'Produkter',
					productFunctions: 'Produkt funktioner',
					addon: 'Tillägg',
					specialDiet: 'Specialkost',
					categories: 'Kategorier',
					tours: 'Turer',
					orders: 'Ordrar',
					payments: 'Betalningar',
					customers: 'Kunder',
					customer: 'Kunder',
					pickupLocations: 'Hämtningsplatser',
					analytics: 'Statistik',
					email: 'E-post',
					suppliers: 'Leverantörer',
					travelAgencies: 'Resebyråer',
					system: 'System',
					users: 'Användare',
					dashboard: 'Instrumentbräda',
					settings: 'Inställningar',
					reports: 'Rapporter',
					specialDietReports: 'Specialkost',
					media: 'Media',
					term: 'Villkor',
					tour: 'Turer',
				},
				settings: {
					settingsTitle: 'Inställningar',
					themesTitle: 'Teman',
					languageTitle: 'Språk',
				},
			},
			login: {
				tryToLogin: 'Försöker logga in dig...',
				title: 'Logga in',
				subtitle: 'Sidan du försöker nå är skyddad',
				email: 'E-postadress',
				password: 'Lösenord',
				updatePasswordDescription: 'Du måste uppdatera ditt lösenord. Detta kan bero på att du är ny i systemet eller att någon admin har begärt det.',
				newPassword: 'Nytt lösenord',
				login: 'Logga in',
				changePasswordAndLogin: 'Ändra lösenord och logga in',
				unauthorizedTitle: 'Du saknar behörighet',
				unauthorizedDescription1a: 'Din användare',
				unauthorizedDescription1b: 'saknar behörighet för sidan som du försöker nå.',
				unauthorizedDescription2: 'Du kan logga ut med den här användaren och logga in med rätt användare.',
				logout: 'Logga ut',
			},
			blades: {
				tours: {
					bladeTitle: 'Turer',
					filter: {
						dateLabel: 'Välj mellan vilka datum du vill hämta turer',
						fromDate: 'Från datum',
						toDate: 'Till datum',
						dateSearch: 'Sök',
						idSearchLabel: 'Ange ID som du vill söka på',
						idSearch: 'Sök på ID',
						filterLabel: 'Visa:',
						noSearchResult: 'Finns inga turer. Ändra från och till datum och försök igen.',
						createNew: 'Skapa ny',
						table: {
							name: 'Namn',
							guide: 'Guide',
							noGuide: 'Saknas',
							supplier: 'Leverantör',
							specialDiet: 'Specialkost',
							date: 'Datum',
							missingDate: 'Saknar datum',
							amount: 'Antal',
							status: 'Status',
							active: 'Aktiv',
							private: 'Privat',
						},
					},
				},
				tour: {
					bladeTitleNew: 'Namnlös',
					bladeSubtitle: 'Tur',
					saveBtn: 'Spara',
					createBtn: 'Skapa',
					internalMessage: 'Internmeddelande (Frivillig)',
					messageLabel: 'Meddelande',
					tabs: {
						generally: {
							title: 'Generellt',
							newTitle: 'Ny tur',
							newTourCard: 'Leverantör fylls automatiskt i när du väljer en produkt.',
							activeLabel: 'Aktiv',
							privateLabel: 'Privat',
							nameLabel: 'Namn',
							nameTooltip: 'Anger du inget namn så används produktens namn',
							statusLabel: 'Status',
							dateLabel: 'Datum',
							dateTooltip: 'Ange i formatet ÅÅÅÅ-MM-DD',
							timeLabel: 'Tid',
							availablePlacesLabel: 'Tillgänliga platser',
							timeLengthLabel: 'Ange längd i timmar',
							productLabel: 'Produkt',
							productPlaceholder: 'Välj product',
							supplierLabel: 'Leverantör',
							supplierPlaceholder: 'Välj leverantör',
							created: 'Skapad:',
							modified: 'Ändrad:',
						},
						bookings: {
							title: 'Bokningar',
							createNewBtn: 'Lägg till ny bokning',
							table: {
								customer: 'Kund',
								source: 'Källa',
								status: 'Status',
								created: 'Skapad',
							},
						},
						guides: {
							title: 'Guider',
							createNew: 'Lägg till guide',
							table: {
								name: 'Namn',
								type: 'Typ',
							},
						},
						summary: {
							title: 'Kunder',
							addon: {
								title: 'Tillägg',
								table: {
									name: 'Namn',
									customer: 'Kund',
									amount: 'Antal',
								},
							},
							customerType: {
								travelAgency: 'Resebyrå:',
								amount: 'Antal',
								table: {
									type: 'Typ',
									amount: 'Antal',
									birthDate: 'Födelsedatum',
								},
							},
							pickuplocation: {
								title: 'Upphämtningsplats',
								table: {
									type: 'Typ',
									place: 'Plats',
									amount: 'Antal',
								},
							},
							message: {
								title: 'Kundmeddelande',
								noMessage: 'Inget meddelande',
							},
							paymentBalance: {
								title: 'Kredit',
								total: 'Totalt belopp på ordern:',
								payed: 'Kunden har betalat',
								pay: 'Ska betala:',
								getBack: 'Få tillbaka:',
							},
							specialDiet: {
								title: 'Specialkost',
								noSpecialDiet: 'Ingen specialkost',
								amount: 'antal',
								messageTitle: 'Meddelande',
								noMessage: 'Inget meddelande',
							},
						},
						pickup: {
							title: 'Hämtningar',
							table: {
								customer: 'Kund',
								pickupLocation: 'Hämtningsplats',
								customeType: 'Kund-typ',
								amount: 'Antal',
							},
						},
						messages: {
							title: 'Internmeddelanden',
							editMessage: 'Redigera meddelandet',
							createMessage: 'Skapa nytt meddelande',
							updateBtn: 'Uppdatera',
							createBtn: 'Skapa',
						},
						resource: {
							title: 'Resurser',
						},
						program: {
							title: 'Turdeltagare',
							showProgramBtn: 'Visa turdeltagare',
						},
					},
				},
				plannerGuidesTours: {
					bladeTitle: 'Guideplanering',
					workTime: 'Arbetstid',
					missingTour: 'Saknar tur',
					tourTimeWarning: 'Turer krockar i tid',
					missingGuide: 'Guide saknas på tur',
					filter: {
						dateLabel: 'Välj mellan vilka datum du vill hämta turer',
						fromDate: 'Från datum',
						toDate: 'Till datum',
						dateSearch: 'Sök',
					},
				},
			},
			shared: {
				tourResource: {
					title: 'Resurs',
					subtitle: 'Om denna tur är kopplad till en produkt som har resurser så kan du här se en sammanställning för hur resurserna används. Du kan även koppla resurser här. De du kopplar här kommer aldrig att räknas bort. De är "hårt" kopplade till just den här turen.',
					missing: 'Den här leverantören saknar resurser.',
					selectResource: 'Välj en resurs i listan följt av antal.',
					selectResourceLabel: 'Välj resurs',
					amount: 'Antal',
					remove: 'Ta bort',
					cancel: 'Avbryt',
					addResource: 'Lägg till resurs',
					updateResource: 'Uppdatera resurs',
					total: 'Totalt:',
					table: {
						resource: 'Resurs',
						amount: 'Antal',
					},
					calculatedResource: {
						title: 'Beräknad resursåtgång',
						table: {
							resource: 'Resurs',
							amount: 'Antal',
							handled: 'Hanteras',
							addedOnTour: 'Tillagd på turen',
							amountResource: 'Antal resurser',
						},
					},
				},
			},
			customerProgram: {
				title: 'Program',
				reference: 'Referens',
				status: 'Status: ',
				statusMapping: {
					Ny: 'Ny',
					Hanterad: 'Hanterad',
					Avbokad: 'Avbokad',
				},
				message: 'Meddelanden',
				pickup: 'Hämtas:',
				pickupTime: 'Tid:',
				pickupAmount: 'Antal:',
				pickupPct: 'st',
			},
			customerProgramV2: {
				title: 'Program',
				canceled: '(Avbokad)',
				reference: 'Referens',
				status: 'Status: ',
				statusMapping: {
					Ny: 'Ny',
					Hanterad: 'Hanterad',
					Avbokad: 'Avbokad',
				},
				message: 'Meddelanden',
				pickup: 'Hämtningsplats:',
				pickupTime: 'Tid:',
				pickupAmount: 'Antal:',
				quantity: 'Antal:',
				pct: 'st',
				pctPris: 'Styck pris:',
				pickupPrice: 'Pris för hämtning',
				total: 'Totalt:',
				ages: 'Åldrar:',
				year: 'år',
				addon: 'Tillägg',
				name: 'Namn:',
				specialDiet: 'Specialdiet',
				sum: 'Summa:',
				travelAgency: 'Resebyrå',
				customerTitle: 'Info om kunden',
				customerName: 'Namn',
				firstName: 'Förnamn',
				lastName: 'Efternamn',
				email: 'Epost',
				phone: 'Telefon',
				language: 'Kunden föredrar språk',
				totalSum: 'Totalt belopp',
				internalMessage: 'Internmeddelande',
				internalMessageMissing: 'Inget meddelande',
				customerMessage: 'Kundmeddelande',
				customerMessageMissing: 'Inget meddelande',
			},
			tourCustomerProgram: {
				title: 'Turdeltagare',
				private: 'Privat',
				noPrivate: 'Ej privat',
				status: 'Status: ',
				statusMapping: {
					Ny: 'Ny',
					Hanterad: 'Hanterad',
					Avbokad: 'Avbokad',
				},
				messages: 'Meddelanden',
				noMessages: 'Inga meddelanden',
				participantTitle: 'Deltagare',
				participants: {
					status: 'Status: ',
					statusMapping: {
						Ny: 'Ny',
						Bekräftad: 'Bekräftad',
						Avbokad: 'Avbokad',
					},
					travelAgancy: 'Resebyrå:',
					messages: 'Meddelanden',
					noMessages: 'Inga meddelanden',
					pickup: 'Hämtas:',
					pickupTime: 'Tid:',
					pickupAmount: 'Antal:',
					pickupPct: 'st',
					age: 'Ålder:',
					year: 'År',
					specialDiet: {
						title: 'Specialkost',
						noSpecialDiet: 'Ingen specialkost',
						messages: 'Specialkost meddelande',
						noMessages: 'Inget specialkost meddelande',
					},
					balance: {
						title: 'Balans -',
						pay: 'Ska betala: ',
						receive: 'Få tillbaka: ',
					},
				},
			},
			tour: {
				search: {
					title: 'Sök turer',
					dateLabel: 'Välj mellan vilka datum du vill hämta turer',
					fromDate: 'Från datum',
					toDate: 'Till datum',
					dateSearch: 'Sök',
					idSearchLabel: 'Ange ID som du vill söka på',
					idSearch: 'Sök på ID',
					filterLabel: 'Visa:',
					noSearchResult: 'Finns inga turer. Ändra från och till datum och försök igen.',
					createNew: 'Skapa ny tur',
					cancel: 'Avbryt',
					filterOnProduct: 'Filtrera på produkt',
					product: 'Produkt',
					table: {
						id: 'ID',
						name: 'Namn',
						guide: 'Guide',
						noGuide: 'Saknas',
						supplier: 'Leverantör',
						date: 'Datum',
						missingDate: 'Saknar datum',
						amount: 'Antal',
						status: 'Status',
						active: 'Aktiv',
						private: 'Privat',
					},
				},
			}
		},
		en: {
			dashboard: {
				title: 'Dashboard',
				createNew: 'Create new',
				edit: 'Edit',
				changeName: 'Change name',
				saveName: 'Spara namn',
				add: 'Add widget',
				useAsStandard: 'Use as standard',
				makeThisGlobal: 'Make this global',
				delete: 'Delete',
				stopEdit: 'End edit',
			},
			widget: {
				delete: 'Delete',
				draftOrders: {
					customer: 'Customer',
					restAmount: 'Rest amount',
					source: 'Source',
					created: 'Created',
				},
				modal: {
					title: 'Are you sure?',
					closeBtn: 'Cancel',
					saveBtn: 'Save',
				},
				orders: {
					customer: 'Customer',
					created: 'Created',
				},
				orderRest: {
					description: 'Picks up on tours to be completed between',
					descriptionAnd: 'and',
					customer: 'Customer',
					// travelAgency: 'Travel agency',
					date: 'Tours date',
					restAmount: 'Rest amount',
					goToTour: 'Go to tour',
					goToOrder: 'Go to order',
					goToCustomer: 'Go to customer',
				},
				tourBookings: {
					customer: 'Customer',
					source: 'Source',
					status: 'Status',
					created: 'Created',
				},
			},
			sideNav: {
				menu: {
					homepage: 'Homepage',
					wiki: 'Wiki',
					homepageSettings: 'Settings',
					homepagePages: 'Pages',
					homepageWYSIWYG: 'WYSIWYG',
					guideTours: 'My tours',
					planner: 'Planner',
					plannerResource: 'Resurses',
					plannerGuides: 'Guides',
					products: 'Products',
					productFunctions: 'Product functions',
					addon: 'Addons',
					specialDiet: 'Special diet',
					categories: 'Categories',
					tours: 'Tures',
					orders: 'Orders',
					payments: 'Payments',
					customers: 'Customers',
					customer: 'Customers',
					pickupLocations: 'Pickuplocations',
					analytics: 'Analytics',
					email: 'Email',
					suppliers: 'Suppliers',
					travelAgencies: 'Travel agancies',
					system: 'System',
					users: 'Users',
					dashboard: 'Dashboard',
					settings: 'Settings',
					reports: 'Reports',
					specialDietReports: 'Specialdiet',
					media: 'Media',
					term: 'Terms',
					tour: 'Tour',
				},
				settings: {
					settingsTitle: 'Settings',
					themesTitle: 'Themes',
					languageTitle: 'Languages',
				},
			},
			login: {
				tryToLogin: 'Trying to log in ...',
				title: 'Log in',
				subtitle: 'The page you are trying to reach is protected',
				email: 'Email',
				password: 'Password',
				updatePasswordDescription: 'You must update your password. This may be because you are new to the system or if some admin has requested it.',
				newPassword: 'New password',
				login: 'Log in',
				changePasswordAndLogin: 'Change password and log in',
				unauthorizedTitle: 'You do not have permission',
				unauthorizedDescription1a: 'Your user',
				unauthorizedDescription1b: 'does not have permission for the page you are trying to access.',
				unauthorizedDescription2: 'You can log out with this user and log in with the correct user.',
				logout: 'Log out',
			},
			blades: {
				tours: {
					bladeTitle: 'Tour',
					filter: {
						dateLabel: 'Choose between which dates you want to pick up tours',
						fromDate: 'From date',
						toDate: 'To date',
						dateSearch: 'Search',
						idSearchLabel: 'Enter the ID you want to search for',
						idSearch: 'Search by ID',
						filterLabel: 'Show:',
						noSearchResult: 'There are no tours. Change from and to date and try again.',
						createNew: 'Create new',
						table: {
							name: 'Name',
							guide: 'Guide',
							noGuide: 'Missing',
							supplier: 'Supplier',
							specialDiet: 'Special diet',
							date: 'Date',
							missingDate: 'Missing date',
							amount: 'Amount',
							status: 'Status',
							active: 'Active',
							private: 'Private',
						},
					},
				},
				tour: {
					bladeTitleNew: 'Untitled',
					bladeSubtitle: 'Tour',
					saveBtn: 'Save',
					createBtn: 'Create',
					internalMessage: 'Internal message (Optional)',
					messageLabel: 'Message',
					tabs: {
						generally: {
							title: 'Generally',
							newTitle: 'New tour',
							newTourCard: 'Supplier is filled in automatically when you select a product.',
							activeLabel: 'Active',
							privateLabel: 'Private',
							nameLabel: 'Name',
							nameTooltip: 'If you do not enter a name, the product name is used',
							statusLabel: 'Status',
							dateLabel: 'Date',
							dateTooltip: 'Enter in the format YYYY-MM-DD',
							timeLabel: 'Time',
							availablePlacesLabel: 'Available places',
							timeLengthLabel: 'Enter length in hours',
							productLabel: 'Product',
							productPlaceholder: 'Select product',
							supplierLabel: 'Supplier',
							supplierPlaceholder: 'Select supplier',
							created: 'Created:',
							modified: 'Changed:',
						},
						bookings: {
							title: 'Bookings',
							createNewBtn: 'Add new booking',
							table: {
								customer: 'Customer',
								source: 'Source',
								status: 'Status',
								created: 'Created',
							},
						},
						guides: {
							title: 'Guides',
							createNew: 'Add new guide',
							table: {
								name: 'Name',
								type: 'Type',
							},
						},
						summary: {
							title: 'Customer',
							addon: {
								title: 'Addon',
								table: {
									name: 'Name',
									customer: 'Customer',
									amount: 'Amount',
								},
							},
							customerType: {
								travelAgency: 'Travelagency:',
								amount: 'Amount',
								table: {
									type: 'Type',
									amount: 'Amount',
									birthDate: 'Birthdate',
								},
							},
							pickuplocation: {
								title: 'Pickuplocation',
								table: {
									type: 'Type',
									place: 'Place',
									amount: 'Amount',
								},
							},
							message: {
								title: 'Customer message',
								noMessage: 'No message',
							},
							paymentBalance: {
								title: 'Credit',
								total: 'Total amount on the order:',
								payed: 'The customer has paid',
								pay: 'Will pay:',
								getBack: 'Get back:',
							},
							specialDiet: {
								title: 'Specialdiet',
								noSpecialDiet: 'No specialdiet',
								amount: 'amount',
								messageTitle: 'Message',
								noMessage: 'No message',
							},
						},
						pickup: {
							title: 'Pickup',
							table: {
								customer: 'Customer',
								pickupLocation: 'Pickuplocation',
								customeType: 'Customer-type',
								amount: 'Amount',
							},
						},
						messages: {
							title: 'Messages',
							editMessage: 'Edit message',
							createMessage: 'Create new message',
							updateBtn: 'Update',
							createBtn: 'Create',
						},
						resource: {
							title: 'Resource',
						},
						program: {
							title: 'Program',
							showProgramBtn: 'Show program',
						},
					},
				},
				plannerGuidesTours: {
					bladeTitle: 'Guideplanning',
					workTime: 'Workingtime',
					missingTour: 'Missing tour',
					tourTimeWarning: 'Tours collide in time',
					missingGuide: 'Guide is missing on tour',
					filter: {
						dateLabel: 'Choose between which dates you want to pick up tours',
						fromDate: 'From date',
						toDate: 'To date',
						dateSearch: 'Search',
					},
				},
			},
			shared: {
				tourResource: {
					title: 'Resource',
					subtitle: 'If this tour is linked to a product that has resources, you can see here a summary of how the resources are used. You can also link resources here. Those you connect here will never be counted out. They are "tightly" connected to this particular tour.',
					missing: 'This provider does not have the resources.',
					selectResource: 'Select a resource from the list followed by the number.',
					selectResourceLabel: 'Välj resurs',
					amount: 'Amount',
					remove: 'Delete',
					cancel: 'Cancel',
					addResource: 'Add resource',
					updateResource: 'Update resource',
					total: 'Total:',
					table: {
						resource: 'Resources',
						amount: 'Amount',
					},
					calculatedResource: {
						title: 'Estimated resource consumption',
						table: {
							resource: 'Resource',
							amount: 'Amount',
							handled: 'Handled',
							addedOnTour: 'Added on the tour',
							amountResource: 'Number of resources',
						},
					},
				},
			},
			customerProgram: {
				title: 'Program',
				reference: 'Reference',
				status: 'Status',
				statusMapping: {
					Ny: 'New',
					Hanterad: 'Handled',
					Avbokad: 'Canceled',
				},
				message: 'Message',
				pickup: 'Pickup:',
				pickupTime: 'Time:',
				pickupAmount: 'Amount:',
				pickupPct: 'st',
			},
			customerProgramV2: {
				title: 'Program',
				canceled: '(Canceled)',
				reference: 'Reference',
				status: 'Status',
				statusMapping: {
					Ny: 'New',
					Hanterad: 'Handled',
					Avbokad: 'Canceled',
				},
				message: 'Message',
				pickup: 'Pickup:',
				pickupTime: 'Time:',
				pickupAmount: 'Amount:',
				pct: 'pct',
				pctPris: 'Piece price:',
				pickupPrice: 'Price for pickup',
				quantity: 'Quantity:',
				total: 'Total:',
				ages: 'Ages:',
				year: 'year',
				addon: 'Addon',
				name: 'Name:',
				specialDiet: 'Specialdiet',
				sum: 'Sum:',
				travelAgency: 'Travel agency',
				customerTitle: 'Info about the customer',
				customerName: 'Name',
				firstName: 'Firstname',
				lastName: 'Lastname',
				email: 'Email',
				phone: 'Phone',
				language: 'Customer prefers language',
				totalSum: 'Total sum',
				internalMessage: 'Internalmessage',
				internalMessageMissing: 'No messages',
				customerMessage: 'Customermessage',
				customerMessageMissing: 'No messages',
			},
			tourCustomerProgram: {
				title: 'Participants',
				private: 'Private',
				noPrivate: 'No private',
				status: 'Status: ',
				statusMapping: {
					Ny: 'New',
					Hanterad: 'Handled',
					Avbokad: 'Canceled',
				},
				travelAgancy: 'TravelAgancy:',
				messages: 'Message',
				noMessages: 'No messages',
				participantTitle: 'Participants',
				participants: {
					status: 'Status: ',
					statusMapping: {
						Ny: 'New',
						Bekräftad: 'Confirmed',
						Avbokad: 'Canceled',
					},
					messages: 'Messages',
					noMessages: 'No messages',
					pickup: 'Pickup:',
					pickupTime: 'Time:',
					pickupAmount: 'Amount:',
					pickupPct: 'st',
					age: 'Age:',
					year: 'year',
					specialDiet: {
						title: 'Special diet',
						noSpecialDiet: 'No Special diet',
						messages: 'Special diet message',
						noMessages: 'No special diet message',
					},
					balance: {
						title: 'Balance -',
						pay: 'To pay: ',
						receive: 'To receive: ',
					},
				},
			},
		},
	};
}
