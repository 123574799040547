<app-blade-item [canIClose]="!itemForm.dirty" [loading]="loading">
	<app-blade-header [title]="'Prisskillnad'" [subTitle]="'Produkt datum tid prisskillnad'"></app-blade-header>
	<app-blade-content>
		<app-alert-card [mode]="'info'">Lorem ipsum dolor sit amet consectetur adipisicing elit.</app-alert-card>

		<form [formGroup]="itemForm">
			<mat-form-field class="w-100" *ngFor="let customerType of customerTypes">
				<input matInput type="number" [formControlName]="customerType.id" [placeholder]="customerType.name">
			</mat-form-field>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span>Spara</span>
		</button>
	</app-blade-footer>
</app-blade-item>
