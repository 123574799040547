import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Etage } from 'app/models/site/etage.model';
import { EtageStore } from 'app/admin/stores/etage.store.service';
import { EtageBladeComponent } from 'app/admin/blades/etage/etage-blade/etage-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';

@UntilDestroy()
@Component({
	selector: 'app-page-blade-etages',
	templateUrl: './page-blade-etages.component.html',
	styleUrls: ['./page-blade-etages.component.scss'],
})
export class PageBladeEtagesComponent implements OnInit, OnDestroy {
	private pristinePrioList: number[];
	private etages: Etage[];
	public prioIsChangedReplay$ = new ReplaySubject<boolean>();
	public savingPrioReplay$ = new ReplaySubject<boolean>();

	public displayedColumns: string[] = ['name'];
	public dataSource: MatTableDataSource<Etage>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public etageStore: EtageStore,
	) {
		etageStore.getAllOnPage(bladeItem.id).pipe(untilDestroyed(this)).subscribe(etages => {
			this.etages = _.sortBy(etages, i => i.prio);
			this.pristinePrioList = this.etages.map(p => p.id);

			this.dataSource = new MatTableDataSource<Etage>(this.etages);
			this.dataSource.paginator = this.paginator;
		});
	 }

	ngOnInit() {
	}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: EtageBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

	public onListDrop(event: CdkDragDrop<MatTableDataSource<Etage>, any>) {
		const previousIndex = this.etages.findIndex(row => row === event.item.data);
		moveItemInArray(this.etages, previousIndex, event.currentIndex);
		this.etages.forEach((element, index) => element.prio = index);
		this.dataSource = new MatTableDataSource<Etage>(this.etages);
		this.dataSource.paginator = this.paginator;

		this.isPrioChanged();
	}

	public savePrio() {
		this.savingPrioReplay$.next(true);
		this.etageStore.updatePrio(this.etages).pipe(untilDestroyed(this)).subscribe(done => {
			this.pristinePrioList = this.etages.map(p => p.id);
			this.savingPrioReplay$.next(false);
			this.isPrioChanged();
		});
	}

	private isPrioChanged() {
		if (this.etages.map(p => p.id).toString() !== this.pristinePrioList.toString()) {
			this.prioIsChangedReplay$.next(true);
		} else {
			this.prioIsChangedReplay$.next(false);
		}
	}
}
