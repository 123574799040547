<mat-card>
	<mat-card-title>Generellt</mat-card-title>
	<form [formGroup]="pageBladeService.itemForm">
		<div class="d-flex justify-content-between mb-3">
			<mat-slide-toggle
				color="accent"
				formControlName="isActive">
				Aktiv
			</mat-slide-toggle>
			<mat-slide-toggle
				color="accent"
				formControlName="showOnMenu">
				Visa i menyn
			</mat-slide-toggle>
		</div>
		<mat-form-field class="w-100">
			<input matInput formControlName="name" placeholder="Arbetsnamn">
		</mat-form-field>
		<mat-form-field class="w-100">
			<input matInput formControlName="externalLink" placeholder="Extern länk">
		</mat-form-field>
		<mat-form-field class="w-100">
			<mat-label>Välj sida-typ</mat-label>
			<mat-select formControlName="pageTypeId">
				<mat-option *ngFor="let type of (pageTypeStore.items$|async)" [value]="type.id">
					{{type.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</form>
</mat-card>
