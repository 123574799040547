<ng-container *ngIf="{
	adminText: 'widget' | adminText | async
} as source">
	<mat-card class="dashboard-card"
		[class.widget-icon]="item.type === dashboardWidgetType.icon"
		[class.widget-dynamic]="item.type === dashboardWidgetType.dynamic"
		[class.is-handset]="isHandset"
	>
		<mat-card-header>
			<mat-card-title *ngIf="item.name">
				{{item.name}}
			</mat-card-title>

			<button mat-icon-button class="more-button" [matMenuTriggerFor]="menu"
				aria-label="Toggle menu">
				<mat-icon>more_vert</mat-icon>
			</button>

			<mat-menu #menu="matMenu" xPosition="before">
				<button mat-menu-item (click)="showSettings()" *ngIf="item.dashboardWidgetTypeId === 5"><mat-icon>settings</mat-icon> Inställningar</button>
				<button mat-menu-item (click)="removeWidget()"><mat-icon>clear</mat-icon> {{source.adminText?.delete}}</button>
			</mat-menu>
		</mat-card-header>
		<mat-card-content class="dashboard-card-content" (click)="itemClick()">
			<!-- <ng-container *ngIf="item.type === dashboardWidgetType.icon">
				<mat-icon [inline]="true">{{item.data.icon}}</mat-icon>
			</ng-container>

			<ng-container *ngIf="item.type === dashboardWidgetType.dynamic">
				<div>Card Content Here</div>
				<div>{{item.data|json}}</div>
			</ng-container>

			<ng-container *ngIf="item.type === dashboardWidgetType.chart">
				<div style="display: block; max-height: 100%; position: relative;">
					<canvas baseChart [data]="item.data.chartData" [labels]="item.data.chartLabels" [chartType]="item.data.chartType"
						(chartHover)="chartHovered($event)" [options]="item.data.options" (chartClick)="chartClicked($event)"></canvas>
				</div>
			</ng-container> -->

			<app-dashboard-widget-orders *ngIf="item.dashboardWidgetTypeId === 1"></app-dashboard-widget-orders>
			<app-dashboard-widget-tour-bookings *ngIf="item.dashboardWidgetTypeId === 2"></app-dashboard-widget-tour-bookings>
			<app-dashboard-widget-order-payments *ngIf="item.dashboardWidgetTypeId === 3"></app-dashboard-widget-order-payments>
			<app-dashboard-widget-order-amount *ngIf="item.dashboardWidgetTypeId === 4"></app-dashboard-widget-order-amount>
			<app-dashboard-widget-orders-rest *ngIf="item.dashboardWidgetTypeId === 5"></app-dashboard-widget-orders-rest>
			<app-dashboard-widget-draft-orders *ngIf="item.dashboardWidgetTypeId === 6"></app-dashboard-widget-draft-orders>
		</mat-card-content>
	</mat-card>
</ng-container>
