import { Component, Inject, Input, ContentChildren } from '@angular/core';
import { BladeItemInjectToken, BladeService } from '../../../blade.service';
import { BladeItem } from '../../../models/bladeItem.model';
import { HeaderMenuItem } from '../../../models/headerMenuItem.model';
import { BladePositionService } from '../../../blade-position.service';

@Component({
	selector: 'app-blade-header',
	templateUrl: './blade-header.component.html',
	styleUrls: ['./blade-header.component.scss'],
})
export class BladeHeaderComponent {

	public mainTitle: string;
	@Input() menuItems: HeaderMenuItem[];
	@Input() set title(title: string) {
		setTimeout(() => {
			this.mainTitle = title;
			this.bladeItem.title = this.mainTitle;
		});
	}
	@Input() subTitle: string;
	@ContentChildren('.mat-card-title') contentChild !: any;

	constructor(
		public bladeService: BladeService,
		public bladePositionService: BladePositionService,
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
	) { }

	public closeMe() {
		this.bladeService.removeBladeItem(this.bladeItem);
	}

}
