import { Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingCustomerTypeAgeStore } from 'app/admin/stores/tour-booking-customer-type-age.store.service';
import { TourBookingCustomerTypeStore } from 'app/admin/stores/tour-booking-customer-type.store.service';
import { TourBookingCustomerTypeAge } from 'app/models/tour-booking-customer-type-age.model';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TourBookingCustomerTypeComponent } from '../tour-booking-customer-type.component';

@UntilDestroy()
@Injectable()
export class TourBookingCustomerTypeService {
	private deleteTourBookingCustomerTypeAgeIds: number[] = [];
	private deleteTourBookingCustomerTypeIds: number[] = [];
	private tourBookingCustomerTypes: TourBookingCustomerType[];
	public tourBookingCustomerTypesReplay$ = new ReplaySubject<TourBookingCustomerType[]>();
	public isSaving$ = new BehaviorSubject<boolean>(false);

	constructor(
		@Inject(MAT_DIALOG_DATA) public tourBookingId: number,
		public tourBookingCustomerTypeStore: TourBookingCustomerTypeStore,
		public tourBookingCustomerTypeAgeStore: TourBookingCustomerTypeAgeStore,
		public dialogRef: MatDialogRef<TourBookingCustomerTypeComponent>,
	) {
		this.tourBookingCustomerTypeStore.getAllOnTourBooking$(this.tourBookingId).pipe(untilDestroyed(this)).subscribe(tourBookingCustomerTypes => {
			this.tourBookingCustomerTypes = tourBookingCustomerTypes;
			this.tourBookingCustomerTypesReplay$.next(this.tourBookingCustomerTypes);
		});
	}

	public addNew() {
		if (!this.tourBookingCustomerTypes) {
			this.tourBookingCustomerTypes = [];
		}

		const tourBookingCustomerType = new TourBookingCustomerType();
		tourBookingCustomerType.tourBookingId = this.tourBookingId;
		this.tourBookingCustomerTypes.push(tourBookingCustomerType);
		this.tourBookingCustomerTypesReplay$.next(this.tourBookingCustomerTypes);
	}

	public save() {
		this.isSaving$.next(true);
		combineLatest([
			this.deleteAllAges().pipe(switchMap(() => this.deleteAllCustomerTypes())),
		]).pipe(
			switchMap(() => this.createCustomerTypes().pipe(switchMap(() => this.createCustomerTypeAges()))),
			untilDestroyed(this),
		).subscribe(() => {
			this.isSaving$.next(false);
			this.dialogRef.close(true);
		}, () => this.isSaving$.next(false));
	}

	public removeAge(tourBookingCustomerTypeAgeId: number) {
		this.deleteTourBookingCustomerTypeAgeIds.push(tourBookingCustomerTypeAgeId);
	}

	public removeCustomerType(tourBookingCustomerType: TourBookingCustomerType) {
		const findIndex = _.findIndex(this.tourBookingCustomerTypes, tourBookingCustomerType);
		if (findIndex > -1) {
			this.tourBookingCustomerTypes.splice(findIndex, 1);
			if (tourBookingCustomerType.id) {
				if (tourBookingCustomerType.tourBookingCustomerTypeAges?.length) {
					tourBookingCustomerType.tourBookingCustomerTypeAges.forEach(age => this.removeAge(age.id));
				}
				this.deleteTourBookingCustomerTypeIds.push(tourBookingCustomerType.id);
			}
		}
	}

	private deleteAllAges(): Observable<boolean[]> {
		const observables: Observable<boolean>[] = [of(true)];
		this.deleteTourBookingCustomerTypeAgeIds.forEach(tourBookingCustomerTypeAgeId => {
			observables.push(this.tourBookingCustomerTypeAgeStore.delete(tourBookingCustomerTypeAgeId));
		});
		return forkJoin(observables);
	}

	private deleteAllCustomerTypes(): Observable<boolean[]> {
		const observables: Observable<boolean>[] = [of(true)];
		this.deleteTourBookingCustomerTypeIds.forEach(deleteTourBookingCustomerTypeId => {
			observables.push(this.tourBookingCustomerTypeStore.delete(deleteTourBookingCustomerTypeId));
		});
		return forkJoin(observables);
	}

	private createCustomerTypes(): Observable<TourBookingCustomerType[]> {
		const observables: Observable<TourBookingCustomerType>[] = [of(null)];
		this.tourBookingCustomerTypes.forEach(tourBookingCustomerType => {
			if (tourBookingCustomerType.id) {
				observables.push(this.tourBookingCustomerTypeStore.update(tourBookingCustomerType).pipe(tap(created => tourBookingCustomerType.id = created.id)));
			} else {
				observables.push(this.tourBookingCustomerTypeStore.create(tourBookingCustomerType).pipe(tap(created => tourBookingCustomerType.id = created.id)));
			}
		});
		return forkJoin(observables);
	}

	private createCustomerTypeAges(): Observable<TourBookingCustomerTypeAge[]> {
		const observables: Observable<TourBookingCustomerTypeAge>[] = [of(null)];
		this.tourBookingCustomerTypes.forEach(tourBookingCustomerType => {
			const agesToCreate = tourBookingCustomerType.tourBookingCustomerTypeAges?.filter(x => !x.id);
			if (!agesToCreate) {
				return;
			}
			agesToCreate.forEach(tourBookingCustomerTypeAge => {
				tourBookingCustomerTypeAge.tourBookingCustomerTypeId = tourBookingCustomerType.id;
				observables.push(this.tourBookingCustomerTypeAgeStore.create(tourBookingCustomerTypeAge));
			});
		});
		return forkJoin(observables);
	}
}
