import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import { TourBookingSpecialDietMessageService } from './services/tour-booking-special-diet-message.service';

@UntilDestroy()
@Component({
  	selector: 'app-tour-booking-special-diet-message',
  	templateUrl: './tour-booking-special-diet-message.component.html',
  	styleUrls: ['./tour-booking-special-diet-message.component.scss'],
	providers: [TourBookingSpecialDietMessageService],
  	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourBookingSpecialDietMessageComponent implements OnInit {
  	constructor(
		  public tourBookingSpecialDietMessageService: TourBookingSpecialDietMessageService,
	) { }

  	ngOnInit() {
  	}

}
