import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';


@Injectable()
export class LoginModalService {

	private showLoginModalReplay = new ReplaySubject<boolean>();
	public showLoginModalReplay$ = this.showLoginModalReplay.asObservable();

	private loginSuccessReplay = new ReplaySubject<boolean>();
	public loginSuccessReplay$ = this.loginSuccessReplay.asObservable();

	public isNotEnoughPermission: boolean;
	constructor() { }

	public showLoginInModal(): void {
		this.showLoginModalReplay.next(true);
	}

	public showNotEnoughPermissionLoginModal(): void {
		this.isNotEnoughPermission = true;
		this.showLoginModalReplay.next(true);
	}

	public loginSuccess(): void {
		this.showLoginModalReplay.next(false);
		this.loginSuccessReplay.next(true);
	}
}
