import { Component, Inject} from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { CustomerStore } from 'app/admin/stores/customer.store.service';
import { CustomerBladeComponent } from '../customer-blade/customer-blade.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-customers-blade',
	templateUrl: './customers-blade.component.html',
	styleUrls: ['./customers-blade.component.scss'],
})
@IBladeComponent.register
export class CustomersBladeComponent {
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: CustomerStore,
	) {
		itemStore.reset();
		if (this.bladeItem.payload?.travelAgencyId) {
			this.itemStore.getAllOnTravelAgency(this.bladeItem.payload.travelAgencyId);
		} else {
			itemStore.getAll();
		}
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: CustomerBladeComponent,
			minClass: BladeSize.xs,
			payload: {
				travelAgencyId: +this.bladeItem.payload?.travelAgencyId,
				closeWhenCreated: this.bladeItem.payload?.closeWhenCreated ?? false,
			},
		}, this.bladeItem);
	}
}
