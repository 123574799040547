import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { TourMessageStore } from 'app/admin/stores/tour-message.store.service';
import { TourMessage } from 'app/models/tour-message.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-tour-messages',
	templateUrl: './tour-messages.component.html',
	styleUrls: ['./tour-messages.component.scss'],
})
export class TourMessagesComponent implements OnInit, OnDestroy {
	public latestMessage$ = new ReplaySubject<TourMessage>(1);
	public isCreating = false;
	public editMessage = false;
	public itemForm: FormGroup = new FormGroup({
		message: new FormControl('', []),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public supplierStore: SupplierStore,
		public tourMessageStore: TourMessageStore,
	) {
		this.tourMessageStore.getAllOnTour(this.bladeItem.id).subscribe();
		this.tourMessageStore.reset();
		this.tourMessageStore.items$.pipe(untilDestroyed(this)).subscribe(messages => {
			const message = messages[messages.length - 1];
			if (message) {
				this.itemForm.get('message').setValue(message.message);
				this.latestMessage$.next(message);
			}
		});
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {

	}

	public createMessage() {
		this.isCreating = true;
		this.editMessage = false;
		const tourMessage = new TourMessage();
		tourMessage.tourId = this.bladeItem.id;
		tourMessage.message = this.itemForm.get('message').value;
		this.tourMessageStore.create(tourMessage).subscribe(() => {
			this.isCreating = false;
		});
	}
}
