import { BaseModel } from './base.model';

export class OrderPayment extends BaseModel {
	public orderId: number;
	public chargeId: string;
	public paymentTypeId: number;
	public data: any;
	public paymentId: string;
	public paymentReference: string;
	public message: string;
	public amount: number;
	public sourceTypeId: number;
}
