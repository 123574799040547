<app-blade-item [canIClose]="true" [loading]="loading">
	<app-blade-header [title]="'Tillägg'" [subTitle]="'Produkt'"></app-blade-header>
	<app-blade-content *ngIf="item">
		<h3>Tillägg för {{item.productId | productName | async}}</h3>
		<p>Vald tillägg <b>{{item.addonId | addonName | async}}</b></p>

		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<mat-label>Sätt pris som ska gälla. Orginal pris: {{(addon$ | async)?.price}}</mat-label>
				<input matInput formControlName="price" placeholder="Pris">
			</mat-form-field>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<button mat-raised-button color="warn" (click)="remove()">
			Ta bort
		</button>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
