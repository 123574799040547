import { Component, OnInit } from '@angular/core';
import { UserPageService } from '../../services/user-page.service';

@Component({
  selector: 'app-user-page-account',
  templateUrl: './user-page-account.component.html',
  styleUrls: ['./user-page-account.component.scss']
})
export class UserPageAccountComponent implements OnInit {

  	constructor(
		public userPageService: UserPageService,
	) { }

  ngOnInit() {
  }

}
