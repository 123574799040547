import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';

import { DashboardService } from '../../../../services/dashboard.service';
import { BladeService } from 'app/admin/components/blade/blade.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourBooking } from 'app/models/tour-booking.model';
import { ToursBladeComponent } from 'app/admin/blades/tour/tours-blade/tours-blade.component';
import { TourBladeComponent } from 'app/admin/blades/tour/tour-blade/tour-blade.component';
import { TourBookingBladeComponent } from 'app/admin/blades/tour-booking/tour-booking-blade/tour-booking-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-dashboard-widget-tour-bookings',
	templateUrl: './dashboard-widget-tour-bookings.component.html',
	styleUrls: ['./dashboard-widget-tour-bookings.component.scss'],
})
export class DashboardWidgetTourBookingsComponent implements OnInit, OnDestroy, AfterViewInit {
	public displayedColumns: string[] = ['id', 'name', 'source', 'status', 'created'];
	public dataSource: MatTableDataSource<TourBooking>;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private router: Router,
		private tourBookingStore: TourBookingStore,
		private bladeService: BladeService,
	) { }

	ngOnInit() {
	}

	ngAfterViewInit(): void {
		this.tourBookingStore.getAll$().pipe(untilDestroyed(this)).subscribe(tourBookings => {
			this.dataSource = new MatTableDataSource<TourBooking>(_.orderBy(tourBookings, tb => tb.created, 'desc'));
			this.dataSource.sort = this.sort;
		});
	}

	ngOnDestroy(): void { }

	public selectItem(tourBooking: TourBooking) {
		this.bladeService.setInitOverride([
			{
				id: 0,
				component: ToursBladeComponent,
				minClass: BladeSize.s,
				maxClass: BladeSize.m,
			}, {
				id: tourBooking.tourId,
				component: TourBladeComponent,
				minClass: BladeSize.m,
				maxClass: BladeSize.l,
			}, {
				id: tourBooking.id,
				component: TourBookingBladeComponent,
				minClass: BladeSize.m,
				maxClass: BladeSize.l,
			},
		]);
		this.router.navigateByUrl(`tours/list`);
	}
}
