<mat-card>
	<mat-card-title>Generellt</mat-card-title>
	<div class="mb-3">
		<p>Hämta klassnamnet på ikonen från <a href="https://fonts.google.com/icons">https://fonts.google.com/icons</a>. Ex <b>cake</b></p>
		<p>Ni kan även använda <a href="https://fontawesome.com/v5.15/icons">https://fontawesome.com/v5.15/icons</a>. Ange då båda klassnamnen. Ex <b>fas fa-glass-whiskey</b></p>
	</div>
	<form [formGroup]="specialDietBladeService.itemForm">
		<mat-form-field class="w-100">
			<input matInput formControlName="name" placeholder="Namn">
		</mat-form-field>
		<mat-form-field class="w-100">
			<input matInput formControlName="icon" placeholder="Namn på ikonen">
		</mat-form-field>
		<section class="d-flex justify-content-between">
			<mat-slide-toggle
				color="accent"
				formControlName="isActive">
				Aktiv
			</mat-slide-toggle>
			<mat-slide-toggle
				color="accent"
				formControlName="requireExtraInfo">
				Kräv extra info
			</mat-slide-toggle>
		</section>
	</form>
</mat-card>

<div class="mt-3 text-right">
	<button mat-stroked-button color="accent" (click)="specialDietBladeService.save()">
		Spara
	</button>
</div>
