import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// Angular Material
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

const material = [
	MatIconModule,
	MatDialogModule,
	MatInputModule,
	MatButtonModule,
	MatCardModule,
	MatSnackBarModule,
	MatTableModule,
	MatPaginatorModule,
	MatSortModule,
	MatFormFieldModule,
	MatSelectModule,
	MatCheckboxModule,
];

// Serives and Stores
// import { EmailQueueStore } from './stores/email-queue.store';
import { DialogEditService } from './services/dialog-edit.service';

const services = [
	DialogEditService,
];

// Components
import { TourBookingCustomerTypeComponent } from './dialogs/tour-booking-customer-type/tour-booking-customer-type.component';
import { TourBookingCustomerTypeItemComponent } from './dialogs/tour-booking-customer-type/controllers/tour-booking-customer-type-item/tour-booking-customer-type-item.component';
import { TourBookingCustomerTypeAgeItemComponent } from './dialogs/tour-booking-customer-type/controllers/tour-booking-customer-type-item/controllers/tour-booking-customer-type-age-item/tour-booking-customer-type-age-item.component';
import { TourBookingAddonComponent } from './dialogs/tour-booking-addon/tour-booking-addon.component';
import { TourBookingAddonItemComponent } from './dialogs/tour-booking-addon/controllers/tour-booking-addon-item/tour-booking-addon-item.component';
import { TourBookingPickupLocationComponent } from './dialogs/tour-booking-pickup-location/tour-booking-pickup-location.component';
import { TourBookingPickupLocationItemComponent } from './dialogs/tour-booking-pickup-location/controllers/tour-booking-pickup-location-item/tour-booking-pickup-location-item.component';
import { TourBookingSpecialDietComponent } from './dialogs/tour-booking-special-diet/tour-booking-special-diet.component';

const components = [
	TourBookingCustomerTypeComponent,
	TourBookingCustomerTypeItemComponent,
	TourBookingCustomerTypeAgeItemComponent,

	TourBookingAddonComponent,
	TourBookingAddonItemComponent,

	TourBookingPickupLocationComponent,
	TourBookingPickupLocationItemComponent,

	TourBookingSpecialDietComponent,
	TourBookingSpecialDietItemComponent,
	TourBookingSpecialDietMessageComponent,

	TourBookingMessageComponent,
];

import { PipeModule } from 'app/admin/pipes/pipe.module';
import { TourBookingSpecialDietItemComponent } from './dialogs/tour-booking-special-diet/controllers/tour-booking-special-diet-item/tour-booking-special-diet-item.component';
import { TourBookingSpecialDietMessageComponent } from './dialogs/tour-booking-special-diet/controllers/tour-booking-special-diet-message/tour-booking-special-diet-message.component';
import { TourBookingMessageComponent } from './dialogs/tour-booking-message/tour-booking-message.component';

@NgModule({
	declarations: [
		...components,
	],
	imports: [
		CommonModule,
		PipeModule,
		ReactiveFormsModule,
		...material,
  	],
	exports: [
		...components,
	],
	providers: [
		...services,
	],
})
export class DialogEditModule { }
