import { Injectable, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SpecialDietStore } from 'app/admin/stores/special-diet.store.service';
import * as _ from 'lodash';
import { combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TourBookingSpecialDietExtended } from '../../../models/tour-booking-special-diet-extended.model';
import { TourBookingSpecialDietService } from '../../../services/tour-booking-special-diet.service';

@UntilDestroy()
@Injectable()
export class TourBookingSpecialDietItemService {
	public tourBookingSpecialDietExtendedItem: TourBookingSpecialDietExtended;
	private tourBookingSpecialDietExtendedItemReplay = new ReplaySubject<TourBookingSpecialDietExtended>(1);
	public tourBookingSpecialDietExtendedItemReplay$ = this.tourBookingSpecialDietExtendedItemReplay.asObservable();
	public specialDietForm: FormGroup = new FormGroup({});

  	constructor(
		public tourBookingSpecialDietService: TourBookingSpecialDietService,
		public specialDietStore: SpecialDietStore,
	  ) {
		combineLatest([
			this.tourBookingSpecialDietExtendedItemReplay,
			this.specialDietStore.items$,
		]).pipe(untilDestroyed(this))
		.subscribe(([tourBookingSpecialDietExtendedItem, specialDiets]) => {
			specialDiets.forEach(specialDiet => {
				if (specialDiet.requireExtraInfo) {
					let controller = this.specialDietForm.get(specialDiet.id.toString());
					if (!controller) {
						this.specialDietForm.addControl(specialDiet.id.toString(), new FormControl(null));
						controller = this.specialDietForm.get(specialDiet.id.toString());
						const find = tourBookingSpecialDietExtendedItem?.tourBookingSpecialDiets?.find(x => x.specialDietId === specialDiet.id);
						if (find) {
							controller.setValue(find.extraInfo);
						}
						controller
							.valueChanges
							.pipe(untilDestroyed(this), debounceTime(500))
							.subscribe(value => {
								const findTourBookingSpecialDiet = tourBookingSpecialDietExtendedItem?.tourBookingSpecialDiets?.find(x => x.specialDietId === specialDiet.id);
								if (findTourBookingSpecialDiet) {
									findTourBookingSpecialDiet.extraInfo = value;
								}
							});
					}
				}
			});
		});
	}

	public setTourBookingSpecialDietExtendedItem(tourBookingSpecialDietExtendedItem: TourBookingSpecialDietExtended) {
		this.tourBookingSpecialDietExtendedItem = tourBookingSpecialDietExtendedItem;
		this.tourBookingSpecialDietExtendedItemReplay.next(this.tourBookingSpecialDietExtendedItem);
	}

	public isChecked(specialDietId: number): boolean {
		return !!_.find(this.tourBookingSpecialDietExtendedItem?.tourBookingSpecialDiets, x => x.specialDietId === specialDietId);
	}

	public toggleSpecialDiet(specialDietId: number) {
		const isChecked = this.isChecked(specialDietId);
		if (isChecked) {
			this.tourBookingSpecialDietService.removeTourBookingSpecialDiet(this.tourBookingSpecialDietExtendedItem, specialDietId);
		} else {
			this.tourBookingSpecialDietService.addTourBookingSpecialDiet(this.tourBookingSpecialDietExtendedItem, specialDietId);
		}
	}
}
