import { Pipe, PipeTransform } from '@angular/core';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'min' })
export class MinPipe implements PipeTransform {
	public transform(value: any, ...args: any[]): number {
		const max: number = Number(String(args.shift() || 0)) || value;
		const min: number = Math.min(value, max);
		return args.length ? this.transform(min, ...args) : min;
	}
}
