<ng-container *ngIf="tourBookingsReplay | async as tourBookings">
	<ng-container *ngIf="tourBookings.length">
		<div class="d-flex justify-content-between">
			<h3>Meddelande</h3>
			<div>
				<button mat-icon-button (click)="accordion.openAll()">
					<mat-icon>unfold_more</mat-icon>
				</button>
				<button mat-icon-button (click)="accordion.closeAll()">
					<mat-icon>unfold_less</mat-icon>
				</button>
			</div>
		</div>
		<mat-accordion multi>
			<mat-expansion-panel expanded *ngFor="let tourBooking of tourBookings">
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{tourBooking.customerId | customerName | async}}
					</mat-panel-title>
					<mat-panel-description>

					</mat-panel-description>
				</mat-expansion-panel-header>
				<mat-card class="mat-elevation-z3">
					<p [innerHtml]="tourBooking.message | newLine"></p>
				</mat-card>
			</mat-expansion-panel>
		</mat-accordion>
	</ng-container>
</ng-container>

