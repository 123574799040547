import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MediaStore } from 'app/admin/stores/media.store.service';
import { Media } from 'app/models/media.model';
import { MediaTypeStore } from 'app/admin/stores/media-type.store.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UploadMediaBladeComponent } from '../upload-media-blade/upload-media-blade.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-media-blade',
	templateUrl: './media-blade.component.html',
	styleUrls: ['./media-blade.component.scss'],
})
@IBladeComponent.register
export class MediaBladeComponent implements OnInit, OnDestroy {
	public listView = true;
	public searchString = '';
	public loading = true;
	public canIClose: Function | boolean;
	public items: Media[];
	public filteredItems$: ReplaySubject<Media[]> = new ReplaySubject<Media[]>();
	public selection = new SelectionModel<Media>(true, []);


	public displayedColumns: string[] = ['select', 'media', 'name'];
	public dataSource: MatTableDataSource<Media>;

	public itemForm: FormGroup = new FormGroup({
		searchString: new FormControl('', [Validators.nullValidator]),
		mediaType: new FormControl('', [Validators.nullValidator]),
	});

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public itemStore: MediaStore,
		public mediaTypeStore: MediaTypeStore,
		public bladeService: BladeService,
	) {
		this.itemForm.get('searchString').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.dataSource.filter = value;
		});
		this.itemForm.get('mediaType').setValue(0);
		this.itemForm.get('mediaType').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			let filterItems = this.items;
			if (value > 0) {
				filterItems = _.filter(this.items, item => item.mediaTypeId === value);
			}
			this.setMediaList(filterItems);
		});
	}

	ngOnInit() {
		this.itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.items = _.orderBy(items, i => i.created, 'desc');
			this.setMediaList(this.items);
			this.loading = false;
		});
		this.itemStore.getAll();
	}

	ngOnDestroy(): void {}

	public addNewMedia() {
		this.bladeService.addBladeItem({
			id: 0,
			component: UploadMediaBladeComponent,
			minClass: BladeSize.s,
			payload: {
				mediaTypes: 'image',
			},
		}, this.bladeItem);
	}

	public useSelectedMedia() {
		if (!this.bladeItem.parent || !this.bladeItem.parent.callback) {
			console.error('Missing callback on parent');
			return;
		}
		this.bladeItem.parent.callback(this.selection.selected, this.bladeItem.payload);
		this.bladeItem.bladeItemComponent.closeMe();
	}

	private setMediaList(items: Media[]) {
		this.dataSource = new MatTableDataSource<Media>(items);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.filteredItems$.next(items);
	}
}
