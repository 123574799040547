
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Page } from 'app/models/site/page.model';
import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { PrioBaseStore } from './prio-base.store.service';

@Injectable({
	providedIn: 'root',
})
export class PageStore extends PrioBaseStore<Page> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Pages', baseUrl, snackBarService);
	}

	public getOnElement(elementId: number): Observable<Page> {
		return this.http.get<Page>(`${this.host}/element/${elementId}`);
	}
}
