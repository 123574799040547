import { Component, OnInit, Inject, Input } from '@angular/core';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { CustomerStore } from 'app/admin/stores/customer.store.service';
import { TourBooking } from 'app/models/tour-booking.model';
import { Customer } from 'app/models/customer.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { CustomerBladeComponent } from 'app/admin/blades/customer/customer-blade/customer-blade.component';

@Component({
  selector: 'app-tour-booking-blade-customer',
  templateUrl: './tour-booking-blade-customer.component.html',
  styleUrls: ['./tour-booking-blade-customer.component.scss'],
})
export class TourBookingBladeCustomerComponent implements OnInit {
	@Input() item: TourBooking;
	public customer: Customer;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private customerStore: CustomerStore,
	) { }

  	ngOnInit() {
		this.customerStore.get(this.item.customerId).subscribe(customer => {
			this.customer = customer;
		});
	}

	public openCustomer() {
		window.open('/customer?customerId=' + this.item.customerId, '_blank');
	// 	this.bladeService.addBladeItem({
	// 		id: this.customer.id,
	// 		component: CustomerBladeComponent,
	// 		minClass: BladeSize.m,
	// 		maxClass: BladeSize.l,
	// 	}, this.bladeItem);
	}
}
