import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import * as moment from 'moment';
import * as _ from 'lodash';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Tour } from 'app/models/tour.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { SelectProductBladeComponent } from 'app/admin/blades/product/select-product-blade/select-product-blade.component';
import { Product } from 'app/models/product/product.model';
import { TourStatusStore } from 'app/admin/stores/tour-status.store.service';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { Supplier } from 'app/models/supplier.model';

@UntilDestroy()
@Component({
	selector: 'app-tour-generally',
	templateUrl: './tour-generally.component.html',
	styleUrls: ['./tour-generally.component.scss'],
})
export class TourGenerallyComponent implements OnInit {
	public filterProducts$ = new ReplaySubject<Product[]>(1);
	private products: Product[];
	public filterSuppliers$ = new ReplaySubject<Supplier[]>(1);
	private suppliers: Supplier[];
	@Input() tour: Tour;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl(''),
		isActive: new FormControl(''),
		isPrivate: new FormControl(''),
		date: new FormControl(''),
		time: new FormControl(''),
		availablePlaces: new FormControl(0),
		timeLength: new FormControl(0),
		tourStatusId: new FormControl(1),
		supplierId: new FormControl(''),
		productId: new FormControl(''),
		search: new FormControl(''),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public tourStatusStore: TourStatusStore,
		public supplierStore: SupplierStore,
		public productStore: ProductStore,
	) {
		this.bladeItem.callback = (selectedItem: Product, type: string) => {
			if (type === 'product') {
				this.tour.productId = selectedItem.id;
			}
		};
		this.productStore.items$.pipe(untilDestroyed(this)).subscribe(products => {
			this.products = products;
			this.filterProducts();
		});

		this.supplierStore.items$.pipe(untilDestroyed(this)).subscribe(suppliers => {
			this.suppliers = suppliers;
			this.filterSuppliers();
		});

		this.itemForm.get('supplierId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			if (isNaN(value)) {
				this.filterSuppliers(value);
			} else {
				this.tour.supplierId = value;
			}
		});

		this.itemForm.get('productId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			if (isNaN(value)) {
				this.filterProducts(value);
			} else {
				this.tour.productId = value;
				const selectedProduct = this.products?.find(x => x.id === value);
				if (selectedProduct?.supplierID) {
					this.itemForm.get('supplierId').setValue(selectedProduct?.supplierID);
				}
			}
		});
	}

	ngOnInit() {
		this.itemForm.patchValue(this.tour);

		if (this.tour.date && !_.includes(this.tour.date.toString(), '0001-01-01')) {
			this.itemForm.get('date').setValue(moment(this.tour.date).format('YYYY-MM-DD'));
		}
		this.itemForm.get('time').setValue(this.tour.time);
		this.itemForm.get('tourStatusId').setValue(this.tour.tourStatusId);

		this.itemForm.get('tourStatusId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.tour.tourStatusId = value;
		});
		this.itemForm.get('name').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.tour.name = value;
		});
		this.itemForm.get('availablePlaces').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.tour.availablePlaces = value;
		});
		this.itemForm.get('timeLength').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			console.log('timeLength', value);
			this.tour.timeLength = value;
		});
		this.itemForm.get('isActive').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.tour.isActive = value;
		});
		this.itemForm.get('isPrivate').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.tour.isPrivate = value;
		});
		this.itemForm.get('date').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.tour.date = value;
		});
		this.itemForm.get('time').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.tour.time = value;
		});
	}

	public selectProduct() {
		this.bladeService.addBladeItem({
			id: 0,
			component: SelectProductBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	public displayProductFn(id): string {
		if (!id) {
			 return '';
		}
		const selectedProduct = _.find(this.products, product => product.id === id);
		return selectedProduct.name;
	}

	public displaySupplierFn(id): string {
		if (!id) {
			 return '';
		}
		const selectedSupplier = _.find(this.suppliers, supplier => supplier.id === id);
		return selectedSupplier.name;
	}

	private filterProducts(value?: string): void {
		const filterValue = value?.toLowerCase();
		let filterProducts = this.products;
		if (filterValue) {
			filterProducts = _.filter(this.products, x => x.name.toLocaleLowerCase().includes(filterValue));
		}
		this.filterProducts$.next(_.orderBy(filterProducts, ['name'], ['asc']));
	}

	private filterSuppliers(value?: string): void {
		const filterValue = value?.toLowerCase();
		let filterSuppliers = this.suppliers;
		if (filterValue) {
			filterSuppliers = _.filter(this.suppliers, x => x.name.toLocaleLowerCase().includes(filterValue));
		}
		this.filterSuppliers$.next(_.orderBy(filterSuppliers, ['name'], ['asc']));
	}
}
