import { Injectable } from '@angular/core';
import { AdminData } from '../admin-data';

@Injectable()
export class AdminDataStore {

	private adminData = new AdminData();

	constructor() { }

	public getAdminText() {
		return this.adminData.text;
	}
}
