<ng-container *ngIf="{
	adminText: 'dashboard' | adminText | async
} as source">
	<h1>{{source.adminText?.title}}</h1>
	<form [formGroup]="formGroup" class="edit-dashboard-panel">
		<mat-form-field *ngIf="!isChangeName">
			<mat-select formControlName="selectedDashboard">
				<mat-option (click)="newDashboard()">{{source.adminText?.createNew}}</mat-option>
				<mat-option *ngFor="let dashboard of dashboardService.dashboardsReplay$ | async" (click)="selectDashboard(dashboard)" [value]="dashboard">
					{{dashboard.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field *ngIf="isChangeName">
			<input matInput formControlName="name" placeholder="Dashboard namn" autofocus>
		</mat-form-field>

		<button mat-button *ngIf="!isEditMode && selectedDashboard" (click)="editMode()">{{source.adminText?.edit}}</button>
		<button mat-button *ngIf="isEditMode && !isChangeName" (click)="changeName()">{{source.adminText?.changeName}}</button>
		<button mat-button *ngIf="isEditMode && isChangeName" (click)="saveName()">{{source.adminText?.saveName}}</button>
		<button *ngIf="isEditMode" mat-button [matMenuTriggerFor]="widgetMenu"
			aria-label="Toggle menu">
			{{source.adminText?.add}} <mat-icon>arrow_drop_down</mat-icon>
		</button>

		<mat-menu #widgetMenu="matMenu" xPosition="before">
			<button mat-menu-item *ngFor="let widget of dashboardWidgetTypeStore.items$ | async" (click)="addWidget(widget)">{{widget.name}}</button>
		</mat-menu>

		<mat-slide-toggle *ngIf="isEditMode" [color]="'primary'" formControlName="isActive">
			{{source.adminText?.useAsStandard}}
		</mat-slide-toggle>
		<mat-slide-toggle *ngIf="isEditMode" class="ml-3" [color]="'primary'" formControlName="isGlobal">
			{{source.adminText?.makeThisGlobal}}
		</mat-slide-toggle>

		<button mat-button *ngIf="isEditMode" (click)="delete()" [color]="'warn'">{{source.adminText?.delete}}</button>
		<button mat-button *ngIf="isEditMode && selectedDashboard" (click)="stopEditMode()" [color]="'accent'">{{source.adminText?.stopEdit}}</button>
	</form>
	<gridster [options]="options" [class.edit]="isEditMode">
		<gridster-item [item]="item" *ngFor="let item of selectedDashboard?.widgets" class="mat-elevation-z3">
			<app-dashboard-widget [item]="item" [isHandset]="isHandset$ | async"></app-dashboard-widget>
		</gridster-item>
	</gridster>
</ng-container>
