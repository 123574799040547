import { Inject, Injectable } from '@angular/core';

import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import _sum from 'lodash-es/sum';

import { TourBooking } from 'app/models/tour-booking.model';
import { Tour } from 'app/models/tour.model';
import { TourStore } from '../../../../stores/tour.store.service';
import { TourBookingCustomerTypeStore } from 'app/admin/stores/tour-booking-customer-type.store.service';
import { TourBookingCustomerType } from '../../../../../models/tour-booking-customer-type.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { TourBookingBladeComponent } from 'app/admin/blades/tour-booking/tour-booking-blade/tour-booking-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { CustomerBladeComponent } from 'app/admin/blades/customer/customer-blade/customer-blade.component';
import { TourBladeComponent } from 'app/admin/blades/tour/tour-blade/tour-blade.component';
import { OrderBladeComponent } from 'app/admin/blades/order/order-blade/order-blade.component';

@UntilDestroy()
@Injectable()
export class OrderTourBookingItemService {
	public tourBooking: TourBooking;
	private tourBookingReplay = new ReplaySubject<TourBooking>(1);
	public tourBookingReplay$ = this.tourBookingReplay.asObservable();

	public tourBookingCustomerTypes: TourBookingCustomerType[];
	private tourBookingCustomerTypesReplay = new ReplaySubject<TourBookingCustomerType[]>(1);
	public tourBookingCustomerTypesReplay$ = this.tourBookingCustomerTypesReplay.asObservable();

	public quantity: number;
	private quantityReplay = new ReplaySubject<number>(1);
	public quantityReplay$ = this.quantityReplay.asObservable();

	public tour: Tour;
	private tourReplay = new ReplaySubject<Tour>(1);
	public tourReplay$ = this.tourReplay.asObservable();

	constructor(
		private tourStore: TourStore,
		private tourBookingCustomerTypeStore: TourBookingCustomerTypeStore,
		private bladeService: BladeService,
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
	) { }

	public setTourtBooking(tourBooking: TourBooking) {
		this.tourBooking = tourBooking;
		this.tourBookingReplay.next(this.tourBooking);

		this.tourStore.get(tourBooking.tourId).pipe(untilDestroyed(this)).subscribe(tour => {
			this.tour = tour;
			this.tourReplay.next(this.tour);
		});

		this.tourBookingCustomerTypeStore.getAllOnTourBooking(tourBooking.id).pipe(untilDestroyed(this)).subscribe(tourBookingCustomerTypes => {
			this.tourBookingCustomerTypes = tourBookingCustomerTypes;
			this.tourBookingCustomerTypesReplay.next(this.tourBookingCustomerTypes);
			this.quantity = _sum(this.tourBookingCustomerTypes.map(x => x.quantity));
			this.quantityReplay.next(this.quantity);
		});
	}

	public openCustomer(id: number) {
		this.bladeService.addBladeItem({
			id,
			component: CustomerBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public openOrder(id: number) {
		this.bladeService.addBladeItem({
			id,
			component: OrderBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public openTour(id: number) {
		this.bladeService.addBladeItem({
			id,
			component: TourBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public openTourBooking(id: number) {
		this.bladeService.addBladeItem({
			id,
			component: TourBookingBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}
}
