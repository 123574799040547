import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { TourBookingPickupLocation } from 'app/models/tour-booking-pickup-location.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class TourBookingPickupLocationStore extends BaseStore<TourBookingPickupLocation> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TourBookingPickupLocation', baseUrl, snackBarService);
	}

	public getAllOnTourBooking(tourBookingId: number): Observable<TourBookingPickupLocation[]> {
		return this.http.get<TourBookingPickupLocation[]>(`${this.host}/tourBooking/${tourBookingId}`).pipe(tap(tourBookings => {
			this._items = tourBookings;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnTourBooking$(tourBookingId: number): Observable<TourBookingPickupLocation[]> {
		return this.http.get<TourBookingPickupLocation[]>(`${this.host}/tourBooking/${tourBookingId}`);
	}
}
