import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { CustomerTypeStore } from '../stores/customer-type.store.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'customerTypeName' })
export class CustomerTypeNamePipe implements PipeTransform, OnDestroy {
	constructor(private customerTypeStore: CustomerTypeStore) {	}
	public transform(customerTypeId: number, languageId?: number): Observable<string> {
		if (!customerTypeId) {
			return;
		}
		return this.customerTypeStore.pipeGetName(customerTypeId, languageId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
