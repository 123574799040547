import { Component, OnInit, Inject } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { CategoryBladeService } from '../../services/category-blade.service';

@Component({
  selector: 'app-category-blade-generally',
  templateUrl: './category-blade-generally.component.html',
  styleUrls: ['./category-blade-generally.component.scss'],
})
export class CategoryBladeGenerallyComponent implements OnInit {

  constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		public categoryBladeService: CategoryBladeService,
	) { }

  ngOnInit() {
  }
}
