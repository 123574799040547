import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject, ViewContainerRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

import { BladeMenuItem } from '../../../models/bladeMenuItem.model';
import { BladeService, BladeItemInjectToken } from '../../../blade.service';
import { BladeItem } from '../../../models/bladeItem.model';
import { BladeSize } from '../../../models/bladeSize.model';
import { BladeContentService } from './blade-content.service';
import { BladeContentTabComponent } from './components/blade-content-tabs/components/blade-content-tab/blade-content-tab.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-blade-content',
	templateUrl: './blade-content.component.html',
	styleUrls: ['./blade-content.component.scss'],
	providers: [BladeContentService],
})
export class BladeContentComponent implements OnInit, OnDestroy {
	@ViewChild('sidenav') sidenav: MatSidenav;

	public hasIcon: boolean;

	public menuIndex = 0;
	public items: any[];
	public BladeSize = BladeSize;
	public isHandset$: Observable<boolean> = this.breakpointObserver
		.observe(Breakpoints.Handset)
		.pipe(map(result => {
			this.isHandset = result.matches;
			return result.matches;
		}));

	@Output() indexChange = new EventEmitter<number>();

	@Input()
	set menuItems(items: BladeMenuItem[]) {
		this.items = items;
		if (!this.menuIndex && this.items && this.items.length) {
			this.menuIndex = this.items[0].index;
		}
	}

	private closeOnClick = true;
	private isHandset: boolean;

	constructor(
		private breakpointObserver: BreakpointObserver,
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public bladeContentService: BladeContentService,
	) {
		if (!this.menuIndex && bladeItem.menuIndex) {
			this.menuIndex = bladeItem.menuIndex;
			setTimeout(() => { // Emit changes on next threed. Cant move to OnInit. The @Output() indexChange will run and set this.menuIndex to 1.
				this.indexChange.emit(this.menuIndex);
			});
		}
		this.bladeContentService.tabs$.pipe(untilDestroyed(this)).subscribe(tabs => {
			this.hasIcon = !!_.find(tabs, tab => tab.iconName);
		});
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	selectMenuItem(item: BladeMenuItem) {
		if (!item.disabled) {
			this.menuIndex = item.index;
			this.bladeItem.menuIndex = this.menuIndex;
			this.bladeService.bladeItemsIsChanged();
			this.indexChange.emit(this.menuIndex);
			if (this.closeOnClick && this.sidenav.opened && this.sidenav.mode === 'over') {
				this.sidenav.toggle();
			}
		}
	}

	selectMenuTab(item: BladeContentTabComponent) {
		if (!item.disabled) {
			this.bladeContentService.selectTab(item);
			this.menuIndex = item.index;
			this.bladeItem.menuIndex = this.menuIndex;
			item.isActive = true;
			this.bladeService.bladeItemsIsChanged();
			this.indexChange.emit(this.menuIndex);
			if (this.closeOnClick && this.sidenav.opened && this.sidenav.mode === 'over') {
				this.sidenav.toggle();
			}
		}
	}

	sidenavToggle() {
		this.sidenav.toggle();
	}
}
