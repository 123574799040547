<ng-container *ngIf="{item: productFunctionService.itemReplay$ | async} as source">
	<app-blade-item [canIClose]="true" [loading]="productFunctionService.loadingReplay$ | async" [id]="source.item?.id">
		<app-blade-header [title]="source.item?.name || 'Ny'" [subTitle]="'Produkt udda-funktion'"></app-blade-header>
		<app-blade-content>
			<app-alert-card mode="info" label="Med denna funktion så hanterar ni vad som ska hända om kunden väljer udda antal. Om kunden väljer udda så kan ni antingen förbjuda det eller kräfa ett visst tillägg."></app-alert-card>
			<app-blade-content-tabs>
				<app-blade-content-tab [title]="'Hantering'">
					<app-odd-function-blade-generally></app-odd-function-blade-generally>
				</app-blade-content-tab>
				<app-blade-content-tab [title]="'Tillägg'">
					<app-odd-function-blade-addons></app-odd-function-blade-addons>
				</app-blade-content-tab>
				<app-blade-content-tab [title]="'Meddelande'">
					<app-odd-function-blade-messages></app-odd-function-blade-messages>
				</app-blade-content-tab>
				<app-blade-content-tab [title]="'Kopplade produkter'" [template]="products">
					<ng-template #products>
						<app-odd-function-blade-products></app-odd-function-blade-products>
					</ng-template>
				</app-blade-content-tab>
			</app-blade-content-tabs>
		</app-blade-content>
		<app-blade-footer>
			<span fxFlex></span>
			<button mat-raised-button color="primary" [disabled]="!productFunctionService.itemForm.valid" (click)="productFunctionService.save()">
				<span *ngIf="source.item?.id">Spara</span>
				<span *ngIf="!source.item?.id">Skapa</span>
			</button>
		</app-blade-footer>
	</app-blade-item>
</ng-container>
