<app-blade-item [canIClose]="true" [loading]="loading">
	<app-blade-header [title]="item?.name||'Ny'" [subTitle]="'Hämtningsplats'"></app-blade-header>
	<app-blade-content *ngIf="item?.id">
		<app-blade-content-tabs>
			<app-blade-content-tab [title]="'Generellt'">
				<app-pickup-location-blade-generally [item]="item"></app-pickup-location-blade-generally>
				<app-pickup-location-blade-geo [item]="item"></app-pickup-location-blade-geo>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Texter'">
				<app-pickup-location-blade-texts></app-pickup-location-blade-texts>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Priser'">
				<app-pickup-location-blade-prices></app-pickup-location-blade-prices>
			</app-blade-content-tab>

		</app-blade-content-tabs>
	</app-blade-content>
	<app-blade-content *ngIf="!item?.id">
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="name" placeholder="Namn">
			</mat-form-field>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
