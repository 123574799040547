import { BaseModel } from './base.model';

export class ButtonElement extends BaseModel {
	public elementId?: number;
	public languageId?: number;
	public buttonType?: string;
	public text?: string;
	public icon?: string;
	public color?: string;
	public link?: string;
}
