<mat-card>
	<mat-card-title>Bilder</mat-card-title>
	<mat-card>
		<mat-card-title>Presentationsbild</mat-card-title>
		<mat-card-subtitle>Denna bild kommer visas i listor och som första bild på produktens presentationssida.</mat-card-subtitle>

		<div class="row">
			<div class="col-md-6">
				<app-image *ngIf="presentationImage?.imageId" [maxHeigth]="100" [maxWidth]="100" [mediaId]="presentationImage?.imageId"></app-image>
			</div>
			<div class="col-md-6 text-right">
				<button mat-stroked-button color="accent" (click)="selectPresentationImage()">
					<span *ngIf="presentationImage?.imageId">Ändra presentationsbild</span>
					<span *ngIf="!presentationImage?.imageId">Välj presentationsbild</span>
				</button>
				<button *ngIf="presentationImage?.imageId" mat-stroked-button color="warn" (click)="deleteImage(presentationImage)" class="ml-2">
					Ta bort <mat-icon>delete_forever</mat-icon>
				</button>
			</div>
		</div>
	</mat-card>

	<mat-card class="mt-3">
		<mat-card-title>Bildspel</mat-card-title>
		<mat-card-subtitle>Dessa bilder kommer visas som ett bildspel på produktens presentationssida</mat-card-subtitle>

		<mat-table [dataSource]="dataSource" class="w-100" *ngIf="dataSource?.data?.length">
			<ng-container matColumnDef="id">
				<mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
				<mat-cell *matCellDef="let item">{{item.id || 'Ny'}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="media">
				<mat-header-cell *matHeaderCellDef>Media</mat-header-cell>
				<mat-cell *matCellDef="let item">
					<app-image class="my-1" [maxHeigth]="75" [maxWidth]="100" [mediaId]="item.imageId"></app-image>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="prio">
				<mat-header-cell *matHeaderCellDef>Prio</mat-header-cell>
				<mat-cell *matCellDef="let item">{{item.prio}}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="actions">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let item" class="d-block text-right">
					<button mat-icon-button color="warn" (click)="deleteImage(item)">
						<mat-icon>delete_forever</mat-icon>
					</button>
				</mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let item; columns: displayedColumns;"></mat-row>
		</mat-table>

		<mat-card-footer class="p-3 text-right">
			<button mat-stroked-button color="accent" (click)="selectImage()">
				Lägg till bilder från mediabanken
			</button>
		</mat-card-footer>
	</mat-card>
</mat-card>
