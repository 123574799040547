import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';

declare global {
	interface Window { trackingId: any; }
}

@Injectable()
export class AnalyticsService {

	// private appInsights: ApplicationInsights;
	private renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

	constructor(
		@Inject(DOCUMENT) private document,
		private angulartics: Angulartics2GoogleGlobalSiteTag,
		private rendererFactory: RendererFactory2,
	) {

		// this.appInsights = new ApplicationInsights({ config: {
		// 		instrumentationKey: '4865948b-8428-47cc-929a-58adb8d15f07', // TODO Make this dynamic?
		// 	},
		// });
		// this.appInsights.loadAppInsights();
	}

	// public startTrack(router: Router) {
	// 	router.events.subscribe(event => {
	// 		if (event instanceof NavigationEnd) {
	// 			this.appInsights.trackPageView();
	// 		}
	// 	});
	// }

	public registerGoogleAnalytics(trackingId: string) {
		if (trackingId && !window.trackingId) {
			this.writeGtagScript(trackingId);
		}
	}

	private writeGtagScript(trackingId: string) {
		const script = this.renderer.createElement('script');
		const text = this.renderer.createText(
		  // tslint:disable-next-line:max-line-length
		  `window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}gtag('js',new Date());gtag('config', '${trackingId}', { 'send_page_view': false })`,
		);

		this.renderer.appendChild(script, text);
		this.renderer.appendChild(this.document.head, script);

		const scriptPath = this.renderer.createElement('script');
		scriptPath.src = `https://www.googletagmanager.com/gtag/js?id='${trackingId}'`;
		scriptPath.async = true;

		this.renderer.appendChild(this.document.head, scriptPath);
	}
}
