import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrderStatusStore } from '../stores/order-status.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'orderStatusName' })
export class OrderStatusNamePipe implements PipeTransform, OnDestroy {
	constructor(private orderStatusStore: OrderStatusStore) { }

	public transform(orderStatusId: number): Observable<string> {
		if (!orderStatusId) {
			return;
		}
		return this.orderStatusStore.pipeGet(orderStatusId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
