import { Component, OnInit, Input, HostBinding, ChangeDetectorRef, OnDestroy, Injector, HostListener, ElementRef } from '@angular/core';
import { Portal, ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { BladeItem } from '../../../models/bladeItem.model';
import { BladeItemInjectToken } from '../../../blade.service';
import { BladePositionService } from '../../../blade-position.service';

@Component({
  selector: 'app-blade-component',
  templateUrl: './blade-component.component.html',
  styleUrls: ['./blade-component.component.scss'],
})
export class BladeComponentComponent implements OnInit, OnDestroy {
	public portal: Portal<any>;
	public thisBladeItem: BladeItem;

	@Input()
	set bladeItem(bladeItem: BladeItem) {
		bladeItem.elementRef = this.elementRef;
		this.thisBladeItem = bladeItem;
		this.portal = new ComponentPortal(this.thisBladeItem.component, null, this.createInjector(this.thisBladeItem));
	}

	// alternatively also the host parameter in the @Component()` decorator can be used
	@HostBinding('class.show') addClass = false;

	@HostListener('click') onClick() {
		this.selectThis();
	}

	constructor(
		private cdRef: ChangeDetectorRef,
		private injector: Injector,
		private bladePositionService: BladePositionService,
		private elementRef: ElementRef,
	) {}

	ngOnInit() {
	  this.addClass = true;
	  this.cdRef.detectChanges();
	}

	ngOnDestroy(): void { }

	createInjector(dataToPass): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(BladeItemInjectToken, dataToPass);
		return new PortalInjector(this.injector, injectorTokens);
	}

	public selectThis() {
		this.bladePositionService.selectBladeItem(this.thisBladeItem);
	}
}
