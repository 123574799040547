
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { ConfirmationElement } from 'app/models/confirmation-element.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ConfirmationElementStore extends BaseStore<ConfirmationElement> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ConfirmationElements', baseUrl, snackBarService);
	}

	public getOnElement(elementId: number): Observable<ConfirmationElement> {
		return this.http.get<ConfirmationElement>(`${this.host}/element/${elementId}`);
	}
}
