import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingCustomerTypeAge } from 'app/models/tour-booking-customer-type-age.model';
import * as moment from 'moment';
import { TourBookingCustomerTypeItemService } from '../../services/tour-booking-customer-type-item.service';

@UntilDestroy()
@Component({
  selector: 'app-tour-booking-customer-type-age-item',
  templateUrl: './tour-booking-customer-type-age-item.component.html',
  styleUrls: ['./tour-booking-customer-type-age-item.component.scss']
})
export class TourBookingCustomerTypeAgeItemComponent implements OnInit {
	public currentTourBookingCustomerTypeAge: TourBookingCustomerTypeAge;
	@Input() set tourBookingCustomerTypeAge(tourBookingCustomerTypeAge: TourBookingCustomerTypeAge) {
		this.currentTourBookingCustomerTypeAge = tourBookingCustomerTypeAge;
		this.itemForm.get('birthDate').setValue(moment(this.currentTourBookingCustomerTypeAge.birthDate).format('YYYY-MM-DD'));
	}

	public itemForm: FormGroup = new FormGroup({
		birthDate: new FormControl('', [Validators.required]),
	});

	constructor(
		public tourBookingCustomerTypeItemService: TourBookingCustomerTypeItemService,
	) { }

  	ngOnInit() {
		this.itemForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			Object.assign(this.currentTourBookingCustomerTypeAge, value);
		});
  	}

	public delete() {

	}
}
