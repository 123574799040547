
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SourceType } from 'app/models/source-type.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class SourceTypeStore extends BaseStore<SourceType> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SourceType', baseUrl, snackBarService);
	}
}
