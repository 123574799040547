import { BaseModel } from '../base.model';
import { Column } from './column.model';
import { Style } from './style.model';

export class Etage extends BaseModel {
	public name: string;
	public pageId?: number;
	public columns: Column[];
	public prio?: number;
	public classes?: string[];
	public style?: any;

	public etageBackground?: {
		path: string;
		data: any;
	};
}
