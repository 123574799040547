import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { Language } from 'app/models/language.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerTypeTextStore } from 'app/admin/stores/customer-type-text.store.service';
import { CustomerTypeText } from 'app/models/customer-type-text.model';
import { CustomerTypeTextBladeComponent } from 'app/admin/blades/customer-type-text/customer-type-text-blade/customer-type-text-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-customer-type-blade-texts',
	templateUrl: './customer-type-blade-texts.component.html',
	styleUrls: ['./customer-type-blade-texts.component.scss'],
})
export class CustomerTypeBladeTextsComponent implements OnInit, OnDestroy {
	public languages: Language[];
	public customerTypeTexts: CustomerTypeText[];
	public displayedColumns: string[] = ['id', 'headline', 'created', 'modified', 'language'];
	public dataSource: MatTableDataSource<CustomerTypeText>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public languageStore: LanguageStore,
		private customerTypeTextStore: CustomerTypeTextStore,
	) {}

	ngOnInit() {
		this.customerTypeTextStore.items$.pipe(untilDestroyed(this)).subscribe(customerTypeTexts => {
			this.customerTypeTexts = customerTypeTexts;
			this.handleCategory();
		});
		this.customerTypeTextStore.getAllOnCustomerType(this.bladeItem.id).subscribe(customerTypeTexts => {
			this.customerTypeTexts = customerTypeTexts;
			this.handleCategory();
		});
		this.languageStore.items$.pipe(untilDestroyed(this)).subscribe(languages => {
			this.languages = languages;
			this.handleCategory();
		});
	}

	ngOnDestroy(): void { }

	private handleCategory() {
		if (this.customerTypeTexts && this.languages) {
			// Every language must have a customerTypeText
			_.each(this.languages, language => {
				const customerTypeTexts = _.filter(this.customerTypeTexts, text => text.languageId === language.id);
				let customerTypeText;
				if (customerTypeTexts && customerTypeTexts.length > 1) {
					customerTypeText = _.find(this.customerTypeTexts, text => text.languageId === language.id && text.id > 0);
					this.customerTypeTextStore.removeCustomerTypeText(_.find(this.customerTypeTexts, text => text.languageId === language.id && !text.id));
				} else if (customerTypeTexts && customerTypeTexts.length) {
					customerTypeText = customerTypeTexts[0];
				}

				if (!customerTypeText) {
					customerTypeText = new CustomerTypeText();
					customerTypeText.languageId = language.id;
					this.customerTypeTextStore.addCustomerTypeText(customerTypeText);
				}
				customerTypeText.language = language.name;
			});
			this.dataSource = new MatTableDataSource<CustomerTypeText>(this.customerTypeTexts);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}

	public selectItem(row: CustomerTypeText) {
		this.bladeService.addBladeItem({
			id: 0,
			component: CustomerTypeTextBladeComponent,
			minClass: BladeSize.m,
			payload: {
				customerTypeId: this.bladeItem.id,
				languageId: row.languageId,
			},
		}, this.bladeItem);
	}
}
