<app-blade-item [canIClose]="true" *ngIf="{
	selectedProductId: selectProductToOrderBladeService.selectedProductIdBehavior$ | async,
	selectedSupplierId: selectProductToOrderBladeService.selectedSupplierIdBehavior$ | async,
	user: selectProductToOrderBladeService.userService.user$ | async,
	productDates: selectProductToOrderBladeService.productDateStore.items$ | async,
	times: selectProductToOrderBladeService.avalibleTimesReplay | async,
	selectedDate: selectProductToOrderBladeService.selectedDateReplay | async,
	errorMessages: selectProductToOrderBladeService.errorMessagesBehavior$ | async
} as source">
	<app-blade-header [title]="bladeTitle"></app-blade-header>
	<app-blade-content>

		<mat-card class="mb-3" *ngIf="bladeItem.payload.mode === 0">
			<mat-card-title>Produkt</mat-card-title>
			<mat-card-content>
				<form [formGroup]="selectProductToOrderBladeService.itemFilterForm" class="mb-2">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Produkt</mat-label>
						<input type="text"
							placeholder="Välj product"
							matInput
							formControlName="productId"
							[matAutocomplete]="product">
						<mat-autocomplete #product="matAutocomplete" [displayWith]="displayProductFn.bind(this)">
							<mat-option *ngFor="let type of (filterProducts$ | async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>

					<mat-form-field class="w-100" appearance="outline" *ngIf="bladeItem.payload.mode === CreateOrderModeEnum.newOrder">
						<mat-label>Leverantör</mat-label>
						<input type="text"
							placeholder="Välj leverantör"
							matInput
							formControlName="supplierId"
							[matAutocomplete]="supplier">
						<mat-autocomplete #supplier="matAutocomplete" [displayWith]="displaySupplierFn.bind(this)">
							<mat-option *ngFor="let type of (filterSuppliers$ | async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
					<mat-form-field class="w-100" appearance="outline" *ngIf="bladeItem.payload.mode === CreateOrderModeEnum.newOrder">
						<mat-label>Meddelande - Ses av kund</mat-label>
						<textarea matInput formControlName="message" rows="7" placeholder="Meddelande - Ses av kund"></textarea>
					</mat-form-field>
				</form>
				<div *ngIf="source.selectedProductId">
					<h4>Ange datum</h4>
					<form [formGroup]="selectProductToOrderBladeService.formGroup">

						<mat-form-field class="w-100 d-none" (click)="date.open()">
							<input matInput formControlName="date" [min]="todayDate" [matDatepicker]="date" placeholder="Välj datum" [matDatepickerFilter]="selectProductToOrderBladeService.dateFilter">
							<mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
							<mat-datepicker [startAt]="source.productDates[0]?.fromDate" [dateClass]="selectProductToOrderBladeService.dateClass" touchUi #date disabled="false"></mat-datepicker>
						</mat-form-field>

						<div class="align-content-center d-flex justify-content-between">
							<p><b>{{source.selectedDate | date:'yyyy-MM-dd' || 'Inget valt'}}</b></p>

							<button mat-stroked-button color="accent" (click)="date.open()">
								<span *ngIf="!source.selectedDate">Välj datum</span>
								<span *ngIf="source.selectedDate">Ändra datum</span>
							</button>
						</div>

						<div class="d-flex ">
							<div class="pr-4" *ngIf="source.times?.length || (selectProductToOrderBladeService.userService.isAdminUser() && source.selectedDate)">
								<h4 class="mt-3" [class.text-color-grey]="!selectProductToOrderBladeService.formGroup.get('date')">Ange tid</h4>

								<mat-radio-group formControlName="time" aria-label="Select an option">
									<mat-radio-button class="mr-4" *ngFor="let time of source.times" [value]="time">
										{{time.time}}
									</mat-radio-button>

									<mat-radio-button [value]="selectProductToOrderBladeService.formGroup?.value?.manuellTime" *ngIf="selectProductToOrderBladeService.userService.isAdminUser() && source.selectedDate">
										<mat-form-field>
											<input type="time" matInput formControlName="manuellTime" placeholder="Egen tid">
										</mat-form-field>
									</mat-radio-button>
								</mat-radio-group>
							</div>
						</div>
					</form>
				</div>
			</mat-card-content>
		</mat-card>

		<ng-container *ngIf="bladeItem.payload.mode === CreateOrderModeEnum.newOrder ? source.selectedProductId && source.selectedSupplierId && source.selectedDate : true">
			<app-select-product-to-order-customer-types></app-select-product-to-order-customer-types>
			<app-select-product-to-order-pickup></app-select-product-to-order-pickup>
			<app-select-product-to-order-addons></app-select-product-to-order-addons>
		</ng-container>

		<div *ngFor="let error of source.errorMessages" class="p-2 mt-3 bg-danger text-white rounded">{{error}}</div>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<span *ngIf="source.errorMessages?.length" class="align-self-center mr-3 text-danger">Finns fel!</span>
		<button mat-flat-button color="primary" (click)="select()">Välj</button>
	</app-blade-footer>
</app-blade-item>
