import { Component, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import { combineLatest, ReplaySubject } from 'rxjs';
import * as _ from 'lodash';

import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { TourBooking } from 'app/models/tour-booking.model';
import { TourBookingPickupLocationSummary } from 'app/models/tour-booking-pickup-location-summary.model';
import { Dictionary } from 'lodash';
import { TourBookingBladeComponent } from 'app/admin/blades/tour-booking/tour-booking-blade/tour-booking-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourBookingAddonStore } from 'app/admin/stores/tour-booking-addon.store.service';
import { TourBookingAddonSummary } from 'app/models/tour-booking-addon-summary.model';
import { TourSummaryService } from '../../services/tour-summary.service';
import { DialogEditService } from 'app/modules/dialog-edit/services/dialog-edit.service';

@UntilDestroy()
@Component({
	selector: 'app-tour-summary-addon',
	templateUrl: './tour-summary-addon.component.html',
	styleUrls: ['./tour-summary-addon.component.scss'],
})
export class TourSummaryAddonComponent implements OnDestroy {
	@ViewChild(MatAccordion) accordion: MatAccordion;
	private tourBookings: TourBooking[];
	public displayedColumns: string[] = ['name', 'quantity'];
	public addonWrappersReplay = new ReplaySubject<Dictionary<TourBookingAddonSummary[]>>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private tourBookingAddonStore: TourBookingAddonStore,
		private tourSummaryService: TourSummaryService,
		public dialogEditService: DialogEditService,
	) {
		this.tourSummaryService.tourBookingsBehavior$.pipe(untilDestroyed(this), switchMap(tourBookings => {
			this.tourBookings = tourBookings;
			const observables = [];
			_.each(tourBookings, tb => {
				observables.push(this.tourBookingAddonStore.getAllOnTourBooking(tb.id));
			});
			return combineLatest(observables);
		})).subscribe(tourBookingAddonList => {
			const tourBookingAddons = _.flatten(tourBookingAddonList) as TourBookingAddonSummary[];
			_.each(tourBookingAddons, tourBookingAddon => {
				tourBookingAddon.tourBooking = _.find(this.tourBookings, tBooking => tBooking.id === tourBookingAddon.tourBookingId);
			});
			const tourBookingAddonGroups = _.groupBy(tourBookingAddons, pickup => pickup.addonId);
			this.addonWrappersReplay.next(tourBookingAddonGroups);
		});
	}

	ngOnDestroy() { }

	public selectItem(row: TourBookingPickupLocationSummary) {
		this.bladeService.addBladeItem({
			id: row.tourBookingId,
			component: TourBookingBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public openEditCustomerType(tourBookingId: number): void {
		this.dialogEditService.editTourBookingCustomerType(tourBookingId).pipe(untilDestroyed(this)).subscribe(done => {
			this.tourSummaryService.getAllOnTour();
		});
	}
}
