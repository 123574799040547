
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ProductDateTimeCustomerType } from 'app/models/product/product-date-time-customer-type.model';
import { BaseStore } from '../base.store.service';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ProductDateTimeCustomerTypeStore extends BaseStore<ProductDateTimeCustomerType> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductDateTimeCustomerTypes', baseUrl, snackBarService);
	}

	public getAllOnProductDateTime(productDateTimeId: number): Observable<ProductDateTimeCustomerType[]> {
		return this.http.get<ProductDateTimeCustomerType[]>(`${this.host}/product/date/time/${productDateTimeId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}
}

