import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { TourGuideTypeStore } from '../stores/tour-guide-type.store.service';

/**
 * Print page type name
 */
@Pipe({ name: 'tourGuideTypeName' })
export class TourGuideTypeNamePipe implements PipeTransform, OnDestroy {
	constructor(private tourGuideTypeStore: TourGuideTypeStore) { }

	public transform(pageTypeId: number): Observable<string> {
		if (!pageTypeId) {
			return;
		}
		return this.tourGuideTypeStore.pipeGet(pageTypeId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
