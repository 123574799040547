<app-blade-item>
	<app-blade-header title="E-post"></app-blade-header>
	<app-blade-content>
		<app-blade-content-tabs>
			<app-blade-content-tab [title]="'Skickat'" [iconName]="'send'">
				<app-email-blade-outbox></app-email-blade-outbox>
			</app-blade-content-tab>
		</app-blade-content-tabs>
	</app-blade-content>
 </app-blade-item>
