<div class="mat-elevation-z1">
	<table mat-table [dataSource]="dataSource" matSort class="w-100">
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
			<td mat-cell *matCellDef="let item">{{item.id}} </td>
		</ng-container>
		<ng-container matColumnDef="customerType">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Kund-typ</th>
			<td mat-cell *matCellDef="let item">{{item.customerTypeId | customerTypeName | async}}</td>
		</ng-container>
		<ng-container matColumnDef="quantity">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Antal</th>
			<td mat-cell *matCellDef="let item">{{item.quantity}}</td>
		</ng-container>
		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
			<td mat-cell *matCellDef="let item">{{item.created|date:'yyyy-MM-dd HH:mm'}}</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>
