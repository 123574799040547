

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Settings } from 'app/models/site/settings.model';
import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class SettingsStore extends BaseStore<Settings> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Settings', baseUrl, snackBarService);
	}

	public getOnSite(): Observable<Settings> {
		return this.http.get<Settings>(`${this.host}/getOnSite`);
	}

}
