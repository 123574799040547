import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BladeService } from '../../../blade.service';

@UntilDestroy()
@Component({
  selector: 'app-blade-page',
  templateUrl: './blade-page.component.html',
  styleUrls: ['./blade-page.component.scss'],
})
export class BladePageComponent implements OnInit {
	constructor(
		private activatedRouter: ActivatedRoute,
		private bladeService: BladeService,
	) {
	}

	ngOnInit() {
		this.activatedRouter.data.pipe(untilDestroyed(this)).subscribe(data => {
			this.bladeService.init(data.bladeItem);
		});
	}
}
