import { Component, OnInit, Inject, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { PageClass } from 'app/models/page-class.model';
import { PageClassStore } from 'app/admin/stores/page-class.store.service';
import { PageStyle } from 'app/models/page-style.model';
import { PageStyleStore } from 'app/admin/stores/page-style.store.service';

@UntilDestroy()
@Component({
	selector: 'app-page-blade-styles',
	templateUrl: './page-blade-styles.component.html',
	styleUrls: ['./page-blade-styles.component.scss'],
})
export class PageBladeStylesComponent implements OnInit, OnDestroy {
	public editorOptions = {theme: 'vs', language: 'json'};

	public code = '';
	private pageStyle: PageStyle;

	private pristinePageClasses: PageClass[];
	private pageClasses: PageClass[];

	public className: string;
	public displayedColumns: string[] = ['id', 'name', 'actions'];
	public dataSource: MatTableDataSource<PageClass>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public pageClassStore: PageClassStore,
		public pageStyleStore: PageStyleStore,
		private snackBarService: SnackBarService,
	) {
		this.pageClassStore.getAllOnPage(this.bladeItem.id).subscribe();
		this.pageClassStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.pristinePageClasses = _.cloneDeep(items);
			this.pageClasses = items;
			this.addToDataSource();
		});

		this.pageStyleStore.getOnPage(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(pageStyle => {
			this.pageStyle = pageStyle;
			if (!this.pageStyle) {
				this.pageStyle = new PageStyle();
				this.pageStyle.pageId = this.bladeItem.id;
			}
			this.code = pageStyle.style;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {}

	public saveStyle() {
		this.pageStyle.style = this.code;
		if (this.pageStyle.id) {
			this.pageStyleStore.update(this.pageStyle).subscribe(pageStyle => {
				this.pageStyle = pageStyle;
			});
		} else {
			this.pageStyleStore.create(this.pageStyle).subscribe(pageStyle => {
				this.pageStyle = pageStyle;
			});
		}
	}

	public addClass(): void {
		if (this.className) {
			const pageClass = new PageClass();
			pageClass.class = this.className;
			pageClass.pageId = this.bladeItem.id;
			if (!this.pageClasses) {
				this.pageClasses = [];
			}
			this.pageClasses.push(pageClass);
			this.className = '';
			this.addToDataSource();
			this.doSave();
		}
	}

	public deleteClass(pageClass: PageClass) {
		_.remove(this.pageClasses, pageClass);
		this.addToDataSource();
		this.doSave();
	}

	private addToDataSource() {
		this.dataSource = new MatTableDataSource<PageClass>(this.pageClasses);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	private doSave() {
		const add: PageClass[] = _.differenceWith(this.pageClasses, this.pristinePageClasses, (a, b) => a.id === b.id);

		const removed = _.differenceWith(this.pristinePageClasses, this.pageClasses, (a, b) => a.id === b.id);

		if (add || removed) {
			this.pageClassStore.addDelete(this.bladeItem.id, add, _.map(removed, 'id')).subscribe(() => {
				this.pageClassStore.getAllOnPage(this.bladeItem.id).subscribe();
				this.snackBarService.showSuccess('Kopplingarna har sparats!');
			});
		}
	}
}
