import { Component, Inject, OnDestroy } from '@angular/core';

import * as _ from 'lodash';

import { SelectProductToOrderBladeService } from '../../services/create-order-tour-booking-blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { TourBookingAddonStore } from 'app/admin/stores/tour-booking-addon.store.service';
import { AddonOrder } from '../../models/addon-order.model';
import { AddonStore } from 'app/admin/stores/addon.store.service';
import { UserService } from 'app/admin/services/user.service';
import { Addon } from 'app/models/addon.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderPart } from 'app/models/order/OrderPart';

@UntilDestroy()
@Component({
	selector: 'app-select-product-to-order-addons',
	templateUrl: './select-product-to-order-addons.component.html',
	styleUrls: ['./select-product-to-order-addons.component.scss'],
})
export class SelectProductToOrderAddonsComponent implements OnDestroy {
	private addonOrder: AddonOrder[] = [];
	public displayedColumns: string[] = ['type', 'quantity', 'unitPrice'];
	public selectedAddon: Addon;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public selectProductToOrderBladeService: SelectProductToOrderBladeService,
		public addonStore: AddonStore,
		public userService: UserService,
	) {
		addonStore.getAll();
		this.selectProductToOrderBladeService.orderPartBehavior$.pipe(untilDestroyed(this)).subscribe(orderPart => {
			if (orderPart && orderPart.addons) {
				orderPart.addons.forEach(ad => {
					const addon = new Addon();
					addon.id = ad.addonId;
					addon.price = ad.unitPrice;
					this.addAddon(addon, ad.quantity);
				});
			}
		});
	}
	ngOnDestroy(): void { }

	public addAddon(addon: Addon, quantity?: number) {
		this.addonOrder.push({
			addonId: addon.id,
			unitPrice: addon.price,
			quantity,
		});
		this.selectProductToOrderBladeService.setAddonOrders(this.addonOrder);
		this.selectedAddon = null;
	}

	public isAddonSelected(addonId: number) {
		return !!_.find(this.addonOrder, addon => addon.addonId === addonId);
	}
}
