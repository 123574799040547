

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from '../base.store.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { OddFunction } from 'app/models/product-function/odd-function.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OddFunctionStore extends BaseStore<OddFunction> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'oddFunction', baseUrl, snackBarService);
	}
}
