import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { ProductPickupLocationStore } from 'app/admin/stores/product/product-pickup-location.store.service';
import { TourBookingSpecialDietMessageStore } from 'app/admin/stores/tour-booking-special-diet-message.store.service';
import { TourBookingSpecialDietStore } from 'app/admin/stores/tour-booking-special-diet.store.service';
import { TourBookingSpecialDiet } from 'app/models/tour-booking-special-diet.model';
import { TourBooking } from 'app/models/tour-booking.model';
import { Tour } from 'app/models/tour.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TourBookingCustomerTypeAgeStore } from '../../../../../../stores/tour-booking-customer-type-age.store.service';
import { TourBookingCustomerTypeAge } from '../../../../../../../models/tour-booking-customer-type-age.model';

@UntilDestroy()
@Component({
  selector: 'app-tours-customers-program-customer',
  templateUrl: './tours-customers-program-customer.component.html',
  styleUrls: ['./tours-customers-program-customer.component.scss'],
})
export class ToursCustomersProgramCustomerComponent implements OnInit {
	moment = moment;
	@Input() language: any;

	@Input() tourBooking: TourBooking;
	@Input() tour: Tour;
	private summaries: ExtendedTourSummary[] = [];
	public summaries$ = new ReplaySubject<ExtendedTourSummary[]>(1);
	public specialDietsReplay$ = new Observable<TourBookingSpecialDietExtended[]>();

	constructor(
		private productPickupLocationStore: ProductPickupLocationStore,
		public tourBookingSpecialDietMessageStore: TourBookingSpecialDietMessageStore,
		public tourBookingSpecialDietStore: TourBookingSpecialDietStore,
		private customerTypeStore: CustomerTypeStore,
		private tourBookingCustomerTypeAgeStore: TourBookingCustomerTypeAgeStore,
		private route: ActivatedRoute,
		private cdr: ChangeDetectorRef,
	) { }

  	ngOnInit() {

		this.tourBooking.tourBookingCustomerTypes.forEach(customerType => {
			this.addCustomerType(customerType.customerTypeId, customerType.quantity);
			this.tourBookingCustomerTypeAgeStore.getAllOnTourBookingCustomerType$(customerType.id).subscribe(ages => {
				this.addAgeToCustomerType(customerType.customerTypeId, ages);
			});
		});
		this.tourBooking.tourBookingPickupLocations.forEach(pickup => {
			this.addCustomerType(pickup.customerTypeId, 0, pickup.pickupLocationId, pickup.relativeTime);
		});
		this.tourBookingSpecialDietMessageStore.getAllOnTourBooking(this.tourBooking.id).subscribe();
		this.specialDietsReplay$ = combineLatest([
			this.tourBookingSpecialDietStore.getAllOnTourBooking$(this.tourBooking.id),
			this.customerTypeStore.items$,
		])
		.pipe(switchMap(([items, customerTypes]) => {
			const list: TourBookingSpecialDietExtended[] = [];

			_.forOwn(_.groupBy(items, x => x.groupName), (value, key, object) => {
				const customerType = _.find(customerTypes, c => c.identifier === key.split('_')[0]);
				list.push({
					customerTypeId: customerType.id,
					tourBookingSpecialDiets: value,
				});
		});
			return of(list);
		}));

	}

	private addCustomerType(customerTypeId: number, quantity: number, pickupLocationId?: number, relativeTime?: number) {
		let findSummary = this.summaries.find(x => x.customerTypeId === customerTypeId);
		if (!findSummary) {
			findSummary = {
				pickupLocationId,
				customerTypeId,
				quantity: 0,
			};

			this.summaries.push(findSummary);
		}

		findSummary.quantity += quantity;
		if (pickupLocationId) {
			findSummary.pickupLocationId = pickupLocationId;
		}

		const tourTime = moment(`${moment(this.tour.date).format('YYYY-MM-DD')} ${this.tour.time}`);
		findSummary.relativeTime = tourTime.add(relativeTime, 'minute').format('HH:mm');
		this.summaries$.next(_.sortBy(this.summaries, 'date', 'time'));
	}

	private addAgeToCustomerType(customerTypeId: number, ages?: TourBookingCustomerTypeAge[]): void {
		if (ages) {
			const findSummary = this.summaries.find(x => x.customerTypeId === customerTypeId);
			if (findSummary) {
				findSummary.ages = ages;
				this.cdr.markForCheck();
			}
		}
	}
}

interface ExtendedTourSummary {
	pickupLocationId?: number;
	relativeTime?: string;
	customerTypeId: number;
	quantity: number;
	ages?: TourBookingCustomerTypeAge[];
}

interface TourBookingSpecialDietExtended {
	customerTypeId: number;
	tourBookingSpecialDiets: TourBookingSpecialDiet[];
}
