<app-blade-item [canIClose]="true">
	<app-blade-header [title]="'Resursplanering'"></app-blade-header>
	<app-blade-content>
		<mat-card class="mb-3" *ngFor="let dateGroup of plannerResourceDateGroupsReplay$ | async">
			<mat-card-title>{{dateGroup.date | date:'yyyy-MM-dd'}}</mat-card-title>
			<mat-accordion>
				<app-planner-resource-item *ngFor="let group of dateGroup.plannerResourceGroups" [group]="group"></app-planner-resource-item>
			</mat-accordion>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="createNew()">Skapa ny</button>
	</app-blade-footer>
</app-blade-item>
