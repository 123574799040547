import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class MenuService {
	public currentUrl = new BehaviorSubject<string>(undefined);

	constructor(private router: Router) {
		this.currentUrl.next(router.url);
		this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.currentUrl.next(event.urlAfterRedirects);
			}
		});
	}
}
