import { Component, OnInit, Inject, ViewChild, Input, OnDestroy } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Product } from 'app/models/product/product.model';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CategoryProductStore } from 'app/admin/stores/category/category-product.store.service';
import { CategoryProduct } from 'app/models/category/category-product.model';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { CategoryStore } from 'app/admin/stores/category/category.store.service';
import { Category } from 'app/models/category/category.model';

@UntilDestroy()
@Component({
	selector: 'app-product-blade-category',
	templateUrl: './product-blade-category.component.html',
	styleUrls: ['./product-blade-category.component.scss'],
})
export class ProductBladeCategoryComponent implements OnInit, OnDestroy {
	private saveSubject: Subject<void>;
	private categories: Category[];
	public displayedColumns: string[] = ['select', 'id', 'name'];
	public dataSource: MatTableDataSource<Category>;
	public selection: SelectionModel<Category>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@Input() set save(subject: Subject<void>) {
		if (subject) {
			if (this.saveSubject) {
				this.saveSubject.unsubscribe();
			}
			this.saveSubject = subject;
			subject.pipe(untilDestroyed(this)).subscribe(() => {
				this.doSave();
			});
		} else if (this.saveSubject) {
			this.saveSubject.unsubscribe();
		}

	}

	private connectedCategories: CategoryProduct[];
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public categoryStore: CategoryStore,
		public categoryProductStore: CategoryProductStore,
		private snackBarService: SnackBarService,
	) {
		this.categoryStore.getAll();
		this.categoryStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.categories = items;
			this.dataSource = new MatTableDataSource<Category>(this.categories);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getAllOnChild();
		});
	}

	ngOnInit() {}

	ngOnDestroy() {}

	public selectCategory(category: Category) {
		this.selection.toggle(category);
	}

	private getAllOnChild() {
		this.categoryProductStore.getAllOnChild(this.bladeItem.id).subscribe(connectedCategories => {
			this.connectedCategories = connectedCategories;
			this.handleSelectedCategories();
		});
	}

	private handleSelectedCategories() {
		const selectedCategories = [];
		_.each(_.map(this.connectedCategories, 'category'), category => {
			const cat = _.find(this.categories, p => p.id === category.id);
			if (cat && !_.find(selectedCategories, p => p.id === cat.id)) {
				selectedCategories.push(cat);
			}
		});
		this.selection = new SelectionModel<Category>(true, selectedCategories);
	}

	private doSave() {
		const add: CategoryProduct[] = [];
		_.each(_.differenceWith(this.selection.selected, _.map(this.connectedCategories, 'category'), (a, b) => a.id === b.id), category => {
			const cp = new CategoryProduct();
			cp.categoryId = category.id;
			cp.productId = this.bladeItem.id;
			add.push(cp);
		});

		const removed = _.differenceWith(this.connectedCategories, this.selection.selected, (a, b) => a.categoryId === b.id);
		if (add || removed) {
			this.categoryProductStore.addDelete(this.bladeItem.id, add, _.map(removed, 'id')).subscribe(() => {
				this.getAllOnChild();
				this.snackBarService.showSuccess('Kopplingarna har sparats!');
			});
		}
	}
}
