<!-- <mat-card>
	<mat-card-title>Betalningar</mat-card-title> -->
	<table mat-table [dataSource]="dataSource" matSort class="w-100">
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
			<td mat-cell *matCellDef="let item">{{item.id}} </td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>
		<ng-container matColumnDef="payment">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Betalning</th>
			<td mat-cell *matCellDef="let item">{{item.paymentTypeId | paymentTypeName | async}}</td>
			<td mat-footer-cell *matFooterCellDef class="text-right pr-2"><b>Totalt:</b></td>
		</ng-container>
		<ng-container matColumnDef="amount">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Belopp</th>
			<td mat-cell *matCellDef="let item">{{item.amount | currency:'SEK':'code'}}</td>
			<td mat-footer-cell *matFooterCellDef><b>{{totalAmountReplay$ | async | currency:'SEK':'code'}}</b></td>
		</ng-container>
		<ng-container matColumnDef="source">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Källa</th>
			<td mat-cell *matCellDef="let item">{{item.sourceTypeId | sourceTypeName | async}}</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
		<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>

	<div class="mt-3 text-right">
		<button mat-stroked-button color="accent" (click)="newPayment()">Lägg till ny betalning</button>
	</div>
<!-- </mat-card> -->

