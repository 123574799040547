import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TourBookingAddon } from 'app/models/tour-booking-addon.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class TourBookingAddonStore extends BaseStore<TourBookingAddon> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TourBookingAddon', baseUrl, snackBarService);
	}

	public getAllOnTourBooking(tourBookingId: number): Observable<TourBookingAddon[]> {
		return this.http.get<TourBookingAddon[]>(`${this.host}/tourBooking/${tourBookingId}`).pipe(tap(tourBookings => {
			this._items = tourBookings;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnTourBooking$(tourBookingId: number): Observable<TourBookingAddon[]> {
		return this.http.get<TourBookingAddon[]>(`${this.host}/tourBooking/${tourBookingId}`);
	}
}
