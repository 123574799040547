<mat-card>
	<mat-card-title>
		<div class="d-flex justify-content-between">
			<span>Kunder</span>
			<div>
				<button mat-button (click)="accordion.closeAll()"><mat-icon>unfold_less</mat-icon></button>
				<button mat-button (click)="accordion.openAll()"><mat-icon>unfold_more</mat-icon></button>
			</div>
		</div>
	</mat-card-title>
	<mat-card-content>
		<mat-accordion multi>
			<mat-expansion-panel *ngFor="let tourBooking of tourBookingsReplay$ | async">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<b>{{tourBooking.tourBooking.customerId | customerName | async}}</b>
						<p class="ml-3" *ngIf="{amount: tourBooking.paymentBalance.totalOrder - tourBooking.paymentBalance.totalPayment} as total">
							Kredit -
							<span *ngIf="total.amount > 0">Ska betala: </span>
							<span *ngIf="total.amount < 0">Få tillbaka: </span>
							<span
								[class.text-success]="!total.amount"
								[class.text-danger]="total.amount > 0"
								[class.text-warning]="total.amount < 0">
								{{total.amount | currency:'SEK':'code'}}
							</span>
						</p>
					</mat-panel-title>
					<mat-panel-description class="d-flex justify-content-end">
						<span class="material-icons" *ngIf="tourBooking.tourBooking.message">message</span>
					</mat-panel-description>
				</mat-expansion-panel-header>

				<mat-card>
					<mat-card-title class="card-title-small">Antal</mat-card-title>
					<div *ngFor="let tourBookingCustomerType of tourBooking.tourBookingCustomerTypes">
						<p><b>{{tourBookingCustomerType.customerTypeId | customerTypeName | async}}</b> {{tourBookingCustomerType.quantity}}st</p>
						<p class="ml-3" *ngFor="let tourBookingCustomerTypeAge of tourBookingCustomerType.tourBookingCustomerTypeAges; let last = last; let i = index;">
							{{tourBookingCustomerType.customerTypeId | customerTypeName | async}} {{i + 1}}: {{tourBookingCustomerTypeAge.birthDate | date:'yyyy-MM-dd'}} ({{moment().diff(tourBookingCustomerTypeAge.birthDate, 'years', false)}}år)
							<span *ngIf="!last">, </span>
						</p>
					</div>
				</mat-card>

				<mat-card class="mt-3">
					<mat-card-title class="card-title-small">Kundmeddelande</mat-card-title>
					<p *ngIf="tourBooking.tourBooking.message" [innerHtml]="tourBooking.tourBooking.message | newLine"></p>
					<p *ngIf="!tourBooking.tourBooking.message">Inget meddelande</p>
				</mat-card>

				<mat-card class="mt-3">
					<mat-card-title class="card-title-small">Kredit</mat-card-title>
					<p>Totalt belopp på ordern: {{tourBooking.paymentBalance?.totalOrder | currency:'SEK':'code'}}</p>
					<p>Kunden har betalat {{tourBooking.paymentBalance?.totalPayment | currency:'SEK':'code'}}</p>
					<p *ngIf="{amount: tourBooking.paymentBalance?.totalOrder - tourBooking.paymentBalance?.totalPayment} as total">
						Balans -
						<span *ngIf="total.amount > 0">Ska betala: </span>
						<span *ngIf="total.amount < 0">Få tillbaka: </span>
						<span
							[class.text-success]="!total.amount"
							[class.text-danger]="total.amount > 0"
							[class.text-warning]="total.amount < 0">
							{{total.amount | currency:'SEK':'code'}}
						</span>
					</p>
			</mat-card>
			</mat-expansion-panel>
		</mat-accordion>
	</mat-card-content>
</mat-card>


<!-- <mat-card class="mat-elevation-z3">
	<mat-table [dataSource]="customer.value" class="w-100">
		<ng-container matColumnDef="customerType">
			<mat-header-cell *matHeaderCellDef>Typ</mat-header-cell>
			<mat-cell *matCellDef="let item">{{item.customerTypeId | customerTypeName | async}}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="quantity">
			<mat-header-cell *matHeaderCellDef>Antal</mat-header-cell>
			<mat-cell *matCellDef="let item">{{item.quantity}}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="birthDate">
			<mat-header-cell *matHeaderCellDef>Födelsedatum</mat-header-cell>
			<mat-cell *matCellDef="let item" class="d-block">
				<span class="d-block" *ngFor="let age of item.tourBookingCustomerTypeAges; let last = last; let i = index;">
					{{item.customerTypeId | customerTypeName | async}} {{i + 1}}: {{age.birthDate | date:'yyyy-MM-dd'}} ({{moment().diff(age.birthDate, 'years', false)}}år)
					<span *ngIf="!last">, </span>
				</span>
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)"></mat-row>
	</mat-table>
</mat-card> -->
