import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { BladeService } from 'app/admin/components/blade/blade.service';
import { SlideshowImageBladeComponent } from '../blades/slideshow-image/slideshow-image-blade/slideshow-image-blade.component';
import { SlideshowCategoryBladeComponent } from '../blades/slideshow-category/slideshow-category-blade/slideshow-category-blade.component';

@Injectable({
	providedIn: 'root',
})
export class SlideshowItemTypeService {
	constructor(private bladeService: BladeService) { }

	getSlideshowItemComponent(slideshowItemTypeId: number): any {
		switch (slideshowItemTypeId) {
			case 1:
				return SlideshowImageBladeComponent;
			case 2:
				return SlideshowCategoryBladeComponent;
			default:
				break;
		}
	}
}
