import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { AddonBladeComponent } from '../addon-blade/addon-blade.component';
import { Addon } from 'app/models/addon.model';
import { AddonStore } from 'app/admin/stores/addon.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BladeModalData } from 'app/admin/components/blade/models/bladeModalData.model';

@UntilDestroy()
@Component({
	selector: 'app-addons-blade',
	templateUrl: './addons-blade.component.html',
	styleUrls: ['./addons-blade.component.scss'],
})
@IBladeComponent.register
export class AddonsBladeComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['name', 'isActive'];
	public dataSource: MatTableDataSource<Addon>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: AddonStore,
	) {
		this.itemStore.getAll();
		this.itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<Addon>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: AddonBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: AddonBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}
}
