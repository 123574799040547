import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
	public transform(items: any[], property: string, value: any): any {
		return _.filter(items, item => item[property] === value);
	}
}
