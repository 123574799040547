
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AdminModule } from 'app/admin/admin.module';

export function getBaseUrl() {
	let baseUrl = document.getElementsByTagName('base')[0].href;
	if (baseUrl.indexOf('localhost:4200') > -1) {
		baseUrl = baseUrl.replace('http://localhost:4200', 'https://localhost:44369');
	}

	return baseUrl;
}

export function getMediaUrl() {
	return '';
}
const providers = [
	{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
	{ provide: 'BASE_MEDIA_URL', useFactory: getMediaUrl, deps: [] },
];

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic(providers)
	.bootstrapModule(AdminModule)
	.catch(err => console.log(err));

// export { renderModule, renderModuleFactory } from '@angular/platform-server';
