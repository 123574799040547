import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { GuideTourService } from './services/guide-tour.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-guide-tour-blade',
	templateUrl: './guide-tour-blade.component.html',
	styleUrls: ['./guide-tour-blade.component.scss'],
	providers: [GuideTourService],
})
@IBladeComponent.register
export class GuideTourBladeComponent implements OnInit {
	public selectedIndex = 1;
	public loading = true;
	public canIClose: () => {} | boolean;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		type: new FormControl('', [Validators.required]),
		supplier: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public guideTourService: GuideTourService,
	) {
		this.guideTourService.tourBehavior$.pipe(untilDestroyed(this)).subscribe(tour => this.loading = false);
	}

	ngOnInit() {}
}
