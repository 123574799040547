import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TourBookingMessageService } from './services/tour-booking-message.service';

@Component({
  	selector: 'app-tour-booking-message',
	templateUrl: './tour-booking-message.component.html',
  	styleUrls: ['./tour-booking-message.component.scss'],
	providers: [TourBookingMessageService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourBookingMessageComponent implements OnInit {


	constructor(
		public tourBookingMessageService: TourBookingMessageService,
	) { }

	ngOnInit() {
	}
}
