import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BookingStatusStore } from '../stores/booking-status.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'bookingStatusName' })
export class BookingStatusNamePipe implements PipeTransform, OnDestroy {
	constructor(private bookingStatusStore: BookingStatusStore) { }

	public transform(bookingStatusId: number): Observable<string> {
		if (!bookingStatusId) {
			return;
		}
		return this.bookingStatusStore.pipeGet(bookingStatusId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
