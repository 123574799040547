<app-blade-item [canIClose]="true" [loading]="source.isLoading" [id]="source?.category?.id" *ngIf="{
	category: categoryBladeService.categoryReplay$ | async,
	isLoading: categoryBladeService.isLoadingBehavior$ | async
} as source">
    <app-blade-header [title]="source?.category?.name||'Ny'" [subTitle]="'Kategori'" [menuItems]="headerMenuItems"></app-blade-header>
    <app-blade-content (indexChange)="categoryBladeService.selectedIndex = $event">
		<app-blade-content-tabs>
			<app-blade-content-tab [title]="'Generellt'" [template]="generally">
				<ng-template #generally>
					<app-category-blade-generally></app-category-blade-generally>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Texter'" [template]="texts" [disabled]="!source?.category?.id">
				<ng-template #texts>
					<app-category-blade-texts></app-category-blade-texts>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Bilder'" [template]="images" [disabled]="!source?.category?.id">
				<ng-template #images>
					<app-category-blade-images></app-category-blade-images>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Produkter'" [template]="product" [disabled]="!source?.category?.id">
				<ng-template #product>
					<app-category-blade-product></app-category-blade-product>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Kategorier'" [template]="category" [disabled]="!source?.category?.id">
				<ng-template #category>
					<app-category-blade-category></app-category-blade-category>
				</ng-template>
			</app-blade-content-tab>
		</app-blade-content-tabs>
	</app-blade-content>
 </app-blade-item>
