import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { PickupLocationPrice } from 'app/models/page-location-price.model';
import { PickupLocationPriceStore } from 'app/admin/stores/pickup-location-price.store.service';
import { CustomerType } from 'app/models/customer-type.model';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { PickupLocationPriceBladeComponent } from 'app/admin/blades/pickup-location-price/pickup-location-price-blade/pickup-location-price-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';

@UntilDestroy()
@Component({
	selector: 'app-pickup-location-blade-prices',
	templateUrl: './pickup-location-blade-prices.component.html',
	styleUrls: ['./pickup-location-blade-prices.component.scss'],
})
export class PickupLocationBladePricesComponent implements OnInit, OnDestroy {

	public customerTypes: CustomerType[];
	public pickupLocationPrices: PickupLocationPrice[];
	public displayedColumns: string[] = ['customerType', 'price'];
	public dataSource: MatTableDataSource<PickupLocationPrice>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public customerTypeStore: CustomerTypeStore,
		private bladeService: BladeService,
		public pageLocationPriceStore: PickupLocationPriceStore,
	) {
		this.pageLocationPriceStore.getAllOnPickupLocation(this.bladeItem.id).subscribe();
		this.pageLocationPriceStore.items$.pipe(untilDestroyed(this)).subscribe(pickupLocationPrices => {
			this.pickupLocationPrices = pickupLocationPrices;
			this.handlePickupLocationPrices();
		});
		this.customerTypeStore.items$.pipe(untilDestroyed(this)).subscribe(customerTypes => {
			this.customerTypes = customerTypes;
			this.handlePickupLocationPrices();
		});
	}

	private handlePickupLocationPrices() {
		if (this.pickupLocationPrices && this.customerTypes) {
			// Every customerTypes must have a productPrice
			_.each(this.customerTypes, customerType => {
				const pickupLocationPrices = _.filter(this.pickupLocationPrices, price => price.customerTypeId === customerType.id);
				let productPrice;
				if (pickupLocationPrices && pickupLocationPrices.length > 1) {
					productPrice = _.find(this.pickupLocationPrices, price => price.customerTypeId === customerType.id && price.id > 0);
					this.pageLocationPriceStore.removePickupLocationPrice(_.find(this.pickupLocationPrices, price => price.customerTypeId === customerType.id && !price.id));
				} else if (pickupLocationPrices && pickupLocationPrices.length) {
					productPrice = pickupLocationPrices[0];
				}

				if (!productPrice) {
					productPrice = new PickupLocationPrice();
					productPrice.customerTypeId = customerType.id;
					this.pageLocationPriceStore.addPickupLocationPrice(productPrice);
				}
			});
			this.dataSource = new MatTableDataSource<PickupLocationPrice>(this.pickupLocationPrices);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}

	ngOnInit() {}

	ngOnDestroy() {}

	public selectItem(row: PickupLocationPrice) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: PickupLocationPriceBladeComponent,
			minClass: BladeSize.xs,
			payload: {
				pickupLocationId: this.bladeItem.id,
				customerTypeId: row.customerTypeId,
			},
		}, this.bladeItem);
	}
}
