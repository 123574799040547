import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import _find from 'lodash-es/find';
import _map from 'lodash-es/map';
import _uniq from 'lodash-es/uniq';
import _sortBy from 'lodash-es/sortBy';

import { SettingsStore } from 'app/admin/stores/settings.store.service';
import { Settings } from 'app/models/site/settings.model';
import { CustomerStore } from 'app/modules/customer/stores/customer.store';
import { Observable, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap, tap } from 'rxjs/operators';
import { AdminDataService } from 'app/modules/admin-data/services/admin-data.service';

@UntilDestroy()
@Injectable()
export class CustomerProgramPageService {

	private totalAmount = 0;
	public totalAmount$ = new ReplaySubject<number>(1);
	public siteSettings$ = new ReplaySubject<Settings>(1);
	public customer$: Observable<any>;
	public language$: Observable<any>;
	public tourIds$ = new ReplaySubject<number[]>();
	public showTotalPrice$ = new ReplaySubject<boolean>();

  	constructor(
		private customerStore: CustomerStore,
		private route: ActivatedRoute,
		private settingsStore: SettingsStore,
		private adminDataService: AdminDataService,
	) {
		this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
			this.showTotalPrice$.next(params.showTotalPrice === 'true');
		});

		this.settingsStore.getOnSite().pipe(untilDestroyed(this)).subscribe(item => {
			this.siteSettings$.next(item);
		});

		this.customer$ = this.route.queryParams
			.pipe(
				untilDestroyed(this),
				switchMap(query => {
					return this.customerStore.get(query.customerId).pipe(tap(customer => {
						if (query?.tourBookings?.length) {
							const tourBookingsToInclude = (query?.tourBookings as string).split(',');
							customer.tourBookings = customer?.tourBookings.filter(x => tourBookingsToInclude.includes(x.id + ''));
						}
						let allTours = _uniq(_map(customer.tourBookings, x => x.tour));
						allTours = _sortBy(allTours, 'date', 'time');
						this.tourIds$.next(_uniq(_map(allTours, t => t.id) ?? []));
					}));
				}),
			);

		this.language$ = this.route.queryParams.pipe(untilDestroyed(this), switchMap(params => {
			return this.adminDataService.avaiableLanguages$.pipe(map(allLanguages => {
				return _find(allLanguages, lang => lang.id === +params.languageId);
			}));
		}));
  	}

	public addTotalAmount(totalAmount): void {
		this.totalAmount += totalAmount;
		this.totalAmount$.next(this.totalAmount);
	}

}
