<mat-card *ngIf="{
	loggedInUser: userPageService.loggedInUserReplay$ | async
} as source">
	<mat-card-title>Konto</mat-card-title>

	<form [formGroup]="userPageService.itemForm">
		<mat-form-field class="w-100" appearance="outline">
			<mat-label>Förnamn</mat-label>
			<input matInput formControlName="firstName" placeholder="Förnamn">
		</mat-form-field>
		<mat-form-field class="w-100" appearance="outline">
			<mat-label>Efternamn</mat-label>
			<input matInput formControlName="lastName" placeholder="Efternamn">
		</mat-form-field>
		<mat-form-field class="w-100" appearance="outline">
			<mat-label>Epost</mat-label>
			<input matInput formControlName="email" placeholder="Epost">
		</mat-form-field>
		<mat-form-field class="w-100" appearance="outline">
			<mat-label>Telefon</mat-label>
			<input matInput formControlName="phone" placeholder="Telefon">
		</mat-form-field>
	</form>

	<div class="text-right">
		<button mat-raised-button color="primary" (click)="userPageService.update()">
			Updatera
		</button>
	</div>
</mat-card>
