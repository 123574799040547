<ng-container *ngIf="{
	adminText: 'blades.tours' | adminText | async
} as source">
	<app-blade-item [canIClose]="true" [loading]="loading">
		<app-blade-header [title]="source.adminText?.bladeTitle"></app-blade-header>
		<app-blade-content>
			<mat-card>
				<div class="row">
					<div class="col-md-4">
						<form [formGroup]="formGroup">
							<mat-form-field class="w-100">
								<mat-label>{{source.adminText?.filter.dateLabel}}</mat-label>
								<mat-date-range-input
									[formGroup]="formGroup"
									[rangePicker]="campaignOnePicker"
									[comparisonStart]="formGroup.value.start"
									[comparisonEnd]="formGroup.value.end">
									<input readonly matStartDate [placeholder]="source.adminText?.filter.fromDate" formControlName="start">
									<input readonly matEndDate [placeholder]="source.adminText?.filter.toDate" formControlName="end">
								</mat-date-range-input>
								<mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
								<mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
							</mat-form-field>
							<button mat-stroked-button class="w-100" color="accent" (click)="search()">{{source.adminText?.filter.dateSearch}}</button>
						</form>
					</div>
					<div class="col-md-4">
						<form [formGroup]="idFormGroup">
							<mat-form-field class="w-100">
								<input matInput formControlName="id" [placeholder]="source.adminText?.filter.idSearchLabel">
							</mat-form-field>
							<button mat-stroked-button class="w-100" color="accent" type="submit" [disabled]="!idFormGroup.value?.id" (click)="getById()">{{source.adminText?.filter.idSearch}}</button>
						</form>
					</div>
					<div class="col-md-4 align-items-center justify-content-end d-flex flex-wrap">
						<span class="ml-2 mb-2">{{source.adminText?.filter.filterLabel}} </span>
						<mat-checkbox class="ml-2" (change)="changeTourStatusFilter(type)" *ngFor="let type of (tourStatusStore.items$|async)" [checked]="selectedTourStatus?.includes(type)">{{type.name}}</mat-checkbox>
					</div>
				</div>
			</mat-card>
			<mat-card [class.d-none]="!initSearch" class="mt-3">
				<p *ngIf="!dataSource?.data?.length">{{source.adminText?.filter.noSearchResult}}</p>
				<div class="overflow-auto">
					<table mat-table [dataSource]="dataSource" matSort class="w-100" [class.d-none]="!dataSource?.data?.length">
						<ng-container matColumnDef="id">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
							<td mat-cell *matCellDef="let item">{{item.id}} </td>
						</ng-container>
						<ng-container matColumnDef="productId">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.filter.table.name}}</th>
							<td mat-cell *matCellDef="let item">{{item.name || (item.productId | productName | async)}}</td>
						</ng-container>
						<ng-container matColumnDef="tourGuides">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.filter.table.guide}}</th>
							<td mat-cell *matCellDef="let item" (click)="selectItem(item, 2); $event.stopPropagation();">
								<p class="m-0" *ngFor="let guide of item.tourGuides"><strong>{{guide.userId | adminUserName | async}}</strong></p>
								<span *ngIf="!item.tourGuides?.length">{{source.adminText?.filter.table.noGuide}}</span>
							</td>
						</ng-container>
						<ng-container matColumnDef="supplierId">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.filter.table.supplier}}</th>
							<td mat-cell *matCellDef="let item">{{item.supplierId | supplierName | async}}</td>
						</ng-container>
						<ng-container matColumnDef="date">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.filter.table.date}}</th>
							<td mat-cell *matCellDef="let item">
								<span *ngIf="_.includes(moment(item.date).format('YYYY-MM-DD'), '0001-01-01')">{{source.adminText?.filter.table.missingDate}}</span>
								<span *ngIf="!_.includes(moment(item.date).format('YYYY-MM-DD'), '0001-01-01')">{{item.date|date:'yyyy-MM-dd'}} {{item.time || '--:--'}}</span>
							</td>
						</ng-container>
						<ng-container matColumnDef="quantity">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.filter.table.amount}}</th>
							<td mat-cell *matCellDef="let item">{{item.quantity}}/{{item.availablePlaces}}</td>
						</ng-container>
						<ng-container matColumnDef="tourStatusId">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.filter.table.status}}</th>
							<td mat-cell *matCellDef="let item">{{item.tourStatusId | tourStatusName | async}}</td>
						</ng-container>
						<ng-container matColumnDef="isActive">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.filter.table.active}}</th>
							<td mat-cell *matCellDef="let item">
								<mat-icon *ngIf="item.isActive">check</mat-icon>
							</td>
						</ng-container>
						<ng-container matColumnDef="isPrivate">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.filter.table.private}}</th>
							<td mat-cell *matCellDef="let item">
								<mat-icon *ngIf="item.isPrivate">check</mat-icon>
							</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
					</table>
				</div>
				<mat-paginator [class.d-none]="!dataSource?.data?.length" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
			</mat-card>
		</app-blade-content>
		<app-blade-footer>
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="createNew()">{{source.adminText?.filter.createNew}}</button>
		</app-blade-footer>
	</app-blade-item>
</ng-container>
