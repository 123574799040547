import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerStore } from 'app/admin/stores/customer.store.service';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { ProductPickupLocationStore } from 'app/admin/stores/product/product-pickup-location.store.service';
import { ProductTextStore } from 'app/admin/stores/product/product-text.store.service';
import { SettingsStore } from 'app/admin/stores/settings.store.service';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { Customer } from 'app/models/customer.model';
import { ProductText } from 'app/models/product/product-text.model';
import { Settings } from 'app/models/site/settings.model';
import { TourBooking } from 'app/models/tour-booking.model';
import { Tour } from 'app/models/tour.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { TourBookingMessageStore } from 'app/admin/stores/tour-booking-message.store.service';
import { AdminDataService } from 'app/modules/admin-data/services/admin-data.service';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import _find from 'lodash-es/find';
import { map, switchMap } from 'rxjs/operators';
import { PickupLocationStore } from '../../../../stores/pickup-location.store.service';

@UntilDestroy()
@Component({
  selector: 'app-customers-program',
  templateUrl: './customers-program.component.html',
  styleUrls: ['./customers-program.component.scss'],
})
export class CustomersProgramComponent implements OnInit {
	public siteSettings$ = new ReplaySubject<Settings>(1);
	public extendedTours$ = new ReplaySubject<ExtendedTour[]>(1);
	public customer$ = new ReplaySubject<Customer>(1);
	public language$: Observable<any>;
	private extendedTours: ExtendedTour[] = [];

  	constructor(
		private tourStore: TourStore,
		private tourBookingStore: TourBookingStore,
		private productTextStore: ProductTextStore,
		private settingsStore: SettingsStore,
		private customerStore: CustomerStore,
		private route: ActivatedRoute,
		private productPickupLocationStore: ProductPickupLocationStore,
		private orderStore: OrderStore,
		private tourBookingMessageStore: TourBookingMessageStore,
		private cdr: ChangeDetectorRef,
		private adminDataService: AdminDataService,
		private pickupLocationStore: PickupLocationStore,
  	) {
		this.settingsStore.getOnSite().pipe(untilDestroyed(this)).subscribe(item => {
			this.siteSettings$.next(item);
		});
  	}

	ngOnInit() {
		this.language$ = this.route.queryParams.pipe(untilDestroyed(this), switchMap(params => {
			return this.adminDataService.avaiableLanguages$.pipe(map(allLanguages => {
				return _find(allLanguages, lang => lang.id === +params.languageId);
			}));
		}));

		combineLatest([
			this.route.queryParams,
			this.pickupLocationStore.items$,
		]).pipe(untilDestroyed(this)).subscribe(([params, pickupLocations]) => {
			if (params?.languageId && params?.customerId) {
				this.customerStore.get(params?.customerId).pipe(untilDestroyed(this)).subscribe(customer => this.customer$.next(customer));
				this.getTours(params.customerId, params.fromDate, params.toDate)
				.pipe(untilDestroyed(this))
					.subscribe(allToursOnCustomer => {
						allToursOnCustomer.filter(x => x.isActive).forEach(tour => {
							this.tourBookingStore.getAllOnCustomerAndTour(params?.customerId, tour.id).pipe(untilDestroyed(this)).subscribe(tourBookings => {
								const extendedTour = tour as ExtendedTour;
								if (params?.tourBookings?.length) {
									const tourBookingsToInclude = (params?.tourBookings as string).split(',');
									tourBookings = tourBookings.filter(x => tourBookingsToInclude.includes(x.id + ''));
								}
								
								if (!tourBookings.length) {
									return;
								}

								if (tour.productId) {
									this.productTextStore.getAllOnProduct(tour.productId).pipe(untilDestroyed(this)).subscribe(productTexts => {
										if (productTexts?.length) {
											extendedTour.message = productTexts.find(x => x.languageId === +params?.languageId)?.emailDescription;
										}
									});
								}
								extendedTour.tourBookings = tourBookings;
								extendedTour.tourBookings.forEach(tourBooking => {
									this.orderStore.getAllOnTourBooking(tourBooking.id).subscribe(orders => {
										const referenceOrder = _.find(orders, o => !!o.reference);
										if (referenceOrder) {
											extendedTour.reference = referenceOrder.reference;
										}
									});
									this.tourBookingMessageStore.getAllOnTourBooking(tourBooking.id).pipe().subscribe(message => {
										let tourBookingsMessage = '';
										if (message?.[0]?.message) {
											tourBookingsMessage = message[0].message;
										}
										tourBooking.tourBookingCustomerTypes.forEach(customerType => {
											this.addCustomerType(extendedTour, customerType.customerTypeId, customerType.quantity, 0, 0, tourBookingsMessage);
										});
									});
									tourBooking.tourBookingPickupLocations.forEach(pickup => {
										if (tour.productId) {
											this.productPickupLocationStore.getAllOnProduct(tour.productId).pipe(untilDestroyed(this)).subscribe(productPickups => {
												const find = productPickups?.find(x => x.pickupLocationId === pickup.pickupLocationId);
												const findOriginalPickupLocation = pickupLocations.find(x => x.id === pickup.pickupLocationId);
												const relativeTime = find?.relativeTime ?? findOriginalPickupLocation?.relativeTime ?? 0;
												this.addCustomerType(extendedTour, pickup.customerTypeId, 0, pickup.pickupLocationId, relativeTime);
											});
										}
										this.addCustomerType(extendedTour, pickup.customerTypeId, 0, pickup.pickupLocationId);
									});
								});
								this.extendedTours.push(extendedTour);
								this.extendedTours$.next(_.sortBy(this.extendedTours, 'date', 'time'));
							});
						});
					});
			}
		});
  	}

	private addCustomerType(
		extendedTour: ExtendedTour, customerTypeId: number, quantity: number, pickupLocationId?: number, relativeTime?: number, tourBookingMessage?: string,
	) {
		if (!extendedTour.summaries) {
			extendedTour.summaries = [];
		}
		let findSummary = extendedTour.summaries.find(x => x.customerTypeId === customerTypeId);
		if (!findSummary) {
			findSummary = {
				pickupLocationId,
				customerTypeId,
				quantity: 0,
				tourBookingMessage,
			};

			extendedTour.summaries.push(findSummary);
		}

		findSummary.quantity += quantity;
		if (pickupLocationId) {
			findSummary.pickupLocationId = pickupLocationId;
		}

		const tourTime = moment(`${moment(extendedTour.date).format('YYYY-MM-DD')} ${extendedTour.time}`);
		findSummary.relativeTime = tourTime.add(relativeTime, 'minute').format('HH:mm');

		if (tourBookingMessage) {
			findSummary.tourBookingMessage = tourBookingMessage;
		}
		setTimeout(() => {
			this.cdr.markForCheck();
		}, 500);
	}

	private getTours(customerId: number, fromDate: string, toDate: string): Observable<Tour[]> {
		if (fromDate && toDate) {
			return this.tourStore.getAllOnCustomerBetweenDates(customerId, fromDate, toDate);
		} else {
			return this.tourStore.getAllOnCustomer(customerId);
		}
	}
}

interface ExtendedTour extends Tour {
	tourBookings: TourBooking[];
	message: string;
	summaries: ExtendedTourSummary[];
	reference?: string;
}

interface ExtendedTourSummary {
	pickupLocationId?: number;
	relativeTime?: string;
	customerTypeId: number;
	quantity: number;
	tourBookingMessage: string;
}
