import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { PageBladeComponent } from '../page-blade/page-blade.component';
import { Page } from 'app/models/site/page.model';
import { PageStore } from 'app/admin/stores/page.store.service';

@UntilDestroy()
@Component({
	selector: 'app-pages-blade',
	templateUrl: './pages-blade.component.html',
	styleUrls: ['./pages-blade.component.scss'],
})
@IBladeComponent.register
export class PagesBladeComponent implements OnInit, OnDestroy {
	private pristinePrioList: number[];
	private pages: Page[];
	public prioIsChangedReplay$ = new ReplaySubject<boolean>();
	public savingPrioReplay$ = new ReplaySubject<boolean>();
	public displayedColumns: string[] = ['name', 'type', 'modified', 'active'];
	public dataSource: MatTableDataSource<Page>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: PageStore,
	) {
		itemStore.getAll();
		itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.pages = _.sortBy(items, i => i.prio);
			this.pristinePrioList = this.pages.map(p => p.id);

			this.dataSource = new MatTableDataSource<Page>(this.pages);
			this.dataSource.paginator = this.paginator;
		});
	}

	ngOnInit() { }

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: PageBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: PageBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	public onListDrop(event: CdkDragDrop<MatTableDataSource<Page>, any>) {
		const previousIndex = this.pages.findIndex(row => row === event.item.data);
		moveItemInArray(this.pages, previousIndex, event.currentIndex);
		this.pages.forEach((element, index) => element.prio = index);
		this.dataSource = new MatTableDataSource<Page>(this.pages);
		this.dataSource.paginator = this.paginator;

		this.isPrioChanged();
	}

	public savePrio() {
		this.savingPrioReplay$.next(true);
		this.itemStore.updatePrio(this.pages).pipe(untilDestroyed(this)).subscribe(done => {
			this.pristinePrioList = this.pages.map(p => p.id);
			this.savingPrioReplay$.next(false);
			this.isPrioChanged();
		});
	}

	private isPrioChanged() {
		if (this.pages.map(p => p.id).toString() !== this.pristinePrioList.toString()) {
			this.prioIsChangedReplay$.next(true);
		} else {
			this.prioIsChangedReplay$.next(false);
		}
	}
}
