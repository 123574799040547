import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { TravelAgencyCustomer } from 'app/models/travel-agency-customer.model';
import { SnackBarService } from '../services/snack-bar.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TravelAgencyProductDiscount } from 'app/models/travel-agency-product-discount.model';

@Injectable({
	providedIn: 'root',
})
export class TravelAgencyProductDiscountStore extends BaseStore<TravelAgencyProductDiscount> {
	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TravelAgencyProductDiscount', baseUrl, snackBarService);
	}

	public getAllOnTravelAgency(travelAgencyId: number): Observable<TravelAgencyProductDiscount[]> {
		return this.http.get<TravelAgencyProductDiscount[]>(`${this.host}/travelAgency/${travelAgencyId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnProduct(productId: number): Observable<TravelAgencyProductDiscount[]> {
		return this.http.get<TravelAgencyProductDiscount[]>(`${this.host}/product/${productId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}
}
