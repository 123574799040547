<mat-card>
	<mat-card-title>Inställningar</mat-card-title>

	<form [formGroup]="itemForm">
		<section>
			<mat-slide-toggle
				color="accent"
				formControlName="canOrderOnline">
				Ska kunden betala denna produkten online?
			</mat-slide-toggle>
		</section>
		<mat-hint>Om inte kommer ordern skickas per mejl till er för manuel bokning</mat-hint>
		<section class="mt-3">
			<mat-slide-toggle
				color="accent"
				formControlName="showSpecialDiet">
				Ska special diet visas för den här produkten?
			</mat-slide-toggle>
		</section>
	</form>
	<mat-card-footer class="p-3 text-right">
		<button mat-stroked-button color="accent" (click)="save()">
			Spara
		</button>
	</mat-card-footer>
</mat-card>
