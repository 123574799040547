<mat-card class="my-3">
	<mat-card-subtitle class="card-title-small">{{headline}}</mat-card-subtitle>
	<table mat-table [dataSource]="emailQueueService.dataSource" matSort class="w-100">
		<ng-container matColumnDef="emailQueueType">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
			<td mat-cell *matCellDef="let item">{{item.emailQueueType}} </td>
		</ng-container>
		<ng-container matColumnDef="sourceTypeId">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Källa</th>
			<td mat-cell *matCellDef="let item"><strong>{{item.sourceTypeId | sourceTypeName | async}}</strong></td>
		</ng-container>
		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
			<td mat-cell *matCellDef="let item">{{item.created | date:'yyyy-MM-dd HH:mm'}}</td>
		</ng-container>
		<ng-container matColumnDef="sent">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Skickad</th>
			<td mat-cell *matCellDef="let item">
				<span *ngIf="item.sent">{{item.sent | date:'yyyy-MM-dd HH:mm'}}</span>
				<span *ngIf="!item.sent">Inte skickat än</span>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="emailQueueService.displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: emailQueueService.displayedColumns;"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	<mat-card-actions class="text-right">
		<button mat-stroked-button (click)="senNewEmail()" [color]="'accent'">
			Skicka nytt mejl
		</button>
  </mat-card-actions>
</mat-card>
