import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProductPickupLocationStore } from 'app/admin/stores/product/product-pickup-location.store.service';
import { ProductTextStore } from 'app/admin/stores/product/product-text.store.service';
import { SettingsStore } from 'app/admin/stores/settings.store.service';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { Settings } from 'app/models/site/settings.model';
import { Supplier } from 'app/models/supplier.model';
import { TourBooking } from 'app/models/tour-booking.model';
import { Tour } from 'app/models/tour.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-tours-program',
  templateUrl: './tours-program.component.html',
  styleUrls: ['./tours-program.component.scss'],
})
export class ToursProgramComponent implements OnInit {
	public siteSettings$ = new ReplaySubject<Settings>(1);
	public extendedTours$ = new ReplaySubject<ExtendedTour[]>(1);
	public supplier$ = new ReplaySubject<Supplier>(1);
	private extendedTours: ExtendedTour[] = [];
	public showCustomerInfo$ = new ReplaySubject<boolean>(1);
	public showCanceledTours$ = new ReplaySubject<boolean>(1);

  	constructor(
		private tourStore: TourStore,
		private tourBookingStore: TourBookingStore,
		private productTextStore: ProductTextStore,
		private settingsStore: SettingsStore,
		private supplierStore: SupplierStore,
		private route: ActivatedRoute,
		private productPickupLocationStore: ProductPickupLocationStore,
  	) {
		this.settingsStore.getOnSite().pipe(untilDestroyed(this)).subscribe(item => {
			this.siteSettings$.next(item);
		});
  	}

	ngOnInit() {
		this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
			if (params?.supplierId && params?.fromDate && params?.toDate) {
				this.showCustomerInfo$.next(!!params.showCustomerInfo);
				this.showCanceledTours$.next(!!params.showCanceledTours);
				this.supplierStore.get(params?.supplierId).pipe().subscribe(supplier => this.supplier$.next(supplier));
				this.tourStore.getAllOnSupplierBetweenDates(params?.supplierId, params?.fromDate, params?.toDate)
					.pipe(untilDestroyed(this))
					.subscribe(allToursOnSupplier => {
						allToursOnSupplier.forEach(tour => {
							if (tour.tourStatusId !== 3 || !!params.showCanceledTours) {
								this.tourBookingStore.getAllOnTour(tour.id).pipe(untilDestroyed(this)).subscribe(tourBookings => {
									const extendedTour = tour as ExtendedTour;
									extendedTour.tourBookings = tourBookings;
									extendedTour.tourBookings.forEach(tourBooking => {
										tourBooking.tourBookingCustomerTypes.forEach(customerType => {
											this.addCustomerType(extendedTour, customerType.customerTypeId, customerType.quantity);
										});
										tourBooking.tourBookingPickupLocations.forEach(pickup => {
											if (tour.productId) {
												this.productPickupLocationStore.getAllOnProduct(tour.productId).pipe(untilDestroyed(this)).subscribe(productPickups => {
													const find = productPickups?.find(x => x.pickupLocationId === pickup.pickupLocationId);
													if (find) {
														this.addCustomerType(extendedTour, pickup.customerTypeId, 0, pickup.pickupLocationId, find.relativeTime);
													}
												});
											}
											this.addCustomerType(extendedTour, pickup.customerTypeId, 0, pickup.pickupLocationId);
										});
									});
									this.extendedTours.push(extendedTour);
									this.extendedTours$.next(_.sortBy(this.extendedTours, 'date', 'time'));
								});
							}
						});
					});
			}
		});
  	}

	private addCustomerType(extendedTour: ExtendedTour, customerTypeId: number, quantity: number, pickupLocationId?: number, relativeTime?: number) {
		if (!extendedTour.summaries) {
			extendedTour.summaries = [];
		}
		let findSummary = extendedTour.summaries.find(x => x.customerTypeId === customerTypeId);
		if (!findSummary) {
			findSummary = {
				pickupLocationId,
				customerTypeId,
				quantity: 0,
			};

			extendedTour.summaries.push(findSummary);
		}

		findSummary.quantity += quantity;
		if (pickupLocationId) {
			findSummary.pickupLocationId = pickupLocationId;
		}

		const tourTime = moment(`${moment(extendedTour.date).format('YYYY-MM-DD')} ${extendedTour.time}`);
		findSummary.relativeTime = tourTime.add(relativeTime, 'minute').format('HH:mm');
	}
}

interface ExtendedTour extends Tour {
	tourBookings: TourBooking[];
	message: string;
	summaries: ExtendedTourSummary[];
}

interface ExtendedTourSummary {
	pickupLocationId?: number;
	relativeTime?: string;
	customerTypeId: number;
	quantity: number;
}
