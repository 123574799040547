<app-blade-item [canIClose]="true">
	<app-blade-header [title]="item?.name || 'Ny'" [subTitle]="'System'"></app-blade-header>
	<app-blade-content>
		<form [formGroup]="formGroup" class="mb-2">
			<mat-form-field class="w-100">
				<input matInput formControlName="name" placeholder="Namn">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="host" placeholder="Url/Host">
			</mat-form-field>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<button mat-raised-button>Avbryt</button>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">Spara</button>
	</app-blade-footer>
</app-blade-item>
