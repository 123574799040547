<app-blade-item [canIClose]="true">
	<app-blade-header [title]="'Ny'" [subTitle]="'Bildspels objekt'"></app-blade-header>
	<app-blade-content>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<mat-label>Välj vilken typ du vill lägga till</mat-label>
				<mat-select formControlName="slideshowItemType">
					<mat-option *ngFor="let type of (slideshowItemTypeStore.items$|async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()" [disabled]="itemForm.invalid">Lägg till ny</button>
	</app-blade-footer>
</app-blade-item>
