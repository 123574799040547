import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class TourBookingCustomerTypeStore extends BaseStore<TourBookingCustomerType> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TourBookingCustomerType', baseUrl, snackBarService);
	}

	public getAllOnTourBooking(tourBookingId: number): Observable<TourBookingCustomerType[]> {
		return this.http.get<TourBookingCustomerType[]>(`${this.host}/tourBooking/${tourBookingId}`).pipe(tap(tourBookings => {
			this._items = tourBookings;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnTourBooking$(tourBookingId: number): Observable<TourBookingCustomerType[]> {
		return this.http.get<TourBookingCustomerType[]>(`${this.host}/tourBooking/${tourBookingId}`);
	}
}
