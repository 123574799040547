import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-enum-dashboard',
	templateUrl: './enum-dashboard.component.html',
	styleUrls: ['./enum-dashboard.component.scss'],
})
export class EnumsDashboardComponent implements OnInit {
	constructor() { }

	ngOnInit() {
	}

}
