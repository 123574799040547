import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';
import { ReplaySubject, Observable, of, throwError } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';

import { BaseModel } from 'app/models/base.model';
import { SnackBarService } from '../services/snack-bar.service';
import { EntityName } from 'app/models/entity-name.model';

export abstract class BaseV3Store<T extends BaseModel> {
	protected entityPipeNames: EntityName[] = [];
	protected entityPipeNameObservables: {[key: number]: ReplaySubject<EntityName>} = {};

	protected host: string;
	protected _items: T[] = [];
	protected _itemsSubject = new ReplaySubject<T[]>(1);
	public items$ = this._itemsSubject.asObservable();

	protected changedItemSubject = new ReplaySubject<T | number>(1);

	/**
	 * Broadcast the created or updated object. Or removed object ID.
	 */
	public changedItem$ = this.changedItemSubject.asObservable();

	constructor(
		protected http: HttpClient,
		public path: string,
		@Inject('BASE_URL') baseUrl: string,
		protected snackBarService: SnackBarService,
	) {
		this.host = `${baseUrl}api/admin/v3/${path}`;
	}

	public reset() {
		this._items = [];
		this._itemsSubject.next(this._items);
	}

	public getAll() {
		this.http.get<T[]>(`${this.host}/all`).subscribe(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		});
	}

	public getAll$(): Observable<T[]> {
		return this.http.get<T[]>(`${this.host}/all`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public get(id: number): Observable<T> {
		return this.http.get<T>(`${this.host}/${id}`).pipe(tap(items => {
			const findObj = _.find(this._items, type => type.id === items.id);
			_.assign(findObj, items); // Keep object in sync
		}));
	}

	public create(item: T): Observable<T> {
		return this.http.put<T>(`${this.host}`, item).pipe(tap(createdItem => {
			if (!this._items) {
				this._items = [];
			}
			this._items.push(createdItem);
			this._itemsSubject.next(this._items);
			this.changedItemSubject.next(createdItem);
			this.snackBarService.showSuccess('Objektet har han skapats.');
		}), catchError(error => {
			this.snackBarService.showError(`Något gick fel: ${error.message}`);
			return throwError(error);
		}));
	}

	public update(item: T): Observable<T> {
		return this.http.post<T>(`${this.host}`, item).pipe(tap(updatedItem => {
			const findObj = _.find(this._items, type => type.id === item.id);
			_.assign(findObj, updatedItem);
			this._itemsSubject.next(this._items);
			this.changedItemSubject.next(updatedItem);
			this.snackBarService.showSuccess('Objektet har han uppdaterats.');
		}), catchError(error => {
			this.snackBarService.showError(`Något gick fel: ${error.message}`);
			return throwError(error);
		}));
	}

	public delete(id: number): Observable<boolean> {
		return this.http.delete<boolean>(`${this.host}/${id}`).pipe(tap(isRemoved => {
			if (isRemoved) {
				_.remove(this._items, type => type.id === id);
				this._itemsSubject.next(this._items);
				this.changedItemSubject.next(id);
				this.snackBarService.showSuccess('Objektet har han raderats.');
			} else {
				this.snackBarService.showError(`Objektet kunde inte raderas. Kontrollera om det finns relationer kvar.`);
			}
		}));
	}

	public pipeGet(id: number): Observable<T> {
		return this._itemsSubject.pipe(switchMap(items => {
			const findObj = _.find(this._items, type => type.id === id);
			if (findObj) {
				return of(findObj);
			}
			return of({} as T);
		}));
	}

	public pipeGetName(id: number, languageId?: number): Observable<EntityName> {
		const findObj = _.find(this.entityPipeNames, type => type.id === id);
		if (findObj) {
			return of(findObj);
		} else {
			return this.preGetCustomerName(id, languageId);
		}
	}

	private preGetCustomerName(id: number, languageId?: number): Observable<EntityName> {
		if (!this.entityPipeNameObservables[id]) {
			this.entityPipeNameObservables[id] = new ReplaySubject<EntityName>(1);
			this.getName(id, languageId);
		}
		return this.entityPipeNameObservables[id];
	}

	private getName(id: number, languageId?: number): void {
		this.doGetName(id, languageId).subscribe(customerName => {
			const findObj = _.find(this.entityPipeNames, type => type.id === customerName.id);
			if (!findObj) {
				this.entityPipeNames.push(customerName);
			}
			this.entityPipeNameObservables[id].next(customerName);
		});
	}

	private doGetName(id: number, languageId?: number) {
		if (id && languageId) {
			return this.http.get<EntityName>(`${this.host}/${id}/fullname/language/${languageId}`);
		} else {
			return this.http.get<EntityName>(`${this.host}/${id}/fullname`);
		}
	}
}
