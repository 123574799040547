<mat-card>
	<mat-card-content>
		<mat-card-title>Nets inställningar</mat-card-title>
		<mat-card-subtitle>Dessa nycklar finner du här: <a href="https://portal.dibspayment.eu/integration">https://portal.dibspayment.eu/integration</a></mat-card-subtitle>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="secretKey" placeholder="secretKey">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="checkoutKey" placeholder="checkoutKey">
			</mat-form-field>
			<div class="text-right">
				<button mat-raised-button color="primary" (click)="save()" type="submit" [disabled]="!itemForm.valid">
					<span *ngIf="!itemForm.value.id">Skapa</span>
					<span *ngIf="itemForm.value.id">Spara</span>
				</button>
			</div>
		</form>
	</mat-card-content>
</mat-card>
