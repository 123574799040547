import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { ElementStore } from 'app/admin/stores/element.store.service';
import { Element } from 'app/models/element.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { PageStore } from 'app/admin/stores/page.store.service';
import { Page } from 'app/models/site/page.model';
import { PageTypeEnum } from 'app/enums/page-type.enum';
import { ElementTypeEnum } from 'app/enums/element-type.enum';

@UntilDestroy()
@Component({
	selector: 'app-element-blade-generally',
	templateUrl: './element-blade-generally.component.html',
	styleUrls: ['./element-blade-generally.component.scss'],
})
export class ElementBladeGenerallyComponent implements OnInit, OnDestroy {
	public PageTypeEnum = PageTypeEnum;
	public ElementTypeEnum = ElementTypeEnum;
	private allMenuItems: BladeMenuItem[];
	public selectedElement: Element;
	@Input()
	set saving(subject: Subject<boolean>) {
		if (subject) {
			subject.pipe(untilDestroyed(this)).subscribe(isSaving => {
				this.save();
			});
		}
	}
	@Input() set element(element: Element) {
		this.selectedElement = element;
		this.itemForm.get('onProduct').setValue(this.selectedElement.onProduct);
		this.itemForm.get('onCategory').setValue(this.selectedElement.onCategory);
		this.itemForm.get('onProduct').valueChanges.pipe(untilDestroyed(this)).subscribe(onProduct => this.selectedElement.onProduct = onProduct);
		this.itemForm.get('onCategory').valueChanges.pipe(untilDestroyed(this)).subscribe(onCategory => this.selectedElement.onCategory = onCategory);
		this.handleMenuItems();
		this.pageStore.getOnElement(this.selectedElement.id).pipe(untilDestroyed(this)).subscribe(page => {
			this.page = page;
		});
	}
	@Input() set menuItems(menuItems: BladeMenuItem[]) {
		this.allMenuItems = menuItems;
		this.handleMenuItems();
	}
	public itemForm: FormGroup = new FormGroup({
		onProduct: new FormControl('', [Validators.required]),
		onCategory: new FormControl('', [Validators.required]),
	});
	public page: Page;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		private itemStore: ElementStore,
		private pageStore: PageStore,
	) {}

	ngOnInit() {}
	ngOnDestroy() { }

	private save() {
		this.itemStore.update(this.selectedElement).subscribe(updatedItem => {
			this.selectedElement = _.assign(this.selectedElement, updatedItem);
			this.itemForm.get('onProduct').setValue(this.selectedElement.onProduct);
			this.itemForm.get('onCategory').setValue(this.selectedElement.onCategory);
			this.handleMenuItems();
		});
	}

	private handleMenuItems() {
		console.log(this.allMenuItems, this.selectedElement);
		if (this.allMenuItems && this.selectedElement) {
			_.each(this.allMenuItems, item => {
				if (item.index > 0 && item.index < 99) {
					item.disabled = this.selectedElement.onProduct || this.selectedElement.onCategory;
				}
			});
		}
	}
}
