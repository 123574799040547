import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'addTime' })
export class AddTimePipe implements PipeTransform {
	constructor() {	}
	public transform(date: Date, time: string, relativeTime: number): string {
		if (!date || !time) {
			return '--';
		}
		const timeList = time.split(':');
		if (timeList?.length !== 2) {
			return;
		}

		let theDate = moment(date);
		theDate.set('hour', parseInt(timeList[0], 10));
		theDate.set('minutes', parseInt(timeList[1], 10));
		theDate.add(relativeTime, 'minutes');

		return theDate.format('HH:mm');
	}
}
