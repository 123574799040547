<mat-card *ngIf="{
	addons: addonStore.items$ | async,
	supplierResourceAddonIds: supplierResourceAddonIds$ | async
} as source">
	<p>Koppla tillägg</p>
	<mat-checkbox class="mr-3 d-block"
		*ngFor="let addon of source.addons"
		[checked]="source.supplierResourceAddonIds?.includes(addon.id)"
		(change)="toggleAddon(addon.id)">
			{{addon.name}}
	</mat-checkbox>
</mat-card>
