import { Component, Input } from '@angular/core';
import { MediaStore } from '../../stores/media.store.service';
import { Media } from 'app/models/media.model';

@Component({
	selector: 'app-image',
	templateUrl: './image-element.component.html',
	styleUrls: ['./image-element.component.scss'],
})
export class ImageElementComponent {
	public image: Media;
	public cssStyle: any = {};

	@Input()
	set maxWidth(width: number) {
		this.cssStyle.maxWidth = width + 'px';
	}
	@Input()
	set maxHeigth(height: number) {
		this.cssStyle.maxHeight = height + 'px';
	}

	@Input()
	set mediaId(id: number) {
		if (id) {
			this.mediaStore.get(id).subscribe(media => {
				this.image = media;
			});
		} else {
			this.image = undefined;
		}
	}

	constructor(public mediaStore: MediaStore) {
	}
}
