import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dictionary } from 'lodash';
import * as _ from 'lodash';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourBookingPickupLocationStore } from 'app/admin/stores/tour-booking-pickup-location.store.service';
import { TourBookingPickupLocation } from 'app/models/tour-booking-pickup-location.model';
import { TourBookingPickupLocationBladeComponent } from 'app/admin/blades/tour-booking-pickup-location/tour-booking-pickup-location-blade/tour-booking-pickup-location-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-tour-booking-blade-bookings-pickup',
	templateUrl: './tour-booking-blade-bookings-pickup.component.html',
	styleUrls: ['./tour-booking-blade-bookings-pickup.component.scss'],
})
export class TourBookingBladeBookingsPickupComponent implements OnDestroy {
	@ViewChild(MatAccordion) accordion: MatAccordion;
	public displayedColumns: string[] = ['id', 'customerType', 'quantity', 'created'];
	public tourBookingPickupLocationsReplay$ = new ReplaySubject<Dictionary<TourBookingPickupLocation[]>>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private tourBookingPickupLocationStore: TourBookingPickupLocationStore,
		private bladeService: BladeService,
	) {
		this.tourBookingPickupLocationStore.getAllOnTourBooking(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(tourBookingPickupLocations => {
			this.tourBookingPickupLocationsReplay$.next(_.groupBy(tourBookingPickupLocations, type => type.pickupLocationId));
		});
	}

	ngOnDestroy() { }

	public selectItem(row: TourBookingPickupLocation) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: TourBookingPickupLocationBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}
}
