import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { FormElement } from 'app/models/form-element.model';

@UntilDestroy()
@Injectable()
export class FormBuilderService {
	public formElement: FormElement;
	public itemForm: FormGroup = new FormGroup({
		headline: new FormControl('', [Validators.required]),
		description: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required]),
		submitBtn: new FormControl('', [Validators.required]),
	});

	constructor() { }

	public addNewElement() {
		this.formElement.formData.elements.push({
			prio: this.formElement.formData.elements?.length || 0,
			formBuilderElementType: 'text',
		});
	}

	public setFormElement(formElement: FormElement) {
		this.formElement = formElement;
		if (!this.formElement.formData.elements) {
			this.formElement.formData.elements = [];
		}
		this.itemForm.get('headline').setValue(this.formElement.formData.headline);
		this.itemForm.get('headline').valueChanges.pipe(untilDestroyed(this)).subscribe(headline => this.formElement.formData.headline = headline);

		this.itemForm.get('description').setValue(this.formElement.formData.description);
		this.itemForm.get('description').valueChanges.pipe(untilDestroyed(this)).subscribe(description => this.formElement.formData.description = description);

		this.itemForm.get('email').setValue(this.formElement.formData.email);
		this.itemForm.get('email').valueChanges.pipe(untilDestroyed(this)).subscribe(email => this.formElement.formData.email = email);

		this.itemForm.get('submitBtn').setValue(this.formElement.formData.submitBtn);
		this.itemForm.get('submitBtn').valueChanges.pipe(untilDestroyed(this)).subscribe(submitBtn => this.formElement.formData.submitBtn = submitBtn);
	}

	public drop(event: CdkDragDrop<{title: string; poster: string}[]>) {
		moveItemInArray(this.formElement.formData.elements, event.previousIndex, event.currentIndex);
		this.formElement.formData.elements.forEach((element, index) => element.prio = index);
	}
}
