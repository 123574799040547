<mat-card *ngIf="{
	tourBookingCustomerType: tourBookingCustomerTypeItemService.tourBookingCustomerTypeReplay$ | async
} as source">
	<form [formGroup]="tourBookingCustomerTypeItemService.itemForm">
		<mat-form-field class="w-100" appearance="outline">
			<mat-label>Typ</mat-label>
			<mat-select formControlName="customerTypeId">
				<mat-option *ngFor="let type of (customerTypeStore.items$ | async)" [value]="type.id">
					{{type.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="w-100" appearance="outline">
			<input matInput type="number" [min]="0" formControlName="quantity" placeholder="Antal">
		</mat-form-field>
	</form>

	<app-tour-booking-customer-type-age-item *ngFor="let age of source.tourBookingCustomerType?.tourBookingCustomerTypeAges" [tourBookingCustomerTypeAge]="age"></app-tour-booking-customer-type-age-item>

	<div class="align-items-baseline d-flex justify-content-between mt-3">
		<button mat-button color="warn" class="mb-3" (click)="tourBookingCustomerTypeItemService.tourBookingCustomerTypeService.removeCustomerType(source.tourBookingCustomerType)">
			Radera <mat-icon>delete_forever</mat-icon>
		</button>
		<button mat-button color="accent" (click)="tourBookingCustomerTypeItemService.addNewAge()">Lägg till ny ålder</button>
	</div>
</mat-card>
