<mat-card>
	<mat-card-content>
		<mat-card-title>Papperskorg</mat-card-title>
		<mat-card-subtitle>
			<p class="mb-1">
				Visar en lista på alla raderade objekt i systemet.<br>
				Man kan försöka återställa objektet.<br>
				Går det inte så kan det bero på att andra objekt saknas i relationer.<br>
			</p>
		</mat-card-subtitle>
		<mat-card-content>
			<mat-accordion>
				<mat-expansion-panel *ngFor="let entity of items$ | async">
					<mat-expansion-panel-header>
					<mat-panel-title>
						{{entity.entityName}}
					</mat-panel-title>
					<mat-panel-description class="justify-content-end">
						<span class="mr-3">{{entity.deletedBy | adminUserName | async}}</span> <span>{{entity.deleted | date:'yyyy-MM-dd HH:mm:ss'}}</span>
					</mat-panel-description>
					</mat-expansion-panel-header>
					<pre>{{entity.entityJson | json}}</pre>
					<div class="text-right">
						<button mat-raised-button color="primary" (click)="restore(entity)">Återställ</button>
					</div>
				</mat-expansion-panel>
			</mat-accordion>			
		</mat-card-content>
	</mat-card-content>
</mat-card>