import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ProductTypeStore } from '../stores/product/product-type.store.service';

/**
 * Print product type name
 */
@Pipe({ name: 'productTypeName' })
export class ProductTypeNamePipe implements PipeTransform, OnDestroy {
	constructor(private productTypeStore: ProductTypeStore) { }

	public transform(productTypeId: number): Observable<string> {
		if (!productTypeId) {
			return;
		}
		return this.productTypeStore.pipeGet(productTypeId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
