<ng-container *ngIf="tourBookingAddonService.tourBookingAddonsReplay$ | async as tourBookingAddons">
	<h2 mat-dialog-title>Redigera tillägg på tourBooking</h2>
	<mat-dialog-content>
		<p *ngIf="!tourBookingAddons?.length">Det finns inga tillägg.</p>
		<app-tour-booking-addon-item class="d-block mt-3" *ngFor="let item of tourBookingAddons" [tourBookingAddon]="item"></app-tour-booking-addon-item>

		<div class="text-right mt-3">
			<button mat-stroked-button color="accent" (click)="tourBookingAddonService.addNew()">Lägg till nytt tillägg</button>
		</div>
		<div class="text-right mt-3 pt-3 border-top">
			<button mat-raised-button color="primary" [disabled]="tourBookingAddonService.isSaving$ | async" (click)="tourBookingAddonService.save()">Spara</button>
		</div>
	</mat-dialog-content>
</ng-container>
