<div class="mat-elevation-z1">
	<table mat-table [dataSource]="dataSource" matSort class="w-100">
		<ng-container matColumnDef="customerType">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Kundtyp</th>
			<td mat-cell *matCellDef="let item">{{item.customerTypeId | customerTypeName | async}}</td>
		</ng-container>
		<ng-container matColumnDef="price">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Pris</th>
			<td mat-cell *matCellDef="let item">{{item.price}}</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.payload?.customerTypeId == item?.customerTypeId"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>
