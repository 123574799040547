import { Component } from '@angular/core';
import { DashboardWidgetSize } from '../../components/dashboard/models/dashboard-widget-size.model';
import { DashboardWidget } from '../../components/dashboard/models/dashboard-widget.model';
import { DashboardWidgetType } from '../../components/dashboard/models/dashboard-widget-type.model';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesPageComponent {
  constructor() { }
}
