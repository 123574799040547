import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourGuideStore } from 'app/admin/stores/tour-guide.store.service';
import { TourGuide } from 'app/models/tour-guide.model';
import { TourGuideBladeComponent } from 'app/admin/blades/tour-guide/tour-guide-blade/tour-guide-blade.component';

@Component({
	selector: 'app-tour-guides',
	templateUrl: './tour-guides.component.html',
	styleUrls: ['./tour-guides.component.scss'],
})
export class TourGuidesComponent implements OnInit {
	public displayedColumns: string[] = ['name', 'type'];
	public dataSource: MatTableDataSource<TourGuide>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public tourBookingStore: TourGuideStore,
		private bladeService: BladeService,
	) {
		this.getAllOnTour();
		this.bladeItem.callback = () => {
			this.getAllOnTour();
		};
	}

	ngOnInit() {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: TourGuideBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: TourGuideBladeComponent,
			minClass: BladeSize.xs,
			payload: {
				tourId: this.bladeItem.id,
			},
		}, this.bladeItem);
	}

	private getAllOnTour() {
		this.tourBookingStore.getAllOnTour(this.bladeItem.id).subscribe(tourGuides => {
			this.dataSource = new MatTableDataSource<TourGuide>(tourGuides);
			this.dataSource.sort = this.sort;
			this.dataSource.paginator = this.paginator;
		});
	}
}
