import { Component, OnInit, Inject } from '@angular/core';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Observable } from 'rxjs';
import { CategoryImageType } from 'app/models/category/category-image-type.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CategoryImageTypeStore } from 'app/admin/stores/category/category-image-type.store.service';
import * as _ from 'lodash';

@Component({
	selector: 'app-category-image-type-blade',
	templateUrl: './category-image-type-blade.component.html',
	styleUrls: ['./category-image-type-blade.component.scss'],
})
@IBladeComponent.register
export class CategoryImageTypeBladeComponent implements OnInit {

	public loading = true;
	public item: CategoryImageType;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		identifier: new FormControl('', [Validators.required]),
	});
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: CategoryImageTypeStore,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.itemForm.setValue({name: this.item.name, identifier: this.item.identifier});

				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new CategoryImageType();
		}
	 }

	ngOnInit() {
	}

	public createIdentifier() {
		this.itemForm.get('identifier').setValue(_.snakeCase(this.itemForm.get('name').value));
	}

	public save() {
		this.loading = true;
		this.item.name = this.itemForm.get('name').value;
		this.item.identifier = this.itemForm.get('identifier').value;
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<CategoryImageType> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}
}
