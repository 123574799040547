import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TourBookingAddonComponent } from '../dialogs/tour-booking-addon/tour-booking-addon.component';
import { TourBookingCustomerTypeComponent } from '../dialogs/tour-booking-customer-type/tour-booking-customer-type.component';
import { TourBookingMessageComponent } from '../dialogs/tour-booking-message/tour-booking-message.component';
import { TourBookingPickupLocationComponent } from '../dialogs/tour-booking-pickup-location/tour-booking-pickup-location.component';
import { TourBookingSpecialDietComponent } from '../dialogs/tour-booking-special-diet/tour-booking-special-diet.component';

@Injectable()
export class DialogEditService {

	constructor(public dialog: MatDialog) { }

	public editTourBookingCustomerType(tourBookingId: number): Observable<any> {
		const dialogRef = this.dialog.open(TourBookingCustomerTypeComponent, {
			data: tourBookingId,
		});

		return dialogRef.afterClosed();
	}

	public editTourBookingAddon(tourBookingId: number): Observable<any> {
		const dialogRef = this.dialog.open(TourBookingAddonComponent, {
			data: tourBookingId,
		});

		return dialogRef.afterClosed();
	}

	public editTourBookingPickupLocation(tourBookingId: number): Observable<any> {
		const dialogRef = this.dialog.open(TourBookingPickupLocationComponent, {
			data: tourBookingId,
		});

		return dialogRef.afterClosed();
	}

	public editTourBookingSpecialDietLocation(tourBookingId: number): Observable<any> {
		const dialogRef = this.dialog.open(TourBookingSpecialDietComponent, {
			data: tourBookingId,
		});

		return dialogRef.afterClosed();
	}

	public editTourBookingMessage(tourBookingId: number): Observable<any> {
		const dialogRef = this.dialog.open(TourBookingMessageComponent, {
			data: tourBookingId,
		});

		return dialogRef.afterClosed();
	}
}
