<mat-card *ngIf="{
	user: userService.user$ | async
} as source">
	<mat-card-title>Generellt</mat-card-title>
	<mat-card-content>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="firstName" placeholder="Förnamn">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="lastName" placeholder="Efternamn">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="email" placeholder="Epost">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="phone" placeholder="Telefon">
			</mat-form-field>

			<mat-form-field class="w-100" *ngIf="source.user?.userType === UserTypeEnum.admin">
				<mat-label>Resebyrå</mat-label>
				<mat-select formControlName="travelAgencyId">
					<mat-option value="0">Ingen resebyrå vald</mat-option>
					<mat-option *ngFor="let type of (travelAgencyStore.items$ | async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<div class="row">
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>Källa</mat-label>
						<mat-select formControlName="sourceTypeId">
							<mat-option *ngFor="let type of (sourceTypeStore.items$|async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field class="w-100">
						<mat-label>Språk</mat-label>
						<mat-select formControlName="languageId">
							<mat-option *ngFor="let type of (languageStore.items$|async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<email-queue *ngIf="customer?.id" [emailQueueType]="'NewCustomer'" [entityName]="'Customers'" [entityId]="customer?.id"></email-queue>

		</form>
		<div class="row" *ngIf="customer?.id">
			<div class="col-6">
				<b>Skapad:</b><br>
				{{customer.created | date:'yyyy-MM-dd HH:mm'}}<br>
				<span *ngIf="customer.createdBy > 0"><small>{{customer.createdBy | adminUserName | async}}</small></span>
			</div>
			<div class="col-6">
				<b>Ändrad:</b><br>
				{{customer.modified | date:'yyyy-MM-dd HH:mm'}}<br>
				<span *ngIf="customer.modifiedBy > 0"><small>{{customer.modifiedBy | adminUserName | async}}</small></span>
			</div>
		</div>
	</mat-card-content>
</mat-card>
