import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AddonStore } from '../stores/addon.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'addonName' })
export class AddonNamePipe implements PipeTransform, OnDestroy {
	constructor(private addonStore: AddonStore) {	}
	public transform(addonId: number, languageId?: number): Observable<string> {
		if (!addonId) {
			return;
		}
		return this.addonStore.pipeGetName(addonId, languageId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
