
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';

import { Media } from 'app/models/media.model';
import { BaseStore } from './base.store.service';
import { UploadMedia } from 'app/models/upload-media.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class MediaStore extends BaseStore<Media> {
	public imgPath = '';

	constructor(
		protected http: HttpClient,
		@Inject('BASE_URL') baseUrl: string,
		@Inject('BASE_MEDIA_URL') baseMediaUrl: string,
		protected snackBarService: SnackBarService,
	) {
		super(http, 'Media', baseUrl, snackBarService);
		this.imgPath = baseMediaUrl;
	}

	public uploade(uploadeImage: UploadMedia, file: File) {

		const formData = new FormData();
		formData.append('file', file, file.name);

		this.http.post(`${this.host}/upload`, formData, { reportProgress: true, observe: 'events' })
			.subscribe(event => {
				if (event.type === HttpEventType.UploadProgress) {
					uploadeImage.progress = Math.round(100 * event.loaded / event.total);
				} else if (event.type === HttpEventType.Response && event.body) {
					uploadeImage.isDone = true;
					this._items.push(event.body as Media);
					this._itemsSubject.next(this._items);
				}
			});

	}

	public replace(uploadeImage: UploadMedia, file: File, fileId: number) {

		const formData = new FormData();
		formData.append('file', file, file.name);

		this.http.post(`${this.host}/replace/id/${fileId}`, formData, { reportProgress: true, observe: 'events' })
			.subscribe(event => {
				if (event.type === HttpEventType.UploadProgress) {
					uploadeImage.progress = Math.round(100 * event.loaded / event.total);
				} else if (event.type === HttpEventType.Response && event.body) {
					uploadeImage.isDone = true;
					this._items.push(event.body as Media);
					this._itemsSubject.next(this._items);
				}
			});
	}

	public uploadeWiki(uploadeImage: UploadMedia, file: File): Promise<Media> {
		var promise = new Promise<Media>((resolve, reject) => {
			const formData = new FormData();
			formData.append('file', file, file.name);

			this.http.post(`${this.host}/upload-wiki`, formData, { reportProgress: true, observe: 'events' })
				.subscribe(event => {
					if (event.type === HttpEventType.UploadProgress) {
						uploadeImage.progress = Math.round(100 * event.loaded / event.total);
					} else if (event.type === HttpEventType.Response && event.body) {
						uploadeImage.isDone = true;
						this._items.push(event.body as Media);
						this._itemsSubject.next(this._items);
						return resolve(event.body as Media);
					}
				});
		});

		return promise;

	}
}
