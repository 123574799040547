import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingCustomerTypeAge } from 'app/models/tour-booking-customer-type-age.model';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { TourBookingCustomerTypeService } from '../../../services/tour-booking-customer-type.service';

@UntilDestroy()
@Injectable()
export class TourBookingCustomerTypeItemService {
	private tourBookingCustomerType: TourBookingCustomerType;
	private tourBookingCustomerTypeReplay = new ReplaySubject<TourBookingCustomerType>(1);
	public tourBookingCustomerTypeReplay$ = this.tourBookingCustomerTypeReplay.asObservable();

	public itemForm: FormGroup = new FormGroup({
		customerTypeId: new FormControl('', [Validators.required]),
		quantity: new FormControl('', [Validators.required]),
	});

  	constructor(
		public tourBookingCustomerTypeService: TourBookingCustomerTypeService,
	  ) {
		this.itemForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			Object.assign(this.tourBookingCustomerType, value);
		});
	}

	public setTourBookingCustomerTypeItem(tourBookingCustomerType: TourBookingCustomerType) {
		this.tourBookingCustomerType = tourBookingCustomerType;
		if (!this.tourBookingCustomerType.tourBookingCustomerTypeAges) {
			this.tourBookingCustomerType.tourBookingCustomerTypeAges = [];
		}
		this.itemForm.patchValue(this.tourBookingCustomerType);
		this.tourBookingCustomerTypeReplay.next(this.tourBookingCustomerType);
	}

	public addNewAge() {
		this.tourBookingCustomerType.tourBookingCustomerTypeAges.push({
			tourBookingCustomerTypeId: this.tourBookingCustomerType.id,
			birthDate: new Date(),
		});
		this.tourBookingCustomerTypeReplay.next(this.tourBookingCustomerType);
	}

	public removeAge(tourBookingCustomerTypeAge: TourBookingCustomerTypeAge) {
		const findIndex = _.findIndex(this.tourBookingCustomerType.tourBookingCustomerTypeAges, tourBookingCustomerTypeAge);
		if (findIndex > -1) {
			this.tourBookingCustomerType.tourBookingCustomerTypeAges.splice(findIndex, 1);
			if (tourBookingCustomerTypeAge.id) {
				this.tourBookingCustomerTypeService.removeAge(tourBookingCustomerTypeAge.id);
			}
		}
	}
}
