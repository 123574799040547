import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { Page } from 'app/models/site/page.model';
import { PageTypeStore } from 'app/admin/stores/page-type.store.service';
import { PageBladeService } from '../../services/page-blade.service';

@Component({
	selector: 'app-page-blade-generally',
	templateUrl: './page-blade-generally.component.html',
	styleUrls: ['./page-blade-generally.component.scss'],
})
export class PageBladeGenerallyComponent implements OnInit {

	@Input() item: Page;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		public pageTypeStore: PageTypeStore,
		public pageBladeService: PageBladeService,
	) { }

	ngOnInit() {
	}
}
