
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseStore } from 'app/admin/stores/base.store.service';
import { Dashboard } from 'app/models/dashboard.model';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable()
export class DashboardStore extends BaseStore<Dashboard> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Dashboard', baseUrl, snackBarService);
	}

	public getAllOnUser(): Observable<Dashboard[]> {
		return this.http.get<Dashboard[]>(`${this.host}/user`).pipe(tap(dashboards => {
			this._items = dashboards;
			this._itemsSubject.next(this._items);
		}));
	}

}
