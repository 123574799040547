import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SlideshowItem } from 'app/models/slideshow-item.model';
import { SlideshowItemStore } from 'app/admin/stores/slideshow-item.store.service';
import { SlideshowElement } from 'app/models/slideshow-element.model';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { SlideshowItemTypeService } from 'app/admin/services/slideshow-item-type.service';
import { SlideshowItemBladeComponent } from 'app/admin/blades/slideshow-item/slideshow-item-blade/slideshow-item-blade.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-element-slideshow-blade-items',
	templateUrl: './element-slideshow-blade-items.component.html',
	styleUrls: ['./element-slideshow-blade-items.component.scss'],
	providers: [SlideshowItemTypeService],
})
export class ElementSlideshowBladeItemsComponent implements OnInit {
	private activeSlideshowElement: SlideshowElement;
	public displayedColumns: string[] = ['id', 'name', 'created', 'modified'];
	public dataSource: MatTableDataSource<SlideshowItem>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	@Input() set slideshowElement(slideshowElement: SlideshowElement) {
		this.activeSlideshowElement = slideshowElement;
		this.slideshowItemStore.reset();
		this.slideshowItemStore.getAllOnSlideshowElement(slideshowElement.id);

		this.slideshowItemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<SlideshowItem>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private slideshowItemStore: SlideshowItemStore,
		private bladeService: BladeService,
		private slideshowItemTypeService: SlideshowItemTypeService,
	) {
	}

	ngOnInit() {
	}

	public selectItem(item: SlideshowItem) {
		this.bladeService.addBladeItem({
			id: item.id,
			component: this.slideshowItemTypeService.getSlideshowItemComponent(item.slideshowItemTypeId),
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: this.activeSlideshowElement.id,
			component: SlideshowItemBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}
}
