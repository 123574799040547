<mat-card>
	<mat-card-title>Kund</mat-card-title>

	<mat-card-content>
		<div class="row">
			<div class="col-6 mt-2"><b>Förnamn:</b><br>{{customer?.firstName}}</div>
			<div class="col-6 mt-2"><b>Efternamn:</b><br>{{customer?.lastName}}</div>
			<div class="col-6 mt-2"><b>Epost:</b><br>{{customer?.email}}</div>
			<div class="col-6 mt-2"><b>Telefon:</b><br>{{customer?.phone}}</div>
			<div class="col-6 mt-2"><b>Skapad:</b><br>{{customer?.created | date: 'yyyy-MM-dd'}}</div>
			<div class="col-6 mt-2"><b>Ändrad:</b><br>{{customer?.modified | date: 'yyyy-MM-dd'}}</div>
		</div>
	</mat-card-content>
	<mat-card-actions>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="openCustomer()">Öppna kund</button>
	</mat-card-actions>
</mat-card>
