<form [formGroup]="itemForm">
	<mat-form-field class="w-100">
		<input matInput formControlName="name" placeholder="Namn">
	</mat-form-field>
	<mat-form-field class="w-100">
		<input matInput formControlName="phone" placeholder="Telefonnummer">
	</mat-form-field>
	<mat-form-field class="w-100">
		<input matInput formControlName="email" placeholder="Epost">
	</mat-form-field>
	<mat-form-field class="w-100">
		<input matInput formControlName="homepage" placeholder="Hemsida">
	</mat-form-field>
	<mat-form-field class="w-100">
		<textarea matInput formControlName="description" rows="6" placeholder="Beskrivning"></textarea>
	</mat-form-field>
</form>
