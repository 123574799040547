import { Component, OnInit } from '@angular/core';

import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { MinFunctionService } from '../../services/min-function.service';

@Component({
	selector: 'app-min-function-blade-products',
	templateUrl: './function-blade-products.component.html',
	styleUrls: ['./function-blade-products.component.scss'],
})
export class MinFunctionBladeProductsComponent implements OnInit {
	constructor(
		public productFunctionService: MinFunctionService,
		public productStore: ProductStore,
	) {

	}

	ngOnInit(): void {
	}
}
