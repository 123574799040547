import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { EmailStore } from 'app/admin/stores/email.store.service';
import { Email } from 'app/models/email/email.model';

@Component({
	selector: 'app-email-blade',
	templateUrl: './email-blade.component.html',
	styleUrls: ['./email-blade.component.scss'],
})
@IBladeComponent.register
export class EmailBladeComponent implements OnInit {
	public email: Email;
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private bladePositionService: BladePositionService,
		private emailStore: EmailStore,
	) {
		this.emailStore.get(bladeItem.id).subscribe(email => {
			email.data = JSON.parse(email.data);
			this.email = email;
		});
	}

	ngOnInit() {}

}
