<app-blade-item [canIClose]="true" [loading]="loading">
	<app-blade-header [title]="'Ordrar'"></app-blade-header>
	<app-blade-content>
		<mat-expansion-panel class="mb-3">
			<mat-expansion-panel-header>
				<mat-panel-title>
					Filtrera och sök
				</mat-panel-title>
				<mat-panel-description>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<form [formGroup]="formGroup">
				<div class="row">
					<div class="col-md-6">
						<mat-form-field>
							<mat-label>Vilka ordrar vill du visa</mat-label>
							<mat-select value="all">
								<mat-option value="all" (click)="getOrders()">Alla</mat-option>
								<mat-option value="draft" (click)="getDrafts()">Utkast</mat-option>
								<mat-option value="rest" (click)="getRest()">Med rest belopp</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-md-6 text-right">
						<mat-form-field>
							<input matInput formControlName="search" placeholder="Sök på ID och referens">
						</mat-form-field>
						<button mat-stroked-button class="ml-2" color="accent" [disabled]="!formGroup.value?.search" (click)="searchByIdOrReference()">Sök</button>
					</div>
				</div>
			</form>
		</mat-expansion-panel>
		<div class="mat-elevation-z1">
			<table mat-table [dataSource]="dataSource" matSort class="w-100">
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
					<td mat-cell *matCellDef="let item">{{item.id || item.orderId}} </td>
				</ng-container>
				<ng-container matColumnDef="customerId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Kund</th>
					<td mat-cell *matCellDef="let item">{{item.customerId | customerName | async}}</td>
				</ng-container>
				<ng-container matColumnDef="sourceTypeId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Källa</th>
					<td mat-cell *matCellDef="let item">{{item.sourceTypeId | sourceTypeName | async}}</td>
				</ng-container>
				<ng-container matColumnDef="payments">
					<th mat-header-cell *matHeaderCellDef>Betalningar</th>
					<td mat-cell *matCellDef="let item">
						<span *ngIf="item | paymentBalance | async as payment" class="{{payment.className}}">{{payment.balanceStr}}</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="orderStatusId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
					<td mat-cell *matCellDef="let item">{{item.orderStatusId | orderStatusName |async}}</td>
				</ng-container>
				<ng-container matColumnDef="created">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
					<td mat-cell *matCellDef="let item">{{item.created | date:'yyyy-MM-dd HH:mm'}}</td>
				</ng-container>
				<ng-container matColumnDef="isDraft">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Utkast</th>
					<td mat-cell *matCellDef="let item"><span *ngIf="item?.isDraft" class="text-warning">Utkast</span></td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer"
					(click)="selectItem(item)"
					[class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
			</table>
			<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</div>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="createNew()">Skapa ny</button>
	</app-blade-footer>
</app-blade-item>
