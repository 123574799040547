import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaymentTypeStore } from '../stores/payment-type.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'paymentTypeName' })
export class PaymentTypeNamePipe implements PipeTransform, OnDestroy {
	constructor(private paymentTypeStore: PaymentTypeStore) { }

	public transform(paymentTypeId: number): Observable<string> {
		if (!paymentTypeId) {
			return;
		}
		return this.paymentTypeStore.pipeGet(paymentTypeId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
