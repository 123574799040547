import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { MediaStore } from '../stores/media.store.service';

/**
 * Print page type name
 */
@Pipe({ name: 'mediaPath' })
export class MediaPathPipe implements PipeTransform, OnDestroy {
	constructor(private mediaStore: MediaStore) { }

	public transform(mediaId: number): Observable<string> {
		if (!mediaId) {
			return;
		}
		return this.mediaStore.pipeGetName(mediaId).pipe(map(type => this.mediaStore.imgPath + type.name));
	}

	ngOnDestroy(): void { }
}
