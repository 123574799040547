import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { Order } from 'app/models/order.model';
import { OrderBladeComponent } from 'app/admin/blades/order/order-blade/order-blade.component';

@Component({
	selector: 'app-customer-orders',
	templateUrl: './customer-orders.component.html',
	styleUrls: ['./customer-orders.component.scss'],
})
export class CustomerOrdersComponent implements OnInit {
	public displayedColumns: string[] = ['id', 'name', 'created', 'modified', 'isActive'];
	public dataSource: MatTableDataSource<Order>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public orderStore: OrderStore,
		private bladeService: BladeService,
	) {
		this.orderStore.getAllOnCustomer(bladeItem.id).subscribe(orders => {
			this.dataSource = new MatTableDataSource<Order>(orders);
			this.dataSource.sort = this.sort;
			this.dataSource.paginator = this.paginator;
		});
	}

	ngOnInit() {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: OrderBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public createNew() {
		// this.bladeService.addBladeItem({
		// 	id: 0,
		// 	component: TourBladeComponent,
		// 	minClass: BladeSize.xs
		// }, this.bladeItem);
	}
}
