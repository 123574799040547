<app-blade-item [canIClose]="true">
	<app-blade-header [title]="item?.name||'Ny'" [subTitle]="'Turguide-typ'"></app-blade-header>
	<app-blade-content *ngIf="item">
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="name" placeholder="Namn"(keyup)="createIdentifier()">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="identifier" placeholder="Identifier">
			</mat-form-field>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
