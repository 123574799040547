import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SpecialDietReportService } from '../../services/special-diet-report.service';
import { switchMap } from 'rxjs/operators';
import { TourBookingSpecialDiet } from 'app/models/tour-booking-special-diet.model';
import * as _ from 'lodash';

@Component({
	selector: 'app-special-diet-report-on-tour-table',
	templateUrl: './special-diet-report-on-tour-table.component.html',
	styleUrls: ['./special-diet-report-on-tour-table.component.scss'],
})
export class SpecialDietReportOnTourTableComponent implements OnInit {
	public showMessages = true;
	public displayedColumns: string[];
	public specialDietOnTour$: Observable<SpecialDietOnTour>;
	constructor(private specialDietReportService: SpecialDietReportService) {
		this.specialDietOnTour$ = this.specialDietReportService.specialDietSummary$.pipe(switchMap(specialDietOnTours => {
			this.displayedColumns = ['tourName'];
			const specialDietOnTour: SpecialDietOnTour = {tours: [], specialDiets: []};
			specialDietOnTours.forEach(special => {
				const specialDiets: any = this.getAllSpecialDietDetails(special.tourBookingSpecialDiets);
				specialDietOnTour.tours.push({
					id: special.tour.id,
					name: special.tour.name,
					date: special.tour.date,
					time:  special.tour.time,
					specialDiets,
					messages: _.map(special.tourBookingSpecialDietMessage, x => x.message),
				});
			});

			this.getCalcSpecialDiets(specialDietOnTour);

			specialDietOnTour.specialDiets.forEach(specialDiet => {
				this.displayedColumns.push('specialDiet-' + specialDiet.id);
			});

			this.displayedColumns.push('messages');

			return of(specialDietOnTour);
		}));
	}

	ngOnInit() {
	}

	private getAllSpecialDietDetails(tourBookingSpecialDiets: TourBookingSpecialDiet[]): any {
		const specialDiets = {};

		tourBookingSpecialDiets.forEach(diet => {
			if (!specialDiets[diet.specialDietId]) {
				specialDiets[diet.specialDietId] = {
					specialDietId: diet.specialDietId,
					amount: 0,
					messages: [],
				};
			}
			specialDiets[diet.specialDietId].amount += 1;
			if (diet.extraInfo) {
				specialDiets[diet.specialDietId].messages.push(diet.extraInfo);
			}
		});
		return specialDiets;
	}

	private getCalcSpecialDiets(specialDietOnTour: SpecialDietOnTour): any {
		const specialDietSum = {};
		specialDietOnTour.tours.forEach(tour => {
			_.forOwn(tour.specialDiets, (value, key) => {
				if (!specialDietSum[value.specialDietId]){
					specialDietSum[value.specialDietId] = {
						id: value.specialDietId,
						total: 0,
					};
				}
				specialDietSum[value.specialDietId].total += value.amount;
			});
		});

		specialDietOnTour.specialDiets = [];
		_.forOwn(specialDietSum, (value, key) => {
			specialDietOnTour.specialDiets.push(value);
		});
	}
}


interface SpecialDietOnTour {
	tours?: {
		id: number,
		name: string,
		date: Date,
		time: string,
		specialDiets: {
			[id: number]: {
				specialDietId: number,
				amount: number,
				messages: string[],
			},
		},
		messages: string[],
	}[];
	specialDiets: {
		id: number,
		total: number,
	}[];
}
