
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { SpecialDietText } from 'app/models/special-diet-text.model';
import { SnackBarService } from '../services/snack-bar.service';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class SpecialDietTextStore extends BaseStore<SpecialDietText> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SpecialDietText', baseUrl, snackBarService);
	}

	public getAllOnSpecialDiet(specialDietId: number): Observable<SpecialDietText[]> {
		return this.http.get<SpecialDietText[]>(`${this.host}/specialDiet/${specialDietId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getOnSpecialDietAndLanguage(specialDietId: number, languageId: number): Observable<SpecialDietText> {
		return this.http.get<SpecialDietText>(`${this.host}/specialDiet/${specialDietId}/language/${languageId}`).pipe(tap(specialDietText => {
			if (specialDietText) {
				const findObj = _.find(this._items, type => type.specialDietId === specialDietText.specialDietId
									&& type.languageId === specialDietText.languageId);
				_.assign(findObj, specialDietText); // Keep object in sync
			}
		}));
	}

	public addSpecialDietText(specialDietText: SpecialDietText) {
		this._items.push(specialDietText);
		this._itemsSubject.next(this._items);
	}

	public removeSpecialDietText(specialDietText: SpecialDietText) {
		_.remove(this._items, specialDietText);
		this._itemsSubject.next(this._items);
	}
}
