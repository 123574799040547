import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import {
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProductDateTime } from 'app/models/product/product-date-time.model';
import { ProductDateTimeStore } from 'app/admin/stores/product/product-date-time.store.service';

@UntilDestroy()
@Component({
	selector: 'app-product-date-time-blade',
	templateUrl: './product-date-time-blade.component.html',
	styleUrls: ['./product-date-time-blade.component.scss'],
})
@IBladeComponent.register
export class ProductDateTimeBladeComponent implements OnInit, OnDestroy {
	public loading = true;
	public productDateTime: ProductDateTime;
	public itemForm: FormGroup = new FormGroup({
		time: new FormControl('', [Validators.required]),
		quantity: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private productDateTimeStore: ProductDateTimeStore,
	) {
		if (bladeItem.id) {
			productDateTimeStore.get(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(item => {
					this.productDateTime = item;
					this.itemForm.setValue({
						time: this.productDateTime.time,
						quantity: this.productDateTime.quantity,
					});

					this.loading = false;
				});
		} else if (bladeItem.payload && bladeItem.payload.productDateId) {
			this.productDateTime = new ProductDateTime();
			this.productDateTime.productDateId = bladeItem.payload.productDateId;
			this.loading = false;
		}
	}

	ngOnInit() {
		this.itemForm.get('time').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.productDateTime.time = value;
		});
		this.itemForm.get('quantity').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.productDateTime.quantity = value;
		});

		// this.productDateTimeStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
		// 	this.productDateTimes = new MatTableDataSource<ProductDateTime>(items);
		// });
	}

	ngOnDestroy(): void { }

	public save() {
		this.loading = true;
		this.saveOrUpdate().subscribe(item => {
			this.productDateTime = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}

	private saveOrUpdate(): Observable<ProductDateTime> {
		if (this.productDateTime.id > 0) {
			return this.productDateTimeStore.update(this.productDateTime);
		} else {
			return this.productDateTimeStore.create(this.productDateTime);
		}
	}

}
