import { Component, Inject, Input, OnInit } from '@angular/core';
import { CustomerBladeComponent } from 'app/admin/blades/customer/customer-blade/customer-blade.component';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';

@Component({
  selector: 'app-open-customer-blade',
  templateUrl: './open-customer-blade.component.html',
  styleUrls: ['./open-customer-blade.component.scss'],
})
export class OpenCustomerBladeComponent implements OnInit {

	@Input() customerId: number;
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
	) { }

	ngOnInit() {}

	public openBlade(): void {
		window.open('/customer?customerId=' + this.customerId, '_blank');
		// this.bladeService.addBladeItem({
		// 	id: this.customerId,
		// 	component: CustomerBladeComponent,
		// 	minClass: BladeSize.m,
		// 	maxClass: BladeSize.l,
		// }, this.bladeItem);
	}
}
