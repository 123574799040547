import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Tour } from 'app/models/tour.model';
import { SpecialDietStore } from '../stores/special-diet.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'allSpecialDietIconOnTour' })
export class SpecialDietIconOnTourPipe implements PipeTransform, OnDestroy {
	constructor(private specialDietStore: SpecialDietStore) {	}
	public transform(tour: Tour): Observable<string[]> {
		if (!tour) {
			return;
		}

		// tslint:disable-next-line: no-string-literal
		if (tour['specialDietIcons']) {
			// tslint:disable-next-line: no-string-literal
			return of(tour['specialDietIcons']);
		}

		return this.specialDietStore.getAllSpecialDietIconOnTour$(tour.id).pipe(map(specialDietIcons => {
			// tslint:disable-next-line: no-string-literal
			tour['specialDietIcons'] = specialDietIcons;
			// tslint:disable-next-line: no-string-literal
			return tour['specialDietIcons'];
		}));
	}

	ngOnDestroy(): void { }
}
