<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem.id">
	<app-blade-header [title]="'Betalning'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
		<mat-card>
			<div *ngIf="paymentElement && selectedIndex !== 99">
				<app-alert-card [mode]="'info'" [label]="'Detta element visar en betalning där en skapad order ligger som grund.'"></app-alert-card>
			</div>
			<app-element-blade-styles *ngIf="paymentElement && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="paymentElement?.id">Spara</span>
			<span *ngIf="!paymentElement?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
