<mat-card>
	<mat-card-title>Style</mat-card-title>
	<mat-card-subtitle>
		Här kan du lägga till klassnamn som finns på siten. Det är standard klasserna från Bootstrap som finns tillgängliga.<br>
		<a href="https://getbootstrap.com/docs/4.6/utilities" target="_blank">https://getbootstrap.com/docs/4.6/utilities</a><br><br>
		Du kan även välja att skriva egen CSS. Denna kommer appliceras direkt på elementet.
	</mat-card-subtitle>

	<mat-card>
		<div class="d-flex">
			<mat-form-field class="flex-grow-1 pr-4">
				<input matInput [(ngModel)]="className" placeholder="Klassnamn" />
			</mat-form-field>
			<div>
				<button
					mat-raised-button
					color="primary"
					[disabled]="!className"
					(click)="addElementClass()"
				>
					Lägg till
				</button>
			</div>
		</div>

		<div class="mat-elevation-z1">
			<table mat-table [dataSource]="dataSource" matSort class="w-100">
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
					<td mat-cell *matCellDef="let item">{{ item.id || 'Ny' }}</td>
				</ng-container>
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
					<td mat-cell *matCellDef="let item">
						<strong>{{ item.class }}</strong
						><br /><em>{{ item.identifier }}</em>
					</td>
				</ng-container>
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let item" class="text-right">
						<button
							mat-icon-button
							color="warn"
							(click)="$event.stopPropagation(); deleteElementClass(item)"
						>
							<mat-icon>delete_forever</mat-icon>
						</button>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let item; columns: displayedColumns"
					class="pointer"
				></tr>
			</table>

			<mat-paginator
				[pageSizeOptions]="[10, 25, 50]"
				showFirstLastButtons
			></mat-paginator>
		</div>
	</mat-card>
	<mat-card class="mt-3">
		<mat-card-subtitle>Css</mat-card-subtitle>

		<div style="height: 500px" class="mt-2">
			<ngx-monaco-editor style="height: 100%" [options]="editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
		</div>

		<button
			mat-button
			color="primary"
			(click)="saveStyle()">
			<mat-icon>save</mat-icon>
		</button>
	</mat-card>
</mat-card>
