import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserType } from 'app/models/user-type.model';
import { UserTypeStore } from 'app/admin/stores/user-type.store.service';
import { UserTypeBladeComponent } from 'app/admin/blades/user-type/user-type-blade/user-type-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-settings-user-type',
	templateUrl: './settings-user-type.component.html',
	styleUrls: ['./settings-user-type.component.scss'],
})
export class SettingsUserTypeComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['id', 'name', 'created', 'modified'];
	public dataSource: MatTableDataSource<UserType>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: UserTypeStore,
	) {
		this.itemStore.getAll();
		this.itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<UserType>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: UserTypeBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: UserTypeBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}
}
