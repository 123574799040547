import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { EntityName } from 'app/models/entity-name.model';
import { User } from 'app/admin/models/user.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class UserStore extends BaseStore<User> {
	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'User', baseUrl, snackBarService);
	}

	public getAllOnTravelAgency(travelAgencyId: number): Observable<User[]> {
		return this.http.get<User[]>(`${this.host}/travelAgency/${travelAgencyId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getWithServices(id: number): Observable<User> {
		return this.http.get<User>(`${this.host}/${id}/services`);
	}

	public updatePassword(userId: number, oldPassword: string, newPassword: string): Observable<User> {
		return this.http.post<User>(`${this.host}/${userId}/password`, {
			userId,
			oldPassword,
			newPassword,
		});
	}
}
