<app-blade-item [canIClose]="true" [loading]="loading" [id]="item?.id">
	<app-blade-header [title]="(item?.pickupLocationId | pickupLocationName | async) + ' ' + (item?.customerTypeId | customerTypeName | async)" [subTitle]="'Turbokning rad'" [menuItems]="headerMenuItems"></app-blade-header>
	<!-- Edit column content -->
	<app-blade-content *ngIf="item?.id" [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
		<ng-container [ngSwitch]="selectedIndex">
			<ng-container *ngSwitchCase="1">
				<app-tour-booking-pickup-location-blade-generally [item]="item"></app-tour-booking-pickup-location-blade-generally>
			</ng-container>
			<ng-container *ngSwitchCase="2">
				<app-tour-booking-pickup-location-blade-order-items [item]="item"></app-tour-booking-pickup-location-blade-order-items>
			</ng-container>
		</ng-container>
	</app-blade-content>
	<!-- New column content -->
	<app-blade-content *ngIf="!item?.id">
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="name" placeholder="Arbetsnamn">
			</mat-form-field>
		</form>
	</app-blade-content>
	<ng-container [ngSwitch]="selectedIndex">
		<app-blade-footer *ngSwitchCase="1">
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="save()">
				<span *ngIf="item?.id">Spara</span>
				<span *ngIf="!item?.id">Skapa</span>
			</button>
		</app-blade-footer>
	</ng-container>
</app-blade-item>
