import { Injectable } from '@angular/core';
import { ResourceSummaryItem } from 'app/admin/models/resource-summary-item';
import { ReplaySubject } from 'rxjs';
import { ResourceSummaryGroup } from './models/resource-summary-group';

@Injectable()
export class TourResourceService {

	private resourceSummaryGroups: ResourceSummaryGroup[] = [];
	private resourceSummaryGroupReplay = new ReplaySubject<ResourceSummaryGroup[]>();
	public resourceSummaryGroupReplay$ = this.resourceSummaryGroupReplay.asObservable();

	constructor() { }

	public addResourceSummary(id: string, items: ResourceSummaryItem[]): void {
		const resourceSummaryGroup = this.resourceSummaryGroups.find(g => g.id === id);
		if (resourceSummaryGroup) {
			resourceSummaryGroup.items = items;
		} else {
			this.resourceSummaryGroups.push({id, items});
		}
		this.resourceSummaryGroupReplay.next(this.resourceSummaryGroups);
	}
}
