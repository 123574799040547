import { Component, Input } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TourResourceService } from 'app/admin/components/shared/tour-resource/tour-resource.service';
import { combineLatest, ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import { Tour } from 'app/models/tour.model';
import { User } from 'app/admin/models/user.model';
import { TourStore } from 'app/admin/stores/tour.store.service';

@UntilDestroy()
@Component({
	selector: 'app-planner-guides-tours-guide-total-time-item',
	templateUrl: './planner-guides-tours-guide-total-time-item.component.html',
	styleUrls: ['./planner-guides-tours-guide-total-time-item.component.scss'],
	providers: [
		TourResourceService,
	],
})
export class PlannerGuidesToursGuideTotalTimeItemComponent {
	private user$ = new ReplaySubject<User>();
	@Input() set user(user: User) {
		this.user$.next(user);
	}

	public totalTimeReplay$ = new ReplaySubject<number>();

	constructor(
		public tourStore: TourStore,
	) {
		combineLatest([
			tourStore.items$,
			this.user$,
		]).pipe(untilDestroyed(this))
		.subscribe(([tours, user]) => {
			this.calculateTime(user, tours);
		});
	}

	private calculateTime(user: User, tours: Tour[]) {
		const toursOnUser = _.filter(tours, tour => tour.tourGuides?.filter(tg => tg.userId === user.id)?.length > 0);
		this.totalTimeReplay$.next(_.sum(toursOnUser.map(tour => tour.timeLength)) || 0);
	}
}
