import { BaseModel } from '../base.model';
import { ProductPickupLocationPrice } from './product-pickup-location-price.model';

export class ProductPickupLocation extends BaseModel {
	public productId: number;
	public pickupLocationId: number;
	public relativeTime?: number;
	public prio: number;
	public productPickupLocationPrices?: ProductPickupLocationPrice[];
}
