import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { combineLatest, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTableDataSource } from '@angular/material/table';
import { ProductPickupLocation } from 'app/models/product/product-pickup-location.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProductPickupLocationStore } from 'app/admin/stores/product/product-pickup-location.store.service';
import { ProductPickupLocationBladeComponent } from 'app/admin/blades/poduct-pickup-location/product-pickup-location-blade/product-pickup-location-blade.component';
import { PickupLocationStore } from 'app/admin/stores/pickup-location.store.service';
import { PickupLocation } from 'app/models/page-location.model';
import { ProductPickupLocationPriceStore } from 'app/admin/stores/product/product-pickup-location-price.store.service';
import { PickupLocationPriceStore } from 'app/admin/stores/pickup-location-price.store.service';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';

@UntilDestroy()
@Component({
	selector: 'app-product-blade-pickup-locations',
	templateUrl: './product-blade-pickup-locations.component.html',
	styleUrls: ['./product-blade-pickup-locations.component.scss'],
})
export class ProductBladePickupLocationComponent implements OnInit, OnDestroy {
	private pristinePrioList: number[];
	public prioIsChangedReplay$ = new ReplaySubject<boolean>();
	public savingPrioReplay$ = new ReplaySubject<boolean>();

	public displayedColumns: string[] = ['id', 'pickupLocation', 'relativeTime', 'pickupLocationPrice', 'action'];
	public dataSource: MatTableDataSource<ExtendProductPickupLocation>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	private allProductPickupLocations: ExtendProductPickupLocation[] = [];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private productPickupLocationStore: ProductPickupLocationStore,
		private productPickupLocationPriceStore: ProductPickupLocationPriceStore,
		public pickupLocationStore: PickupLocationStore,
		public pickupLocationPriceStore: PickupLocationPriceStore,
		public customerTypeStore: CustomerTypeStore,

	) {
		this.productPickupLocationStore.reset();
		this.pickupLocationPriceStore.reset();
		this.productPickupLocationPriceStore.reset();

		this.productPickupLocationStore.getAllOnProduct(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe();
		this.productPickupLocationPriceStore.getAll();
		this.pickupLocationPriceStore.getAll();

		combineLatest([
			this.pickupLocationStore.getAll$(),
			this.productPickupLocationStore.items$,
			this.productPickupLocationPriceStore.items$,
			this.pickupLocationPriceStore.items$,
		])
		.pipe(untilDestroyed(this))
		.subscribe(([allPickupLocations, productPickupLocations, productPickupLocationPrices, pickupLocationPrices]) => {
			this.allProductPickupLocations = [];
			allPickupLocations.forEach((pickupLocation, index) => {
				const extendProductPickupLocation: ExtendProductPickupLocation = {
					pickupLocation,
				};
				const findPickupLocationPrices = pickupLocationPrices.filter(x => x.pickupLocationId === pickupLocation.id);
				if (findPickupLocationPrices?.length) {
					extendProductPickupLocation.pickupLocation.pickupLocationPrices = findPickupLocationPrices;
				}
				if (productPickupLocations) {
					const findProductPickupLocation = productPickupLocations.find(x => x.pickupLocationId === pickupLocation.id);
					if (findProductPickupLocation) {
						const findProductPickupLocationPrices = productPickupLocationPrices.filter(x => x.productPickupLocationId === findProductPickupLocation.id);
						if (findProductPickupLocationPrices?.length) {
							findProductPickupLocation.productPickupLocationPrices = findProductPickupLocationPrices;
						}

						extendProductPickupLocation.prio = findProductPickupLocation.prio;
						extendProductPickupLocation.productPickupLocation = findProductPickupLocation;
					}
				} else {
					extendProductPickupLocation.prio = (productPickupLocations?.length ?? 0) + index;
				}
				this.allProductPickupLocations.push(extendProductPickupLocation);
			});

			this.allProductPickupLocations = _.sortBy(this.allProductPickupLocations, i => i.productPickupLocation?.prio);
			this.pristinePrioList = this.allProductPickupLocations.map(p => p.productPickupLocation?.id);

			this.dataSource = new MatTableDataSource<ExtendProductPickupLocation>(this.allProductPickupLocations);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}
	ngOnInit() {}
	ngOnDestroy(): void { }

	public toggleProductPickupLocation(item: ExtendProductPickupLocation) {
		if (item.productPickupLocation?.id) {
			this.productPickupLocationStore.delete(item.productPickupLocation.id).subscribe();
		} else {
			const newProductPickupLocation = new ProductPickupLocation();
			newProductPickupLocation.productId = this.bladeItem.id;
			newProductPickupLocation.pickupLocationId = item.pickupLocation.id;
			this.productPickupLocationStore.create(newProductPickupLocation).subscribe();
		}
	}

	public selectItem(row: ProductPickupLocation) {
		if (!row) {
			return;
		}
		this.bladeService.addBladeItem({
			id: row.id,
			component: ProductPickupLocationBladeComponent,
			minClass: BladeSize.s,
			payload: {
				productId: this.bladeItem.id,
			},
		}, this.bladeItem);
	}

	public onListDrop(event: CdkDragDrop<MatTableDataSource<ExtendProductPickupLocation>, any>) {
		const previousIndex = this.allProductPickupLocations.findIndex(row => row === event.item.data);
		moveItemInArray(this.allProductPickupLocations, previousIndex, event.currentIndex);
		this.allProductPickupLocations.forEach((element, index) => {
			element.prio = index;
			if (element.productPickupLocation) {
				element.productPickupLocation.prio = index;
			}
		});
		this.dataSource = new MatTableDataSource<ExtendProductPickupLocation>(this.allProductPickupLocations);
		this.dataSource.paginator = this.paginator;

		this.isPrioChanged();
	}

	public savePrio() {
		this.savingPrioReplay$.next(true);
		this.productPickupLocationStore.updatePrio(this.allProductPickupLocations.map(x => x.productPickupLocation)).pipe(untilDestroyed(this)).subscribe(done => {
			this.pristinePrioList = this.allProductPickupLocations.map(p => p.productPickupLocation?.id);
			this.savingPrioReplay$.next(false);
			this.isPrioChanged();
		});
	}

	private isPrioChanged() {
		if (this.allProductPickupLocations.map(p => p.productPickupLocation?.id).toString() !== this.pristinePrioList.toString()) {
			this.prioIsChangedReplay$.next(true);
		} else {
			this.prioIsChangedReplay$.next(false);
		}
	}
}

interface ExtendProductPickupLocation {
	prio?: number;
	pickupLocation: PickupLocation;
	productPickupLocation?: ProductPickupLocation;

}
