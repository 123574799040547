import { Component, Input } from '@angular/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { NgModel, FormControl, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-site-wysiwyg',
	templateUrl: './site-wysiwyg.component.html',
	styleUrls: ['./site-wysiwyg.component.scss'],
})
export class SiteWYSIWYGComponent {
	@Input() label: string;

	/**
	 * Can be 'success' | 'danger' | 'warning' | 'info'
	 */
	@Input() mode: string;

	constructor() {}
}
