import { Component, Inject } from '@angular/core';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { PageStore } from 'app/admin/stores/page.store.service';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { PageTypeStore } from 'app/admin/stores/page-type.store.service';
import { MinFunctionService } from './services/min-function.service';

@Component({
	selector: 'app-min-function-blade',
	templateUrl: './min-function-blade.component.html',
	styleUrls: ['./min-function-blade.component.scss'],
	providers: [MinFunctionService],
})
@IBladeComponent.register
export class MinFunctionBladeComponent {

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public pageTypeStore: PageTypeStore,
		public productFunctionService: MinFunctionService,
	) { }
}
