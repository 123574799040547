import { Component, Inject, ViewChild } from '@angular/core';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTableDataSource } from '@angular/material/table';
import { Tour } from 'app/models/tour.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TourStore } from 'app/admin/stores/tour.store.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TourBladeComponent } from 'app/admin/blades/tour/tour-blade/tour-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-supplier-blade-tours',
	templateUrl: './supplier-blade-tours.component.html',
	styleUrls: ['./supplier-blade-tours.component.scss'],
})
export class SupplierBladeToursComponent {
	public moment = moment;
	public _ = _;
	public displayedColumns: string[] = ['productId', 'guide', 'date', 'status', 'isActive', 'isPrivate', 'link'];
	public dataSource: MatTableDataSource<Tour>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private tourStore: TourStore,
	) {
		this.tourStore.getAllOnSupplier(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(tours => {
			this.dataSource = new MatTableDataSource<Tour>(_.orderBy(tours, 'date', 'desc'));
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	public selectItem(row) {
		let query = `/tour?tourId=${row.id}`;
		window.open(query, '_blank');
	}
}
