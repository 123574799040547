import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { NewCustomerTemplate } from 'app/models/email/new-customer-template.model';
import { NewCustomerTemplateStore } from 'app/admin/stores/new-customer-template.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-new-customer-template-blade',
	templateUrl: './new-customer-template-blade.component.html',
	styleUrls: ['./new-customer-template-blade.component.scss'],
})
@IBladeComponent.register
export class NewCustomerTemplateBladeComponent implements OnInit {
	public item: NewCustomerTemplate;

	public itemForm: FormGroup = new FormGroup({
		languageId: new FormControl('', [Validators.required]),
		previewText: new FormControl('', [Validators.required]),
		subject: new FormControl('', [Validators.required]),
		headline: new FormControl('', [Validators.required]),
		title: new FormControl('', [Validators.required]),
		message: new FormControl('', [Validators.required]),
		buttonText: new FormControl(''),
		buttonUrl: new FormControl(''),
		tags: new FormControl(''),
		sendinBlueTemplateId: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: NewCustomerTemplateStore,
		public languageStore: LanguageStore,
	) {
		itemStore.get(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(item => {
			this.item = item;
			this.itemForm.patchValue(this.item);
		});
	}

	ngOnInit() {}

	public save() {
		this.item = _.assign(this.item, this.itemForm.value);
		if (this.item.id) {
			this.itemStore.update(this.item).subscribe(updatedItem => {
				this.item = _.assign(this.item, updatedItem);
			});
		} else {
			this.itemStore.create(this.item).subscribe(createdItem => {
				this.item = _.assign(this.item, createdItem);
			});
		}
	}
}
