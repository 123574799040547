import { BaseModel } from './base.model';
import { SupplierResourceProduct } from './supplier-resource-product.model';
import { SupplierResourceTour } from './supplier-resource-tour.model';
import { TourBooking } from './tour-booking.model';
import { TourGuide } from './tour-guide.model';
import { TourMessage } from './tour-message.model';

export class Tour extends BaseModel {
	public name: string;
	public date: Date;
	public time: string;
	public quantity: number;
	public availablePlaces: number;
	public supplierId?: number;
	public productId: number;
	public timeLength: number;
	public isActive: boolean;
	public isPrivate: boolean;
	public tourStatusId: number;
	public dateMatchOnlineData: boolean;
	public tourGuides: TourGuide[];
	public tourBookings: TourBooking[];
	public tourMessages: TourMessage[];
	public supplierResourceProducts: SupplierResourceProduct[];
	public supplierResourceTours: SupplierResourceTour[];
}
