import { Component, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { Settings } from 'app/models/site/settings.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-site-settings-blade-generally',
	templateUrl: './site-settings-blade-generally.component.html',
	styleUrls: ['./site-settings-blade-generally.component.scss'],
})
export class SiteSettingsBladeGenerallyComponent {

	@Input() set item(item: Settings) {
		if (item) {
			this.itemForm.setValue({
				logo: item.logo,
				googleAnalyticsTrackingId: item.googleAnalyticsTrackingId,
				defaultLanguage: item.defaultLanguage,
				fbPageId: item.fbPageId,
				dibsCheckoutPath: item.dibsCheckoutPath,
			});
			this.itemForm.get('logo').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
				item.logo = value;
			});
			this.itemForm.get('googleAnalyticsTrackingId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
				item.googleAnalyticsTrackingId = value;
			});
			this.itemForm.get('defaultLanguage').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
				item.defaultLanguage = value;
			});
			this.itemForm.get('fbPageId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
				item.fbPageId = value;
			});
			this.itemForm.get('dibsCheckoutPath').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
				item.dibsCheckoutPath = value;
			});
		}
	}

	public itemForm: FormGroup = new FormGroup({
		logo: new FormControl('', [Validators.required]),
		googleAnalyticsTrackingId: new FormControl('', [Validators.required]),
		defaultLanguage: new FormControl('', [Validators.required]),
		fbPageId: new FormControl('', [Validators.required]),
		dibsCheckoutPath: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
	) { }
}
