import { Component, OnInit, Inject, Input } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CategoryText } from 'app/models/category/category-text.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-category-blade-texts-text',
	templateUrl: './category-blade-texts-text.component.html',
	styleUrls: ['./category-blade-texts-text.component.scss'],
})
export class CategoryBladeTextsTextComponent implements OnInit {
	@Input() categoryText: CategoryText;
	public itemForm: FormGroup = new FormGroup({
		headline: new FormControl('', [Validators.required]),
		information: new FormControl('', [Validators.required]),
		description: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
	) {}

	ngOnInit() {
		this.itemForm.get('headline').setValue(this.categoryText.headline);
		this.itemForm.get('headline').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.categoryText.headline = value;
		});
		this.itemForm.get('information').setValue(this.categoryText.information);
		this.itemForm.get('information').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.categoryText.information = value;
		});
		this.itemForm.get('description').setValue(this.categoryText.description);
		this.itemForm.get('description').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.categoryText.description = value;
		});
	}
}
