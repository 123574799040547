<ng-container *ngIf="tourBookingCustomerTypeService.tourBookingCustomerTypesReplay$ | async as tourBookingCustomerTypes">
	<h2 mat-dialog-title>Redigera kunder på tourBooking</h2>
	<mat-dialog-content>
		<p *ngIf="!tourBookingCustomerTypes?.length">Det finns inga kund-typer.</p>
		<app-tour-booking-customer-type-item class="d-block mt-3" *ngFor="let item of tourBookingCustomerTypes" [tourBookingCustomerType]="item"></app-tour-booking-customer-type-item>

		<div class="text-right mt-3">
			<button mat-stroked-button color="accent" (click)="tourBookingCustomerTypeService.addNew()">Lägg till ny kund-typ</button>
		</div>
		<div class="text-right mt-3 pt-3 border-top">
			<button mat-raised-button color="primary" [disabled]="tourBookingCustomerTypeService.isSaving$ | async" (click)="tourBookingCustomerTypeService.save()">Spara</button>
		</div>
	</mat-dialog-content>
</ng-container>
