import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { BaseV3Store } from 'app/admin/stores/base-v3.store';
import { Customer } from '../models/customer.model';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SearchCustomer } from '../dialogs/customer-main-search/models/search-customer';

@Injectable()
export class CustomerStore extends BaseV3Store<Customer> {

	constructor(
		protected http: HttpClient,
		@Inject('BASE_URL') baseUrl: string,
		protected snackBarService: SnackBarService,
	) {
		super(http, 'customer', baseUrl, snackBarService);
	}

	public search(pageSize: number, page: number, searchWord: string): Observable<SearchCustomer> {
		return this.http.get<SearchCustomer>(this.host + `/search/page-size/${pageSize}/page/${page}/${searchWord}`);
	}

	public latest(pageSize: number, page: number): Observable<SearchCustomer> {
		return this.http.get<SearchCustomer>(this.host + `/latest/page-size/${pageSize}/page/${page}`);
	}
}
