import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { PickupLocationStore } from 'app/admin/stores/pickup-location.store.service';
import { PickupLocation } from 'app/models/page-location.model';

@Component({
	selector: 'app-pickup-location-blade',
	templateUrl: './pickup-location-blade.component.html',
	styleUrls: ['./pickup-location-blade.component.scss'],
})
@IBladeComponent.register
export class PickupLocationBladeComponent implements OnInit {

	public loading = true;
	public item: PickupLocation;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
	});
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: PickupLocationStore,
	) {
		if (bladeItem.id) {
			this.itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.itemForm.setValue({name: this.item.name});

				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new PickupLocation();
		}
	 }

	ngOnInit() {
	}

	public save() {
		this.loading = true;
		this.item.name = this.itemForm.get('name').value;
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<PickupLocation> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}
}
