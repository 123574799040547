import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { SpecialDietStore } from 'app/admin/stores/special-diet.store.service';
import { SpecialDiet } from 'app/models/special-diet.model';
import * as _ from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable()
export class SpecialDietBladeService {
	public selectedIndex = 1;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		icon: new FormControl('', [Validators.required]),
		isActive: new FormControl(false),
		requireExtraInfo: new FormControl(false),
	});

	private specialDiet: SpecialDiet;
	private specialDietReplay = new ReplaySubject<SpecialDiet>();
	public specialDietReplay$ = this.specialDietReplay.asObservable();

	private isLoadingBehavior = new BehaviorSubject<boolean>(true);
	public isLoadingBehavior$ = this.isLoadingBehavior.asObservable();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: SpecialDietStore,
		private bladePositionService: BladePositionService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.specialDiet = item;
				this.specialDietReplay.next(this.specialDiet);
				this.itemForm.patchValue(this.specialDiet);
				this.isLoadingBehavior.next(false);
			});
		} else {
			this.specialDiet = new SpecialDiet();
			this.specialDietReplay.next(this.specialDiet);
			this.isLoadingBehavior.next(false);
		}
	}

	public save() {
		this.isLoadingBehavior.next(true);
		this.specialDiet = Object.assign(this.specialDiet, this.itemForm.value);

		if (this.specialDiet.id) {
				this.itemStore.update(this.specialDiet).subscribe(category => {
					this.isLoadingBehavior.next(false);
					this.specialDiet = _.assign(this.specialDiet, category);
				});
		} else {
			this.itemStore.create(this.specialDiet).subscribe(category => {
				this.isLoadingBehavior.next(false);
				this.specialDiet = _.assign(this.specialDiet, category);
				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	public deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.isLoadingBehavior.next(true);
		this.itemStore.delete(this.specialDiet.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
			this.isLoadingBehavior.next(false);
		});
	}

	public setLoading(isLoading: boolean) {
		this.isLoadingBehavior.next(isLoading);
	}
}
