
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { ElementStyle } from 'app/models/element-style.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ElementStyleStore extends BaseStore<ElementStyle> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ElementStyle', baseUrl, snackBarService);
	}

	public getOnElement(elementId: number): Observable<ElementStyle> {
		return this.http.get<ElementStyle>(`${this.host}/element/${elementId}`);
	}
}
