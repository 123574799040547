<div class="container py-3" *ngIf="{
	settings: customerProgramPageService.siteSettings$ | async,
	customer: customerProgramPageService.customer$ | async,
	tourIds: customerProgramPageService.tourIds$ | async,
	language: customerProgramPageService.language$ | async,
	totalAmount: customerProgramPageService.totalAmount$ | async,
	showTotalPrice: customerProgramPageService.showTotalPrice$ | async,
	adminText: 'customerProgramV2' | adminText: ((customerProgramPageService.language$ | async)?.prefix) | async
} as source">
	<mat-card>
		<div class="align-items-center d-flex justify-content-between mb-3 pb-3 border-bottom">
			<img class="logo" [src]="source.settings?.logo">
			<div>
				<p class="title">{{source.adminText?.title}}</p>
			</div>
		</div>

		<mat-card>
			<mat-card-subtitle class="card-title-small">{{source.adminText?.customerTitle}}</mat-card-subtitle>
			<div class="row">
				<div class="info-block col">
					<mat-label>{{source.adminText?.travelAgency}}</mat-label>
					<p>{{(source.customer?.travelAgencyId | travelAgencyName | async) || 'Ingen'}}</p>
				</div>
				<div class="info-block col">
					<mat-label>{{source.adminText?.customerName}}</mat-label>
					<p>{{source.customer?.firstName}} {{source.customer?.lastName}}</p>
				</div>
				<div class="info-block col">
					<mat-label>{{source.adminText?.email}}</mat-label>
					<p>{{source.customer?.email}}</p>
				</div>
				<div class="info-block col">
					<mat-label>{{source.adminText?.phone}}</mat-label>
					<p>{{source.customer?.phone}}</p>
				</div>
				<div class="info-block col">
					<mat-label>{{source.adminText?.language}}</mat-label>
					<p>{{source.customer?.languageId | languageName | async}}</p>
				</div>
				<div class="info-block col" *ngIf="source.showTotalPrice">
					<mat-label>{{source.adminText?.totalSum}}</mat-label>
					<p>{{source.totalAmount | currency:'SEK':'code'}}</p>
				</div>
			</div>
		</mat-card>

		<app-customer-program-tour *ngFor="let tourId of source.tourIds" [tourId]="tourId"></app-customer-program-tour>
	</mat-card>
</div>
