import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import {
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { SiteDataStore } from 'app/admin/stores/site-data.store.service';
import { SiteData } from 'app/models/site-data.model';
import { JsonEditorOptions } from "@maaxgr/ang-jsoneditor";

@Component({
	selector: 'app-site-data-blade',
	templateUrl: './site-data-blade.component.html',
	styleUrls: ['./site-data-blade.component.scss'],
})
@IBladeComponent.register
export class SiteDataBladeComponent {
	private basicTextData = {
		menu: {
			title: 'MENY',
		},
		cart: {
			emptyTitle: 'Det verkar inte finnas något i din varukorg...',
			emptySubTitle: 'Men det går alltid bra att kolla på våra produkter och finna insperation inför kommande upplevelser.',
			item: {
				customerTypeTitle: 'Antal:',
				addonTitle: 'Tillägg:',
				pickupPlaceTitle: 'Hämtningsplats:',
				piece: 'st',
				removeBtn: 'Ta bort',
				editBtn: 'Redigera',
				specialDietTitle: 'Specialkost',
				specialDietMessageTitle: 'Ange eventuellt meddelande angående specialkosten',
			},
			message: {
				dialog: {
					addTitle: 'Lägg till meddelande',
					updateTitle: 'Uppdatera meddelande',
					label: 'Meddelande',
					closeBtn: 'Avbryt',
					saveBtn: 'Spara',
				},
				noMessageTitle: 'Vill du lämna ett meddelande?',
				messageTitle: 'Ditt meddelande',
				addMessageBtn: 'Lägg till meddelande',
				updateMessageBtn: 'Uppdatera meddelande',
			},
			priceSummary: {
				total: 'Totalt:',
				discount: 'Rabatt:',
				sum: 'Summa:',
			},
			payment: {
				title: 'Betalning',
				initPaymentBtn: 'Vidare till betalningen',
				draftOrder: {
					itemListTitle: 'Följande produkter måste hanteras manuellt.',
					// tslint:disable-next-line: max-line-length
					info: '<p class="m-0">Detta kan bero på att det inte finns tillräkligt med platser eller resurser kvar på den aktuella turen.</p><p class="m-0">Detta medför att det kommer skapas en orderförfrågan på proukterna ovan.</p><p>Vi kommer kontakta dig efter att vi undersökt möjligheten om din begäran är möjlig.</p>',
					form: {
						title: 'Order',
						subTitle: 'Ange din information nedan för att kunna skapa en order',
						orderFirstNameLabel: 'Förnamn',
						orderLastNameLabel: 'Efternamn',
						orderEmailLabel: 'Email',
						createDraftOrderBtn: 'Skapa en order',
					},
				},
			},
		},
		orderGroup: {
			date: 'Datum:',
			time: 'Tid:',
			customerTypeTitle: 'Antal:',
			addonTitle: 'Tillägg:',
			pickupPlaceTitle: 'Hämtningsplats:',
			piece: 'st',
			total: 'Totalt:',
		},
		confirmation: {
			doneSoon: 'Snart är det klart!',
			productTitle: 'Produkter',
			addonTitle: 'Tillägg',
			pickupPlaceTitle: 'Upphämtning',
			pickupPlaceInfo: 'Hämtningen sker på: ',
			piece: 'st',
			total: 'Totalt: ',
			tax: 'Varav skatt ',
			sum: 'Summa: ',
			customer: {
				title: 'Kund',
				firstName: 'Förnamn: ',
				lastName: 'Efternamn: ',
				email: 'Epost: ',
				phone: 'Telefonnummer: ',
				missingPhone: 'Saknas',
			}
		},
		login: {
			title: 'Logga in',
			subTitle: 'För att komma vidare så måste du logga in',
			epostLabel: 'E-postadress',
			passwordLabel: 'Lösenord',
			errorLogin: 'Fel e-postadress eller lösenord',
			loginBtn: 'Logga in',
		},
		payment: {
			title: 'Det verkar inte finnas något beställning.',
			subtitle: 'Eller så är köpet redan genomfört.',
			sum: 'Summa: ',
			paymentTitle: 'Betalning',
			initPayment: 'Vidare till betalningen',
			orderTitle: 'Orderrader',
			paid: 'Betalat',
			toPay: 'Att betala',
		},
		productBlurb: {
			gotoProductBtn: 'Gå till produkten',
			from: 'Från',
		},
		categoryBlurb: {
			gotoCategoryBtn: 'Gå till kategori',
		},
		productBooking: {
			bookingMobileBtn: 'Boka',
			title: 'Bokning',
			selectDateTitle: '1. Vänligen ange datum då du vill utföra resan:',
			selectTimeTitle: '2. Ange den tid som som passar dig bäst:',
			selectAmountTitle: '3. Ange hur många ni är på resan:',
			selectPickupTitle: '4. Hämtningsplats:',
			selectAddonTitle: '5. Tillägg:',
			selectDate: 'Välj datum',
			changeDate: 'Ändra datum',
			price: 'Pris: ',
			amount: 'Antal',
			selectBirthDate: 'Ange födelsedatum på ',
			selectPickup: 'Välj hämtningsplats',
			changePickup: 'Ändra hämtningsplats',
			totalPrice: 'Total pris för denna produkt: ',
			statusAdding: 'Lägger till ',
			statusUpdating: 'Uppdaterar ',
			statusAdd: 'Lägg till i varukorgen ',
			statusUpdate: 'Uppdatera varan i varukorgen ',
			confirmationModal: {
				isAdded: 'har lagts till i varukorgen!',
				isUpdated: 'har uppdaterats!',
				gotToCart: 'Fortsätt till varukorgen',
				close: 'Fortsätt handla',
			},
		},
		productList: {
			search: 'Sök',
			noResult: 'Det finns inga produkter som matchar din sökning.',
			loading: 'Laddar in produkterna...',
			categoryTitle: 'Kategorier',
		},
		profileEdit: {
			firstName: 'Förnamn',
			lastName: 'Efternamn',
			email: 'E-postadress',
			phone: 'Telefonnummer',
			updateBtn: 'Uppdatera',
		},
		profileMenu: {
			title: 'Meny',
		},
		profileOrder: {
			orderDate: 'Order datum: ',
			orderId: 'Order id: ',
			loading: 'Laddar in dina ordrar...',
		},
		selectPickup: {
			title: 'Hämtningsplats',
			// tslint:disable-next-line: max-line-length
			info: 'Nedan finns en karta på de platser som vi erbjuder hämtning från. Välj en plats från listan. Vill du inte ha någon hämtning så ses vi på Kirunaguidetur ca 10 minuter innan utsatt avresa tid.',
			showOnMap: 'Visa på kartan',
			select: 'Välj',
			selected: 'Vald',
			cancel: 'Avbryt',
		},
	};

	public loading = true;
	public item: SiteData;

	public itemForm: FormGroup = new FormGroup({
		text: new FormControl(this.basicTextData, [Validators.required]),
	});
	public editorOptions: JsonEditorOptions = new JsonEditorOptions();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: SiteDataStore,
	) {
		this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
		if (bladeItem.payload?.languageId) {
			itemStore
				.getOnLanguage(+bladeItem.payload.languageId)
				.subscribe(item => {
					this.item = item;
					if (!this.item) {
						this.item = new SiteData();
						this.item.languageId = +bladeItem.payload.languageId;
					} else {
						this.itemForm.get('text').setValue(_.merge(this.basicTextData, JSON.parse(this.item.text)));
					}

					this.loading = false;
				});
		} else {
			console.error('Missing languageId');
		}
	}

	public save() {
		this.loading = true;
		this.item.text = JSON.stringify(this.itemForm.value.text);
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.itemForm.get('text').setValue(JSON.parse(this.item.text));
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<SiteData> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

}
