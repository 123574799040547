<app-blade-item [canIClose]="!itemForm.dirty" [loading]="loading" [id]="productDate?.id">
	<app-blade-header [title]="productDate?.id ? 'Datum' : 'Nytt datum'" [subTitle]="'Produktdatum'"></app-blade-header>
	<app-blade-content>
		<app-alert-card [mode]="'info'">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam placeat possimus laborum expedita, nihil fuga id, officia nam repellendus laboriosam aut voluptate est fugiat recusandae veniam eaque soluta similique officiis!</app-alert-card>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input readonly matInput [matDatepicker]="fromDatePicker" placeholder="Från datum" formControlName="fromDate"
					[max]="itemForm.get('toDate').value" [matDatepickerFilter]="dateFilter">
				<mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #fromDatePicker disabled="false" [dateClass]="dateClass"></mat-datepicker>
			</mat-form-field>
			<mat-form-field class="w-100">
				<input readonly matInput [matDatepicker]="toDatePicker" placeholder="Till datum" formControlName="toDate" [min]="itemForm.get('fromDate').value" [matDatepickerFilter]="dateFilter">
				<mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #toDatePicker [disabled]="!itemForm.get('fromDate').value" [dateClass]="dateClass"></mat-datepicker>
			</mat-form-field>

			<div>
				<section>
					<mat-slide-toggle
						color="accent"
						formControlName="isAllSelected"
						(click)="toggleAll()">
						Välj alla dagar
					</mat-slide-toggle>
				</section>

				<mat-checkbox class="mr-2 text-capitalize" *ngFor="let weekDay of moment.weekdays(true); let index = index" [checked]="productDate?.weekDays?.includes(index?.toString())" (change)="toggleWeekDay(index)">{{weekDay}}</mat-checkbox>
			</div>
		</form>
		<ng-container *ngIf="productDate?.id">
			<p *ngIf="!productDateTimes?.data?.length">Det saknas tider!</p>

			<mat-table [dataSource]="productDateTimes" class="w-100" *ngIf="productDateTimes?.data?.length">
				<ng-container matColumnDef="id">
					<mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
					<mat-cell *matCellDef="let item">{{item.id || 'Ny'}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="clock">
					<mat-header-cell *matHeaderCellDef>Tid</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{item.time}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="quantity">
					<mat-header-cell *matHeaderCellDef>Antal</mat-header-cell>
					<mat-cell *matCellDef="let item">
						{{item.quantity}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef></mat-header-cell>
					<mat-cell *matCellDef="let item" class="d-block text-right">
						<button mat-icon-button color="warn" (click)="$event.stopPropagation(); deleteTime(item);">
							<mat-icon>delete_forever</mat-icon>
						</button>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectTime(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></mat-row>
			</mat-table>

			<button mat-stroked-button color="accent" (click)="addTime()" class="float-right mt-2">
				Lägg till tid
			</button>
		</ng-container>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="productDate?.id">Spara</span>
			<span *ngIf="!productDate?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
