import { BladeItemComponent } from '../blade-item/blade-item.component';
import { BladeSize } from './bladeSize.model';
import { ElementRef } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export class BladeItem {
	public id: number;
	public title?: string;
	public itemIndex?: number;
	public menuIndex?: number;
	public component: any;
	public activeClass?: BladeSize;
	public minClass: BladeSize;
	public maxClass?: BladeSize;
	public payload?: any;
	public parent?: BladeItem;
	public child?: BladeItem;
	public isRemoving?: boolean;
	public bladeItemComponent?: BladeItemComponent;
	public elementRef?: ElementRef;
	public isSelected?: boolean;
	public callback?: Function;
}
