import { Component, Input, OnInit } from '@angular/core';
import { AddonStore } from 'app/admin/stores/addon.store.service';
import { SupplierResourceAddonStore } from 'app/admin/stores/supplier-resource-addon.store.service';
import { SupplierResourceAddon } from 'app/models/supplier-resource-addon.model';
import { SupplierResource } from 'app/models/supplier-resource.model';
import { Observable, ReplaySubject, Subscriber, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-supplier-blade-resource-addon',
  templateUrl: './supplier-blade-resource-addon.component.html',
  styleUrls: ['./supplier-blade-resource-addon.component.scss']
})
export class SupplierBladeResourceAddonComponent implements OnInit {

	private supplierResourceAddonIds: SupplierResourceAddon[] = [];
	public supplierResourceAddonIds$ = new Observable<number[]>();
	@Input() supplierResource: SupplierResource;

	constructor(
		public addonStore: AddonStore,
		public supplierResourceAddonStore: SupplierResourceAddonStore,
	) { }

	ngOnInit() {
		this.getAllOnSupplierResource();
	}

	public toggleAddon(addonId: number ) {
		const excisting = this.supplierResourceAddonIds.find(x => x.addonId === addonId);
		if (excisting) {
			// Remove
			this.supplierResourceAddonStore.delete(excisting.id).subscribe(isDeleted => {
				this.getAllOnSupplierResource();
			});
		} else {
			// New
			this.supplierResourceAddonStore.create({
				addonId,
				supplierResourceId: this.supplierResource.id,
			}).subscribe(added => {
				this.getAllOnSupplierResource();
			});
		}
	}

	private getAllOnSupplierResource() {
		this.supplierResourceAddonIds$ = this.supplierResourceAddonStore.getAllOnResourceSupplier(this.supplierResource.id)
			.pipe(
				shareReplay(1),
				map(x => {
					this.supplierResourceAddonIds = x;
					return this.supplierResourceAddonIds.map(y => y.addonId);
				}),
			);
	}
}
