<mat-card>
	<mat-card-title>Datum och tider</mat-card-title>

	<mat-card>
		<mat-card-title>Tillgängliga datum</mat-card-title>
		<mat-card-subtitle>Ange från och till datum då denna produkt är tillgänglig på hemsidan. På varje inlägg så anger ni en lista med tider som i sin tur har antal platser. Varje tid kan även innehålla prisförändringar på varje kundtyp.</mat-card-subtitle>

		<p *ngIf="!dataSource?.data?.length">Det saknas datum på produkten!</p>
		<mat-table [dataSource]="dataSource" class="w-100 mat-elevation-z1" *ngIf="dataSource?.data?.length">
			<ng-container matColumnDef="date">
				<mat-header-cell *matHeaderCellDef>Datum</mat-header-cell>
				<mat-cell *matCellDef="let item" class="d-block">
					<p class="m-0"><b>Från</b>: {{item.fromDate | date:'yyyy-MM-dd'}}</p>
					<p class="m-0"><b>Till</b>: {{item.toDate | date:'yyyy-MM-dd'}}</p>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="times">
				<mat-header-cell *matHeaderCellDef>Tider</mat-header-cell>
				<mat-cell *matCellDef="let item">
					<app-product-blade-date-times [productDateId]="item.id"></app-product-blade-date-times>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="days">
				<mat-header-cell *matHeaderCellDef>Dagar</mat-header-cell>
				<mat-cell *matCellDef="let item" class="d-block">
					<ng-container *ngIf="item.weekDays?.split(',')?.length || 0 as weekDayLength">
						<ng-container *ngIf="weekDayLength === 7">
							<p class="ml-2">Alla dagar</p>
						</ng-container>
						<ng-container *ngIf="weekDayLength !== 7">
							<ng-container *ngFor="let day of weekDays">
								<span *ngIf="item.weekDays.includes(day.dayNumber)">{{day.name}} </span>
							</ng-container>
						</ng-container>
					</ng-container>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="actions">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let item" class="d-block text-right">
					<button mat-icon-button color="warn" (click)="$event.stopPropagation(); deleteDate(item);">
						<mat-icon>delete_forever</mat-icon>
					</button>
				</mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></mat-row>
		</mat-table>

		<mat-card-footer class="p-3 text-right">
			<button mat-stroked-button color="accent" (click)="addDate()">
				Lägg till datum
			</button>
		</mat-card-footer>
	</mat-card>

	<mat-card class="mt-3">
		<mat-card-title>Exkluderande datum</mat-card-title>
		<mat-card-subtitle>Finns det ett datum med i listan ovanför så ka du ange det i listan nedan. Detta datum kommer då inte visas som tillgänligt på hemsidan. Vill ni pausa försäljningen för ett datum så kan ni ange det datum här.</mat-card-subtitle>

		<p *ngIf="!excludeDataSource?.data?.length">Det saknas datum på produkten!</p>
		<mat-table [dataSource]="excludeDataSource" class="w-100 mat-elevation-z1" *ngIf="excludeDataSource?.data?.length">
			<ng-container matColumnDef="date">
				<mat-header-cell *matHeaderCellDef>Datum</mat-header-cell>
				<mat-cell *matCellDef="let item">
					{{item.excludeDate | date:'yyyy-MM-dd'}}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="actions">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let item" class="d-block text-right">
					<button mat-icon-button color="warn" (click)="$event.stopPropagation(); deleteExcludeDate(item);">
						<mat-icon>delete_forever</mat-icon>
					</button>
				</mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedExcludeColumns"></mat-header-row>
			<mat-row *matRowDef="let item; columns: displayedExcludeColumns;" class="pointer" (click)="selectExcludeItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></mat-row>
		</mat-table>

		<mat-card-footer class="p-3 text-right">
			<button mat-stroked-button color="accent" (click)="addExcludeDate()">
				Lägg till datum
			</button>
		</mat-card-footer>
	</mat-card>
</mat-card>
