import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { Order } from '../../models/order.model';
import { Observable } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class UtilStore {
	protected host = `${this.baseUrl}api/admin/Util`;

	constructor(protected http: HttpClient, @Inject('BASE_URL') private baseUrl: string, protected snackBarService: SnackBarService) { }
	public getHashId(orderId: number): Observable<string> {
		return this.http.get<any>(`${this.host}/hash/encode/${orderId}`).pipe(map(hash => {
			return hash.hash as string;
		}));
	}
}
