import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';

import { OrderPaymentStore } from 'app/admin/stores/order-payment.store.service';
import { OrderPayment } from 'app/models/order-payment.model';
import { OrderStore } from 'app/admin/stores/order.store.service';

@UntilDestroy()
@Component({
	selector: 'app-dashboard-widget-order-amount',
	templateUrl: './dashboard-widget-order-amount.component.html',
	styleUrls: ['./dashboard-widget-order-amount.component.scss'],
})
export class DashboardWidgetOrderAmountComponent implements OnInit, OnDestroy {
	  public lineChartOptions: (ChartOptions & { annotation: any }) = {
		responsive: true,
		scales: {
		  // We use this empty structure as a placeholder for dynamic theming.
		  xAxes: [{}],
		  yAxes: [
			{
			  id: 'y-axis-0',
			  position: 'left',
			},
			{
				id: 'y-axis-1',
				position: 'right',
				gridLines: {
				  color: 'rgba(255,0,0,0.3)',
				},
				ticks: {
				  fontColor: 'red',
				},
			}
		  ],
		},
		annotation: {
		  annotations: [
			{
			  type: 'line',
			  mode: 'vertical',
			  scaleID: 'x-axis-0',
			  value: 'March',
			  borderColor: 'orange',
			  borderWidth: 2,
			  label: {
				enabled: true,
				fontColor: 'orange',
				content: 'LineAnno',
			  },
			},
		  ],
		},
	};

	public lineChartLegend = true;
	public lineChartType: ChartType = 'line';
	public lineChartPlugins = [pluginAnnotations];

	@ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

	public isDone = false;
	public lineChartData: ChartDataSets[] = [];
	public lineChartLabels: Label[] = moment.months();

	constructor(
		private orderStore: OrderStore,
	) { }

	ngOnInit() {
		this.orderStore.getAll$().pipe(untilDestroyed(this)).subscribe(orders => {
			orders = _.orderBy(orders, 'created', 'asc');
			// Group all order by year
			_.each(_.groupBy(orders, order => moment(order.created).format('YYYY')), yearGroups => {
				const yearData = {
					label: moment(yearGroups[0].created).format('YYYY'),
					yAxisID: 'y-axis-0',
					data: [],
				};

				yearData.yAxisID = yearData.label === '2019' ? 'y-axis-1' : 'y-axis-0';

				// Loop every month to get each amount
				_.each(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', ], monthNumber => {
					let count = 0;
					_.each(_.filter(yearGroups, order => moment(order.created).format('YYYY-MM') === yearData.label + '-' + monthNumber), order => {
						count += order.amount;
					});
					yearData.data.push(count);
				});
				this.lineChartData.push(yearData);
			});
			this.isDone = true;
		});
	}

	ngOnDestroy(): void { }
}
