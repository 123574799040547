<mat-card *ngIf="{
	adminText: 'shared.tourResource' | adminText | async
} as adminTextSource">
	<mat-card-title>{{adminTextSource.adminText?.title}}</mat-card-title>
	<mat-card-subtitle>
		{{adminTextSource.adminText?.subtitle}}
	</mat-card-subtitle>

	<mat-card-content>
		<ng-container *ngIf="{resourceTypes: supplierResourceTypeStore.items$ | async } as source">
			<i *ngIf="!source.resourceTypes?.length">{{adminTextSource.adminText?.missing}}</i>
			<ng-container *ngIf="source.resourceTypes?.length">
				<p>{{adminTextSource.adminText?.selectResource}}</p>

				<form [formGroup]="resourceForm" class="row">
					<div class="col-6">
						<mat-form-field class="w-100">
							<mat-label>{{adminTextSource.adminText?.selectResourceLabel}}</mat-label>
							<mat-select formControlName="supplierResourceTypeId">
								<mat-option *ngFor="let type of source.resourceTypes" [value]="type.id">
									{{type.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-6">
						<mat-form-field class="w-100">
							<mat-label>{{adminTextSource.adminText?.amount}}</mat-label>
							<input type="number" matInput formControlName="amount">
						</mat-form-field>
					</div>
				</form>
				<div class="d-flex" [ngClass]="{'justify-content-end': !resourceForm.value.id, 'justify-content-between': resourceForm.value.id}">
					<button mat-stroked-button color="warn" (click)="removeTourResource(resourceForm.value.id)" *ngIf="resourceForm.value.id">{{adminTextSource.adminText?.remove}}</button>
					<div>
						<button mat-stroked-button class="mr-2" (click)="resetTourResource()" *ngIf="resourceForm.value.id">{{adminTextSource.adminText?.cancel}}</button>
						<button mat-stroked-button color="accent" (click)="addTourResource()" [disabled]="!resourceForm.valid">
							<span *ngIf="!resourceForm.value.id">{{adminTextSource.adminText?.addResource}}</span>
							<span *ngIf="resourceForm.value.id">{{adminTextSource.adminText?.updateResource}}</span>
						</button>
					</div>
				</div>

				<div class="mat-elevation-z3 mt-3" *ngIf="(supplierResourceTourStore.items$ | async)?.length">
					<table mat-table [dataSource]="supplierResourceTourStore.items$" matSort class="w-100">
						<ng-container matColumnDef="resource">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{adminTextSource.adminText?.resource}}</th>
							<td mat-cell *matCellDef="let item"><strong>{{item.supplierResourceTypeId | supplierResourceTypeName | async}}</strong></td>
						</ng-container>
						<ng-container matColumnDef="amount">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{adminTextSource.adminText?.amount}}</th>
							<td mat-cell *matCellDef="let item">{{item.amount}}</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectTourResource(item)" [class.selected]="resourceForm?.value?.id === item?.id"></tr>
					</table>
				</div>

				<mat-card class="mat-elevation-z3 mt-3">
					<mat-card-subtitle>{{adminTextSource.adminText?.calculatedResource.title}}</mat-card-subtitle>
					<mat-card-content>
						<table mat-table [dataSource]="calculatedResourceItems$" matSort class="w-100">
							<ng-container matColumnDef="resource">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{adminTextSource.adminText?.calculatedResource.table.resource}}</th>
								<td mat-cell *matCellDef="let item"><strong>{{item.supplierResourceTypeId | supplierResourceTypeName | async}}</strong></td>
							</ng-container>
							<ng-container matColumnDef="amount">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{adminTextSource.adminText?.calculatedResource.table.resource}}</th>
								<td mat-cell *matCellDef="let item">
									<span *ngIf="item.source.course">{{item.source.course}}</span>
									<span *ngIf="!item.source.course">{{item.source.amount}}</span>
								</td>
							</ng-container>
							<ng-container matColumnDef="handled">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{adminTextSource.adminText?.calculatedResource.table.handled}}</th>
								<td mat-cell *matCellDef="let item">
									<span *ngIf="item.source.supplierResourceProductTypeId">{{item.source.supplierResourceProductTypeId | supplierResourceProductTypeName | async}}</span>
									<span *ngIf="!item.source.supplierResourceProductTypeId">{{adminTextSource.adminText?.calculatedResource.table.addedOnTour}}</span>
								</td>
							</ng-container>
							<ng-container matColumnDef="total">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{adminTextSource.adminText?.calculatedResource.table.amountResource}}</th>
								<td mat-cell *matCellDef="let item">{{item.amount}}</td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedColumnsResourceSummary"></tr>
							<tr mat-row *matRowDef="let item; columns: displayedColumnsResourceSummary;"></tr>
						</table>
					</mat-card-content>
				</mat-card>
				<div class="mt-3">
					<p>{{adminTextSource.adminText?.total}}</p>
					<p *ngFor="let resource of calculatedResourceSummary$ | async">
						{{resource.supplierResourceTypeId | supplierResourceTypeName | async}} = {{resource.amount}}st
					</p>
				</div>
			</ng-container>
		</ng-container>

	</mat-card-content>
</mat-card>
