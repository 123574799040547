import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { EtageBladeComponent } from 'app/admin/blades/etage/etage-blade/etage-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { PickupLocationText } from 'app/models/page-location-text.model';
import { PickupLocationTextStore } from 'app/admin/stores/pickup-location-text.store.service';
import { Language } from 'app/models/language.model';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { PickupLocationTextBladeComponent } from 'app/admin/blades/pickup-location-text/pickup-location-text-blade/pickup-location-text-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-pickup-location-blade-texts',
	templateUrl: './pickup-location-blade-texts.component.html',
	styleUrls: ['./pickup-location-blade-texts.component.scss'],
})
export class PickupLocationBladeTextsComponent implements OnInit, OnDestroy {
	public languages: Language[];
	public pickupLocationTexts: PickupLocationText[];

	public displayedColumns: string[] = ['language', 'name'];
	public dataSource: MatTableDataSource<PickupLocationText>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public pageLocationTextStore: PickupLocationTextStore,
		public languageStore: LanguageStore,
	) {
		pageLocationTextStore.getAllOnPickupLocation(bladeItem.id).pipe(untilDestroyed(this)).subscribe();
		pageLocationTextStore.items$.pipe(untilDestroyed(this)).subscribe(pickupLocationTexts => {
			this.pickupLocationTexts = pickupLocationTexts;
			this.handlePickupLocationTexts();
		});
		this.languageStore.items$.pipe(untilDestroyed(this)).subscribe(languages => {
			this.languages = languages;
			this.handlePickupLocationTexts();
		});
	}

	ngOnInit() {
	}

	ngOnDestroy(): void { }

	private handlePickupLocationTexts() {
		if (this.pickupLocationTexts && this.languages) {
			// Every language must have a productText
			_.each(this.languages, language => {
				const pickupLocationTexts = _.filter(this.pickupLocationTexts, text => text.languageId === language.id);
				let pickupLocationText;
				if (pickupLocationTexts && pickupLocationTexts.length > 1) {
					pickupLocationText = _.find(this.pickupLocationTexts, text => text.languageId === language.id && text.id > 0);
					this.pageLocationTextStore.removePickupLocationText(_.find(this.pickupLocationTexts, text => text.languageId === language.id && !text.id));
				} else if (pickupLocationTexts && pickupLocationTexts.length) {
					pickupLocationText = pickupLocationTexts[0];
				}

				if (!pickupLocationText) {
					pickupLocationText = new PickupLocationText();
					pickupLocationText.languageId = language.id;
					this.pageLocationTextStore.addPickupLocationText(pickupLocationText);
				}
				pickupLocationText.language = language.name;
			});
			this.dataSource = new MatTableDataSource<PickupLocationText>(this.pickupLocationTexts);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: PickupLocationTextBladeComponent,
			minClass: BladeSize.s,
			payload: {
				pickupLocationId: this.bladeItem.id,
				languageId: row.languageId,
			},
		}, this.bladeItem);
	}
}
