<mat-card class="mb-3" *ngIf="{
	tourBooking: orderTourBookingItemService.tourBookingReplay$ | async,
	tour: orderTourBookingItemService.tourReplay$ | async,
	tourBookingCustomerTypes: orderTourBookingItemService.tourBookingCustomerTypesReplay$ | async,
	quantity: orderTourBookingItemService.quantityReplay$ | async
} as source">
	<div class="d-flex justify-content-between">
		<p>ID: {{source.tourBooking?.id}}</p>
		<p>Status: {{source.tourBooking?.bookingStatusId | bookingStatusName | async}}</p>
		<p>Källa: {{source.tourBooking?.sourceTypeId | sourceTypeName | async}}</p>
	</div>
	<mat-card-title class="card-title-small d-flex justify-content-between">
		<span>Produkt: {{source.tour?.productId | productName | async}}</span>
		<span>Turdatum: {{source.tour?.date | date:'yyyy-MM-dd'}} {{source.tour?.time}}</span>
	</mat-card-title>
	<div class="d-flex justify-content-between">
		<p>Kund: {{source.tourBooking?.customerId | customerName | async}}</p>
		<p>Antal: {{source.quantity}}st</p>
	</div>
	<div class="d-flex justify-content-between">
		<p>Utkast: {{source.tourBooking?.isDraft ? 'Ja': 'Nej'}}</p>
		<p>Skapad: {{source.tourBooking?.created | date:'yyyy-MM-dd HH:mm'}}</p>
	</div>
	<mat-card-actions class="d-flex justify-content-between">
		<button *ngIf="isOrder" mat-stroked-button [color]="'primary'" (click)="orderTourBookingItemService.openCustomer(source.tourBooking?.customerId)">Kund</button>
		<button *ngIf="isCustomer" mat-stroked-button [color]="'primary'" (click)="orderTourBookingItemService.openOrder(source.tourBooking?.orderId)">Order</button>
		<button mat-stroked-button [color]="'primary'" (click)="orderTourBookingItemService.openTour(source.tour?.id)">Tur</button>
		<button mat-stroked-button [color]="'primary'" (click)="orderTourBookingItemService.openTourBooking(source.tourBooking?.id)">Bokning</button>
	</mat-card-actions>
</mat-card>
