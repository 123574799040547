import { Directive, OnInit, Input, ElementRef, TemplateRef, ViewContainerRef, OnDestroy, Renderer2 } from '@angular/core';
import { UserService } from 'app/admin/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from 'app/admin/models/user.model';
import * as _ from 'lodash';

@UntilDestroy()
@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[icon]',
})
export class IconDirective implements OnInit, OnDestroy {
	@Input() set icon(icon: string) {
		this.updateView(icon);
	}

	constructor(
	  private element: ElementRef,
	  private viewContainer: ViewContainerRef,
	  private renderer2: Renderer2,
	) {
	}

	ngOnInit() { }

	ngOnDestroy(): void { }

	private updateView(icon: string) {
		this.viewContainer.clear();
		const childElements = this.element.nativeElement.children;
		for (let child of childElements) {
			this.renderer2.removeChild(this.element.nativeElement, child);
		}
		if (icon) {
			if (icon.includes(' ')) {
				// Font Awesome
				const element = this.renderer2.createElement('i');
				icon.split(' ').forEach(ic => {
					this.renderer2.addClass(element, ic);
				});
				this.renderer2.appendChild(this.element.nativeElement, element);
			} else {
				// Google icon
				const element = this.renderer2.createElement('span');
				this.renderer2.addClass(element, 'material-icons');
				this.renderer2.appendChild(element, this.renderer2.createText(icon));

				this.renderer2.appendChild(this.element.nativeElement, element);
			}
		}
	}


}
