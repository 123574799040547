import { BaseModel } from '../base.model';
import { Category } from './category.model';

export class CategoryCategory extends BaseModel {
	public parentId: number;
	public categoryId: number;
	public prioId: number;
	public parent: Category;
	public category: Category;
}
