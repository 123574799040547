import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dictionary } from 'lodash';
import * as moment from 'moment';

import { TourBookingCustomerTypeStore } from 'app/admin/stores/tour-booking-customer-type.store.service';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingCustomerTypeBladeComponent } from 'app/admin/blades/tour-booking-customer-type/tour-booking-customer-type-blade/tour-booking-customer-type-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';

@UntilDestroy()
@Component({
	selector: 'app-tour-booking-blade-bookings-customer-type',
	templateUrl: './tour-booking-blade-bookings-customer-type.component.html',
	styleUrls: ['./tour-booking-blade-bookings-customer-type.component.scss'],
})
export class TourBookingBladeBookingsCustomerTypeComponent implements OnDestroy {
	public moment = moment;
	@ViewChild(MatAccordion) accordion: MatAccordion;
	public displayedColumns: string[] = ['id', 'quantity', 'created'];
	public tourBookingCustomerTypesReplay$ = new ReplaySubject<Dictionary<TourBookingCustomerType[]>>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private tourBookingCustomerTypeStore: TourBookingCustomerTypeStore,
		private bladeService: BladeService,
	) {
		this.tourBookingCustomerTypeStore.getAllOnTourBooking(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(tourBookingCustomerTypes => {
			this.tourBookingCustomerTypesReplay$.next(_.groupBy(tourBookingCustomerTypes, type => type.customerTypeId));
		});
	}

	ngOnDestroy() { }

	public selectItem(row: TourBookingCustomerType) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: TourBookingCustomerTypeBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

}
