import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Product } from 'app/models/product/product.model';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CategoryProductStore } from 'app/admin/stores/category/category-product.store.service';
import { CategoryProduct } from 'app/models/category/category-product.model';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { CategoryBladeService } from '../../services/category-blade.service';

@UntilDestroy()
@Component({
	selector: 'app-category-blade-product',
	templateUrl: './category-blade-product.component.html',
	styleUrls: ['./category-blade-product.component.scss'],
})
export class CategoryBladeProductComponent implements OnInit, OnDestroy {
	private products: Product[];
	public displayedColumns: string[] = ['select', 'id', 'name'];
	public dataSource: MatTableDataSource<Product>;
	public selection: SelectionModel<Product>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	private connectedProducts: CategoryProduct[];
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public productStore: ProductStore,
		public categoryProductStore: CategoryProductStore,
		private snackBarService: SnackBarService,
		private categoryBladeService: CategoryBladeService,
	) {
		this.productStore.getAll();
		this.productStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.products = items;
			this.dataSource = new MatTableDataSource<Product>(this.products);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getAllOnParent();
		});
	}

	ngOnInit() {}

	ngOnDestroy() {}

	public selectProduct(product: Product) {
		this.selection.toggle(product);
	}

	private getAllOnParent() {
		this.categoryProductStore.getAllOnParent(this.bladeItem.id).subscribe(connectedProducts => {
			this.connectedProducts = connectedProducts;
			this.handleSelectedProducts();
		});
	}

	private handleSelectedProducts() {
		const selectedProducts = [];
		_.each(_.map(this.connectedProducts, 'product'), product => {
			const prod = _.find(this.products, p => p.id === product.id);
			if (prod && !_.find(selectedProducts, p => p.id === prod.id)) {
				selectedProducts.push(prod);
			}
		});
		this.selection = new SelectionModel<Product>(true, selectedProducts);
	}

	public save() {
		this.categoryBladeService.setLoading(true);
		const add: CategoryProduct[] = [];
		_.each(_.differenceWith(this.selection.selected, _.map(this.connectedProducts, 'product'), (a, b) => a.id === b.id), product => {
			const cp = new CategoryProduct();
			cp.categoryId = this.bladeItem.id;
			cp.productId = product.id;
			add.push(cp);
		});

		const removed = _.differenceWith(this.connectedProducts, this.selection.selected, (a, b) => a.productId === b.id);
		if (add || removed) {
			this.categoryProductStore.addDelete(this.bladeItem.id, add, _.map(removed, 'id')).subscribe(() => {
				this.getAllOnParent();
				this.snackBarService.showSuccess('Kopplingarna har sparats!');
				this.categoryBladeService.setLoading(false);
			});
		}
	}
}
