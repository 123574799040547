import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SupplierResource } from 'app/models/supplier-resource.model';
import { SupplierResourceStore } from 'app/admin/stores/supplier-resource.store.service';
import { SupplierResourceTypeStore } from 'app/admin/stores/supplier-resource-type.store.service';
import { SettingsBladeComponent } from '../../settings/settings-blade/settings-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { tap } from 'rxjs/operators';
import { SupplierResourceDateStore } from 'app/admin/stores/supplier-resource-date.store.service';
import { SupplierResourceDate } from 'app/models/supplier-resource-date.model';
import * as moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-supplier-resource-blade',
	templateUrl: './supplier-resource-blade.component.html',
	styleUrls: ['./supplier-resource-blade.component.scss'],
})
@IBladeComponent.register
export class SupplierResourceBladeComponent implements OnInit, OnDestroy {

	public loading = true;

	public item: SupplierResource;

	public displayedDateColumns: string[] = ['date', 'amount'];

	public itemForm: FormGroup = new FormGroup({
		supplierResourceType: new FormControl(null, [Validators.required]),
		amount: new FormControl(null, [Validators.required, Validators.min(1)]),
	});

	public dateForm: FormGroup = new FormGroup({
		supplierResourceId: new FormControl(0),
		id: new FormControl(0),
		date: new FormControl(null, [Validators.required]),
		amount: new FormControl(null, [Validators.required, Validators.min(1)]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: SupplierResourceStore,
		public supplierResourceTypeStore: SupplierResourceTypeStore,
		private bladeService: BladeService,
		public supplierResourceDateStore: SupplierResourceDateStore,
	) {
		if (bladeItem.id) {
			this.dateForm.get('supplierResourceId').setValue(bladeItem.id);
			this.supplierResourceDateStore.getAllOnSupplierResource$(bladeItem.id).subscribe();
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.itemForm.setValue({
					supplierResourceType: this.item.supplierResourceTypeId,
					amount: this.item.amount,
				});

				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new SupplierResource();
			if (this.bladeItem.payload) {
				this.item.supplierId = this.bladeItem.payload;
			}
			this.supplierResourceDateStore.reset();
		}
	 }

	ngOnInit(): void { }
	ngOnDestroy(): void { }

	public save() {
		this.loading = true;
		this.item.supplierResourceTypeId = this.itemForm.get('supplierResourceType').value;
		this.item.amount = this.itemForm.get('amount').value;

		this.saveOrUpdate().pipe(untilDestroyed(this)).subscribe(item => {
			this.item = _.assign(this.item, item);
			this.loading = false;
			this.itemForm.markAsPristine();
			this.dateForm.get('supplierResourceId').setValue(this.item.id);
		});
	}

	public delete() {
		if (this.item.id) {
			this.loading = true;
			this.itemStore.delete(this.item.id).subscribe(item => {
				this.loading = false;
				this.bladeItem.bladeItemComponent.closeMe();
			});
		}
	}
	private saveOrUpdate(): Observable<SupplierResource> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
			// .pipe(tap(item => {
			// 	this.bladeItem.bladeItemComponent.closeMe();
			// }));
		}
	}

	public newResource() {
		this.bladeService.addBladeItem({
			id: 0,
			component: SettingsBladeComponent,
			minClass: BladeSize.m,
			menuIndex: 13,
		}, this.bladeItem);
	}

	public addDateResource() {
		this.loading = true;
		this.saveOrUpdateResourceDate().pipe(untilDestroyed(this)).subscribe(item => {
			this.dateForm.reset();
			this.dateForm.get('supplierResourceId').setValue(this.item.id);
			this.loading = false;
		});
	}

	public resetDateResource() {
		this.dateForm.reset();
		this.dateForm.get('supplierResourceId').setValue(this.item.id);
	}

	private saveOrUpdateResourceDate(): Observable<SupplierResourceDate> {
		if (this.dateForm.value.id) {
			return this.supplierResourceDateStore.update(this.dateForm.value);
		} else {
			return this.supplierResourceDateStore.create(this.dateForm.value);
		}
	}

	public selectDateResource(supplierResourceDate: SupplierResourceDate) {
		this.dateForm.patchValue(supplierResourceDate);
	}

	public removeDateResource() {
		if (this.dateForm.value.id) {
			this.loading = true;
			this.supplierResourceDateStore.delete(this.dateForm.value.id).subscribe(isRemoved => {
				this.dateForm.reset();
				this.dateForm.get('supplierResourceId').setValue(this.item.id);
				this.loading = false;
			});
		}
	}
}
