import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import * as _ from 'lodash';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Language } from 'app/models/language.model';
import { FormElement } from 'app/models/form-element.model';
import { Subject, ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormElementStore } from 'app/admin/stores/form-element.store.service';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { FormGroup, FormControl } from '@angular/forms';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { Element } from 'app/models/element.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { ElementStore } from 'app/admin/stores/element.store.service';

@UntilDestroy()
@Component({
	selector: 'app-element-form-blade',
	templateUrl: './element-form.blade.html',
	styleUrls: ['./element-form.blade.scss'],
})
@IBladeComponent.register
export class ElementFormBlade implements OnInit, OnDestroy {
	public saving: Subject<boolean> = new Subject<boolean>();
	public selectedIndex = 0;
	public loading = true;
	public canIClose: Function | boolean;
	public languages: Language[];
	public element: Element;
	public menuItems: BladeMenuItem[] = [];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];
	public activeItem: FormElement;
	public activeItemSubject$ = new ReplaySubject<FormElement>(1);
	private activeLanguage: Language;
	public allItems: FormElement[];
	public itemForm: FormGroup = new FormGroup({
		form: new FormControl(''),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public formElementStore: FormElementStore,
		public languageStore: LanguageStore,
		private elementStore: ElementStore,
	) {
	}

	ngOnInit() {
		this.languageStore.items$.pipe(untilDestroyed(this)).subscribe(languages => {
			this.languages = languages;
			this.menuItems.push({
				index: 0,
				title: 'Generellt',
			});
			_.each(this.languages, (lang, index) => {
				this.menuItems.push({
					index: index + 1,
					title: lang.name,
				});
			});
			this.menuItems.push({
				index: 99,
				title: 'Style',
			});
			this.activeLanguage = this.languages[0];
			this.formElementStore.getAllOnElement(this.bladeItem.id, languages).subscribe();
			this.elementStore.get(this.bladeItem.id).subscribe(element => {
				this.element = element;
			});
		});

		this.formElementStore.allOnElement$.pipe(untilDestroyed(this)).subscribe(allItems => {
			this.loading = false;
			this.allItems = allItems;
			this.allItems.forEach(item => {
				if (typeof item.formData === 'string' || !item.formData) {
					item.formData = JSON.parse(item.formData || '{elements: []}');
				}
			});
			this.setActiveItem();
		});
	}

	ngOnDestroy() { }

	public menuChange(index: number) {
		this.activeLanguage = this.languages[index - 1];
		this.setActiveItem();
	}

	private setActiveItem() {
		if (this.activeLanguage && this.allItems) {
			this.activeItem = _.find(this.allItems, item => item.languageId === this.activeLanguage.id);


			this.activeItemSubject$.next(null);
			setTimeout(() => {
				this.activeItemSubject$.next(this.activeItem);
			}, 100);
		}
	}

	public save() {
		this.loading = true;
		if (this.selectedIndex === 0 || this.selectedIndex === 99) {
			this.saving.next(true);
			return;
		}

		_.each(this.allItems, item => {
			item.formData = JSON.stringify(item.formData);
			if (item.id) {
				this.formElementStore.update(item).subscribe(updatedItem => {
					this.loading = false;
					item = _.assign(item, updatedItem);
					item.formData = JSON.parse(item.formData);
				});
			} else {
				this.formElementStore.create(item).subscribe(updatedItem => {
					this.loading = false;
					item = _.assign(item, updatedItem);
					item.formData = JSON.parse(item.formData);
				});
			}
		});
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		_.each(this.allItems, item => {
			this.elementStore.delete(item.elementId).subscribe(isDeleted => {
				this.loading = false;
				if (isDeleted) {
					this.bladeItem.bladeItemComponent.closeMe();
				}
			});
		});
	}
}
