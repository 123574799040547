import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { ProductBookingElementStore } from 'app/admin/stores/product-booking-element.store.service';
import { ProductBookingElement } from 'app/models/product-booking-element.model';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { ElementStore } from 'app/admin/stores/element.store.service';

@UntilDestroy()
@Component({
  selector: 'app-element-product-booking-blade',
  templateUrl: './element-product-booking.blade.html',
  styleUrls: ['./element-product-booking.blade.scss'],
})
@IBladeComponent.register
export class ElementProductBookingBlade implements OnInit, OnDestroy {
	public saving: Subject<boolean> = new Subject<boolean>();
	public selectedIndex = 0;
	public loading = true;

	public menuItems: BladeMenuItem[] = [
		{
			index: 1,
			title: 'Produktbokining',
		}, {
			index: 99,
			title: 'Style',
		},
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	public productBookingElement: ProductBookingElement;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public productBookingElementStore: ProductBookingElementStore,
		private elementStore: ElementStore,
	) {
	}

	ngOnInit() {
		this.productBookingElement = new ProductBookingElement();
		this.productBookingElement.elementId = this.bladeItem.id;
		this.productBookingElementStore.getOnElement(this.bladeItem.id).subscribe(productBookingElement => {
			this.loading = false;
			if (productBookingElement) {
				this.productBookingElement = productBookingElement;
			}
		});
	}

	ngOnDestroy() { }

	public save() {
		if (this.selectedIndex === 99) {
			this.saving.next(true);
			return;
		}

		this.loading = true;

		if (this.productBookingElement.id) {
			this.productBookingElementStore.update(this.productBookingElement).subscribe(updatedItem => {
				this.loading = false;
				this.productBookingElement = _.assign(this.productBookingElement, updatedItem);
			});
		} else {
			this.productBookingElementStore.create(this.productBookingElement).subscribe(updatedItem => {
				this.loading = false;
				this.productBookingElement = _.assign(this.productBookingElement, updatedItem);
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.elementStore.delete(this.productBookingElement.elementId).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
