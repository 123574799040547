import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingMessageStore } from 'app/admin/stores/tour-booking-message.store.service';
import { TourBookingMessage } from 'app/models/tour-booking-message.model';
import * as _ from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TourBookingMessageComponent } from '../tour-booking-message.component';

@UntilDestroy()
@Injectable()
export class TourBookingMessageService {
	private tourBookingMessages: TourBookingMessage[];
	public tourBookingMessagesReplay$ = new ReplaySubject<TourBookingMessage[]>();
	public isSaving$ = new BehaviorSubject<boolean>(false);

	public formGroup = new FormGroup({
		message: new FormControl(),
	});

	constructor(
		@Inject(MAT_DIALOG_DATA) public tourBookingId: number,
		public tourBookingMessageStore: TourBookingMessageStore,
		public dialogRef: MatDialogRef<TourBookingMessageComponent>,
	) {
		this.tourBookingMessageStore.reset();
		this.tourBookingMessageStore.items$.pipe().subscribe(tourBookingMessages => {
			if (tourBookingMessages?.length) {
				this.tourBookingMessages = tourBookingMessages;
				this.tourBookingMessagesReplay$.next(this.tourBookingMessages);
				this.formGroup.patchValue(this.tourBookingMessages[0]);
			}
		});

		this.tourBookingMessageStore.getAllOnTourBooking(this.tourBookingId).pipe(untilDestroyed(this)).subscribe();
	}

	public saveOrUpdateMessage(): void {
		let tourBookingMessage = this.tourBookingMessages?.[0];

		if (!tourBookingMessage) {
			tourBookingMessage = new TourBookingMessage();
			tourBookingMessage.tourBookingId = this.tourBookingId;
		}
		tourBookingMessage.message = this.formGroup.value.message;

		this.isSaving$.next(true);
		if (tourBookingMessage.id) {
			this.tourBookingMessageStore.update(tourBookingMessage).pipe(tap(created => tourBookingMessage.id = created.id)).subscribe(_ => this.isSaving$.next(false));
		} else {
			this.tourBookingMessageStore.create(tourBookingMessage).pipe(tap(created => tourBookingMessage.id = created.id)).subscribe(_ => this.isSaving$.next(false));
		}
	}
}
