<mat-card *ngIf="{
	products: productStore.items$ | async,
	item: productFunctionService.itemReplay$ | async,
	selectedProducts: productFunctionService.selectedProducts$ | async
} as source">
	<mat-card-title>Produkter</mat-card-title>
	<mat-card-subtitle>Välj vilka produkter denna funktionen ska appliceras på.</mat-card-subtitle>

	<div class="mt-3 pt-3 border-top">
		<p>Tillägg</p>
		<mat-checkbox class="mr-3 d-block"
			*ngFor="let product of source.products"
			[checked]="source.selectedProducts?.includes(product.id)"
			(change)="productFunctionService.toggleProduct(product.id)">
				{{product.name}}
		</mat-checkbox>
	</div>

</mat-card>
