import { Injectable, Inject, OnDestroy } from '@angular/core';

import { combineLatest, of, forkJoin, BehaviorSubject, ReplaySubject, EMPTY, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { OrderItemStore } from 'app/admin/stores/order-item.store.service';
import { TourBookingAddonStore } from 'app/admin/stores/tour-booking-addon.store.service';
import { TourBookingPickupLocationStore } from 'app/admin/stores/tour-booking-pickup-location.store.service';
import { OrderWrapper } from '../models/order-wrapper.model';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { CustomerStore } from 'app/admin/stores/customer.store.service';
import { Customer } from 'app/models/customer.model';
import { OrderItemTypeEnum } from '../enums/order-item-type.enum';
import { OrderItem } from 'app/models/order-item.model';
import { AddonOrder } from '../models/addon-order.model';
import { CustomerTypeOrder } from '../models/customer-type-order.model';
import { PickupLocationOrder } from '../models/pickup-location-order.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { CreateOrderModeEnum } from 'app/enums/create-order-mode.enum';
import { Product } from 'app/models/product/product.model';
import { CreateOrder } from 'app/models/order/CreateOrder';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { OrderPart } from 'app/models/order/OrderPart';
import { Order } from 'app/models/order.model';

@UntilDestroy()
@Injectable()
export class CreateOrderTourBookingBladeService implements OnDestroy {
	private errorMessage: any;
	private errorMessageBehavior = new ReplaySubject<any>(1);
	public errorMessageBehavior$ = this.errorMessageBehavior.asObservable();

	private orderWrappers: OrderWrapper[];

	private isLoadingBehavior = new BehaviorSubject<boolean>(true);
	public isLoadingBehavior$ = this.isLoadingBehavior.asObservable();

	private selectedCustomer: Customer;
	private selectedCustomerReplay = new ReplaySubject<Customer>(1);
	public selectedCustomerReplay$ = this.selectedCustomerReplay.asObservable();

	private orderParts: OrderPart[] = [];
	private orderPartsReplay = new ReplaySubject<OrderPart[]>(1);
	public orderPartsReplay$ = this.orderPartsReplay.asObservable();

	private selectedProductReplay = new ReplaySubject<Product>(1);
	public selectedProductReplay$ = this.selectedProductReplay.asObservable();

	public addonOrderReplay = new ReplaySubject<AddonOrder[]>();
	public customerTypeOrderReplay = new ReplaySubject<CustomerTypeOrder[]>();
	public pickupLocationOrderReplay = new ReplaySubject<PickupLocationOrder[]>();

	public formGroup: FormGroup = new FormGroup({
		name: new FormControl('', []),
		message: new FormControl('', []),
		travelAgencyId: new FormControl('0', []),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private orderStore: OrderStore,
		public tourBookingStore: TourBookingStore,
		private orderItemStore: OrderItemStore,
		private tourBookingAddonStore: TourBookingAddonStore,
		private tourBookingPickupLocationStore: TourBookingPickupLocationStore,
		public customerStore: CustomerStore,
		private tourStore: TourStore,
	) {
		if (bladeItem.id && bladeItem.payload.mode === CreateOrderModeEnum.onTourBooking) {
			// When creating order based on tour booking
			combineLatest([
				this.tourBookingStore.get(this.bladeItem.id).pipe(switchMap(tourBooking => this.customerStore.get(tourBooking.customerId))),
				this.orderStore.getAllOnTourBooking(this.bladeItem.id).pipe(switchMap((orders) => {
					const observables = [];
					orders.forEach(order => {
						observables.push(this.orderItemStore.getAllOnOrder(order.id).pipe(switchMap(orderItems => of({order, orderItems} as OrderWrapper))));
					});
					return forkJoin(observables);
				})),
			]).pipe(take(1), untilDestroyed(this)).subscribe(([customer, orderWrappers]) => {
				this.orderWrappers = orderWrappers as OrderWrapper[];
				this.isLoadingBehavior.next(false);

				const orderName = this.getOrderName();
				if (orderName) {
					this.formGroup.get('name').setValue(orderName);
				}

				this.setCustomer(customer);
			});
		} else if (bladeItem.id && bladeItem.payload.mode !== CreateOrderModeEnum.onTourBooking) {
			this.tourStore.get(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(tour => {
				this.isLoadingBehavior.next(false);
				if (tour) {
					this.formGroup.get('name').setValue(tour.name);
				}
			});
		} else {
			this.isLoadingBehavior.next(false);
		}

		this.formGroup.get('travelAgencyId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.setCustomer(null);
		});
	}

	ngOnDestroy(): void { }

	public getOrderItemsOfType(orders: OrderWrapper[], orderItemTypeEnum: OrderItemTypeEnum): OrderItem[] {
		const orderItems: OrderItem[] = [];
		if (!orders) {
			return orderItems;
		}

		orders.forEach((orderWrapp) => {
			orderWrapp.orderItems.forEach(orderItem => {
				switch (orderItemTypeEnum) {
					case OrderItemTypeEnum.customer:
						if (orderItem.customerTypeId > 0 && orderItem.pickupLocationId === 0) {
							orderItems.push(orderItem);
						}
						break;
					case OrderItemTypeEnum.addon:
						if (orderItem.addonId > 0) {
							orderItems.push(orderItem);
						}
						break;
					case OrderItemTypeEnum.pickup:
						if (orderItem.customerTypeId > 0 && orderItem.pickupLocationId > 0) {
							orderItems.push(orderItem);
						}
						break;
				}
			});
		});
		return orderItems;
	}

	public setLoading(isLoading: boolean) {
		this.isLoadingBehavior.next(isLoading);
	}

	public setCustomer(customer: Customer) {
		this.selectedCustomer = customer;
		this.selectedCustomerReplay.next(customer);
	}

	public setOrderPart(orderPart: OrderPart) {
		this.orderParts = _.filter(this.orderParts, part => part.uniqueId !== orderPart.uniqueId);
		this.orderParts.push(orderPart);
		this.orderPartsReplay.next(this.orderParts);
	}

	public removeOrderPart(orderPart: OrderPart) {
		this.orderParts = _.filter(this.orderParts, part => part.uniqueId !== orderPart.uniqueId);
		this.orderPartsReplay.next(this.orderParts);
	}

	public getOrderName(): string {
		let orderName = '';
		const orderWrapperWithName = _.find(this.orderWrappers, wrapper => wrapper.order.name) as OrderWrapper;
		if (orderWrapperWithName) {
			orderName = orderWrapperWithName.order.name;
		} else {
			const orderItems = this.getOrderItemsOfType(this.orderWrappers, OrderItemTypeEnum.customer);
			const orderItemWithName = _.find(orderItems, item => item.name) as OrderItem;
			if (orderItemWithName) {
				orderName = orderItemWithName.name;
			}
		}
		return orderName;
	}

	public save() {
		// Reset error message
		this.errorMessage = '';
		this.errorMessageBehavior.next(this.errorMessage);

		this.setLoading(true);
		const createOrderOnTourBooking: CreateOrder = {};
		createOrderOnTourBooking.name = this.formGroup.value.name;
		createOrderOnTourBooking.message = this.formGroup.value.message;
		createOrderOnTourBooking.travelAgencyId =  (+this.formGroup.value.travelAgencyId > 0) ? +this.formGroup.value.travelAgencyId : null;

		if (!createOrderOnTourBooking.name) {
			createOrderOnTourBooking.name = this.getOrderName();
		}

		createOrderOnTourBooking.orderParts = this.orderParts;

		this.doSave(createOrderOnTourBooking).pipe(untilDestroyed(this)).subscribe({
			next: newOrders => {
				this.setLoading(false);
				if (this.bladeItem.parent && this.bladeItem.parent.callback) {
					this.bladeItem.parent.callback();
				}
				this.bladeItem.bladeItemComponent.closeMe();
			},
			error: (error) => {
				this.errorMessage = error;
				this.errorMessageBehavior.next(this.errorMessage);
				this.setLoading(false);
			},
		});
	}

	private doSave(createOrderOnTourBooking: CreateOrder): Observable<Order[] | Order> {
		if (this.bladeItem.payload.mode === CreateOrderModeEnum.onTourBooking) {
			return this.orderStore.createOnTourBooking(this.bladeItem.id, this.selectedCustomer.id, createOrderOnTourBooking);
		} else if (this.bladeItem.payload.mode === CreateOrderModeEnum.onTour) {
			return this.orderStore.createOnTourWithTourBooking(this.bladeItem.id, this.selectedCustomer.id, createOrderOnTourBooking);
		} else if (this.bladeItem.payload.mode === CreateOrderModeEnum.newOrder) {
			if (this.bladeItem.payload.orderId) {
				// Or this is a new booking created from an order. Then add the orderId to make the connection.
				createOrderOnTourBooking.orderId = this.bladeItem.payload.orderId;
			}
			return this.orderStore.createPlainOrder(this.selectedCustomer.id, createOrderOnTourBooking);
		}

		console.error('No mode is selected...');

		return EMPTY;
	}
}
