
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReplaySubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { Language } from 'app/models/language.model';
import { SlideshowImage } from 'app/models/slideshow-image.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class SlideshowImageStore extends BaseStore<SlideshowImage> {
	private allOnElement: SlideshowImage[];
	protected allOnElementSubject = new ReplaySubject<SlideshowImage[]>(1);
	public allOnElement$ = this.allOnElementSubject.asObservable();

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SlideshowImage', baseUrl, snackBarService);
	}

	public GetOnSlideshowItem(slideshowItemId: number, languages: Language[]): Observable<SlideshowImage[]> {
		this.allOnElement = [];
		this.allOnElementSubject.next(this.allOnElement);

		return this.http.get<SlideshowImage[]>(`${this.host}/slideshowItem/${slideshowItemId}`).pipe(tap(imageElements => {
			this.allOnElement = imageElements;
			if (!this.allOnElement) {
				this.allOnElement = [];
			}
			_.each(languages, lang => {
				if (!_.includes(_.map(this.allOnElement, 'languageId'), lang.id)) {
					const imageElement = new SlideshowImage();
					imageElement.languageId = lang.id;
					imageElement.slideshowItemId = slideshowItemId;
					this.allOnElement.push(imageElement);
				}
			});
			this.allOnElementSubject.next(this.allOnElement);
		}));
	}
}
