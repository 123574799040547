<app-blade-item [canIClose]="true" [loading]="loading" [id]="item?.id">
    <app-blade-header title="Bokning" subTitle="Turbokning" [menuItems]="headerMenuItems"></app-blade-header>
	<!-- Edit content -->
	<app-blade-content *ngIf="item?.id" [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
		<mat-card *ngIf="item?.isDraft" class="mb-3">
			<mat-card-title class="text-warning">Detta är ett utkast</mat-card-title>
			Gå till order för att publicera.
		</mat-card>

		<ng-container [ngSwitch]="selectedIndex">
			<ng-container *ngSwitchCase="1">
				<app-tour-booking-blade-generally *ngIf="item" [item]="item"></app-tour-booking-blade-generally>
			</ng-container>
			<ng-container *ngSwitchCase="2">
				<app-tour-booking-blade-bookings></app-tour-booking-blade-bookings>
			</ng-container>
			<ng-container *ngSwitchCase="3">
				<app-tour-booking-blade-customer [item]="item"></app-tour-booking-blade-customer>
			</ng-container>
			<ng-container *ngSwitchCase="4">
				<app-tour-booking-order [order]="order"></app-tour-booking-order>
			</ng-container>
			<ng-container *ngSwitchCase="5">
				<app-tour-booking-messages></app-tour-booking-messages>
			</ng-container>
		</ng-container>
	</app-blade-content>

	<!-- New content -->
	<app-blade-content *ngIf="!loading && !item?.id">
		<app-tour-booking-blade-new [create]="createSubject"></app-tour-booking-blade-new>
	</app-blade-content>
	<app-blade-footer *ngIf="!loading && !item?.id">
		<span fxFlex></span>
		<button mat-flat-button color="primary" (click)="create()">Skapa</button>
	</app-blade-footer>
	<app-blade-footer *ngIf="!loading && item?.id && selectedIndex === 1">
		<span fxFlex></span>
		<button mat-flat-button color="primary" (click)="save()">Spara</button>
	</app-blade-footer>
 </app-blade-item>
