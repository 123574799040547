import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { OddFunctionBladeComponent } from '../odd-function-blade/odd-function-blade.component';
import { ProductFunctionStore } from 'app/admin/stores/product-function/product-function.store.service';
import { ProductFunctionBase } from 'app/models/product-function/product-function-base.model';
import { MinFunctionBladeComponent } from '../min-function-blade/min-function-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-product-functions-blade',
	templateUrl: './product-functions-blade.component.html',
	styleUrls: ['./product-functions-blade.component.scss'],
})
@IBladeComponent.register
export class ProductFunctionsBladeComponent implements OnInit, OnDestroy {
	private productFunctions: ProductFunctionBase[];
	public displayedColumns: string[] = ['name', 'type', 'modified'];
	public dataSource: MatTableDataSource<ProductFunctionBase>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: ProductFunctionStore,
	) {
		itemStore.getAll();
		itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.productFunctions = items;
			this.dataSource = new MatTableDataSource<ProductFunctionBase>(this.productFunctions);
			this.dataSource.paginator = this.paginator;
		});
	}

	ngOnInit() { }

	ngOnDestroy(): void {}

	public selectItem(row: ProductFunctionBase) {

		this.bladeService.addBladeItem({
			id: row.id,
			component: this.getFuctionComponent(row.functionTypeId),
			minClass: BladeSize.m,
			payload: row.functionTypeId,
		}, this.bladeItem);
	}

	public createNewOddFunction() {
		this.bladeService.addBladeItem({
			id: 0,
			component: OddFunctionBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

	public createNewMinFunction() {
		this.bladeService.addBladeItem({
			id: 0,
			component: MinFunctionBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

	private getFuctionComponent(functionTypeId: number): any {
		switch (functionTypeId) {
			case 1:
				return OddFunctionBladeComponent;
			case 2:
				return MinFunctionBladeComponent;
			default:
				break;
		}
	}
}
