import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { ProductPriceStore } from 'app/admin/stores/product/product-price.store.service';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { ProductPrice } from 'app/models/product/product-price.model';
import { CustomerType } from 'app/models/customer-type.model';
import { ProductPriceBladeComponent } from 'app/admin/blades/poduct-price/product-price-blade/product-price-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';

@UntilDestroy()
@Component({
	selector: 'app-product-blade-prices',
	templateUrl: './product-blade-prices.component.html',
	styleUrls: ['./product-blade-prices.component.scss'],
})
export class ProductBladePricesComponent implements OnInit, OnDestroy {
	public customerTypes: CustomerType[];
	public productPrices: ProductPrice[];
	public displayedColumns: string[] = ['id', 'customerType', 'price'];
	public dataSource: MatTableDataSource<ProductPrice>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public customerTypeStore: CustomerTypeStore,
		private productPriceStore: ProductPriceStore,
	) {}

	ngOnInit() {
		this.productPriceStore.items$.pipe(untilDestroyed(this)).subscribe(productPrices => {
			this.productPrices = productPrices;
			this.handleProduct();
		});
		this.productPriceStore.getAllOnProduct(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(productTexts => {
			this.productPrices = productTexts;
			this.handleProduct();
		});
		this.customerTypeStore.items$.pipe(untilDestroyed(this)).subscribe(customerTypes => {
			this.customerTypes = customerTypes;
			this.handleProduct();
		});
	}

	ngOnDestroy(): void { }

	private handleProduct() {
		if (this.productPrices && this.customerTypes) {
			// Every customerTypes must have a productPrice
			_.each(this.customerTypes, customerType => {
				const productPrices = _.filter(this.productPrices, price => price.customerTypeId === customerType.id);
				let productPrice;
				if (productPrices && productPrices.length > 1) {
					productPrice = _.find(this.productPrices, price => price.customerTypeId === customerType.id && price.id > 0);
					this.productPriceStore.removeProductPrice(_.find(this.productPrices, price => price.customerTypeId === customerType.id && !price.id));
				} else if (productPrices && productPrices.length) {
					productPrice = productPrices[0];
				}

				if (!productPrice) {
					productPrice = new ProductPrice();
					productPrice.customerTypeId = customerType.id;
					this.productPriceStore.addProductPrice(productPrice);
				}
			});
			this.dataSource = new MatTableDataSource<ProductPrice>(this.productPrices);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}

	public selectItem(row: ProductPrice) {
		this.bladeService.addBladeItem({
			id: 0,
			component: ProductPriceBladeComponent,
			minClass: BladeSize.xs,
			payload: {
				productId: this.bladeItem.id,
				customerTypeId: row.customerTypeId,
			},
		}, this.bladeItem);
	}
}
