<mat-card *ngIf="{
	adminText: 'blades.tour.tabs.generally' | adminText | async
} as source">
	<mat-card-content>
		<mat-card-title *ngIf="tour?.id">{{source.adminText?.title}}</mat-card-title>
		<mat-card-title *ngIf="!tour?.id">{{source.adminText?.newTitle}}</mat-card-title>
		<app-alert-card [mode]="'info'" *ngIf="!tour?.id">{{source.adminText?.newTourCard}}</app-alert-card>
		<form [formGroup]="itemForm" class="mb-2">
			<div class="d-flex align-items-center text-right justify-content-between mb-3">
				<mat-slide-toggle formControlName="isActive">{{source.adminText?.activeLabel}}</mat-slide-toggle>
				<mat-slide-toggle formControlName="isPrivate">{{source.adminText?.privateLabel}}</mat-slide-toggle>
			</div>
			<div class="row">
				<div class="col-md-6">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>{{source.adminText?.nameLabel}}</mat-label>
						<input matInput formControlName="name" [placeholder]="source.adminText?.nameLabel">
						<mat-icon fontSet="material-icons-outlined" matSuffix [matTooltip]="source.adminText?.nameTooltip">info</mat-icon>
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>{{source.adminText?.statusLabel}}</mat-label>
						<mat-select [formControlName]="'tourStatusId'">
							<mat-option *ngFor="let type of (tourStatusStore.items$|async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>{{source.adminText?.dateLabel}}</mat-label>
						<input matInput formControlName="date" readonly [placeholder]="source.adminText?.dateLabel" [matDatepicker]="picker" [matTooltip]="source.adminText?.dateTooltip">
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-6">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>{{source.adminText?.timeLabel}}</mat-label>
						<input type="time" matInput formControlName="time" [placeholder]="source.adminText?.timeLabel">
					</mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>{{source.adminText?.availablePlacesLabel}}</mat-label>
						<input matInput formControlName="availablePlaces" [placeholder]="source.adminText?.availablePlacesLabel">
					</mat-form-field>
				</div>
				<div class="col-6">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>{{source.adminText?.timeLengthLabel}}</mat-label>
						<input matInput formControlName="timeLength" [placeholder]="source.adminText?.timeLengthLabel">
					</mat-form-field>
				</div>
			</div>
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>{{source.adminText?.productLabel}}</mat-label>
				<input type="text"
					[placeholder]="source.adminText?.productPlaceholder"
					matInput
					formControlName="productId"
					[matAutocomplete]="product">
				<mat-autocomplete #product="matAutocomplete" [displayWith]="displayProductFn.bind(this)">
					<mat-option *ngFor="let type of (filterProducts$ | async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>{{source.adminText?.supplierLabel}}</mat-label>
				<input type="text"
					[placeholder]="source.adminText?.supplierPlaceholder"
					matInput
					formControlName="supplierId"
					[matAutocomplete]="supplier">
				<mat-autocomplete #supplier="matAutocomplete" [displayWith]="displaySupplierFn.bind(this)">
					<mat-option *ngFor="let type of (filterSuppliers$ | async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</form>
		<div class="row" *ngIf="tour?.id">
			<div class="col-6">
				<b>{{source.adminText?.created}}</b><br>
				{{tour.created | date:'yyyy-MM-dd HH:mm'}}<br>
				<span *ngIf="tour.createdBy > 0"><small>{{tour.createdBy | adminUserName | async}}</small></span>
			</div>
			<div class="col-6">
				<b>{{source.adminText?.modified}}</b><br>
				{{tour.modified | date:'yyyy-MM-dd HH:mm'}}<br>
				<span *ngIf="tour.modifiedBy > 0"><small>{{tour.modifiedBy | adminUserName | async}}</small></span>
			</div>
		</div>
	</mat-card-content>
</mat-card>
