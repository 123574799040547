<ng-container *ngIf="{
	tour: customerProgramTourService.tour$ | async,
	booking: customerProgramTourService.extendedBooking$ | async,
	order: customerProgramTourService.order$ | async,
	pickup: customerProgramTourService.extendedPickup$ | async,
	specialDiets: customerProgramTourService.specialDiets$ | async,
	language: customerProgramTourService.language$ | async,
	showPriceDetails: customerProgramTourService.showPriceDetails$ | async,
	showInternalMessage: customerProgramTourService.showInternalMessage$ | async,
	showCustomerMessage: customerProgramTourService.showCustomerMessage$ | async,
	showTotalPrice: customerProgramTourService.showTotalPrice$ | async,
	showAddon: customerProgramTourService.showAddon$ | async,
	showPickup: customerProgramTourService.showPickup$ | async,
	showSpecialDiet: customerProgramTourService.showSpecialDiet$ | async,
	adminText: 'customerProgramV2' | adminText: ((customerProgramTourService.language$ | async)?.prefix) | async
} as source">
	<mat-card class="mt-3" *ngIf="source.tour?.id" [class.canceled]="source.booking?.bookingStatusId === 3">
		<mat-card-title class="card-title-small d-flex justify-content-between">
			<span>
				{{source.tour?.name  || (source.tour?.productId | productName | async)}}
				<span *ngIf="source.booking?.bookingStatusId === 3">{{source.adminText?.canceled}}</span>
				<span *ngIf="source.order?.reference" class="ml-3">{{source.adminText?.reference}}: {{source.order?.reference}}</span>
			</span>
			<span>{{source.tour?.date | date:'yyyy-MM-dd'}} {{source.tour?.time}}</span>
		</mat-card-title>

		<div class="row" *ngIf="source.showPickup && source.pickup?.headline">
			<div class="col">
				<mat-card>
					<mat-card-subtitle class="card-title-small">{{source.adminText?.pickup}}</mat-card-subtitle>
					<div>
						{{source.pickup?.headline}}
						<br>
						{{source.adminText?.pickupTime}} {{source.pickup?.relativeTime}}
						<div *ngIf="source.pickup?.text">
							<mat-label class="d-block mt-1 text-black-50">{{source.adminText?.message}}</mat-label>
							<p [innerHtml]="source.pickup?.text"></p>	
						</div>
					</div>
				</mat-card>		
			</div>
		</div>

		<div class="row mt-3" *ngIf="source.booking?.customerTypes?.length">
			<div class="col" *ngFor="let customerType of source.booking?.customerTypes">
				<mat-card class="d-flex flex-column">
					<mat-card-subtitle class="card-title-small">{{customerType.customerTypeId | customerTypeName:source.language?.id | async}}</mat-card-subtitle>
					<div class="d-flex justify-content-between"><span>{{source.adminText?.quantity}} </span><span>{{customerType.quantity}}{{source.adminText?.pct}}</span></div>
					<ng-container *ngIf="source.showPriceDetails">
						<mat-divider [inset]="true"></mat-divider>
						<div class="d-flex justify-content-between"><span>{{source.adminText?.pctPris}} </span><span>{{customerType.price | currency:'SEK':'code'}}</span></div>
						<mat-divider [inset]="true"></mat-divider>
						<div class="d-flex justify-content-between"><span>{{source.adminText?.pickupPrice}} </span><span>{{customerType.pickupPrice | currency:'SEK':'code'}}</span></div>
						<mat-divider [inset]="true"></mat-divider>
						<div class="d-flex justify-content-between"><strong><span>{{source.adminText?.total}} </span></strong><strong><span>{{customerType.totalAmount | currency:'SEK':'code'}}</span></strong></div>
					</ng-container>
					<mat-divider [inset]="true" *ngIf="customerType.customerTypeAges?.length"></mat-divider>
					<div *ngIf="customerType.customerTypeAges?.length">
						<p class="mb-0">{{source.adminText?.ages}}</p>
						<span *ngFor="let age of customerType.customerTypeAges" class="mr-3 d-inline-block">{{age.birthDate | date:'yyyy-MM-dd'}} ({{moment().diff(age.birthDate, 'years', false)}} {{source.adminText?.year}})</span>
					</div>
				</mat-card>
			</div>
		</div>

		<mat-card class="mt-3" *ngIf="source.showAddon && source.booking?.addons?.length">
			<mat-card-subtitle class="card-title-small">{{source.adminText?.addon}}</mat-card-subtitle>
			<div class="d-flex justify-content-between" *ngFor="let addon of source.booking?.addons">
				<span>{{source.adminText?.name}} {{addon.addonId | addonName:source.language?.id | async}}</span>
				<div><span>Antal: </span><span>{{addon.quantity}}{{source.adminText?.pct}}</span></div>
				<ng-container *ngIf="source.showPriceDetails">
					<div><span>{{source.adminText?.pctPris}} </span><span>{{addon.price | currency:'SEK':'code'}}</span></div>
					<div><strong><span>{{source.adminText?.total}} </span><span>{{addon.totalAmount | currency:'SEK':'code'}}</span></strong></div>
				</ng-container>
			</div>
		</mat-card>

		<div class="row mt-3" *ngIf="source.showSpecialDiet && source.specialDiets?.length">
			<div class="col">
				<mat-card>
					<mat-card-subtitle class="card-title-small">{{source.adminText?.specialDiet}}</mat-card-subtitle>
					<div *ngFor="let specialDiet of source.specialDiets; let i = index" class="mb-2">
						<mat-card-subtitle class="m-0">{{specialDiet.customerTypeId | customerTypeName:source.language?.id | async}} {{i+1}}</mat-card-subtitle>
						<div class="ml-3">
							<ng-container *ngFor="let tourBookingSpecialDiet of specialDiet.tourBookingSpecialDiets">
									<span [class.mr-2]="tourBookingSpecialDiet.extraInfo" [class.mr-4]="!tourBookingSpecialDiet.extraInfo">
										{{tourBookingSpecialDiet.specialDietId | specialDietName:source.language?.id | async}}<span *ngIf="tourBookingSpecialDiet.extraInfo">: </span>
									</span>
									<span class="mr-4" *ngIf="tourBookingSpecialDiet.extraInfo" [innerHtml]="tourBookingSpecialDiet.extraInfo | newLine"></span>
							</ng-container>
							<mat-divider [inset]="true" *ngIf="(i+1) !== source.specialDiets?.length"></mat-divider>
						</div>
					</div>
				</mat-card>
			</div>
		</div>

		<div class="row mt-3">
			<div class="col" *ngIf="source.showInternalMessage && source.tour?.tourMessages?.length">
				<mat-card class="h-100">
					<mat-card-subtitle class="card-title-small">{{source.adminText?.internalMessage}}</mat-card-subtitle>
					<div [innerHtml]="source.tour?.tourMessages[0]?.message"></div>
				</mat-card>		
			</div>
			<div class="col" *ngIf="source.showCustomerMessage && source.booking?.message">
				<mat-card class="h-100">
					<mat-card-subtitle class="card-title-small">{{source.adminText?.customerMessage}}</mat-card-subtitle>
					<div [innerHtml]="source.booking?.message"></div>
				</mat-card>		
			</div>
		</div>

		<div class="text-right mt-3" *ngIf="source.showTotalPrice">
			<strong>{{source.adminText?.sum}} {{source.booking?.totalAmount | currency:'SEK':'code'}}</strong>
		</div>
	</mat-card>
</ng-container>
