import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import * as _ from 'lodash';

import { LoginModalService } from '../services/login-modal.service';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { switchMap, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { MenuItemsService } from '../components/side-nav/services/menu-items.service';
import { NavItem } from '../models/nav-item.model';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private loginModalService: LoginModalService,
		private userService: UserService,
		private menuItemsService: MenuItemsService,
		public router: Router,
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// return true;
		console.log('canActivate');
		if (!this.authService.getAuthToken()) {
			console.log('canActivate - logged in');
			this.loginModalService.showLoginInModal();
			return this.loginModalService.loginSuccessReplay$;
		}

		return this.handleAccesOnUser(state.url.split('?')[0]);
	}

	private handleAccesOnUser(url: string): Observable<boolean> {
		return this.menuItemsService.menuItemsReplay$.pipe(switchMap(menuItems => {
			const findItem = this.findActiveItem(menuItems, url);
			if (findItem && findItem.roles) {
				if (this.userService.checkPermission(findItem.roles, 0)) {
					// User has permission
					return of(true);
				} else {
					// User dont have pernmission
					this.loginModalService.showLoginInModal();
					return this.loginModalService.loginSuccessReplay$.pipe(switchMap(loggedIn => {
						return this.handleAccesOnUser(url);
					}));
				}
			}
			return of(true);
		}));
	}

	private findActiveItem(menuItems: NavItem[], url: string): NavItem {
		if (url.length > 1 && url.startsWith('/')) {
			url = url.substring(1);
		}
		return _.find(menuItems, item => item.routeMatch === url);
	}
}
