import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from 'app/admin/models/user.model';
import { AuthService } from 'app/admin/services/auth.service';
import { UserService } from 'app/admin/services/user.service';
import { UserStore } from 'app/admin/stores/user.store.service';
import { of, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserPageService {

	public loggedInUserReplay$ = new ReplaySubject<User>(1);
	public itemForm: FormGroup = new FormGroup({
		firstName: new FormControl('', [Validators.required]),
		lastName: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required]),
		phone: new FormControl('', [Validators.required]),
	});

	constructor(
		public authService: AuthService,
		public userService: UserService,
		public userStore: UserStore,
	) {
		userService.user$.pipe(
			untilDestroyed(this),
			switchMap(userToken => {
				if (userToken?.id) {
					return userStore.getWithServices(userToken.id);
				} else {
					return of(new User());
				}
			}),
		).subscribe(user => {
			this.loggedInUserReplay$.next(user);
			this.itemForm.patchValue(user);
		});
	}

	ngOnInit(): void {}

	public update(): void {

	}
}
