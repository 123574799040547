<form [formGroup]="formBuilderService.itemForm">
	<mat-card-title>Formulär</mat-card-title>

	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Rubrik</mat-label>
		<input matInput formControlName="headline" placeholder="E-post">
	</mat-form-field>

	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Beskrivning</mat-label>
		<textarea matInput formControlName="description" placeholder="Beskrivning"></textarea>
	</mat-form-field>

	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Vart ska fomrulärens data skickas?</mat-label>
		<input matInput formControlName="email" placeholder="E-post">
	</mat-form-field>

	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Text på knappen</mat-label>
		<input matInput formControlName="submitBtn" placeholder="Text på knappen">
	</mat-form-field>
</form>

<mat-card>
	<mat-card-title>Element</mat-card-title>
	<mat-card-subtitle>En lista på alla element som kommer visas i formuläret. Du kan sortera elementen genom att dra de till rätt plats.</mat-card-subtitle>
	<div cdkDropList (cdkDropListDropped)="formBuilderService.drop($event)">
		<div *ngFor="let element of formBuilderService.formElement.formData?.elements">
			<mat-card class="mb-3" cdkDrag>
				<span cdkDragHandle class="move-icon"><mat-icon>drag_indicator</mat-icon></span>		
				<app-form-builder-element cdkDrag [formElement]="element"></app-form-builder-element>
			</mat-card>
		</div>
	</div>
	<button mat-raised-button (click)="formBuilderService.addNewElement()">Lägg till nytt element</button>
</mat-card>
