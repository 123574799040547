import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { TourBookingPickupLocation } from 'app/models/tour-booking-pickup-location.model';
import { PickupLocationStore } from 'app/admin/stores/pickup-location.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-tour-booking-pickup-location-blade-generally',
  templateUrl: './tour-booking-pickup-location-blade-generally.component.html',
  styleUrls: ['./tour-booking-pickup-location-blade-generally.component.scss'],
})
export class TourBookingPickupLocationBladeGenerallyComponent implements OnInit {
	public maxQuantity: number;

	@Input() set item(item: TourBookingPickupLocation) {
		this.maxQuantity = item.quantity;
		this.itemForm.get('quantity').setValue(item.quantity);
		this.itemForm.get('customerTypeId').setValue(item.customerTypeId);
		this.itemForm.get('pickupLocationId').setValue(item.pickupLocationId);
		this.itemForm.get('quantity').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			item.quantity = value;
		});
		this.itemForm.get('customerTypeId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			item.customerTypeId = value;
		});
		this.itemForm.get('pickupLocationId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			item.pickupLocationId = value;
		});
	}

	public itemForm: FormGroup = new FormGroup({
		quantity: new FormControl('', [Validators.required]),
		customerTypeId: new FormControl('', [Validators.required]),
		pickupLocationId: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		public pickupLocationStore: PickupLocationStore,
	) { }

	ngOnInit() {
	}
}
