import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { ProductPickupLocation } from 'app/models/product/product-pickup-location.model';
import { BaseStore } from '../base.store.service';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { PrioBaseStore } from '../prio-base.store.service';

@Injectable({
	providedIn: 'root',
})
export class ProductPickupLocationStore extends PrioBaseStore<ProductPickupLocation> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductPickupLocation', baseUrl, snackBarService);
	}

	public getAllOnProduct(productId: number): Observable<ProductPickupLocation[]> {
		return this.http.get<ProductPickupLocation[]>(`${this.host}/product/${productId}`).pipe(tap(productTexts => {
			this._items = productTexts;
			this._itemsSubject.next(this._items);
		}));
	}
}
