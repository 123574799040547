import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { Element } from 'app/models/element.model';
import { ElementStore } from 'app/admin/stores/element.store.service';
import { ElementService } from 'app/admin/services/element.service';
import { ElementTypeStore } from 'app/admin/stores/element-type.store.service';

@Component({
	selector: 'app-element-blade',
	templateUrl: './element-blade.component.html',
	styleUrls: ['./element-blade.component.scss'],
})
@IBladeComponent.register
export class ElementBladeComponent implements OnInit {
	public item: Element;
	public loading = true;
	public itemForm: FormGroup = new FormGroup({
		elementType: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: ElementStore,
		private bladeService: BladeService,
		private elementService: ElementService,
		public elementTypeStore: ElementTypeStore,
	) {
		this.loading = false;
		this.item = new Element();
		if (!this.bladeItem.payload.columnId) {
			console.error('Missing columnId');
		}
		this.item.columnId = this.bladeItem.payload.columnId;
	}

	ngOnInit() {}

	public save() {
		this.loading = true;
		this.item.elementTypeId = this.itemForm.get('elementType').value;
		this.itemStore.create(this.item).subscribe(item => {
			this.loading = false;
			this.item = _.assign(this.item, item);
			this.openElement();
		});
	}

	private openElement() {
		const component = this.elementService.getElementComponent(this.item.elementTypeIdentifier);

		this.bladeService.addBladeItem({
			id: this.item.id,
			component,
			minClass: BladeSize.m,
		}, this.bladeItem.parent );
	}
}
