import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { CategoryImageType } from 'app/models/category/category-image-type.model';
import { CategoryImageTypeStore } from 'app/admin/stores/category/category-image-type.store.service';
import { CategoryImageTypeBladeComponent } from 'app/admin/blades/category-image-type/category-image-type-blade/category-image-type-blade.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-settings-category-image-type',
	templateUrl: './settings-category-image-type.component.html',
	styleUrls: ['./settings-category-image-type.component.scss'],
})
export class SettingsCategoryImageTypeComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['id', 'name', 'created', 'modified'];
	public dataSource: MatTableDataSource<CategoryImageType>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: CategoryImageTypeStore,
	) {
		this.itemStore.getAll$().pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<CategoryImageType>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: CategoryImageTypeBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: CategoryImageTypeBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}
}
