import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TravelAgencyBladeComponent } from '../travel-agency-blade/travel-agency-blade.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TravelAgencyStore } from 'app/admin/stores/travel-agency.store.service';
import { TravelAgency } from 'app/models/travel-agency.model';
import { FormControl, FormGroup } from '@angular/forms';

@UntilDestroy()
@Component({
	selector: 'app-travel-agencies-blade',
	templateUrl: './travel-agencies-blade.component.html',
	styleUrls: ['./travel-agencies-blade.component.scss'],
})
@IBladeComponent.register
export class TravelAgenciesBladeComponent implements OnInit, OnDestroy {
	private allTravelAgencies: TravelAgency[] = [];
	public searchForm = new FormGroup({
		name: new FormControl(null),
	});

	public displayedColumns: string[] = ['shortName', 'name'];
	public dataSource: MatTableDataSource<TravelAgency>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: TravelAgencyStore,
	) {
		itemStore.getAll();
		itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.allTravelAgencies = items;
			this.setValue(this.allTravelAgencies);
		});

		this.searchForm.get('name').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.setValue(this.allTravelAgencies.filter(x => x.name.toLowerCase().includes((value as string).toLowerCase())));
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		if (this.bladeItem.parent && this.bladeItem.parent.callback) {
			this.bladeItem.parent.callback(row);
			this.bladeItem.bladeItemComponent.closeMe();
		} else {
			this.bladeService.addBladeItem({
				id: row.id,
				component: TravelAgencyBladeComponent,
				minClass: BladeSize.m,
				maxClass: BladeSize.l,
			}, this.bladeItem);
		}
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: TravelAgencyBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	private setValue(items: TravelAgency[]) {
		this.dataSource = new MatTableDataSource<TravelAgency>(items);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
}
