<mat-card *ngIf="{
	errorMessages: productFunctionService.errorMessagesReplay$ | async,
	item: productFunctionService.itemReplay$ | async
} as source">
	<mat-card-title>Meddelanden</mat-card-title>
	<mat-card-subtitle>Skriv en text på alla språk nedan</mat-card-subtitle>

	<mat-card class="mb-3" *ngFor="let errorMessage of source.errorMessages">
		<mat-card-subtitle>{{errorMessage.languageId | languageName | async}}</mat-card-subtitle>
		<mat-form-field class="w-100">
			<mat-label>Meddelande</mat-label>
			<textarea matInput [(ngModel)]="errorMessage.message" placeholder="Meddelande"></textarea>
		</mat-form-field>
	</mat-card>

</mat-card>
