import { Component, OnInit, Inject } from '@angular/core';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { UserType } from 'app/models/user-type.model';
import { UserTypeStore } from 'app/admin/stores/user-type.store.service';

@Component({
	selector: 'app-user-type-blade',
	templateUrl: './user-type-blade.component.html',
	styleUrls: ['./user-type-blade.component.scss'],
})
@IBladeComponent.register
export class UserTypeBladeComponent implements OnInit {

	public loading = true;
	public item: UserType;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		identifier: new FormControl('', [Validators.required]),
	});
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: UserTypeStore,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.itemForm.setValue({name: this.item.name, identifier: this.item.identifier});

				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new UserType();
		}
	 }

	ngOnInit() {
	}

	public createIdentifier() {
		this.itemForm.get('identifier').setValue(_.snakeCase(this.itemForm.get('name').value));
	}

	public save() {
		this.loading = true;
		this.item.name = this.itemForm.get('name').value;
		this.item.identifier = this.itemForm.get('identifier').value;
		this.saveOrUpdate().subscribe(item => {
			Object.assign(this.item, item);
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<UserType> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}
}
