<app-blade-item [canIClose]="!itemForm.dirty" [loading]="loading" [id]="item?.id">
	<app-blade-header [title]="item?.headline||'Ny'" [subTitle]="'ProduktText'"></app-blade-header>
	<app-blade-content *ngIf="item">
		<mat-card>
			<app-alert-card [mode]="'info'">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam placeat
				possimus laborum expedita, nihil fuga id, officia nam repellendus laboriosam aut voluptate est fugiat
				recusandae veniam eaque soluta similique officiis!</app-alert-card>
			<form [formGroup]="itemForm">
				<mat-form-field class="w-100">
					<input matInput formControlName="headline" placeholder="Rubrik">
				</mat-form-field>

				<app-text-editor [size]="'small'" [label]="'Mindra beskrivning, ex till listor'"
					[textModel]="itemForm?.get('information')"></app-text-editor>
				<app-text-editor [size]="'medium'" [label]="'Beskrivning'"
					[textModel]="itemForm?.get('description')"></app-text-editor>
				<app-text-editor [size]="'medium'" [label]="'Text som visas i bekräftelse mejlet till kund'"
					[textModel]="itemForm?.get('emailDescription')"></app-text-editor>
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>SEO rubrik (Rekomenderad längd 50-60)</mat-label>
					<input matInput [formControl]="$any(itemForm?.get('seoTitle'))">
				</mat-form-field>
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>SEO beskrivning (Rekomenderad längd 160-165)</mat-label>
					<input matInput [formControl]="$any(itemForm?.get('seoDescription'))">
				</mat-form-field>
			</form>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>