<ng-container *ngIf="{
	specialDietSummary: specialDietReportService.specialDietSummary$ | async
} as source">
	<div class="main-container">
		<h1>Specialkost rapport</h1>
		<mat-card class="filter-card">
			<mat-card-title>Välj period</mat-card-title>
			<form [formGroup]="specialDietReportService.formGroup">
				<mat-form-field class="w-100">
					<mat-label>Datum</mat-label>
					<mat-date-range-input
						[rangePicker]="campaignOnePicker"
						[comparisonStart]="specialDietReportService.formGroup.value.start"
						[comparisonEnd]="specialDietReportService.formGroup.value.end">
						<input readonly matStartDate placeholder="Från" formControlName="start">
						<input readonly matEndDate placeholder="Till" formControlName="end">
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
					<mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
				</mat-form-field>
				<button mat-stroked-button class="w-100" color="accent" (click)="specialDietReportService.getSpecialDietsByDate()">Visa</button>
			</form>
		</mat-card>

		<app-special-diet-report-on-tour-table></app-special-diet-report-on-tour-table>
		<app-special-diet-report-customer-table></app-special-diet-report-customer-table>
	</div>
</ng-container>
