
<mat-card *ngIf="itemStore.items$ | async as items">
	<mat-card-title>Resurser</mat-card-title>
	<p *ngIf="!items?.length">
		Det finns inga kopplade resurser till den här leverantören.
	</p>
	<mat-accordion multi>
		<mat-expansion-panel *ngFor="let item of items" [expanded]="true">
			<mat-expansion-panel-header>
				<mat-panel-title>
					{{item.supplierResourceTypeId | supplierResourceTypeName | async}}
				</mat-panel-title>
				<mat-panel-description class="justify-content-end">
					{{item.amount}}st
				</mat-panel-description>
			</mat-expansion-panel-header>

			<app-supplier-blade-resource-addon [supplierResource]="item"></app-supplier-blade-resource-addon>

			<div class="mt-3 text-right">
				<button mat-stroked-button (click)="selectItem(item)" color="accent">Redigera</button>
			</div>
		</mat-expansion-panel>

	</mat-accordion>
</mat-card>
