<mat-card>
	<mat-card-title>Användare</mat-card-title>
	<mat-card-content>
		<table mat-table [dataSource]="dataSource" matSort class="w-100">
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
				<td mat-cell *matCellDef="let item">{{item.id}} </td>
			</ng-container>
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
				<td mat-cell *matCellDef="let item">{{item.firstName}} {{item.lastName}}</td>
			</ng-container>
			<ng-container matColumnDef="created">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
				<td mat-cell *matCellDef="let item">{{item.created|date:'yyyy-MM-dd HH:mm'}}<br />{{item.createdBy}}</td>
			</ng-container>
			<ng-container matColumnDef="modified">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Modifierad</th>
				<td mat-cell *matCellDef="let item">{{item.modified|date:'yyyy-MM-dd HH:mm'}}<br />{{item.modifiedBy}}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
		</table>

		<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	</mat-card-content>
</mat-card>
