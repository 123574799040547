
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { EtageClass } from 'app/models/etage-class.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class EtageClassStore extends BaseStore<EtageClass> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'EtageClasses', baseUrl, snackBarService);
	}

	public getAllOnEtage(etageId: number): Observable<EtageClass[]> {
		return this.http.get<EtageClass[]>(`${this.host}/etage/${etageId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public addDelete(categoryId: number, etageClasses: EtageClass[], deleteIds: number[]): Observable<void> {
		const addDeleteObj = {
			etageClasses,
			deleteIds,
		};
		return this.http.post<void>(`${this.host}/addDelete/${categoryId}`, addDeleteObj);
	}
}
