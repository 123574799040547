<app-blade-item [canIClose]="!itemForm.dirty" [loading]="loading" [id]="productExcludeDate?.id">
	<app-blade-header [title]="productExcludeDate?.id ? 'Datum' : 'Nytt datum'" [subTitle]="'Exkludera Produktdatum'"></app-blade-header>
	<app-blade-content>
		<app-alert-card [mode]="'info'">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam placeat possimus laborum expedita, nihil fuga id, officia nam repellendus laboriosam aut voluptate est fugiat recusandae veniam eaque soluta similique officiis!</app-alert-card>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input readonly matInput [matDatepicker]="fromDatePicker" placeholder="Datum att exkludera" formControlName="excludeDate">
				<mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #fromDatePicker disabled="false"></mat-datepicker>
			</mat-form-field>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="productExcludeDate?.id">Spara</span>
			<span *ngIf="!productExcludeDate?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
