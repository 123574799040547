import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { Etage } from 'app/models/site/etage.model';
import { EtageStore } from 'app/admin/stores/etage.store.service';
import { ColumnBladeComponent } from '../../column/column-blade/column-blade.component';

@Component({
	selector: 'app-etage-blade',
	templateUrl: './etage-blade.component.html',
	styleUrls: ['./etage-blade.component.scss'],
})
@IBladeComponent.register
export class EtageBladeComponent implements OnInit {
	public selectedIndex = 1;
	public item: Etage;
	public loading = true;
	public canIClose: Function | boolean;
	public menuItems: BladeMenuItem[] = [
		{ title: 'Generellt', index: 1 },
		{ title: 'Kolumner', index: 2 },
		{ title: 'Style', index: 3 },
		{ title: 'Bakgrund', index: 4 },
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: EtageStore,
		private bladeService: BladeService,
		private bladePositionService: BladePositionService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new Etage();
			if (!this.bladeItem.payload.pageId) {
				console.error('Missing pageId');
			}
			this.item.pageId = this.bladeItem.payload.pageId;
		}
	}

	ngOnInit() {}

	public save() {
		this.loading = true;
		if (this.item.id) {
			this.itemStore.update(this.item).subscribe(item => {
				this.loading = false;
				this.item = _.assign(this.item, item);
			});
		} else {
			this.item.name = this.itemForm.get('name').value;
			this.itemStore.create(this.item).subscribe(item => {
				this.loading = false;
				this.item = _.assign(this.item, item);
				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	public addNewColumn() {
		this.bladeService.addBladeItem({
			id: 0,
			component: ColumnBladeComponent,
			minClass: BladeSize.s,
			payload: {
				etageId: this.bladeItem.id,
			},
		}, this.bladeItem);
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
