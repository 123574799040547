import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';

import { Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';

import { ProductDate } from 'app/models/product/product-date.model';
import { ProductDateStore } from 'app/admin/stores/product/product-date.store.service';
import { ProductDateTime } from 'app/models/product/product-date-time.model';
import { ProductDateTimeStore } from 'app/admin/stores/product/product-date-time.store.service';
import { ProductDateTimeBladeComponent } from '../../poduct-date-time/product-date-time-blade/product-date-time-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';

@UntilDestroy()
@Component({
	selector: 'app-product-date-blade',
	templateUrl: './product-date-blade.component.html',
	styleUrls: ['./product-date-blade.component.scss'],
})
@IBladeComponent.register
export class ProductDateBladeComponent implements OnInit, OnDestroy {
	public loading = true;
	public productDate: ProductDate;
	public displayedColumns: string[] = ['id', 'clock', 'quantity', 'actions'];
	public productDateTimes: MatTableDataSource<ProductDateTime>;
	public currentDates: string[];
	public availableDates: string[];
	public productDates: ProductDate[];

	/**
	 * Work with moment in template
	 */
	public moment = moment;

	public itemForm: FormGroup = new FormGroup({
		fromDate: new FormControl({ value: '' }, [Validators.required]),
		toDate: new FormControl({ value: '' }, [Validators.required]),
		isAllSelected: new FormControl(false),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private productDateStore: ProductDateStore,
		private productDateTimeStore: ProductDateTimeStore,
		private bladeService: BladeService,
	) {
		this.productDateTimeStore.reset();

		if (bladeItem.id) {
			productDateStore.get(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(item => {
				this.productDate = item;
				this.itemForm.get('fromDate').setValue(this.productDate.fromDate);
				this.itemForm.get('toDate').setValue(this.productDate.toDate);

				this.loading = false;

				this.productDateTimeStore.getAllOnProductDate(this.productDate.id);
				this.setListeners();
				this.isAllWeekDaysSelected();
			});
		} else if (bladeItem.payload && bladeItem.payload.productId) {
			this.productDate = new ProductDate();
			this.productDate.productId = bladeItem.payload.productId;
			this.loading = false;
			this.setListeners();

			this.productDate.weekDays = '0,1,2,3,4,5,6';

			this.isAllWeekDaysSelected();
		}
	}

	ngOnInit() {

		this.productDateTimeStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.productDateTimes = new MatTableDataSource<ProductDateTime>(items);
		});
		this.setTakenDates();
	}

	ngOnDestroy(): void { }

	public save() {
		this.loading = true;
		this.saveOrUpdate().subscribe(item => {
			this.productDate = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}

	public selectTime(productDateTime: ProductDateTime) {
		this.bladeService.addBladeItem({
			id: productDateTime.id,
			component: ProductDateTimeBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	public addTime() {
		this.bladeService.addBladeItem({
			id: 0,
			component: ProductDateTimeBladeComponent,
			minClass: BladeSize.xs,
			payload: { productDateId: this.bladeItem.id },
		}, this.bladeItem);
	}

	public deleteTime(productDateTime: ProductDateTime) {
		this.productDateTimeStore.delete(productDateTime.id).subscribe();
	}

	public dateFilter = (date: Date) => {
		const dateStr = moment(date).format('YYYY-MM-DD');
		const isCurrentDate = _.includes(this.currentDates, dateStr);
		if (isCurrentDate) {
			return true;
		}
		return !_.includes(this.availableDates, dateStr);
	}

	public dateClass = (date: Date) => {
		const dateStr = moment(date).format('YYYY-MM-DD');
		const isCurrentDate = _.includes(this.currentDates, dateStr);
		if (isCurrentDate) {
			return 'current-date';
		}
		return _.includes(this.availableDates, dateStr) ? 'unavailable' : undefined;
	}

	public toggleWeekDay(index: number): void {
		let weekDaysList = (this.productDate.weekDays || '').split(',');
		weekDaysList = _.xor(weekDaysList, [index.toString()]);
		this.productDate.weekDays = weekDaysList.sort().join(',');
		if (this.productDate.weekDays.startsWith(',')) {
			// Remove empty string
			this.productDate.weekDays = this.productDate.weekDays.substring(1);
		}
		this.isAllWeekDaysSelected();
	}

	private isAllWeekDaysSelected() {
		let hasAll = true;
		moment.weekdays(true).forEach((element, index) => {
			if (hasAll) {
				hasAll = this.productDate.weekDays.includes(index.toString());
			}
		});
		this.itemForm.get('isAllSelected').setValue(hasAll);
	}

	private setTakenDates() {
		this.productDateStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.productDates = items;
			this.handleDates();
		});
	}

	private handleDates() {
		this.availableDates = [];
		_.each(this.productDates, pDate => {
			this.addDates(new Date(pDate.fromDate), new Date(pDate.toDate), this.availableDates);
		});
	}

	private setCurrentDates() {
		this.currentDates = [];
		if (this.productDate.fromDate && this.productDate.toDate) {
			this.addDates(new Date(this.productDate.fromDate), new Date(this.productDate.toDate), this.currentDates);
		}
	}

	private saveOrUpdate(): Observable<ProductDate> {
		if (this.productDate.id > 0) {
			return this.productDateStore.update(this.productDate);
		} else {
			return this.productDateStore.create(this.productDate);
		}
	}

	private addDates(startDate: Date, stopDate: Date, list: string[]) {
		let currentDate = moment(startDate);
		while (currentDate <= moment(stopDate)) {
			list.push(moment(currentDate).format('YYYY-MM-DD'));
			currentDate = moment(currentDate).add(1, 'days');
		}
	}

	private setListeners() {
		this.itemForm.get('fromDate').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.productDate.fromDate = moment(value).format('YYYY-MM-DD');
			this.setCurrentDates();
		});
		this.itemForm.get('toDate').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.productDate.toDate = moment(value).format('YYYY-MM-DD');
			this.setCurrentDates();
		});
	}

	public toggleAll() {
		const weekDaysList = (this.productDate.weekDays || '').split(',');
		if (weekDaysList?.length === 7) {
			this.productDate.weekDays = '';
		} else {
			this.productDate.weekDays = '0,1,2,3,4,5,6';
		}
	}
}
