import { Component, Inject, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Order } from 'app/models/order.model';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { OrderBladeComponent } from 'app/admin/blades/order/order-blade/order-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { CreataOrderTourBookingBladeComponent } from 'app/admin/blades/order/create-order-tour-booking-blade/create-order-tour-booking-blade.component';
import { CreateOrderModeEnum } from 'app/enums/create-order-mode.enum';

@Component({
	selector: 'app-tour-booking-order',
	templateUrl: './tour-booking-order.component.html',
	styleUrls: ['./tour-booking-order.component.scss'],
})
export class TourBookingOrderComponent {
	@Input() order: Order;

	public orders: Order[];

	public displayedColumns: string[] = ['id', 'customer', 'amount', 'created'];
	public dataSource: MatTableDataSource<Order>;

	constructor(
		private orderStore: OrderStore,
		private bladeService: BladeService,
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
	) {
		this.orderStore.getAllOnTourBooking(this.bladeItem.id).subscribe(orders => {
			this.orders = orders;
			this.dataSource = new MatTableDataSource<Order>(orders);
		});

		this.bladeItem.callback = () => {
			this.orderStore.getAllOnTourBooking(this.bladeItem.id).subscribe(orders => {
				this.orders = orders;
				this.dataSource = new MatTableDataSource<Order>(orders);
			});
		};
	}

	public openOrder(item: Order) {
		window.open('/customer?customerId=' + item.customerId + '&selectedOrder=' + item.id, '_blank');

		// this.bladeService.addBladeItem({
		// 	id: orderId,
		// 	component: OrderBladeComponent,
		// 	minClass: BladeSize.m,
		// 	maxClass: BladeSize.l,
		// }, this.bladeItem);
	}

	public createOrder() {
		this.bladeService.addBladeItem({
			id: this.bladeItem.id,
			component: CreataOrderTourBookingBladeComponent,
			minClass: BladeSize.m,
			payload: { mode: CreateOrderModeEnum.onTourBooking },
		}, this.bladeItem);
	}
}
