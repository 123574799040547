import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-homepage-dashboard',
	templateUrl: './homepage-dashboard.component.html',
	styleUrls: ['./homepage-dashboard.component.scss'],
})
export class HomepageDashboardComponent implements OnInit {

	constructor() { }

	ngOnInit() { }

}
