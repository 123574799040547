import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/admin/services/user.service';
import { UserPageService } from '../../services/user-page.service';

@Component({
  selector: 'app-user-page-service',
  templateUrl: './user-page-service.component.html',
  styleUrls: ['./user-page-service.component.scss']
})
export class UserPageServiceComponent implements OnInit {
	constructor(
		public userPageService: UserPageService,
		public userService: UserService,
	) { }

  	ngOnInit() {}

}
