<p class="mt-3">
	<b>latitude:</b> {{item.longitude || '-'}}, <b>longitude:</b> {{item.latitude || '-'}}
</p>

<aol-map [logo]="true" [width]="width" [height]="height" (onSingleClick)="onSingleClick($event)">
	<aol-interaction-default></aol-interaction-default>
	<aol-view [zoom]="zoom">
		<aol-coordinate [x]="longitude" [y]="latitude" [srid]="'EPSG:4326'"></aol-coordinate>
	</aol-view>
	<aol-layer-tile [opacity]="opacity">
		<aol-source-osm></aol-source-osm>
	</aol-layer-tile>
	<aol-layer-vector [opacity]="opacity">
		<aol-source-vector>
			<aol-feature>
				<aol-geometry-point>
					<aol-coordinate [x]="longitudePointer" [y]="latitudePointer" [srid]="'EPSG:4326'"></aol-coordinate>
				</aol-geometry-point>
				<aol-style>
					<aol-style-circle [radius]="10">
						<aol-style-stroke [color]="[0,0,0,0]" [width]="parseInt(width)"></aol-style-stroke>
						<aol-style-fill [color]="'green'"></aol-style-fill>
					</aol-style-circle>
				</aol-style>
			</aol-feature>
			<aol-feature>
				<aol-geometry-point>
					<aol-coordinate [x]="longitudePointer" [y]="latitudePointer" [srid]="'EPSG:4326'"></aol-coordinate>
				</aol-geometry-point>
				<aol-style>
					<aol-style-icon [src]="markerImage" [anchor]="[0.5, 1]" [anchorXUnits]="'fraction'" [anchorYUnits]="'fraction'" [scale]="0.1"
					 [anchorOrigin]="'top-left'">
					</aol-style-icon>
				</aol-style>
			</aol-feature>
		</aol-source-vector>
	</aol-layer-vector>
	<aol-control>
		<aol-content>
      <div class="ol-overlaycontainer-stopevent">
        <div class="ol-zoom ol-unselectable ol-control">
          <button title="Zooma in" class="ol-zoom-in" type="button" title="Zoom in" (click)="increaseZoom()">+</button>
          <button title="Zooma ut" class="ol-zoom-out" type="button" title="Zoom out" (click)="decreaseZoom()">−</button>
        </div>
        <div *ngIf="currentPosition" class="ol-rotate ol-unselectable ol-control">
          <button title="Sätt där du är" (click)="setCurrentLocation($event)">&#8982;</button>
        </div>
        <div class="ol-attribution ol-unselectable ol-control ol-uncollapsible">
          <ul><li>© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a></li></ul>
        </div>
      </div>
    </aol-content>
  </aol-control>
</aol-map>
<p>{{adressText}}</p>
