import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourBooking } from 'app/models/tour-booking.model';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import { TourBookingCustomerTypeStore } from 'app/admin/stores/tour-booking-customer-type.store.service';

@Component({
	selector: 'app-tour-booking-customer-type-blade',
	templateUrl: './tour-booking-customer-type-blade.component.html',
	styleUrls: ['./tour-booking-customer-type-blade.component.scss'],
})
@IBladeComponent.register
export class TourBookingCustomerTypeBladeComponent implements OnInit {
	public selectedIndex = 1;
	public item: TourBookingCustomerType;
	public loading = true;
	public canIClose: Function | boolean;
	public menuItems: BladeMenuItem[] = [
		{ title: 'Generellt', index: 1 },
		{ title: 'Orderrader', index: 2 },
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: TourBookingCustomerTypeStore,
		private bladeService: BladeService,
		private bladePositionService: BladePositionService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new TourBookingCustomerType();
		}
	}

	ngOnInit() {}

	public save() {
		this.loading = true;
		if (this.item.id) {
			this.itemStore.update(this.item).subscribe(item => {
				this.loading = false;
				this.item = _.assign(this.item, item);
			});
		} else {
			this.itemStore.create(this.item).subscribe(item => {
				this.loading = false;
				this.item = _.assign(this.item, item);
				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
