import { Component, OnInit, Inject, Input, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Supplier } from 'app/models/supplier.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { SupplierResourceStore } from 'app/admin/stores/supplier-resource.store.service';
import { SupplierResource } from 'app/models/supplier-resource.model';
import { SupplierResourceBladeComponent } from 'app/admin/blades/supplier-resource/supplier-resource-blade/supplier-resource-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-supplier-blade-resources',
	templateUrl: './supplier-blade-resources.component.html',
	styleUrls: ['./supplier-blade-resources.component.scss'],
})
export class SupplierBladeResourcesComponent implements OnInit, OnDestroy {
	@Input() item: Supplier;

	public displayedColumns: string[] = ['name', 'amount'];
	public dataSource: MatTableDataSource<SupplierResource>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	// public itemForm: FormGroup = new FormGroup({
	// 	name: new FormControl('', [Validators.required])
	// });

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: SupplierResourceStore,
	) {
		this.itemStore.reset();
	}

	ngOnInit() {
		this.itemStore.getAllOnSupplier(this.item.id).pipe(untilDestroyed(this)).subscribe();
	}

	ngOnDestroy(): void {}

	public selectItem(item: SupplierResource) {
		this.bladeService.addBladeItem({
			id: item.id,
			component: SupplierResourceBladeComponent,
			minClass: BladeSize.xs,
			payload: this.item.id,
		}, this.bladeItem);
	}
}
