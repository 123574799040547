import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBooking } from 'app/models/tour-booking.model';
import { Tour } from 'app/models/tour.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import _find from 'lodash-es/find';
import { CustomerStore } from 'app/modules/customer/stores/customer.store';
import { Settings } from 'app/models/site/settings.model';
import { CustomerProgramPageService } from './services/customer-program-page.service';

@UntilDestroy()
@Component({
	selector: 'app-customer-program',
	templateUrl: './customer-program.component.html',
	styleUrls: ['./customer-program.component.scss'],
	providers: [
		CustomerStore,
		CustomerProgramPageService,
	],
})
export class CustomerProgramPage implements OnInit {
	// public siteSettings$ = new ReplaySubject<Settings>(1);

  	constructor(
		public customerProgramPageService: CustomerProgramPageService,
	) { }

	ngOnInit() {
		// combineLatest([
		// 	this.route.queryParams,
		// 	this.pickupLocationStore.items$,
		// ]).pipe(untilDestroyed(this)).subscribe(([params, pickupLocations]) => {
		// 	if (params?.languageId && params?.customerId) {
		// 		this.customerStore.get(params?.customerId).pipe(untilDestroyed(this)).subscribe(customer => this.customer$.next(customer));
		// 		this.getTours(params.customerId, params.fromDate, params.toDate)
		// 		.pipe(untilDestroyed(this))
		// 			.subscribe(allToursOnCustomer => {
		// 				allToursOnCustomer.filter(x => x.isActive).forEach(tour => {
		// 					this.tourBookingStore.getAllOnCustomerAndTour(params?.customerId, tour.id).pipe(untilDestroyed(this)).subscribe(tourBookings => {
		// 						const extendedTour = tour as ExtendedTour;
		// 						if (params?.tourBookings?.length) {
		// 							const tourBookingsToInclude = (params?.tourBookings as string).split(',');
		// 							tourBookings = tourBookings.filter(x => tourBookingsToInclude.includes(x.id + ''));
		// 						}
								
		// 						if (!tourBookings.length) {
		// 							return;
		// 						}

		// 						if (tour.productId) {
		// 							this.productTextStore.getAllOnProduct(tour.productId).pipe(untilDestroyed(this)).subscribe(productTexts => {
		// 								if (productTexts?.length) {
		// 									extendedTour.message = productTexts.find(x => x.languageId === +params?.languageId)?.emailDescription;
		// 								}
		// 							});
		// 						}
		// 						extendedTour.tourBookings = tourBookings;
		// 						extendedTour.tourBookings.forEach(tourBooking => {
		// 							this.orderStore.getAllOnTourBooking(tourBooking.id).subscribe(orders => {
		// 								const referenceOrder = _.find(orders, o => !!o.reference);
		// 								if (referenceOrder) {
		// 									extendedTour.reference = referenceOrder.reference;
		// 								}
		// 							});
		// 							this.tourBookingMessageStore.getAllOnTourBooking(tourBooking.id).pipe().subscribe(message => {
		// 								let tourBookingsMessage = '';
		// 								if (message?.[0]?.message) {
		// 									tourBookingsMessage = message[0].message;
		// 								}
		// 								tourBooking.tourBookingCustomerTypes.forEach(customerType => {
		// 									this.addCustomerType(extendedTour, customerType.customerTypeId, customerType.quantity, 0, 0, tourBookingsMessage);
		// 								});
		// 							});
		// 							tourBooking.tourBookingPickupLocations.forEach(pickup => {
		// 								if (tour.productId) {
		// 									this.productPickupLocationStore.getAllOnProduct(tour.productId).pipe(untilDestroyed(this)).subscribe(productPickups => {
		// 										const find = productPickups?.find(x => x.pickupLocationId === pickup.pickupLocationId);
		// 										const findOriginalPickupLocation = pickupLocations.find(x => x.id === pickup.pickupLocationId);
		// 										const relativeTime = find?.relativeTime ?? findOriginalPickupLocation?.relativeTime ?? 0;
		// 										this.addCustomerType(extendedTour, pickup.customerTypeId, 0, pickup.pickupLocationId, relativeTime);
		// 									});
		// 								}
		// 								this.addCustomerType(extendedTour, pickup.customerTypeId, 0, pickup.pickupLocationId);
		// 							});
		// 						});
		// 						this.extendedTours.push(extendedTour);
		// 						this.extendedTours$.next(_.sortBy(this.extendedTours, 'date', 'time'));
		// 					});
		// 				});
		// 			});
		// 	}
		// });
  	}

	// private addCustomerType(
	// 	extendedTour: ExtendedTour, customerTypeId: number, quantity: number, pickupLocationId?: number, relativeTime?: number, tourBookingMessage?: string,
	// ) {
	// 	if (!extendedTour.summaries) {
	// 		extendedTour.summaries = [];
	// 	}
	// 	let findSummary = extendedTour.summaries.find(x => x.customerTypeId === customerTypeId);
	// 	if (!findSummary) {
	// 		findSummary = {
	// 			pickupLocationId,
	// 			customerTypeId,
	// 			quantity: 0,
	// 			tourBookingMessage,
	// 		};

	// 		extendedTour.summaries.push(findSummary);
	// 	}

	// 	findSummary.quantity += quantity;
	// 	if (pickupLocationId) {
	// 		findSummary.pickupLocationId = pickupLocationId;
	// 	}

	// 	const tourTime = moment(`${moment(extendedTour.date).format('YYYY-MM-DD')} ${extendedTour.time}`);
	// 	findSummary.relativeTime = tourTime.add(relativeTime, 'minute').format('HH:mm');

	// 	if (tourBookingMessage) {
	// 		findSummary.tourBookingMessage = tourBookingMessage;
	// 	}
	// 	setTimeout(() => {
	// 		this.cdr.markForCheck();
	// 	}, 500);
	// }

	// private getTours(customerId: number, fromDate: string, toDate: string): Observable<Tour[]> {
	// 	if (fromDate && toDate) {
	// 		return this.tourStore.getAllOnCustomerBetweenDates(customerId, fromDate, toDate);
	// 	} else {
	// 		return this.tourStore.getAllOnCustomer(customerId);
	// 	}
	// }
}

interface ExtendedTour extends Tour {
	tourBookings: TourBooking[];
	message: string;
	summaries: ExtendedTourSummary[];
	reference?: string;
}

interface ExtendedTourSummary {
	pickupLocationId?: number;
	relativeTime?: string;
	customerTypeId: number;
	quantity: number;
	tourBookingMessage: string;
}
