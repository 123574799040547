import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Angular Material
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

const material = [
	MatIconModule,
	MatDialogModule,
	MatInputModule,
	MatButtonModule,
	MatCardModule,
	MatSnackBarModule,
	MatTableModule,
	MatPaginatorModule,
	MatSortModule,
];

// Serives and Stores
import { EmailQueueStore } from './stores/email-queue.store';

const services = [
	EmailQueueStore,
];

// Components
import { EmailQueueComponent } from './components/email-queue/email-queue.component';
import { PipeModule } from 'app/admin/pipes/pipe.module';

const components = [
	EmailQueueComponent,

]

@NgModule({
	declarations: [
		...components,
	],
	imports: [
		CommonModule,
		PipeModule,
		...material,
  	],
	exports: [
		...components,
	],
	providers: [
		...services,
	]
})
export class EmailModule { }
