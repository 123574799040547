import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import _find from 'lodash-es/find';

import { BaseStore } from './base.store.service';
import { Order } from '../../models/order.model';
import { SnackBarService } from '../services/snack-bar.service';
import { CreateOrder } from 'app/models/order/CreateOrder';
import { PaymentBalance } from 'app/models/paymanet-balance.model';
import { OrderRestWidget } from 'app/models/order-rest-widget.model';

@Injectable({
	providedIn: 'root',
})
export class OrderStore extends BaseStore<Order> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Order', baseUrl, snackBarService);
	}
	public getAllOnCustomer(customerId: number): Observable<Order[]> {
		return this.http.get<Order[]>(`${this.host}/customer/${customerId}`).pipe(tap(orders => {
			this._items = orders;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnTourBooking(tourBookingId: number): Observable<Order[]> {
		return this.http.get<Order[]>(`${this.host}/tourBooking/${tourBookingId}`).pipe(tap(orders => {
			this._items = orders;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllFromDate(date: string): Observable<Order[]> {
		return this.http.get<Order[]>(`${this.host}/all/date/${date}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public createOnTourBooking(tourBookingId: number, customerId: number, createOrder: CreateOrder) {
		return this.http.put<Order>(`${this.host}/tourBooking/${tourBookingId}/customer/${customerId}`, createOrder).pipe(tap(item => {
			this._items.push(item);
			this._itemsSubject.next(this._items);
		}));
	}

	public createOnTourWithTourBooking(tourId: number, customerId: number, createOrder: CreateOrder) {
		return this.http.put<Order>(`${this.host}/tour/${tourId}/customer/${customerId}`, createOrder).pipe(tap(item => {
			this._items.push(item);
			this._itemsSubject.next(this._items);
		}));
	}

	public createPlainOrder(customerId: number, createOrder: CreateOrder) {
		return this.http.put<Order>(`${this.host}/customer/${customerId}`, createOrder).pipe(tap(item => {
			const find = _find(this._items, x => x.id === item.id);
			if (!find) {
				this._items.push(item);
				this._itemsSubject.next(this._items);
			}
		}));
	}

	public getPaymentBalanceOnOrder(orderId: number) {
		return this.http.get<PaymentBalance>(`${this.host}/payment/balance/order/${orderId}`);
	}

	public getAllWithBadBalance(fromDate: string, toDate: string) {
		return this.http.get<OrderRestWidget[]>(`${this.host}/widget/rest/from/${fromDate}/to/${toDate}`);
	}

	public getAllWithRest(fromDate: string, toDate: string, selectedPaymentTypes: number[]): Observable<OrderRestWidget[]> {
		return this.http.post<OrderRestWidget[]>(`${this.host}/widget/rest`, {
			fromDate,
			toDate,
			selectedPaymentTypes,
		});
	}

	public getAllDraft() {
		return this.http.get<Order[]>(`${this.host}/draft`);
	}

	public getAllOnTravelAgency(travelAgencyId: number): Observable<Order[]> {
		return this.http.get<Order[]>(`${this.host}/travelAgency/${travelAgencyId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public searchByIdOrReference(freeText: string): Observable<Order[]> {
		return this.http.post<Order[]>(`${this.host}/search/id/reference`, {freeText}).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}
}
