<ng-container *ngIf="{
	loggedInUser: userPageService.loggedInUserReplay$ | async
} as source">
	<div class="container pt-5" *ngIf="source.loggedInUser?.id">
		<h1>Profilsida för {{source.loggedInUser?.firstName}} {{source.loggedInUser?.lastName}}</h1>
		<div class="row">
			<div class="col-md-6">
				<app-user-page-account></app-user-page-account>
				<app-user-page-service class="d-block mt-4"></app-user-page-service>
			</div>
			<div class="col-md-6">
				<app-user-page-password></app-user-page-password>
				<mat-card class="mt-4">
					<mat-card-title>Logga ut</mat-card-title>
					<mat-card-subtitle>Försök att alltid lägga ut. Detta ökar säkerheten</mat-card-subtitle>
					<div class="text-right">
						<button mat-raised-button color="primary" (click)="userPageService.authService.logout()">
							Logga ut
						</button>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
</ng-container>
