import { Component, OnInit, Inject, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { Category } from 'app/models/category/category.model';
import { CategoryCategory } from 'app/models/category/category-category.model';
import { CategoryStore } from 'app/admin/stores/category/category.store.service';
import { CategoryCategoryStore } from 'app/admin/stores/category/category-category.store.service';
import { ElementClass } from 'app/models/element-class.model';
import { ElementClassStore } from 'app/admin/stores/element-class.store.service';
import { ElementStyleStore } from 'app/admin/stores/element-style.store.service';
import { ElementStyle } from 'app/models/element-style.model';

@UntilDestroy()
@Component({
	selector: 'app-element-blade-styles',
	templateUrl: './element-blade-styles.component.html',
	styleUrls: ['./element-blade-styles.component.scss'],
})
export class ElementBladeStylesComponent implements OnInit, OnDestroy {
	public editorOptions = {theme: 'vs', language: 'json'};

	public code = '';
	private elementStyle: ElementStyle;

	private saveSubject: Subject<boolean>;
	private pristineElementClasses: ElementClass[];
	private elementClasses: ElementClass[];

	public className: string;
	public displayedColumns: string[] = ['id', 'name', 'actions'];
	public dataSource: MatTableDataSource<ElementClass>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@Input() set saving(subject: Subject<boolean>) {
		if (subject) {
			if (this.saveSubject) {
				this.saveSubject.unsubscribe();
			}
			this.saveSubject = subject;
			subject.pipe(untilDestroyed(this)).subscribe(() => {
				this.doSave();
			});
		} else if (this.saveSubject) {
			this.saveSubject.unsubscribe();
		}
	}

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public elementClassStore: ElementClassStore,
		public elementStyleStore: ElementStyleStore,
		private snackBarService: SnackBarService,
	) {
		this.elementClassStore.getAllOnElement(this.bladeItem.id).subscribe();
		this.elementClassStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.pristineElementClasses = _.cloneDeep(items);
			this.elementClasses = items;
			this.addToDataSource();
		});

		this.elementStyleStore.getOnElement(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(elementStyle => {
			this.elementStyle = elementStyle;
			if (!this.elementStyle) {
				this.elementStyle = new ElementStyle();
				this.elementStyle.elementId = this.bladeItem.id;
			}
			this.code = elementStyle.style;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {}

	public addElementClass(): void {
		if (this.className) {
			const elementClass = new ElementClass();
			elementClass.class = this.className;
			elementClass.elementId = this.bladeItem.id;
			if (!this.elementClasses) {
				this.elementClasses = [];
			}
			this.elementClasses.push(elementClass);
			this.className = '';
			this.addToDataSource();
		}
	}

	public deleteElementClass(elementClass: ElementClass) {
		_.remove(this.elementClasses, elementClass);
		this.addToDataSource();
	}

	public saveStyle() {
		this.elementStyle.style = this.code;
		if (this.elementStyle.id) {
			this.elementStyleStore.update(this.elementStyle).subscribe(elementStyle => {
				this.elementStyle = elementStyle;
			});
		} else {
			this.elementStyleStore.create(this.elementStyle).subscribe(elementStyle => {
				this.elementStyle = elementStyle;
			});
		}
	}

	private addToDataSource() {
		this.dataSource = new MatTableDataSource<ElementClass>(this.elementClasses);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	private doSave() {
		const add: ElementClass[] = _.differenceWith(this.elementClasses, this.pristineElementClasses, (a, b) => a.id === b.id);

		const removed = _.differenceWith(this.pristineElementClasses, this.elementClasses, (a, b) => a.id === b.id);
		console.log(add, removed);
		if (add || removed) {
			this.elementClassStore.addDelete(this.bladeItem.id, add, _.map(removed, 'id')).subscribe(() => {
				this.elementClassStore.getAllOnElement(this.bladeItem.id).subscribe();
				this.snackBarService.showSuccess('Kopplingarna har sparats!');
			});
		}
	}
}
