import { Component, Input, OnInit } from '@angular/core';
import { OrderPayment } from 'app/models/order-payment.model';

@Component({
  selector: 'app-payment-nets',
  templateUrl: './payment-nets.component.html',
  styleUrls: ['./payment-nets.component.scss']
})
export class PaymentNetsComponent implements OnInit {

	@Input() item: OrderPayment;

  	constructor() { }

	ngOnInit() {
  	}

}
