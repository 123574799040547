<app-blade-item [canIClose]="true">
    <app-blade-header [title]="'Specialkost'"></app-blade-header>
    <app-blade-content>
		<div class="mat-elevation-z1">
			<table mat-table [dataSource]="dataSource" matSort class="w-100">
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
					<td mat-cell *matCellDef="let item"><strong>{{item.name}}</strong><br /><em>{{item.identifier}}</em></td>
				</ng-container>
				<ng-container matColumnDef="icon">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Ikon</th>
					<td mat-cell *matCellDef="let item">
						<span [icon]="item.icon"></span></td>
				</ng-container>
				<ng-container matColumnDef="isActive">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Aktiv</th>
					<td mat-cell *matCellDef="let item">
						<mat-icon *ngIf="item.isActive">check_box</mat-icon>
						<mat-icon *ngIf="!item.isActive">check_box_outline_blank</mat-icon>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.id === item?.id"></tr>
			</table>

			<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</div>
    </app-blade-content>
    <app-blade-footer>
        <span fxFlex></span>
        <button mat-raised-button color="primary" (click)="createNew()">Skapa ny</button>
     </app-blade-footer>
 </app-blade-item>
