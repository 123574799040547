import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TermStore } from 'app/admin/stores/term.store.service';
import { TermBladeService } from './service/term-blade.service';

@Component({
	selector: 'app-term-blade',
	templateUrl: './term-blade.component.html',
	styleUrls: ['./term-blade.component.scss'],
	providers: [TermBladeService],
})
@IBladeComponent.register
export class TermBladeComponent implements OnInit {
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: TermStore,
		public termBladeService: TermBladeService,
	) { }

	ngOnInit() { }
}
