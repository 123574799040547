import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { BaseStore } from '../base.store.service';
import { ProductPrice } from 'app/models/product/product-price.model';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class ProductPriceStore extends BaseStore<ProductPrice> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductPrices', baseUrl, snackBarService);
	}

	public getAllOnProduct(productId: number): Observable<ProductPrice[]> {
		return this.http.get<ProductPrice[]>(`${this.host}/product/${productId}`).pipe(tap(productPrices => {
			this._items = productPrices;
			this._itemsSubject.next(this._items);
		}));
	}

	public getOnProductAndCustomerType(productId: number, customerTypeId: number): Observable<ProductPrice> {
		return this.http.get<ProductPrice>(`${this.host}/product/${productId}/customer/${customerTypeId}`).pipe(tap(productPrice => {
			if (productPrice) {
				const findObj = _.find(this._items, type => type.productId === productPrice.productId
									&& type.customerTypeId === productPrice.customerTypeId);
				_.assign(findObj, productPrice); // Keep object in sync
			}
		}));
	}

	public addProductPrice(productText: ProductPrice) {
		this._items.push(productText);
		this._itemsSubject.next(this._items);
	}

	public removeProductPrice(productText: ProductPrice) {
		_.remove(this._items, item => item.customerTypeId === productText.customerTypeId && !item.price);
		this._itemsSubject.next(this._items);
	}

	public getOnProductAndCustomerTypeAndDateTime(productId: number, customerTypeId: number, date: Date, time: string): Observable<ProductPrice> {
		return this.http.get<ProductPrice>(`${this.host}/product/${productId}/customerType/${customerTypeId}/date/${moment(date).format('YYYY-MM-DD')}/time/${time}`);
	}
}
