import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { SpecialDietBladeComponent } from '../special-diet-blade/special-diet-blade.component';
import { SpecialDiet } from 'app/models/special-diet.model';
import { SpecialDietStore } from 'app/admin/stores/special-diet.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BladeModalData } from 'app/admin/components/blade/models/bladeModalData.model';

@UntilDestroy()
@Component({
	selector: 'app-special-diets-blade',
	templateUrl: './special-diets-blade.component.html',
	styleUrls: ['./special-diets-blade.component.scss'],
})
@IBladeComponent.register
export class SpecialDietsBladeComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['name', 'icon', 'isActive'];
	public dataSource: MatTableDataSource<SpecialDiet>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: SpecialDietStore,
	) {
		this.itemStore.getAll();
		this.itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<SpecialDiet>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: SpecialDietBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: SpecialDietBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}
}
