import { Component, Inject, OnDestroy } from '@angular/core';

import * as _ from 'lodash';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SelectProductToOrderBladeService } from './services/create-order-tour-booking-blade.service';
import { CreateOrderModeEnum } from 'app/enums/create-order-mode.enum';
import { Product } from 'app/models/product/product.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { ReplaySubject } from 'rxjs';
import { Supplier } from 'app/models/supplier.model';

@UntilDestroy()
@Component({
	selector: 'app-select-product-to-order-blade',
	templateUrl: './select-product-to-order-blade.component.html',
	styleUrls: ['./select-product-to-order-blade.component.scss'],
	providers: [SelectProductToOrderBladeService],
})
export class SelectProductToOrderBladeComponent implements OnDestroy {
	public filterProducts$ = new ReplaySubject<Product[]>(1);
	private products: Product[];
	public filterSuppliers$ = new ReplaySubject<Supplier[]>(1);
	private suppliers: Supplier[];
	public todayDate = new Date();
	public CreateOrderModeEnum = CreateOrderModeEnum;
	public bladeTitle: string;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public selectProductToOrderBladeService: SelectProductToOrderBladeService,
		private bladeService: BladeService,
		public supplierStore: SupplierStore,
		public productStore: ProductStore,
	) {
		if (this.bladeItem.payload.mode === CreateOrderModeEnum.newOrder) {
			this.bladeTitle = 'Välj produkt och antal till order';
		} else if (this.bladeItem.payload.mode === CreateOrderModeEnum.onTour) {
			this.bladeTitle = 'Välj antal till bokningen';
		} else if (this.bladeItem.payload.mode === CreateOrderModeEnum.onTourBooking) {
			this.bladeTitle = 'Välj antal til turen';
		}

		this.productStore.items$.pipe(untilDestroyed(this)).subscribe(products => {
			this.products = products;
			this.filterProducts();
		});

		this.supplierStore.items$.pipe(untilDestroyed(this)).subscribe(suppliers => {
			this.suppliers = suppliers;
			this.filterSuppliers();
		});

		this.selectProductToOrderBladeService.itemFilterForm.get('supplierId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			if (isNaN(value)) {
				this.filterSuppliers(value);
			} else {
				this.selectProductToOrderBladeService.setSupplier(value);
			}
		});

		this.selectProductToOrderBladeService.itemFilterForm.get('productId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			if (isNaN(value)) {
				this.filterProducts(value);
			} else {
				this.selectProductToOrderBladeService.setProduct(value);
				const selectedProduct = this.products?.find(x => x.id === value);
				if (selectedProduct?.supplierID) {
					this.selectProductToOrderBladeService.itemFilterForm.get('supplierId').setValue(selectedProduct?.supplierID);
				}
			}
		});

	}

	ngOnDestroy(): void { }

	public select() {
		this.selectProductToOrderBladeService.select();
	}

	public displayProductFn(id): string {
		if (!id) {
			 return '';
		}
		const selectedProduct = _.find(this.products, product => product.id === id);
		return selectedProduct.name;
	}

	public displaySupplierFn(id): string {
		if (!id) {
			 return '';
		}
		const selectedSupplier = _.find(this.suppliers, supplier => supplier.id === id);
		return selectedSupplier.name;
	}

	private filterProducts(value?: string): void {
		const filterValue = value?.toLowerCase();
		let filterProducts = this.products;
		if (filterValue) {
			filterProducts = _.filter(this.products, x => x.name.toLocaleLowerCase().includes(filterValue));
		}
		this.filterProducts$.next(_.orderBy(filterProducts, ['name'], ['asc']));
	}

	private filterSuppliers(value?: string): void {
		const filterValue = value?.toLowerCase();
		let filterSuppliers = this.suppliers;
		if (filterValue) {
			filterSuppliers = _.filter(this.suppliers, x => x.name.toLocaleLowerCase().includes(filterValue));
		}
		this.filterSuppliers$.next(_.orderBy(filterSuppliers, ['name'], ['asc']));
	}

}
