import { Component, OnInit, Inject } from '@angular/core';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { Product } from 'app/models/product/product.model';
import { ProductTypeStore } from 'app/admin/stores/product/product-type.store.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import * as _ from 'lodash';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { Subject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-product-blade',
	templateUrl: './product-blade.component.html',
	styleUrls: ['./product-blade.component.scss'],
})
@IBladeComponent.register
export class ProductBladeComponent implements OnInit {
	public selectedIndex = 0;
	public saveCategorySubject = new Subject<void>();
	public saveSettingsSubject = new Subject<void>();
	public saveAddonSubject = new Subject<void>();
	public saveSirvoySubject = new Subject<void>();
	public item: Product;
	public loading = true;
	public canIClose: () => {} | boolean;
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		type: new FormControl('', [Validators.required]),
		supplierId: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: ProductStore,
		public productTypeStore: ProductTypeStore,
		private bladePositionService: BladePositionService,
		public supplierStore: SupplierStore,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.loading = false;
				this.itemForm.patchValue(this.item);
			});
		} else {
			this.loading = false;
			this.item = new Product();
		}

	}

	ngOnInit() {}

	public save() {
		if (this.item.id) {
			if (this.selectedIndex === 9) {
				this.saveSirvoySubject.next();
			} else if (this.selectedIndex === 7) {
				this.saveCategorySubject.next();
			} else if (this.selectedIndex === 6) {
				this.saveAddonSubject.next();
			} else {
				this.loading = true;
				this.itemStore.update(this.item).subscribe(product => {
					this.loading = false;
					this.item = _.assign(this.item, product);
				});
			}
		} else {
			this.loading = true;
			this.item.name = this.itemForm.get('name').value;
			this.item.productTypeID = this.itemForm.get('type').value;
			this.item.supplierID = this.itemForm.get('supplierId').value.id;
			this.itemStore.create(this.item).subscribe(product => {
				this.loading = false;
				this.item = _.assign(this.item, product);
				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
