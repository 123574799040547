import { BaseModel } from '../base.model';
import { Category } from './category.model';
import { Product } from '../product/product.model';

export class CategoryProduct extends BaseModel {
	public categoryId: number;
	public productId: number;
	public prioId: number;
	public category: Category;
	public product: Product;
}
