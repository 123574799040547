<mat-card class="mb-3" *ngIf="{
	pickupLocationOrders: selectProductToOrderBladeService.pickupLocationOrdersReplay$ | async
} as source">
	<mat-card-title class="card-title-small">Hämtningsplats</mat-card-title>
	<mat-form-field class="w-100 pr-2" appearance="outline" *ngIf="source.pickupLocationOrders?.length">
		<mat-label>Välj hämtningsplats kopplad på produkten</mat-label>
		<mat-select (valueChange)="setPickupLocation($event)" [value]="source.pickupLocationOrders[0]?.pickupLocationId">
			<mat-option disabled [value]="0">Välj plats</mat-option>
			<mat-option *ngFor="let pickupLocation of pickupLocations$ | async" [value]="pickupLocation.id">
				{{pickupLocation.name}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-table [dataSource]="source.pickupLocationOrders" class="w-100">
		<ng-container matColumnDef="type">
			<mat-header-cell *matHeaderCellDef>Kund-typ</mat-header-cell>
			<mat-cell *matCellDef="let item" [class.text-line-through]="item.avaiableQuantity === 0">{{item.customerTypeId | customerTypeName | async}} </mat-cell>
		</ng-container>
		<ng-container matColumnDef="quantity">
			<mat-header-cell *matHeaderCellDef>Antal</mat-header-cell>
			<mat-cell *matCellDef="let item">
				<mat-form-field class="w-100 pr-2">
					<input type="number" min="0" matInput [(ngModel)]="item.quantity" [disabled]="item.avaiableQuantity === 0">
				</mat-form-field>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="unitPrice">
			<mat-header-cell *matHeaderCellDef>á pris</mat-header-cell>
			<mat-cell *matCellDef="let item">
				<mat-form-field class="w-100">
					<input type="number" min="0" matInput [(ngModel)]="item.unitPrice" [disabled]="item.avaiableQuantity === 0 || userService.isTravelAgencyUser() || !item.pickupLocationId">
				</mat-form-field>
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let item; columns: displayedColumns;"></mat-row>
	</mat-table>
</mat-card>
