<app-blade-item [canIClose]="!itemForm.dirty" [loading]="loading" [id]="item?.id">
	<app-blade-header [title]="(item?.id) ? 'Upphämtningsplats' : 'Ny'" [subTitle]="'Produkt'"></app-blade-header>
	<app-blade-content *ngIf="item">
		<h3>Upphämtnings plats för {{item.productId | productName | async}}</h3>
		<app-alert-card [mode]="'info'">Ange vilken upphämtningsplats som ska kopplas till produkten. Du har oxå möjligheten att skriva över tiden för upphämtningsplatsen bara för den här kopplingen.</app-alert-card>
		<p>Vald upphämtningsplats <b>{{item.pickupLocationId | pickupLocationName | async}}</b></p>

		<mat-card>
			<mat-card-subtitle>Relativ tid</mat-card-subtitle>
			<form [formGroup]="itemForm">
				<mat-form-field class="w-100">
					<input matInput formControlName="relativeTime" placeholder="Relativ tid i minuter till turen">
					<mat-hint>Ange bara tid om den skilljer sig från orginalet.</mat-hint>
				</mat-form-field>
			</form>
		</mat-card>

		<mat-card class="mt-3">
			<mat-card-subtitle>Priser</mat-card-subtitle>
			<app-product-pickup-location-price-edit
				class="mb-3 d-block"
				*ngFor="let customerType of customerTypeStore.items$ | async"
				[pickupLocationPrices]="pickupLocationPricesReplay$ | async"
				[productPickupLocationPrices]="productPickupLocationPricesReplay$ | async"
				[customerType]="customerType"
				[save]="saveReplay$">
			</app-product-pickup-location-price-edit>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
