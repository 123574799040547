
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { SupplierResourceAddon } from 'app/models/supplier-resource-addon.model';
import { Behavior } from 'popper.js';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SupplierResourceAddonStore extends BaseStore<SupplierResourceAddon> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SupplierResourceAddon', baseUrl, snackBarService);
	}

	public getAllOnAddon(addonId: number): Observable<SupplierResourceAddon[]> {
		return this.http.get<SupplierResourceAddon[]>(`${this.host}/addon/${addonId}`);
	}

	public getAllOnResourceSupplier(resourceSupplierId: number): Observable<SupplierResourceAddon[]> {
		return this.http.get<SupplierResourceAddon[]>(`${this.host}/resourceSupplier/${resourceSupplierId}`)
	}
}
