import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DeletedEntityStore } from 'app/admin/stores/deleted-entity.store.service';
import { Observable, of } from 'rxjs';
import { DeletedEntity } from '../../../../../../models/deleted-entity.model';
import { switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'app-settings-trashcan',
	templateUrl: './settings-trashcan.component.html',
	styleUrls: ['./settings-trashcan.component.scss'],
})
export class SettingsTrashcanComponent implements OnInit, OnDestroy {
	public items$: Observable<DeletedEntity[]>;
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public itemStore: DeletedEntityStore,
	) {
		this.itemStore.reset();
		this.itemStore.getAll();
		this.items$ = this.itemStore.items$.pipe(switchMap(x => {
			x.forEach(y => y.entityJson = JSON.parse(y.entity));
			return of(x);
		}));
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public restore(entity: DeletedEntity) {
		this.itemStore.restore(entity.id).subscribe(isRemoved => {
			if (isRemoved) {
				this.itemStore.getAll();
			}
		});
	}
}
