import { Injectable, Inject, OnDestroy } from '@angular/core';

import { combineLatest, of, forkJoin, BehaviorSubject, ReplaySubject, EMPTY, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { OrderItemStore } from 'app/admin/stores/order-item.store.service';
import { TourBookingAddonStore } from 'app/admin/stores/tour-booking-addon.store.service';
import { TourBookingPickupLocationStore } from 'app/admin/stores/tour-booking-pickup-location.store.service';
import { OrderWrapper } from '../models/order-wrapper.model';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { CustomerStore } from 'app/admin/stores/customer.store.service';
import { Customer } from 'app/models/customer.model';
import { OrderItemTypeEnum } from '../enums/order-item-type.enum';
import { OrderItem } from 'app/models/order-item.model';
import { AddonOrder } from '../models/addon-order.model';
import { CustomerTypeOrder } from '../models/customer-type-order.model';
import { PickupLocationOrder } from '../models/pickup-location-order.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { CreateOrderModeEnum } from 'app/enums/create-order-mode.enum';
import { Product } from 'app/models/product/product.model';
import { ProductDateTime } from 'app/models/product/product-date-time.model';
import { ProductPrice } from 'app/models/product/product-price.model';
import { ProductDateStore } from 'app/admin/stores/product/product-date.store.service';
import * as moment from 'moment';
import { ProductDate } from 'app/models/product/product-date.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from 'app/admin/services/user.service';
import { User } from 'app/admin/models/user.model';
import { ProductDateTimeStore } from 'app/admin/stores/product/product-date-time.store.service';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { ProductPriceStore } from 'app/admin/stores/product/product-price.store.service';
import { TravelAgencyProductDiscountStore } from 'app/admin/stores/travel-agency-product-discount.store.service';
import { TravelAgencyProductDiscount } from 'app/models/travel-agency-product-discount.model';
import { OrderPart } from 'app/models/order/OrderPart';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { Guid } from 'guid-typescript';
import { Order } from 'app/models/order.model';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';

@UntilDestroy()
@Injectable()
export class SelectProductToOrderBladeService implements OnDestroy {
	private errorMessages: string[];
	private errorMessagesBehavior = new ReplaySubject<string[]>(1);
	public errorMessagesBehavior$ = this.errorMessagesBehavior.asObservable();

	private orderPart: OrderPart = new OrderPart();
	private orderPartBehavior = new BehaviorSubject<OrderPart>(this.orderPart);
	public orderPartBehavior$ = this.orderPartBehavior.asObservable();

	private orderWrappers: OrderWrapper[];
	private user: User;

	private travelAgencyProductDiscounts: TravelAgencyProductDiscount[] = [];
	private travelAgencyProductDiscountBehavior = new BehaviorSubject<TravelAgencyProductDiscount>(null);
	public travelAgencyProductDiscountBehavior$ = this.travelAgencyProductDiscountBehavior.asObservable();


	private productPrices: ProductPrice[];
	private productPricesReplay = new ReplaySubject<ProductPrice[]>(1);
	public productPricesReplay$ = this.productPricesReplay.asObservable();

	private isLoadingBehavior = new BehaviorSubject<boolean>(true);
	public isLoadingBehavior$ = this.isLoadingBehavior.asObservable();

	private selectedCustomerReplay = new ReplaySubject<Customer>(1);
	public selectedCustomerReplay$ = this.selectedCustomerReplay.asObservable();

	private selectedProductId = 0;
	private selectedProductIdBehavior = new BehaviorSubject<number>(this.selectedProductId);
	public selectedProductIdBehavior$ = this.selectedProductIdBehavior.asObservable();

	private selectedSupplierId = 0;
	private selectedSupplierIdBehavior = new BehaviorSubject<number>(this.selectedSupplierId);
	public selectedSupplierIdBehavior$ = this.selectedSupplierIdBehavior.asObservable();

	private ordersBehavior = new ReplaySubject<OrderWrapper[]>(1);
	public ordersBehavior$ = this.ordersBehavior.asObservable();

	private addonOrders: AddonOrder[];
	private addonOrdersReplay = new ReplaySubject<AddonOrder[]>();
	public addonOrdersReplay$ = this.addonOrdersReplay.asObservable();

	public customerTypeOrders: CustomerTypeOrder[] = [];
	public customerTypeOrdersReplay = new ReplaySubject<CustomerTypeOrder[]>();
	public customerTypeOrdersReplay$ = this.customerTypeOrdersReplay.asObservable();

	private pickupLocationOrders: PickupLocationOrder[] = [];
	private pickupLocationOrdersReplay = new ReplaySubject<PickupLocationOrder[]>();
	public pickupLocationOrdersReplay$ = this.pickupLocationOrdersReplay.asObservable();


	public availableDates: string[];
	public allDatesOnProduct: ProductDate[];
	public avalibleTimes: ProductDateTime[];
	public avaliblePrices: ProductPrice[];

	private selectedTime: ProductDateTime;
	public selectedTimeReplay = new ReplaySubject<ProductDateTime>();
	public selectedTimeReplay$ = this.selectedTimeReplay.asObservable();

	private selectedDate: Date;
	public selectedDateReplay = new ReplaySubject<string>();
	public avalibleTimesReplay = new ReplaySubject<ProductDateTime[]>();

	public formGroup: FormGroup = new FormGroup({
		date: new FormControl({ value: ''}, [
			Validators.required,
		]),
		time: new FormControl('', [Validators.required]),
		manuellTime: new FormControl('', []),
	});
	public itemFilterForm: FormGroup = new FormGroup({
		supplierId: new FormControl(''),
		productId: new FormControl(''),
		message: new FormControl(''),
	});


	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private orderStore: OrderStore,
		public tourBookingStore: TourBookingStore,
		private orderItemStore: OrderItemStore,
		private tourBookingAddonStore: TourBookingAddonStore,
		private tourBookingPickupLocationStore: TourBookingPickupLocationStore,
		public customerStore: CustomerStore,
		public productDateStore: ProductDateStore,
		public productDateTimeStore: ProductDateTimeStore,
		public userService: UserService,
		public tourStore: TourStore,
		private productPriceStore: ProductPriceStore,
		private travelAgencyProductDiscountStore: TravelAgencyProductDiscountStore,
		private productStore: ProductStore,
	) {

		if (bladeItem.id && bladeItem.payload.mode === CreateOrderModeEnum.onTourBooking) {
			this.orderStore.getAllOnTourBooking(this.bladeItem.id).pipe(untilDestroyed(this), switchMap((orders) => {
				const observables = [];
				orders.forEach(order => {
					observables.push(this.orderItemStore.getAllOnOrder(order.id).pipe(switchMap(orderItems => of({order, orderItems} as OrderWrapper))));
				});
				return forkJoin(observables);
			})).pipe(take(1), untilDestroyed(this)).subscribe((orderWrappers) => {
				this.orderWrappers = orderWrappers as OrderWrapper[];
				this.isLoadingBehavior.next(false);

				this.ordersBehavior.next(this.orderWrappers as OrderWrapper[]);
			});
		} else if (bladeItem.id && bladeItem.payload.mode === CreateOrderModeEnum.onTour) {
			this.tourBookingStore.getAllOnTour(this.bladeItem.id).pipe(
				switchMap(tourBookings => {
					if (tourBookings && tourBookings.length) {
						const observables = [];
						tourBookings.forEach(tourBooking => {
							observables.push(this.orderStore.getAllOnTourBooking(tourBooking.id));
						});
						return forkJoin(observables as Observable<Order[]>[]);
					}

					return of(null);
				}),
				switchMap(orders => {
					if (orders && orders.length) {
						const observables = [];
						_.flatMap(orders).forEach(order => {
							observables.push(
								this.orderItemStore.getAllOnOrder((order as any).id)
									.pipe(
										switchMap(orderItems => {
											return of({order, orderItems} as OrderWrapper);
										}),
									),
							);
						});
						return forkJoin(observables);
					} else {
						return of(null);
					}

				}),
				untilDestroyed(this),
			).subscribe((orderWrappers) => {
				this.orderWrappers = orderWrappers as OrderWrapper[];
				this.isLoadingBehavior.next(false);

				this.ordersBehavior.next(this.orderWrappers as OrderWrapper[]);
			});
		} else {
			this.ordersBehavior.next(this.orderWrappers as OrderWrapper[]);
			this.isLoadingBehavior.next(false);
		}

		this.userService.user$
			.pipe(
				untilDestroyed(this),
				switchMap(user => {
					if (this.userService.isTravelAgencyUser()) {
						return this.travelAgencyProductDiscountStore.getAllOnTravelAgency(user.travelAgencyId);
					}
					return EMPTY;
				}),
			).subscribe();

		this.formGroup.get('time').valueChanges
			.pipe(
				untilDestroyed(this),
			).subscribe(selectedTime => {
				this.selectedTime = selectedTime;
				this.selectedTimeReplay.next(this.selectedTime);
			});

		this.productDateStore.items$
			.pipe(untilDestroyed(this))
			.subscribe(productDates => {
				this.allDatesOnProduct = productDates;
				this.handleDates();
			});

		this.travelAgencyProductDiscountStore.items$
			.pipe(untilDestroyed(this))
			.subscribe(travelAgencyProductDiscounts => {
				this.travelAgencyProductDiscounts = travelAgencyProductDiscounts;
			});

		this.productPriceStore.items$
			.pipe(untilDestroyed(this))
			.subscribe(productPrices => {
				this.productPrices = productPrices;
				this.productPricesReplay.next(this.productPrices);
			});

		combineLatest([
			this.productDateStore.items$,
			this.formGroup.get('date').valueChanges,
		]).pipe(
			untilDestroyed(this),
		).subscribe(([allDatesOnProduct, selectedDate]) => {
			// Reset times when change date
			this.avalibleTimes = [];
			this.avalibleTimesReplay.next(this.avalibleTimes);

			selectedDate = moment(selectedDate).format('YYYY-MM-DD');
			const dayIndex = moment(selectedDate).weekday() + '';

			this.selectedDateReplay.next(selectedDate);
			const selectedDateModel = _.find(
				allDatesOnProduct,
				date =>
					selectedDate >= moment(date.fromDate).format('YYYY-MM-DD') &&
					selectedDate <= moment(date.toDate).format('YYYY-MM-DD') &&
					date.weekDays.includes(dayIndex),
			);

			if (selectedDateModel) {
				return this.productDateTimeStore.getAllOnProductDate$(selectedDateModel.id).pipe(untilDestroyed(this)).subscribe(timesOnDate => {
					this.avalibleTimes = timesOnDate;
					this.avalibleTimesReplay.next(this.avalibleTimes);
					if (!(this.orderPart && this.orderPart.time) && this.avalibleTimes && this.avalibleTimes.length === 1) {
						this.formGroup.get('time').setValue(this.avalibleTimes[0]);
					} else if (this.orderPart && this.orderPart.time) {
						const findTime = _.find(this.avalibleTimes, time => time.time === this.orderPart.time);
						if (findTime) {
							this.formGroup.get('time').setValue(findTime);
						}
					}
				});
			}
		});
		if (this.bladeItem.payload.orderPart) {
			this.setOrderPart(this.bladeItem.payload.orderPart);
		}
	}
	ngOnDestroy(): void { }

	public getOrderItemsOfType(orders: OrderWrapper[], orderItemTypeEnum: OrderItemTypeEnum): OrderItem[] {
		const orderItems: OrderItem[] = [];
		if (!orders) {
			return orderItems;
		}

		orders.forEach((orderWrapp) => {
			orderWrapp.orderItems.forEach(orderItem => {
				switch (orderItemTypeEnum) {
					case OrderItemTypeEnum.customer:
						if (orderItem.customerTypeId > 0 && orderItem.pickupLocationId === 0) {
							orderItems.push(orderItem);
						}
						break;
					case OrderItemTypeEnum.addon:
						if (orderItem.addonId > 0) {
							orderItems.push(orderItem);
						}
						break;
					case OrderItemTypeEnum.pickup:
						if (orderItem.customerTypeId > 0 && orderItem.pickupLocationId > 0) {
							orderItems.push(orderItem);
						}
						break;
				}
			});
		});
		return orderItems;
	}

	public setLoading(isLoading: boolean) {
		this.isLoadingBehavior.next(isLoading);
	}


	public setProduct(productId: number) {
		this.selectedProductId = productId;
		this.selectedProductIdBehavior.next(productId);
		this.productDateStore.getAllOnProduct(productId);
		this.productPriceStore.getAllOnProduct(productId).pipe(untilDestroyed(this)).subscribe();
		this.travelAgencyProductDiscountBehavior.next(_.find(this.travelAgencyProductDiscounts, discount => discount.productId === productId));
	}

	public setSupplier(supplierId: number) {
		this.selectedSupplierId = supplierId;
		this.selectedSupplierIdBehavior.next(supplierId);
	}

	public dateFilter = (date: Date) => {
		if (this.userService.isAdminUser()) {
			return true;
		}
		const dateStr = moment(date).format('YYYY-MM-DD');
		return _.includes(this.availableDates, dateStr);
	}

	public dateClass: MatCalendarCellClassFunction<string> = (cellDate, view) => {
		const dateStr = moment(cellDate).format('YYYY-MM-DD');
		return _.includes(this.availableDates, dateStr)
			? 'available'
			: undefined;
	}

	public setOrderPart(orderPart: OrderPart) {
		this.orderPart = orderPart;
		this.orderPartBehavior.next(this.orderPart);

		if (orderPart.productId) {
			this.setProduct(orderPart.productId);
			this.itemFilterForm.get('productId').setValue(orderPart.productId);
		}
		if (orderPart.supplierId) {
			this.setSupplier(orderPart.supplierId);
			this.itemFilterForm.get('supplierId').setValue(orderPart.supplierId);
		}
		if (orderPart.message) {
			this.itemFilterForm.get('message').setValue(orderPart.message);
		}
		if (orderPart.date) {
			this.formGroup.get('date').setValue(moment(orderPart.date).format('YYYY-MM-DD'));
		}
		if (orderPart.time) {
			this.formGroup.get('time').setValue(orderPart.time);
			this.formGroup.get('manuellTime').setValue(orderPart.time);
		}
	}

	public setCustomerTypeOrders(customerTypeOrders: CustomerTypeOrder[]) {
		this.customerTypeOrders = customerTypeOrders;
		this.customerTypeOrdersReplay.next(this.customerTypeOrders);
	}

	public setPickupLocationOrders(pickupLocationOrders: PickupLocationOrder[]) {
		this.pickupLocationOrders = pickupLocationOrders;
		this.pickupLocationOrdersReplay.next(this.pickupLocationOrders);
	}

	public setAddonOrders(addonOrders: AddonOrder[]) {
		this.addonOrders = addonOrders;
		this.addonOrdersReplay.next(this.addonOrders);
	}

	public select() {
		this.setLoading(true);
		if (!this.orderPart) {
			this.orderPart = new OrderPart();
		}

		if (!this.orderPart.uniqueId) {
			this.orderPart.uniqueId = Guid.create().toString();
		}

		this.orderPart.date = moment(this.formGroup.value.date).toDate();
		this.orderPart.time = this.formGroup.value.time?.time;
		this.orderPart.customerTypes = [];
		this.orderPart.addons = [];
		this.orderPart.pickupLocations = [];

		this.errorMessages = [];

		if (this.formGroup.value.manuellTime && this.userService.isAdminUser()) {
			this.orderPart.time = this.formGroup.value.manuellTime;
		}

		if (this.selectedProductId) {
			this.orderPart.productId = this.selectedProductId;
		}

		if (this.selectedSupplierId) {
			this.orderPart.supplierId = this.selectedSupplierId;
		}

		if (this.itemFilterForm.value.message) {
			this.orderPart.message = this.itemFilterForm.value.message;
		}

		(this.customerTypeOrders || []).forEach(customerType => {
			if (customerType.quantity) {
				this.orderPart.customerTypes.push({
					customerTypeId: customerType.customerTypeId,
					quantity: customerType.quantity,
					unitPrice: customerType.unitPrice ?? 0,
				});
			}
		});

		(this.addonOrders || []).forEach(addon => {
			if (addon.quantity) {
				this.orderPart.addons.push({
					addonId: addon.addonId,
					quantity: addon.quantity,
					unitPrice: addon.unitPrice ?? 0,
				});
			}
		});

		(this.pickupLocationOrders || []).forEach(pickup => {
			if (pickup.quantity) {
				this.orderPart.pickupLocations.push({
					customerTypeId: pickup.customerTypeId,
					quantity: pickup.quantity,
					pickupLocationId: pickup.pickupLocationId,
					unitPrice: pickup.unitPrice ?? 0,
				});
			}
		});

		// Validate
		if (!this.orderPart.productId) {
			this.errorMessages.push('Saknar produkt');
		}
		if (!this.orderPart.supplierId) {
			this.errorMessages.push('Saknar leverantör');
		}
		if (!this.orderPart.date) {
			this.errorMessages.push('Datum saknas');
		}
		if (!this.orderPart.time) {
			this.errorMessages.push('Tid saknas');
		}
		if (!this.orderPart.customerTypes.length) {
			this.errorMessages.push('Saknas kunder');
		}

		this.errorMessagesBehavior.next(this.errorMessages);
		if (this.errorMessages?.length) {
			return;
		}

		if (this.bladeItem.parent && this.bladeItem.parent.callback) {
			this.bladeItem.parent.callback(this.orderPart, 'orderPart');
		}
		this.bladeItem.bladeItemComponent.closeMe();
	}

	private handleDates() {
		this.availableDates = [];
		_.each(this.allDatesOnProduct, pDate => {
			this.addDates(new Date(pDate.fromDate), new Date(pDate.toDate), pDate.weekDays);
		});
	}

	private addDates(startDate: Date, stopDate: Date, weekDays: string) {
		let currentDate = moment(startDate);
		while (currentDate <= moment(stopDate)) {
			if (moment(currentDate) >= moment()) {
				const cDate = moment(currentDate);
				if (weekDays.includes(`${cDate.weekday()}`)) {
					this.availableDates.push(moment(currentDate).format('YYYY-MM-DD'));
				}
			}
			currentDate = moment(currentDate).add(1, 'days');
		}
	}
}
