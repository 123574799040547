import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { SupplierResourceProductTypeStore } from '../stores/supplier-resource-product-type.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'supplierResourceProductTypeName' })
export class SupplierResourceProductTypeNamePipe implements PipeTransform, OnDestroy {
	constructor(private supplierResourceProductTypeStore: SupplierResourceProductTypeStore) {	}
	public transform(supplierResourceProductId: number): Observable<string> {
		if (supplierResourceProductId === 0) {
			return of('Ingen vald');
		}
		if (!supplierResourceProductId) {
			return;
		}
		return this.supplierResourceProductTypeStore.pipeGet(supplierResourceProductId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
