import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { ProductText } from 'app/models/product/product-text.model';
import { BaseStore } from '../base.store.service';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ProductTextStore extends BaseStore<ProductText> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductTexts', baseUrl, snackBarService);
	}

	public getAllOnProduct(productId: number): Observable<ProductText[]> {
		return this.http.get<ProductText[]>(`${this.host}/product/${productId}`).pipe(tap(productTexts => {
			this._items = productTexts;
			this._itemsSubject.next(this._items);
		}));
	}

	public getOnProductAndLanguage(productId: number, languageId: number): Observable<ProductText> {
		return this.http.get<ProductText>(`${this.host}/product/${productId}/language/${languageId}`).pipe(tap(productText => {
			if (productText) {
				const findObj = _.find(this._items, type => type.productId === productText.productId
									&& type.languageId === productText.languageId);
				_.assign(findObj, productText); // Keep object in sync
			}
		}));
	}

	public addProductText(productText: ProductText) {
		this._items.push(productText);
		this._itemsSubject.next(this._items);
	}

	public removeProductText(productText: ProductText) {
		_.remove(this._items, productText);
		this._itemsSubject.next(this._items);
	}
}
