import { Component, OnInit, Inject, Input, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';

import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Order } from 'app/models/order.model';
import { PageStore } from 'app/admin/stores/page.store.service';
import { PageTypeStore } from 'app/admin/stores/page-type.store.service';
import { FormControl, FormGroup } from '@angular/forms';
import { OrderStatusStore } from 'app/admin/stores/order-status.store.service';
import { SystemStore } from 'app/admin/stores/system.store.service';

@UntilDestroy()
@Component({
	selector: 'app-order-generally',
	templateUrl: './order-generally.component.html',
	styleUrls: ['./order-generally.component.scss'],
})
export class OrderGenerallyComponent implements OnInit, OnChanges, OnDestroy {
	public showPaymentBalance$ = new BehaviorSubject<boolean>(true);
	public isPristine$ = new Subject<boolean>();
	@Input() order: Order;
	@Output() save = new EventEmitter();

	public pathReplay$ = new ReplaySubject<string>();

	public itemForm: FormGroup = new FormGroup({
		orderStatusId: new FormControl(''),
		reference: new FormControl(''),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private pageStore: PageStore,
		private pageTypeStore: PageTypeStore,
		public orderStatusStore: OrderStatusStore,
		public systemStore: SystemStore,
	) {
		combineLatest([
			this.pageStore.getAll$(),
			this.pageTypeStore.getAll$(),
			this.systemStore.getCurrent$(),
		]).pipe(untilDestroyed(this), take(1))
		.subscribe(([pages, pageTypes, system]) => {
			const paymentType = pageTypes.find(type => type.identifier === 'payment');
			if (paymentType) {
				const page = pages.find(p => p.pageTypeId === paymentType.id);
				if (page) {
					let basePath = system.host;
					if (!basePath.endsWith('/')){
						basePath += '/';
					}
					this.pathReplay$.next(basePath + _.kebabCase(page.name));
				}
			}
		});
	}

	ngOnInit() {}

	ngOnChanges() {
		if (this.order?.id) {
			this.itemForm.get('orderStatusId').setValue(this.order.orderStatusId);
			this.itemForm.get('reference').setValue(this.order.reference);
			this.itemForm.get('orderStatusId').valueChanges.pipe(untilDestroyed(this)).subscribe(orderStatusId => {
				this.order.orderStatusId = orderStatusId;
				this.save.next();
			});
			this.itemForm.get('reference').valueChanges.pipe(untilDestroyed(this), debounceTime(1000)).subscribe(reference => {
				this.order.reference = reference;
				this.save.next();
			});
		}
	}

	ngOnDestroy() {}

	public reloadPaymentBalance() {
		this.showPaymentBalance$.next(false);
		this.order.paymentBalance = null;
		setTimeout(() => {
			this.showPaymentBalance$.next(true);
		}, 500);
	}
}
