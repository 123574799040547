import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TermTypeStore } from 'app/admin/stores/term-type.store.service';
import { TermType } from 'app/models/term-type.model';
import { Observable, ReplaySubject } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class TermTypeService {
	public selectedIndex = 1;
	private isLoadingReplay = new ReplaySubject<boolean>();
	public isLoadingReplay$ = this.isLoadingReplay.asObservable();

	public item: TermType;
	private itemReplay = new ReplaySubject<TermType>();
	public itemReplay$ = this.itemReplay.asObservable();

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		identifier: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: TermTypeStore,
	) {

		if (bladeItem.id) {
			this.isLoadingReplay.next(true);
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.itemForm.patchValue(this.item);

				this.isLoadingReplay.next(false);
				this.itemReplay.next(this.item);
			});
		} else {
			this.item = new TermType();
			this.itemReplay.next(this.item);
		}
	}

	public createIdentifier() {
		this.itemForm.get('identifier').setValue(_.snakeCase(this.itemForm.get('name').value));
	}

	public save() {
		this.isLoadingReplay.next(true);
		this.item = Object.assign(this.item, this.itemForm.value);
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.isLoadingReplay.next(false);
			this.itemForm.markAsPristine();
		});
	}

	private saveOrUpdate(): Observable<TermType> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

}
