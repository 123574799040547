<ng-container *ngIf="{
	adminText: 'blades.tour.tabs.pickup' | adminText | async
} as source">
	<ng-container *ngIf="pickupLocationWrappersReplay | async | keyvalue as pickups">
		<ng-container *ngIf="pickups.length">
			<div class="d-flex justify-content-between">
				<h3>{{source.adminText?.title}}</h3>
				<div>
					<button mat-icon-button (click)="accordion.openAll()">
						<mat-icon>unfold_more</mat-icon>
					</button>
					<button mat-icon-button (click)="accordion.closeAll()">
						<mat-icon>unfold_less</mat-icon>
					</button>
				</div>
			</div>
			<mat-accordion multi>
				<mat-expansion-panel *ngFor="let pickup of pickups">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{+pickup.key | pickupLocationName | async}}
						</mat-panel-title>
						<mat-panel-description>

						</mat-panel-description>
					</mat-expansion-panel-header>
					<mat-card class="mat-elevation-z3">
						<mat-table [dataSource]="pickup.value" class="w-100">
							<ng-container matColumnDef="name">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.table.customer}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.tourBooking?.customerId | customerName | async}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="pickupLocation">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.table.pickupLocation}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.pickupLocationId | pickupLocationName | async}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="customerType">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.table.customeType}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.customerTypeId | customerTypeName | async}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="quantity">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.table.amount}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.quantity}}</mat-cell>
							</ng-container>
							<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
							<mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)"></mat-row>
						</mat-table>
					</mat-card>
				</mat-expansion-panel>
			</mat-accordion>
		</ng-container>
	</ng-container>
</ng-container>
