
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ProductDateTime } from 'app/models/product/product-date-time.model';
import { BaseStore } from '../base.store.service';
import { Observable } from 'rxjs';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ProductDateTimeStore extends BaseStore<ProductDateTime> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductDateTimes', baseUrl, snackBarService);
	}

	public getAllOnProductDate(productDateId: number): void {
		this._items = [];
		this._itemsSubject.next(this._items);
		this.getAllOnProductDate$(productDateId).subscribe(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		});
	}

	public getAllOnProductDate$(productDateId: number): Observable<ProductDateTime[]> {
		return this.http.get<ProductDateTime[]>(`${this.host}/product/date/${productDateId}`);
	}
}
