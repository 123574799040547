
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { ColumnStyle } from 'app/models/column-style.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ColumnStyleStore extends BaseStore<ColumnStyle> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ColumnStyle', baseUrl, snackBarService);
	}

	public getOnColumn(columnId: number): Observable<ColumnStyle> {
		return this.http.get<ColumnStyle>(`${this.host}/column/${columnId}`);
	}
}
