<!-- <mat-card>
	<mat-card-title>Information</mat-card-title>
	<mat-card-content> -->
		<div class="row">
			<div class="col-6 mb-2">
				<b>Kund:</b><br>
				<app-open-customer-blade [customerId]="order?.customerId"></app-open-customer-blade>
			</div>
			<div class="col-6 mb-2">
				<b>Källa:</b><br>
				{{order?.sourceTypeId | sourceTypeName | async}}
			</div>
		</div>
		<form [formGroup]="itemForm" class="mb-2">
			<div class="row">
				<div class="col-6">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Status</mat-label>
						<mat-select formControlName="orderStatusId">
							<mat-option *ngFor="let type of (orderStatusStore.items$ | async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-6">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Referens</mat-label>
						<input matInput formControlName="reference" placeholder="Referens">
					</mat-form-field>			
				</div>
			</div>
		</form>
		<div class="mb-2" *ngIf="pathReplay$ | async as path">
			<b>Betalningslänk:</b><br>
			<a [href]="path + '?id=' + order?.hashId" target="_blank">{{path + '?id=' + order?.hashId}}</a>
		</div>
		<div class="row">
			<div class="col-6 mb-2">
				<b>Skapad:</b><br>
				{{order?.created | date:'yyyy-MM-dd HH:mm'}}<br>
				<span *ngIf="order?.createdBy > 0"><small>{{order?.createdBy | adminUserName | async}}</small></span>
			</div>
			<div class="col-6 mb-2">
				<b>Ändrad:</b><br>
				{{order?.modified | date:'yyyy-MM-dd HH:mm'}}<br>
				<span *ngIf="order?.modifiedBy > 0"><small>{{order?.modifiedBy | adminUserName | async}}</small></span>
			</div>
		</div>
	<!-- </mat-card-content>
</mat-card> -->

<!-- <app-order-items class="d-block mt-3"></app-order-items>

<ng-container *ngIf="showPaymentBalance$ | async; else loading">
	<mat-card class="mt-3" *ngIf="order | paymentBalance | async as payment">
		<mat-card-title class="d-flex justify-content-between">
			<span>Kredit</span><button mat-icon-button class="reload" (click)="reloadPaymentBalance()">
			<mat-icon>sync</mat-icon></button>
		</mat-card-title>
		<p>Totalt belopp på ordern: <b>{{payment?.paymentBalance?.totalOrder | currency:'SEK':'code'}}</b></p>
		<p>Kunden har betalat <b>{{payment?.paymentBalance?.totalPayment | currency:'SEK':'code'}}</b></p>
		<p>
			Balans -
			<span *ngIf="payment.balance > 0">Ska betala: </span>
			<span *ngIf="payment.balance < 0">Få tillbaka: </span>
			<b>
				<span
					[class.text-success]="!payment.balance"
					[class.text-danger]="payment.balance > 0"
					[class.text-warning]="payment.balance < 0">
					{{payment.balanceStr}}
				</span>
			</b>
		</p>
	</mat-card>
</ng-container>

<ng-template #loading>
	<mat-card class="mt-3 d-flex justify-content-around">
		<mat-spinner></mat-spinner>
	</mat-card>
</ng-template>

<email-queue *ngIf="order?.id" [emailQueueType]="'NewOrder'" [entityName]="'Orders'" [entityId]="order?.id"></email-queue> -->
