<mat-card>
	<mat-card-content>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="headline" [placeholder]="'Namn på ' + language?.name">
			</mat-form-field>
		</form>
	</mat-card-content>
	<mat-card-actions>
		<button *ngIf="pageText?.id" mat-stroked-button color="warn" (click)="delete()">
			Radera
		</button>
		<span fxFlex></span>
		<button mat-stroked-button color="primary" [disabled]="saving" (click)="save()">
			<span *ngIf="pageText?.id">Spara</span>
			<span *ngIf="!pageText?.id">Skapa</span>
		</button>
	</mat-card-actions>
</mat-card>
