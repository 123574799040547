import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomerType } from 'app/models/customer-type.model';
import { PickupLocationPrice } from 'app/models/page-location-price.model';
import { ProductPickupLocationPrice } from 'app/models/product/product-pickup-location-price.model';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-product-pickup-location-price',
  templateUrl: './product-pickup-location-price.component.html',
  styleUrls: ['./product-pickup-location-price.component.scss'],
})
export class ProductPickupLocationPriceComponent implements OnChanges {
	public pickupLocationPriceReplay$ = new ReplaySubject<PickupLocationPrice>(1);
	public productPickupLocationPriceReplay$ = new ReplaySubject<ProductPickupLocationPrice>(1);

	@Input() pickupLocationPrices: PickupLocationPrice[];
	@Input() productPickupLocationPrices: ProductPickupLocationPrice[];
	@Input() customerType: CustomerType;

	constructor() { }

	ngOnChanges(changes: SimpleChanges): void {
		if (this.customerType) {
			this.pickupLocationPriceReplay$.next(this.pickupLocationPrices?.find(x => x.customerTypeId === this.customerType.id));
			this.productPickupLocationPriceReplay$.next(this.productPickupLocationPrices?.find(x => x.customerTypeId === this.customerType.id));
		}
	}
}
