import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { TermTypeStore } from '../stores/term-type.store.service';
import { Observable } from 'rxjs';

/**
 * Print term type name
 */
@Pipe({ name: 'termTypeName' })
export class TermTypeNamePipe implements PipeTransform, OnDestroy {
	constructor(private termTypeStore: TermTypeStore) { }

	public transform(termTypeId: number): Observable<string> {
		if (!termTypeId) {
			return;
		}
		return this.termTypeStore.pipeGet(termTypeId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
