<app-blade-item [canIClose]="true" [loading]="loading" [id]="item?.id">
    <app-blade-header [title]="item?.name||'Ny'" [subTitle]="'Sida'" [menuItems]="headerMenuItems"></app-blade-header>

	<app-blade-content *ngIf="item?.id" [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
		<ng-container [ngSwitch]="selectedIndex">
			<ng-container *ngSwitchCase="1">
				<app-page-blade-generally [item]="item"></app-page-blade-generally>
			</ng-container>
			<ng-container *ngSwitchCase="2">
				<app-page-blade-text [item]="item"></app-page-blade-text>
			</ng-container>
			<ng-container *ngSwitchCase="3">
				<app-page-blade-etages></app-page-blade-etages>
			</ng-container>
			<ng-container *ngSwitchCase="5">
				<app-page-blade-styles></app-page-blade-styles>
			</ng-container>
		</ng-container>
	</app-blade-content>
	<app-blade-content *ngIf="!item?.id">
		<form [formGroup]="pageBladeService.itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="name" placeholder="Namn">
			</mat-form-field>
			<div class="d-flex justify-content-between">
				<mat-slide-toggle
					color="accent"
					formControlName="isActive">
					Aktiv
				</mat-slide-toggle>
				<mat-slide-toggle
					color="accent"
					formControlName="showOnMenu">
					Visa i menyn
				</mat-slide-toggle>
			</div>
			<mat-form-field class="w-100">
				<input matInput formControlName="externalLink" placeholder="Extern länk">
			</mat-form-field>
			<mat-form-field class="w-100">
				<mat-label>Välj sida-typ</mat-label>
				<mat-select formControlName="pageTypeId">
					<mat-option *ngFor="let type of (pageTypeStore.items$|async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
	</app-blade-content>
	<ng-container [ngSwitch]="selectedIndex">
		<app-blade-footer *ngSwitchCase="1">
			<span fxFlex></span>
			<button mat-raised-button color="primary" [disabled]="pageBladeService.itemForm.invalid" (click)="save()">
				<span *ngIf="item?.id">Spara</span>
				<span *ngIf="!item?.id">Skapa</span>
			</button>
		</app-blade-footer>
		<app-blade-footer *ngSwitchCase="3">
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="addNewEtage()">Lägg till ny</button>
		</app-blade-footer>
	</ng-container>
 </app-blade-item>
