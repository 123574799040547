<app-blade-item [canIClose]="true">
    <app-blade-header [title]="'Ladda upp'" [subTitle]="'Media'"></app-blade-header>
	<app-blade-content>
		<div class="mat-elevation-z1 p-2">
			<app-alert-card [mode]="'info'" [label]="'Du kan hitta gratisbilder på följande ställen https://pixabay.com, https://se.freeimages.com, https://stocksnap.io/'"></app-alert-card>
			<input type="file" #file placeholder="Choose file" multiple (change)="uploadFile(file)" style="display:none;">
			<button mat-raised-button type="button" (click)="file.click()">Välj filer att ladda upp...</button>

			<table mat-table *ngIf="uploadMedia?.length" [dataSource]="uploadMedia" class="w-100 mt-2 mat-elevation-z1">
				<ng-container matColumnDef="media">
					<th mat-header-cell *matHeaderCellDef>Media</th>
					<td mat-cell *matCellDef="let item"><img *ngIf="item.mediaType === 'image'" class="my-1 list-img" [src]="item.path"></td>
				</ng-container>
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Name</th>
					<td mat-cell *matCellDef="let item"><strong>{{item.name}}</strong></td>
				</ng-container>
				<ng-container matColumnDef="done">
					<th mat-header-cell *matHeaderCellDef>Klar</th>
					<td mat-cell *matCellDef="let item">
						<mat-progress-spinner
							*ngIf="!item.isDone"
							[diameter]="30"
							[color]="'primary'"
							[mode]="'determinate'"
							[value]="item.progress">
						</mat-progress-spinner>
						<mat-icon class="text-success" *ngIf="item.isDone">done</mat-icon>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
			</table>



			<!-- <mat-card class="m-2" *ngFor="let item of uploadMedia">
				<mat-card-header>
					<mat-card-title>{{item.name}}</mat-card-title>
				</mat-card-header>
				<img *ngIf="item.mediaType === 'image'" mat-card-image [src]="item.path">
				<mat-progress-bar mode="determinate" [value]="item.progress"></mat-progress-bar>
				<mat-card-actions class="text-right text-success">
					<mat-icon *ngIf="item.isDone">done</mat-icon>
				</mat-card-actions>
			</mat-card> -->
		</div>
	</app-blade-content>
 </app-blade-item>
