import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ActivatedRoute, Route, Router } from '@angular/router';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';


import { BladePositionService } from '../blade/blade-position.service';
import { AuthService } from 'app/admin/services/auth.service';
import { UserService } from 'app/admin/services/user.service';
import { LoginModalService } from 'app/admin/services/login-modal.service';
import { User } from 'app/admin/models/user.model';
import { MenuItemsService } from './services/menu-items.service';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { AdminDataService } from 'app/modules/admin-data/services/admin-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SystemStore } from '../../stores/system.store.service';

@UntilDestroy()
@Component({
	selector: 'app-side-nav',
	templateUrl: './side-nav.component.html',
	styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
	private isExpanded = true;
	public isExpandedReplay$ = new BehaviorSubject<boolean>(this.isExpanded);

	private selectedThemeName = 'selectedTheme';
	public selectedTheme;
	public user: User;

	@ViewChild('sidenavContainer') drawerNav: MatSidenavContainer;
	@ViewChild('settings') settingsNav: MatSidenav;
	@ViewChild('mobileDrawer') mobileDrawer: MatSidenav;

	public hideMenuSideNav$ = new BehaviorSubject<boolean>(false);

	isHandset$: Observable<boolean> = this.breakpointObserver
		.observe(Breakpoints.Handset)
		.pipe(map(result => result.matches));

	constructor(
		private breakpointObserver: BreakpointObserver,
		private bladePositionService: BladePositionService,
		public authService: AuthService,
		public userService: UserService,
		private loginModalService: LoginModalService,
		private router: Router,
		private route: ActivatedRoute,
		public menuItemsService: MenuItemsService,
		public adminDataService: AdminDataService,
		public systemStore: SystemStore,
	) {
		this.selectedTheme = localStorage.getItem(this.selectedThemeName);
		userService.user$.pipe(untilDestroyed(this)).subscribe(user => {
			this.user = user;
		});
		this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
			this.hideMenuSideNav$.next(!!params?.hideMenuSideNav);
		});
	}

	public toggleMenu() {
		if (this.mobileDrawer) {
			this.mobileDrawer.toggle();
		} else {
			this.isExpanded = !this.isExpanded;
			this.isExpandedReplay$.next(this.isExpanded);
			setTimeout(() => {
				this.drawerNav.updateContentMargins();
			}, 100);
		}
	}

	public selectTheme(theme: string) {
		if (theme) {
			document.body.classList.remove(this.selectedTheme);
			this.selectedTheme = theme;
			localStorage.setItem(this.selectedThemeName, this.selectedTheme);
			document.getElementById('AngularMaterialStyle').setAttribute('href', `assets/prebuilt-themes/${this.selectedTheme}.css`);
			document.body.classList.add(this.selectedTheme);
			this.settingsNav.close();
		}
	}

	public accountClick() {
		if (this.user && this.user.id) {
			this.router.navigate(['my-page']);
		} else {
			this.loginModalService.showLoginInModal();
		}
	}
}
