import { Injectable, Inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { BladeContentTabComponent } from './components/blade-content-tabs/components/blade-content-tab/blade-content-tab.component';
import * as _ from 'lodash';
import { BladeItem } from '../../../models/bladeItem.model';
import { BladeItemInjectToken } from '../../../blade.service';

@Injectable()
export class BladeContentService {

	private tabs: BladeContentTabComponent[];
	private tabsSubject = new ReplaySubject<BladeContentTabComponent[]>();
	public tabs$ = this.tabsSubject.asObservable();
	constructor(@Inject(BladeItemInjectToken) public bladeItem: BladeItem) { }

	public addTabs(tabs: BladeContentTabComponent[]): void {
		this.tabs = tabs;
		_.each(this.tabs, (tab, index) => {
			if (!tab.index && tab.index !== 0) {
				tab.index = index;
			}
		});
		if (this.bladeItem.menuIndex !== undefined) {
			const selectedTab = _.find(this.tabs, tab => tab.index === this.bladeItem.menuIndex);
			if (selectedTab) {
				selectedTab.isActive = true;
			} else {
				_.first(this.tabs).isActive = true;
			}
		} else {
			_.first(this.tabs).isActive = true;
		}
		this.tabsSubject.next(this.tabs);
	}

	public selectTab(tab: BladeContentTabComponent): void {
		_.each(this.tabs, t => {
			t.isActive = false;
		});
		tab.isActive = true;
	}
}
