<mat-card>
	<mat-card-title>Generellt</mat-card-title>
	<form [formGroup]="itemForm">
		<div class="col-md-2 align-items-end d-flex" *ngIf="page?.pageTypeIdentifier === PageTypeEnum.Product && selectedElement.elementTypeIdentifier !== ElementTypeEnum.ProductBooking">
			<mat-checkbox formControlName="onProduct">Ska värden hämtas från produkten?</mat-checkbox>
		</div>
		<div class="col-md-2 align-items-end d-flex" *ngIf="page?.pageTypeIdentifier === PageTypeEnum.Category">
			<mat-checkbox formControlName="onCategory">Ska värden hämtas från kategorin?</mat-checkbox>
		</div>
	</form>
</mat-card>
