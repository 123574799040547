import { Component, OnInit } from '@angular/core';

import { OddFunctionService } from '../../services/odd-function.service';

@Component({
	selector: 'app-odd-function-blade-messages',
	templateUrl: './function-blade-messages.component.html',
	styleUrls: ['./function-blade-messages.component.scss'],
})
export class OddFunctionBladeMessagesComponent implements OnInit {
	constructor(
		public productFunctionService: OddFunctionService,
	) {

	}

	ngOnInit(): void {
	}
}
