import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { TourBookingPickupLocationService } from './services/tour-booking-pickup-location.service';

@Component({
  	selector: 'app-tour-booking-pickup-location',
	templateUrl: './tour-booking-pickup-location.component.html',
  	styleUrls: ['./tour-booking-pickup-location.component.scss'],
	providers: [TourBookingPickupLocationService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourBookingPickupLocationComponent implements OnInit {


	constructor(
		public tourBookingPickupLocationService: TourBookingPickupLocationService,
	) { }

	ngOnInit() {
	}
}
