import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingAddon } from 'app/models/tour-booking-addon.model';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { TourBookingAddonService } from '../../../services/tour-booking-addon.service';

@UntilDestroy()
@Injectable()
export class TourBookingAddonItemService {
	private tourBookingAddon: TourBookingAddon;
	private tourBookingCustomerTypeReplay = new ReplaySubject<TourBookingAddon>(1);
	public tourBookingCustomerTypeReplay$ = this.tourBookingCustomerTypeReplay.asObservable();

	public itemForm: FormGroup = new FormGroup({
		addonId: new FormControl('', [Validators.required]),
		quantity: new FormControl('', [Validators.required]),
	});

  	constructor(
		public tourBookingAddonService: TourBookingAddonService,
	  ) {
		this.itemForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			Object.assign(this.tourBookingAddon, value);
		});
	}

	public setTourBookingAddonItem(tourBookingAddon: TourBookingAddon) {
		this.tourBookingAddon = tourBookingAddon;

		this.itemForm.patchValue(this.tourBookingAddon);
		this.tourBookingCustomerTypeReplay.next(this.tourBookingAddon);
	}
}
