import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import 'moment/locale/sv';
import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
registerLocaleData(localeSv, 'sv');

import { ClipboardModule } from 'ngx-clipboard';
import { NgJsonEditorModule } from '@maaxgr/ang-jsoneditor';

import { LoadingModule } from 'app/modules/loading/loading.module';

import { AdminAppComponent } from './components/app/app.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';

import { DashboardPageComponent } from './pages/dashboard/dashboard.component';
import { ToursBladeComponent } from './blades/tour/tours-blade/tours-blade.component';
import { TourBladeComponent } from './blades/tour/tour-blade/tour-blade.component';
import { TourGuideBladeComponent } from './blades/tour-guide/tour-guide-blade/tour-guide-blade.component';
import { OrderBladeComponent } from './blades/order/order-blade/order-blade.component';
import { OrdersBladeComponent } from './blades/order/orders-blade/orders-blade.component';
import { OrderItemBladeComponent } from './blades/order/order-item-blade/order-item-blade.component';
import { TourBookingBladeComponent } from './blades/tour-booking/tour-booking-blade/tour-booking-blade.component';
import { TourBookingCustomerTypeBladeComponent } from './blades/tour-booking-customer-type/tour-booking-customer-type-blade/tour-booking-customer-type-blade.component';
import { CustomerBladeComponent } from './blades/customer/customer-blade/customer-blade.component';
import { CustomersBladeComponent } from './blades/customer/customers-blade/customers-blade.component';

import { BladeModule } from './components/blade/blade.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { AdminRoutingModule } from './admin-routing.module';
import { OrdersPageComponent } from './pages/orders/orders.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { MenuListItemComponent } from './components/side-nav/components/menu-list-item/menu-list-item.component';
import { OrdersDashboardComponent } from './pages/orders/children/orders-dashboard/orders-dashboard.component';
import { OrdersNewComponent } from './pages/orders/children/orders-new/orders-new.component';
import { OrdersStatisticsComponent } from './pages/orders/children/orders-statistics/orders-statistics.component';
import { ToursListComponent } from './pages/tours/children/tours-list/tours-list.component';
import { OrdersListComponent } from './pages/orders/children/orders-list/orders-list.component';
import { TourGenerallyComponent } from './blades/tour/tour-blade/components/tour-generally/tour-generally.component';
import { TourBookingsComponent } from './blades/tour/tour-blade/components/tour-bookings/tour-bookings.component';
import { TourGuidesComponent } from './blades/tour/tour-blade/components/tour-guides/tour-guides.component';
import { OrderGenerallyComponent } from './blades/order/order-blade/components/order-generally/order-generally.component';
import { OrderItemsComponent } from './blades/order/order-blade/components/order-items/order-items.component';
import { OrderPaymentsComponent } from './blades/order/order-blade/components/order-payments/order-payments.component';
import { TourBookingBladeGenerallyComponent } from './blades/tour-booking/tour-booking-blade/components/tour-booking-generally/tour-booking-blade-generally.component';
import { TourBookingBladeBookingsComponent } from './blades/tour-booking/tour-booking-blade/components/tour-booking-bookings/tour-booking-blade-bookings.component';
import { TourBookingBladeCustomerComponent } from './blades/tour-booking/tour-booking-blade/components/tour-booking-customer/tour-booking-blade-customer.component';
import { TourBookingCustomerTypeBladeGenerallyComponent } from './blades/tour-booking-customer-type/tour-booking-customer-type-blade/components/tour-booking-customer-type-generally/tour-booking-customer-type-blade-generally.component';
import { TourBookingCustomerTypeBladeOrderItemsComponent } from './blades/tour-booking-customer-type/tour-booking-customer-type-blade/components/tour-booking-customer-type-order-items/tour-booking-customer-type-blade-order-items.component';
import { CustomerGenerallyComponent } from './blades/customer/customer-blade/components/customer-generally/customer-generally.component';
import { CustomerBookingsComponent } from './blades/customer/customer-blade/components/customer-bookings/customer-bookings.component';
import { CustomerOrdersComponent } from './blades/customer/customer-blade/components/customer-orders/customer-orders.component';

import { ToursPageComponent } from './pages/tours/tours.component';
import { ToursNewComponent } from './pages/tours/children/tours-new/tours-new.component';
import { ToursStatisticsComponent } from './pages/tours/children/tours-statistics/tours-statistics.component';
import { ToursDashboardComponent } from './pages/tours/children/tours-dashboard/tours-dashboard.component';

import { ProductsPageComponent } from './pages/products/products.component';
import { ProductsNewComponent } from './pages/products/children/products-new/products-new.component';
import { ProductsStatisticsComponent } from './pages/products/children/products-statistics/products-statistics.component';
import { ProductsDashboardComponent } from './pages/products/children/products-dashboard/products-dashboard.component';

import { EnumsPageComponent } from './pages/enums/enums.component';
import { EnumsDashboardComponent } from './pages/enums/children/enum-dashboard/enum-dashboard.component';
import { BookingSourceBladeComponent } from './blades/booking-source/booking-source-blade/booking-source-blade.component';
import { CustomerTypeBladeComponent } from './blades/customer-type/customer-type-blade/customer-type-blade.component';
import { ElementTypeBladeComponent } from './blades/element-type/element-type-blade/element-type-blade.component';
import { LanguageBladeComponent } from './blades/language/language-blade/language-blade.component';
import { PageTypeBladeComponent } from './blades/page-type/page-type-blade/page-type-blade.component';
import { ProductImageTypeBladeComponent } from './blades/poduct-image-type/product-image-type-blade/product-image-type-blade.component';
import { ProductTextTypeBladeComponent } from './blades/poduct-text-type/product-text-type-blade/product-text-type-blade.component';
import { ProductTypeBladeComponent } from './blades/poduct-type/product-type-blade/product-type-blade.component';
import { TourGuideTypeBladeComponent } from './blades/tour-guide-type/tour-guide-type-blade/tour-guide-type-blade.component';
import { ProductBladeComponent } from './blades/product/product-blade/product-blade.component';
import { ProductBladeGenerallyComponent } from './blades/product/product-blade/components/product-generally/product-blade-generally.component';
import { ProductBladeSettingsComponent } from './blades/product/product-blade/components/product-settings/product-blade-settings.component';
import { ProductBladeImagesComponent } from './blades/product/product-blade/components/product-images/product-blade-images.component';
import { ProductBladeDatesComponent } from './blades/product/product-blade/components/product-dates/product-blade-dates.component';
import { ProductBladeCategoryComponent } from './blades/product/product-blade/components/product-category/product-blade-category.component';
import { ProductBladeTextsComponent } from './blades/product/product-blade/components/product-texts/product-blade-texts.component';
import { ProductBladePricesComponent } from './blades/product/product-blade/components/product-prices/product-blade-prices.component';
import { ProductDateBladeComponent } from './blades/poduct-date/product-date-blade/product-date-blade.component';
import { ProductDateTimeBladeComponent } from './blades/poduct-date-time/product-date-time-blade/product-date-time-blade.component';
import { ProductDateTimeCustomerTypeBladeComponent } from './blades/poduct-date-time-customer-type/product-date-time-customer-type-blade/product-date-time-customer-type-blade.component';
import { ProductDateTimeBladeCustomerTypeComponent } from './blades/poduct-date-time/product-date-time-blade/components/product-date-time-blade-customer-type/product-date-time-blade-customer-type.component';
import { ProductsBladeComponent } from './blades/product/products-blade/products-blade.component';
import { SupplierBladeComponent } from './blades/supplier/supplier-blade/supplier-blade.component';
import { SettingsBladeComponent } from './blades/settings/settings-blade/settings-blade.component';
import { SuppliersBladeComponent } from './blades/supplier/suppliers-blade/suppliers-blade.component';
import { SupplierBladeGenerallyComponent } from './blades/supplier/supplier-blade/components/supplier-generally/supplier-blade-generally.component';
import { SettingsGenerallyComponent } from './blades/settings/settings-blade/components/settings-generally/settings-generally.component';
import { ProductTextBladeComponent } from './blades/poduct-text/product-text-blade/product-text-blade.component';
import { ProductPriceBladeComponent } from './blades/poduct-price/product-price-blade/product-price-blade.component';
import { TextEditorModule } from './components/ckEditor/ckEditor.module';
import { AlertCardModule } from './components/alert-card/alert-card.module';

import { CategoryImageTypeBladeComponent } from './blades/category-image-type/category-image-type-blade/category-image-type-blade.component';
import { CategoryTextBladeComponent } from './blades/category-text/category-text-blade/category-text-blade.component';
import { CategoryBladeComponent } from './blades/category/category-blade/category-blade.component';
import { CategoryBladeGenerallyComponent } from './blades/category/category-blade/components/category-generally/category-blade-generally.component';
import { CategoryBladeTextsComponent } from './blades/category/category-blade/components/category-texts/category-blade-texts.component';
import { CategoryBladeImagesComponent } from './blades/category/category-blade/components/category-images/category-blade-images.component';
import { CategoriesBladeComponent } from './blades/category/categories-blade/categories-blade.component';
import { CategoryBladeTextsTextComponent } from './blades/category/category-blade/components/category-texts/components/category-blade-texts-text/category-blade-texts-text.component';
import { CategoryBladeProductComponent } from './blades/category/category-blade/components/category-product/category-blade-product.component';
import { CategoryBladeCategoryComponent } from './blades/category/category-blade/components/category-category/category-blade-category.component';

import { CategoriesPageComponent } from './pages/categories/categories.component';
import { CategoriesNewComponent } from './pages/categories/children/categories-new/categories-new.component';
import { CategoriesStatisticsComponent } from './pages/categories/children/categories-statistics/categories-statistics.component';
import { CategoriesDashboardComponent } from './pages/categories/children/categories-dashboard/categories-dashboard.component';
import { HomepagePageComponent } from './pages/homepage/homepage.component';
import { HomepageDashboardComponent } from './pages/homepage/children/homepage-dashboard/homepage-dashboard.component';
import { PagesBladeComponent } from './blades/page/pages-blade/pages-blade.component';
import { PageBladeComponent } from './blades/page/page-blade/page-blade.component';
import { PageBladeGenerallyComponent } from './blades/page/page-blade/components/page-generally/page-blade-generally.component';
import { PageBladeStylesComponent } from './blades/page/page-blade/components/page-styles/page-blade-styles.component';
import { EtageBladeColumnsComponent } from './blades/etage/etage-blade/components/etage-columns/etage-blade-columns.component';
import { EtageBladeComponent } from './blades/etage/etage-blade/etage-blade.component';
import { EtageBladeGenerallyComponent } from './blades/etage/etage-blade/components/etage-generally/etage-blade-generally.component';
import { EtageBladeStylesComponent } from './blades/etage/etage-blade/components/etage-styles/etage-blade-styles.component';
import { PageBladeEtagesComponent } from './blades/page/page-blade/components/page-etages/page-blade-etages.component';
import { ColumnBladeComponent } from './blades/column/column-blade/column-blade.component';
import { ColumnBladeGenerallyComponent } from './blades/column/column-blade/components/column-generally/column-blade-generally.component';
import { ColumnBladeStylesComponent } from './blades/column/column-blade/components/column-styles/column-blade-styles.component';
import { ColumnBladeElementsComponent } from './blades/column/column-blade/components/column-elements/column-blade-elements.component';
import { ElementBladeComponent } from './blades/element/element-blade/element-blade.component';
import { ElementVideoBlade } from './blades/element/element-video-blade/element-video.blade';
import { ElementBladeStylesComponent } from './blades/element/components/element-styles/element-blade-styles.component';
import { ElementProductBlade } from './blades/element/element-product-blade/element-product.blade';
import { ElementImageBlade } from './blades/element/element-image-blade/element-image.blade';
import { ElementProductBookingBlade } from './blades/element/element-product-booking-blade/element-product-booking.blade';
import { ElementProductListBlade } from './blades/element/element-product-list-blade/element-product-list.blade';
import { ElementSlideshowBlade } from './blades/element/element-slideshow-blade/element-blade-slideshow.blade';
import { MediaBladeComponent } from './blades/media/media-blade/media-blade.component';
import { UploadMediaBladeComponent } from './blades/media/upload-media-blade/upload-media-blade.component';
import { ImageElementComponent } from './directives/image-element/image-element.component';
import { VideoElementComponent } from './directives/video-element/video-element.component';

// import { MatVideoModule } from 'mat-video';

import { SitePageComponent } from './pages/site/site.component';

// import { SiteModule } from '../site/site.module';
import { SiteWYSIWYGComponent } from './components/site-wysiwyg/site-wysiwyg.component';
import { SiteSettingsBladeComponent } from './blades/site-settings-blade/site-settings-blade.component';
import { SiteSettingsBladeGenerallyComponent } from './blades/site-settings-blade/components/site-settings-generally/site-settings-blade-generally.component';
import { ProductBladeDateTimesComponent } from './blades/product/product-blade/components/product-dates/components/product-blade-date-time/product-blade-date-times.component';
import { ElementBladeGenerallyComponent } from './blades/element/components/element-generally/element-blade-generally.component';
import { ElementSlideshowBladeItemsComponent } from './blades/element/element-slideshow-blade/components/element-slideshow-blade-items/element-slideshow-blade-items.component';
import { ElementCardBlade } from './blades/element/element-card-blade/element-blade-card.blade';
import { ElementCartBlade } from './blades/element/element-cart-blade/element-blade-cart.blade';
import { ElementConfirmationBlade } from './blades/element/element-confirmation-blade/element-confirmation.blade';
import { ElementTextBlade } from './blades/element/element-text-blade/element-text.blade';
import { ElementHeadlineBlade } from './blades/element/element-headline-blade/element-headline.blade';
import { CustomersPageComponent } from './pages/customers/customers.component';
import { SettingsPageComponent } from './pages/settings/settings.component';
import { CustomersDashboardComponent } from './pages/customers/children/customers-dashboard/customers-dashboard.component';
import { CustomersListComponent } from './pages/customers/children/customers-list/customers-list.component';
import { CustomersNewComponent } from './pages/customers/children/customers-new/customers-new.component';
import { CustomersProgramComponent } from './pages/customers/children/customers-program/customers-program.component';
import { SlideshowItemBladeComponent } from './blades/slideshow-item/slideshow-item-blade/slideshow-item-blade.component';
import { SlideshowImageBladeComponent } from './blades/slideshow-image/slideshow-image-blade/slideshow-image-blade.component';
import { SlideshowCategoryBladeComponent } from './blades/slideshow-category/slideshow-category-blade/slideshow-category-blade.component';
import { DashboardWidgetTypeBladeComponent } from './blades/dashboard-widget-type/dashboard-widget-type-blade/dashboard-widget-type-blade.component';
import { PipeModule } from './pipes/pipe.module';
import { LoginModuleComponent } from './components/login-module/login-module.component';
import { LoginModalService } from './services/login-modal.service';
import { AuthGuard } from './gards/auth.gard';
import { TourBookingBladeNewComponent } from './blades/tour-booking/tour-booking-blade/components/tour-booking-new/tour-booking-blade-new.component';
import { TourBookingOrderComponent } from './blades/tour-booking/tour-booking-blade/components/tour-booking-order/tour-booking-order.component';
import { SettingsLanguageComponent } from './blades/settings/settings-blade/components/settings-language/settings-language.component';
import { SettingsBookingSourceComponent } from './blades/settings/settings-blade/components/settings-booking-source/settings-booking-source.component';
import { SettingsCustomerTypeComponent } from './blades/settings/settings-blade/components/settings-customer-type/settings-customer-type.component';
import { SettingsElementTypeComponent } from './blades/settings/settings-blade/components/settings-element-type/settings-element-type.component';
import { SettingsDashboardWidgetTypeComponent } from './blades/settings/settings-blade/components/settings-dashboard-widget-type/settings-dashboard-widget-type.component';
import { SettingsPageTypeComponent } from './blades/settings/settings-blade/components/settings-page-type/settings-page-type.component';
import { SettingsProductTypeComponent } from './blades/settings/settings-blade/components/settings-product-type/settings-product-type.component';
import { SettingsProductImageTypeComponent } from './blades/settings/settings-blade/components/settings-product-image-type/settings-product-image-type.component';
import { SettingsProductTextTypeComponent } from './blades/settings/settings-blade/components/settings-poduct-text-type/settings-product-text-type.component';
import { SettingsCategoryImageTypeComponent } from './blades/settings/settings-blade/components/settings-category-image-type/settings-category-image-type.component';
import { SettingsTourGuideTypeComponent } from './blades/settings/settings-blade/components/settings-tour-guide-type/settings-tour-guide-type.component';
import { HasRolesDirective } from './directives/has-roles/hasRoles.directive';
import { ProductBladeAddonsComponent } from './blades/product/product-blade/components/product-addons/product-blade-addons.component';
import { AddonBladeComponent } from './blades/addon/addon-blade/addon-blade.component';
import { AddonsBladeComponent } from './blades/addon/addons-blade/addons-blade.component';
import { TourBookingAddonBladeComponent } from './blades/tour-booking-addon/tour-booking-addon-blade/tour-booking-addon-blade.component';
import { TourBookingAddonBladeOrderItemsComponent } from './blades/tour-booking-addon/tour-booking-addon-blade/components/tour-booking-addon-order-items/tour-booking-addon-blade-order-items.component';
import { TourBookingAddonBladeGenerallyComponent } from './blades/tour-booking-addon/tour-booking-addon-blade/components/tour-booking-addon-generally/tour-booking-addon-blade-generally.component';
import { PickupLocationsBladeComponent } from './blades/pickup-location/pickup-locations-blade/pickup-locations-blade.component';
import { PickupLocationBladeComponent } from './blades/pickup-location/pickup-location-blade/pickup-location-blade.component';
import { PickupLocationBladeGenerallyComponent } from './blades/pickup-location/pickup-location-blade/components/pickup-location-generally/pickup-location-blade-generally.component';
import { PickupLocationBladePricesComponent } from './blades/pickup-location/pickup-location-blade/components/pickup-location-prices/pickup-location-blade-prices.component';
import { PickupLocationBladeTextsComponent } from './blades/pickup-location/pickup-location-blade/components/pickup-location-texts/pickup-location-blade-texts.component';
import { PickupLocationPriceBladeComponent } from './blades/pickup-location-price/pickup-location-price-blade/pickup-location-price-blade.component';
import { PickupLocationTextBladeComponent } from './blades/pickup-location-text/pickup-location-text-blade/pickup-location-text-blade.component';
import { TourBookingPickupLocationBladeComponent } from './blades/tour-booking-pickup-location/tour-booking-pickup-location-blade/tour-booking-pickup-location-blade.component';
import { TourBookingPickupLocationBladeOrderItemsComponent } from './blades/tour-booking-pickup-location/tour-booking-pickup-location-blade/components/tour-booking-pickup-location-order-items/tour-booking-pickup-location-blade-order-items.component';
import { TourBookingPickupLocationBladeGenerallyComponent } from './blades/tour-booking-pickup-location/tour-booking-pickup-location-blade/components/tour-booking-pickup-location-generally/tour-booking-pickup-location-blade-generally.component';
import { TourSummaryComponent } from './blades/tour/tour-blade/components/tour-summary/tour-summary.component';
import { TourMessagesComponent } from './blades/tour/tour-blade/components/tour-messages/tour-messages.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/token-interceptor.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { PageTextItemComponent } from './blades/page/page-blade/components/page-text/components/page-text-item/page-text-item.component';
import { PageBladeTextComponent } from './blades/page/page-blade/components/page-text/page-blade-text.component';
import { ElementService } from './services/element.service';
import { MenuItemsService } from './components/side-nav/services/menu-items.service';
import { AnalyticsPageComponent } from './pages/analytics/analytics.component';
import { AnalyticsWidgetTourBookingComponent } from './pages/analytics/widgets/tour-booking/tour-booking.component';

import { ChartsModule } from 'ng2-charts';
import { AnalyticsWidgetTourBookingStore } from './pages/analytics/widgets/tour-booking/stores/tour-booking.store';
import { CreataOrderTourBookingBladeComponent } from './blades/order/create-order-tour-booking-blade/create-order-tour-booking-blade.component';

import { MatRippleModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';

import { TourSummaryPickupComponent } from './blades/tour/tour-blade/components/tour-pickup/components/tour-summary-pickup/tour-summary-pickup.component';
import { TourSummaryCustomerTypeComponent } from './blades/tour/tour-blade/components/tour-summary/components/tour-summary-customer-type/tour-summary-customer-type.component';
import { TourSummaryAddonComponent } from './blades/tour/tour-blade/components/tour-summary/components/tour-summary-addon/tour-summary-addon.component';
import { TourBookingBladeBookingsAddonComponent } from './blades/tour-booking/tour-booking-blade/components/tour-booking-bookings/components/tour-booking-blade-bookings-addon/tour-booking-blade-bookings-addon.component';
import { TourBookingBladeBookingsCustomerTypeComponent } from './blades/tour-booking/tour-booking-blade/components/tour-booking-bookings/components/tour-booking-blade-bookings-customer-type/tour-booking-blade-bookings-customer-type.component';
import { TourBookingBladeBookingsPickupComponent } from './blades/tour-booking/tour-booking-blade/components/tour-booking-bookings/components/tour-booking-blade-bookings-pickup/tour-booking-blade-bookings-pickup.component';
import { ElementPaymentBlade } from './blades/element/element-payment-blade/element-blade-payment.blade';
import { SelectProductBladeComponent } from './blades/product/select-product-blade/select-product-blade.component';
import { ElementLoginBlade } from './blades/element/element-login-blade/element-blade-login.blade';
import { ElementProfileMenuBlade } from './blades/element/element-profile-menu-blade/element-blade-profile-menu.blade';
import { ElementProfileSubpageViewerBlade } from './blades/element/element-profile-subpage-viewer-blade/element-blade-profile-subpage-viewer.blade';
import { ElementProfileOrderBlade } from './blades/element/element-profile-order-blade/element-blade-profile-order.blade';
import { ElementProfileEditBlade } from './blades/element/element-profile-edit-blade/element-blade-profile-edit.blade';
import { SystemsBladeComponent } from './blades/system/systems-blade/systems-blade.component';
import { SystemBladeComponent } from './blades/system/system-blade/system-blade.component';
import { SystemsPageComponent } from './pages/system/systems.component';
import { SupplierBladeResourcesComponent } from './blades/supplier/supplier-blade/components/supplier-resources/supplier-blade-resources.component';
import { SupplierResourceBladeComponent } from './blades/supplier-resource/supplier-resource-blade/supplier-resource-blade.component';
import { SupplierResourceTypeBladeComponent } from './blades/supplier-resource/supplier-resource-type-blade/supplier-resource-type-blade.component';
import { SettingsSupplierResourceTypeComponent } from './blades/settings/settings-blade/components/settings-supplier-resource-type/settings-supplier-resource-type.component';
import { SupplierResourceProductTypeBladeComponent } from './blades/supplier-resource/supplier-resource-product-type-blade/supplier-resource-product-type-blade.component';
import { SettingsSupplierResourceProductTypeComponent } from './blades/settings/settings-blade/components/settings-supplier-resource-product-type/settings-supplier-resource-product-type.component';
import { TourResourceComponent } from './components/shared/tour-resource/tour-resource.component';
import { PlannerResourceBladeComponent } from './blades/planner/planner-resource-blade/planner-resource-blade.component';
import { PlannerPageComponent } from './pages/planner/planner.component';
import { PlannerResourceItemComponent } from './blades/planner/planner-resource-blade/components/planner-resource-item/planner-resource-item.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { SettingsEmailComponent } from './blades/settings/settings-blade/components/settings-email/settings-email.component';
import { EmailsBladeComponent } from './blades/email/emails-blade/emails-blade.component';
import { EmailBladeOutboxComponent } from './blades/email/emails-blade/components/email-outbox/email-blade-outbox.component';
import { EmailBladeComponent } from './blades/email/email-blade/email-blade.component';
import { TravelAgenciesBladeComponent } from './blades/travel-agency/travel-agencies-blade/travel-agencies-blade.component';
import { TravelAgencyBladeComponent } from './blades/travel-agency/travel-agency-blade/travel-agency-blade.component';
import { TravelAgencyBookingsComponent } from './blades/travel-agency/travel-agency-blade/components/travel-agency-bookings/travel-agency-bookings.component';
import { TravelAgencyGenerallyComponent } from './blades/travel-agency/travel-agency-blade/components/travel-agency-generally/travel-agency-generally.component';
import { TravelAgencyOrdersComponent } from './blades/travel-agency/travel-agency-blade/components/travel-agency-orders/travel-agency-orders.component';
import { UserTypeBladeComponent } from './blades/user-type/user-type-blade/user-type-blade.component';
import { UserBladeComponent } from './blades/user/user-blade/user-blade.component';
import { SettingsUserTypeComponent } from './blades/settings/settings-blade/components/settings-user-type/settings-user-type.component';
import { TravelAgencyUsersComponent } from './blades/travel-agency/travel-agency-blade/components/travel-agency-users/travel-agency-users.component';
import { TravelAgencyDiscountsComponent } from './blades/travel-agency/travel-agency-blade/components/travel-agency-discounts/travel-agency-discounts.component';
import { PickupLocationBladeGeoComponent } from './blades/pickup-location/pickup-location-blade/components/pickup-location-geo/pickup-location-blade-geo.component';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { SelectProductToOrderBladeComponent } from './blades/order/select-product-to-order-blade/select-product-to-order-blade.component';
import { SelectProductToOrderAddonsComponent } from './blades/order/select-product-to-order-blade/components/select-product-to-order-addons/select-product-to-order-addons.component';
import { SelectProductToOrderCustomerTypesComponent } from './blades/order/select-product-to-order-blade/components/select-product-to-order-customer-types/select-product-to-order-customer-types.component';
import { SelectProductToOrderPickupComponent } from './blades/order/select-product-to-order-blade/components/select-product-to-order-pickup/select-product-to-order-pickup.component';
import { OrderTourBookingsComponent } from './blades/order/order-blade/components/order-tour-bookings/order-tour-bookings.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { ElementButtonBlade } from './blades/element/element-button-blade/element-button.blade';
import { SettingsPaymentComponent } from './blades/settings/settings-blade/components/settings-payment/settings-payment.component';
import { GuideToursBladeComponent } from './blades/guide/guide-tours-blade/guide-tours-blade.component';
import { GuideToursPageComponent } from './pages/guide/guide-tours.component';
import { GuideTourBladeComponent } from './blades/guide/guide-tour-blade/guide-tour-blade.component';
import { GuideTourSummaryComponent } from './blades/guide/guide-tour-blade/components/tour-summary/tour-summary.component';
import { GuideTourAddonsComponent } from './blades/guide/guide-tour-blade/components/tour-addons/tour-addons.component';
import { GuideTourCustomersComponent } from './blades/guide/guide-tour-blade/components/tour-customers/tour-customers.component';
import { GuideTourPickupComponent } from './blades/guide/guide-tour-blade/components/tour-pickup/tour-pickup.component';
import { TourSummaryMessageComponent } from './blades/tour/tour-blade/components/tour-summary/components/tour-summary-message/tour-summary-message.component';
import { ProductFunctionsBladeComponent } from './blades/product-functions/product-functions-blade/product-functions-blade.component';
import { OddFunctionBladeComponent } from './blades/product-functions/odd-function-blade/odd-function-blade.component';
import { OddFunctionBladeGenerallyComponent } from './blades/product-functions/odd-function-blade/components/function-generally/function-blade-generally.component';
import { OddFunctionBladeMessagesComponent } from './blades/product-functions/odd-function-blade/components/function-messages/function-blade-messages.component';
import { OddFunctionBladeAddonsComponent } from './blades/product-functions/odd-function-blade/components/function-addons/function-blade-addons.component';
import { OddFunctionBladeProductsComponent } from './blades/product-functions/odd-function-blade/components/function-products/function-blade-products.component';
import { UsersBladeComponent } from './blades/user/users-blade/users-blade.component';
import { UsersPageComponent } from './pages/users/users.component';
import { PaymentNetsComponent } from './blades/payment/payment-blade/components/payment-nets/payment-nets.component';
import { PaymentTypeBladeComponent } from './blades/payment-type/payment-type-blade/payment-type-blade.component';
import { SettingsPaymentTypeComponent } from './blades/settings/settings-blade/components/settings-payment-type/settings-payment-type.component';
import { ProductExcludeDateBladeComponent } from './blades/poduct-exclude-date/product-exclude-date-blade/product-exclude-date-blade.component';
import { SettingsEmailTemplatesComponent } from './blades/settings/settings-blade/components/settings-email-templates/settings-email-templates.component';
import { SettingsEmailTemplateNewOrderComponent } from './blades/settings/settings-blade/components/settings-email-templates/components/settings-email-template-new-order/settings-email-template-new-order.component';
import { NewOrderTemplateBladeComponent } from './blades/new-order-template/new-order-template-blade/new-order-template-blade.component';
import { NewCustomerTemplateBladeComponent } from './blades/new-customer-template/new-customer-template-blade/new-customer-template-blade.component';
import { SettingsEmailTemplateNewCustomerComponent } from './blades/settings/settings-blade/components/settings-email-templates/components/settings-email-template-new-customer/settings-email-template-new-customer.component';
import { ProductPickupLocationBladeComponent } from './blades/poduct-pickup-location/product-pickup-location-blade/product-pickup-location-blade.component';
import { ProductBladePickupLocationComponent } from './blades/product/product-blade/components/product-pickup-locations/product-blade-pickup-locations.component';
import { EmailModule } from 'app/modules/email/email.module';
import { TourPickupComponent } from './blades/tour/tour-blade/components/tour-pickup/tour-pickup.component';
import { CustomerTypeBladeGenerallyComponent } from './blades/customer-type/customer-type-blade/components/customer-type-generally/customer-type-generally.component';
import { CustomerTypeBladeTextsComponent } from './blades/customer-type/customer-type-blade/components/customer-type-texts/customer-type-blade-texts.component';
import { CustomerTypeTextBladeComponent } from './blades/customer-type-text/customer-type-text-blade/customer-type-text-blade.component';
import { SettingsSiteDataComponent } from './blades/settings/settings-blade/components/settings-site-data/settings-site-data.component';
import { SiteDataBladeComponent } from './blades/site-data/site-data-blade/site-data-blade.component';
import { MinFunctionBladeComponent } from './blades/product-functions/min-function-blade/min-function-blade.component';
import { MinFunctionBladeGenerallyComponent } from './blades/product-functions/min-function-blade/components/function-generally/function-blade-generally.component';
import { MinFunctionBladeMessagesComponent } from './blades/product-functions/min-function-blade/components/function-messages/function-blade-messages.component';
import { MinFunctionBladeProductsComponent } from './blades/product-functions/min-function-blade/components/function-products/function-blade-products.component';
import { TravelAgencyCustomersComponent } from './blades/travel-agency/travel-agency-blade/components/travel-agency-customers/travel-agency-customers.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { PaymentsPageComponent } from './pages/payments/payments.component';
import { PaymentBladeComponent } from './blades/payment/payment-blade/payment-blade.component';
import { PaymentsBladeComponent } from './blades/payment/payments-blade/payments-blade.component';
import { ProductBladeToursComponent } from './blades/product/product-blade/components/product-tours/product-blade-tours.component';
import { SupplierBladeToursComponent } from './blades/supplier/supplier-blade/components/supplier-tours/supplier-blade-tours.component';
import { CustomerProgramComponent } from './components/shared/customer-program/customer-program.component';
import { ToursProgramComponent } from './pages/suppliers/children/tours-program/tours-program.component';
import { SuppliersPageComponent } from './pages/suppliers/suppliers.component';
import { SupplierTourProgramComponent } from './blades/supplier/supplier-blade/components/supplier-tour-program/supplier-tour-program.component';
import { TourCustomerProgramComponent } from './blades/tour/tour-blade/components/tour-customer-program/tour-customer-program.component';
import { ToursCustomersProgramComponent } from './pages/tours/children/tours-customers-program/tours-customers-program.component';
import { ToursCustomersProgramCustomerComponent } from './pages/tours/children/tours-customers-program/components/tours-customers-program-customer/tours-customers-program-customer.component';
import { TourBookingMessagesComponent } from './blades/tour-booking/tour-booking-blade/components/tour-booking-messages/tour-booking-messages.component';
import { ProductPickupLocationPriceComponent } from './blades/product/product-blade/components/product-pickup-locations/components/product-pickup-location-price/product-pickup-location-price.component';
import { ProductPickupLocationPriceEditComponent } from './blades/poduct-pickup-location/product-pickup-location-blade/components/product-pickup-location-price-edit/product-pickup-location-price-edit.component';
import { ProductAddonBladeComponent } from './blades/product-addon/product-addon-blade/product-addon-blade.component';
import { SupplierBladeResourceAddonComponent } from './blades/supplier/supplier-blade/components/supplier-resources/components/supplier-blade-resource-addon/supplier-blade-resource-addon.component';
import { Angulartics2Module } from 'angulartics2';
import { DialogEditModule } from 'app/modules/dialog-edit/dialog-edit.module';
import { SettingsElementLinkTypeComponent } from './blades/settings/settings-blade/components/settings-element-link-type/settings-element-link-type.component';
import { ColumnLinkTypeBladeComponent } from './blades/column-link-type/column-link-type-blade/column-link-type-blade.component';
import { ColumnBladeLinkComponent } from './blades/column/column-blade/components/column-link/column-blade-link.component';
import { AddonBladeGenerallyComponent } from './blades/addon/addon-blade/components/addon-generally/addon-blade-generally.component';
import { AddonTextBladeComponent } from './blades/addon-text/addon-text-blade/addon-text-blade.component';
import { AddonBladeTextsComponent } from './blades/addon/addon-blade/components/addon-texts/addon-blade-texts.component';
import { OpenCustomerBladeComponent } from './components/shared/open-blade/open-customer-blade/open-customer-blade.component';
import { UserPageAccountComponent } from './pages/user-page/controllers/user-page-account/user-page-account.component';
import { UserPageServiceComponent } from './pages/user-page/controllers/user-page-service/user-page-service.component';
import { UserPagePasswordComponent } from './pages/user-page/controllers/user-page-password/user-page-password.component';
import { UserBladeServiceComponent } from './blades/user/user-blade/components/user-blade-service/user-blade-service.component';
import { ElementCategoryListBlade } from './blades/element/element-category-list-blade/element-category-list.blade';
import { ElementCategoryBlade } from './blades/element/element-category-blade/element-category.blade';

import { ToastrModule } from 'ngx-toastr';
import { SpecialDietsBladeComponent } from './blades/special-diet/special-diets-blade/special-diets-blade.component';
import { SpecialDietBladeComponent } from './blades/special-diet/special-diet-blade/special-diet-blade.component';
import { SpecialDietBladeGenerallyComponent } from './blades/special-diet/special-diet-blade/components/special-diet-generally/special-diet-blade-generally.component';
import { SpecialDietBladeTextsComponent } from './blades/special-diet/special-diet-blade/components/special-diet-texts/special-diet-blade-texts.component';
import { SpecialDietTextBladeComponent } from './blades/special-diet-text/special-diet-text-blade/special-diet-text-blade.component';
import { IconDirective } from './directives/icon/icon.directive';
import { TourSummarySpecialDietComponent } from './blades/tour/tour-blade/components/tour-summary/components/tour-summary-special-diet/tour-summary-special-diet.component';
import { AdminDataModule } from 'app/modules/admin-data/admin-data.module';
import { PlannerGuidesToursBladeComponent } from './blades/planner/planner-guides-tours-blade/planner-guides-tours-blade.component';
import { PlannerGuidesToursGuideItemComponent } from './blades/planner/planner-guides-tours-blade/components/planner-guides-tours-guide-item/planner-guides-tours-guide-item.component';
import { PlannerGuidesToursGuideTotalTimeItemComponent } from './blades/planner/planner-guides-tours-blade/components/planner-guides-tours-guide-total-time-item/planner-guides-tours-guide-total-time-item.component';
import { SettingsTrashcanComponent } from './blades/settings/settings-blade/components/settings-trashcan/settings-trashcan.component';
import { ElementFormBlade } from './blades/element/element-form-blade/element-form.blade';
import { FormBuilderComponent } from './blades/element/element-form-blade/components/form-builder/form-builder.component';
import { FormBuilderElementComponent } from './blades/element/element-form-blade/components/form-builder/components/form-builder-element/form-builder-element.component';
import { ReportPageComponent } from './pages/report/report.component';
import { SpecialDietReportComponent } from './pages/report/pages/special-diet-report/special-diet-report.component';
import { SpecialDietReportOnTourTableComponent } from './pages/report/pages/special-diet-report/components/special-diet-report-on-tour-table/special-diet-report-on-tour-table.component';
import { SpecialDietReportOnCustomerComponent } from './pages/report/pages/special-diet-report/components/special-diet-report-on-customer-table/special-diet-report-on-customer-table.component';
import { EtageBladeBackgroundComponent } from './blades/etage/etage-blade/components/etage-background/etage-blade-background.component';
import { TermTypeBladeComponent } from './blades/term-type/term-type-blade/term-type-blade.component';
import { TermTypeBladeGenerallyComponent } from './blades/term-type/term-type-blade/components/term-type-generally/term-type-generally.component';
import { SettingsTermTypeComponent } from './blades/settings/settings-blade/components/settings-term-type/settings-term-type.component';
import { TermBladeGenerallyComponent } from './blades/term/term-blade/components/term-generally/term-blade-generally.component';
import { TermBladeTextsComponent } from './blades/term/term-blade/components/term-texts/term-blade-texts.component';
import { TermBladeComponent } from './blades/term/term-blade/term-blade.component';
import { TermTextBladeComponent } from './blades/term/term-text-blade/term-text-blade.component';
import { TermsBladeComponent } from './blades/term/terms-blade/terms-blade.component';
import { ProductBladeSirvoyComponent } from './blades/product/product-blade/components/product-sirvoy/product-blade-sirvoy.component';
import { OrderTourBookingItemComponent } from './components/shared/order-tour-booking-item/order-tour-booking-item.component';
import { NewOrderDraftTemplateBladeComponent } from './blades/new-order-draft-template/new-order-draft-template-blade/new-order-draft-template-blade.component';
import { SettingsEmailTemplateNewOrderDraftComponent } from './blades/settings/settings-blade/components/settings-email-templates/components/settings-email-template-new-order-draft/settings-email-template-new-order-draft.component';
import { CustomerProgramPage } from './pages/customers/children/customer-program/customer-program.component';
import { CustomerProgramTourComponent } from './pages/customers/children/customer-program/components/customer-program-tour/customer-program-tour.component';

const angularMaterial = [
	MatSidenavModule,
	MatToolbarModule,
	MatListModule,
	MatIconModule,
	MatButtonModule,
	MatInputModule,
	MatFormFieldModule,
	FlexLayoutModule,
	MatCardModule,
	MatTableModule,
	MatPaginatorModule,
	MatRippleModule,
	MatSortModule,
	MatCheckboxModule,
	MatSelectModule,
	MatTabsModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatDatepickerModule,
	// MatNativeDateModule,
	MatMomentDateModule,
	MatSlideToggleModule,
	MatDialogModule,
	MatSnackBarModule,
	MatTooltipModule,
	MatExpansionModule,
	DragDropModule,
	MatRadioModule,
	MatAutocompleteModule,
	MatMenuModule,
];

const modules = [
	BladeModule,
	DashboardModule,
	NgJsonEditorModule,
	ChartsModule,
	AngularOpenlayersModule,
	EmailModule,
	DialogEditModule,
	ToastrModule.forRoot(),
];

const pages = [
	DashboardPageComponent,
	SitePageComponent,
	AnalyticsPageComponent,
	AnalyticsWidgetTourBookingComponent,
];

const components = [
	MenuListItemComponent,
	SiteWYSIWYGComponent,
];

const directive = [
	ImageElementComponent,
	VideoElementComponent,
	HasRolesDirective,
	IconDirective,
];

const blades = [
	GuideToursBladeComponent,
	GuideTourBladeComponent,
	ToursBladeComponent,
	TourBladeComponent,
	TourGuideBladeComponent,
	BookingSourceBladeComponent,
	AddonTextBladeComponent,
	AddonBladeTextsComponent,

	TermTypeBladeComponent,
	TermBladeComponent,
	TermTextBladeComponent,
	TermsBladeComponent,

	SpecialDietBladeTextsComponent,
	SpecialDietTextBladeComponent,

	OrderBladeComponent,
	OrdersBladeComponent,
	OrderItemBladeComponent,
	PaymentBladeComponent,
	PaymentsBladeComponent,
	CreataOrderTourBookingBladeComponent,
	SelectProductToOrderBladeComponent,
	SelectProductToOrderAddonsComponent,
	SelectProductToOrderCustomerTypesComponent,
	SelectProductToOrderPickupComponent,
	ElementCardBlade,
	ElementButtonBlade,
	ElementCartBlade,
	ElementConfirmationBlade,
	ElementTextBlade,
	ElementHeadlineBlade,

	ElementFormBlade,
	FormBuilderComponent,
	FormBuilderElementComponent,

	ElementImageBlade,
	ElementProductBlade,
	ElementVideoBlade,
	ElementProductBookingBlade,
	ElementProductListBlade,
	ElementCategoryListBlade,
	ElementCategoryBlade,
	ElementSlideshowBlade,
	ElementPaymentBlade,
	ElementLoginBlade,
	ElementProfileMenuBlade,
	ElementProfileSubpageViewerBlade,
	ElementProfileOrderBlade,
	ElementProfileEditBlade,
	TourBookingBladeComponent,
	TourBookingCustomerTypeBladeComponent,
	TourBookingAddonBladeComponent,
	TourBookingPickupLocationBladeComponent,
	CustomerBladeComponent,
	CustomersBladeComponent,
	TravelAgenciesBladeComponent,
	TravelAgencyBladeComponent,
];

const bladeComponents = [
	TourGenerallyComponent,
	TourBookingsComponent,
	TourGuidesComponent,
	TourSummaryComponent,
	TourSummaryPickupComponent,
	TourSummaryCustomerTypeComponent,
	TourSummaryAddonComponent,
	TourSummaryMessageComponent,
	TourMessagesComponent,
	TourResourceComponent,
	TourPickupComponent,
	TourCustomerProgramComponent,
	TourSummarySpecialDietComponent,

	TermTypeBladeGenerallyComponent,
	TermBladeGenerallyComponent,
	TermBladeTextsComponent,

	GuideTourSummaryComponent,
	GuideTourAddonsComponent,
	GuideTourCustomersComponent,
	GuideTourPickupComponent,

	OrderGenerallyComponent,
	OrderItemsComponent,
	OrderPaymentsComponent,
	OrderTourBookingsComponent,
	OrderTourBookingItemComponent,
	PaymentNetsComponent,

	SupplierBladeGenerallyComponent,
	SupplierBladeResourcesComponent,
	SupplierBladeResourceAddonComponent,
	SupplierBladeToursComponent,
	SupplierTourProgramComponent,

	SettingsGenerallyComponent,
	SettingsLanguageComponent,
	SettingsBookingSourceComponent,
	SettingsCustomerTypeComponent,
	SettingsElementTypeComponent,
	SettingsElementLinkTypeComponent,
	SettingsDashboardWidgetTypeComponent,
	SettingsPageTypeComponent,
	SettingsProductTypeComponent,
	SettingsSiteDataComponent,
	SettingsSupplierResourceTypeComponent,
	SettingsSupplierResourceProductTypeComponent,
	SettingsProductImageTypeComponent,
	SettingsProductTextTypeComponent,
	SettingsCategoryImageTypeComponent,
	SettingsTourGuideTypeComponent,
	SettingsTermTypeComponent,
	SettingsTrashcanComponent,
	SettingsEmailComponent,
	SettingsUserTypeComponent,
	SettingsPaymentComponent,
	SettingsPaymentTypeComponent,
	SettingsEmailTemplatesComponent,
	SettingsEmailTemplateNewOrderComponent,
	SettingsEmailTemplateNewCustomerComponent,
	SettingsEmailTemplateNewOrderDraftComponent,

	PlannerResourceItemComponent,
	PlannerGuidesToursGuideItemComponent,
	PlannerGuidesToursGuideTotalTimeItemComponent,

	TourBookingBladeGenerallyComponent,
	TourBookingBladeBookingsComponent,
	TourBookingBladeBookingsAddonComponent,
	TourBookingBladeBookingsCustomerTypeComponent,
	TourBookingBladeBookingsPickupComponent,
	TourBookingBladeCustomerComponent,
	TourBookingOrderComponent,
	TourBookingMessagesComponent,
	TourBookingCustomerTypeBladeGenerallyComponent,
	TourBookingCustomerTypeBladeOrderItemsComponent,
	TourBookingPickupLocationBladeOrderItemsComponent,
	TourBookingPickupLocationBladeGenerallyComponent,
	TourBookingBladeNewComponent,
	TourBookingAddonBladeOrderItemsComponent,
	TourBookingAddonBladeGenerallyComponent,
	CustomerGenerallyComponent,
	CustomerBookingsComponent,
	CustomerOrdersComponent,
	CustomerProgramComponent,

	TravelAgencyBookingsComponent,
	TravelAgencyGenerallyComponent,
	TravelAgencyOrdersComponent,
	TravelAgencyUsersComponent,
	TravelAgencyDiscountsComponent,
	TravelAgencyCustomersComponent,
];

const stores = [
	AnalyticsWidgetTourBookingStore,
];

const MY_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'YYYY-MM-DD',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'YYYY-MM-DD',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

import { NgxSpinnerModule } from 'ngx-spinner';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
	entryComponents: [
		...blades,
		CustomerTypeBladeComponent,
		CustomerTypeBladeGenerallyComponent,
		CustomerTypeBladeTextsComponent,

		CustomerTypeTextBladeComponent,

		ColumnLinkTypeBladeComponent,

		DashboardWidgetTypeBladeComponent,
		ElementTypeBladeComponent,
		PaymentTypeBladeComponent,
		PaymentBladeComponent,
		PaymentsBladeComponent,
		LanguageBladeComponent,
		PageTypeBladeComponent,
		ProductImageTypeBladeComponent,
		ProductTextTypeBladeComponent,
		ProductTypeBladeComponent,
		TourGuideTypeBladeComponent,
		AddonsBladeComponent,
		AddonBladeComponent,

		SpecialDietsBladeComponent,
		SpecialDietBladeComponent,

		ProductBladeComponent,
		SelectProductBladeComponent,
		ProductsBladeComponent,
		ProductTextBladeComponent,
		ProductPriceBladeComponent,
		ProductPickupLocationBladeComponent,
		ProductDateBladeComponent,
		ProductExcludeDateBladeComponent,
		ProductDateTimeBladeComponent,
		ProductDateTimeCustomerTypeBladeComponent,
		SupplierBladeComponent,
		SettingsBladeComponent,
		SuppliersBladeComponent,
		SupplierResourceBladeComponent,
		SupplierResourceProductTypeBladeComponent,
		SupplierResourceTypeBladeComponent,
		SlideshowItemBladeComponent,
		SlideshowImageBladeComponent,
		SlideshowCategoryBladeComponent,
		SystemBladeComponent,

		UserBladeComponent,
		UserTypeBladeComponent,
		UsersBladeComponent,

		PlannerResourceBladeComponent,
		PlannerGuidesToursBladeComponent,

		CategoryImageTypeBladeComponent,
		CategoryBladeComponent,
		CategoryBladeGenerallyComponent,
		CategoryBladeTextsComponent,
		CategoryBladeImagesComponent,
		CategoryBladeTextsTextComponent,
		CategoryTextBladeComponent,
		CategoriesBladeComponent,

		PagesBladeComponent,
		PageBladeComponent,

		ProductFunctionsBladeComponent,
		OddFunctionBladeComponent,
		MinFunctionBladeComponent,

		EtageBladeComponent,

		EmailsBladeComponent,
		EmailBladeComponent,

		ColumnBladeComponent,

		ElementBladeComponent,

		MediaBladeComponent,
		UploadMediaBladeComponent,

		NewOrderTemplateBladeComponent,
		NewCustomerTemplateBladeComponent,
		NewOrderDraftTemplateBladeComponent,

		SiteSettingsBladeComponent,

		SiteDataBladeComponent,

		LoginModuleComponent,

		PickupLocationsBladeComponent,
		PickupLocationBladeComponent,
		PickupLocationTextBladeComponent,
		PickupLocationPriceBladeComponent,

		CustomerListComponent,
	],
	declarations: [
		...blades,
		...bladeComponents,
		...directive,
		...pages,
		ToursPageComponent,
		UsersPageComponent,
		PaymentsPageComponent,
		GuideToursPageComponent,
		SystemsPageComponent,
		ToursNewComponent,
		ToursStatisticsComponent,
		ToursDashboardComponent,
		ToursListComponent,
		ToursCustomersProgramComponent,
		ToursCustomersProgramCustomerComponent,
		ProductsPageComponent,
		ProductsNewComponent,
		ProductsStatisticsComponent,
		ProductsDashboardComponent,

		ReportPageComponent,
		SpecialDietReportComponent,
		SpecialDietReportOnTourTableComponent,
		SpecialDietReportOnCustomerComponent,

		AdminAppComponent,
		SideNavComponent,
		OrdersPageComponent,
		PlannerPageComponent,
		CustomersPageComponent,
		SettingsPageComponent,
		UserPageComponent,
		components,
		OrdersDashboardComponent,
		CustomersDashboardComponent,
		CustomersListComponent,
		CustomersNewComponent,

		CustomersProgramComponent,
		CustomerProgramPage,
		CustomerProgramTourComponent,

		OrdersNewComponent,
		OrdersStatisticsComponent,
		OrdersListComponent,
		EnumsPageComponent,
		EnumsDashboardComponent,
		SuppliersPageComponent,
		ToursProgramComponent,

		CustomerTypeBladeComponent,
		CustomerTypeBladeGenerallyComponent,
		CustomerTypeBladeTextsComponent,

		CustomerTypeTextBladeComponent,

		ColumnLinkTypeBladeComponent,

		DashboardWidgetTypeBladeComponent,
		ElementTypeBladeComponent,
		PaymentTypeBladeComponent,
		PaymentBladeComponent,
		PaymentsBladeComponent,
		LanguageBladeComponent,
		PageTypeBladeComponent,
		ProductImageTypeBladeComponent,
		ProductTextTypeBladeComponent,
		ProductTypeBladeComponent,
		TourGuideTypeBladeComponent,
		AddonsBladeComponent,
		AddonBladeComponent,

		AddonBladeGenerallyComponent,

		SpecialDietBladeComponent,
		SpecialDietsBladeComponent,

		SpecialDietBladeGenerallyComponent,

		UserBladeComponent,
		UserTypeBladeComponent,
		UsersBladeComponent,

		ProductBladeComponent,
		ProductBladeGenerallyComponent,
		ProductPickupLocationPriceComponent,
		ProductBladeImagesComponent,
		ProductBladePickupLocationComponent,
		ProductAddonBladeComponent,
		ProductBladeTextsComponent,
		ProductBladePricesComponent,
		ProductBladeDatesComponent,
		ProductBladeCategoryComponent,
		ProductBladeSettingsComponent,
		ProductBladeSirvoyComponent,
		ProductsBladeComponent,
		ProductBladeDateTimesComponent,
		ProductBladeAddonsComponent,
		ProductBladeToursComponent,
		SelectProductBladeComponent,

		SupplierBladeComponent,
		SettingsBladeComponent,
		SuppliersBladeComponent,
		SupplierResourceBladeComponent,
		SupplierResourceProductTypeBladeComponent,
		SupplierResourceTypeBladeComponent,
		SystemsBladeComponent,
		SystemBladeComponent,

		PlannerResourceBladeComponent,
		PlannerGuidesToursBladeComponent,

		ProductTextBladeComponent,
		ProductPriceBladeComponent,

		ProductPickupLocationBladeComponent,
		ProductPickupLocationPriceEditComponent,

		ProductDateBladeComponent,
		ProductExcludeDateBladeComponent,
		ProductDateTimeBladeComponent,
		ProductDateTimeCustomerTypeBladeComponent,
		ProductDateTimeBladeCustomerTypeComponent,
		SlideshowItemBladeComponent,
		SlideshowImageBladeComponent,
		SlideshowCategoryBladeComponent,

		CategoryImageTypeBladeComponent,
		CategoryTextBladeComponent,
		CategoryBladeComponent,
		CategoryBladeGenerallyComponent,
		CategoryBladeTextsComponent,
		CategoryBladeImagesComponent,
		CategoryBladeTextsTextComponent,
		CategoryBladeProductComponent,
		CategoryBladeCategoryComponent,
		CategoriesBladeComponent,
		CategoriesPageComponent,
		CategoriesDashboardComponent,
		CategoriesNewComponent,
		CategoriesStatisticsComponent,

		HomepagePageComponent,
		HomepageDashboardComponent,

		PagesBladeComponent,
		PageBladeComponent,
		PageBladeGenerallyComponent,
		PageBladeTextComponent,
		PageTextItemComponent,
		PageBladeEtagesComponent,
		PageBladeStylesComponent,

		ProductFunctionsBladeComponent,
		OddFunctionBladeComponent,
		OddFunctionBladeGenerallyComponent,
		OddFunctionBladeMessagesComponent,
		OddFunctionBladeAddonsComponent,
		OddFunctionBladeProductsComponent,

		MinFunctionBladeComponent,
		MinFunctionBladeGenerallyComponent,
		MinFunctionBladeMessagesComponent,
		MinFunctionBladeProductsComponent,

		EtageBladeColumnsComponent,
		EtageBladeGenerallyComponent,
		EtageBladeComponent,
		EtageBladeStylesComponent,
		EtageBladeBackgroundComponent,

		EmailsBladeComponent,
		EmailBladeOutboxComponent,
		EmailBladeComponent,


		ColumnBladeComponent,
		ColumnBladeGenerallyComponent,
		ColumnBladeElementsComponent,
		ColumnBladeStylesComponent,
		ColumnBladeLinkComponent,

		ElementBladeComponent,
		ElementBladeGenerallyComponent,
		ElementBladeStylesComponent,
		ElementSlideshowBladeItemsComponent,

		MediaBladeComponent,
		UploadMediaBladeComponent,

		NewOrderTemplateBladeComponent,
		NewCustomerTemplateBladeComponent,
		NewOrderDraftTemplateBladeComponent,

		SiteSettingsBladeComponent,
		SiteSettingsBladeGenerallyComponent,

		SiteDataBladeComponent,

		LoginModuleComponent,

		PickupLocationsBladeComponent,
		PickupLocationBladeComponent,
		PickupLocationTextBladeComponent,
		PickupLocationPriceBladeComponent,
		PickupLocationBladeGenerallyComponent,
		PickupLocationBladePricesComponent,
		PickupLocationBladeTextsComponent,
		PickupLocationBladeGeoComponent,

		CustomerListComponent,

		OpenCustomerBladeComponent,

		UserPageAccountComponent,
		UserPageServiceComponent,
		UserPagePasswordComponent,

		UserBladeServiceComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		...modules,
		...angularMaterial,
		MonacoEditorModule.forRoot(),
		AdminRoutingModule,
		TextEditorModule,
		AlertCardModule,
		// MatVideoModule,
		Angulartics2Module.forRoot(),
		PipeModule,
		BrowserModule.withServerTransition({ appId: 'QuestSystem' }),
		BrowserAnimationsModule,
		HttpClientModule,
		AdminDataModule,
		LoadingModule,
		ClipboardModule,
		NgxSpinnerModule,
		MarkdownModule.forRoot(),
		// NgxLocalStorageModule.forRoot({prefix: 'QuestSystem'}),

	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
		{ provide: LOCALE_ID, useValue: 'sv' },
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
		LoginModalService,
		AuthGuard,
		AnalyticsService,
		ElementService,
		MenuItemsService,
		...stores,
	],
	bootstrap: [AdminAppComponent],
})
export class AdminModule { }
