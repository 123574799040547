import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Service } from 'app/admin/models/service.mode';
import { UserService } from 'app/admin/services/user.service';
import { UserServiceStore } from 'app/admin/stores/user-service.store.service';
import { combineLatest, ReplaySubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-user-blade-service',
  templateUrl: './user-blade-service.component.html',
  styleUrls: ['./user-blade-service.component.scss']
})
export class UserBladeServiceComponent implements OnInit {

	public servicesReplay$ = new ReplaySubject<Service[]>(1);
	public selection: SelectionModel<Service>;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public userServiceStore: UserServiceStore,
		public userService: UserService,
	) {
		combineLatest([
			this.userServiceStore.getAllServices(),
			this.userServiceStore.items$
		])
		.pipe(untilDestroyed(this))
		.subscribe(([services, userServices]) => {
			console.log('*** Calculate ***');
			this.servicesReplay$.next(services);
			var selectedServices = services.filter(x => userServices.map(y => y.serviceId).includes(x.id));
			this.selection = new SelectionModel<Service>(true, selectedServices);
		});
		this.userServiceStore.getAllOnUser(this.bladeItem.id).subscribe();
	}

  	ngOnInit() { }

	public selectService(service: Service): void {
		if (this.selection.isSelected(service)) {
			this.userServiceStore.remove(this.bladeItem.id, service.id).subscribe();
		} else {
			this.userServiceStore.add(this.bladeItem.id, service.id).subscribe()
		}
		this.selection.toggle(service);
	}
}
