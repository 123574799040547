import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../models/user-service.model';
import { Service } from '../models/service.mode';

@Injectable({
	providedIn: 'root',
})
export class UserServiceStore extends BaseStore<UserService> {
	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'UserService', baseUrl, snackBarService);
	}

	public add(userId: number, serviceId): Observable<UserService> {
		return this.http.put<UserService>(`${this.host}/user/${userId}/service/${serviceId}`, {}).pipe(tap(addedService => {
			this._items.push(addedService);
			this._itemsSubject.next(this._items);
			this.snackBarService.showSuccess('Tjänsten är tillagd på användaren');
		}));
	}

	public remove(userId: number, serviceId): Observable<UserService> {
		return this.http.delete<UserService>(`${this.host}/user/${userId}/service/${serviceId}`).pipe(tap(isRemoved => {
			if(isRemoved) {
				this._items = this._items.filter(x => x.userId === userId && x.serviceId !== serviceId);
				this._itemsSubject.next(this._items);
				this.snackBarService.showSuccess('Tjänsten är borttagen på användaren');
			}
		}));
	}

	public getAllOnUser(userId: number): Observable<UserService[]> {
		return this.http.get<UserService[]>(`${this.host}/user/${userId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllServices(): Observable<Service[]> {
		return this.http.get<Service[]>(`${this.host}/services`);
	}
}
