import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { CategoryText } from 'app/models/category/category-text.model';
import { BaseStore } from '../base.store.service';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class CategoryTextStore extends BaseStore<CategoryText> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'CategoryTexts', baseUrl, snackBarService);
	}

	public getAllOnCategory(categoryId: number): Observable<CategoryText[]> {
		return this.http.get<CategoryText[]>(`${this.host}/category/${categoryId}`).pipe(tap(categoryTexts => {
			this._items = categoryTexts;
			this._itemsSubject.next(this._items);
		}));
	}

	public getOnCategoryAndLanguage(categoryId: number, languageId: number): Observable<CategoryText> {
		return this.http.get<CategoryText>(`${this.host}/category/${categoryId}/language/${languageId}`).pipe(tap(categoryText => {
			if (categoryText) {
				const findObj = _.find(this._items, type => type.categoryId === categoryText.categoryId
									&& type.languageId === categoryText.languageId);
				_.assign(findObj, categoryText); // Keep object in sync
			}
		}));
	}

	public addCategoryText(categoryText: CategoryText) {
		this._items.push(categoryText);
		this._itemsSubject.next(this._items);
	}

	public removeCategoryText(categoryText: CategoryText) {
		_.remove(this._items, categoryText);
		this._itemsSubject.next(this._items);
	}
}
