<ng-container *ngIf="{
	adminText: 'widget.orderRest' | adminText | async,
	orders: dashboardWidgetOrderRestService.orders$ | async,
	isLoading: dashboardWidgetOrderRestService.isLoading$ | async
} as source">
	<p class="m-0">{{source.adminText?.description}} <b>{{dashboardWidgetOrderRestService.formGroup.value.fromDate | date:'yyyy-MM-dd'}}</b> {{source.adminText?.descriptionAnd}} <b>{{dashboardWidgetOrderRestService.formGroup.value.toDate | date:'yyyy-MM-dd'}}</b></p>
	<div class="mt-2">
		<span class="mr-3 d-block">Valda betalmetoder: </span>
		<span class="mr-3 d-inline-block font-bold" *ngFor="let type of dashboardWidgetOrderRestService.selectedPaymentTypes?.selected">
			<span *ngIf="type !== 999">{{type | paymentTypeName | async}}</span>
			<span *ngIf="type === 999">Saknar betalning</span>
		</span>
	</div>
	<mat-progress-bar class="my-4" *ngIf="source.isLoading" mode="indeterminate"></mat-progress-bar>
	<p class="bg-info mt-3 ng-star-inserted p-2 rounded text-center text-white" *ngIf="!source.isLoading && !source.orders?.length">Finns inga ordrar!</p>
	<p class="mt-2" *ngIf="source.orders?.length">Antal ordrar: <b>{{source.orders?.length}}</b></p>
	<table *ngIf="source.orders?.length" mat-table [dataSource]="source.orders" class="w-100">
		<ng-container matColumnDef="customerId">
			<th mat-header-cell *matHeaderCellDef>{{source.adminText?.customer}}</th>
			<td mat-cell *matCellDef="let item">{{item.customerId | customerName | async}}</td>
		</ng-container>
		<ng-container matColumnDef="travelAgencyId">
			<th mat-header-cell *matHeaderCellDef>{{source.adminText?.travelAgency}}</th>
			<td mat-cell *matCellDef="let item">{{item.travelAgencyId | travelAgencyName | async}}</td>
		</ng-container>
		<ng-container matColumnDef="date">
			<th mat-header-cell *matHeaderCellDef>{{source.adminText?.date}}</th>
			<td mat-cell *matCellDef="let item">{{item.tourDate | date: 'yyyy-MM-dd'}} {{item.tourTime}}</td>
		</ng-container>
		<ng-container matColumnDef="payment">
			<th mat-header-cell *matHeaderCellDef>{{source.adminText?.restAmount}}</th>
			<td mat-cell *matCellDef="let item">
				<span *ngIf="{amount: item.paymentBalance?.totalOrder - item.paymentBalance?.totalPayment} as total"
					[class.text-success]="!total.amount"
					[class.text-danger]="total.amount > 0"
					[class.text-warning]="total.amount < 0">
					{{total.amount | currency:'SEK':'code'}}
				</span>
			</td>
		</ng-container>
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let item">
				<button mat-icon-button [title]="source.adminText?.goToTour" (click)="goToTour(item)"><mat-icon>flight</mat-icon></button>
				<button mat-icon-button [title]="source.adminText?.goToOrder" (click)="goToOrder(item)"><mat-icon>credit_card</mat-icon></button>
				<button mat-icon-button [title]="source.adminText?.goToCustomer" (click)="goToCustomer(item)">
					<mat-icon
						fontSet="fas"
						fontIcon="fa-users">
					</mat-icon>
				</button>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
	</table>
</ng-container>
