<app-blade-item [canIClose]="true" [loading]="source.isLoading" *ngIf="{
	isLoading: termTypeService.isLoadingReplay$ | async,
	item: termTypeService.itemReplay$ | async
} as source">
	<app-blade-header [title]="source.item?.name||'Ny'" [subTitle]="'Villkorstyp'"></app-blade-header>
    <app-blade-content (indexChange)="termTypeService.selectedIndex = $event">
		<app-blade-content-tabs>
			<app-blade-content-tab [title]="'Generellt'" [template]="generally">
				<ng-template #generally>
					<app-term-type-blade-generally></app-term-type-blade-generally>
				</ng-template>
			</app-blade-content-tab>
		</app-blade-content-tabs>
	</app-blade-content>
</app-blade-item>
