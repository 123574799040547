import { Component, OnDestroy, Inject, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import { combineLatest, ReplaySubject } from 'rxjs';
import { Dictionary } from 'lodash';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBooking } from 'app/models/tour-booking.model';
import { TourBookingCustomerTypeStore } from 'app/admin/stores/tour-booking-customer-type.store.service';
import { TourBookingCustomerTypeSummary } from 'app/models/tour-booking-customer-type-summary.model';
import { TourBookingBladeComponent } from 'app/admin/blades/tour-booking/tour-booking-blade/tour-booking-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourSummaryService } from '../../services/tour-summary.service';
import * as moment from 'moment';
import { TourBookingAddonStore } from 'app/admin/stores/tour-booking-addon.store.service';
import { DialogEditService } from 'app/modules/dialog-edit/services/dialog-edit.service';

@UntilDestroy()
@Component({
	selector: 'app-tour-summary-customer-type',
	templateUrl: './tour-summary-customer-type.component.html',
	styleUrls: ['./tour-summary-customer-type.component.scss'],
})
export class TourSummaryCustomerTypeComponent implements OnDestroy {
	public moment = moment;
	@ViewChild(MatAccordion) accordion: MatAccordion;
	private tourBookings: TourBooking[];
	public displayedColumns: string[] = ['customerType', 'quantity', 'birthDate'];
	public displayedAddonColumns: string[] = ['name', 'quantity'];
	public displayedPickupLocationColumns: string[] = ['customerType', 'name', 'quantity'];
	public customerTypeWrappersReplay = new ReplaySubject<Dictionary<TourBookingCustomerTypeSummary[]>>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private tourBookingCustomerTypeStore: TourBookingCustomerTypeStore,
		private tourSummaryService: TourSummaryService,
		private tourBookingAddonStore: TourBookingAddonStore,
		public dialogEditService: DialogEditService,
	) {
		this.tourSummaryService.tourBookingsBehavior$.pipe(untilDestroyed(this), switchMap(tourBookings => {
			this.tourBookings = tourBookings;
			const observables = [];
			_.each(tourBookings, tb => {
				observables.push(this.tourBookingCustomerTypeStore.getAllOnTourBooking(tb.id));
			});

			return combineLatest(observables);
		})).subscribe((tourBookingCustomerTypeList) => {
			const tourBookingCustomerTypes = _.flatten(tourBookingCustomerTypeList) as TourBookingCustomerTypeSummary[];
			_.each(tourBookingCustomerTypes, tourBookingCustomerType => {
				tourBookingCustomerType.tourBooking = _.find(this.tourBookings, tBooking => tBooking.id === tourBookingCustomerType.tourBookingId);
			});
			const tourBookingCustomerTypeGroups = _.groupBy(tourBookingCustomerTypes, customerType => customerType.tourBooking.customerId);
			this.customerTypeWrappersReplay.next(tourBookingCustomerTypeGroups);
		});
	}

	ngOnDestroy() { }

	public selectItem(id: number) {
		this.bladeService.addBladeItem({
			id,
			component: TourBookingBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public openEditCustomerType(tourBookingId: number): void {
		this.dialogEditService.editTourBookingCustomerType(tourBookingId).subscribe(done => {
			this.tourSummaryService.getAllOnTour();
		});
	}

	public openEditAddon(tourBookingId: number): void {
		this.dialogEditService.editTourBookingAddon(tourBookingId).subscribe(done => {
			this.tourSummaryService.getAllOnTour();
		});
	}

	public openEditPickup(tourBookingId: number): void {
		this.dialogEditService.editTourBookingPickupLocation(tourBookingId).subscribe(done => {
			this.tourSummaryService.getAllOnTour();
		});
	}

	public openEditMessage(tourBookingId: number): void {
		this.dialogEditService.editTourBookingMessage(tourBookingId).subscribe(done => {
			this.tourSummaryService.getAllOnTour();
		});
	}
}
