<app-blade-item [canIClose]="!itemForm.dirty" [loading]="loading">
    <app-blade-header [title]="productDateTime ? 'Tid' : 'Ny tid'" [subTitle]="'Produkt datum tid'"></app-blade-header>
    <app-blade-content *ngIf="productDateTime">
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput type="time" formControlName="time" placeholder="Tid">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput type="number" formControlName="quantity" placeholder="Antal">
			</mat-form-field>
		</form>

		<app-product-date-time-blade-customer-type></app-product-date-time-blade-customer-type>

	</app-blade-content>
    <app-blade-footer>
        <span fxFlex></span>
        <button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="productDateTime?.id">Spara</span>
			<span *ngIf="!productDateTime?.id">Skapa</span>
		</button>
    </app-blade-footer>
 </app-blade-item>
