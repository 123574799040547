import { BaseModel } from './base.model';
import { SupplierResourceProductType } from './supplier-resource-product-type.model';
import { SupplierResource } from './supplier-resource.model';

export class SupplierResourceProduct extends BaseModel {
	public supplierResourceId: number;
	public supplierResourceProductTypeId: number;
	public productId: number;
	public course: number;
	public supplierResource: SupplierResource;
	public supplierResourceProductType: SupplierResourceProductType;
}
