<mat-card>
	<mat-card-title>Kategorier</mat-card-title>
	<mat-card-subtitle>Välj de kategorier där denna produkt tillhör.</mat-card-subtitle>

	<div class="mat-elevation-z1">
		<table mat-table [dataSource]="dataSource" matSort class="w-100">
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					Välj
				</th>
				<td mat-cell *matCellDef="let item">
					<mat-checkbox (click)="$event.stopPropagation()"
									(change)="$event ? selectCategory(item) : null"
									[checked]="selection?.isSelected(item)">
					</mat-checkbox>
				</td>
			</ng-container>
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
				<td mat-cell *matCellDef="let item">{{item.id}} </td>
			</ng-container>
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
				<td mat-cell *matCellDef="let item"><strong>{{item.name}}</strong><br /><em>{{item.identifier}}</em></td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectCategory(item)" [class.selected]="bladeItem?.child?.id === item?.id"></tr>
		</table>

		<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	</div>
</mat-card>
