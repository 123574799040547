<mat-card>
	<mat-card-title>Turer program</mat-card-title>
	<mat-card-content>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<mat-label>Välj mellan vilka datum du vill hämta rapporten</mat-label>
				<mat-date-range-input
					[rangePicker]="campaignOnePicker"
					[comparisonStart]="itemForm.value.fromDate"
					[comparisonEnd]="itemForm.value.toDate">
					<input readonly matStartDate placeholder="Från datum" formControlName="fromDate">
					<input readonly matEndDate placeholder="Till datum" formControlName="toDate">
				</mat-date-range-input>
				<mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
				<mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
			</mat-form-field>
			<mat-checkbox formControlName="showCustomerInfo" class="mr-3">Visa kunder</mat-checkbox>
			<mat-checkbox formControlName="showCanceledTours">Visa avbokade turer</mat-checkbox>
			<div class="text-right">
				<button mat-raised-button color="accent" [disabled]="itemForm.invalid" (click)="showProgram()">Visa rapport <mat-icon class="ml-2">open_in_new</mat-icon></button>
			</div>
		</form>
	</mat-card-content>
</mat-card>
