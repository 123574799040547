import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { LoadingComponent } from './loading.component';
import { LoadingService } from './loading.service';

@NgModule({
	imports: [
		CommonModule,
		MatProgressBarModule,
	],
	declarations: [LoadingComponent],
	exports: [LoadingComponent],
	providers: [LoadingService],
})
export class LoadingModule { }
