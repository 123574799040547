import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';

import { AuthService } from 'app/admin/services/auth.service';
import { UserService } from 'app/admin/services/user.service';
import { LoginModalService } from 'app/admin/services/login-modal.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login-module',
  templateUrl: './login-module.component.html',
  styleUrls: ['./login-module.component.scss'],
})
export class LoginModuleComponent {
	public newPasswordSent$ = new Subject<boolean>();
	public newPasswordSending$ = new Subject<boolean>();
	public requireChangePasswordSubject$ = new Subject<boolean>();
	public errorMessage: string;
	public formGroup = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
		password: new FormControl('', [Validators.required]),
		newPassword: new FormControl(''),
	});

	constructor(
		private authService: AuthService,
		public userService: UserService,
		public loginModalService: LoginModalService,
		private spinner: NgxSpinnerService,
	) { }

	public login() {
		this.spinner.show();
		this.formGroup.disable();
		this.authService.login(this.formGroup.value.email, this.formGroup.value.password, this.formGroup.value.newPassword).subscribe(success => {
			this.spinner.hide();
			this.formGroup.enable();
			this.loginModalService.loginSuccess();
		}, error => {
			if (error.status === 418) {
				this.requireChangePasswordSubject$.next(true);
			} else {
				this.errorMessage = 'Fel användarnamn eller lösenord';
			}
			this.spinner.hide();
			this.formGroup.enable();
		});
	}

	public logut() {
		this.authService.logout();
	}

	public sendNewPassword(newPasswordSending: boolean) {
		if (newPasswordSending) {
			return;
		}

		this.newPasswordSending$.next(true);
		this.authService.sendNewPassword(this.formGroup.value.email).subscribe(sent => {
			this.newPasswordSent$.next(sent);
		});
	}
}
