import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NewOrderTemplateStore } from 'app/admin/stores/new-order-template.store.service';
import { NewOrderTemplate } from 'app/models/email/new-order-template.model';
import { NewOrderTemplateBladeComponent } from 'app/admin/blades/new-order-template/new-order-template-blade/new-order-template-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-settings-email-template-new-order',
	templateUrl: './settings-email-template-new-order.component.html',
	styleUrls: ['./settings-email-template-new-order.component.scss'],
})
export class SettingsEmailTemplateNewOrderComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['language', 'subject', 'modified'];
	public dataSource: MatTableDataSource<NewOrderTemplate>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: NewOrderTemplateStore,
	) {
		this.itemStore.getAll();
		this.itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<NewOrderTemplate>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: NewOrderTemplateBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: NewOrderTemplateBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}
}
