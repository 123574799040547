<app-blade-item [canIClose]="true">
	<app-blade-header [title]="'Användare'"></app-blade-header>
	<app-blade-content>
		<mat-card>
			<mat-card-content>
				<mat-card-subtitle>
					<p class="mb-1">Användare som är koplade till systemet.</p>
				</mat-card-subtitle>
				<form [formGroup]="itemForm">
					<mat-form-field class="w-100" appearance="outline">
						<input matInput formControlName="searchWord" placeholder="Sök på namn, epost och telefon">
					</mat-form-field>
				</form>
				<mat-card-content>
					<table mat-table [dataSource]="dataSource" matSort class="w-100">
						<ng-container matColumnDef="id">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
							<td mat-cell *matCellDef="let item">{{item.id}} </td>
						</ng-container>
						<ng-container matColumnDef="name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
							<td mat-cell *matCellDef="let item">{{item.firstName}} {{item.lastName}}</td>
						</ng-container>
						<ng-container matColumnDef="email">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Epost</th>
							<td mat-cell *matCellDef="let item">{{item.email}}</td>
						</ng-container>
						<ng-container matColumnDef="phone">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
							<td mat-cell *matCellDef="let item">{{item.phone}}</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.id === item?.id"></tr>
					</table>

					<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
				</mat-card-content>
			</mat-card-content>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="createNew()">Skapa ny</button>
	</app-blade-footer>
</app-blade-item>
