import { BaseModel } from './base.model';
import { OrderItem } from './order-item.model';

export class TourBookingPickupLocation extends BaseModel {
	public tourBookingId: number;
	public customerTypeId: number;
	public pickupLocationId: number;
	public relativeTime: number;
	public quantity: number;
	public orderItemId?: number;
	public orderItem: OrderItem;
}
