import { OddFunctionAddon } from './odd-function-addon.model';
import { OddFunctionCustomerType } from './odd-function-customer-type.model';
import { OddFunctionErrorMessage } from './odd-function-error-message.model';
import { ProductFunctionBase } from './product-function-base.model';
import { ProductFunction } from './product-function.model';

export class OddFunction extends ProductFunctionBase {
	public name: string;
	public functionTypeId: number;
	public requireAddon: boolean;
	public addons: OddFunctionAddon[];
	public errorMessages: OddFunctionErrorMessage[];
	public customerTypes: OddFunctionCustomerType[];
	public products: ProductFunction[];
}
