import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormBuilderService } from '../../services/form-builder.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-form-builder-element',
	templateUrl: './form-builder-element.component.html',
	styleUrls: ['./form-builder-element.component.scss'],
})
export class FormBuilderElementComponent implements OnInit, AfterContentInit {

	@Input() formElement: FormBuilderElement | any;

	public formBuilderElementTypes = [{
		name: 'Text',
		identifier: 'text',
	}, {
		name: 'Textarea',
		identifier: 'textarea',
	}];

	public itemForm: FormGroup = new FormGroup({
		label: new FormControl('', [Validators.required]),
		description: new FormControl(''),
		formBuilderElementType: new FormControl(null),
	});

	constructor(public formBuilderService: FormBuilderService) { }

	ngOnInit() {
	}

	ngAfterContentInit(): void {
		this.itemForm.get('label').setValue(this.formElement.label);
		this.itemForm.get('description').setValue(this.formElement.description);
		this.itemForm.get('formBuilderElementType').setValue(this.formElement.formBuilderElementType);

		this.itemForm.get('label').valueChanges.pipe(untilDestroyed(this))
			.subscribe(label => this.formElement.label = label);
		this.itemForm.get('description').valueChanges.pipe(untilDestroyed(this))
			.subscribe(description => this.formElement.description = description);
		this.itemForm.get('formBuilderElementType').valueChanges.pipe(untilDestroyed(this))
			.subscribe(formBuilderElementType => this.formElement.formBuilderElementType = formBuilderElementType);
	}

}

interface FormBuilderElement {
	formBuilderElementType: string;
	label: string;
	description: string;
}
