<mat-card>
	<mat-card-title>Program</mat-card-title>
	<mat-card-content>
		<form [formGroup]="itemForm">
			<div class="row">
				<div class="col-6">
					<mat-form-field class="w-100">
						<mat-label>Språk</mat-label>
						<mat-select formControlName="languageId">
							<mat-option *ngFor="let type of (languageStore.items$|async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-6">
					<mat-form-field class="w-100">
						<mat-label>Välj mellan vilka datum du vill hämta rapporten</mat-label>
						<mat-date-range-input
							[rangePicker]="campaignOnePicker"
							[comparisonStart]="itemForm.value.fromDate"
							[comparisonEnd]="itemForm.value.toDate"
							(click)="campaignOnePicker.open()">
							<input readonly matStartDate placeholder="Från datum" formControlName="fromDate">
							<input readonly matEndDate placeholder="Till datum" formControlName="toDate">
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
						<mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
						<button *ngIf="itemForm.value.fromDate || itemForm.value.toDate" matSuffix mat-icon-button (click)="clearDate()">
							<mat-icon>close</mat-icon>
						</button>
					</mat-form-field>
				</div>
			</div>
			<div class="text-right">
				<button mat-raised-button color="accent" [disabled]="itemForm.invalid" (click)="showProgram()">Visa rapport <mat-icon class="ml-2">open_in_new</mat-icon></button>
			</div>
		</form>
	</mat-card-content>
</mat-card>
