import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import { TourBookingCustomerTypeItemService } from './services/tour-booking-customer-type-item.service';

@UntilDestroy()
@Component({
  	selector: 'app-tour-booking-customer-type-item',
  	templateUrl: './tour-booking-customer-type-item.component.html',
  	styleUrls: ['./tour-booking-customer-type-item.component.scss'],
	providers: [TourBookingCustomerTypeItemService],
  	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourBookingCustomerTypeItemComponent implements OnInit {
	@Input() set tourBookingCustomerType(tourBookingCustomerType: TourBookingCustomerType) {
		this.tourBookingCustomerTypeItemService.setTourBookingCustomerTypeItem(tourBookingCustomerType);
	}

  	constructor(
		public customerTypeStore: CustomerTypeStore,
		public tourBookingCustomerTypeItemService: TourBookingCustomerTypeItemService,
	) { }

  	ngOnInit() {
  	}

}
