<mat-card>
	<mat-card-title>Länkning</mat-card-title>
	<form [formGroup]="itemForm">
		<mat-form-field class="w-100" appearance="outline">
			<mat-label>Länk type</mat-label>
			<mat-select formControlName="columnLinkTypeId">
				<mat-option *ngFor="let type of (columnLinkTypeStore.items$ | async)" [value]="type.id">
					{{type.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="w-100" appearance="outline" *ngIf="itemForm.value?.columnLinkTypeId === 1">
			<mat-label>Ange externlänk som börjar med https://</mat-label>
			<input matInput formControlName="value" placeholder="Länk">
		</mat-form-field>

		<mat-form-field class="w-100" appearance="outline" *ngIf="itemForm.value?.columnLinkTypeId === 2">
			<mat-label>Välj en sida som ska länkas</mat-label>
			<mat-select formControlName="value">
				<mat-option *ngFor="let type of (pageStore.items$ | async)" [value]="type.id.toString()">
					{{type.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="w-100" appearance="outline" *ngIf="itemForm.value?.columnLinkTypeId === 3">
			<mat-label>Välj en product som ska länkas</mat-label>
			<mat-select formControlName="value">
				<mat-option *ngFor="let type of (productStore.items$ | async)" [value]="type.id.toString()">
					{{type.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="w-100" appearance="outline" *ngIf="itemForm.value?.columnLinkTypeId === 4">
			<mat-label>Välj en kategory som ska länkas</mat-label>
			<mat-select formControlName="value">
				<mat-option *ngFor="let type of (categoryStore.items$ | async)" [value]="type.id.toString()">
					{{type.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</form>
	<div class="text-right">
		<button mat-raised-button color="primary" [disabled]="isSaving$ | async" (click)="save()">
			<span *ngIf="columnLink?.id">Spara</span>
			<span *ngIf="!columnLink?.id">Skapa</span>
		</button>
	</div>
</mat-card>
