import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormControl, FormBuilder } from '@angular/forms';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { System } from 'app/models/system.model';
import { SystemStore } from 'app/admin/stores/system.store.service';

@Component({
	selector: 'app-system-blade',
	templateUrl: './system-blade.component.html',
	styleUrls: ['./system-blade.component.scss'],
})
@IBladeComponent.register
export class SystemBladeComponent implements OnInit {
	public selectedIndex = 1;
	public item: System;
	public loading = true;
	public canIClose: () => {} | boolean;

	public formGroup = this.fb.group({
		name: new FormControl('', Validators.required),
		host: new FormControl('', Validators.required),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: SystemStore,
		private bladePositionService: BladePositionService,
		private fb: FormBuilder,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.formGroup.get('name').setValue(this.item.name);
				this.formGroup.get('host').setValue(this.item.host);
				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new System();
		}
	}

	ngOnInit() {}

	public save() {
		if (!this.formGroup.value.name || !this.formGroup.value.host) {
			return;
		}

		this.loading = true;
		this.item.name = this.formGroup.value.name;
		this.item.host = this.formGroup.value.host;

		if (this.item.id) {
			this.itemStore.update(this.item).subscribe(product => {
				this.loading = false;
				this.item = _.assign(this.item, product);
			});
		} else {
			this.itemStore.create(this.item).subscribe(product => {
				this.loading = false;
				this.item = _.assign(this.item, product);
			});
		}
	}
}
