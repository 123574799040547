import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CustomerType } from 'app/models/customer-type.model';
import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class CustomerTypeStore extends BaseStore<CustomerType> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'CustomerTypes', baseUrl, snackBarService);
	}
}
