import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { OrderPaymentStore } from 'app/admin/stores/order-payment.store.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrderPayment } from 'app/models/order-payment.model';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { PaymentBladeComponent } from 'app/admin/blades/payment/payment-blade/payment-blade.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-order-payments',
	templateUrl: './order-payments.component.html',
	styleUrls: ['./order-payments.component.scss'],
})
export class OrderPaymentsComponent implements OnInit {
	public totalAmountReplay$ = new ReplaySubject<number>();

	public displayedColumns: string[] = ['id', 'payment', 'amount', 'source'];
	public dataSource: MatTableDataSource<OrderPayment>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public orderPaymentStore: OrderPaymentStore,
		private bladeService: BladeService,
	) {
		orderPaymentStore.getAllOnOrder(bladeItem.id).subscribe();
		orderPaymentStore.items$.pipe(untilDestroyed(this)).subscribe(orderPayments => {
			this.totalAmountReplay$.next(_.sumBy(orderPayments, 'amount'));
			this.dataSource = new MatTableDataSource<OrderPayment>(orderPayments);
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit() {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: PaymentBladeComponent,
			minClass: BladeSize.s,
		}, this.bladeItem);
	}

	public newPayment() {
		this.bladeService.addBladeItem({
			id: 0,
			component: PaymentBladeComponent,
			minClass: BladeSize.s,
			payload: { orderId: this.bladeItem.id },
		}, this.bladeItem);
	}
}
