import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingCustomerTypeStore } from 'app/admin/stores/tour-booking-customer-type.store.service';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrderItemBladeComponent } from 'app/admin/blades/order/order-item-blade/order-item-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { OrderItemStore } from 'app/admin/stores/order-item.store.service';
import { OrderItem } from 'app/models/order-item.model';

@Component({
  selector: 'app-tour-booking-customer-type-blade-order-items',
  templateUrl: './tour-booking-customer-type-blade-order-items.component.html',
  styleUrls: ['./tour-booking-customer-type-blade-order-items.component.scss'],
})
export class TourBookingCustomerTypeBladeOrderItemsComponent implements OnInit {
	@Input() item: TourBookingCustomerType;

	public displayedColumns: string[] = ['id', 'customerType', 'quantity', 'created'];
	public dataSource: MatTableDataSource<OrderItem>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private orderItemStore: OrderItemStore,
	) { }

  	ngOnInit() {
		this.orderItemStore.get(this.item.orderItemId).subscribe(tourBookingCustomerTypes => {
			this.dataSource = new MatTableDataSource<OrderItem>([tourBookingCustomerTypes]);
			this.dataSource.sort = this.sort;
			this.dataSource.paginator = this.paginator;
		});
  	}

	  public selectItem(row: TourBookingCustomerType) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: OrderItemBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}
}
