import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class GeoLocationStrore {
	constructor(protected http: HttpClient) { }

	public getAddress(long: number, lat: number): Observable<string> {
		return this.http.get<any>(`
			https://nominatim.openstreetmap.org/reverse?key=iTzWSiYpGxDvhATNtSrqx5gDcnMOkntL&format=jsonv2&zoom=16&lat=${lat}&lon=${long}`)
			.pipe(map(address => address.display_name));
	}
}
