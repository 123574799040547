
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SpecialDiet } from 'app/models/special-diet.model';
import { SnackBarService } from '../services/snack-bar.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SpecialDietStore extends BaseStore<SpecialDiet> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SpecialDiet', baseUrl, snackBarService);
		this.getAll();
	}

	public getAllSpecialDietIconOnTour$(tourId: number): Observable<string[]> {
		return this.http.get<string[]>(`${this.host}/tour/${tourId}/icons`);
	}
}
