<ng-container *ngIf="{
	isLoading: loading$ | async
} as source">
	<app-blade-item [canIClose]="true" [loading]="source.isLoading" [id]="item?.id">
		<app-blade-header [title]="item?.id ? item?.firstName + ' ' + item?.lastName : 'Ny'" [subTitle]="'Systemanvändare'"></app-blade-header>
		<app-blade-content *ngIf="item">
			<mat-card>
				<mat-card-title *ngIf="item?.id">Systemanvändare</mat-card-title>
				<mat-card-title *ngIf="!item?.id">Ny systemanvändare</mat-card-title>
				<form [formGroup]="itemForm">
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Förnamn</mat-label>
						<input matInput formControlName="firstName" placeholder="Förnamn">
					</mat-form-field>
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Efternamn</mat-label>
						<input matInput formControlName="lastName" placeholder="Efternamn">
					</mat-form-field>
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Epost</mat-label>
						<input matInput formControlName="email" placeholder="Epost">
					</mat-form-field>
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Telefon</mat-label>
						<input matInput formControlName="phone" placeholder="Telefon">
					</mat-form-field>
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Admin användartyp</mat-label>
						<mat-select formControlName="userType">
							<mat-option *ngFor="let type of (userTypeStore.items$|async)" [value]="type.id">
								{{type.name}} ({{type.id}})
							</mat-option>
						</mat-select>
					</mat-form-field>
					<app-user-blade-service class="mb-3 d-block" *ngIf="item?.id"></app-user-blade-service>
					<mat-card *ngIf="!item?.id">
						<p>Ange ett tillfälligt lösenord till användaren.</p>
						<p>Användaren kommer att bli tvungen att ange ett nytt lösenord så fort denna loggar in i systemet.</p>
						<mat-form-field class="w-100" appearance="outline">
							<mat-label>Lösenord</mat-label>
							<input matInput formControlName="newPassword" placeholder="Lösenord">
						</mat-form-field>
					</mat-card>
					<mat-card *ngIf="item?.id">
						<p>Tvinga användaren att byta lösenord.</p>
						<p>Så fort användaren loggar in så kommer denna bli tvingad att byta lösenord så länge denna flagga är aktiv. Så fort användaren bytt lösenord, antingen logga i eller profil sidan, så kommer flaggan att inaktiveras igen.</p>
						<section>
							<mat-slide-toggle
								color="accent"
								formControlName="requireChangePassword">
								Aktiv
							</mat-slide-toggle>
						</section>
					</mat-card>
				</form>
			</mat-card>
		</app-blade-content>
		<app-blade-footer>
			<button mat-raised-button>Avbryt</button>
			<span fxFlex></span>
			<button mat-raised-button color="primary" [disabled]="!userService.checkPermission(['admin'], 0) || !itemForm.valid || source.isLoading" (click)="save()">
				<span *ngIf="item?.id">Spara</span>
				<span *ngIf="!item?.id">Skapa</span>
			</button>
		</app-blade-footer>
	</app-blade-item>
</ng-container>