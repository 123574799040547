import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { SupplierBladeComponent } from '../supplier-blade/supplier-blade.component';
import { Supplier } from 'app/models/supplier.model';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@UntilDestroy()
@Component({
	selector: 'app-suppliers-blade',
	templateUrl: './suppliers-blade.component.html',
	styleUrls: ['./suppliers-blade.component.scss'],
})
@IBladeComponent.register
export class SuppliersBladeComponent implements OnInit, OnDestroy {
	private allSuppliers: Supplier[] = [];
	public searchForm = new FormGroup({
		name: new FormControl(null),
	});
	public displayedColumns: string[] = ['name'];
	public dataSource: MatTableDataSource<Supplier>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: SupplierStore,
	) {
		this.itemStore.getAll();
		this.itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.allSuppliers = items;
			this.setValue(this.allSuppliers);
		});

		this.searchForm.get('name').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.setValue(this.allSuppliers.filter(x => x.name.toLowerCase().includes((value as string).toLowerCase())));
		});
	}

	ngOnInit(): void { }

	ngOnDestroy(): void { }

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: SupplierBladeComponent,
			minClass: BladeSize.l,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: SupplierBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	private setValue(items: Supplier[]) {
		this.dataSource = new MatTableDataSource<Supplier>(items);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
}
