import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EventManager } from '@angular/platform-browser';

import { ReplaySubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import scrollIntoView from 'scroll-into-view-if-needed'

import { BladeItem } from './models/bladeItem.model';
import { BladeService } from './blade.service';
import { UtilsService } from 'app/services/utils.service';
import { BladeSize } from './models/bladeSize.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class BladePositionService implements OnDestroy {
	private bladeItems: BladeItem[];
	private isHandset = false;
	public selectedIndex = 0;
	public previusSelectedIndex = 0;

	public isHandset$: Observable<boolean> = this.breakpointObserver
	.observe([Breakpoints.XSmall])
	.pipe(map(result => {
		return result.matches;
	}));

	public translateX = 0;
	private translateXReplaySubject = new ReplaySubject<number>(1);
	public translateX$ = this.translateXReplaySubject.asObservable();

	constructor(
		private bladeService: BladeService,
		private utilsService: UtilsService,
		private breakpointObserver: BreakpointObserver,
		private eventManager: EventManager,
	) {
		this.bladeService.bladeItems$.pipe(untilDestroyed(this)).subscribe(bladeItems => {
			this.bladeItems = bladeItems;
			utilsService.debounceThrottle('focusActiveIndexWithDebounce', () => { this.focustLastItem(); }, 500);
		});
		this.eventManager.addGlobalEventListener('window', 'resize', () => this.focusActiveIndexWithDebounce());
		this.isHandset$.pipe(untilDestroyed(this)).subscribe((isHandset) => { this.isHandset = isHandset; });
		this.bladeService.isInitDone$.pipe(untilDestroyed(this)).subscribe(isDone => {
			// When isDone === false the init is starting
			if (!isDone) {
				this.selectedIndex = 0;
			}
		});
	}

	ngOnDestroy(): void { }

	public selectBladeItem(bladeItem: BladeItem, force: boolean = false) {
		if (bladeItem && (this.selectedIndex !== bladeItem.itemIndex || force)) {
			this.previusSelectedIndex = this.selectedIndex;
			this.selectedIndex = bladeItem.itemIndex;
			this.focusActiveIndexWithDebounce();
		}
	}

	public focustLastItem() {
		this.selectBladeItem(this.bladeItems[this.bladeItems.length - 1], this.bladeItems.length === 1);
	}

	public focusActiveIndexWithDebounce() {
		if (this.bladeItems && this.bladeItems.length) {
			this.utilsService.debounceThrottle('focusActiveIndexWithDebounce', () => { this.focusActiveIndex(); }, 500);
		}
	}

	private focusActiveIndex() {
		if (this.isHandset) {
			this.scrollIntoVew(true);
			// this.focusActiveIndexOnHandset();
		} else {
			this.scrollIntoVew();
		}
		// else if (!this.isInViewPort(this.selectedIndex)) {
		// 	if (this.selectedIndex === 0) {
		// 		this.setTranslateValue(this.getLeftTranslate());
		// 	} else if (this.previusSelectedIndex > this.selectedIndex) {
		// 		this.setTranslateValue(this.getLeftTranslate());
		// 	} else {
		// 		this.setTranslateValue(this.getRightTranslate());
		// 	}
		// }
		_.each(this.bladeItems, item => {
			item.isSelected = item.itemIndex === this.selectedIndex;
		});
	}

	// private isInViewPort(index: number): boolean {
	// 	let isInViewPort = false;
	// 	const itemPosition = this.bladeItems[this.selectedIndex].elementRef.nativeElement.getBoundingClientRect();
	// 	const mainContainer = this.getBladeMainContainerClientRect();
	// 	isInViewPort = itemPosition.left > mainContainer.left && itemPosition.right < mainContainer.right;
	// 	return isInViewPort;
	// }

	// private focusActiveIndexOnHandset() {
	// 	// if (this.selectedIndex !== 0 && this.selectedIndex === _.last(this.bladeItems).itemIndex) {
	// 	// 	this.setTranslateValue(this.getRightTranslate());
	// 	// } else {
	// 	// 	this.setTranslateValue(this.getCenterTranslate());
	// 	// }
	// 	this.scrollIntoVew();
	// }

	// private setTranslateValue(value: number) {
	// 	// this.translateX = value;
	// 	// this.translateXReplaySubject.next(this.translateX);
	// }

	private scrollIntoVew(center?: boolean) {
		if (this.bladeItems[this.selectedIndex]?.elementRef?.nativeElement) {
			scrollIntoView(this.bladeItems[this.selectedIndex]?.elementRef?.nativeElement, {
				scrollMode: 'if-needed',
				behavior: 'smooth',
				block: center ? 'center' : 'nearest',
				inline: center ? 'center' : 'nearest',
			});
		}
	}

	// private getLeftTranslate(): number {
	// 	let value = 0;
	// 	_.each(this.bladeItems, bladeItem => {
	// 		if (bladeItem.itemIndex < this.selectedIndex) {
	// 			value += this.getBladeItemClassWidth(bladeItem.activeClass);
	// 		}
	// 	});
	// 	return -value;
	// }

	// private getCenterTranslate(): number {
	// 	let value = -this.getLeftTranslate();

	// 	if (this.selectedIndex > 0 && this.selectedIndex < this.bladeItems.length - 1) {
	// 		const bladeItem = _.find(this.bladeItems, item => item.itemIndex === this.selectedIndex);
	// 		const mainContainerWidth = this.getBladeMainContainerClientRect().width;
	// 		const bladeWidth = this.getBladeItemClassWidth(bladeItem.activeClass);
	// 		value -= (mainContainerWidth - bladeWidth) / 2;
	// 	}

	// 	return -value;
	// }

	// private getRightTranslate(): number {
	// 	const itemPosition = this.getBladeItemPosition();
	// 	const mainContainer = this.getBladeMainContainerClientRect();
	// 	return -(itemPosition.right - mainContainer.width);
	// }


	// private getBladeItemPosition(): BladeItemPosition {
	// 	const bladeItemPosition = {
	// 		left: 0,
	// 		right: 0,
	// 	};
	// 	_.each(this.bladeItems, bladeItem => {
	// 		if (bladeItem.itemIndex < this.selectedIndex) {
	// 			bladeItemPosition.left += this.getBladeItemClassWidth(bladeItem.activeClass);
	// 		}
	// 		if (bladeItem.itemIndex <= this.selectedIndex) {
	// 			bladeItemPosition.right += this.getBladeItemClassWidth(bladeItem.activeClass);
	// 		}
	// 	});
	// 	return bladeItemPosition;
	// }

	private getBladeMainContainerClientRect(): ClientRect {
		const bladeMainContainer = document.getElementsByClassName('blade-main-container');
		return bladeMainContainer[0].getBoundingClientRect();
	}

	public getBladeItemClassWidth(bladeSize: BladeSize): number {
		const mainContainerWidth = this.getBladeMainContainerClientRect().width;
		let bladeClassWidth = 0;

		switch (bladeSize) {
			case BladeSize.xs:
				bladeClassWidth = 380;
				break;
			case BladeSize.s:
				bladeClassWidth = 540;
				break;
			case BladeSize.m:
				bladeClassWidth = 720;
				break;
			case BladeSize.l:
				bladeClassWidth = 960;
				break;
			case BladeSize.xl:
				bladeClassWidth = 1140;
				break;
			case BladeSize.xxl:
				bladeClassWidth = 1500;
				break;
			default:
				bladeClassWidth = 720;
				break;
		}

		if (mainContainerWidth < bladeClassWidth || this.isHandset) {
			bladeClassWidth = mainContainerWidth * this.getWidthDiff();
		}
		return bladeClassWidth;
	}

	private getWidthDiff(): number {
		return this.bladeItems.length === 1  ? 1 : .85;
	}
}
