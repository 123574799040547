import { BaseModel } from './base.model';
import { Media } from './media.model';

export class VideoElement extends BaseModel {
	public elementId: number;
	public languageId: number;
	public title: string;
	public mediaId: number;
	public media: Media;
}
