<app-blade-item [canIClose]="true">
	<app-blade-header [title]="item?.name || 'Ny'" [subTitle]="'Resebyrå'"></app-blade-header>
	<app-blade-content [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
		<ng-container [ngSwitch]="selectedIndex">
			<ng-container *ngSwitchCase="1">
				<app-travel-agency-generally *ngIf="item" [travelAgency]="item"></app-travel-agency-generally>
			</ng-container>
			<ng-container *ngSwitchCase="2">
				<app-travel-agency-bookings></app-travel-agency-bookings>
			</ng-container>
			<ng-container *ngSwitchCase="3">
				<app-travel-agency-orders></app-travel-agency-orders>
			</ng-container>
			<ng-container *ngSwitchCase="4">
				<app-travel-agency-customers></app-travel-agency-customers>
			</ng-container>
			<ng-container *ngSwitchCase="5">
				<app-travel-agency-users></app-travel-agency-users>
			</ng-container>
			<ng-container *ngSwitchCase="6">
				<app-travel-agency-discounts></app-travel-agency-discounts>
			</ng-container>

		</ng-container>
	</app-blade-content>
	<app-blade-footer *ngIf="selectedIndex === 1">
		<button mat-raised-button>Avbryt</button>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
