import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { BladeService } from 'app/admin/components/blade/blade.service';
import { ElementCardBlade } from '../blades/element/element-card-blade/element-blade-card.blade';
import { ElementCartBlade } from '../blades/element/element-cart-blade/element-blade-cart.blade';
import { ElementConfirmationBlade } from '../blades/element/element-confirmation-blade/element-confirmation.blade';
import { ElementTextBlade } from '../blades/element/element-text-blade/element-text.blade';
import { ElementHeadlineBlade } from '../blades/element/element-headline-blade/element-headline.blade';
import { ElementImageBlade } from '../blades/element/element-image-blade/element-image.blade';
import { ElementProductBlade } from '../blades/element/element-product-blade/element-product.blade';
import { ElementVideoBlade } from '../blades/element/element-video-blade/element-video.blade';
import { ElementProductBookingBlade } from '../blades/element/element-product-booking-blade/element-product-booking.blade';
import { ElementProductListBlade } from '../blades/element/element-product-list-blade/element-product-list.blade';
import { ElementSlideshowBlade } from '../blades/element/element-slideshow-blade/element-blade-slideshow.blade';
import { ElementPaymentBlade } from '../blades/element/element-payment-blade/element-blade-payment.blade';
import { ElementLoginBlade } from '../blades/element/element-login-blade/element-blade-login.blade';
import { ElementProfileMenuBlade } from '../blades/element/element-profile-menu-blade/element-blade-profile-menu.blade';
import { ElementProfileSubpageViewerBlade } from '../blades/element/element-profile-subpage-viewer-blade/element-blade-profile-subpage-viewer.blade';
import { ElementProfileOrderBlade } from '../blades/element/element-profile-order-blade/element-blade-profile-order.blade';
import { ElementProfileEditBlade } from '../blades/element/element-profile-edit-blade/element-blade-profile-edit.blade';
import { ElementTypeEnum } from 'app/enums/element-type.enum';
import { ElementButtonBlade } from '../blades/element/element-button-blade/element-button.blade';
import { ElementCategoryListBlade } from '../blades/element/element-category-list-blade/element-category-list.blade';
import { ElementCategoryBlade } from '../blades/element/element-category-blade/element-category.blade';
import { ElementFormBlade } from '../blades/element/element-form-blade/element-form.blade';

@Injectable()
export class ElementService {
	constructor(private bladeService: BladeService) { }

	getElementComponent(elementTypeIdentifier: string): any {
		switch (elementTypeIdentifier) {
			case ElementTypeEnum.Text:
				return ElementTextBlade;
			case ElementTypeEnum.Image:
				return ElementImageBlade;
			case ElementTypeEnum.Video:
				return ElementVideoBlade;
			case ElementTypeEnum.Headline:
				return ElementHeadlineBlade;
			case ElementTypeEnum.Product:
				return ElementProductBlade;
			case ElementTypeEnum.ProductBooking:
				return ElementProductBookingBlade;
			case ElementTypeEnum.MatCard:
				return ElementCardBlade;
			case ElementTypeEnum.Cart:
				return ElementCartBlade;
			case ElementTypeEnum.ProductList:
				return ElementProductListBlade;
			case ElementTypeEnum.Confirmation:
				return ElementConfirmationBlade;
			case ElementTypeEnum.Slideshow:
				return ElementSlideshowBlade;
			case ElementTypeEnum.Payment:
				return ElementPaymentBlade;
			case ElementTypeEnum.Login:
				return ElementLoginBlade;
			case ElementTypeEnum.ProfileMenu:
				return ElementProfileMenuBlade;
			case ElementTypeEnum.ProfileSubpageViewer:
				return ElementProfileSubpageViewerBlade;
			case ElementTypeEnum.ProfileOrders:
				return ElementProfileOrderBlade;
			case ElementTypeEnum.ProfileEdit:
				return ElementProfileEditBlade;
			case ElementTypeEnum.Button:
				return ElementButtonBlade;
			case ElementTypeEnum.CategoryList:
				return ElementCategoryListBlade;
			case ElementTypeEnum.Category:
				return ElementCategoryBlade;
			case ElementTypeEnum.Form:
				return ElementFormBlade;
			default:
				break;
		}
	}
}
