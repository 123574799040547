
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { TourStatus } from 'app/models/tour-status.model';

@Injectable({
	providedIn: 'root',
})
export class TourStatusStore extends BaseStore<TourStatus> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TourStatus', baseUrl, snackBarService);
	}
}
