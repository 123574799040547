<!-- <mat-card>
	<mat-card-title>Orderrader</mat-card-title> -->
	<table mat-table [dataSource]="dataSource" matSort class="w-100">
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
			<td mat-cell *matCellDef="let item">
				<p class="m-0">{{item.name}}</p>
				<span *ngFor="let age of item.tourBookingCustomerTypeAges; let last = last; let i = index;">
					{{item.customerTypeId | customerTypeName | async}} {{i + 1}}: {{age.birthDate | date:'yyyy-MM-dd'}} ({{moment().diff(age.birthDate, 'years', false)}}år)
					<span *ngIf="!last">, </span>
				</span>
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>
		<ng-container matColumnDef="customerType">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
			<td mat-cell *matCellDef="let item">
				<span *ngIf="item.customerTypeId">{{item.customerTypeId | customerTypeName | async}}</span>
				<span *ngIf="item.addonId">Tillägg</span>
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>
		<ng-container matColumnDef="quantity">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Antal</th>
			<td mat-cell *matCellDef="let item" class="text-center">{{item.quantity}}</td>
			<td mat-footer-cell *matFooterCellDef><b>Totalt:</b></td>
		</ng-container>
		<ng-container matColumnDef="grossTotalAmount">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Total belopp</th>
			<td mat-cell *matCellDef="let item">{{item.grossTotalAmount | currency:'SEK':'code'}}</td>
			<td mat-footer-cell *matFooterCellDef><b>{{totalAmountReplay$ | async | currency:'SEK':'code'}}</b></td>
		</ng-container>
		<ng-container matColumnDef="totalPaid">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Betalat</th>
			<td mat-cell *matCellDef="let item">{{item.totalPaid | currency:'SEK':'code'}}</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
		<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>

	<div class="text-right">
		<button mat-stroked-button color="accent" (click)="createNew()">Lägg till ny rad</button>
	</div>
<!-- </mat-card> -->
