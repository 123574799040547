import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AddonStore } from 'app/admin/stores/addon.store.service';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { TourBookingAddon } from 'app/models/tour-booking-addon.model';
import { TourBookingAddonService } from '../../services/tour-booking-addon.service';
import { TourBookingAddonItemService } from './services/tour-booking-addon-item.service';

@UntilDestroy()
@Component({
  	selector: 'app-tour-booking-addon-item',
  	templateUrl: './tour-booking-addon-item.component.html',
  	styleUrls: ['./tour-booking-addon-item.component.scss'],
	providers: [TourBookingAddonItemService],
  	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourBookingAddonItemComponent implements OnInit {
	@Input() set tourBookingAddon(tourBookingAddon: TourBookingAddon) {
		this.tourBookingAddonItemService.setTourBookingAddonItem(tourBookingAddon);
	}

  	constructor(
		public customerTypeStore: CustomerTypeStore,
		public tourBookingAddonItemService: TourBookingAddonItemService,
		public tourBookingAddonService: TourBookingAddonService,
		public addonStore: AddonStore,
	) { }

  	ngOnInit() {
  	}

}
