<form [formGroup]="itemForm">
	<div class="d-flex align-items-center">
		<mat-form-field class="flex-grow-1" appearance="outline">
			<input matInput type="date" [min]="0" formControlName="birthDate" placeholder="Födelsedatum">
		</mat-form-field>
		<button mat-icon-button color="warn" class="mb-3" (click)="tourBookingCustomerTypeItemService.removeAge(currentTourBookingCustomerTypeAge)">
			<mat-icon>delete_forever</mat-icon>
		</button>
	</div>
</form>
