import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { Tour } from 'app/models/tour.model';
import { User } from '../models/user.model';
import * as moment from 'moment';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'plannerGuideGuideWarning' })
export class PlannerGuideGuideWarningPipe implements PipeTransform {
	constructor() {	}
	public transform(user: User, tours: Tour[]): string {
		if (!user) {
			return;
		}
		let className = '-';
		const toursOnUser = _.filter(tours, tour => tour.tourGuides?.filter(tg => tg.userId === user.id)?.length > 0);
		if (!toursOnUser?.length) {
			className = 'text-info';
		} else {
			const orderTours = _.orderBy(toursOnUser, ['date', 'time'], ['asc', 'asc']);
			orderTours.forEach((tour, index) => {
				if (index) {
					const previousTour = orderTours[index - 1];
					const previousTourStart = moment(`${moment(previousTour.date).format('YYYY-MM-DD')} ${previousTour.time}`);
					const previousTourEnd = moment(`${moment(previousTour.date).format('YYYY-MM-DD')} ${previousTour.time}`).add(previousTour.timeLength, 'hours');

					const currentTourStart = moment(`${moment(tour.date).format('YYYY-MM-DD')} ${tour.time}`);

					if (previousTourStart.isSameOrBefore(currentTourStart) && previousTourEnd.isAfter(currentTourStart)) {
						className = 'text-warning';
					}
				}
			});

		}
		return className;
	}
}
