<div class="container py-3" *ngIf="{
	tours: extendedTours$ | async,
	settings: siteSettings$ | async,
	customer: customer$ | async,
	language: language$ | async,
	adminText: 'customerProgram' | adminText: ((language$ | async)?.prefix) | async
} as source">
	<mat-card>
		<div class="align-items-center d-flex justify-content-between mb-3 pb-3 border-bottom">
			<img class="logo" [src]="source.settings?.logo">
			<div>
				<p class="title">{{source.adminText?.title}}</p>
				<p class="customer">{{source.customer?.firstName}} {{source.customer?.lastName}}</p>
			</div>
		</div>
		<div *ngFor="let tour of source.tours" class="mb-3 pb-3 border-bottom avoid-page-break-inside">
			<div class="d-flex justify-content-between">
				<p class="mb-1">
					<strong>{{tour.id | tourName: source.language?.id | async}}</strong>
				</p>
				<p class="d-flex justify-content-between mb-1">
					<span *ngIf="tour.reference" class="mr-3">source.adminText?.reference: {{tour.reference}}</span>
					<span>{{tour.date | date:'yyyy-MM-dd'}} {{tour.time}}</span>
				</p>
			</div>
			<p>{{source.adminText?.status}} {{source.adminText?.statusMapping[tour.tourStatusId | tourStatusName | async]}}</p>
			<div class="ml-3">
				<div *ngFor="let summary of tour.summaries">
					{{source.adminText?.pickup}} <b>{{summary.pickupLocationId | pickupLocationName: source.language?.id | async}}</b> {{source.adminText?.pickupTime}} <b>{{summary.relativeTime}}</b>. {{source.adminText?.pickupAmount}} <b>{{summary.quantity}}{{source.adminText?.pickupPct}} {{summary.customerTypeId | customerTypeName: source.language?.id | async}}</b>

					<div class="mt-1 ml-3" *ngIf="summary.tourBookingMessage">
						<p class="m-0">
							<strong>{{source.adminText?.message}}</strong>
						</p>
						<p class="mb-0" [innerHtml]="summary.tourBookingMessage | newLine"></p>
					</div>
				</div>
			</div>
			<p *ngIf="tour.message" [innerHtml]="tour.message"></p>
		</div>
	</mat-card>
</div>
