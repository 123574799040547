import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { ColumnStore } from 'app/admin/stores/column.store.service';
import { EmailStore } from 'app/admin/stores/email.store.service';
import { Email } from 'app/models/email/email.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { EmailBladeComponent } from '../../../email-blade/email-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-email-blade-outbox',
	templateUrl: './email-blade-outbox.component.html',
	styleUrls: ['./email-blade-outbox.component.scss'],
})
export class EmailBladeOutboxComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['date', 'toEmail', 'latestEvent'];
	public dataSource: MatTableDataSource<Email>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public columnStore: ColumnStore,
		private emailStore: EmailStore,
	) {}

	ngOnInit() {
		this.emailStore.getAll$()
			.pipe(untilDestroyed(this))
			.subscribe((emails: EmailExtended[]) => {

				emails.forEach(email => {
					email.data = JSON.parse(email.data);
					if (email.data && email.data.to) {
						email.toEmail = email.data.to[0].name + ' ' + email.data.to[0].email;
					}

					if (email.data && email.sendinBlueEvents && email.sendinBlueEvents.length) {
						const lastEvent = _.first(_.orderBy(email.sendinBlueEvents, event => event.created, 'desc'));
						lastEvent.data = JSON.parse(lastEvent.data);
						email.latestEvent = {
							event: lastEvent.data.event,
							created: lastEvent.created,
						};
					}

				});
				this.dataSource = new MatTableDataSource<EmailExtended>(_.orderBy(emails, e => e.created, 'desc'));
				this.dataSource.paginator = this.paginator;
		});
	}

	ngOnDestroy(): void { }

	public selectItem(row: Email) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: EmailBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}
}

interface EmailExtended extends Email {
	toEmail: string;
	latestEvent: any;
}
