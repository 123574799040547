import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { BladeService } from '../blade/blade.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { NotificationService } from 'app/services/notification.service';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class AdminAppComponent implements AfterViewInit {
	constructor(
		private bladeService: BladeService,
		private analyticsService: AnalyticsService,
		private router: Router,
		private angulartics: Angulartics2GoogleGlobalSiteTag,
		private snackBarService: SnackBarService,
		private clipboardService: ClipboardService,
	) {
		this.clipboardService.copyResponse$.subscribe((res: IClipboardResponse) => {
			if (res.isSuccess) {
				this.snackBarService.showSuccess('Kopierat');
			}
		});
		// this.analyticsService.startTrack(this.router);
	}
	ngAfterViewInit(): void {
		this.analyticsService.registerGoogleAnalytics(location.host === 'admin.questsystem.se' ? 'G-DEG25ES5WQ' : 'G-20RC9XB7KM');
		setTimeout(() => {
			this.angulartics.startTracking();
		}, 1000);
	}
}
