<mat-card>
	<mat-card-title>Tillägg</mat-card-title>
	<mat-card-subtitle>Klicka i de tillägg som ska vara tillgängliga på produkten på hemsidan</mat-card-subtitle>

	<div class="mat-elevation-z1">
		<table mat-table [dataSource]="dataSource" matSort class="w-100">
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					Välj
				</th>
				<td mat-cell *matCellDef="let item">
					<mat-checkbox class="mr-3 d-block"
						[checked]="!!item.productAddon"
						(change)="toggleProductAddon(item)">
					</mat-checkbox>
				</td>
			</ng-container>
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
				<td mat-cell *matCellDef="let item"><strong>{{item.addon.name}}</strong></td>
			</ng-container>
			<ng-container matColumnDef="price">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Pris</th>
				<td mat-cell *matCellDef="let item">
					<div class="align-items-center d-flex justify-content-between">
						<span>
							<del class="mr-2" *ngIf="item.productAddon && (item.productAddon?.price !== '' && item.productAddon?.price !== undefined && item.productAddon?.price !== null)">
								{{item.addon.price}}
							</del>
							{{
								item.productAddon && (item.productAddon?.price !== '' && item.productAddon?.price !== undefined && item.productAddon?.price !== null)
								? item.productAddon?.price
								: item.addon.price
							}}
						</span>
					</div>
				</td>
			</ng-container>
			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
				<td mat-cell *matCellDef="let item">
					<button mat-button color="accent" *ngIf="item?.productAddon" (click)="openAddon(item.productAddon)">Ändra</button>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" [class.selected]="bladeItem?.child?.id === item?.addon.id"></tr>
		</table>

		<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	</div>
</mat-card>
