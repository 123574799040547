<mat-expansion-panel>
	<mat-expansion-panel-header>
		<mat-panel-title>
			{{group.supplier.name}}
		</mat-panel-title>
		<mat-panel-description>
			<span *ngFor="let resourceSummary of resourceSummaryReplay$ | async; last as isLast">
				<span [class.text-line-through]="resourceSummary?.supplierResourceDate">{{resourceSummary.amount}}st </span>
				<span *ngIf="resourceSummary?.supplierResourceDate">{{resourceSummary?.supplierResourceDate.amount}}st </span>
				{{resourceSummary.supplierResourceTypeId | supplierResourceTypeName | async}}
				<span *ngIf="!isLast">,&nbsp;</span>
			</span>
		</mat-panel-description>
	</mat-expansion-panel-header>
	<mat-card>
		<mat-card-content>
			<mat-accordion>
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							Resurser
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-card class="mb-3" *ngFor="let resourceSummary of resourceSummaryReplay$ | async; last as isLast">
						<mat-card-subtitle>{{resourceSummary.supplierResourceTypeId | supplierResourceTypeName | async}}</mat-card-subtitle>
						<p>Standard: {{resourceSummary.supplierResource?.amount}}</p>
						<p>Du kan ändra antalet för den här dagen i rutan nedan.</p>
						<form [formGroup]="resourceForm">
							<div fxLayout="row" fxLayoutAlign="space-between center">
								<mat-form-field class="flex-grow-1 mr-3">
									<input matInput type="number" [formControlName]="'amount' + resourceSummary.supplierResourceTypeId" placeholder="Antal för detta datum">
								</mat-form-field>
								<button mat-flat-button color="primary" (click)="save(resourceSummary)">
									<span *ngIf="!resourceSummary?.supplierResourceDate">Skapa undantag</span>
									<span *ngIf="resourceSummary?.supplierResourceDate">Uppdatera</span>
								</button>
							</div>
						</form>
					</mat-card>
				</mat-expansion-panel>
			</mat-accordion>

			<mat-accordion>
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							Turer
						</mat-panel-title>
						<mat-panel-description>
							{{group?.tours?.length || 0}}st
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class="mb-3" *ngFor="let item of group.tours">
						<app-tour-resource [tour]="item"></app-tour-resource>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</mat-card-content>
	</mat-card>
</mat-expansion-panel>
