
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReplaySubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { HeadlineElement } from 'app/models/headline-element.model';
import { Language } from 'app/models/language.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class HeadlineElementStore extends BaseStore<HeadlineElement> {
	private allOnElement: HeadlineElement[];
	protected allOnElementSubject = new ReplaySubject<HeadlineElement[]>(1);
	public allOnElement$ = this.allOnElementSubject.asObservable();

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'HeadlineElements', baseUrl, snackBarService);
	}

	public getAllOnElement(elementId: number, languages: Language[]): Observable<HeadlineElement[]> {
		return this.http.get<HeadlineElement[]>(`${this.host}/element/${elementId}`).pipe(tap(imageElements => {
			this.allOnElement = imageElements;
			if (!this.allOnElement) {
				this.allOnElement = [];
			}
			_.each(languages, lang => {
				if (!_.includes(_.map(this.allOnElement, 'languageId'), lang.id)) {
					const imageElement = new HeadlineElement();
					imageElement.languageId = lang.id;
					imageElement.elementId = elementId;
					this.allOnElement.push(imageElement);
				}
			});
			this.allOnElementSubject.next(this.allOnElement);
		}));
	}
}
