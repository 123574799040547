<mat-card class="mb-3">
	<mat-card-title class="card-title-small">Kundtyper</mat-card-title>
	<mat-table [dataSource]="selectProductToOrderBladeService.customerTypeOrdersReplay$ | async" class="w-100">
		<ng-container matColumnDef="type">
			<mat-header-cell *matHeaderCellDef>Kund-typ</mat-header-cell>
			<mat-cell *matCellDef="let item" [class.text-line-through]="item.avaiableQuantity === 0">{{item.customerTypeId | customerTypeName | async}} </mat-cell>
		</ng-container>
		<ng-container matColumnDef="quantity">
			<mat-header-cell *matHeaderCellDef>Antal</mat-header-cell>
			<mat-cell *matCellDef="let item">
				<mat-form-field class="w-100 pr-2">
					<input type="number" appearance="outline" min="0" matInput [(ngModel)]="item.quantity" [disabled]="item.avaiableQuantity === 0" (ngModelChange)="change()">
				</mat-form-field>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="unitPrice">
			<mat-header-cell *matHeaderCellDef>á pris</mat-header-cell>
			<mat-cell *matCellDef="let item">
				<mat-form-field class="w-100">
					<input type="number" appearance="outline" min="0" matInput [(ngModel)]="item.unitPrice" [disabled]="item.avaiableQuantity === 0 || userService.isTravelAgencyUser()">
				</mat-form-field>
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let item; columns: displayedColumns;"></mat-row>
	</mat-table>
</mat-card>
