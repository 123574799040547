<mat-card>
	<mat-card-content>
		<mat-card-title>Email templates</mat-card-title>
		<mat-card-subtitle>
			Nedan listas all templates som används i diverse mejlutskick.
		</mat-card-subtitle>
		<mat-card-content>
			<app-settings-email-template-new-order></app-settings-email-template-new-order>
			<app-settings-email-template-new-order-draft></app-settings-email-template-new-order-draft>
			<app-settings-email-template-new-customer class="mt-3 d-block"></app-settings-email-template-new-customer>
		</mat-card-content>
	</mat-card-content>
</mat-card>
