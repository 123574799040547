import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage-page',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepagePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
