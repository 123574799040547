import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { CustomerTypeStore } from '../stores/customer-type.store.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PickupLocationStore } from '../stores/pickup-location.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'pickupLocationName' })
export class PickupLocationNamePipe implements PipeTransform, OnDestroy {
	constructor(private pickupLocationStore: PickupLocationStore) {	}
	public transform(pickupLocationId: number, languageId?: number): Observable<string> {
		if (!pickupLocationId) {
			return;
		}
		return this.pickupLocationStore.pipeGetName(pickupLocationId, languageId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
