<ng-container *ngIf="{
	isHandset: isHandset$ | async,
	hideMenuSideNav: hideMenuSideNav$ | async,
	adminText: 'sideNav' | adminText | async,
	isExpanded: isExpandedReplay$ | async,
	systems: systemStore.items$ | async
} as source">
	<ng-container *ngIf="!source.hideMenuSideNav">
		<mat-toolbar color="primary">
			<button
				type="button"
				aria-label="Toggle sidenav"
				mat-icon-button
				(click)="toggleMenu()">
				<mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
			</button>

			<img *ngIf="!source.isHandset" class="logo" src="qs_logo_horizontal_white.png" alt="Logo">
			<img *ngIf="source.isHandset" class="logo" src="qs_logo_small_white.png" alt="Logo">
			<span class="beta">beta</span>

			<span class="fill-remaining-space" *ngIf="!source.systems"></span>
			<span class="fill-remaining-space text-center company-name" *ngIf="source.systems">{{source.systems[0]?.name}}</span>
			<span *ngIf="(userService.user$|async)?.firstName && !source.isHandset">{{(userService.user$|async)?.firstName}}</span>
			<button mat-icon-button (click)="accountClick()"><mat-icon>account_circle</mat-icon></button>
			<button mat-icon-button (click)="authService.logout()" title="Logga ut"><mat-icon>logout</mat-icon></button>
			<button mat-icon-button (click)="settings.toggle()"><mat-icon>settings</mat-icon></button>
		</mat-toolbar>
		<mat-sidenav-container class="sidenav-container" #sidenavContainer>
			<mat-sidenav
				*ngIf="source.isHandset"
				#mobileDrawer
				class="sidenav mat-elevation-z5 open"
				fixedInViewport="false"
				[attr.role]="'dialog'"
				[mode]="'over'"
				[opened]="false">
				<ng-container *ngTemplateOutlet="navMenuContent"></ng-container>
			</mat-sidenav>
			<mat-sidenav
				*ngIf="!source.isHandset"
				#drawer
				class="sidenav mat-elevation-z5"
				fixedInViewport="false"
				attr.role="navigation"
				mode="side"
				opened="true"
				[class.open]="source.isExpanded">
				<ng-container *ngTemplateOutlet="navMenuContent"></ng-container>
			</mat-sidenav>

			<mat-sidenav
				#settings
				class="settings-sidenav"
				fixedInViewport="true"
				mode="over"
				position="end">
				<mat-toolbar color="primary" class="justify-content-between">{{source.adminText?.settings?.settingsTitle}}<mat-icon class="pointer" (click)="settings.toggle()">close</mat-icon></mat-toolbar>
				<mat-nav-list>
					<mat-toolbar class="small">{{source.adminText?.settings?.themesTitle}} <mat-icon class="ml-3">format_color_fill</mat-icon></mat-toolbar>
					<a mat-list-item (click)="selectTheme('deeppurple-amber')" [class.font-bold]="'deeppurple-amber' === selectedTheme">Deeppurple-amber</a>
					<a mat-list-item (click)="selectTheme('indigo-pink')" [class.font-bold]="'indigo-pink' === selectedTheme">Indigo-pink</a>
					<a mat-list-item (click)="selectTheme('pink-bluegrey')" [class.font-bold]="'pink-bluegrey' === selectedTheme">Pink-bluegrey</a>
					<a mat-list-item (click)="selectTheme('purple-green')" [class.font-bold]="'purple-green' === selectedTheme">Purple-green</a>
				</mat-nav-list>
				<mat-nav-list>
					<mat-toolbar class="small">{{source.adminText?.settings?.languageTitle}} <mat-icon class="ml-3">translate</mat-icon></mat-toolbar>
					<a mat-list-item *ngFor="let language of adminDataService.avaiableLanguages$ | async" (click)="adminDataService.selectLanguage(language)" [class.font-bold]="language.isSelected">{{language.name}}</a>
				</mat-nav-list>
			</mat-sidenav>
			<mat-sidenav-content class="--blade-main-content">
				<ng-container *ngTemplateOutlet="outlet"></ng-container>
			</mat-sidenav-content>
		</mat-sidenav-container>
	</ng-container>

	<!-- Hide menu and side-nav -->
	<ng-container *ngIf="source.hideMenuSideNav">
		<ng-container *ngTemplateOutlet="outlet"></ng-container>
	</ng-container>

	<ng-template #navMenuContent>
		<div class="nav-list-wrapper">
			<mat-nav-list>
				<ng-container *ngFor="let item of menuItemsService.menuItemsReplay$ | async">
					<app-menu-list-item *hasRoles="item.roles" [item]="item" [isExpanded]="source.isExpanded || source.isHandset"></app-menu-list-item>
				</ng-container>
			</mat-nav-list>
		</div>
		<mat-toolbar *hasRoles="['settings', 'admin']" class="settings d-flex justify-content-between pointer" [routerLink]="'./settings'">
			<span *ngIf="source.isExpanded || source.isHandset">{{source.adminText?.menu?.settings}}</span> <mat-icon>settings</mat-icon>
		</mat-toolbar>
	</ng-template>

	<ng-template #outlet>
		<router-outlet></router-outlet>
	</ng-template>
</ng-container>

