<app-blade-item [canIClose]="true" [loading]="source.isLoading" *ngIf="{
	isLoading: customerTypeService.isLoadingReplay$ | async,
	item: customerTypeService.itemReplay$ | async
} as source">
	<app-blade-header [title]="source.item?.name||'Ny'" [subTitle]="'Kund-typ'"></app-blade-header>
    <app-blade-content (indexChange)="customerTypeService.selectedIndex = $event">
		<app-blade-content-tabs>
			<app-blade-content-tab [title]="'Generellt'" [template]="generally">
				<ng-template #generally>
					<app-customer-type-blade-generally></app-customer-type-blade-generally>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Texter'" [template]="texts">
				<ng-template #texts>
					<app-customer-type-blade-texts></app-customer-type-blade-texts>
				</ng-template>
			</app-blade-content-tab>
		</app-blade-content-tabs>
	</app-blade-content>
</app-blade-item>
