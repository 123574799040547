import { Injectable, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EmailQueue } from 'app/modules/email/models/email-queue.model';
import { EmailQueueStore } from 'app/modules/email/stores/email-queue.store';

@Injectable()
export class EmailQueueService {
	private entityName: string;
	private entityId: number;
	public displayedColumns: string[] = ['emailQueueType', 'sourceTypeId', 'created', 'sent'];
	public dataSource: MatTableDataSource<EmailQueue>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;


	constructor(private emailQueueStore: EmailQueueStore) {

	}

	public getQueue(entityName: string, entityId: number)  {
		if (entityName && entityId) {
			this.entityName = entityName;
			this.entityId = entityId;
			this.emailQueueStore.getAllOnEntity(this.entityName, this.entityId).subscribe(emailQueues => {
				this.dataSource = new MatTableDataSource<EmailQueue>(emailQueues);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;
			});
		}
	}

	public senNewEmail(emailQueueType: string) {
		if (!emailQueueType) {
			return;
		}

		switch (emailQueueType) {
			case 'NewCustomer':
				this.emailQueueStore.addNewCustomerQueue(this.entityId).subscribe(_ => this.getQueue(this.entityName, this.entityId));
				break;
			case 'NewOrder':
				this.emailQueueStore.addNewOrderQueue(this.entityId).subscribe(_ => this.getQueue(this.entityName, this.entityId));
				break;
			case 'DraftOrder':
				this.emailQueueStore.addDraftOrderQueue(this.entityId).subscribe(_ => this.getQueue(this.entityName, this.entityId));
				break;

			default:
				break;
		}
	}
}
