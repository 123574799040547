<ng-container *ngIf="{
	specialDietMessages: tourBookingSpecialDietMessageService.specialDietMessagesReplay$ | async
} as source">
	<pre>{{source.specialDietMessages | json}}</pre>
	<mat-card class="mb-3">
		<mat-card-title class="card-title-small">Specialkost meddelande</mat-card-title>
		<mat-card-content>
			<div [formGroup]="tourBookingSpecialDietMessageService.specialDietMessageForm" mat-dialog-content>
				<mat-form-field class="w-100" appearance="outline">
				<mat-label>Ange eventuellt meddelande angående specialkosten</mat-label>
				<textarea
					matInput
					[cdkTextareaAutosize]="true"
					#autosize="cdkTextareaAutosize"
					[cdkAutosizeMinRows]="3"
					[cdkAutosizeMaxRows]="7"
					formControlName="message"></textarea>
				</mat-form-field>
			</div>
		</mat-card-content>
		<mat-card-actions>
			<button mat-button (click)="tourBookingSpecialDietMessageService.save()">Spara meddelandet</button>
		</mat-card-actions>
	</mat-card>
</ng-container>
