import { BaseModel } from './base.model';
import { OrderItem } from './order-item.model';

export class TourBookingAddon extends BaseModel {
	public tourBookingId: number;
	public addonId: number;
	public quantity: number;
	public orderItemId?: number;
	public orderItem: OrderItem;
}
