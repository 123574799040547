import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SystemDibsStore } from 'app/admin/stores/system-dibs.store.service';

@Component({
	selector: 'app-settings-payment',
	templateUrl: './settings-payment.component.html',
	styleUrls: ['./settings-payment.component.scss'],
})
export class SettingsPaymentComponent {
	public itemForm: FormGroup = new FormGroup({
		id: new FormControl(0),
		secretKey: new FormControl('', [Validators.required]),
		checkoutKey: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public systemDibsStore: SystemDibsStore,
	) {
		this.systemDibsStore.getActive().subscribe(emailCompany => this.itemForm.patchValue(emailCompany));
	}

	public save() {
		if (!this.itemForm.valid) {
			return;
		}
		if (this.itemForm.value.id) {
			this.systemDibsStore.update(this.itemForm.value).subscribe(emailCompany => this.itemForm.patchValue(emailCompany));
		} else {
			this.systemDibsStore.create(this.itemForm.value).subscribe(emailCompany => this.itemForm.patchValue(emailCompany));
		}
	}
}
