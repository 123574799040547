import { Component, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderPayment } from 'app/models/order-payment.model';
import { OrderPaymentStore } from 'app/admin/stores/order-payment.store.service';
import { PaymentBladeComponent } from '../payment-blade/payment-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-payments-blade',
	templateUrl: './payments-blade.component.html',
	styleUrls: ['./payments-blade.component.scss'],
})
@IBladeComponent.register
export class PaymentsBladeComponent {
	public displayedColumns: string[] = ['id', 'paymentTypeId', 'paymentReference', 'amount', 'message', 'sourceTypeId', 'created'];
	public dataSource: MatTableDataSource<OrderPayment>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: OrderPaymentStore,
	) {
		this.itemStore.getAll$().pipe(untilDestroyed(this)).subscribe(orderPayments => {
			this.dataSource = new MatTableDataSource<OrderPayment>(_.orderBy(orderPayments, ['created'], 'desc'));
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: PaymentBladeComponent,
			minClass: BladeSize.s,
		}, this.bladeItem);
	}
}
