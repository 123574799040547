<ng-container>
	<h2 mat-dialog-title>Redigera widget för "Ordrar med rest"</h2>
	<mat-dialog-content>
		<form [formGroup]="dashboardWidgetOrderRestService.formGroup">
			<mat-form-field class="w-100"  appearance="outline">
				<mat-label>Från och till datum</mat-label>
				<mat-date-range-input
					[rangePicker]="campaignOnePicker">
					<input readonly matStartDate placeholder="Från datum" type="datetime" formControlName="fromDate">
					<input readonly matEndDate placeholder="Till datum" formControlName="toDate">
				</mat-date-range-input>
				<mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
				<mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
			</mat-form-field>
			<p>Betalmetod:</p>
			<mat-checkbox class="mr-3" *ngFor="let type of dashboardWidgetOrderRestService.paymentTypeStore.items$ | async" [checked]="dashboardWidgetOrderRestService.selectedPaymentTypes?.isSelected(type.id)" (change)="dashboardWidgetOrderRestService.selectedPaymentTypes.toggle(type.id)">{{type.name}}</mat-checkbox>
			<mat-checkbox class="mr-3" [checked]="dashboardWidgetOrderRestService.selectedPaymentTypes?.isSelected(999)" (change)="dashboardWidgetOrderRestService.selectedPaymentTypes.toggle(999)">Saknar betalning</mat-checkbox>
			<button mat-stroked-button mat-dialog-close class="w-100" color="accent" (click)="dashboardWidgetOrderRestService.getOrders()">Hämta</button>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-button mat-dialog-close>Stäng</button>
	</mat-dialog-actions>
</ng-container>
