import { Component, OnInit } from '@angular/core';
import { DashboardWidgetSize } from 'app/admin/components/dashboard/models/dashboard-widget-size.model';
import { DashboardWidget } from 'app/admin/components/dashboard/models/dashboard-widget.model';
import { DashboardWidgetType } from 'app/admin/components/dashboard/models/dashboard-widget-type.model';

@Component({
  selector: 'app-categories-dashboard',
  templateUrl: './categories-dashboard.component.html',
  styleUrls: ['./categories-dashboard.component.scss'],
})
export class CategoriesDashboardComponent implements OnInit {
	private dashboardSizes = new DashboardWidgetSize();

	constructor() { }

	ngOnInit() {
	}

}
