<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem?.id">
	<app-blade-header [title]="'Bild'" [subTitle]="'Bildspel'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content *ngIf="languages" [menuItems]="menuItems">
		<mat-card>
		<form [formGroup]="itemForm">
			<app-image [maxHeigth]="200" [maxWidth]="200" [mediaId]="activeItem?.mediaId"></app-image>
			<button mat-flat-button [color]="'primary'" (click)="selectImage()" class="float-right">
				<span *ngIf="activeItem?.mediaId">Ändra bild</span>
				<span *ngIf="!activeItem?.mediaId">Välj bild</span>
			</button>
			<mat-form-field class="w-100">
				<input matInput formControlName="headline" placeholder="Rubrik">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="description" placeholder="Beskrivning">
			</mat-form-field>
		</form>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="activeItem?.id">Spara</span>
			<span *ngIf="!activeItem?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
