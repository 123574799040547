import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TourGuide } from 'app/models/tour-guide.model';
import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class TourGuideStore extends BaseStore<TourGuide> {
	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TourGuide', baseUrl, snackBarService);
	}

	public getAllOnTour(tourId: number): Observable<TourGuide[]> {
		return this.http.get<TourGuide[]>(`${this.host}/tour/${tourId}`).pipe(tap(tourGuides => {
			this._items = tourGuides;
			this._itemsSubject.next(this._items);
		}));
	}
}
