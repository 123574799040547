import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import * as _ from 'lodash';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourBookingPickupLocationStore } from 'app/admin/stores/tour-booking-pickup-location.store.service';
import { TourSummaryService } from './services/tour-summary.service';

@Component({
	selector: 'app-guide-tour-summary',
	templateUrl: './tour-summary.component.html',
	styleUrls: ['./tour-summary.component.scss'],
	providers: [TourSummaryService],
})
export class GuideTourSummaryComponent implements OnInit, OnDestroy {

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public tourBookingStore: TourBookingStore,
		private bladeService: BladeService,
		private tourBookingPickupLocationStore: TourBookingPickupLocationStore,
		private tourSummaryService: TourSummaryService,
	) {
	}

	ngOnInit() {}
	ngOnDestroy() {}

}
