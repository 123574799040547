<app-blade-item [canIClose]="true" [loading]="loading" [id]="item?.id">
	<app-blade-header [title]="'Inställningar'"></app-blade-header>
	<app-blade-content>
		<app-site-settings-blade-generally [item]="item"></app-site-settings-blade-generally>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			Spara
		</button>
	</app-blade-footer>
</app-blade-item>
