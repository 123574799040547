<app-blade-item [canIClose]="true">
   <app-blade-header [title]="'Kunder'"></app-blade-header>
   <app-blade-content>
	   <mat-card>
			<app-customer-list></app-customer-list>
		</mat-card>
   </app-blade-content>
   <app-blade-footer>
	   <span fxFlex></span>
	   <button mat-raised-button color="primary" (click)="createNew()">Skapa ny</button>
   </app-blade-footer>
</app-blade-item>
