import { ReplaySubject } from 'rxjs';
import 'reflect-metadata';

import * as _ from 'lodash';

import { BladeComponent } from '../models/bladeComponent.model';

export interface IBladeComponent {
	// add some methods or something to distinguish from {}
	// routeName: string;
}

// add a registry of the type you expect
export namespace IBladeComponent {
	interface Constructor<T> {
		new(...args: any[]): T;
		readonly prototype: T;
	}

	const implementations: BladeComponent[] = [];
	const implementationsReplaySubject = new ReplaySubject<BladeComponent[]>(1);
	export const implementations$ = implementationsReplaySubject.asObservable();

	export function register<T extends Constructor<IBladeComponent>>(ctor: T) {
		implementations.push({
			component: ctor,
		});
		implementationsReplaySubject.next(implementations);
		return ctor;
	}
}
