import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as _ from 'lodash';

import { DashboardWidgetOrderRestService } from 'app/admin/components/dashboard/services/dashboard-widget-order-rest.service';
import { DashboardWidget } from 'app/admin/components/dashboard/models/dashboard-widget.model';


@Component({
	selector: 'app-dashboard-widget-order-rest-modal',
	templateUrl: './dashboard-widget-order-rest-modal.component.html',
	styleUrls: ['./dashboard-widget-order-rest-modal.component.scss'],
})
export class DashboardWidgetOrderRestModalComponent implements OnInit {
	public workingWidget: DashboardWidget;

	constructor(
		public dialogRef: MatDialogRef<DashboardWidgetOrderRestModalComponent>,
		@Inject(MAT_DIALOG_DATA) public dashboardWidget: DashboardWidget,
		public dashboardWidgetOrderRestService: DashboardWidgetOrderRestService,
	) {
		this.workingWidget = _.cloneDeep(dashboardWidget);
	}

	ngOnInit() {
	}

	public save() {
		this.dialogRef.close();
	}
}
