<mat-card *ngIf="{
	loggedInUser: userPageService.loggedInUserReplay$ | async
} as source">
	<mat-card-title>Lösenord</mat-card-title>
	<mat-card-subtitle>Vill du ändra lösenordet?</mat-card-subtitle>

	<p *ngIf="source.loggedInUser?.requireChangePassword" class="rounded bg-warning p-2 text-white my-3">Någon har begärt att lösenordet ska bytas. Det gör du enklast nedan. Annars tvingas du byta lösenord nästa gång du loggar in.</p>

	<form [formGroup]="itemForm">
		<mat-form-field appearance="outline" class="w-100">
			<mat-label>Ditt nuvarnade lösenord</mat-label>
			<input
				matInput
				type="password"
				placeholder="Ditt nuvarnade lösenord"
				formControlName="password"
			/>
		</mat-form-field>

		<mat-form-field appearance="outline" class="w-100">
			<mat-label>Ditt nya lösenord</mat-label>
			<input
				matInput
				type="password"
				placeholder="Lösenord"
				formControlName="newPassword"
			/>
		</mat-form-field>

		<mat-form-field appearance="outline" class="w-100">
			<mat-label>Ditt nya lösenord, igen</mat-label>
			<input
				matInput
				type="password"
				placeholder="Lösenord"
				formControlName="newPassword2"
			/>
		</mat-form-field>

		<div *ngIf="errorMessageReplay$ | async as message" class="rounded bg-danger p-2 text-white mb-3">
			{{message}}
		</div>

		<div class="text-right">
			<button mat-raised-button color="primary" [disabled]="!itemForm.valid || itemForm.value.newPassword !== itemForm.value.newPassword2" (click)="changePassword()">
				Updatera
			</button>
		</div>
	</form>
</mat-card>
