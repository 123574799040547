import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserPageService } from './services/user-page.service';

@UntilDestroy()
@Component({
	selector: 'app-user-page',
	templateUrl: './user-page.component.html',
	styleUrls: ['./user-page.component.scss'],
	providers: [UserPageService],
})
export class UserPageComponent implements OnInit {

	constructor(
		public userPageService: UserPageService,
	) { }

	ngOnInit() {
	}


}
