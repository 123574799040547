import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TokenModel } from '../models/token.model';

@Injectable({
	providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {

	constructor(private authService: AuthService) { }

	isRefreshingToken = false;
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	intercept(request: HttpRequest<any>, next: HttpHandler):
		Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
		return next.handle(this.addTokenToRequest(request, this.authService.getAuthToken()))
			.pipe(
				catchError(err => {
					if (err instanceof HttpErrorResponse) {
						switch ((err as HttpErrorResponse).status) {
							case 401:
								return this.handle401Error(request, next);
							// case 400:
							// 	this.isRefreshingToken = false;
							// 	return this.authService.logout() as any;
							default:
								this.isRefreshingToken = false;
								return throwError(err);
						}
					} else {
						this.isRefreshingToken = false;
						return throwError(err);
					}
				}));
	}

	private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
		return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			// Reset here so that the following requests wait until the token
			// comes back from the refreshToken call.
			this.tokenSubject.next(null);

			return this.authService.refreshToken()
				.pipe(
					switchMap((tokenModel: TokenModel) => {
						this.isRefreshingToken = false;
						if (tokenModel) {
							this.tokenSubject.next(tokenModel.accessToken);
							return next.handle(this.addTokenToRequest(request, tokenModel.accessToken));
						}

						return this.authService.logout() as any;
					}),
					catchError(err => {
						this.isRefreshingToken = false;
						return this.authService.logout() as any;
					}),
				);
		} else {
			return this.tokenSubject
				.pipe(filter(token => token != null),
					take(1),
					switchMap(token => {
						return next.handle(this.addTokenToRequest(request, token));
					}));
		}
	}
}
