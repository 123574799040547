import { Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingPickupLocationStore } from 'app/admin/stores/tour-booking-pickup-location.store.service';
import { TourBookingPickupLocation } from 'app/models/tour-booking-pickup-location.model';
import * as _ from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TourBookingPickupLocationComponent } from '../tour-booking-pickup-location.component';

@UntilDestroy()
@Injectable()
export class TourBookingPickupLocationService {
	private deleteTourBookingPickupLocationIds: number[] = [];
	private tourBookingPickupLocations: TourBookingPickupLocation[];
	public tourBookingPickupLocationsReplay$ = new ReplaySubject<TourBookingPickupLocation[]>();
	public isSaving$ = new BehaviorSubject<boolean>(false);

	constructor(
		@Inject(MAT_DIALOG_DATA) public tourBookingId: number,
		public tourBookingPickupLocationStore: TourBookingPickupLocationStore,
		public dialogRef: MatDialogRef<TourBookingPickupLocationComponent>,
	) {
		this.tourBookingPickupLocationStore.getAllOnTourBooking$(this.tourBookingId).pipe(untilDestroyed(this)).subscribe(tourBookingAddons => {
			this.tourBookingPickupLocations = tourBookingAddons;
			this.tourBookingPickupLocationsReplay$.next(this.tourBookingPickupLocations);
		});
	}

	public addNew() {
		if (!this.tourBookingPickupLocations) {
			this.tourBookingPickupLocations = [];
		}

		const tourBookingPickupLocation = new TourBookingPickupLocation();
		tourBookingPickupLocation.tourBookingId = this.tourBookingId;
		this.tourBookingPickupLocations.push(tourBookingPickupLocation);
		this.tourBookingPickupLocationsReplay$.next(this.tourBookingPickupLocations);
	}

	public save() {
		this.isSaving$.next(true);
		this.deleteAllPickupLocations()
		.pipe(
			switchMap(() => this.createPickupLocations()),
			untilDestroyed(this),
		)
		.subscribe(() => {
			this.isSaving$.next(false);
			this.dialogRef.close(true);
		}, () => this.isSaving$.next(false));
	}

	public removePickupLocation(tourBookingPickupLocation: TourBookingPickupLocation) {
		const findIndex = _.findIndex(this.tourBookingPickupLocations, tourBookingPickupLocation);
		if (findIndex > -1) {
			this.tourBookingPickupLocations.splice(findIndex, 1);
			if (tourBookingPickupLocation.id) {
				this.deleteTourBookingPickupLocationIds.push(tourBookingPickupLocation.id);
			}
		}
	}

	private deleteAllPickupLocations(): Observable<boolean[]> {
		const observables: Observable<boolean>[] = [of(true)];
		this.deleteTourBookingPickupLocationIds.forEach(deleteTourBookingPickupLocationId => {
			observables.push(this.tourBookingPickupLocationStore.delete(deleteTourBookingPickupLocationId));
		});
		return forkJoin(observables);
	}

	private createPickupLocations(): Observable<TourBookingPickupLocation[]> {
		const observables: Observable<TourBookingPickupLocation>[] = [of(null)];
		this.tourBookingPickupLocations.forEach(tourBookingPickupLocation => {
			if (tourBookingPickupLocation.id) {
				observables.push(this.tourBookingPickupLocationStore.update(tourBookingPickupLocation).pipe(tap(created => tourBookingPickupLocation.id = created.id)));
			} else {
				observables.push(this.tourBookingPickupLocationStore.create(tourBookingPickupLocation).pipe(tap(created => tourBookingPickupLocation.id = created.id)));
			}
		});
		return forkJoin(observables);
	}
}
