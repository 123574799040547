import { Injectable } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';

import { ChartOptions, ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';

import { AnalyticsWidgetTourBookingStore } from '../stores/tour-booking.store';
import { AnalyticsTourBooking } from '../../../models/tour-booking';

@UntilDestroy()
@Injectable()
export class AnalyticsWidgetTourBookingService {
	public lineChartOptions: (ChartOptions & { annotation: any }) = {
		responsive: true,
		scales: {
		  // We use this empty structure as a placeholder for dynamic theming.
		  xAxes: [{}],
		  yAxes: [{}],
		},
		annotation: {
		  annotations: [
			{
			  type: 'line',
			  mode: 'vertical',
			  value: 'March',
			  borderColor: 'orange',
			  borderWidth: 2,
			  label: {
				enabled: true,
				fontColor: 'orange',
				content: 'LineAnno',
			  },
			},
		  ],
		},
	};

	public lineChartLegend = true;
	public lineChartType: ChartType = 'line';
	public lineChartPlugins = [pluginAnnotations];

	public isDone = false;
	public lineChartData: ChartDataSets[] = [];
	public lineChartLabels: Label[] = moment.months();

	constructor(public analyticsWidgetTourBookingStore: AnalyticsWidgetTourBookingStore) {
	}

	public init(mode: number) {
		this.analyticsWidgetTourBookingStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			switch (mode) {
				case 1:
					this.allData(items);
					break;
				case 2:
					this.allCompletedTours(items);
					break;

				default:
					break;
			}
		});
	}

	private allData(items: AnalyticsTourBooking[]) {
		items = _.orderBy(items, 'created', 'asc');
		// Group all order by year
		_.forOwn(_.groupBy(items, booking => moment(booking.created).format('YYYY')), (yearGroup, month) => {
			const yearData = {
				label: month,
				data: [],
			};

			// Loop every month to get each amount
			_.each(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', ], monthNumber => {
				let count = 0;
				_.each(_.filter(yearGroup, order => moment(order.created).format('YYYY-MM') === yearData.label + '-' + monthNumber), tourBooking => {
					count += tourBooking.quantity;
				});
				yearData.data.push(count);
			});
			this.lineChartData.push(yearData);
		});
		this.isDone = true;
	}

	private allCompletedTours(items: AnalyticsTourBooking[]) {
		items = _.filter(items, item => !!item.isCompleted);
		items = _.orderBy(items, 'tourDate', 'asc');
		// Group all order by year
		_.forOwn(_.groupBy(items, booking => moment(booking.tourDate).format('YYYY')), (yearGroup, month) => {
			const yearData = {
				label: month,
				data: [],
			};

			// Loop every month to get each amount
			_.each(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', ], monthNumber => {
				let count = 0;
				_.each(_.filter(yearGroup, order => moment(order.tourDate).format('YYYY-MM') === yearData.label + '-' + monthNumber), tourBooking => {
					count += tourBooking.quantity;
				});
				yearData.data.push(count);
			});
			this.lineChartData.push(yearData);
		});
		this.isDone = true;
	}

	// tslint:disable-next-line:use-lifecycle-interface
	ngOnDestroy(): void { }
}
