import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminTextPipe } from './pipes/admin-text.pipe';
import { AdminDataStore } from './stores/admin-data.store';
import { AdminDataService } from './services/admin-data.service';

@NgModule({
	imports: [
		CommonModule,
  	],
	declarations: [
		AdminTextPipe,
	],
	providers: [
		AdminDataStore,
		AdminDataService,
	],
	exports: [
		AdminTextPipe,
	],
})
export class AdminDataModule { }
