import { Component, OnInit, Inject, Input, ChangeDetectionStrategy } from '@angular/core';
import { proj } from 'openlayers';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { PickupLocation } from 'app/models/page-location.model';
import { HttpClient } from '@angular/common/http';
import { marker } from './marker.image';
import { GeoLocationService } from './geo-location.service';
import { GeoLocationStrore } from 'app/admin/stores/geo-location.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-pickup-location-blade-geo',
	templateUrl: './pickup-location-blade-geo.component.html',
	styleUrls: ['./pickup-location-blade-geo.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default, // To make map work.
	providers: [GeoLocationService],
})
export class PickupLocationBladeGeoComponent implements OnInit {

	@Input() width: string;
	@Input() height: string;

	@Input() latitude = 67.85593996374223;
	@Input() longitude = 20.22664989641446;

	@Input() item: PickupLocation;

	public latitudePointer = 67.85593996374223;
	public longitudePointer = 20.22664989641446;
	public parseInt = parseInt;
	@Input() opacity = 1;
	@Input() zoom = 16;

	public markerImage = marker;

	public currentPosition: any;

	public dirtyPosition: any;

	public adressText: string;
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		private httpClient: HttpClient,
		private geoLocationService: GeoLocationService,
		private geoLocationStrore: GeoLocationStrore,

	) { }

	ngOnInit() {
		if (this.item && this.item.longitude && this.item.latitude) {
			this.longitude = this.longitudePointer = parseFloat(this.item.longitude);
			this.latitude = this.latitudePointer = parseFloat(this.item.latitude);

			this.reverseGeo();
		} else {
			this.geoLocationService.getLocation().pipe(untilDestroyed(this)).subscribe((position) => {
				this.currentPosition = position;
				if (!this.dirtyPosition) {
					this.dirtyPosition = true;
					this.longitude = this.longitudePointer = this.currentPosition.coords.longitude;
					this.latitude = this.latitudePointer = this.currentPosition.coords.latitude;

					this.reverseGeo();
				}
			});
		}
	}

	public onSingleClick(event) {
		const lonlat = proj.transform(event.coordinate, 'EPSG:3857', 'EPSG:4326');
		this.longitudePointer = lonlat[0];
		this.latitudePointer = lonlat[1];
		this.item.longitude = this.longitudePointer.toString();
		this.item.latitude = this.latitudePointer.toString();

		this.reverseGeo();
	}

	private reverseGeo() {
		this.geoLocationStrore.getAddress(this.longitudePointer, this.latitudePointer).pipe(untilDestroyed(this)).subscribe(adressText => {
			this.adressText = adressText;
		});
	}

	public increaseZoom() {
		this.zoom++;
	}
	public decreaseZoom() {
		this.zoom--;
	}

	public setCurrentLocation(event) {
		// TODO FIX: setting current location does move the pointer but not the map!!!
		if (this.currentPosition) {
			this.longitude = this.longitudePointer = this.currentPosition.coords.longitude;
			this.latitude = this.latitudePointer = this.currentPosition.coords.latitude;
			this.item.longitude = this.longitude.toString();
			this.item.latitude = this.latitude.toString();

			/**
			 * Trigger new address change
			 */
			this.reverseGeo();
		}
	}

}
