import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { BaseStore } from 'app/admin/stores/base.store.service';
import { AnalyticsTourBooking } from '../../../models/tour-booking';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AnalyticsWidgetTourBookingStore extends BaseStore<AnalyticsTourBooking> {
	constructor(
		protected http: HttpClient,
		@Inject('BASE_URL') baseUrl: string,
		protected snackBarService: SnackBarService,
	) {
		super(http, 'analytics/tourbooking', baseUrl, snackBarService);
	}

	public getAllOnDate(from: Date, to: Date): Observable<AnalyticsTourBooking[]> {
		return this.http.get<AnalyticsTourBooking[]>(`${this.host}/all/from/${from}/to/${to}`).pipe(tap(columns => {
			this._items = columns;
			this._itemsSubject.next(this._items);
		}));
	}
}
