import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { Subject } from 'rxjs';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { ProfileSubpageViewerElement } from 'app/models/profile-subpage-viewer-element.model';
import { ProfileSubpageViewerElementStore } from 'app/admin/stores/profile-subpage-viewer-element.store.service';
import { ElementStore } from 'app/admin/stores/element.store.service';

@Component({
	selector: 'app-element-profile-subpage-viewer-blade',
	templateUrl: './element-blade-profile-subpage-viewer.blade.html',
	styleUrls: ['./element-blade-profile-subpage-viewer.blade.scss'],
})
@IBladeComponent.register
export class ElementProfileSubpageViewerBlade implements OnInit, OnDestroy {
	public saving: Subject<boolean> = new Subject<boolean>();
	public selectedIndex = 0;
	public loginElement: ProfileSubpageViewerElement;
	public loading = true;

	public menuItems: BladeMenuItem[] = [
		{
			index: 1,
			title: 'Undersida visare',
		}, {
			index: 99,
			title: 'Style',
		},
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public loginElementStore: ProfileSubpageViewerElementStore,
		private elementStore: ElementStore,
	) {
	}

	ngOnInit() {
		this.loginElement = new ProfileSubpageViewerElement();
		this.loginElement.elementId = this.bladeItem.id;
		this.loginElementStore.getOnElement(this.bladeItem.id).subscribe(loginElement => {
			if (loginElement) {
				this.loginElement = loginElement;
			}
			this.loading = false;
		});
	}

	ngOnDestroy() { }

	public save() {
		this.loading = true;
		if (this.selectedIndex === 99) {
			this.saving.next(true);
			return;
		}

		if (this.loginElement.id) {
			this.loginElementStore.update(this.loginElement).subscribe(updatedItem => {
				this.loading = false;
				this.loginElement = _.assign(this.loginElement, updatedItem);
			});
		} else {
			this.loginElementStore.create(this.loginElement).subscribe(updatedItem => {
				this.loading = false;
				this.loginElement = _.assign(this.loginElement, updatedItem);
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.elementStore.delete(this.loginElement.elementId).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
