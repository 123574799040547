
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SupplierResourceDate } from 'app/models/supplier-resource-date.model';
import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class SupplierResourceDateStore extends BaseStore<SupplierResourceDate> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SupplierResourceDate', baseUrl, snackBarService);
	}

	public getAllOnSupplierResource$(supplierResourceId: number): Observable<SupplierResourceDate[]> {
		return this.http.get<SupplierResourceDate[]>(`${this.host}/supplierResource/${supplierResourceId}`).pipe(tap(supplierResources => {
			this._items = supplierResources;
			this._itemsSubject.next(this._items);
		}));
	}
}
