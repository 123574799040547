import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';

import { OrderStatusStore } from '../stores/order-status.store.service';
import { PageTypeStore } from '../stores/page-type.store.service';
import { PageStore } from '../stores/page.store.service';
import { SystemStore } from '../stores/system.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PageTextStore } from '../stores/page-text.store.service';
import { LanguageStore } from '../stores/language.store.service';
import { SiteTemplateStore } from 'app/modules/homepage-wysiwyg/stores/site-template.store';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@UntilDestroy()
@Pipe({ name: 'orderPaymentLink' })
export class OrderPaymentLinkPipe implements PipeTransform, OnDestroy {
	constructor(
		private pageStore: PageStore,
		private pageTextStore: PageTextStore,
		private pageTypeStore: PageTypeStore,
		private systemStore: SystemStore,
		private languageStore: LanguageStore,
		private siteTemplateStore: SiteTemplateStore,
	) { }

	public transform(languageId: number): Observable<string> {
		if (!languageId) {
			return;
			}

		return combineLatest([
			this.pageStore.items$,
			this.pageTextStore.items$,
			this.pageTypeStore.items$,
			this.systemStore.items$,
			this.languageStore.items$,
			this.siteTemplateStore.getAll$(),
		]).pipe(untilDestroyed(this), take(1), switchMap(([pages, pageTexts, pageTypes, system, languages, siteTemplates]) => {
			let link = '';
			const paymentPageType = pageTypes.find(type => type.identifier === 'payment');
			if (paymentPageType) {
				const paymentPage = pages.find(p => p.pageTypeId === paymentPageType.id);
				if (paymentPage) {
					let basePath = system[0].host;
					if (!basePath.endsWith('/')){
						basePath += '/';
					}
					const siteTemplate = siteTemplates.find(x => x.isActive);
					if (siteTemplate) {
						const template = JSON.parse(siteTemplate.template);
						if (template) {
							const defaultLanguage = languages.find(x => x.identifier === template.settings.defaultLanguage);
							const language = languages.find(x => x.id === languageId);

							if (language && defaultLanguage && defaultLanguage.id !== languageId) {
								basePath += language.identifier + '/';
							}
						}
					}
					const paymentPageText = pageTexts.find(x => x.pageId === paymentPage.id && x.languageId === languageId);
					link = basePath + _.kebabCase(paymentPageText?.headline ?? '');
				}
			}
			return of(link);
		}));
	}

	ngOnDestroy(): void { }
}
