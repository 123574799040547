import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { CategoryStore } from 'app/admin/stores/category/category.store.service';
import { Category } from 'app/models/category/category.model';
import { CategoryBladeComponent } from '../category-blade/category-blade.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, FormGroup } from '@angular/forms';

@UntilDestroy()
@Component({
	selector: 'app-categories-blade',
	templateUrl: './categories-blade.component.html',
	styleUrls: ['./categories-blade.component.scss'],
})
@IBladeComponent.register
export class CategoriesBladeComponent implements OnInit, OnDestroy {
	private categories: Category[];

	public displayedColumns: string[] = ['id', 'name', 'created', 'modified', 'isActive'];
	public dataSource: MatTableDataSource<Category>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public itemForm: FormGroup = new FormGroup({
		searchWord: new FormControl(''),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: CategoryStore,
	) {
		this.itemStore.getAll();
		this.itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.categories = items;
			this.setData(this.categories);
		});

		this.itemForm.get('searchWord').valueChanges.pipe(untilDestroyed(this)).subscribe(searchWord => this.search(searchWord));
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: CategoryBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: CategoryBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	private search(searchWord: string) {
		let searchResult = this.categories;
		if (searchWord) {
			searchWord = searchWord.toLowerCase();
			searchResult = this.categories.filter(c =>
				c.name?.toLowerCase()?.includes(searchWord));
		}
		this.setData(searchResult);
	}

	private setData(categories: Category[]) {
		this.dataSource = new MatTableDataSource<Category>(categories);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
}

}
