
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EmailQueue } from '../models/email-queue.model';
import { BaseStore } from 'app/admin/stores/base.store.service';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { Observable } from 'rxjs';

@Injectable()
export class EmailQueueStore extends BaseStore<EmailQueue> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Email', baseUrl, snackBarService);
		this.host = `${baseUrl}api/email/queue`;
	}

	public getAllOnEntity(entityName: string, entityId: number): Observable<EmailQueue[]> {
		return this.http.get<EmailQueue[]>(`${this.host}/entity/${entityName}/entityId/${entityId}`);
	}

	public addDraftOrderQueue(entityId: number) {
		return this.http.put(`${this.host}/DraftOrder/${entityId}`, {});
	}

	public addNewCustomerQueue(entityId: number) {
		return this.http.put(`${this.host}/NewCustomer/${entityId}`, {});
	}

	public addNewOrderQueue(entityId: number) {
		return this.http.put(`${this.host}/NewOrder/${entityId}`, {});
	}

}
