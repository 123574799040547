import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import { TourBookingSpecialDietExtended } from '../../models/tour-booking-special-diet-extended.model';
import { TourBookingSpecialDietService } from '../../services/tour-booking-special-diet.service';
import { TourBookingSpecialDietItemService } from './services/tour-booking-special-diet-item.service';

@UntilDestroy()
@Component({
  	selector: 'app-tour-booking-special-diet-item',
  	templateUrl: './tour-booking-special-diet-item.component.html',
  	styleUrls: ['./tour-booking-special-diet-item.component.scss'],
	providers: [TourBookingSpecialDietItemService],
  	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourBookingSpecialDietItemComponent implements OnInit {
	@Input() index: number;
	@Input() set tourBookingSpecialDietExtendedItem(tourBookingSpecialDietExtendedItem: TourBookingSpecialDietExtended) {
		this.tourBookingSpecialDietItemService.setTourBookingSpecialDietExtendedItem(tourBookingSpecialDietExtendedItem);
	}

  	constructor(
		public tourBookingSpecialDietItemService: TourBookingSpecialDietItemService,
		public tourBookingSpecialDietService: TourBookingSpecialDietService,
	) { }

  	ngOnInit() {
  	}

}
