
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { EtageStyle } from 'app/models/etage-style.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class EtageStyleStore extends BaseStore<EtageStyle> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'EtageStyle', baseUrl, snackBarService);
	}

	public getOnEtage(etageId: number): Observable<EtageStyle> {
		return this.http.get<EtageStyle>(`${this.host}/etage/${etageId}`);
	}
}
