<mat-card *ngIf="{
	loggedInUser: userPageService.loggedInUserReplay$ | async,
	userToken: userService.user$ | async
} as source">
	<mat-card-title>Din roll</mat-card-title>
	<mat-card-subtitle>Du är inloggad med följande roll och tjänster</mat-card-subtitle>
	<p>Roll: <b>{{source.loggedInUser?.userType['name'] || 'Du saknar roll'}}</b></p>
	<div>
		<p>Dina tjänster:</p>
		<p *ngFor="let service of source.loggedInUser?.userServices">
			{{service.service.name}} - {{service.service.description}} Level: {{service.level}}
		</p>
		<p *ngIf="!source.loggedInUser?.userServices?.length">Du saknar tjänster</p>
	</div>
</mat-card>
