import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { Observable, of, combineLatest } from 'rxjs';
import { PickupLocationStore } from '../stores/pickup-location.store.service';
import { ProductPickupLocationStore } from '../stores/product/product-pickup-location.store.service';
import { FormControl, FormGroup } from '@angular/forms';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'pickupLocationRelativeTime' })
export class PickupLocationRelativeTimePipe implements PipeTransform, OnDestroy {
	constructor(
		private pickupLocationStore: PickupLocationStore,
		private productPickupLocationStore: ProductPickupLocationStore,
	) {	}
	public transform(pickupLocationId: number, productId: number, formControl?: FormControl): Observable<number|string> {
		if (!pickupLocationId) {
			return of('Saknar hämtplats');
		}
		return combineLatest([
			this.pickupLocationStore.get(pickupLocationId),
			this.productPickupLocationStore.getAllOnProduct(productId),
		]).pipe(map(([pickupLocation, pickupLocationOnProducts]) => {
			let relativeTimeText = 'Saknas';
			let relativeTime = 0;

			if (pickupLocation.relativeTime !== null) {
				relativeTimeText =  'Org.tid ' + pickupLocation.relativeTime + 'min';
				relativeTime = pickupLocation.relativeTime;
			}

			const find = pickupLocationOnProducts?.find(x => x.pickupLocationId === pickupLocation.id);
			if (find && find.relativeTime !== null) {
				relativeTimeText = 'Org.tid ' + find.relativeTime + 'min';
				relativeTime = find.relativeTime;
			}

			if (formControl) {
				formControl.setValue(relativeTime);
			}

			return relativeTimeText;
		}));
	}

	ngOnDestroy(): void { }
}
