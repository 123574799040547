<mat-card>
	<mat-card-title>Generellt</mat-card-title>

	<mat-card-content>
		<div class="row">
			<div class="col-6">
				<form [formGroup]="itemForm">
					<mat-form-field class="w-100">
						<mat-label>Status</mat-label>
						<mat-select [formControlName]="'bookingStatus'">
							<mat-option *ngFor="let type of (bookingStatusStore.items$|async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</form>
			</div>
			<div class="col-6">
				<b>Källa:</b><br>
				{{item.sourceTypeId | sourceTypeName | async}}
			</div>
			<div class="col-6">
				<b>Skapad:</b><br>
				{{item.created | date:'yyyy-MM-dd HH:mm'}}<br>
				<span *ngIf="item.createdBy > 0"><small>{{item.createdBy | adminUserName | async}}</small></span>
			</div>
			<div class="col-6">
				<b>Ändrad:</b><br>
				{{item.modified | date:'yyyy-MM-dd HH:mm'}}<br>
				<span *ngIf="item.modifiedBy > 0"><small>{{item.modifiedBy | adminUserName | async}}</small></span>
			</div>
			<div class="col-6 mt-3">
				<div *ngIf="item.orderId">
					<button mat-raised-button (click)="openOrder()">Öppna order (ID: {{item.orderId}})</button>
				</div>
				<div *ngIf="!item.orderId">
					Det saknas en order!
				</div>
			</div>
			<div class="col-6 mt-3">
				<div *ngIf="item.tourId">
					<button mat-raised-button (click)="openTour()">Öppna tur (ID: {{item.tourId}})</button>
					<button mat-raised-button (click)="changeTourId = true" class="ml-2">Ändra tur</button>
				</div>
				<!-- <div *ngIf="changeTourId" class="pt-2">
					<form [formGroup]="moveTourForm">
						<mat-form-field class="">
							<input matInput type="number" placeholder="Ange id på den nya turen" [formControlName]="'tourId'">
						</mat-form-field>
						<button mat-raised-button (click)="changeTour()" [disabled]="isChangeTourId" class="ml-2">Spara</button>
					</form>
				</div> -->
			</div>
			<div class="col-12" *ngIf="item.message">
				<mat-card class="mt-3">
					<mat-card-title class="card-title-small">Kundmeddelande</mat-card-title>
					<p [innerHtml]="item.message | newLine"></p>
				</mat-card>
			</div>
		</div>
	</mat-card-content>
</mat-card>
