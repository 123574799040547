<app-blade-item [canIClose]="true" [id]="item?.id">
	<app-blade-header [title]="(item?.firstName || item?.lastName) ? item?.firstName + ' ' + item?.lastName : 'Ny'" [subTitle]="'Kund'"></app-blade-header>
	<app-blade-content *ngIf="item?.id" [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
		<ng-container [ngSwitch]="selectedIndex">
			<ng-container *ngSwitchCase="1">
				<app-customer-generally *ngIf="item" [customer]="item"></app-customer-generally>
			</ng-container>
			<ng-container *ngSwitchCase="2">
				<app-customer-bookings></app-customer-bookings>
			</ng-container>
			<ng-container *ngSwitchCase="3">
				<app-customer-orders></app-customer-orders>
			</ng-container>
			<ng-container *ngSwitchCase="4">
				<app-shared-customer-program [customerId]="item.id"></app-shared-customer-program>
			</ng-container>
		</ng-container>
		</app-blade-content>
	<app-blade-content *ngIf="!item?.id">
		<app-customer-generally *ngIf="item" [customer]="item"></app-customer-generally>
	</app-blade-content>
	<app-blade-footer *ngIf="selectedIndex === 1">
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="!item?.id">Skapa</span>
			<span *ngIf="item?.id">Spara</span>
		</button>
	</app-blade-footer>
</app-blade-item>
