<app-blade-item [canIClose]="true" [loading]="loading" [id]="item?.id">
	<app-blade-header [title]="item?.name||'Ny'" [subTitle]="'Produkt - ' + (item?.productTypeID | productTypeName | async)" [menuItems]="headerMenuItems"></app-blade-header>
	<!-- Edit Product content -->
	<app-blade-content *ngIf="item?.id" (indexChange)="selectedIndex = $event">
		<app-blade-content-tabs>
			<app-blade-content-tab [title]="'Generellt'" [template]="generally">
				<ng-template #generally>
					<app-product-blade-generally [product]="item"></app-product-blade-generally>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Texter'" [template]="texts" *ngIf="item?.productTypeID !== 4">
				<ng-template #texts>
					<app-product-blade-texts></app-product-blade-texts>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Bilder'" [template]="images" *ngIf="item?.productTypeID !== 4">
				<ng-template #images>
					<app-product-blade-images></app-product-blade-images>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Datum och tider'" [template]="dates" *ngIf="item?.productTypeID !== 4">
				<ng-template #dates>
					<app-product-blade-dates></app-product-blade-dates>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Priser'" [template]="prices" *ngIf="item?.productTypeID !== 4">
				<ng-template #prices>
					<app-product-blade-prices></app-product-blade-prices>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Upphämtningsplatser'" [template]="pickupLocationa" *ngIf="item?.productTypeID !== 4">
				<ng-template #pickupLocationa>
					<app-product-blade-pickup-locations></app-product-blade-pickup-locations>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Tillägg'" [template]="addons" *ngIf="item?.productTypeID !== 4">
				<ng-template #addons>
					<app-product-blade-addons></app-product-blade-addons>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Kategorier'" [template]="categories" *ngIf="item?.productTypeID !== 4">
				<ng-template #categories>
					<app-product-blade-category [save]="saveCategorySubject"></app-product-blade-category>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Inställningar'" [template]="settings">
				<ng-template #settings>
					<app-product-blade-settings></app-product-blade-settings>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Sirvoy'" [template]="sirvoy" *ngIf="item?.productTypeID === 3">
				<ng-template #sirvoy>
					<app-product-blade-sirvoy [save]="saveSirvoySubject"></app-product-blade-sirvoy>
				</ng-template>
			</app-blade-content-tab>
			
			<app-blade-content-tab class="border-top" [title]="'Turer'" [template]="tours">
				<ng-template #tours>
					<app-product-blade-tours></app-product-blade-tours>
				</ng-template>
			</app-blade-content-tab>
		</app-blade-content-tabs>
	</app-blade-content>
	<!-- New product content -->
	<app-blade-content *ngIf="!item?.id">
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="name" placeholder="Arbetsnamn">
			</mat-form-field>
			<mat-form-field class="w-100">
				<mat-label>Välj produkt typ</mat-label>
				<mat-select formControlName="type">
					<mat-option *ngFor="let type of (productTypeStore.items$|async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field class="w-100">
				<mat-label>Välj leverantör</mat-label>
				<mat-select formControlName="supplierId">
					<mat-option *ngFor="let type of (supplierStore.items$|async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-select>
		</mat-form-field>
		</form>
	</app-blade-content>
		<!-- Default -->
		<ng-container *ngIf="!bladeItem.menuIndex">
			<app-blade-footer>
				<span fxFlex></span>
				<button *ngIf="!item?.id" mat-raised-button color="primary" (click)="save()" [disabled]="!itemForm.valid">
					Skapa
				</button>
				<button *ngIf="item?.id" mat-raised-button color="primary" (click)="save()">
					Spara
				</button>
			</app-blade-footer>
		</ng-container>
		<ng-container *ngIf="bladeItem.menuIndex === 5 || bladeItem.menuIndex === 7 || bladeItem.menuIndex === 9">
			<app-blade-footer>
				<span fxFlex></span>
				<button mat-raised-button color="primary" (click)="save()">
					Spara
				</button>
			</app-blade-footer>
		</ng-container>
</app-blade-item>
