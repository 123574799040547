import { Inject, Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingSpecialDietMessageStore } from 'app/admin/stores/tour-booking-special-diet-message.store.service';
import { TourBookingSpecialDietMessage } from 'app/models/tour-booking-special-diet-message.model';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { TourBookingSpecialDietService } from '../../../services/tour-booking-special-diet.service';

@UntilDestroy()
@Injectable()
export class TourBookingSpecialDietMessageService {
	public specialDietMessagesReplay$ = new Observable<TourBookingSpecialDietMessage[]>();

	public specialDietMessageForm: FormGroup = new FormGroup({
		id: new FormControl(null),
		message: new FormControl(null),
		tourBookingId: new FormControl(null),
	});


  	constructor(
		public tourBookingSpecialDietService: TourBookingSpecialDietService,
		private tourBookingSpecialDietMessageStore: TourBookingSpecialDietMessageStore,
		@Inject(MAT_DIALOG_DATA) public tourBookingId: number,
	) {
		this.tourBookingSpecialDietMessageStore.getAllOnTourBooking$(this.tourBookingId).pipe(untilDestroyed(this)).subscribe(specialDietMessages => {
			if (specialDietMessages?.length) {
				this.specialDietMessageForm.patchValue(specialDietMessages[0]);
			}
		});
	}

	public save() {
		const specialDietMessage = this.specialDietMessageForm.value;
		if (specialDietMessage.id) {
			this.tourBookingSpecialDietMessageStore.update(specialDietMessage).subscribe();
		} else {
			specialDietMessage.tourBookingId = this.tourBookingId;
			this.tourBookingSpecialDietMessageStore.create(specialDietMessage).subscribe(newSpecialDietMessage => {
				this.specialDietMessageForm.patchValue(newSpecialDietMessage);
			});
		}
	}
}
