<ng-container *ngIf="{
	adminText: 'widget.draftOrders' | adminText | async
} as source">
	<table mat-table [dataSource]="dataSource" matSort class="w-100">
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
			<td mat-cell *matCellDef="let item">{{item.id}} </td>
		</ng-container>
		<ng-container matColumnDef="customer">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.customer}}</th>
			<td mat-cell *matCellDef="let item">{{item.customerId | customerName | async}}</td>
		</ng-container>
		<ng-container matColumnDef="payment">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.restAmount}}</th>
			<td mat-cell *matCellDef="let item">
				<span *ngIf="{amount: item.paymentBalance?.totalOrder - item.paymentBalance?.totalPayment} as total"
					[class.text-success]="!total.amount"
					[class.text-danger]="total.amount > 0"
					[class.text-warning]="total.amount < 0">
					{{total.amount | currency:'SEK':'code'}}
				</span>
			</td>
		</ng-container>
		<ng-container matColumnDef="source">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.source}}</th>
			<td mat-cell *matCellDef="let item">{{item.sourceTypeId | sourceTypeName | async}}</td>
		</ng-container>
		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.created}}</th>
			<td mat-cell *matCellDef="let item">{{item.created|date:'yyyy-MM-dd HH:mm'}}</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)"></tr>
	</table>
</ng-container>
