import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { EtageBackgroundStore } from 'app/admin/stores/etage-background.store.service';
import { EtageBackground } from '../../../../../../models/etage-background.model';
import { FormControl, FormGroup } from '@angular/forms';
import { MediaBladeComponent } from 'app/admin/blades/media/media-blade/media-blade.component';
import { Media } from 'app/models/media.model';

@UntilDestroy()
@Component({
	selector: 'app-etage-blade-background',
	templateUrl: './etage-blade-background.component.html',
	styleUrls: ['./etage-blade-background.component.scss'],
})
export class EtageBladeBackgroundComponent implements OnInit, OnDestroy {
	private etageBackground: EtageBackground;
	public etageBackground$ = new ReplaySubject<EtageBackground>();
	public formGroup: FormGroup = new FormGroup({
		isFixed: new FormControl(false, []),
		theme: new FormControl('light', []),
		padding: new FormControl('medium', []),
	});


	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public etageBackgroundStore: EtageBackgroundStore,
	) {
		this.bladeItem.callback = (items, payload) => { this.useFiles(items); };
	}

	ngOnInit() {
		this.etageBackgroundStore.reset();
		this.etageBackgroundStore.getAllOnEtage(this.bladeItem.id).subscribe();
		this.etageBackgroundStore.items$
			.pipe(untilDestroyed(this))
			.subscribe(etageBackgrounds => {
				if (etageBackgrounds?.length) {
					this.etageBackground = etageBackgrounds[0];
					this.formGroup.patchValue(JSON.parse(this.etageBackground.data || '{}'));
				} else {
					this.etageBackground = new EtageBackground();
					this.etageBackground.etageId = this.bladeItem.id;
				}
				this.etageBackground$.next(this.etageBackground);
			});
	}

	ngOnDestroy(): void { }

	public selectImage() {
		this.bladeService.addBladeItem(
			{
				id: 0,
				component: MediaBladeComponent,
				minClass: BladeSize.m,
				payload: {
					mediaTypes: 'image',
				},
			},
			this.bladeItem,
		);
	}


	public save() {
		this.etageBackground.data = JSON.stringify(this.formGroup.value);
		if (this.etageBackground.id) {
			this.etageBackgroundStore.update(this.etageBackground).subscribe(etageBackground => {
				Object.assign(this.etageBackground, etageBackground);
				this.etageBackground$.next(this.etageBackground);
			});
		} else {
			this.etageBackgroundStore.create(this.etageBackground).subscribe(etageBackground => {
				Object.assign(this.etageBackground, etageBackground);
				this.etageBackground$.next(this.etageBackground);
			});
		}


	}

	public delete() {
		if (this.etageBackground.id) {
			this.etageBackgroundStore.delete(this.etageBackground.id).subscribe(isRemoved => {
				if (isRemoved) {
					this.etageBackground = new EtageBackground();
					this.etageBackground.etageId = this.bladeItem.id;
					this.etageBackground$.next(this.etageBackground);
				}
			});
		}
	}
	private useFiles(items: Media[]) {
		this.etageBackground.mediaId = items[0].id;
		this.etageBackground$.next(this.etageBackground);
	}
}

interface EtageBackgroundData {
	isFixed: string;
	theme: string;
	padding: string;
}
