import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import * as _ from 'lodash';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, FormControl } from '@angular/forms';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { CategoryListElementStore } from 'app/admin/stores/category-list-element.store.service';
import { CategoryListElement } from 'app/models/category-list-element.model';
import { CategoryStore } from 'app/admin/stores/category/category.store.service';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { ElementStore } from 'app/admin/stores/element.store.service';
import { Element } from 'app/models/element.model';

@UntilDestroy()
@Component({
	selector: 'app-element-category-list-blade',
	templateUrl: './element-category-list.blade.html',
	styleUrls: ['./element-category-list.blade.scss'],
})
@IBladeComponent.register
export class ElementCategoryListBlade implements OnInit, OnDestroy {
	public saving: Subject<boolean> = new Subject<boolean>();
	public selectedIndex = 0;
	public loading = true;
	public element: Element;

	public menuItems: BladeMenuItem[] = [
		{
			index: 0,
			title: 'Produktlista',
		}, {
			index: 99,
			title: 'Style',
		},
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	public categoryListElement: CategoryListElement;
	public itemForm: FormGroup = new FormGroup({
		category: new FormControl(0),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private categoryListElementStore: CategoryListElementStore,
		public categoryStore: CategoryStore,
		private elementStore: ElementStore,
	) {
		this.categoryStore.getAll();
	}

	ngOnInit() {
		this.categoryListElementStore.getOnElement(this.bladeItem.id).subscribe(productElement => {
			this.loading = false;
			this.categoryListElement = productElement;

			if (productElement) {
				this.itemForm.get('category').setValue(this.categoryListElement.categoryId);
			} else {
				this.categoryListElement = new CategoryListElement();
				this.categoryListElement.elementId = this.bladeItem.id;
			}

			this.itemForm.get('category').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
				this.categoryListElement.categoryId = value;
			});
		});

		this.elementStore.get(this.bladeItem.id).subscribe(element => {
			this.element = element;
			if (this.element.onProduct || this.element.onCategory) {
				this.itemForm.disable();
			}
		});
	}

	ngOnDestroy() { }

	public save() {
		this.saving.next(true);

		this.loading = true;

		if (this.categoryListElement.id) {
			this.categoryListElementStore.update(this.categoryListElement).subscribe(updatedItem => {
				this.loading = false;
				this.categoryListElement = _.assign(this.categoryListElement, updatedItem);
			});
		} else {
			this.categoryListElementStore.create(this.categoryListElement).subscribe(updatedItem => {
				this.loading = false;
				this.categoryListElement = _.assign(this.categoryListElement, updatedItem);
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.elementStore.delete(this.categoryListElement.elementId).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
