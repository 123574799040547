import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SourceTypeStore } from '../stores/source-type.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'sourceTypeName' })
export class SourceTypeNamePipe implements PipeTransform, OnDestroy {
	constructor(private sourceTypeStore: SourceTypeStore) { }

	public transform(sourceTypeId: number): Observable<string> {
		if (!sourceTypeId) {
			return;
		}
		return this.sourceTypeStore.pipeGet(sourceTypeId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
