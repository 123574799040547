<mat-card>
	<mat-card-title>Etage</mat-card-title>
	<mat-card-subtitle>Dra ett etage för att flytta den och ändra placeringen av den.</mat-card-subtitle>

	<div class="mat-elevation-z1">
		<mat-table [dataSource]="dataSource" class="w-100"
			cdkDropList
			(cdkDropListDropped)="onListDrop($event)"
			[cdkDropListData]="dataSource">

			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef>Namn</mat-header-cell>
				<mat-cell *matCellDef="let item"><strong>{{item.name}}</strong></mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row cdkDrag [cdkDragData]="item" [cdkDragDisabled]="savingPrioReplay$ | async" *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.id == item?.id"></mat-row>
		</mat-table>

		<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	</div>
	<div class="mt-2 text-right">
		<button mat-raised-button color="accent" *ngIf="prioIsChangedReplay$ | async" (click)="savePrio()" [disabled]="savingPrioReplay$ | async">Spara sorteringen</button>
	</div>
</mat-card>
