import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { UserBladeComponent } from '../user-blade/user-blade.component';
import { UserStore } from 'app/admin/stores/user.store.service';
import { User } from 'app/admin/models/user.model';

@UntilDestroy()
@Component({
	selector: 'app-users-blade',
	templateUrl: './users-blade.component.html',
	styleUrls: ['./users-blade.component.scss'],
})
@IBladeComponent.register
export class UsersBladeComponent implements OnInit, OnDestroy {
	private allUsers: User[];

	public displayedColumns: string[] = ['id', 'name', 'email', 'phone'];
	public dataSource: MatTableDataSource<User>;

	public itemForm: FormGroup = new FormGroup({
		searchWord: new FormControl(''),
	});

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: UserStore,
	) {
		this.itemStore.getAll();
		this.itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.allUsers = items;
			this.setDataSource(this.allUsers);
		});

		this.itemForm.get('searchWord').valueChanges.pipe(untilDestroyed(this)).subscribe(searchWord => this.search(searchWord));
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: UserBladeComponent,
			minClass: BladeSize.s,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: UserBladeComponent,
			minClass: BladeSize.s,
		}, this.bladeItem);
	}

	private search(searchWord: string) {
		let searchResult = this.allUsers;
		if (searchWord) {
			searchWord = searchWord.toLowerCase();
			searchResult = this.allUsers.filter(c =>
				c.firstName?.toLowerCase()?.includes(searchWord) ||
				c.lastName?.toLowerCase()?.includes(searchWord) ||
				c.email?.toLowerCase()?.includes(searchWord) ||
				c.phone?.toLowerCase()?.includes(searchWord) ||
				c.id?.toString().includes(searchWord));
		}
		this.setDataSource(searchResult);
	}

	private setDataSource(items: User[]) {
		this.dataSource = new MatTableDataSource<User>(items);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
}
}
