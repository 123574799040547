import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Tour } from 'app/models/tour.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourMessageStore } from 'app/admin/stores/tour-message.store.service';

@Component({
	selector: 'app-tour-blade',
	templateUrl: './tour-blade.component.html',
	styleUrls: ['./tour-blade.component.scss'],
})
@IBladeComponent.register
export class TourBladeComponent implements OnInit {
	public item: Tour;
	public loading = true;
	public canIClose: () => {} | boolean;
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	public messageForm: FormGroup = new FormGroup({
		message: new FormControl(''),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: TourStore,
		private bladePositionService: BladePositionService,
		private bladeService: BladeService,
		private tourMessageStore: TourMessageStore,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new Tour();
			this.item.supplierId = 0;
		}
	}

	ngOnInit() {}

	public save() {
		this.loading = true;
		if (this.item.id) {
			this.itemStore.update(this.item).subscribe(product => {
				this.loading = false;
				this.item = _.assign(this.item, product);
			});
		} else {
			this.itemStore.create(this.item).subscribe(tour => {
				this.loading = false;
				if (this.messageForm.value.message) {
					this.tourMessageStore.create({
						message: this.messageForm.value.message,
						tourId: tour.id,
					}).subscribe(newMessage => {
						this.bladeService.addBladeItem({
							id: tour.id,
							component: TourBladeComponent,
							minClass: BladeSize.m,
						}, this.bladeItem.parent);
					});
				} else {
					this.bladeService.addBladeItem({
						id: tour.id,
						component: TourBladeComponent,
						minClass: BladeSize.m,
					}, this.bladeItem.parent);
				}
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
