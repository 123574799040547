import { Component, OnInit, Inject, Input, OnDestroy, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';
import { Guid } from 'guid-typescript';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SupplierResourceTourStore } from 'app/admin/stores/supplier-resource-tour.store.service';
import { SupplierResourceTour } from 'app/models/supplier-resource-tour.model';
import { SupplierResourceTypeStore } from 'app/admin/stores/supplier-resource-type.store.service';
import { Tour } from 'app/models/tour.model';
import { SupplierResourceProductStore } from 'app/admin/stores/supplier-resource-product.store.service';
import { ReplaySubject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import * as _ from 'lodash';
import { SupplierResourceService } from 'app/admin/services/supplier-resource.service';
import { ResourceSummaryItem } from 'app/admin/models/resource-summary-item';
import { TourResourceService } from './tour-resource.service';

@UntilDestroy()
@Component({
	selector: 'app-tour-resource',
	templateUrl: './tour-resource.component.html',
	styleUrls: ['./tour-resource.component.scss'],
})
export class TourResourceComponent implements OnInit, OnDestroy {
	private id: string;
	private theTour: Tour;
	public calculatedResourceItems$ = new ReplaySubject<ResourceSummaryItem[]>();
	public calculatedResourceSummary$ = new ReplaySubject<ResourceSummaryItem[]>();

	@Input() set tour(tour: Tour) {
		this.theTour = tour;
		// TODO! Move this to a common service?
		if (this.theTour.productId) {
			this.calculateResourceOnTour(this.theTour);
		}
	}

	public displayedColumns: string[] = ['resource', 'amount'];
	public displayedColumnsResourceSummary: string[] = ['resource', 'amount', 'handled', 'total'];

	public resourceForm: FormGroup = new FormGroup({
		id: new FormControl(null),
		supplierResourceTypeId: new FormControl('', [Validators.required]),
		amount: new FormControl(null, [Validators.required, Validators.min(1)]),
	});


	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public supplierResourceTypeStore: SupplierResourceTypeStore,
		public supplierResourceTourStore: SupplierResourceTourStore,
		public supplierResourceProductStore: SupplierResourceProductStore,
		public tourBookingStore: TourBookingStore,
		public supplierResourceService: SupplierResourceService,
		@Optional() private tourResourceService: TourResourceService,
	) {
		this.id = Guid.create().toString();
		this.supplierResourceTourStore.reset();
		this.supplierResourceTourStore.getAllOnTour(this.bladeItem.id);
	}

	ngOnInit(): void { }

	ngOnDestroy(): void { }


	public resetTourResource() {
		this.resourceForm.reset();
	}

	public removeTourResource(supplierResourceTourId: number) {
		if (supplierResourceTourId) {
			this.supplierResourceTourStore.delete(supplierResourceTourId).subscribe(item => {
				this.calculateResourceOnTour(this.theTour);
				this.resourceForm.reset();
			});
		}
	}

	public addTourResource() {
		const supplierResourceTour = new SupplierResourceTour();
		supplierResourceTour.tourId = this.bladeItem.id;
		supplierResourceTour.id = this.resourceForm.get('id').value;
		supplierResourceTour.supplierResourceTypeId = this.resourceForm.get('supplierResourceTypeId').value;
		supplierResourceTour.amount = this.resourceForm.get('amount').value;

		if (supplierResourceTour.id) {
			this.supplierResourceTourStore.update(supplierResourceTour).subscribe(item => {
				this.calculateResourceOnTour(this.theTour);
				this.resourceForm.reset();
			});
		} else {
			this.supplierResourceTourStore.create(supplierResourceTour).subscribe(item => {
				this.calculateResourceOnTour(this.theTour);
				this.resourceForm.reset();
			});
		}
	}

	public selectTourResource(supplierResourceTour: SupplierResourceTour) {
		this.resourceForm.get('id').setValue(supplierResourceTour.id);
		this.resourceForm.get('supplierResourceTypeId').setValue(supplierResourceTour.supplierResourceTypeId);
		this.resourceForm.get('amount').setValue(supplierResourceTour.amount);
	}

	private calculateResourceOnTour(tour: Tour): void {
		this.supplierResourceService.calculateResourceOnTour(tour).subscribe(calculatedResources => {
			this.calculatedResourceItems$.next(calculatedResources.items);
			this.calculatedResourceSummary$.next(calculatedResources.summary);

			if (this.tourResourceService) {
				this.tourResourceService.addResourceSummary(this.id, calculatedResources.summary);
			}
		});
	}
}
