import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import { ReplaySubject } from 'rxjs';
import { Dictionary } from 'lodash';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingAddonStore } from 'app/admin/stores/tour-booking-addon.store.service';
import { TourBookingAddon } from 'app/models/tour-booking-addon.model';
import { TourBookingAddonBladeComponent } from 'app/admin/blades/tour-booking-addon/tour-booking-addon-blade/tour-booking-addon-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';

@UntilDestroy()
@Component({
	selector: 'app-tour-booking-blade-bookings-addon',
	templateUrl: './tour-booking-blade-bookings-addon.component.html',
	styleUrls: ['./tour-booking-blade-bookings-addon.component.scss'],
})
export class TourBookingBladeBookingsAddonComponent implements OnDestroy {
	@ViewChild(MatAccordion) accordion: MatAccordion;
	public displayedColumns: string[] = ['id', 'quantity', 'created'];
	public tourBookingAddonsReplay$ = new ReplaySubject<Dictionary<TourBookingAddon[]>>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private tourBookingCustomerTypeStore: TourBookingAddonStore,
		private bladeService: BladeService,
	) {
		this.tourBookingCustomerTypeStore.getAllOnTourBooking(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(tourBookingCustomerTypes => {
			this.tourBookingAddonsReplay$.next(_.groupBy(tourBookingCustomerTypes, type => type.addonId));
		});
	}

	ngOnDestroy() { }

	public selectItem(row: TourBookingAddon) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: TourBookingAddonBladeComponent,
			minClass: BladeSize.m,
		}, this.bladeItem);
	}

}
