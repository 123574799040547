import { BaseModel } from './base.model';
import { PaymentBalance } from './paymanet-balance.model';
import { OrderPayment } from './order-payment.model';

export class Order extends BaseModel {
	public customerId: number;
	public hashId: string;
	public amount: number;
	public sourceTypeId: number;
	public orderStatusId: number;
	public name: string;
	public message: string;
	public isDraft: boolean;
	public reference: string;
	public paymentBalance: PaymentBalance;
	public orderPayments: OrderPayment[];
}
