<mat-card *ngIf="{
	summaries: summaries$ | async,
	tourBookingSpecialDiets: specialDietsReplay$ | async,
	tourBookingSpecialDietMessages: tourBookingSpecialDietMessageStore.items$ | async,
	adminText: 'tourCustomerProgram.participants' | adminText: language?.prefix | async
} as source">
	<mat-card-title class="d-flex justify-content-between">
		<span>{{tourBooking.customerId | customerName | async}}</span>
		<span *ngIf="tourBooking.bookingStatusId === 3">{{source.adminText?.statusMapping[tourBooking.bookingStatusId | bookingStatusName | async]}}</span>
	</mat-card-title>
	<mat-card-subtitle>
		<p class="m-0">{{(tourBooking.customerId | customer | async)?.phone}}</p>
		<p class="m-0" *ngIf="tourBooking.travelAgencyId">{{source.adminText?.travelAgancy}} {{tourBooking.travelAgencyId | travelAgencyName | async}}</p>
	</mat-card-subtitle>
	<div class="mb-3">
		<div *ngFor="let summary of source.summaries">
			<div>
				<b>{{summary.customerTypeId | customerTypeName: language?.id | async}} {{summary.quantity}}{{source.adminText?.pickupPct}}</b>
				{{source.adminText?.pickup}} <b>{{summary.pickupLocationId | pickupLocationName: language?.id | async}}</b>
				{{source.adminText?.pickupTime}} <b>{{summary.relativeTime}}</b>
			</div>
			<div *ngIf="summary.ages?.length">
				<strong class="mx-2">{{source.adminText?.age}}</strong>
				<ng-container *ngFor="let age of summary.ages; let i = index">
					<span>{{age.birthDate | date:'yyyy-MM-dd'}} ({{moment().diff(age.birthDate, 'years', false)}}{{source.adminText?.year}})</span><span class="mr-1" *ngIf="i+1 < summary.ages?.length">, </span>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="mb-3" *ngIf="tourBooking | paymentBalance | async as payment">
		<p>
			{{source.adminText?.balance?.title}}
			<span *ngIf="payment.balance > 0">{{source.adminText?.balance?.pay}}</span>
			<span *ngIf="payment.balance < 0">{{source.adminText?.balance?.receive}}</span>
			<b>
				<span
					[class.text-success]="!payment.balance"
					[class.text-danger]="payment.balance > 0"
					[class.text-warning]="payment.balance < 0">
					{{payment.balanceStr}}
				</span>
			</b>
		</p>
	</div>

	<div class="mb-3">
		<mat-card-subtitle class="m-0">
			<span *ngIf="tourBooking.message">{{source.adminText?.messages}}</span>
			<span *ngIf="!tourBooking.message">{{source.adminText?.noMessages}}</span>
		</mat-card-subtitle>
		<mat-card class="mb-3" *ngIf="tourBooking.message">
			<span [innerHtml]="tourBooking.message | newLine"></span>
		</mat-card>
	</div>

	<div class="mb-3">
		<mat-card-subtitle class="m-0" *ngIf="source.tourBookingSpecialDiets?.length as hasSpecialDiets">
			<span *ngIf="hasSpecialDiets">{{source.adminText?.specialDiet?.title}}</span>
			<span *ngIf="!hasSpecialDiets">{{source.adminText?.specialDiet?.noSpecialDiet}}</span>
		</mat-card-subtitle>

		<mat-card *ngFor="let specialDiet of source.tourBookingSpecialDiets; let i = index" class="m-0 ml-3 mb-3">
			<mat-card-subtitle>{{specialDiet.customerTypeId | customerTypeName | async}} {{i+1}}</mat-card-subtitle>
			<div *ngFor="let tourBookingSpecialDiet of specialDiet.tourBookingSpecialDiets" class="p-1" [class.border]="tourBookingSpecialDiet.extraInfo">
				<p class="m-0">{{tourBookingSpecialDiet.specialDietId | specialDietName | async}}</p>
				<p class="ml-2 m-0" *ngIf="tourBookingSpecialDiet.extraInfo" [innerHtml]="tourBookingSpecialDiet.extraInfo | newLine"></p>
			</div>
		</mat-card>
	</div>
	<div class="mb-3">
		<mat-card-subtitle class="m-0" *ngIf="source.tourBookingSpecialDietMessages?.length as hasSpecialDiets">
			<span *ngIf="hasSpecialDiets">{{source.adminText?.specialDiet?.messages}}</span>
			<span *ngIf="!hasSpecialDiets">{{source.adminText?.specialDiet?.noMessages}}</span>
		</mat-card-subtitle>
		<p *ngFor="let message of source.tourBookingSpecialDietMessages" class="m-0 ml-2" [innerHtml]="message.message | newLine"></p>
	</div>
</mat-card>

