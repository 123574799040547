import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ElementType } from 'app/models/element-type.model';
import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ElementTypeStore extends BaseStore<ElementType> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ElementTypes', baseUrl, snackBarService);
	}
}
