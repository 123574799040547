import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class SnackBarService {
	private duration = 3000;

	constructor(private snackBar: MatSnackBar) {
	}

	public showSuccess(message: string) {
		return this.snackBar.open(message, 'OK', {
			duration: this.duration,
		});
	}

	public showError(message: string) {
		return this.snackBar.open(message, 'Error', {
			duration: this.duration,
		});
	}
}
