<mat-card *ngIf="etageBackground$ | async as etageBackground">
	<mat-card-title>Bakgrund</mat-card-title>
	<!-- <mat-card-subtitle>Dra en kolumn för att flytta den och ändra placeringen av den.</mat-card-subtitle> -->

	<mat-card [formGroup]="formGroup">
		<img mat-card-image [src]="etageBackground.mediaId  | mediaPath | async">
		<mat-card-content>
			<mat-slide-toggle formControlName="isFixed">Fixerad</mat-slide-toggle>
		</mat-card-content>
		<mat-form-field appearance="outline">
			<mat-label>Tema</mat-label>
			<mat-select formControlName="theme">
				<mat-option value="dark">Mörk</mat-option>
				<mat-option value="light">Ljus</mat-option>
			</mat-select>
		</mat-form-field>		
		<mat-form-field appearance="outline">
			<mat-label>Padding</mat-label>
			<mat-select formControlName="padding">
				<mat-option value="small">Liten</mat-option>
				<mat-option value="medium">Medium</mat-option>
				<mat-option value="large">Stor</mat-option>
			</mat-select>
		</mat-form-field>
	</mat-card>

	<div class="mt-2 d-flex justify-content-between">
		<button mat-raised-button color="warn" (click)="delete()">Radera</button>
		<button mat-raised-button (click)="selectImage()">Välj bild</button>
		<button mat-raised-button color="accent" (click)="save()">Spara</button>
	</div>
</mat-card>
