import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { Supplier } from 'app/models/supplier.model';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SupplierResourceStore } from 'app/admin/stores/supplier-resource.store.service';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { combineLatest, ReplaySubject } from 'rxjs';
import * as moment from 'moment';
import { PlannerResourceGroup } from './models/planner-resource-group';
import { PlannerResourceDateGroup } from './models/planner-resource-date-group';
import * as _ from 'lodash';

@UntilDestroy()
@Component({
	selector: 'app-planner-resource-blade',
	templateUrl: './planner-resource-blade.component.html',
	styleUrls: ['./planner-resource-blade.component.scss'],
})
@IBladeComponent.register
export class PlannerResourceBladeComponent implements OnInit, OnDestroy {

	private plannerResourceGroups: PlannerResourceGroup[] = [];
	private plannerResourceDateGroups: PlannerResourceDateGroup[] = [];
	public plannerResourceDateGroupsReplay$ = new ReplaySubject<PlannerResourceDateGroup[]>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private  supplierResourceStore: SupplierResourceStore,
		private  supplierStore: SupplierStore,
		private tourStore: TourStore,
	) {
		combineLatest([
			this.supplierStore.getAll$(),
			this.supplierResourceStore.getAll$(),
			this.tourStore.getAllBetweenDates(moment().format('YYYY-MM-DD'), moment().add(3, 'days').format('YYYY-MM-DD')),
		])
		.pipe(untilDestroyed(this)).subscribe(([suppliers, supplierResources, tours]) => {
			this.plannerResourceDateGroups = [];
			this.plannerResourceGroups = [];
			tours.forEach(tour => {
				const supplier = suppliers.find(s => s.id === tour.supplierId);
				if (supplier) {
					const supplierResourceList = supplierResources.filter(sr => sr.supplierId === supplier.id);
					const plannerResourceGroup = this.plannerResourceGroups.find(group =>
						group.supplier.id === supplier.id &&
						moment(group.date).format('YYYY-MM-DD') === moment(tour.date).format('YYYY-MM-DD'));
					if (plannerResourceGroup) {
						if (!plannerResourceGroup.tours) {
							plannerResourceGroup.tours = [];
						}
						plannerResourceGroup.tours.push(tour);
					} else {
						this.plannerResourceGroups.push({
							supplier,
							supplierResources: supplierResourceList,
							tours: [tour],
							date: tour.date,
						});
					}
				}
			});

			this.plannerResourceGroups = _.orderBy(this.plannerResourceGroups, group => group.date, 'desc');

			const dateGroup = _.groupBy(this.plannerResourceGroups, group => group.date);
			_.forOwn(dateGroup, (value, key) => {
				this.plannerResourceDateGroups.push({
					date: new Date(key),
					plannerResourceGroups: value,
				});
			});

			this.plannerResourceDateGroupsReplay$.next(this.plannerResourceDateGroups);
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		// this.bladeService.addBladeItem({
		// 	id: row.id,
		// 	component: SupplierBladeComponent,
		// 	minClass: BladeSize.m,
		// }, this.bladeItem);
	}

	public createNew() {
		// this.bladeService.addBladeItem({
		// 	id: 0,
		// 	component: SupplierBladeComponent,
		// 	minClass: BladeSize.xs,
		// }, this.bladeItem);
	}
}
