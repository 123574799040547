<ng-container *ngIf="tourBookingPickupLocationService.tourBookingPickupLocationsReplay$ | async as pickupLocations">
	<h2 mat-dialog-title>Redigera upphämtningsplats på tourBooking</h2>
	<mat-dialog-content>
		<p *ngIf="!pickupLocations?.length">Det finns inga upphämtningsplatser.</p>
		<app-tour-booking-pickup-location-item class="d-block mt-3" *ngFor="let item of pickupLocations" [tourBookingPickupLocation]="item"></app-tour-booking-pickup-location-item>

		<div class="text-right mt-3">
			<button mat-stroked-button color="accent" (click)="tourBookingPickupLocationService.addNew()">Lägg till nytt upphämtning</button>
		</div>
		<div class="text-right mt-3 pt-3 border-top">
			<button mat-raised-button color="primary" [disabled]="tourBookingPickupLocationService.isSaving$ | async" (click)="tourBookingPickupLocationService.save()">Spara</button>
		</div>
	</mat-dialog-content>
</ng-container>
