import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { Page } from 'app/models/site/page.model';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { PageStore } from 'app/admin/stores/page.store.service';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { PageTypeStore } from 'app/admin/stores/page-type.store.service';
import { EtageBladeComponent } from '../../etage/etage-blade/etage-blade.component';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { PageBladeService } from './services/page-blade.service';

@Component({
	selector: 'app-page-blade',
	templateUrl: './page-blade.component.html',
	styleUrls: ['./page-blade.component.scss'],
	providers: [PageBladeService],
})
@IBladeComponent.register
export class PageBladeComponent implements OnInit {

	public selectedIndex = 1;
	public menuItems: BladeMenuItem[] = [
		{ title: 'Generellt', index: 1 },
		{ title: 'Namn', index: 2 },
		{ title: 'Etage', index: 3 },
		// { title: 'Statistik', index: 4 },
		{ title: 'Style', index: 5 },
	];

	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	public loading = true;
	public item: Page;


	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: PageStore,
		private bladePositionService: BladePositionService,
		public pageTypeStore: PageTypeStore,
		private bladeService: BladeService,
		public pageBladeService: PageBladeService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.loading = false;
				this.pageBladeService.itemForm.patchValue(this.item);
			});
		} else {
			this.loading = false;
			this.item = new Page();
			this.pageBladeService.itemForm.patchValue(this.item);
		}
	 }

	ngOnInit() {
	}

	public save() {
		this.loading = true;
		this.item = _.assign(this.item, this.pageBladeService.itemForm.value);
		this.item.isActive = this.item.isActive ? 1 : 0;

		this.saveOrUpdate().subscribe(item => {
			this.item = _.assign(this.item, item);
			this.bladeItem.minClass = BladeSize.m;
			this.bladeItem.maxClass = BladeSize.m;
			this.bladeItem.activeClass = this.bladeItem.maxClass;
			this.bladePositionService.selectBladeItem(this.bladeItem, true);
			this.loading = false;
			this.pageBladeService.itemForm.markAsPristine();
		});
	}

	public addNewEtage() {
		this.bladeService.addBladeItem({
			id: 0,
			component: EtageBladeComponent,
			minClass: BladeSize.s,
			payload: {
				pageId: this.bladeItem.id,
			},
		}, this.bladeItem);
	}

	private saveOrUpdate(): Observable<Page> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
