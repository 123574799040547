import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BladeItemInjectToken, BladeService } from '../blade.service';
import { BladeItem } from '../models/bladeItem.model';
import { MatDialog } from '@angular/material/dialog';
import { BladeModalComponent } from './components/blade-modal/blade-modal.component';
import { BladePositionService } from '../blade-position.service';
import { BladeModalData } from '../models/bladeModalData.model';


@UntilDestroy()
@Component({
	selector: 'app-blade-item',
	templateUrl: './blade-item.component.html',
	styleUrls: ['./blade-item.component.scss'],
})
export class BladeItemComponent implements OnInit, OnDestroy  {

	// private canIClose: boolean = undefined;
	// private canIClose$ = new Subject<boolean>();
	// @Output() beforeClose = new EventEmitter<number>();

	@Input() canIClose: Function | boolean;

	@Input() loading: boolean;
	@Input()
	set id(id: number) {
		if (id === null) {
			return;
		}
		setTimeout(() => {
			const isDiff = this.bladeItem.id !== id;
			this.bladeItem.id = id;
			if (id || id === 0 && isDiff) {
				this.bladeService.bladeItemsIsChanged();
			}
		});
	}

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private dialog: MatDialog,
		private bladeService: BladeService,
		private bladePositionService: BladePositionService,
	) {
		bladeItem.bladeItemComponent = this;
	}

	ngOnInit() {
	}

	ngOnDestroy(): void { }

	public closeMe(): Promise<boolean> {
		this.bladePositionService.selectBladeItem(this.bladeItem);
		return this.doCloseMe().then((canIClose) => {
			if (canIClose) {
				this.bladeService.deleteBladeItem(this.bladeItem);
			}
			return canIClose;
		});
	}

	private doCloseMe(): Promise<boolean> {
		if (this.bladeItem.child) {
			return this.bladeItem.child.bladeItemComponent.closeMe().then(canIClose => {
				if (canIClose) {
					this.bladeItem.child = undefined;
					return this.closeMe();
				}
				return Promise.resolve(false);

			});
		} else {
			if (typeof this.canIClose === 'boolean') {
				if (!this.canIClose) {
					return this.showModal();
				} else {
					return Promise.resolve(true);
				}
			} else {
				return this.canIClose()
					.then(canClose => {
						if (!canClose) {
							return this.showModal();
						}
						return Promise.resolve(true);
					});
			}
		}
	}

	public showModal(bladeModalData?: BladeModalData): Promise<boolean> {
		bladeModalData = bladeModalData || {
			headline: 'Är du säker?',
			content: 'Är du säker på att du vill stänga ' + this.bladeItem.title + '?',
			primaryButtonTitle: 'Nej',
			primaryButtonValue: false,
			secondaryButtonTitle: 'Ja',
			secondaryButtonValue: true,
		};
		const promise = new Promise<boolean>((resolve, reject) => {
			const dialogRef = this.dialog.open(BladeModalComponent, {
				data: bladeModalData,
			});
			dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(value => {
				resolve(value);
			});
		});
		return promise;
	}
}
