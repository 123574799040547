import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { CategoryText } from 'app/models/category/category-text.model';
import { Category } from 'app/models/category/category.model';
import { Language } from 'app/models/language.model';
import { CategoryTextStore } from 'app/admin/stores/category/category-text.store.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CategoryTextBladeComponent } from 'app/admin/blades/category-text/category-text-blade/category-text-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-category-blade-texts',
	templateUrl: './category-blade-texts.component.html',
	styleUrls: ['./category-blade-texts.component.scss'],
})
export class CategoryBladeTextsComponent implements OnInit, OnDestroy {
	public languages: Language[];
	public categoryTexts: CategoryText[];
	public displayedColumns: string[] = ['id', 'headline', 'created', 'modified', 'language'];
	public dataSource: MatTableDataSource<CategoryText>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public languageStore: LanguageStore,
		private categoryTextStore: CategoryTextStore,
	) {}

	ngOnInit() {
		this.categoryTextStore.items$.pipe(untilDestroyed(this)).subscribe(categoryTexts => {
			this.categoryTexts = categoryTexts;
			this.handleCategory();
		});
		this.categoryTextStore.getAllOnCategory(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(categoryTexts => {
			this.categoryTexts = categoryTexts;
			this.handleCategory();
		});
		this.languageStore.items$.pipe(untilDestroyed(this)).subscribe(languages => {
			this.languages = languages;
			this.handleCategory();
		});
	}

	ngOnDestroy(): void { }

	private handleCategory() {
		if (this.categoryTexts && this.languages) {
			// Every language must have a categoryText
			_.each(this.languages, language => {
				const categoryTexts = _.filter(this.categoryTexts, text => text.languageId === language.id);
				let categoryText;
				if (categoryTexts && categoryTexts.length > 1) {
					categoryText = _.find(this.categoryTexts, text => text.languageId === language.id && text.id > 0);
					this.categoryTextStore.removeCategoryText(_.find(this.categoryTexts, text => text.languageId === language.id && !text.id));
				} else if (categoryTexts && categoryTexts.length) {
					categoryText = categoryTexts[0];
				}

				if (!categoryText) {
					categoryText = new CategoryText();
					categoryText.languageId = language.id;
					this.categoryTextStore.addCategoryText(categoryText);
				}
				categoryText.language = language.name;
			});
			this.dataSource = new MatTableDataSource<CategoryText>(this.categoryTexts);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}

	public selectItem(row: CategoryText) {
		this.bladeService.addBladeItem({
			id: 0,
			component: CategoryTextBladeComponent,
			minClass: BladeSize.m,
			payload: {
				categoryId: this.bladeItem.id,
				languageId: row.languageId,
			},
		}, this.bladeItem);
	}
}
