import { Component, OnInit, ViewChild, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Tour } from 'app/models/tour.model';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { ReplaySubject } from 'rxjs';
import { TourGuideStore } from 'app/admin/stores/tour-guide.store.service';
import { TourGuide } from 'app/models/tour-guide.model';
import { UserStore } from 'app/admin/stores/user.store.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GuideTourBladeComponent } from '../guide-tour-blade/guide-tour-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
	selector: 'app-guide-tours-blade',
	templateUrl: './guide-tours-blade.component.html',
	styleUrls: ['./guide-tours-blade.component.scss'],
})
@IBladeComponent.register
export class GuideToursBladeComponent implements OnInit, OnDestroy {
	public allTourGuides: TourGuideName[] = [];
	public selectedTourGuide: TourGuideName;
	public tourGuides = new ReplaySubject<TourGuideName[]>();
	public _ = _;
	public moment = moment;
	public loading$ = new ReplaySubject<boolean>(1);
	public displayedColumns: string[] = ['date', 'name', 'places', 'status', 'isActive', 'isPrivate', 'open'];
	public dataSource: MatTableDataSource<Tour>;
	public dateList = [];

	public searchFormGroup: FormGroup = new FormGroup({
		guide: new FormControl(''),
	});
	public formGroup: FormGroup;

	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;

	private allTours: Tour[];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: TourStore,
		public tourGuideStore: TourGuideStore,
		private userStore: UserStore,
		private cdRef: ChangeDetectorRef,
		private route: Router,
	) {
		this.formGroup = new FormGroup({
			start: new FormControl(moment()),
			end: new FormControl(null),
		});

		this.tourGuideStore.getAll$().pipe(untilDestroyed(this)).subscribe(tourGuides => {
			_.uniqBy(tourGuides, x => x.userId).forEach(tourguide => {
				const tourguideName = tourguide as TourGuideName;
				this.userStore.pipeGetName(tourguideName.userId).pipe(untilDestroyed(this)).subscribe(name => {
					tourguideName.name = name.name;
				});
				this.allTourGuides.push(tourguideName);
			});
			this.tourGuides.next(this.allTourGuides);
		});

		this.searchFormGroup.get('guide').valueChanges.pipe(untilDestroyed(this)).subscribe(guide => {
			if (guide && typeof guide === 'string') {
				this.tourGuides.next(this.allTourGuides.filter(x => x.name?.toLowerCase()?.includes(guide?.toLowerCase())));
			} else {
				this.tourGuides.next(this.allTourGuides);
			}
		});
	}

	ngOnInit() { }
	ngOnDestroy() { }

	public selectItem(row) {
		this.route.navigate(['/tour'], { queryParams: { tourId: row.id }});
	}

	public openInNewTab(tour: Tour): void {
		let query = `/tour?tourId=${tour.id}`;
		window.open(query, '_blank');
	}

	public getTours() {
		if (!(this.formGroup.value.start && this.selectedTourGuide)) {
			return;
		}

		this.loading$.next(true);

		this.doGetToursFromDates()
		.pipe(untilDestroyed(this)).subscribe(tours => {
			_.each(tours, item => {
				item.date = new Date(item.date);
			});
			this.allTours = tours;
			this.setData();
		});
	}

	public doGetToursFromDates() {
		if (this.formGroup.value.end) {
			return this.itemStore.getAllOnGuideBetweenDates(
				this.selectedTourGuide.userId,
				this.formGroup.value.start.format('YYYY-MM-DD'),
				this.formGroup.value.end.format('YYYY-MM-DD'),
			);
		}

		return this.itemStore.getAllOnGuideFromDate(
			this.selectedTourGuide.userId,
			this.formGroup.value.start.format('YYYY-MM-DD'),
		);
	}

	public selectTourGuide(matAutocompleteSelectedEvent: MatAutocompleteSelectedEvent) {
		this.selectedTourGuide = matAutocompleteSelectedEvent.option.value;
		this.searchFormGroup.get('guide').setValue(this.selectedTourGuide.name);
	}

	public clearGuide() {
		this.selectedTourGuide = undefined;
		this.searchFormGroup.get('guide').setValue('');
		this.cdRef.markForCheck();
	}

	private setData() {
		this.dataSource = new MatTableDataSource<Tour>(_.orderBy(this.allTours, ['date', 'time'], ['asc', 'asc']));
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.loading$.next(false);
		this.cdRef.detectChanges();
	}
}

interface TourGuideName extends TourGuide {
	name: string;
}
