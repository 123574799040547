
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { TextElement } from 'app/models/text-element.model';
import { Language } from 'app/models/language.model';
import { ProductBookingElement } from 'app/models/product-booking-element.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ProductBookingElementStore extends BaseStore<ProductBookingElement> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductBookingElements', baseUrl, snackBarService);
	}

	public getOnElement(elementId: number): Observable<ProductBookingElement> {
		return this.http.get<ProductBookingElement>(`${this.host}/element/${elementId}`);
	}
}
