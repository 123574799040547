import { Component, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TermTypeStore } from 'app/admin/stores/term-type.store.service';
import { TermTypeService } from './services/term-type.service';

@Component({
	selector: 'app-term-type-blade',
	templateUrl: './term-type-blade.component.html',
	styleUrls: ['./term-type-blade.component.scss'],
	providers: [TermTypeService],
})
@IBladeComponent.register
export class TermTypeBladeComponent {

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: TermTypeStore,
		public termTypeService: TermTypeService,
	) { }
}
