import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TourBookingSpecialDietStore } from 'app/admin/stores/tour-booking-special-diet.store.service';
import * as moment from 'moment';
import { SpecialDietStore } from '../../../../../stores/special-diet.store.service';
import { TourStore } from '../../../../../stores/tour.store.service';
import { combineLatest, of, ReplaySubject } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import { Tour } from '../../../../../../models/tour.model';
import * as _ from 'lodash';
import { TourBookingSpecialDiet } from 'app/models/tour-booking-special-diet.model';
import { TourBookingSpecialDietMessage } from 'app/models/tour-booking-special-diet-message.model';

@UntilDestroy()
@Injectable()
export class SpecialDietReportService {
	public specialDietSummary$ = new ReplaySubject<SpecialDietOnTour[]>();

	public formGroup: FormGroup = new FormGroup({
		start: new FormControl(moment()),
		end: new FormControl(moment().add(10, 'days'), []),
	});

	constructor(
		private specialDietStore: SpecialDietStore,
		private tourBookingSpecialDietStore: TourBookingSpecialDietStore,
		private tourStore: TourStore,
	) {
		this.getSpecialDietsByDate();
	}

	public getSpecialDietsByDate(): void {
		combineLatest([
			this.tourStore.getAllBetweenDatesWithSpecialDiets(this.formGroup.value.start.format('YYYY-MM-DD'), this.formGroup.value.end.format('YYYY-MM-DD')),
		]).pipe(untilDestroyed(this), switchMap(([tours]) => {
			const specialDietOnTour = tours.map(t => {
				const tourBookingSpecialDiets = _.flatMap(_.map(_.flatMap(t.tourBookings), x => x.tourBookingSpecialDiets));
				const tourBookingSpecialDietMessage = _.flatMap(_.map(_.flatMap(t.tourBookings), x => x.tourBookingSpecialDietMessages));
				return {
					tour: t,
					tourBookingSpecialDiets,
					tourBookingSpecialDietMessage,
				};
			});
			return of(specialDietOnTour.filter(x => x.tourBookingSpecialDiets?.length || x.tourBookingSpecialDietMessage?.length));
		})).subscribe(specialDietOnTour => this.specialDietSummary$.next(specialDietOnTour));
	}
}

interface SpecialDietOnTour {
	tour: Tour;
	tourBookingSpecialDiets: TourBookingSpecialDiet[];
	tourBookingSpecialDietMessage: TourBookingSpecialDietMessage[];
}
