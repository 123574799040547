import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SupplierResourceTypeStore } from 'app/admin/stores/supplier-resource-type.store.service';
import { SupplierResourceType } from 'app/models/supplier-resource-type.model';
import { BladeModalData } from 'app/admin/components/blade/models/bladeModalData.model';

@Component({
	selector: 'app-supplier-resource-type-blade',
	templateUrl: './supplier-resource-type-blade.component.html',
	styleUrls: ['./supplier-resource-type-blade.component.scss'],
})
@IBladeComponent.register
export class SupplierResourceTypeBladeComponent implements OnInit {

	public loading = true;
	public item: SupplierResourceType;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		identifier: new FormControl('', [Validators.required]),
	});
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: SupplierResourceTypeStore,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.itemForm.setValue({name: this.item.name, identifier: this.item.identifier});

				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new SupplierResourceType();
		}
	 }

	ngOnInit() {
	}

	public createIdentifier() {
		this.itemForm.get('identifier').setValue(_.snakeCase(this.itemForm.get('name').value));
	}

	public save() {
		this.loading = true;
		this.item.name = this.itemForm.get('name').value;
		this.item.identifier = this.itemForm.get('identifier').value;
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<SupplierResourceType> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

	public remove() {
		this.loading = true;
		const bladeModalData: BladeModalData = {
			headline: 'Är du säker?',
			content: 'Detta kommer radera alla resurser av denna typ på både Leverantörer ovh Turer.',
			primaryButtonTitle: 'Nej',
			primaryButtonValue: false,
			secondaryButtonTitle: 'Ja',
			secondaryButtonValue: true,
		};
		this.bladeItem.bladeItemComponent.showModal(bladeModalData).then(value => {
			if (value){
				this.itemStore.delete(this.item.id).subscribe(isDeleted => {
					this.loading = false;
					if (isDeleted) {
						this.bladeItem.bladeItemComponent.closeMe();
					}
				});
			} else {
				this.loading = false;
			}
		});
	}
}
