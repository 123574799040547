
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { PickupLocationPrice } from 'app/models/page-location-price.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class PickupLocationPriceStore extends BaseStore<PickupLocationPrice> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'PickupLocationPrice', baseUrl, snackBarService);
	}

	public getAllOnPickupLocation(pickupLocationId: number): Observable<PickupLocationPrice[]> {
		return this.http.get<PickupLocationPrice[]>(`${this.host}/pickupLocation/${pickupLocationId}`).pipe(tap(orders => {
			this._items = orders;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnPickupLocation$(pickupLocationId: number): Observable<PickupLocationPrice[]> {
		return this.http.get<PickupLocationPrice[]>(`${this.host}/pickupLocation/${pickupLocationId}`);
	}

	public addPickupLocationPrice(pickupLocationPrice: PickupLocationPrice) {
		this._items.push(pickupLocationPrice);
		this._itemsSubject.next(this._items);
	}

	public removePickupLocationPrice(pickupLocationPrice: PickupLocationPrice) {
		_.remove(this._items, item => item.customerTypeId === pickupLocationPrice.customerTypeId && !item.price);
		this._itemsSubject.next(this._items);
	}

	public getPriceOnCustomerType(pickupLocationId: number, productId: number, customerTypeId: number): Observable<PickupLocationPrice> {
		return this.http.get<PickupLocationPrice>(`${this.host}/pickupLocation/${pickupLocationId}/product/${productId}/customerType/${customerTypeId}`);
	}
}
