import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TermType } from 'app/models/term-type.model';
import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class TermTypeStore extends BaseStore<TermType> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TermType', baseUrl, snackBarService);
	}
}
