
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { ProductElement } from 'app/models/product-element.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ProductElementStore extends BaseStore<ProductElement> {
	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductElements', baseUrl, snackBarService);
	}

	public getOnElement(elementId: number): Observable<ProductElement> {
		return this.http.get<ProductElement>(`${this.host}/element/${elementId}`);
	}
}
