import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TravelAgencyStore } from '../stores/travel-agency.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'travelAgencyName' })
export class TravelAgencyNamePipe implements PipeTransform, OnDestroy {
	constructor(private travelAgencyStore: TravelAgencyStore) {	}
	public transform(travelAgencyId: number): Observable<string> {
		if (!travelAgencyId) {
			return;
		}
		return this.travelAgencyStore.pipeGetName(travelAgencyId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
