import { BaseModel } from './base.model';
import { OrderItemCustomerTypeAge } from './order-item-customer-type-age.model';

export class OrderItem extends BaseModel {
	public orderId: number;
	public parentOrderItemId: number;
	public pickupLocationId: number;
	public productId: number;
	public supplierId: number;
	public sourceTypeId: number;
	public productTypeId: number;
	public customerTypeId: number;
	public addonId: number;
	public name: string;
	public data: any;
	public taxRate: number;
	public taxAmount: number;
	public unit: string;
	public unitPrice: number;
	public grossTotalAmount: number;
	public netTotalAmount: number;
	public quantity: number;
	public totalPaid: number;
	public orderItemCustomerTypeAges: OrderItemCustomerTypeAge[];
}
