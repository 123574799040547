<app-blade-item [canIClose]="true">
    <app-blade-header [title]="'Sidor'"></app-blade-header>
    <app-blade-content>
		<div class="mat-elevation-z1">
			<mat-table [dataSource]="dataSource" class="w-100"
				cdkDropList
				(cdkDropListDropped)="onListDrop($event)"
				[cdkDropListData]="dataSource">

				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
					<mat-cell *matCellDef="let item"><strong>{{item.name}}</strong></mat-cell>
				</ng-container>
				<ng-container matColumnDef="type">
					<mat-header-cell *matHeaderCellDef>Typ</mat-header-cell>
					<mat-cell mat-cell *matCellDef="let item">{{item.pageTypeId | pageTypeName | async}}</mat-cell>
				</ng-container>
				<ng-container matColumnDef="modified">
					<mat-header-cell *matHeaderCellDef>Modifierad</mat-header-cell>
					<mat-cell *matCellDef="let item">{{item.modified|date:'yyyy-MM-dd HH:mm'}}<br />{{item.modifiedBy | adminUserName | async}}</mat-cell>
				</ng-container>
				<ng-container matColumnDef="active">
					<mat-header-cell *matHeaderCellDef>Aktiv</mat-header-cell>
					<mat-cell mat-cell *matCellDef="let item">
						<mat-icon *ngIf="item.isActive">check_box</mat-icon>
						<mat-icon *ngIf="!item.isActive">check_box_outline_blank</mat-icon>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row cdkDrag [cdkDragData]="item" [cdkDragDisabled]="savingPrioReplay$ | async" *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.id === item?.id"></mat-row>
			</mat-table>

			<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</div>
		<div class="mt-2 text-right">
			<button mat-raised-button color="accent" *ngIf="prioIsChangedReplay$ | async" (click)="savePrio()" [disabled]="savingPrioReplay$ | async">Spara sorteringen</button>
		</div>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="createNew()">Skapa ny</button>
	</app-blade-footer>
</app-blade-item>
