import { Component, OnInit, OnDestroy } from '@angular/core';
import { BladeItem } from '../../../models/bladeItem.model';
import { BladeService } from '../../../blade.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BladePositionService } from '../../../blade-position.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@UntilDestroy()
@Component({
  selector: 'app-blade-navigation',
  templateUrl: './blade-navigation.component.html',
  styleUrls: ['./blade-navigation.component.scss'],
})
export class BladeNavigationComponent implements OnInit, OnDestroy {
	public bladeItems: BladeItem[];
	constructor(
		public bladeService: BladeService,
		public bladePositionService: BladePositionService,
		private bottomSheetRef: MatBottomSheetRef<BladeNavigationComponent>,
	) {
		this.bladeService.bladeItems$.pipe(untilDestroyed(this)).subscribe(bladeItems => {
			this.bladeItems = bladeItems;
		});
	}

	ngOnInit() {
	}

	ngOnDestroy(): void { }

	selectBladeItem($event: Event, bladeItem: BladeItem) {
		$event.preventDefault();
		this.bladePositionService.selectBladeItem(bladeItem);
		this.bottomSheetRef.dismiss();
	}

	removeBladeItem($event: Event, bladeItem: BladeItem) {
		$event.preventDefault();
		this.bladeService.removeBladeItem(bladeItem);
		this.bottomSheetRef.dismiss();
	}

	public close() {
		this.bottomSheetRef.dismiss();
	}
}
