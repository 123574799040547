import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { Supplier } from 'app/models/supplier.model';
import { PlannerResourceGroup } from '../../models/planner-resource-group';
import { TourResourceService } from 'app/admin/components/shared/tour-resource/tour-resource.service';
import { ResourceSummaryItem } from 'app/admin/models/resource-summary-item';
import { combineLatest, ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SupplierResourceDateStore } from 'app/admin/stores/supplier-resource-date.store.service';
import { SupplierResourceDate } from 'app/models/supplier-resource-date.model';
import { SupplierResource } from 'app/models/supplier-resource.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@UntilDestroy()
@Component({
	selector: 'app-planner-resource-item',
	templateUrl: './planner-resource-item.component.html',
	styleUrls: ['./planner-resource-item.component.scss'],
	providers: [
		TourResourceService,
	],
})
export class PlannerResourceItemComponent implements OnDestroy, OnInit {

	public resourceSummaryReplay$ = new ReplaySubject<ResourceSummaryItemExtended[]>();

	public displayedColumns: string[] = ['name'];
	public dataSource: MatTableDataSource<Supplier>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	@Input() group: PlannerResourceGroup;

	public supplierResourceDateReplay$ = new ReplaySubject<SupplierResourceDate>();

	public resourceForm: FormGroup = new FormGroup({
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public tourResourceService: TourResourceService,
		private supplierResourceDateStore: SupplierResourceDateStore,
	) {
		tourResourceService.resourceSummaryGroupReplay$.pipe(untilDestroyed(this)).subscribe(resourceSummaryGroups => {
			const resourceSummaryItems: ResourceSummaryItemExtended[] = [];
			const summaryGroups = _.groupBy(_.flatMap(resourceSummaryGroups, summary => summary.items), item => item.supplierResourceTypeId);

			_.forOwn(summaryGroups, (value, key) => {
				this.resourceForm.addControl('amount' + parseInt(key, 10), new FormControl(0, [Validators.required]));
				resourceSummaryItems.push({
					supplierResource: this.group.supplierResources.find(sr => sr.supplierResourceTypeId === parseInt(key, 10)),
					supplierResourceTypeId: parseInt(key, 10),
					amount: _.sum(_.map(value, val => val.amount)),
				});
			});

			this.resourceSummaryReplay$.next(resourceSummaryItems);
		});

		this.resourceSummaryReplay$.pipe(untilDestroyed(this)).subscribe(resourceSummaryItems => {
			resourceSummaryItems.forEach(resourceSummaryItem => {
				this.supplierResourceDateStore.getAllOnSupplierResource$(resourceSummaryItem.supplierResource.id).pipe().subscribe(resources => {
					resourceSummaryItem.supplierResourceDate = resources.find(resource =>
						moment(resource.date).format('YYYY-MM-DD') === moment(this.group.date).format('YYYY-MM-DD'));

					if (resourceSummaryItem.supplierResourceDate) {
						this.resourceForm.get('amount' + resourceSummaryItem.supplierResourceTypeId)
							.setValue(resourceSummaryItem.supplierResourceDate.amount);
					}

				});
			});
		});
	}

	ngOnInit() {
	}

	ngOnDestroy() {}

	public save(supplierResource: ResourceSummaryItemExtended) {
		if (supplierResource.supplierResourceDate) {
			supplierResource.supplierResourceDate.amount = this.resourceForm.get('amount' + supplierResource.supplierResourceTypeId).value;
			this.supplierResourceDateStore.update(supplierResource.supplierResourceDate).subscribe();
		} else {
			return this.supplierResourceDateStore.create({
				amount: this.resourceForm.get('amount' + supplierResource.supplierResourceTypeId).value,
				date: this.group.date,
				supplierResourceId: supplierResource.supplierResource.id,
			}).subscribe(supplierResourceDate => supplierResource.supplierResourceDate = supplierResourceDate);
		}
	}
}

interface ResourceSummaryItemExtended extends ResourceSummaryItem {
	supplierResource: SupplierResource;
	supplierResourceDate?: SupplierResourceDate;
}
