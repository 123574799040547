import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import * as _ from 'lodash';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, FormControl } from '@angular/forms';
import { CartElement } from 'app/models/cart-element.model';
import { CartElementStore } from 'app/admin/stores/cart-element.store.service';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { PaymentElement } from 'app/models/payment-element.model';
import { PaymentElementStore } from 'app/admin/stores/payment-element.store.service';
import { ElementStore } from 'app/admin/stores/element.store.service';

@UntilDestroy()
@Component({
	selector: 'app-element-payment-blade',
	templateUrl: './element-blade-payment.blade.html',
	styleUrls: ['./element-blade-payment.blade.scss'],
})
@IBladeComponent.register
export class ElementPaymentBlade implements OnInit, OnDestroy {
	public saving: Subject<boolean> = new Subject<boolean>();
	public selectedIndex = 0;
	public paymentElement: PaymentElement;
	public loading = true;

	public menuItems: BladeMenuItem[] = [
		{
			index: 1,
			title: 'Betalning',
		}, {
			index: 99,
			title: 'Style',
		},
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public paymentElementStore: PaymentElementStore,
		private elementStore: ElementStore,
	) {
	}

	ngOnInit() {
		this.paymentElement = new PaymentElement();
		this.paymentElement.elementId = this.bladeItem.id;
		this.paymentElementStore.getOnElement(this.bladeItem.id).subscribe(paymentElement => {
			if (paymentElement) {
				this.paymentElement = paymentElement;
			}
			this.loading = false;
		});
	}

	ngOnDestroy() { }

	public save() {
		this.loading = true;
		if (this.selectedIndex === 99) {
			this.saving.next(true);
			return;
		}

		if (this.paymentElement.id) {
			this.paymentElementStore.update(this.paymentElement).subscribe(updatedItem => {
				this.loading = false;
				this.paymentElement = _.assign(this.paymentElement, updatedItem);
			});
		} else {
			this.paymentElementStore.create(this.paymentElement).subscribe(updatedItem => {
				this.loading = false;
				this.paymentElement = _.assign(this.paymentElement, updatedItem);
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.elementStore.delete(this.paymentElement.elementId).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
