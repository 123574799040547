import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { OrderStore } from '../stores/order.store.service';
import { Order } from 'app/models/order.model';
import { PaymentBalance } from 'app/models/paymanet-balance.model';
import { CurrencyPipe } from '@angular/common';
import { TourBookingCustomerTypeSummary } from 'app/models/tour-booking-customer-type-summary.model';
import { TourBooking } from 'app/models/tour-booking.model';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'paymentBalance' })
export class PaymentBalancePipe implements PipeTransform, OnDestroy {
	constructor(private orderStore: OrderStore, private currency: CurrencyPipe) {	}
	public transform(obj: Order | TourBookingCustomerTypeSummary | TourBooking): Observable<ExtendedPaymentBalance> {
		if (!obj) {
			return;
		}

		if (obj.paymentBalance) {
			return of(this.calculate(obj.paymentBalance));
		}

		let orderId = obj.id;
		if ((obj as TourBookingCustomerTypeSummary).tourBooking?.orderId) {
			orderId = (obj as TourBookingCustomerTypeSummary).tourBooking.orderId;
		}

		if ((obj as TourBooking).orderId) {
			orderId = (obj as TourBooking).orderId;
		}

		return this.orderStore.getPaymentBalanceOnOrder(orderId).pipe(map(paymentBalance => {
			obj.paymentBalance = paymentBalance;
			return this.calculate(obj.paymentBalance);
		}));
	}

	ngOnDestroy(): void { }

	private calculate(paymentBalance: PaymentBalance): ExtendedPaymentBalance {
		const balance = paymentBalance?.totalOrder - paymentBalance?.totalPayment;
		let className = '';
		if (!balance) {
			className = 'text-success';
		} else if (balance > 0) {
			className = 'text-danger';
		} else if (balance < 0) {
			className = 'text-warning';
		}

		return {
			className,
			balance,
			balanceStr: this.currency.transform(balance, 'SEK', 'code'),
			paymentBalance,
		};
	}
}

interface ExtendedPaymentBalance {
	className: string;
	balance: number;
	balanceStr: string;
	paymentBalance: PaymentBalance;
}
