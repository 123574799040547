import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PickupLocationPriceStore } from '../stores/pickup-location-price.store.service';
import { CurrencyPipe } from '@angular/common';
import { FormControl } from '@angular/forms';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'pickupLocationPrice' })
export class PickupLocationPricePipe implements PipeTransform, OnDestroy {
	constructor(
		private pickupLocationStore: PickupLocationPriceStore,
		private currencyPipe: CurrencyPipe,
	) {	}
	public transform(pickupLocationId: number, productId: number, customerTypeId: number, formControl?: FormControl): Observable<string> {
		if (!pickupLocationId) {
			return of('Saknar hämtplats');
		}
		return this.pickupLocationStore.getPriceOnCustomerType(pickupLocationId, productId, customerTypeId)
		.pipe(map(type => {
			if (formControl) {
				formControl.setValue(type.price);
			}
			return this.currencyPipe.transform(type.price, 'SEK', 'code');
		}));
	}

	ngOnDestroy(): void { }
}
