export enum ElementTypeEnum {
	Text = 'text',
	Image = 'image',
	Video = 'video',
	Headline = 'headline',
	Product = 'product',
	ProductBooking = 'productbookning',
	MatCard = 'mat_card',
	Cart = 'cart',
	ProductList = 'productlist',
	Confirmation = 'confirmation',
	Slideshow = 'slideshow',
	Payment = 'payment',
	Login = 'login',
	ProfileMenu = 'profile_menu',
	ProfileSubpageViewer = 'profile_subpage_viewer',
	ProfileOrders = 'profile_orders',
	ProfileEdit = 'profile_edit',
	Button = 'button',
	CategoryList = 'categorylist',
	Category = 'category',
	Form = 'form',
	Sirvoy = 'sirvoy',
}
