<app-blade-item [canIClose]="true" [loading]="loading">
	<app-blade-header
		[title]="item?.id ? 'Order' : 'Ny'"
		subTite="Order"
		[menuItems]="headerMenuItems"
	></app-blade-header>
	<app-blade-content>
		<mat-card *ngIf="item?.isDraft" class="mb-3">
			<mat-card-title class="text-warning">Detta är ett utkast</mat-card-title>
			<mat-card-content>
				<p>Det innebär att denna order inte kommer ta några resurser i anspråk.<br>Är denna order en bokning på en tur så kommer inte antalet att räknas in som upptagna platser på hemsidan fören denna order och tillhörande bookingar är publicerade.</p>
			</mat-card-content>
			<mat-card-actions class="text-right mr-1 mb-1">
				<button mat-raised-button color="accent" (click)="publishOrder()">Publicera order och alla tillhörande bokningar</button>
			</mat-card-actions>
		</mat-card>
		<mat-accordion multi *ngIf="item">
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Information
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<app-order-generally [order]="item" (save)="save()"></app-order-generally>
			</mat-expansion-panel>
			
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Betalning
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<app-order-payments></app-order-payments>
				<ng-container *ngIf="showPaymentBalance$ | async; else loadingElement">
					<div class="mt-3 pt-3 border-top" *ngIf="item | paymentBalance | async as payment">
						<mat-card-title class="d-flex justify-content-between">
							<span>Kredit</span><button mat-icon-button class="reload" (click)="reloadPaymentBalance()">
							<mat-icon>sync</mat-icon></button>
						</mat-card-title>
						<p>Totalt belopp på ordern: <b>{{payment?.paymentBalance?.totalOrder | currency:'SEK':'code'}}</b></p>
						<p>Kunden har betalat <b>{{payment?.paymentBalance?.totalPayment | currency:'SEK':'code'}}</b></p>
						<p>
							Balans -
							<span *ngIf="payment.balance > 0">Ska betala: </span>
							<span *ngIf="payment.balance < 0">Få tillbaka: </span>
							<b>
								<span
									[class.text-success]="!payment.balance"
									[class.text-danger]="payment.balance > 0"
									[class.text-warning]="payment.balance < 0">
									{{payment.balanceStr}}
								</span>
							</b>
						</p>
					</div>
				</ng-container>
			</mat-expansion-panel>
			
			<mat-expansion-panel [expanded]="false">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Orderrader
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<app-order-items class="d-block mt-3"></app-order-items>
				<ng-template #loadingElement>
					<mat-card class="mt-3 d-flex justify-content-around">
						<mat-spinner></mat-spinner>
					</mat-card>
				</ng-template>				
			</mat-expansion-panel>
			
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Bokningar
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<app-order-tour-bookings [order]="item"></app-order-tour-bookings>
			</mat-expansion-panel>
			
			<mat-expansion-panel [expanded]="false">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Kundprogram
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<app-shared-customer-program [customerId]="item.customerId"></app-shared-customer-program>
			</mat-expansion-panel>

			<mat-expansion-panel [expanded]="false">
				<mat-expansion-panel-header>
					<mat-panel-title>
						Email
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<email-queue *ngIf="item?.id" [emailQueueType]="'NewOrder'" [entityName]="'Orders'" [entityId]="item?.id"></email-queue> -->
			</mat-expansion-panel>
		</mat-accordion>
	</app-blade-content>
</app-blade-item>
