<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem.id">
		<app-blade-header [title]="'Bekräftelse'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
		<app-blade-content [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
			<mat-card>
				<div *ngIf="confirmationElement && selectedIndex !== 99">
					<app-alert-card [mode]="'info'" [label]="'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quibusdam sapiente cupiditate aspernatur?'"></app-alert-card>
					<mat-card-title>Bekräftelse efter köp</mat-card-title>
				</div>
				<app-element-blade-styles *ngIf="confirmationElement && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
			</mat-card>
		</app-blade-content>
		<app-blade-footer>
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="save()">
				<span *ngIf="confirmationElement?.id">Spara</span>
				<span *ngIf="!confirmationElement?.id">Skapa</span>
			</button>
		</app-blade-footer>
	</app-blade-item>
