import { BaseModel } from './base.model';
import { DashboardWidget } from 'app/admin/components/dashboard/models/dashboard-widget.model';

export class Dashboard extends BaseModel {
	public name: string;
	public isActive: number;
	public isGlobal: number;
	public userId: number;
	public widgets: DashboardWidget[];
}
