import { Component, AfterContentInit, ContentChildren, QueryList, } from '@angular/core';

import * as _ from 'lodash';

import { BladeContentTabComponent } from './components/blade-content-tab/blade-content-tab.component';
import { BladeContentService } from '../../blade-content.service';

/**
 * From https://stackblitz.com/edit/angular-dynamic-tabs
 */
@Component({
	selector: 'app-blade-content-tabs',
	templateUrl: './blade-content-tabs.component.html',
	styleUrls: ['./blade-content-tabs.component.scss'],
})
export class BladeContentTabsComponent implements AfterContentInit {
	private pristineTabs: QueryList<BladeContentTabComponent>;
	@ContentChildren(BladeContentTabComponent) tabs: QueryList<BladeContentTabComponent>;

	constructor(
		private bladeContentService: BladeContentService,
	) { }

	ngAfterContentInit() {
		this.pristineTabs = _.cloneDeep(this.tabs);

		this.bladeContentService.addTabs(this.tabs.toArray());
	}
}
