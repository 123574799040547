<mat-card>
	<mat-card-title>Nets betalning</mat-card-title>

	<mat-card class="mb-3">
		<mat-card-title>Info</mat-card-title>
		<mat-card-content>
			<p>Id: {{item.data.ID}}</p>
			<p>Betalnings id: {{item.paymentId}}</p>
			<p>MerchantId: {{item.data.MerchantId}}</p>
			<p>OrderID: {{item.orderId}}</p>
			<p>Betalningstyp: {{item.paymentTypeId | paymentTypeName | async}}</p>
			<p>Belopp: {{item.amount | currency:'SEK':'code'}}</p>
			<p>Datum: {{item.data.Timestamp  | date:'yyyy-MM-dd HH:mm'}}</p>
			<p>Event: {{item.data.Event}}</p>
			<p *ngIf="item.message">Meddelande: {{item.message}}</p>
			<p>Skapad: {{item.created | date:'yyyy-MM-dd HH:mm'}}</p>
			<p *ngIf="item.createdBy > 0">Skapad av: {{item.createdBy | adminUserName | async}}</p>
			<p>Modifierad: {{item.modified | date:'yyyy-MM-dd HH:mm'}}</p>
			<p *ngIf="item.modifiedBy > 0">Modifierad av: {{item.modifiedBy | adminUserName | async}}</p>
		</mat-card-content>
	</mat-card>

	<mat-card class="mb-3">
		<mat-card-title>Kund</mat-card-title>
		<mat-card-content>
			<p>Förnamn: {{item.data.Data.Consumer.firstName}}</p>
			<p>Efternamn: {{item.data.Data.Consumer.lastName}}</p>
			<p>Land: {{item.data.Data.Consumer.country}}</p>
			<p>Email: {{item.data.Data.Consumer.email}}</p>
			<p>IP: {{item.data.Data.Consumer.ip}}</p>
			<p>Telefon: {{item.data.Data.Consumer.phoneNumber.Prefix}} {{item.data.Data.Consumer.phoneNumber.Number}}</p>
			<mat-card>
				<mat-card-subtitle>Adress</mat-card-subtitle>
				<mat-card-content>
					<p>Adress: {{item.data.Data.Consumer.shippingAddress.AddressLine1}}</p>
					<p *ngIf="item.data.Data.Consumer.shippingAddress.AddressLine2">Adress2: {{item.data.Data.Consumer.shippingAddress.AddressLine2}}</p>
					<p>Stad: {{item.data.Data.Consumer.shippingAddress.City}}</p>
					<p>Land: {{item.data.Data.Consumer.shippingAddress.Country}}</p>
					<p>Postnr: {{item.data.Data.Consumer.shippingAddress.Postcode}}</p>
					<p>Mottagare: {{item.data.Data.Consumer.shippingAddress.ReceiverLine}}</p>
				</mat-card-content>
			</mat-card>
		</mat-card-content>
	</mat-card>

	<mat-card class="mb-3">
		<mat-card-title>Beställning</mat-card-title>
		<mat-card-content>
			<p>Reference: {{item.data.Data.Order.Reference}}</p>
			<p>Belopp: {{item.data.Data.Order.Amount.Amount | currency:'SEK':'code'}}</p>
			<p>Valuta: {{item.data.Data.Order.Amount.Currency}}</p>
			<mat-card class="mb-3" *ngFor="let orderItem of item.data.Data.Order.OrderItems">
				<mat-card-title>{{orderItem.Name}}</mat-card-title>
				<mat-card-content>
					<p>Reference: {{orderItem.Reference}}</p>
					<p>Antal: {{orderItem.Quantity}}</p>
					<p>Enhet: {{orderItem.Unit}}</p>
					<p>Enhetspris: {{orderItem.UnitPrice | currency:'SEK':'code'}}</p>
					<p>Netto pris: {{orderItem.UnitNetPrice | currency:'SEK':'code'}}</p>
					<p>Skattsats: {{orderItem.TaxRate | currency:'SEK':'code'}}</p>
					<p>Skatt: {{orderItem.TaxAmount | currency:'SEK':'code'}}</p>
					<p>Skatt totalt: {{orderItem.TaxTotalAmount | currency:'SEK':'code'}}</p>
					<p>Totalt: {{orderItem.GrossTotalAmount | currency:'SEK':'code'}}</p>
					<p>Netto: {{orderItem.NetTotalAmount | currency:'SEK':'code'}}</p>
				</mat-card-content>
			</mat-card>
		</mat-card-content>
	</mat-card>
</mat-card>
