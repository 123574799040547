import { Component, OnInit, Inject, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingAddonStore } from 'app/admin/stores/tour-booking-addon.store.service';
import { MatAccordion } from '@angular/material/expansion';
import { TourBookingAddon } from 'app/models/tour-booking-addon.model';
import { ReplaySubject } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-guide-tour-addons',
	templateUrl: './tour-addons.component.html',
	styleUrls: ['./tour-addons.component.scss'],
})
export class GuideTourAddonsComponent implements OnInit {
	@ViewChild(MatAccordion) accordion: MatAccordion;
	private addonGroups: AddonGroup[] = [];
	public addonGroups$ = new ReplaySubject<AddonGroup[]>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public tourBookingStore: TourBookingStore,
		public tourBookingAddonStore: TourBookingAddonStore,
		private bladeService: BladeService,
	) {
		this.tourBookingStore.getAllOnTour(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(tourBookings => {
			tourBookings.forEach(tourBooking => {
				this.tourBookingAddonStore.getAllOnTourBooking(tourBooking.id).pipe(untilDestroyed(this)).subscribe(tourBookingAddons => {
					tourBookingAddons.forEach(tourBookingAddon => {
						this.addAddon(tourBookingAddon.addonId, tourBooking.customerId, tourBookingAddon);
					});
				});
			});
		});
	}

	ngOnInit() {}


	private addAddon(addonId: number, customerId: number, tourBookingAddon: TourBookingAddon) {
		const addonGroup = this.addonGroups.find(x => x.addonId === addonId);
		if (!addonGroup) {
			this.addonGroups.push({
				addonId,
				quantity : tourBookingAddon.quantity,
				tourBookingAddons: [{
					customerId,
					quantity: tourBookingAddon.quantity,
				}],
			});
		} else {
			addonGroup.quantity += tourBookingAddon.quantity;
			addonGroup.tourBookingAddons.push({
				customerId,
				quantity: tourBookingAddon.quantity,
			});
		}

		this.addonGroups$.next(this.addonGroups);
	}
}

interface AddonGroup {
	addonId: number;
	quantity: number;
	tourBookingAddons: TourBookingAddonGroup[];
}

interface TourBookingAddonGroup {
	customerId: number;
	quantity: number;
}
