import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import {
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { ProductText } from 'app/models/product/product-text.model';
import { ProductTextStore } from 'app/admin/stores/product/product-text.store.service';

@Component({
	selector: 'app-product-text-blade',
	templateUrl: './product-text-blade.component.html',
	styleUrls: ['./product-text-blade.component.scss'],
})
@IBladeComponent.register
export class ProductTextBladeComponent implements OnInit {
	public loading = true;
	public item: ProductText;

	public itemForm: FormGroup = new FormGroup({
		headline: new FormControl('', [Validators.required]),
		information: new FormControl('', [Validators.required]),
		description: new FormControl('', [Validators.required]),
		emailDescription: new FormControl('', []),
		seoTitle: new FormControl('', []),
		seoDescription: new FormControl('', []),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: ProductTextStore,
	) {
		if (
			bladeItem.payload &&
			bladeItem.payload.productId &&
			bladeItem.payload.languageId
		) {
			itemStore
				.getOnProductAndLanguage(
					+bladeItem.payload.productId,
					+bladeItem.payload.languageId,
				)
				.subscribe(item => {
					this.item = item;
					if (!this.item) {
						this.item = new ProductText();
						this.item.productId = +bladeItem.payload.productId;
						this.item.languageId = +bladeItem.payload.languageId;
						this.item.productTextTypeId = 1;
					}
					this.itemForm.patchValue(this.item);

					this.loading = false;
				});
		} else {
			console.error('Missing productId and/or languageId');
		}
	}

	ngOnInit() {
	}

	public save() {
		this.loading = true;
		this.item = _.assign(this.item, this.itemForm.value);

		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<ProductText> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

}
