import { BaseModel } from './base.model';

export class TermText extends BaseModel {
	public termId: number;
	public termTypeId: number;
	public languageId: number;
	public headline: string;
	public description: string;
	public text: string;
	public version: number;
}
