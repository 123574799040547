<mat-card *ngIf="{
	tourBookingSpecialDietExtendedItem: tourBookingSpecialDietItemService.tourBookingSpecialDietExtendedItemReplay$ | async,
	specialDiets: tourBookingSpecialDietItemService.specialDietStore.items$ | async
} as source">
	<mat-card-title class="card-title-small">{{source.tourBookingSpecialDietExtendedItem?.customerTypeId | customerTypeName | async}} {{index+1}}</mat-card-title>
	<div>
		<ng-container *ngFor="let specialDiet of source.specialDiets">
			<mat-checkbox class="mr-3" *ngIf="!specialDiet.requireExtraInfo"
				[checked]="tourBookingSpecialDietItemService.isChecked(specialDiet.id)" (click)="$event.preventDefault();tourBookingSpecialDietItemService.toggleSpecialDiet(specialDiet.id)">{{specialDiet.name}}</mat-checkbox>
		</ng-container>
	</div>

	<div *ngFor="let specialDiet of source.specialDiets">
		<ng-container *ngIf="specialDiet.requireExtraInfo">
			<mat-checkbox class="d-block" [checked]="tourBookingSpecialDietItemService.isChecked(specialDiet.id)" (click)="$event.preventDefault();tourBookingSpecialDietItemService.toggleSpecialDiet(specialDiet.id)">{{specialDiet.name}}</mat-checkbox>
			<div *ngIf="tourBookingSpecialDietItemService.isChecked(specialDiet.id)" [formGroup]="tourBookingSpecialDietItemService.specialDietForm">
				<mat-form-field class="w-100" appearance="outline">
					<input
						matInput
						[formControlName]="specialDiet.id" />
				</mat-form-field>
			</div>
		</ng-container>
	</div>
	<mat-card-actions>
		<button mat-button [color]="'warn'" (click)="tourBookingSpecialDietItemService.tourBookingSpecialDietService.removeGroup(source.tourBookingSpecialDietExtendedItem)">Ta bort</button>
	</mat-card-actions>
</mat-card>
