import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ProductStore } from '../stores/product/product.store.service';
import { ProductPriceStore } from '../stores/product/product-price.store.service';
import { CurrencyPipe } from '@angular/common';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'productPriceDate' })
export class ProductPriceDatePipe implements PipeTransform, OnDestroy {
	constructor(
		private productPriceStore: ProductPriceStore,
		private currencyPipe: CurrencyPipe,
	) {	}
	public transform(productId: number, customerTypeId: number, date: Date, time: string): Observable<string> {
		if (productId === 0) {
			return of('Ingen vald');
		}
		if (!productId) {
			return;
		}
		return this.productPriceStore.getOnProductAndCustomerTypeAndDateTime(productId, customerTypeId, date, time)
			.pipe(switchMap(type => {
				let price = 'Org.pris ' + this.currencyPipe.transform(type.price, 'SEK', 'code');
				if (!price) {
					price = 'Saknas';
				}
				return of(price);
			}));
	}

	ngOnDestroy(): void { }
}
