<app-blade-item [canIClose]="true" [loading]="loading" *ngIf="guideTourService.tourBehavior$ | async as tour">
	<app-blade-header [title]="tour?.name || (tour?.productId | productName | async)" [subTitle]="'Tur'"></app-blade-header>
	<ng-container *ngIf="tour?.id">
		<app-blade-content>
			<app-blade-content-tabs>
				<!-- <app-blade-content-tab [title]="'Sammanfattning'" [template]="summary">
					<ng-template #summary>
						<app-guide-tour-summary></app-guide-tour-summary>
					</ng-template>
				</app-blade-content-tab> -->
				<app-blade-content-tab [title]="'Hämtningar'" [template]="pickup">
					<ng-template #pickup>
						<app-guide-tour-pickup></app-guide-tour-pickup>
					</ng-template>
				</app-blade-content-tab>
				<app-blade-content-tab [title]="'Kunder'" [template]="customers">
					<ng-template #customers>
						<app-guide-tour-customers></app-guide-tour-customers>
					</ng-template>
				</app-blade-content-tab>
				<app-blade-content-tab [title]="'Tillägg'" [template]="addon">
					<ng-template #addon>
						<app-guide-tour-addons></app-guide-tour-addons>
					</ng-template>
				</app-blade-content-tab>
				<app-blade-content-tab [title]="'Internmeddelande'" [template]="messages">
					<ng-template #messages>
						<app-tour-messages></app-tour-messages>
					</ng-template>
				</app-blade-content-tab>
				<app-blade-content-tab [title]="'Resurser'" [template]="resource">
					<ng-template #resource>
						<app-tour-resource [tour]="tour"></app-tour-resource>
					</ng-template>
				</app-blade-content-tab>
			</app-blade-content-tabs>
		</app-blade-content>
	</ng-container>
</app-blade-item>
