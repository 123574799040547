export enum PageTypeEnum {
	Product = 'product_page',
	Article = 'article_page',
	Footer = 'site_footer',
	Cart = 'cart_page',
	Confirmation = 'confirmation_page',
	Payment = 'payment',
	PaymentTerms = 'payment_terms',
	Profile = 'profile_page',
	ProfileSubPage = 'login',
	Login = 'profile_subpage',
	Category = 'category_page',
}
