
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ProductSirvoy } from 'app/models/product/product-Sirvoy.model';
import { BaseStore } from '../base.store.service';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ProductSirvoyStore extends BaseStore<ProductSirvoy> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductSirvoy', baseUrl, snackBarService);
	}

	public getAllOnProduct(productId: number) {
		this._items = [];
		this._itemsSubject.next(this._items);
		this.http.get<ProductSirvoy[]>(`${this.host}/product/${productId}`).subscribe(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		});
	}
}
