<div class="container py-3" *ngIf="{
	tours: extendedTours$ | async,
	settings: siteSettings$ | async,
	supplier: supplier$ | async,
	showCustomerInfo: showCustomerInfo$ | async
} as source">
	<mat-card>
		<div class="align-items-center d-flex justify-content-between mb-3 pb-3 border-bottom">
			<img class="logo" [src]="source.settings?.logo">
			<div>
				<p class="title">Turer program</p>
				<p class="customer">{{source.supplier?.name}}</p>
			</div>
		</div>
		<mat-card *ngFor="let tour of source.tours" class="mb-3">
			<mat-card-title class="d-flex justify-content-between">
				<span>{{tour.name || (tour.id | tourName | async)}}</span>
				<span *ngIf="tour.tourStatusId === 3">Avbruten</span>
			</mat-card-title>
			<mat-card-subtitle>{{tour.date | date:'yyyy-MM-dd'}} {{tour.time}}</mat-card-subtitle>
			<div class="ml-3 mb-3">
				<div *ngFor="let summary of tour.summaries">
					Hämtas: <b>{{summary.pickupLocationId | pickupLocationName | async}}</b> Tid: <b>{{summary.relativeTime}}</b>. Antal: <b>{{summary.quantity}}st {{summary.customerTypeId | customerTypeName | async}}</b>
				</div>
			</div>
			<div class="ml-3 mb-3" *ngIf="source.showCustomerInfo">
				Kunder
				<div *ngFor="let summary of tour.tourBookings">
					<b>{{summary.customerId | customerName | async}}</b>
				</div>
			</div>
			<p *ngIf="tour.message" [innerHtml]="tour.message"></p>
		</mat-card>
	</mat-card>
</div>
