
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { PickupLocation } from 'app/models/page-location.model';
import { SnackBarService } from '../services/snack-bar.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PickupLocationStore extends BaseStore<PickupLocation> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'PickupLocation', baseUrl, snackBarService);
	}

	public getFull(id: number): Observable<PickupLocation> {
		return this.http.get<PickupLocation>(`${this.host}/${id}/full`);
	}
}
