<mat-card>
	<mat-card-title>Generellt</mat-card-title>
	<form [formGroup]="termTypeService.itemForm">
		<mat-form-field class="w-100">
			<input matInput formControlName="name" placeholder="Namn"(keyup)="termTypeService.createIdentifier()">
		</mat-form-field>
		<mat-form-field class="w-100">
			<input matInput formControlName="identifier" placeholder="Identifier">
		</mat-form-field>
	</form>
</mat-card>

<div class="mt-3 text-right">
	<button mat-stroked-button color="accent" (click)="termTypeService.save()">
		Spara
	</button>
</div>
