<mat-card *ngIf="{
	adminText: 'blades.tour' | adminText | async
} as source">
	<mat-card-title>{{source.adminText?.tabs.guides.title}}</mat-card-title>
	<mat-card>
		<table mat-table [dataSource]="dataSource" matSort class="w-100">
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.tabs.guides.table.name}}</th>
				<td mat-cell *matCellDef="let item">{{item.userId | adminUserName | async}}</td>
			</ng-container>
			<ng-container matColumnDef="type">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.tabs.guides.table.type}}</th>
				<td mat-cell *matCellDef="let item">{{item.tourGuideTypeId | tourGuideTypeName | async}}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
		</table>
		<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	</mat-card>
	<mat-card-actions>
		<span fxFlex></span>
		<button mat-stroked-button color="accent" (click)="createNew()">{{source.adminText?.tabs.guides.createNew}}</button>
	</mat-card-actions>
</mat-card>
