import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { ProductDateTime } from 'app/models/product/product-date-time.model';
import { ProductDateTimeStore } from 'app/admin/stores/product/product-date-time.store.service';
import { ProductDateTimeCustomerTypeStore } from 'app/admin/stores/product/product-date-time-customer-type.store.service';
import { ProductDateTimeCustomerType } from 'app/models/product/product-date-time-customer-type.model';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { ProductDateTimeCustomerTypeBladeComponent } from 'app/admin/blades/poduct-date-time-customer-type/product-date-time-customer-type-blade/product-date-time-customer-type-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-product-date-time-blade-customer-type',
	templateUrl: './product-date-time-blade-customer-type.component.html',
	styleUrls: ['./product-date-time-blade-customer-type.component.scss'],
})
export class ProductDateTimeBladeCustomerTypeComponent implements OnDestroy {
	@Input() set productDateTimeId(id: number) {
		this.productDateTimeCustomerStore.getAllOnProductDateTime(id);
	}
	public displayedColumns: string[] = ['id', 'type', 'price', 'actions'];
	public productDateTimeCustomerTypes: MatTableDataSource<ProductDateTimeCustomerType>;
	public itemForm: FormGroup = new FormGroup({});

	// public roductDateTimeCustomerTypes: ProductDateTimeCustomerType[];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private productDateTimeCustomerStore: ProductDateTimeCustomerTypeStore,
		public customerTypeStore: CustomerTypeStore,
		private productDateTimeCustomerTypeStore: ProductDateTimeCustomerTypeStore,
		) {
			if (this.bladeItem.id) {
				this.productDateTimeCustomerStore.getAllOnProductDateTime(this.bladeItem.id).subscribe();
				this.productDateTimeCustomerStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
					this.productDateTimeCustomerTypes = new MatTableDataSource<ProductDateTimeCustomerType>(items);
				});
			}
			this.customerTypeStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
				_.each(items, item => {
					this.itemForm.addControl(item.identifier, new FormControl(''));
				});
			});
		}

		ngOnDestroy(): void { }

		public deleteCustomerType(productDateTimeCustomerType: ProductDateTimeCustomerType) {
			this.productDateTimeCustomerTypeStore.delete(productDateTimeCustomerType.id).subscribe();
		}

		public selectCustomerType(productDateTimeCustomerType: ProductDateTimeCustomerType) {
			this.bladeService.addBladeItem({
				id: 0,
				component: ProductDateTimeCustomerTypeBladeComponent,
				minClass: BladeSize.xs,
				payload: { productDateTimeId: this.bladeItem.id },
			}, this.bladeItem);
		}

		public addCustomerType() {
			this.bladeService.addBladeItem({
				id: 0,
				component: ProductDateTimeCustomerTypeBladeComponent,
				minClass: BladeSize.xs,
				payload: { productDateTimeId: this.bladeItem.id },
			}, this.bladeItem);
		}
	}
