import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Order } from 'app/models/order.model';
import { OrderItemStore } from 'app/admin/stores/order-item.store.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { OrderItemBladeComponent } from '../../../order-item-blade/order-item-blade.component';
import { OrderItem } from 'app/models/order-item.model';
import { ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
	selector: 'app-order-items',
	templateUrl: './order-items.component.html',
	styleUrls: ['./order-items.component.scss'],
})
export class OrderItemsComponent implements OnInit {
	public moment = moment;
	public totalAmountReplay$ = new ReplaySubject<number>();

	public displayedColumns: string[] = ['name', 'customerType', 'quantity', 'grossTotalAmount', 'totalPaid'];
	public dataSource: MatTableDataSource<OrderItem>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public orderItemStore: OrderItemStore,
		private bladeService: BladeService,
		private cdr: ChangeDetectorRef,
	) {
		orderItemStore.items$.subscribe(orderItems => {
			this.totalAmountReplay$.next(_.sumBy(orderItems, x => x.grossTotalAmount));
			this.dataSource = new MatTableDataSource<OrderItem>(orderItems);
			this.dataSource.sort = this.sort;
			cdr.markForCheck();
		});
		orderItemStore.getAllOnOrder(bladeItem.id).subscribe();
	}

	ngOnInit() {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: OrderItemBladeComponent,
			minClass: BladeSize.s,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: OrderItemBladeComponent,
			minClass: BladeSize.s,
			payload: {
				orderId: this.bladeItem.id,
			},
		}, this.bladeItem);
	}
}
