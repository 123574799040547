import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { UserStore } from '../stores/user.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'adminUserName' })
export class AdminUserNamePipe implements PipeTransform, OnDestroy {
	constructor(private userStore: UserStore) {	}
	public transform(customerId: number): Observable<string> {
		if (!customerId || customerId < 1) {
			return;
		}
		return this.userStore.pipeGetName(customerId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
