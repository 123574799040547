<mat-card>
	<mat-card-title>Texter</mat-card-title>
	<mat-card-subtitle>Texter som visas på produktens presentationssida. En rad per språk.</mat-card-subtitle>

	<div class="mat-elevation-z1">
		<table mat-table [dataSource]="dataSource" matSort class="w-100">
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
				<td mat-cell *matCellDef="let item">{{item.id || 'Saknas'}}</td>
			</ng-container>
			<ng-container matColumnDef="headline">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Rubrik</th>
				<td mat-cell *matCellDef="let item"><strong>{{item.headline}}</strong><br /><em>{{item.identifier}}</em></td>
			</ng-container>
			<ng-container matColumnDef="created">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
				<td mat-cell *matCellDef="let item">{{item.created|date:'yyyy-MM-dd HH:mm'}}<br />{{item.createdBy}}</td>
			</ng-container>
			<ng-container matColumnDef="modified">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Modifierad</th>
				<td mat-cell *matCellDef="let item">{{item.modified|date:'yyyy-MM-dd HH:mm'}}<br />{{item.modifiedBy}}</td>
			</ng-container>
			<ng-container matColumnDef="language">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Språk</th>
				<td mat-cell *matCellDef="let item">{{item.language}}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.payload?.languageId == item?.languageId"></tr>
		</table>

		<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	</div>
</mat-card>
