import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { TravelAgency } from 'app/models/travel-agency.model';
import { SnackBarService } from '../services/snack-bar.service';
import { Observable, of, ReplaySubject } from 'rxjs';
import { User } from '../models/user.model';
import { EntityName } from 'app/models/entity-name.model';

@Injectable({
	providedIn: 'root',
})
export class TravelAgencyStore extends BaseStore<TravelAgency> {
	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TravelAgency', baseUrl, snackBarService);
	}

	public pipeGetNameOnCustomer(id: number): Observable<EntityName> {
		const findObj = _.find(this.entityPipeNames, type => type.id === id);
		if (findObj) {
			return of(findObj);
		} else {
			return this.preGetNameOnCustomer(id);
		}
	}

	private preGetNameOnCustomer(id: number): Observable<EntityName> {
		if (!this.entityPipeNameObservables[id]) {
			this.entityPipeNameObservables[id] = new ReplaySubject<EntityName>(1);
			this.getNameOnCustomer(id);
		}
		return this.entityPipeNameObservables[id];
	}

	private getNameOnCustomer(id: number): void {
		this.http.get<EntityName>(`${this.host}/customer/${id}/fullname`).subscribe(customerName => {
			if (!customerName) {
				return;
			}
			const findObj = _.find(this.entityPipeNames, type => type.id === customerName.id);
			if (!findObj) {
				this.entityPipeNames.push(customerName);
			}
			this.entityPipeNameObservables[id].next(customerName);
		});
	}
}
