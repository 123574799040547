import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { BaseStore } from './base.store.service';
import { CustomerTypeText } from 'app/models/customer-type-text.model';

@Injectable({
	providedIn: 'root',
})
export class CustomerTypeTextStore extends BaseStore<CustomerTypeText> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'CustomerTypeText', baseUrl, snackBarService);
	}

	public getAllOnCustomerType(customerTypeId: number): Observable<CustomerTypeText[]> {
		return this.http.get<CustomerTypeText[]>(`${this.host}/customerType/${customerTypeId}`).pipe(tap(customerTypeTexts => {
			this._items = customerTypeTexts;
			this._itemsSubject.next(this._items);
		}));
	}

	public getOnCustomerTypeAndLanguage(customerTypeId: number, languageId: number): Observable<CustomerTypeText> {
		return this.http.get<CustomerTypeText>(`${this.host}/customerType/${customerTypeId}/language/${languageId}`).pipe(tap(customerTypeText => {
			if (customerTypeText) {
				const findObj = _.find(this._items, type => type.customerTypeId === customerTypeText.customerTypeId
									&& type.languageId === customerTypeText.languageId);
				_.assign(findObj, customerTypeText); // Keep object in sync
			}
		}));
	}

	public addCustomerTypeText(customerTypeText: CustomerTypeText) {
		this._items.push(customerTypeText);
		this._itemsSubject.next(this._items);
	}

	public removeCustomerTypeText(customerTypeText: CustomerTypeText) {
		_.remove(this._items, customerTypeText);
		this._itemsSubject.next(this._items);
	}
}
