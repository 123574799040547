<ng-container *ngIf="{
	adminText: 'widget.tourBookings' | adminText | async
} as source">
	<table mat-table [dataSource]="dataSource" matSort class="w-100">
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
			<td mat-cell *matCellDef="let item">{{item.id}} </td>
		</ng-container>
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.customer}}</th>
			<td mat-cell *matCellDef="let item">{{item.customerId | customerName | async}}</td>
		</ng-container>
		<ng-container matColumnDef="source">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.source}}</th>
			<td mat-cell *matCellDef="let item">{{item.sourceTypeId | sourceTypeName | async}}</td>
		</ng-container>
		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.status}}</th>
			<td mat-cell *matCellDef="let item" [class.text-danger]="item.bookingStatusId === 1" [class.text-success]="item.bookingStatusId === 2">{{item.bookingStatusId | bookingStatusName | async}}</td>
		</ng-container>
		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.created}}</th>
			<td mat-cell *matCellDef="let item">{{item.created | date:'yyyy-MM-dd HH:mm'}}</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)"></tr>
	</table>
</ng-container>
