<ng-container *ngIf="{
	adminText: 'blades.tour.tabs.summary' | adminText | async
} as source">
	<ng-container *ngIf="customerTypeWrappersReplay | async | keyvalue as customers">
		<ng-container *ngIf="customers.length">
			<div class="d-flex justify-content-between">
				<span></span>
				<div>
					<button mat-icon-button (click)="accordion.openAll()">
						<mat-icon>unfold_more</mat-icon>
					</button>
					<button mat-icon-button (click)="accordion.closeAll()">
						<mat-icon>unfold_less</mat-icon>
					</button>
				</div>
			</div>

			<mat-accordion multi>
				<mat-expansion-panel expanded *ngFor="let customer of customers">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<app-open-customer-blade [customerId]="+customer.key"></app-open-customer-blade>
						</mat-panel-title>
						<mat-panel-description>
							<span *ngIf="+customer.key | travelAgencyNameOnCustomer | async as travelAgancyName">{{source.adminText?.customerType.travelAgency}} {{travelAgancyName}}</span>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<mat-card>
						<mat-card-title class="card-title-small align-items-center d-flex justify-content-between">
							<span>{{source.adminText?.customerType.amount}}</span>
							<!-- <button mat-icon-button (click)="openEditCustomerType(customer.value[0].tourBookingId)">
								<mat-icon>edit</mat-icon>
							</button> -->
						</mat-card-title>
						<mat-table [dataSource]="customer.value" class="w-100">
							<ng-container matColumnDef="customerType">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.customerType.table.type}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.customerTypeId | customerTypeName | async}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="quantity">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.customerType.table.amount}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.quantity}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="birthDate">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.customerType.table.birthDate}}</mat-header-cell>
								<mat-cell *matCellDef="let item" class="d-block">
									<span class="d-block" *ngFor="let age of item.tourBookingCustomerTypeAges; let last = last; let i = index;">
										{{item.customerTypeId | customerTypeName | async}} {{i + 1}}: {{age.birthDate | date:'yyyy-MM-dd'}} ({{moment().diff(age.birthDate, 'years', false)}}år)
										<span *ngIf="!last">, </span>
									</span>
								</mat-cell>
							</ng-container>
							<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
							<mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer"></mat-row>
						</mat-table>
					</mat-card>

					<mat-card class="mt-3" *ngIf="customer.value[0]?.tourBooking?.tourBookingPickupLocations?.length">
						<mat-card-title class="card-title-small align-items-center d-flex justify-content-between">
							<span>{{source.adminText?.pickuplocation.title}}</span>
							<!-- <button mat-icon-button (click)="openEditPickup(customer.value[0].tourBookingId)">
								<mat-icon>edit</mat-icon>
							</button> -->
						</mat-card-title>

						<mat-table [dataSource]="customer.value[0].tourBooking.tourBookingPickupLocations" class="w-100">
							<ng-container matColumnDef="customerType">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.pickuplocation.table.type}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.customerTypeId | customerTypeName | async}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="name">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.pickuplocation.table.place}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.pickupLocationId | pickupLocationName | async}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="quantity">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.pickuplocation.table.amount}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.quantity}}</mat-cell>
							</ng-container>
							<mat-header-row *matHeaderRowDef="displayedPickupLocationColumns"></mat-header-row>
							<mat-row *matRowDef="let item; columns: displayedPickupLocationColumns;" class="pointer"></mat-row>
						</mat-table>
					</mat-card>

					<mat-card class="mt-3" *ngIf="customer.value[0]?.tourBooking?.tourBookingAddons?.length">
						<mat-card-title class="card-title-small align-items-center d-flex justify-content-between">
							<span>{{source.adminText?.addon.title}}</span>
							<!-- <button mat-icon-button (click)="openEditAddon(customer.value[0].tourBookingId)">
								<mat-icon>edit</mat-icon>
							</button> -->
						</mat-card-title>

						<mat-table [dataSource]="customer.value[0].tourBooking.tourBookingAddons" class="w-100">
							<ng-container matColumnDef="name">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.addon.table.name}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.addonId | addonName | async}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="quantity">
								<mat-header-cell *matHeaderCellDef>{{source.adminText?.addon.table.amount}}</mat-header-cell>
								<mat-cell *matCellDef="let item">{{item.quantity}}</mat-cell>
							</ng-container>
							<mat-header-row *matHeaderRowDef="displayedAddonColumns"></mat-header-row>
							<mat-row *matRowDef="let item; columns: displayedAddonColumns;" class="pointer"></mat-row>
						</mat-table>
					</mat-card>

					<mat-card class="mt-3">
						<mat-card-title class="card-title-small align-items-center d-flex justify-content-between">
							<span>{{source.adminText?.message.title}}</span>
							<!-- <button mat-icon-button (click)="openEditMessage(customer.value[0].tourBookingId)">
								<mat-icon>edit</mat-icon>
							</button> -->
						</mat-card-title>
						<p *ngIf="!customer.value[0]?.tourBooking?.tourBookingMessages?.length">{{source.adminText?.message.noMessage}}</p>
						<p *ngIf="customer.value[0]?.tourBooking?.tourBookingMessages?.length" [innerHtml]="customer.value[0].tourBooking.tourBookingMessages[0]?.message | newLine"></p>
					</mat-card>

					<mat-card class="mt-3" *ngIf="customer.value[0] | paymentBalance | async as payment">
						<mat-card-title class="card-title-small">{{source.adminText?.paymentBalance.title}}</mat-card-title>
						<p>{{source.adminText?.paymentBalance.total}} <b>{{payment?.paymentBalance?.totalOrder | currency:'SEK':'code'}}</b></p>
						<p>{{source.adminText?.paymentBalance.payed}} <b>{{payment?.paymentBalance?.totalPayment | currency:'SEK':'code'}}</b></p>
						<p>
							Balans -
							<span *ngIf="payment.balance > 0">{{source.adminText?.paymentBalance.pay}} </span>
							<span *ngIf="payment.balance < 0">{{source.adminText?.paymentBalance.getBack}} </span>
							<b>
								<span
									[class.text-success]="!payment.balance"
									[class.text-danger]="payment.balance > 0"
									[class.text-warning]="payment.balance < 0">
									{{payment.balanceStr}}
								</span>
							</b>
						</p>
					</mat-card>

					<app-tour-summary-special-diet *ngIf="customer?.value[0]?.tourBookingId" [tourBookingId]="customer.value[0].tourBookingId" class="d-block mt-3"></app-tour-summary-special-diet>
				</mat-expansion-panel>
			</mat-accordion>
		</ng-container>
	</ng-container>
</ng-container>
