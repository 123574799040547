
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { SupplierResourceTour } from 'app/models/supplier-resource-tour.model';

@Injectable({
	providedIn: 'root',
})
export class SupplierResourceTourStore extends BaseStore<SupplierResourceTour> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SupplierResourceTour', baseUrl, snackBarService);
	}

	public getAllOnTour(tourId: number) {
		this.http.get<SupplierResourceTour[]>(`${this.host}/tour/${tourId}`).subscribe(supplierResources => {
			this._items = supplierResources;
			this._itemsSubject.next(this._items);
		});
	}
}
