import { BaseModel } from '../base.model';

export class ProductText extends BaseModel {
	public productId: number;
	public languageId: number;
	public language: string;
	public productTextTypeId: number;
	public headline: string;
	public information: string;
	public description: string;
	public emailDescription: string;
	public seoTitle: string;
	public seoDescription: string;
}
