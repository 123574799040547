<app-blade-item [canIClose]="true">
	<app-blade-header [title]="'Välj produkt'"></app-blade-header>
	<app-blade-content>
		<p>Välj nedan produkt som ska kopplas.</p>

		<mat-form-field class="w-100">
			<input matInput [(ngModel)]="searchWord" placeholder="Sök">
		</mat-form-field>

		<mat-card class="mb-2" *ngFor="let product of productStore.items$ | async | search:'name':searchWord">
			<div class="d-flex align-items-center">
				<p class="flex-grow-1 mb-0">{{product.name}}</p>
				<button mat-flat-button color="primary" (click)="select(product)">Välj</button>
			</div>
		</mat-card>

	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-flat-button color="primary" (click)="selectNone()">Välj ingen (Välj senare)</button>
	</app-blade-footer>
</app-blade-item>
