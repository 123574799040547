
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { CategoryElement } from 'app/models/category-element.model';

@Injectable({
	providedIn: 'root',
})
export class CategoryElementStore extends BaseStore<CategoryElement> {
	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'CategoryElement', baseUrl, snackBarService);
	}

	public getOnElement(elementId: number): Observable<CategoryElement> {
		return this.http.get<CategoryElement>(`${this.host}/element/${elementId}`);
	}
}
