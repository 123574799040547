import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { NavItem } from 'app/admin/models/nav-item.model';
import { MenuService } from 'app/admin/services/menu.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-menu-list-item',
	templateUrl: './menu-list-item.component.html',
	styleUrls: ['./menu-list-item.component.scss'],
	animations: [
		trigger('indicatorRotate', [
			state('collapsed', style({ transform: 'rotate(0deg)' })),
			state('expanded', style({ transform: 'rotate(180deg)' })),
			transition('expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4,0.0,0.2,1)'),
			),
		]),
	],
})
export class MenuListItemComponent implements OnInit {
	public expanded = false;
	@HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
	@Input() item: NavItem;
	@Input() depth: number;
	@Input() isExpanded: boolean;

	constructor(
		public menuService: MenuService,
		public router: Router,
		private activatedRoute: ActivatedRoute) {
		if (this.depth === undefined) {
			this.depth = 0;
		}
	}

	ngOnInit() {
		this.menuService.currentUrl.pipe(untilDestroyed(this)).subscribe((url: string) => {
			if (this.item.route && url) {
				this.expanded = url.indexOf(`/${this.item.routeMatch}`) === 0;
				this.ariaExpanded = this.expanded;
			}
		});
	}

	onItemSelected(item: NavItem) {
		if (!item.preventGoToRoute && item.routeMatch) {
			this.router.navigate([item.routeMatch]);
		}

		if (item.children && item.children.length) {
			this.expanded = !this.expanded;
		}
	}

	public expandSelect($event: MouseEvent, item) {
		$event.preventDefault();
		$event.stopImmediatePropagation();
		if (item.children && item.children.length) {
			this.expanded = !this.expanded;
		}
	}
}
