import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MediaStore } from 'app/admin/stores/media.store.service';
import { Media } from 'app/models/media.model';
import { MediaTypeStore } from 'app/admin/stores/media-type.store.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UploadMedia } from 'app/models/upload-media.model';

@Component({
	selector: 'app-upload-media-blade',
	templateUrl: './upload-media-blade.component.html',
	styleUrls: ['./upload-media-blade.component.scss'],
})
@IBladeComponent.register
export class UploadMediaBladeComponent implements OnInit {
	public uploadMedia: UploadMedia[];
	public displayedColumns: string[] = ['media', 'name', 'done'];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: MediaStore,
		public mediaTypeStore: MediaTypeStore,
		public bladeService: BladeService,
		private mediaStore: MediaStore,
	) {
	}

	ngOnInit() {}

	public uploadFile(fileElem) {
		if (fileElem.files.length === 0) {
		  return;
		}

		this.uploadMedia = [];

		_.each(fileElem.files, file => {
			const uploadFile = new UploadMedia();
			uploadFile.name = file.name;
			uploadFile.mediaType = file.type.split('/')[0];
			uploadFile.MIMEType = file.type;

			const reader = new FileReader();
			reader.onload = e => uploadFile.path = reader.result + '';
			reader.readAsDataURL(file);

			this.uploadMedia.push(uploadFile);

			this.mediaStore.uploade(uploadFile, file);
		});
	}
}
