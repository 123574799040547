import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { UserStore } from 'app/admin/stores/user.store.service';
import { Tour } from '../../../../models/tour.model';
import { TourBladeComponent } from '../../tour/tour-blade/tour-blade.component';
import { User } from 'app/admin/models/user.model';
import { UserBladeComponent } from '../../user/user-blade/user-blade.component';
import { TourGuideStore } from 'app/admin/stores/tour-guide.store.service';
import { FormControl, FormGroup } from '@angular/forms';

@UntilDestroy()
@Component({
	selector: 'app-planner-guides-tours-blade',
	templateUrl: './planner-guides-tours-blade.component.html',
	styleUrls: ['./planner-guides-tours-blade.component.scss'],
})
@IBladeComponent.register
export class PlannerGuidesToursBladeComponent implements OnInit, OnDestroy {
	public searchFormGroup: FormGroup = new FormGroup({
		start: new FormControl(moment()),
		end: new FormControl(moment().add(10, 'days'), []),
	});

	public tours$ = new ReplaySubject<Tour[]>(1);
	public isDone$ = new BehaviorSubject<boolean>(false);
	private baseDisplayedColumns: string[] = ['firstName', 'time'];
	public displayedColumns: string[] = [];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public tourStore: TourStore,
		public userStore: UserStore,
		private bladeService: BladeService,
		private tourGuideStore: TourGuideStore,
	) {
		this.userStore.getAll();
		this.tourStore.reset();
		this.getTours();

		this.tourGuideStore.changedItem$.pipe(
			untilDestroyed(this),
		).subscribe(changedItem => {
			this.getTours();
		});

		this.tourStore.changedItem$.pipe(
			untilDestroyed(this),
		).subscribe(changedItem => {
			this.getTours();
		});
	}

	public getTours(): void {
		this.tourStore.getAllBetweenDates(
			this.searchFormGroup.value.start.format('YYYY-MM-DD'),
			this.searchFormGroup.value.end?.format('YYYY-MM-DD'))
		.subscribe(tours => {
			this.setTours(tours);
		});
	}

	private setTours(tours: Tour[]) {
		this.isDone$.next(false);
		this.displayedColumns = [...this.baseDisplayedColumns];
		_.orderBy(tours, ['date', 'time'], ['asc', 'asc']).forEach(tour => this.displayedColumns.push('tour-' + tour.id));
		setTimeout(() => {
			this.isDone$.next(true);
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public openTour(tour: Tour) {
		this.bladeService.addBladeItem({
			id: tour.id,
			component: TourBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
			menuIndex: 2,
		}, this.bladeItem);
	}

	public openUser(user: User) {
		this.bladeService.addBladeItem({
			id: user.id,
			component: UserBladeComponent,
			minClass: BladeSize.s,
			menuIndex: 2,
		}, this.bladeItem);
	}

}
