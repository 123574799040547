import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { SupplierResourceTypeStore } from '../stores/supplier-resource-type.store.service';
import { SupplierResourceStore } from '../stores/supplier-resource.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'supplierResourceTypeNameFromSupplierResource' })
export class SupplierResourceTypeNameFromSupplierResourcePipe implements PipeTransform, OnDestroy {
	constructor(
		private supplierResourceTypeStore: SupplierResourceTypeStore,
		private supplierResourceStore: SupplierResourceStore,
	) {	}
	public transform(supplierResourceId: number): Observable<string> {
		if (supplierResourceId === 0) {
			return of('Ingen vald');
		}
		if (!supplierResourceId) {
			return;
		}
		return this.supplierResourceStore.get(supplierResourceId).pipe(switchMap(supplierResource => {
			return this.supplierResourceTypeStore.pipeGet(supplierResource.supplierResourceTypeId).pipe(map(type => type.name));
		}));
	}

	ngOnDestroy(): void { }
}
