import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { MinFunctionStore } from 'app/admin/stores/product-function/min-function.store.service';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { CustomerType } from 'app/models/customer-type.model';
import { Language } from 'app/models/language.model';
import { MinFunctionCustomerType } from 'app/models/product-function/min-function-customer-type.model';
import { MinFunctionErrorMessage } from 'app/models/product-function/min-function-error-message.model';
import { MinFunction } from 'app/models/product-function/min-function.model';
import { ProductFunction } from 'app/models/product-function/product-function.model';
import { Product } from 'app/models/product/product.model';
import { combineLatest, Observable } from 'rxjs';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@UntilDestroy()
@Injectable()
export class MinFunctionService {
	private languages: Language[];
	public item = new MinFunction();
	public itemReplay$ = new BehaviorSubject<MinFunction>(this.item);

	private customerTypes: CustomerType[];
	private selectedCustomerTypes: number[] = [];
	public selectedCustomerTypes$ = new ReplaySubject<number[]>(1);


	private products: Product[];
	private selectedProducts: number[] = [];
	public selectedProducts$ = new ReplaySubject<number[]>(1);

	private allErrorMessages: MinFunctionErrorMessage[];
	public errorMessagesReplay$ = new ReplaySubject<MinFunctionErrorMessage[]>(1);

	public loadingReplay$ = new BehaviorSubject<boolean>(true);

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		functionTypeId: new FormControl(2),
		minAmount: new FormControl(''),
		onlyOnEmptyTours: new FormControl(''),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private minFunctionStore: MinFunctionStore,
		public languageStore: LanguageStore,
		public customerTypeStore: CustomerTypeStore,
		private productStore: ProductStore,
	) {

		this.productStore.getAll();

		this.customerTypeStore.getAll();
		this.customerTypeStore.items$.pipe(untilDestroyed(this)).subscribe(customerTypes => this.customerTypes = customerTypes);

		if (bladeItem.id) {
			combineLatest([
				this.languageStore.items$,
				this.productStore.items$,
				this.minFunctionStore.get(bladeItem.id),
			])
			.pipe(untilDestroyed(this))
			.subscribe(([languages, products, item]) => {
				this.languages = languages;
				this.products = products;
				this.item = item;
				this.itemReplay$.next(this.item);
				this.itemForm.patchValue(item);
				this.loadingReplay$.next(false);

				if (this.item.customerTypes) {
					this.setCustomerTypes(this.item.customerTypes.map(x => x.customerTypeId));
				}

				if (this.item.products) {
					this.setProducts(this.item.products.map(x => x.productId));
				}
				this.handleErrorMessage(this.item.errorMessages);
			});
		} else {
			combineLatest([
				this.productStore.items$,
				this.languageStore.items$,
			])
			.pipe(untilDestroyed(this))
			.subscribe(([products, languages]) => {
				this.products = products;
				this.languages = languages;
				this.handleErrorMessage([]);
				this.loadingReplay$.next(false);
			});
		}

	}

	public save() {
		this.loadingReplay$.next(true);
		this.item = Object.assign(this.item, this.itemForm.value);
		this.itemReplay$.next(this.item);

		this.preSavehandleCustomerTypes();
		this.preSavehandleProducts();
		this.item.errorMessages = this.allErrorMessages;

		this.saveOrUpdate().subscribe(item => {
			this.item = Object.assign(this.item, item);
			this.loadingReplay$.next(false);
		});
	}

	public toggleCustomerType(customerTypeId: number) {
		if (this.selectedCustomerTypes.includes(customerTypeId)) {
			this.setCustomerTypes(this.selectedCustomerTypes.filter(x => x !== customerTypeId));
		} else {
			this.setCustomerTypes([...this.selectedCustomerTypes, customerTypeId]);
		}
	}

	private setCustomerTypes(customerTypes: number[]) {
		this.selectedCustomerTypes = customerTypes;
		this.selectedCustomerTypes$.next(this.selectedCustomerTypes);
	}

	public toggleProduct(productId: number) {
		if (this.selectedProducts.includes(productId)) {
			this.setProducts(this.selectedProducts.filter(x => x !== productId));
		} else {
			this.setProducts([...this.selectedProducts, productId]);
		}
	}

	private setProducts(products: number[]) {
		this.selectedProducts = products;
		this.selectedProducts$.next(this.selectedProducts);
	}

	private saveOrUpdate(): Observable<MinFunction> {
		if (this.item.id > 0) {
			return this.minFunctionStore.update(this.item);
		} else {
			return this.minFunctionStore.create(this.item);
		}
	}

	private handleErrorMessage(currentErrorMessages: MinFunctionErrorMessage[]) {
		this.allErrorMessages = [];
		this.languages.forEach(language => {
			const foundErrorMessage = currentErrorMessages.find(x => x.languageId === language.id);
			if (foundErrorMessage) {
				this.allErrorMessages.push(foundErrorMessage);
			} else {
				const errorMessage = new MinFunctionErrorMessage();
				errorMessage.languageId = language.id;
				errorMessage.minFunctionId = this.item.id;
				this.allErrorMessages.push(errorMessage);
			}
		});
		this.errorMessagesReplay$.next(this.allErrorMessages);
	}

	private preSavehandleCustomerTypes() {
		if (!this.item.customerTypes) {
			this.item.customerTypes = [];
		}

		this.customerTypes.forEach(customerType => {
			const isSelected = this.selectedCustomerTypes.find(x => x === customerType.id);
			const isOnItem = this.item.customerTypes.find(x => x.customerTypeId === customerType.id);

			if (isSelected && !isOnItem) {
				const newCustomerType = new MinFunctionCustomerType();
				newCustomerType.customerTypeId = customerType.id;
				this.item.customerTypes.push(newCustomerType);
			}
		});
		this.item.customerTypes = this.item.customerTypes.filter(x => this.selectedCustomerTypes.includes(x.customerTypeId));
	}

	private preSavehandleProducts() {
		if (!this.item.products) {
			this.item.products = [];
		}

		this.products.forEach(product => {
			const isSelected = this.selectedProducts.find(x => x === product.id);
			const isOnItem = this.item.products.find(x => x.productId === product.id);

			if (isSelected && !isOnItem) {
				const newProduct = new ProductFunction();
				newProduct.productId = product.id;
				this.item.products.push(newProduct);
			}
		});
		this.item.products = this.item.products.filter(x => this.selectedProducts.includes(x.productId));
	}
}
