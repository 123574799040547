import { Injectable, Inject, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourBooking } from 'app/models/tour-booking.model';

@UntilDestroy()
@Injectable()
export class TourSummaryService implements OnDestroy {
	private tourBookingsBehavior = new ReplaySubject<TourBooking[]>(1);
	public tourBookingsBehavior$ = this.tourBookingsBehavior.asObservable();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public tourBookingStore: TourBookingStore,
	) {
		if (bladeItem.id) {
			this.tourBookingStore.getAllOnTour(bladeItem.id).pipe(untilDestroyed(this)).subscribe(tourBookings => {
				this.tourBookingsBehavior.next(tourBookings);
			});
		}
	}

	ngOnDestroy(): void { }
}
