import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormGroup, FormControl } from '@angular/forms';

import { GridsterConfig, GridType, DisplayGrid } from 'angular-gridster2';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DashboardService } from './services/dashboard.service';
import { DashboardStore } from './store/dashboard.store.service';
import { Dashboard } from 'app/models/dashboard.model';
import { DashboardWidgetTypeStore } from 'app/admin/stores/dashboard-widget-type.store.service';
import { DashboardWidgetType } from 'app/models/dashboard-widget-type.model';
import { DashboardWidgetStore } from './store/dashboard-widget.store.service';

@UntilDestroy()
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	providers: [
		DashboardService,
		DashboardStore,
		DashboardWidgetStore,
	],
})
export class DashboardComponent implements OnInit, OnDestroy {
	public selectedDashboard: Dashboard;

	public formGroup: FormGroup = new FormGroup({
		selectedDashboard: new FormControl(''),
		name: new FormControl(''),
		isActive: new FormControl(''),
		isGlobal: new FormControl(''),
	});

	public isEditMode: boolean;
	public isChangeName: boolean;
	public options: GridsterConfig;

	public isHandset$: Observable<boolean> = this.breakpointObserver
		.observe(Breakpoints.Handset)
		.pipe(map(result => result.matches));

	constructor(
		private breakpointObserver: BreakpointObserver,
		public dashboardService: DashboardService,
		public dashboardWidgetTypeStore: DashboardWidgetTypeStore,
	) {
		dashboardService.selectedDashboardReplay$.pipe(untilDestroyed(this)).subscribe(dashbord => {
			this.selectedDashboard = dashbord;
			this.formGroup = new FormGroup({
				selectedDashboard: new FormControl(this.selectedDashboard),
				name: new FormControl(this.selectedDashboard ? this.selectedDashboard.name : ''),
				isActive: new FormControl(this.selectedDashboard ? this.selectedDashboard.isActive : ''),
				isGlobal: new FormControl(this.selectedDashboard ? this.selectedDashboard.isGlobal : ''),
			});

			this.formGroup.get('isActive').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
				this.dashboardService.setIsActive(value);
			});
			this.formGroup.get('isGlobal').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
				this.dashboardService.setIsGlobal(value);
			});
		});
	}

	ngOnInit() {
		this.options = {
			gridType: GridType.Fixed,
			fixedColWidth: 100,
			fixedRowHeight: 100,
			itemChangeCallback: (item, itemComponent) => { this.itemChange(item, itemComponent); },
			displayGrid: DisplayGrid.None,
			mobileBreakpoint: 300,
			draggable: {
				enabled: false,
				ignoreContent: true,
				dragHandleClass: 'dashboard-card',
			},
			resizable: {
				enabled: false,
			},
		};

		this.isHandset$.pipe(untilDestroyed(this)).subscribe(isHandset => {
			if (isHandset) {
				this.options.fixedColWidth = 70;
				this.options.fixedRowHeight = 70;
			} else {
				this.options.fixedColWidth = 100;
				this.options.fixedRowHeight = 100;
			}
		});
	}

	ngOnDestroy() {	}

	public selectDashboard(dashboard: Dashboard) {
		this.dashboardService.selectDashboard(dashboard);
	}

	public newDashboard() {
		this.dashboardService.addNewDashboard().pipe(untilDestroyed(this)).subscribe(newDashboard => {
			this.setEditMode(true);
			this.changeName();
		});

	}

	public editMode() {
		this.setEditMode(true);
	}

	public stopEditMode() {
		this.setEditMode(false);
	}

	private setEditMode(isEdit: boolean) {
		this.isEditMode = isEdit;
		this.options.displayGrid = this.isEditMode ? DisplayGrid.Always : DisplayGrid.None;
		this.options.draggable.enabled = this.isEditMode;
		this.options.resizable.enabled = this.isEditMode;
		this.changedOptions();
	}
	public changeName() {
		this.isChangeName = true;
	}

	public saveName() {
		this.isChangeName = false;
		this.dashboardService.setName(this.formGroup.get('name').value);
		this.dashboardService.saveDashboard().subscribe();
	}

	public delete() {
		this.isChangeName = false;
		this.dashboardService.deleteActiveDashboard();
		this.setEditMode(false);
	}

	public addWidget(widgetType: DashboardWidgetType) {
		this.dashboardService.addDashboardWidget(widgetType);
	}
	public itemChange(item, itemComponent) {
		this.dashboardService.saveDashboardWidget(item).subscribe();
	}

	private changedOptions() {
		this.options.api.optionsChanged();
	}
}
