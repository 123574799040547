<app-blade-item [canIClose]="true">
	<app-blade-header [title]="'Inställningar'"></app-blade-header>
	<app-blade-content>
		<app-blade-content-tabs>
			<app-blade-content-tab *hasRoles="['settings-generally', 'admin']" [title]="'Generellt'" [iconName]="'home'" [template]="generally">
				<ng-template #generally>
					<app-settings-generally></app-settings-generally>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-language', 'admin']" [title]="'Språk'" [iconName]="'language'" [template]="language">
				<ng-template #language>
					<app-settings-language></app-settings-language>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-user-type']" [title]="'Admin användartyper'" [iconName]="'fa-user-tag'" [iconSet]="'fas'" [iconCustom]="true" [template]="userType">
				<ng-template #userType>
					<app-settings-user-type></app-settings-user-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-email', 'admin']" [title]="'Email'" [iconName]="'email'" [template]="email">
				<ng-template #email>
					<app-settings-email></app-settings-email>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-email', 'admin']" [title]="'Email template'" [iconName]="'subject'" [template]="emailTemplate">
				<ng-template #emailTemplate>
					<app-settings-email-templates></app-settings-email-templates>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-booking-source']" [title]="'Bokningskällor'" [iconName]="'fa-sourcetree'" [iconSet]="'fab'" [iconCustom]="true" [template]="bookingSource">
				<ng-template #bookingSource>
					<app-settings-booking-source></app-settings-booking-source>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-customer-type', 'admin']" [title]="'Kundtyper'" [iconName]="'supervised_user_circle'" [template]="customerType">
				<ng-template #customerType>
					<app-settings-customer-type></app-settings-customer-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-element-type']" [title]="'Elementtyper'" [iconName]="'code'" [template]="elementType">
				<ng-template #elementType>
					<app-settings-element-type></app-settings-element-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-column-link-type']" [title]="'Kolumn länk typer'" [iconName]="'code'" [template]="columnLinkType">
				<ng-template #columnLinkType>
					<app-settings-element-link-type></app-settings-element-link-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-payment-type']" [title]="'Betalningstyper'" [iconName]="'code'" [template]="paymentType">
				<ng-template #paymentType>
					<app-settings-payment-type></app-settings-payment-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-dashboard-widget-type']" [title]="'Dashboard widget typer'" [iconName]="'code'" [template]="dashboardWidgetType">
				<ng-template #dashboardWidgetType>
					<app-settings-dashboard-widget-type></app-settings-dashboard-widget-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-page-type']" [title]="'Sidtyper'" [iconName]="'insert_drive_file'" [template]="pageType">
				<ng-template #pageType>
					<app-settings-page-type></app-settings-page-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-product-type']" [title]="'Produkttyper'" [iconName]="'fa-box-open'" [iconSet]="'fas'" [iconCustom]="true" [template]="productType">
				<ng-template #productType>
					<app-settings-product-type></app-settings-product-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-product-image-type']" [title]="'Produktbildtyper'" [iconName]="'burst_mode'" [template]="productImageType">
				<ng-template #productImageType>
					<app-settings-product-image-type></app-settings-product-image-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-product-text-type']" [title]="'Produkttexttyper'" [iconName]="'text_format'" [template]="productTextType">
				<ng-template #productTextType>
					<app-settings-product-text-type></app-settings-product-text-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-category-image-type']" [title]="'Kategorybildtyper'" [iconName]="'burst_mode'" [template]="categoryImageType">
				<ng-template #categoryImageType>
					<app-settings-category-image-type></app-settings-category-image-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-tour-guide-type', 'admin']" [title]="'Guidetyper'" [iconName]="'fa-user-astronaut'" [iconSet]="'fas'" [iconCustom]="true" [template]="tourGuideType">
				<ng-template #tourGuideType>
					<app-settings-tour-guide-type></app-settings-tour-guide-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-supplier-resource-type', 'admin']" [title]="'Resurser'" [iconName]="'fa-boxes'" [iconSet]="'fas'" [iconCustom]="true" [template]="supplierResourceType">
				<ng-template #supplierResourceType>
					<app-settings-supplier-resource-type></app-settings-supplier-resource-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-supplier-resource-product-type']" [title]="'Resurshantering'" [iconName]="'fa-hand-holding-heart'" [iconSet]="'fas'" [iconCustom]="true" [template]="supplierResourceProductType">
				<ng-template #supplierResourceProductType>
					<app-settings-supplier-resource-product-type></app-settings-supplier-resource-product-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-payment', 'admin']" [title]="'Betallösning'" [iconName]="'fa-money-check-alt'" [iconSet]="'fas'" [iconCustom]="true" [template]="payment">
				<ng-template #payment>
					<app-settings-payment></app-settings-payment>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-site-data', 'admin']" [title]="'SiteData'" [iconName]="'wysiwyg'" [template]="siteData">
				<ng-template #siteData>
					<app-settings-site-data></app-settings-site-data>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-term-type', 'admin']" [title]="'Villkorstyper'" [iconName]="'gavel'" [template]="termType">
				<ng-template #termType>
					<app-settings-term-type></app-settings-term-type>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['user-service']" [title]="'Behörigheter'">
				saknas
			</app-blade-content-tab>
			<app-blade-content-tab *hasRoles="['settings-trashcan', 'admin']" [title]="'Papperskorg'" [iconName]="'delete'" [template]="trashcan">
				<ng-template #trashcan>
					<app-settings-trashcan></app-settings-trashcan>
				</ng-template>
			</app-blade-content-tab>
		</app-blade-content-tabs>
	</app-blade-content>
</app-blade-item>
