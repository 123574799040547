
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { PageStyle } from 'app/models/page-style.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class PageStyleStore extends BaseStore<PageStyle> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'PageStyle', baseUrl, snackBarService);
	}

	public getOnPage(pageId: number): Observable<PageStyle> {
		return this.http.get<PageStyle>(`${this.host}/page/${pageId}`);
	}

	public addDelete(categoryId: number, PageStylees: PageStyle[], deleteIds: number[]): Observable<void> {
		const addDeleteObj = {
			PageStylees,
			deleteIds,
		};
		return this.http.post<void>(`${this.host}/addDelete/${categoryId}`, addDeleteObj);
	}
}
