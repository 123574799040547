<app-blade-item [canIClose]="true" [loading]="loading">
	<app-blade-header [title]="item?.name||'Ny'" [subTitle]="'Leverantör'" [menuItems]="headerMenuItems"></app-blade-header>

	<app-blade-content *ngIf="item?.id" [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
		<ng-container [ngSwitch]="selectedIndex">
			<ng-container *ngSwitchCase="1">
				<app-supplier-blade-generally [item]="item"></app-supplier-blade-generally>
			</ng-container>
			<ng-container *ngSwitchCase="2">
				<app-supplier-blade-resources [item]="item"></app-supplier-blade-resources>
			</ng-container>
			<ng-container *ngSwitchCase="3">
				<app-supplier-blade-tours></app-supplier-blade-tours>
			</ng-container>
			<ng-container *ngSwitchCase="4">
				<app-supplier-tour-program></app-supplier-tour-program>
			</ng-container>
		</ng-container>
	</app-blade-content>
	<app-blade-content *ngIf="!item?.id">
		<app-supplier-blade-generally [item]="item"></app-supplier-blade-generally>
	</app-blade-content>
	<ng-container [ngSwitch]="selectedIndex">
		<app-blade-footer *ngSwitchCase="1">
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="save()">
				<span *ngIf="item?.id">Spara</span>
				<span *ngIf="!item?.id">Skapa</span>
			</button>
		</app-blade-footer>
		<app-blade-footer *ngSwitchCase="2">
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="addNewSupplierResource()">Lägg till ny</button>
		</app-blade-footer>
	</ng-container>
</app-blade-item>
