import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';

import { LanguageStore } from 'app/admin/stores/language.store.service';

@Component({
	selector: 'app-shared-customer-program',
	templateUrl: './customer-program.component.html',
	styleUrls: ['./customer-program.component.scss'],
})
export class CustomerProgramComponent implements OnInit {
	@Input() customerId: number;
	public itemForm: FormGroup = new FormGroup({
		languageId: new FormControl('', [Validators.required]),
		fromDate: new FormControl('', []),
		toDate: new FormControl('', []),
	});

	constructor(
		public languageStore: LanguageStore,
	) {}

	ngOnInit() { }

	public showProgram() {
		const languageId = this.itemForm.value.languageId;
		let query = `/customers/program?hideMenuSideNav=true&languageId=${languageId}&customerId=${this.customerId}`;

		if (this.itemForm.value.fromDate && this.itemForm.value.toDate) {
			const fromDate = moment(this.itemForm.value.fromDate).format('YYYY-MM-DD');
			const toDate = moment(this.itemForm.value.toDate).format('YYYY-MM-DD');
			query = `${query}&fromDate=${fromDate}&toDate=${toDate}`;
		}

		window.open(query, '_blank');
	}

	public clearDate() {
		this.itemForm.get('fromDate').setValue(null);
		this.itemForm.get('toDate').setValue(null);
	}
}
