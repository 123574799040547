import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { Language } from 'app/models/language.model';
import { Element } from 'app/models/element.model';
import { VideoElement } from 'app/models/video-element.model';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VideoElementStore } from 'app/admin/stores/VideoElement.store.service';
import { MediaStore } from 'app/admin/stores/media.store.service';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { take } from 'rxjs/operators';
import { MediaBladeComponent } from 'app/admin/blades/media/media-blade/media-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { Media } from 'app/models/media.model';
import * as _ from 'lodash';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { ElementStore } from 'app/admin/stores/element.store.service';

@UntilDestroy()
@Component({
  selector: 'app-element-video-blade',
  templateUrl: './element-video.blade.html',
  styleUrls: ['./element-video.blade.scss'],
})
@IBladeComponent.register
export class ElementVideoBlade implements OnInit, OnDestroy {
	public saving: Subject<boolean> = new Subject<boolean>();
	public selectedIndex = 0;
	public loading = true;
	public canIClose: Function | boolean;
	public languages: Language[];
	public element: Element;
	public menuItems: BladeMenuItem[] = [];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];
	public activeItem: VideoElement;
	private activeLanguage: Language;
	public allItems: VideoElement[];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public videoElementStore: VideoElementStore,
		public languageStore: LanguageStore,
		private elementStore: ElementStore,
	) {
		this.bladeItem.callback = (items) => { this.useFiles(items); };
	}

	ngOnInit() {
		this.videoElementStore.allOnElement$.pipe(untilDestroyed(this)).pipe(untilDestroyed(this)).subscribe(allItems => {
			this.loading = false;
			this.allItems = allItems;
			this.setActiveItem();
		});
		this.languageStore.items$.pipe(take(1)).pipe(untilDestroyed(this)).subscribe(languages => {
			this.videoElementStore.getAllOnElement(this.bladeItem.id, languages).subscribe();
		});
	}

	ngOnDestroy() { }

	public selectVideo() {
		this.bladeService.addBladeItem({
			id: 0,
			component: MediaBladeComponent,
			minClass: BladeSize.m,
			payload: {
				mediaTypes: 'video',
			},
		}, this.bladeItem);
	}

	public menuChange(index: number) {
		this.activeLanguage = this.languages[index - 1];
		this.setActiveItem();
	}

	private useFiles(items: Media[]) {
		console.log('--------------------useFiles-------------------------', items);
		_.each(this.allItems, item => {
			item.mediaId = items[0].id;
		});
	}

	private setActiveItem() {
		if (this.activeLanguage && this.allItems) {
			this.activeItem = _.find(this.allItems, item => item.languageId === this.activeLanguage.id);
		}
	}

	public save() {
		if (this.selectedIndex === 0 || this.selectedIndex === 99) {
			this.saving.next(true);
			return;
		}

		this.loading = true;

		_.each(this.allItems, item => {
			if (item.id) {
				this.videoElementStore.update(item).subscribe(updatedItem => {
					this.loading = false;
					item = _.assign(item, updatedItem);
				});
			} else {
				this.videoElementStore.create(item).subscribe(updatedItem => {
					this.loading = false;
					item = _.assign(item, updatedItem);
				});
			}
		});
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		_.each(this.allItems, item => {
			this.elementStore.delete(item.elementId).subscribe(isDeleted => {
				this.loading = false;
				if (isDeleted) {
					this.bladeItem.bladeItemComponent.closeMe();
				}
			});
		});
	}
}
