import { Component, Inject, Input, } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourResourceService } from 'app/admin/components/shared/tour-resource/tour-resource.service';
import { combineLatest, ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { Tour } from 'app/models/tour.model';
import { User } from 'app/admin/models/user.model';
import { TourGuideTypeStore } from 'app/admin/stores/tour-guide-type.store.service';
import { take } from 'rxjs/operators';
import { TourGuideStore } from '../../../../../stores/tour-guide.store.service';
import { TourGuide } from 'app/models/tour-guide.model';

@UntilDestroy()
@Component({
	selector: 'app-planner-guides-tours-guide-item',
	templateUrl: './planner-guides-tours-guide-item.component.html',
	styleUrls: ['./planner-guides-tours-guide-item.component.scss'],
	providers: [
		TourResourceService,
	],
})
export class PlannerGuidesToursGuideItemComponent {
	private selectedTourGuide: TourGuide;
	private user$ = new ReplaySubject<User>();
	private tour$ = new ReplaySubject<Tour>();

	@Input() set user(user: User) {
		this.user$.next(user);
	}
	@Input() set tour(tour: Tour) {
		this.tour$.next(tour);
	}

	public guideTypeForm: FormGroup = new FormGroup({
		guideType: new FormControl(-1),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public tourGuideTypeStore: TourGuideTypeStore,
		private tourGuideStore: TourGuideStore,
	) {
		combineLatest([
			this.user$,
			this.tour$,
		]).pipe(
			untilDestroyed(this),
			take(1),
		)
		.subscribe(([user, tour]) => {
			this.selectedTourGuide = _.find(tour?.tourGuides, tg => tg.userId === user?.id);
			this.guideTypeForm.get('guideType').setValue(this.selectedTourGuide?.tourGuideTypeId || -1);
			this.guideTypeForm.get('guideType').valueChanges.pipe(untilDestroyed(this)).subscribe(selectedGuideTypeId => {
				if (selectedGuideTypeId > 0) {
					// Create or Update
					if (!this.selectedTourGuide) {
						this.selectedTourGuide = new TourGuide();
						this.selectedTourGuide.tourId = tour.id;
						this.selectedTourGuide.userId = user.id;
					}
					this.selectedTourGuide.tourGuideTypeId = selectedGuideTypeId;
					if (this.selectedTourGuide.id) {
						this.tourGuideStore.update(this.selectedTourGuide).subscribe(updated => this.selectedTourGuide = updated);
					} else {
						this.tourGuideStore.create(this.selectedTourGuide).subscribe(created => this.selectedTourGuide = created);
					}
				} else if (this.selectedTourGuide.id > 0) {
					// Delete
					this.tourGuideStore.delete(this.selectedTourGuide.id).subscribe(deleted => (deleted) ? this.selectedTourGuide = undefined : null);
				}
			});
		});
	}
}
