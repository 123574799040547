<h4>Eventuella prisskillnader</h4>

<app-alert-card [mode]="'info'">Lorem ipsum dolor sit amet consectetur adipisicing elit.</app-alert-card>

<p *ngIf="!productDateTimeCustomerTypes?.data?.length">Det finns inga prisskillnader!</p>

<mat-table [dataSource]="productDateTimeCustomerTypes" class="w-100" *ngIf="productDateTimeCustomerTypes?.data?.length">
	<ng-container matColumnDef="id">
		<mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
		<mat-cell *matCellDef="let item">{{item.id || 'Ny'}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="type">
		<mat-header-cell *matHeaderCellDef>Typ</mat-header-cell>
		<mat-cell *matCellDef="let item">
			{{item.customerTypeId | customerTypeName | async}}
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="price">
		<mat-header-cell *matHeaderCellDef>Pris</mat-header-cell>
		<mat-cell *matCellDef="let item">
			{{item.price}}
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="actions">
		<mat-header-cell *matHeaderCellDef></mat-header-cell>
		<mat-cell *matCellDef="let item" class="d-block text-right">
			<button mat-icon-button color="warn" (click)="$event.stopPropagation(); deleteCustomerType(item)">
				<mat-icon>delete_forever</mat-icon>
			</button>
		</mat-cell>
	</ng-container>
	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectCustomerType(item)"></mat-row>
</mat-table>

<button mat-flat-button [color]="'primary'" (click)="addCustomerType()" class="float-right mt-2">
	Lägg till prisskillnad
</button>
