<mat-card>
	<mat-card-title>Generellt</mat-card-title>
	<form [formGroup]="termBladeService.itemForm">
		<mat-form-field class="w-100">
			<input matInput formControlName="name" placeholder="Namn">
		</mat-form-field>
		<mat-form-field class="w-100">
			<mat-label>Välj Vilkorstyp</mat-label>
			<mat-select formControlName="termTypeId">
				<mat-option *ngFor="let type of (termTypeStore.items$|async)" [value]="type.id">
					{{type.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</form>
</mat-card>

<div class="mt-3 text-right">
	<button mat-stroked-button color="accent" (click)="termBladeService.save()">
		Spara
	</button>
</div>
