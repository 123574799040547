import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TourBookingPickupLocation } from 'app/models/tour-booking-pickup-location.model';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { TourBookingPickupLocationService } from '../../../services/tour-booking-pickup-location.service';

@UntilDestroy()
@Injectable()
export class TourBookingPickupLocationItemService {
	private tourBookingPickupLocation: TourBookingPickupLocation;
	private tourBookingPickupLocationReplay = new ReplaySubject<TourBookingPickupLocation>(1);
	public tourBookingPickupLocationReplay$ = this.tourBookingPickupLocationReplay.asObservable();

	public itemForm: FormGroup = new FormGroup({
		customerTypeId: new FormControl('', [Validators.required]),
		pickupLocationId: new FormControl('', [Validators.required]),
		quantity: new FormControl('', [Validators.required]),
	});

  	constructor(
		public tourBookingPickupLocationService: TourBookingPickupLocationService,
	  ) {
		this.itemForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			Object.assign(this.tourBookingPickupLocation, value);
		});
	}

	public setTourBookingPickupLocationItem(tourBookingPickupLocation: TourBookingPickupLocation) {
		this.tourBookingPickupLocation = tourBookingPickupLocation;

		this.itemForm.patchValue(this.tourBookingPickupLocation);
		this.tourBookingPickupLocationReplay.next(this.tourBookingPickupLocation);
	}
}
