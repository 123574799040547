import { SupplierResource } from 'app/models/supplier-resource.model';
import { Supplier } from 'app/models/supplier.model';
import { Tour } from 'app/models/tour.model';

export class PlannerResourceGroup {
	public supplier: Supplier;
	public supplierResources: SupplierResource[];
	public date: Date;
	public tours: Tour[];
}
