<app-blade-item [canIClose]="true">
   	<app-blade-header [title]="'Betalningar'"></app-blade-header>
	<app-blade-content>
		<mat-card>
			<table mat-table [dataSource]="dataSource" matSort class="w-100">
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
					<td mat-cell *matCellDef="let item">{{item.id}} </td>
				</ng-container>
				<ng-container matColumnDef="paymentTypeId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Betalning</th>
					<td mat-cell *matCellDef="let item">{{item.paymentTypeId | paymentTypeName | async}}</td>
				</ng-container>
				<ng-container matColumnDef="paymentReference">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Betalreferens</th>
					<td mat-cell *matCellDef="let item">{{item.paymentReference}}</td>
				</ng-container>
				<ng-container matColumnDef="amount">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Belopp</th>
					<td mat-cell *matCellDef="let item">{{item.amount | currency:'SEK':'code'}}</td>
				</ng-container>
				<ng-container matColumnDef="message">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Meddelande</th>
					<td mat-cell *matCellDef="let item">{{item.message}}</td>
				</ng-container>
				<ng-container matColumnDef="sourceTypeId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Källa</th>
					<td mat-cell *matCellDef="let item">{{item.sourceTypeId | sourceTypeName | async}}</td>
				</ng-container>
				<ng-container matColumnDef="created">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
					<td mat-cell *matCellDef="let item">{{item.created|date:'yyyy-MM-dd HH:mm'}}<br />{{item.createdBy}}</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
			</table>

			<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</mat-card>
	</app-blade-content>
</app-blade-item>
