import { Component, Inject, OnDestroy, OnInit, Input } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of, combineLatest } from 'rxjs';
import * as _ from 'lodash';

import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { TourBookingSpecialDietStore } from 'app/admin/stores/tour-booking-special-diet.store.service';
import { TourSummaryService } from '../../services/tour-summary.service';
import { DialogEditService } from 'app/modules/dialog-edit/services/dialog-edit.service';
import { TourBookingSpecialDietMessageStore } from 'app/admin/stores/tour-booking-special-diet-message.store.service';
import { TourBookingSpecialDietMessage } from 'app/models/tour-booking-special-diet-message.model';
import { TourBookingSpecialDiet } from 'app/models/tour-booking-special-diet.model';
import { switchMap } from 'rxjs/operators';
import { CustomerTypeStore } from '../../../../../../../stores/customer-type.store.service';

@UntilDestroy()
@Component({
	selector: 'app-tour-summary-special-diet',
	templateUrl: './tour-summary-special-diet.component.html',
	styleUrls: ['./tour-summary-special-diet.component.scss'],
})
export class TourSummarySpecialDietComponent implements OnInit, OnDestroy {
	@Input() tourBookingId: number;
	public specialDietsReplay$ = new Observable<TourBookingSpecialDietExtended[]>();
	public specialDietMessagesReplay$ = new Observable<TourBookingSpecialDietMessage[]>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private tourBookingSpecialDietStore: TourBookingSpecialDietStore,
		private tourBookingSpecialDietMessageStore: TourBookingSpecialDietMessageStore,
		private tourSummaryService: TourSummaryService,
		public dialogEditService: DialogEditService,
		private customerTypeStore: CustomerTypeStore,
	) { }

	ngOnInit(): void {
		this.specialDietMessagesReplay$ = this.tourBookingSpecialDietMessageStore.getAllOnTourBooking$(this.tourBookingId);

		this.specialDietsReplay$ = combineLatest([
				this.tourBookingSpecialDietStore.getAllOnTourBooking$(this.tourBookingId),
				this.customerTypeStore.items$,
			])
			.pipe(switchMap(([items, customerTypes]) => {
				const list: TourBookingSpecialDietExtended[] = [];

				_.forOwn(_.groupBy(items, x => x.groupName), (value, key, object) => {
					const customerType = _.find(customerTypes, c => c.identifier === key.split('_')[0]);
					list.push({
						customerTypeId: customerType.id,
						tourBookingSpecialDiets: value,
					});
				});
				return of(list);
			}));
	}

	ngOnDestroy() { }

	public openEditSpecialDiet(tourBookingId: number): void {
		this.dialogEditService.editTourBookingSpecialDietLocation(tourBookingId).subscribe(done => {
			this.tourSummaryService.getAllOnTour();
		});
	}
}

interface TourBookingSpecialDietExtended {
	customerTypeId: number;
	tourBookingSpecialDiets: TourBookingSpecialDiet[];
}
