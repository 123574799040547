import { Component, Inject } from '@angular/core';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingMessageStore } from 'app/admin/stores/tour-booking-message.store.service';
import { TourBookingMessage } from 'app/models/tour-booking-message.model';
import { ReplaySubject } from 'rxjs';

@Component({
	selector: 'app-tour-booking-messages',
	templateUrl: './tour-booking-messages.component.html',
	styleUrls: ['./tour-booking-messages.component.scss'],
})
export class TourBookingMessagesComponent {
	public tourBookingMessages$ = new ReplaySubject<TourBookingMessage[]>(1);

	constructor(
		private tourBpookingMessageStore: TourBookingMessageStore,
		private bladeService: BladeService,
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
	) {
		this.tourBpookingMessageStore.getAllOnTourBooking(this.bladeItem.id).subscribe(tourBookingMessages => {
			this.tourBookingMessages$.next(tourBookingMessages);
		});
	}
}
