
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { AddonText } from 'app/models/addon-text.model';
import { SnackBarService } from '../services/snack-bar.service';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class AddonTextStore extends BaseStore<AddonText> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'AddonText', baseUrl, snackBarService);
	}

	public getAllOnAddon(addonId: number): Observable<AddonText[]> {
		return this.http.get<AddonText[]>(`${this.host}/addon/${addonId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getOnAddonAndLanguage(addonId: number, languageId: number): Observable<AddonText> {
		return this.http.get<AddonText>(`${this.host}/addon/${addonId}/language/${languageId}`).pipe(tap(addonText => {
			if (addonText) {
				const findObj = _.find(this._items, type => type.addonId === addonText.addonId
									&& type.languageId === addonText.languageId);
				_.assign(findObj, addonText); // Keep object in sync
			}
		}));
	}

	public addAddonText(addonText: AddonText) {
		this._items.push(addonText);
		this._itemsSubject.next(this._items);
	}

	public removeAddonText(addonText: AddonText) {
		_.remove(this._items, addonText);
		this._itemsSubject.next(this._items);
	}
}
