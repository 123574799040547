import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { ColumnStore } from 'app/admin/stores/column.store.service';
import { Column } from 'app/models/site/column.model';
import { Language } from 'app/models/language.model';
import { HeadlineElement } from 'app/models/headline-element.model';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HeadlineElementStore } from 'app/admin/stores/headline-element.store.service';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { take } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { Element } from 'app/models/element.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { ElementStore } from 'app/admin/stores/element.store.service';
import { SlideshowImage } from 'app/models/slideshow-image.model';
import { SlideshowImageStore } from 'app/admin/stores/slideshow-image.store.service';
import { SlideshowItem } from 'app/models/slideshow-item.model';
import { SlideshowItemStore } from 'app/admin/stores/slideshow-item.store.service';
import { Media } from 'app/models/media.model';
import { MediaBladeComponent } from '../../media/media-blade/media-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { CategoryStore } from 'app/admin/stores/category/category.store.service';
import { SlideshowCategoryStore } from 'app/admin/stores/slideshow-category.store.service';
import { Category } from 'app/models/category/category.model';
import { SelectionModel } from '@angular/cdk/collections';
import { SlideshowCategory } from 'app/models/slideshow-category.model';

@UntilDestroy()
@Component({
	selector: 'app-slideshow-category-blade',
	templateUrl: './slideshow-category-blade.component.html',
	styleUrls: ['./slideshow-category-blade.component.scss'],
})
@IBladeComponent.register
export class SlideshowCategoryBladeComponent implements OnInit, OnDestroy {
	private saveSubject: Subject<void>;
	private categories: Category[];
	public displayedColumns: string[] = ['select', 'id', 'name'];
	public dataSource: MatTableDataSource<Category>;
	public selection: SelectionModel<Category>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public loading = true;
	public canIClose: Function | boolean;
	// public slideshowItem: SlideshowItem;
	// public menuItems: BladeMenuItem[] = [];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];
	public activeItem: SlideshowCategory = new SlideshowCategory();
	// private activeLanguage: Language;
	// public allItems: SlideshowImage[];
	// public itemForm: FormGroup = new FormGroup({
	// 	headline: new FormControl(''),
	// 	description: new FormControl(''),
	// });

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public slideshowCategoryStore: SlideshowCategoryStore,
		public categoryStore: CategoryStore,
		private slideshowItemStore: SlideshowItemStore,
	) {
		this.activeItem.slideshowItemId = this.bladeItem.id;
		this.categoryStore.getAll();
		this.categoryStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.categories = items;
			this.dataSource = new MatTableDataSource<Category>(this.categories);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.setActiveItem();
		});
	}

	ngOnInit() {
		// this.languageStore.items$.subscribe(languages => {
		// 	this.languages = languages;
		// 	_.each(this.languages, (lang, index) => {
		// 		this.menuItems.push({
		// 			index,
		// 			title: lang.name,
		// 		});
		// 	});
		// 	this.activeLanguage = this.languages[0];
			this.slideshowCategoryStore.GetOnSlideshowItem(this.bladeItem.id).subscribe(item => {
				this.loading = false;
				if (item) {
					this.activeItem = item;
					this.setActiveItem();
				} else {
					this.selection = new SelectionModel<Category>();
				}
			});
			// this.slideshowItemStore.get(this.bladeItem.id).subscribe(element => {
			// 	this.slideshowItem = element;
			// });
		// });

		// this.slideshowImageStore.allOnElement$.pipe(untilDestroyed(this)).subscribe(allItems => {
		// 	this.loading = false;
		// 	this.allItems = allItems;
		// 	this.setActiveItem();
		// });

		// this.itemForm.get('headline').valueChanges.subscribe(value => {
		// 	this.activeItem.headline = value;
		// });
		// this.itemForm.get('description').valueChanges.subscribe(value => {
		// 	this.activeItem.description = value;
		// });
	}

	ngOnDestroy() { }

	public selectCategory(category: Category) {
		if (!this.selection.isSelected(category)) {
			this.selection.clear();
		}
		this.selection.toggle(category);
	}

	// public menuChange(index: number) {
	// 	this.activeLanguage = this.languages[index];
	// 	this.setActiveItem();
	// }

	// private setActiveItem() {
	// 	if (this.activeLanguage && this.allItems && this.allItems.length) {
	// 		this.activeItem = _.find(this.allItems, item => item.languageId === this.activeLanguage.id);
	// 		this.itemForm.get('headline').setValue(this.activeItem.headline || '');
	// 		this.itemForm.get('description').setValue(this.activeItem.description || '');
	// 	}
	// }

	public save() {
		if (this.selection.selected.length) {
			this.loading = true;
			this.activeItem.categoryId = this.selection.selected[0].id;
			if (this.activeItem.id) {
				this.slideshowCategoryStore.update(this.activeItem).subscribe(updatedItem => {
					this.loading = false;
					this.activeItem = _.assign(this.activeItem, updatedItem);
				});
			} else {
				this.slideshowCategoryStore.create(this.activeItem).subscribe(updatedItem => {
					this.loading = false;
					this.activeItem = _.assign(this.activeItem, updatedItem);
				});
			}
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.slideshowCategoryStore.delete(this.activeItem.id).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}

	private setActiveItem() {
		if (this.categories && this.activeItem) {
			const find = _.find(this.categories, cat => cat.id === this.activeItem.categoryId);
			if (find) {
				this.selection = new SelectionModel<Category>(true, [find]);
			}
		}
	}
}
