import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-systems',
	templateUrl: './systems.component.html',
	styleUrls: ['./systems.component.scss'],
})
export class SystemsPageComponent implements OnInit {

	constructor() { }

	ngOnInit() { }

}
