<mat-card class="mt-4 p-0" *ngIf="{
	specialDietOnTour: specialDietOnTour$ | async
} as source">
	<h2 class="p-3">Lista på turer</h2>
	<mat-slide-toggle class="p-3" [(ngModel)]="showMessages">Visa meddelanden</mat-slide-toggle>
	<table mat-table [dataSource]="source.specialDietOnTour?.tours" class="w-100">
		<ng-container matColumnDef="tourName">
			<th mat-header-cell *matHeaderCellDef>Tur</th>
			<td mat-cell *matCellDef="let item">
				{{item.id | tourName | async}}<br>{{item.date | date:'yyyy-MM-dd'}} {{item.time}}
			</td>
			<td mat-footer-cell *matFooterCellDef class="text-right">
				<span class="mr-1">Totalt:</span>
			</td>
		</ng-container>
		<ng-container matColumnDef="specialDiet-{{specialDiet.id}}" *ngFor="let specialDiet of source.specialDietOnTour?.specialDiets">
			<th mat-header-cell *matHeaderCellDef class="special-diet-header">
				{{specialDiet.id | specialDietName | async}}
			</th>
			<td mat-cell *matCellDef="let item">
				<div class="d-flex align-items-center">
					<span class="d-block">{{item.specialDiets[specialDiet.id]?.amount || 0}}st</span>
					<div class="ml-2" *ngIf="showMessages">
						<p class="m-0" [class.border-top]="!first" *ngFor="let message of item.specialDiets[specialDiet.id]?.messages; let first = first;" [innerHtml]="message | newLine"></p>
					</div>
				</div>
			</td>
			<td mat-footer-cell *matFooterCellDef>{{specialDiet.total}}st</td>
		</ng-container>
		<ng-container matColumnDef="messages">
			<th mat-header-cell *matHeaderCellDef class="work-time">Meddelanden</th>
			<td mat-cell *matCellDef="let item">
				<ng-container *ngIf="showMessages">
					<p class="m-0" [class.border-top]="!first" *ngFor="let message of item.messages; let first = first;" [innerHtml]="message | newLine"></p>
				</ng-container>
			</td>
			<td mat-footer-cell *matFooterCellDef></td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
		<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	</table>
</mat-card>