import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';

@Component({
	selector: 'app-supplier-tour-program',
	templateUrl: './supplier-tour-program.component.html',
	styleUrls: ['./supplier-tour-program.component.scss'],
})
export class SupplierTourProgramComponent implements OnInit {
	public itemForm: FormGroup = new FormGroup({
		fromDate: new FormControl('', [Validators.required]),
		toDate: new FormControl('', [Validators.required]),
		showCustomerInfo: new FormControl(false),
		showCanceledTours: new FormControl(false),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
	) {}

	ngOnInit() {
	}

	public showProgram() {
		const fromDate = moment(this.itemForm.value.fromDate).format('YYYY-MM-DD');
		const toDate = moment(this.itemForm.value.toDate).format('YYYY-MM-DD');
		const supplierId = this.bladeItem.id;
		let query = `?hideMenuSideNav=true&supplierId=${supplierId}&fromDate=${fromDate}&toDate=${toDate}`;
		if (this.itemForm.value.showCustomerInfo) {
			query += '&showCustomerInfo=true'
		}
		if (this.itemForm.value.showCanceledTours) {
			query += '&showCanceledTours=true'
		}
		window.open(`/suppliers/tour-program${query}`, '_blank');
	}
}
