<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem?.id">
	<app-blade-header [title]="'Text'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content *ngIf="languages" [menuItems]="menuItems" (indexChange)="selectedIndex = $event; menuChange($event)">
		<mat-card>
			<app-element-blade-generally *ngIf="selectedIndex === 0 && element" [element]="element" [saving]="saving" [menuItems]="menuItems"></app-element-blade-generally>

			<div *ngIf="activeItem && selectedIndex !== 99 && selectedIndex > 0">
				<app-alert-card [mode]="'info'" [label]="'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quibusdam sapiente cupiditate aspernatur?'"></app-alert-card>
				<div *ngIf="activeItem">
					<app-text-editor *ngIf="showEditor" [size]="'small'" [label]="'Text'" [textModel]="itemForm?.get('text')"></app-text-editor>
				</div>
			</div>
			<app-element-blade-styles *ngIf="activeItem && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="activeItem?.id">Spara</span>
			<span *ngIf="!activeItem?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
