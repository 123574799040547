import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseStore } from 'app/admin/stores/base.store.service';
import { SiteTemplate } from '../models/site-template.model';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable()
export class SiteTemplateStore extends BaseStore<SiteTemplate> {

	constructor(
		protected http: HttpClient,
		@Inject('BASE_URL') baseUrl: string,
		protected snackBarService: SnackBarService,
	) {
		super(http, 'SiteTemplate', baseUrl, snackBarService);
		this.getAll();
	}
}
