import { NgModule } from '@angular/core';

import { FilterPipe } from './filter.pipe';
import { CustomerTypeNamePipe } from './customer-type-name.pipe';
import { ElementTypeNamePipe } from './element-type-name.pipe';
import { PageTypeNamePipe } from './page-type-name.pipe';
import { ProductTypeNamePipe } from './product-type-name.pipe';
import { BookingStatusNamePipe } from './booking-status-name.pipe';
import { SourceTypeNamePipe } from './source-type-name.pipe';
import { CustomerNamePipe } from './customer-name.pipe';
import { AdminUserNamePipe } from './admin-user-name.pipe';
import { TourGuideTypeNamePipe } from './tour-guide-type-name.pipe';
import { TourNamePipe } from './tour-name.pipe';
import { SlideshowItemTypeNamePipe } from './slideshow-item-type-name.pipe';
import { PaymentTypeNamePipe } from './payment-type-name.pipe';
import { OrderPipe } from './order.pipe';
import { AddonNamePipe } from './addon-name.pipe';
import { LanguageNamePipe } from './language-name.pipe';
import { PickupLocationNamePipe } from './pickup-location-name.pipe';
import { SearchPipe } from './search.pipe';
import { ProductNamePipe } from './product-name.pipe';
import { SupplierResourceTypeNamePipe } from './supplier-resource-type-name.pipe';
import { SupplierResourceTypeNameFromSupplierResourcePipe } from './supplier-resource-type-name-from-supplier-resource.pipe';
import { SupplierResourceProductTypeNamePipe } from './supplier-resource-product-type-name.pipe';
import { TourStatusNamePipe } from './tour-status-name.pipe';
import { NewLinePipe } from './new-line.pipe';
import { SupplierNamePipe } from './supplier-name.pipe';
import { FunctionTypeNamePipe } from './function-type-name.pipe';
import { PaymentBalancePipe } from './payment-balance.pipe';
import { CurrencyPipe } from '@angular/common';
import { OrderStatusNamePipe } from './order-status-name.pipe';
import { TravelAgencyNamePipe } from './travel-agency-name.pipe';
import { CustomerPipe } from './customer.pipe';
import { TravelAgencyNameOnCustomerPipe } from './travel-agency-name-on-customer.pipe';
import { TravelAgencyShortNameOnCustomerPipe } from './travel-agency-short-name-on-customer.pipe';
import { SpecialDietIconOnTourPipe } from './all-special-diet-icon-on-tour.pipe';
import { PlannerGuideGuideWarningPipe } from './planner-guide-guide-warning.pipe';
import { PlannerGuideTourWarningPipe } from './planner-guide-tour-warning.pipe';
import { TotalCustomersOnTourPipe } from './total-customers-on-tour.pipe';
import { SpecialDietNamePipe } from './special-diet-name.pipe';
import { MediaPathPipe } from './media-path.pipe';
import { TermTypeNamePipe } from './term-type-name.pipe';
import { ProductPriceDatePipe } from './product-price-date.pipe';
import { PickupLocationPricePipe } from './pickup-location-price.pipe';
import { OrderPaymentLinkPipe } from './order-payment-link.pipe';
import { PickupLocationRelativeTimePipe } from './pickup-location-relative-time.pipe';
import { AddTimePipe } from './pickup-location-calculate-time.pipe';

const pipes = [
	FilterPipe,
	CustomerTypeNamePipe,
	ElementTypeNamePipe,
	PageTypeNamePipe,
	ProductTypeNamePipe,
	BookingStatusNamePipe,
	SourceTypeNamePipe,
	CustomerNamePipe,
	CustomerPipe,
	AdminUserNamePipe,
	TourGuideTypeNamePipe,
	TourNamePipe,
	SlideshowItemTypeNamePipe,
	PaymentTypeNamePipe,
	OrderPipe,
	AddonNamePipe,
	LanguageNamePipe,
	PickupLocationNamePipe,
	SearchPipe,
	ProductNamePipe,
	SupplierResourceTypeNamePipe,
	SupplierResourceTypeNameFromSupplierResourcePipe,
	SupplierResourceProductTypeNamePipe,
	TourStatusNamePipe,
	NewLinePipe,
	SupplierNamePipe,
	FunctionTypeNamePipe,
	PaymentBalancePipe,
	OrderStatusNamePipe,
	TravelAgencyNamePipe,
	TravelAgencyNameOnCustomerPipe,
	TravelAgencyShortNameOnCustomerPipe,
	SpecialDietIconOnTourPipe,
	PlannerGuideGuideWarningPipe,
	PlannerGuideTourWarningPipe,
	TotalCustomersOnTourPipe,
	SpecialDietNamePipe,
	MediaPathPipe,
	TermTypeNamePipe,
	ProductPriceDatePipe,
	PickupLocationPricePipe,
	PickupLocationRelativeTimePipe,
	OrderPaymentLinkPipe,
	AddTimePipe,
];


@NgModule({
	declarations: [
		pipes,
	],
	providers: [
		CurrencyPipe,
	],
	exports: [
		pipes,
	],
})
export class PipeModule { }
