
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from '../base.store.service';
import { CategoryImage } from 'app/models/category/category-image.model';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class CategoryImageStore extends BaseStore<CategoryImage> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'CategoryImages', baseUrl, snackBarService);
	}

	public getAllOnCategory(categoryId: number) {
		this._items = [];
		this._itemsSubject.next(this._items);
		return this.http.get<CategoryImage[]>(`${this.host}/category/${categoryId}`).subscribe(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		});
	}

}
