import { BaseModel } from './base.model';

export class Supplier extends BaseModel {
	public name: string;
	public productTypeId: number;
	public phone: string;
	public email: string;
	public homepage: string;
	public description: string;
}
