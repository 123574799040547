import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import * as _ from 'lodash';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { FormGroup, FormControl } from '@angular/forms';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { ElementStore } from 'app/admin/stores/element.store.service';
import { CategoryElement } from 'app/models/category-element.model';
import { CategoryElementStore } from 'app/admin/stores/category-element.store.service';
import { CategoryStore } from 'app/admin/stores/category/category.store.service';

@UntilDestroy()
@Component({
	selector: 'app-element-category-blade',
	templateUrl: './element-category.blade.html',
	styleUrls: ['./element-category.blade.scss'],
})
@IBladeComponent.register
export class ElementCategoryBlade implements OnInit, OnDestroy {
	public saving: Subject<boolean> = new Subject<boolean>();
	public selectedIndex = 0;
	public loading = true;

	public menuItems: BladeMenuItem[] = [
		{
			index: 1,
			title: 'Kategori',
		}, {
			index: 99,
			title: 'Style',
		},
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];
	public categoryElement: CategoryElement;
	public itemForm: FormGroup = new FormGroup({
		category: new FormControl(''),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public categoryElementStore: CategoryElementStore,
		public languageStore: LanguageStore,
		public categoryStore: CategoryStore,
		private elementStore: ElementStore,
	) {
		categoryStore.getAll();
	}

	ngOnInit() {
		this.categoryElementStore.getOnElement(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(categoryElement => {
			this.loading = false;
			this.categoryElement = categoryElement;

			if (categoryElement) {
				this.itemForm.get('category').setValue(this.categoryElement.categoryId);
			} else {
				this.categoryElement = new CategoryElement();
				this.categoryElement.elementId = this.bladeItem.id;
			}

			this.itemForm.get('category').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
				this.categoryElement.categoryId = value;
			});
		});
	}

	ngOnDestroy() { }

	public save() {
		if (this.selectedIndex === 99) {
			this.saving.next(true);
			return;
		}

		this.loading = true;

		if (this.categoryElement.id) {
			this.categoryElementStore.update(this.categoryElement).subscribe(updatedItem => {
				this.loading = false;
				this.categoryElement = _.assign(this.categoryElement, updatedItem);
			});
		} else {
			this.categoryElementStore.create(this.categoryElement).subscribe(updatedItem => {
				this.loading = false;
				this.categoryElement = _.assign(this.categoryElement, updatedItem);
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.elementStore.delete(this.categoryElement.elementId).subscribe(isDeleted => {
			if (isDeleted) {
				this.loading = false;
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
