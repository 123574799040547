import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';

import { DashboardWidget } from '../../models/dashboard-widget.model';
import { DashboardWidgetSize } from '../../models/dashboard-widget-size.model';
import { DashboardWidgetType } from '../../models/dashboard-widget-type.model';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { DashboardService } from '../../services/dashboard.service';
import { DashboardWidgetOrderRestModalComponent } from './components/dashboard-widget-orders-rest/dialogs/dashboard-widget-modal/dashboard-widget-order-rest-modal.component';

@Component({
	selector: 'app-dashboard-widget',
	templateUrl: './dashboard-widget.component.html',
	styleUrls: ['./dashboard-widget.component.scss'],
})
export class DashboardWidgetComponent implements OnInit, OnDestroy {
	public sizes = new DashboardWidgetSize();
	public dashboardWidgetType = DashboardWidgetType;

	@Input() item: DashboardWidget;
	@Input() isHandset: boolean;
	constructor(
		private dialog: MatDialog,
		private router: Router,
		private activatedRouter: ActivatedRoute,
		private orderStore: OrderStore,
		private dashboardService: DashboardService,
	) {
	}

	ngOnInit() {
	}

	ngOnDestroy(): void { }

	public showSettings() {
		this.dialog.open(DashboardWidgetOrderRestModalComponent, {
			data: this.item,
		});
	}

	public itemClick() {
		if (typeof this.item.data === 'object' && this.item.data.link) {
			this.router.navigate([this.item.data.link], { relativeTo: this.activatedRouter });
		}
	}

	public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
		// console.log(event, active);
	 }

	public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
		// console.log(event, active);
	}

	public removeWidget() {
		this.dashboardService.deleteDashboardWidget(this.item).subscribe();
	}
}
