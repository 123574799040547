import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLine',
})
export class NewLinePipe implements PipeTransform {

  constructor() {}

 public transform(value: string): string {
	 if (value) {
		return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
	 }
	 return '';
  }
}
