
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { TermText } from 'app/models/term-text.model';
import { SnackBarService } from '../services/snack-bar.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class TermTextStore extends BaseStore<TermText> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TermText', baseUrl, snackBarService);
		this.getAll();
	}

	public getAllOnTerm(termId: number): Observable<TermText[]> {
		return this.http.get<TermText[]>(`${this.host}/term/${termId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public getOnTermAndLanguage(termId: number, languageId: number): Observable<TermText> {
		return this.http.get<TermText>(`${this.host}/term/${termId}/language/${languageId}`).pipe(tap(termText => {
			if (termText) {
				const findObj = _.find(this._items, type => type.termId === termText.termId
									&& type.languageId === termText.languageId);
				_.assign(findObj, termText); // Keep object in sync
			}
		}));
	}

	public addTermText(termText: TermText) {
		this._items.push(termText);
		this._itemsSubject.next(this._items);
	}

	public removeTermText(termText: TermText) {
		_.remove(this._items, termText);
		this._itemsSubject.next(this._items);
	}
}
