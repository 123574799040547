<div class="blade-main-container">
	<mat-toolbar class="breadcrumbs" *ngIf="!(isHandset$ | async)">
		<mat-chip-list>
			<ng-container *ngFor="let item of bladeItems">
				<mat-chip
					(click)="bladePositionService.selectBladeItem(item)"
					[selected]="item.isSelected"
					*ngIf="!item.isRemoving">
					{{item.title}}
					<mat-icon matChipRemove *ngIf="item.itemIndex !== 0" (click)="bladeService.removeBladeItem(item)">cancel</mat-icon>
				</mat-chip>
			</ng-container>
		</mat-chip-list>
	</mat-toolbar>
	<div class="blade-scroll-container">
		<div class="blade-container"
			[class.is-handset]="isHandset$ | async"
			[ngStyle]="{'width.px': bladeContainerWidth}">
			<!-- [ngStyle]="{'transform': 'translateX(' + (bladePositionService.translateX$ | async) + 'px)', 'width.px': bladeContainerWidth}"> -->
			<app-blade-component
				[bladeItem]="item"
				*ngFor="let item of bladeItems"
				[class.full-width]="bladeItems?.length === 1"
				[class.remove]="item.isRemoving"
				[class.blade-extra-small]="item.activeClass === 'blade-extra-small'"
				[class.blade-small]="item.activeClass === 'blade-small'"
				[class.blade-medium]="item.activeClass === 'blade-medium'"
				[class.blade-large]="item.activeClass === 'blade-large'"
				[class.blade-extra-large]="item.activeClass === 'blade-extra-large'"
				[class.blade-xx-large]="item.activeClass === 'blade-xx-large'">
			</app-blade-component>
		</div>
	</div>
	<div matRipple *ngIf="isHandset$ | async" class="d-flex justify-content-center font-bold pointer" (click)="openButtonNavigation()">
		<span>Navigera</span>
		<mat-icon class="pointer">keyboard_arrow_up</mat-icon>
	</div>
</div>
