import { BaseModel } from '../base.model';

export class Settings extends BaseModel {
	public logo: string;
	public googleAnalyticsTrackingId: string;
	public googleAdWordsConversionId: string;
	public googleAdWordsSendTo: string;
	public defaultLanguage: string;
	public fbPageId: string;
	public dibsCheckoutPath: string;
	public titlePrefix: string;
}
