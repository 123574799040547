<mat-card>
	<mat-card-title>Turer på leverantören</mat-card-title>
	<mat-card-content>
		<div class="overflow-auto">
			<table mat-table [dataSource]="dataSource" matSort class="w-100" [class.d-none]="!dataSource?.data?.length">
				<ng-container matColumnDef="productId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
					<td mat-cell *matCellDef="let item">{{item.name || (item.productId | productName | async)}}</td>
				</ng-container>
				<ng-container matColumnDef="guide">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Guide</th>
					<td mat-cell *matCellDef="let item">
						<p class="m-0" *ngFor="let guide of item.tourGuides"><strong>{{guide.userId | adminUserName |
								async}}</strong></p>
						<span *ngIf="!item.tourGuides?.length">Saknas</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
					<td mat-cell *matCellDef="let item">
						<span *ngIf="_.includes(moment(item.date).format('YYYY-MM-DD'), '0001-01-01')">Saknar
							datum</span>
						<span
							*ngIf="!_.includes(moment(item.date).format('YYYY-MM-DD'), '0001-01-01')">{{item.date|date:'yyyy-MM-dd'}}
							{{item.time || '--:--'}}</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
					<td mat-cell *matCellDef="let item">{{item.tourStatusId | tourStatusName | async}}</td>
				</ng-container>
				<ng-container matColumnDef="isActive">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Aktiv</th>
					<td mat-cell *matCellDef="let item">
						<mat-icon *ngIf="item.isActive">check</mat-icon>
					</td>
				</ng-container>
				<ng-container matColumnDef="isPrivate">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Privat</th>
					<td mat-cell *matCellDef="let item">
						<mat-icon *ngIf="item.isPrivate">check</mat-icon>
					</td>
				</ng-container>
				<ng-container matColumnDef="link">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let item">
						<mat-icon>open_in_new</mat-icon>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)"
					[class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
			</table>
		</div>
		<mat-paginator [class.d-none]="!dataSource?.data?.length" [pageSizeOptions]="[10, 25, 50]"
			showFirstLastButtons></mat-paginator>
	</mat-card-content>
</mat-card>