<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem.id">
	<app-blade-header [title]="'Bildspel'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content *ngIf="element?.id" [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
		<mat-card>
			<div *ngIf="element && selectedIndex === 1">
				<app-alert-card [mode]="'info'" [label]="'Läs om inställningarna här: https://swiperjs.com/api/#parameters'"></app-alert-card>
				<form [formGroup]="formGroup">
					<div class="form-group">
						<json-editor [options]="editorOptions" formControlName="config"></json-editor>
					</div>
				</form>
			</div>
			<div *ngIf="element && selectedIndex === 2">
				<app-alert-card [mode]="'info'" [label]="'Lista av alla kopplade!'"></app-alert-card>
				<app-element-slideshow-blade-items [slideshowElement]="slideshowElement"></app-element-slideshow-blade-items>
			</div>
			<app-element-blade-styles *ngIf="element && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="element?.id">Spara</span>
			<span *ngIf="!element?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
