<p *ngIf="bladeItem.payload?.travelAgencyId > 0">Nedan visas alla kunder på resebyrån:
	<b>{{bladeItem.payload?.travelAgencyId | travelAgencyName | async}}</b></p>
<form [formGroup]="itemForm">
	<mat-form-field class="w-100" appearance="outline">
		<mat-label>Filtrera på namn, epost och telefon</mat-label>
		<input matInput formControlName="searchWord">
	</mat-form-field>
</form>

<mat-card>
	<table mat-table [dataSource]="dataSource" matSort class="w-100">
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
			<td mat-cell *matCellDef="let item">{{item.id}} </td>
		</ng-container>
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
			<td mat-cell *matCellDef="let item"><strong>{{item.firstName}} {{item.lastName}}</strong></td>
		</ng-container>
		<ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Epost</th>
			<td mat-cell *matCellDef="let item">{{item.email}}</td>
		</ng-container>
		<ng-container matColumnDef="phone">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
			<td mat-cell *matCellDef="let item">{{item.phone}}</td>
		</ng-container>
		<ng-container matColumnDef="link">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let item">
				<mat-icon>open_in_new</mat-icon>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)"
			[class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</mat-card>