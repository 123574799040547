import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { ProductPickupLocationPriceStore } from 'app/admin/stores/product/product-pickup-location-price.store.service';
import { CustomerType } from 'app/models/customer-type.model';
import { PickupLocationPrice } from 'app/models/page-location-price.model';
import { ProductPickupLocationPrice } from 'app/models/product/product-pickup-location-price.model';
import { ReplaySubject, Subject } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-product-pickup-location-price-edit',
	templateUrl: './product-pickup-location-price-edit.component.html',
	styleUrls: ['./product-pickup-location-price-edit.component.scss'],
})
export class ProductPickupLocationPriceEditComponent implements OnChanges {
	public pickupLocationPriceReplay$ = new ReplaySubject<PickupLocationPrice>(1);
	public productPickupLocationPriceReplay$ = new ReplaySubject<ProductPickupLocationPrice>(1);
	public selectedProductPickupLocationPrice: ProductPickupLocationPrice;

	@Input() pickupLocationPrices: PickupLocationPrice[];
	@Input() productPickupLocationPrices: ProductPickupLocationPrice[];
	@Input() customerType: CustomerType;
	@Input() set save(subject: Subject<boolean>) {
		subject.pipe(untilDestroyed(this)).subscribe(x => this.doSave());
	}

	public itemForm: FormGroup = new FormGroup({
		price: new FormControl(null),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public productPickupLocationPriceStore: ProductPickupLocationPriceStore,
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (this.customerType) {
			this.pickupLocationPriceReplay$.next(this.pickupLocationPrices?.find(x => x.customerTypeId === this.customerType.id));
			this.selectedProductPickupLocationPrice = this.productPickupLocationPrices?.find(x => x.customerTypeId === this.customerType.id);
			this.productPickupLocationPriceReplay$.next(this.selectedProductPickupLocationPrice);
			if (this.selectedProductPickupLocationPrice) {
				this.itemForm.get('price').setValue(this.selectedProductPickupLocationPrice.price);
			}
		}
	}

	public doSave() {
		if (this.selectedProductPickupLocationPrice?.id && !this.itemForm.value.price) {
			// Delete
			this.productPickupLocationPriceStore.delete(this.selectedProductPickupLocationPrice.id).subscribe(productPickupLocationPrice => {
				if (productPickupLocationPrice) {
					this.selectedProductPickupLocationPrice = null;
					this.productPickupLocationPriceReplay$.next(this.selectedProductPickupLocationPrice);
				}
			});
		} else if (!this.selectedProductPickupLocationPrice?.id && this.itemForm.value.price) {
			// New
			this.selectedProductPickupLocationPrice = new ProductPickupLocationPrice();
			this.selectedProductPickupLocationPrice.productPickupLocationId = this.bladeItem.id;
			this.selectedProductPickupLocationPrice.customerTypeId = this.customerType.id;
			this.selectedProductPickupLocationPrice.price = this.itemForm.value.price;
			this.productPickupLocationPriceStore.create(this.selectedProductPickupLocationPrice).subscribe(productPickupLocationPrice => {
				Object.assign(this.selectedProductPickupLocationPrice, productPickupLocationPrice);
				this.productPickupLocationPriceReplay$.next(this.selectedProductPickupLocationPrice);
			});
		} else if (this.selectedProductPickupLocationPrice?.id && this.itemForm.value.price) {
			// Update
			this.selectedProductPickupLocationPrice.price = this.itemForm.value.price;
			this.productPickupLocationPriceStore.update(this.selectedProductPickupLocationPrice).subscribe(productPickupLocationPrice => {
				Object.assign(this.selectedProductPickupLocationPrice, productPickupLocationPrice);
				this.productPickupLocationPriceReplay$.next(this.selectedProductPickupLocationPrice);
			});
		}
	}
}
