import { ProductText } from './product-text.model';
import { BaseModel } from '../base.model';

export class Product extends BaseModel {
	public name: string;
	public productTypeID: number;
	public supplierID: number;
	public texts: ProductText[];
	public timeLength: number;
	public isActive: boolean;
}
