import { BaseModel } from './base.model';
import { PaymentBalance } from './paymanet-balance.model';
import { TourBookingAddon } from './tour-booking-addon.model';
import { TourBookingCustomerType } from './tour-booking-customer-type.model';
import { TourBookingMessage } from './tour-booking-message.model';
import { TourBookingPickupLocation } from './tour-booking-pickup-location.model';
import { TourBookingSpecialDietMessage } from './tour-booking-special-diet-message.model';
import { TourBookingSpecialDiet } from './tour-booking-special-diet.model';
import { Tour } from './tour.model';

export class TourBooking extends BaseModel {
	public tourId: number;
	public orderId: number;
	public customerId: number;
	public bookingStatusId: number;
	public sourceTypeId: number;
	public message: string;
	public isDraft: boolean;

	/**
	 * If the order has rest amount to pay. And payment is not invoice
	 */
	public hasRestToPay: boolean;

	public tourBookingAddons: TourBookingAddon[];
	public tourBookingCustomerTypes: TourBookingCustomerType[];
	public tourBookingMessages: TourBookingMessage[];
	public tourBookingPickupLocations: TourBookingPickupLocation[];
	public tourBookingSpecialDiets: TourBookingSpecialDiet[];
	public tourBookingSpecialDietMessages: TourBookingSpecialDietMessage[];
	public paymentBalance: PaymentBalance;
	public tour: Tour;
	public tourMessage: string;
	public travelAgencyId: number;
}
