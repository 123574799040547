import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { ProductDate } from 'app/models/product/product-date.model';
import { ProductDateStore } from 'app/admin/stores/product/product-date.store.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { ProductDateBladeComponent } from 'app/admin/blades/poduct-date/product-date-blade/product-date-blade.component';
import { ProductExcludeDate } from 'app/models/product/product-exclude-date.model';
import { ProductExcludeDateStore } from 'app/admin/stores/product/product-exclude-date.store.service';
import { ProductExcludeDateBladeComponent } from 'app/admin/blades/poduct-exclude-date/product-exclude-date-blade/product-exclude-date-blade.component';
import * as _ from 'lodash';

@UntilDestroy()
@Component({
	selector: 'app-product-blade-dates',
	templateUrl: './product-blade-dates.component.html',
	styleUrls: ['./product-blade-dates.component.scss'],
})
export class ProductBladeDatesComponent implements OnInit, OnDestroy {
	public weekDays: WeekDays[] = [
		{
			dayNumber: 0,
			name: 'Måndag',
		},
		{
			dayNumber: 1,
			name: 'Tisdag',
		},
		{
			dayNumber: 2,
			name: 'Onsdag',
		},
		{
			dayNumber: 3,
			name: 'Torsdag',
		},
		{
			dayNumber: 4,
			name: 'Fredag',
		},
		{
			dayNumber: 5,
			name: 'Lördag',
		},
		{
			dayNumber: 6,
			name: 'Söndag',
		},
	];

	public productDates: ProductDate[];
	public productExcludeDates: ProductExcludeDate[];
	public displayedColumns: string[] = ['date', 'times', 'days', 'actions'];
	public displayedExcludeColumns: string[] = ['date', 'actions'];
	public dataSource: MatTableDataSource<ProductDate>;
	public excludeDataSource: MatTableDataSource<ProductExcludeDate>;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private productDateStore: ProductDateStore,
		private productExcludeDateStore: ProductExcludeDateStore,
	) {
		this.productDateStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.productDates = items;
			this.dataSource = new MatTableDataSource<ProductDate>(this.productDates);
		});
		this.productExcludeDateStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.productExcludeDates = items;
			this.excludeDataSource = new MatTableDataSource<ProductExcludeDate>(_.orderBy(this.productExcludeDates, ['excludeDate', 'desc']));
		});
	}

	ngOnInit() {
		this.productDateStore.getAllOnProduct(this.bladeItem.id);
		this.productExcludeDateStore.getAllOnProduct(this.bladeItem.id);
	}

	ngOnDestroy(): void { }

	public addDate() {
		this.bladeService.addBladeItem(
			{
				id: 0,
				component: ProductDateBladeComponent,
				minClass: BladeSize.xs,
				payload: {
					productId: this.bladeItem.id,
				},
			},
			this.bladeItem,
		);
	}
	public addExcludeDate() {
		this.bladeService.addBladeItem(
			{
				id: 0,
				component: ProductExcludeDateBladeComponent,
				minClass: BladeSize.xs,
				payload: {
					productId: this.bladeItem.id,
				},
			},
			this.bladeItem,
		);
	}

	public deleteDate(item: ProductDate) {
		this.productDateStore.delete(item.id).subscribe();
	}

	public deleteExcludeDate(item: ProductExcludeDate) {
		this.productExcludeDateStore.delete(item.id).subscribe();
	}

	public selectItem(item: ProductDate) {
		this.bladeService.addBladeItem({
			id: item.id,
			component: ProductDateBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	public selectExcludeItem(item: ProductExcludeDate) {
		this.bladeService.addBladeItem({
			id: item.id,
			component: ProductExcludeDateBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}
}
interface WeekDays {
	dayNumber: number;
	name: string;
}
