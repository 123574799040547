import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { Column } from 'app/models/site/column.model';
import { ColumnStore } from 'app/admin/stores/column.store.service';
import { ElementBladeComponent } from '../../element/element-blade/element-blade.component';
import { ElementService } from '../../../services/element.service';

@Component({
	selector: 'app-column-blade',
	templateUrl: './column-blade.component.html',
	styleUrls: ['./column-blade.component.scss'],
})
@IBladeComponent.register
export class ColumnBladeComponent implements OnInit {
	public selectedIndex = 1;
	public item: Column;
	public loading = true;
	public canIClose: () => {} | boolean;
	public menuItems: BladeMenuItem[] = [
		{ title: 'Generellt', index: 1 },
		{ title: 'Element', index: 2 },
		{ title: 'Länkar', index: 3 },
		{ title: 'Style', index: 4 },
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: ColumnStore,
		private bladeService: BladeService,
		private bladePositionService: BladePositionService,
		public columnBladeService: ElementService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new Column();
			if (!this.bladeItem.payload.etageId) {
				console.error('Missing etageId');
			}
			this.item.etageId = this.bladeItem.payload.etageId;
		}
	}

	ngOnInit() {}

	public save() {
		this.loading = false;
		if (this.item.id) {
			this.itemStore.update(this.item).subscribe(item => {
				this.loading = false;
				this.item = _.assign(this.item, item);
			});
		} else {
			this.item.name = this.itemForm.get('name').value;
			this.itemStore.create(this.item).subscribe(item => {
				this.loading = false;
				this.item = _.assign(this.item, item);
				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	public addNewElement() {
		this.bladeService.addBladeItem({
			id: 0,
			component: ElementBladeComponent,
			minClass: BladeSize.xs,
			payload: {
				columnId: this.bladeItem.id,
			},
		}, this.bladeItem);
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
