
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { SlideshowItem } from 'app/models/slideshow-item.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class SlideshowItemStore extends BaseStore<SlideshowItem> {
	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SlideshowItem', baseUrl, snackBarService);
	}

	public getAllOnSlideshowElement(slideshowElementId: number): void {
		this.http.get<SlideshowItem[]>(`${this.host}/slideshowElement/${slideshowElementId}`).subscribe(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		});
	}
}
