import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { AddonStore } from 'app/admin/stores/addon.store.service';
import { CategoryStore } from 'app/admin/stores/category/category.store.service';
import { Addon } from 'app/models/addon.model';
import { Category } from 'app/models/category/category.model';
import * as _ from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable()
export class AddonBladeService {
	public selectedIndex = 1;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		price: new FormControl('', [Validators.required]),
		isActive: new FormControl(false),
	});

	private addon: Addon;
	private addonReplay = new ReplaySubject<Addon>();
	public addonReplay$ = this.addonReplay.asObservable();

	private isLoadingBehavior = new BehaviorSubject<boolean>(true);
	public isLoadingBehavior$ = this.isLoadingBehavior.asObservable();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: AddonStore,
		private bladePositionService: BladePositionService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.addon = item;
				this.addonReplay.next(this.addon);
				this.itemForm.patchValue(this.addon);
				this.isLoadingBehavior.next(false);
			});
		} else {
			this.addon = new Addon();
			this.addonReplay.next(this.addon);
			this.isLoadingBehavior.next(false);
		}
	}

	public save() {
		this.isLoadingBehavior.next(true);
		this.addon = Object.assign(this.addon, this.itemForm.value);

		if (this.addon.id) {
				this.itemStore.update(this.addon).subscribe(category => {
					this.isLoadingBehavior.next(false);
					this.addon = _.assign(this.addon, category);
				});
		} else {
			this.itemStore.create(this.addon).subscribe(category => {
				this.isLoadingBehavior.next(false);
				this.addon = _.assign(this.addon, category);
				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	public deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.isLoadingBehavior.next(true);
		this.itemStore.delete(this.addon.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
			this.isLoadingBehavior.next(false);
		});
	}

	public setLoading(isLoading: boolean) {
		this.isLoadingBehavior.next(isLoading);
	}
}
