import { Component, OnInit, Inject, ViewChild, Input, OnDestroy } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { ProductSirvoyStore } from 'app/admin/stores/product/product-sirvoy.store.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductSirvoy } from '../../../../../../models/product/product-sirvoy.model';

@UntilDestroy()
@Component({
	selector: 'app-product-blade-sirvoy',
	templateUrl: './product-blade-sirvoy.component.html',
	styleUrls: ['./product-blade-sirvoy.component.scss'],
})
export class ProductBladeSirvoyComponent implements OnInit, OnDestroy {
	private saveSubject: Subject<void>;
	public productSirvoy: ProductSirvoy;

	public itemForm: FormGroup = new FormGroup({
		sirvoyId: new FormControl('', [Validators.required]),
		rooms: new FormControl('', [Validators.required]),
	});

	@Input() set save(subject: Subject<void>) {
		if (subject) {
			if (this.saveSubject) {
				this.saveSubject.unsubscribe();
			}
			this.saveSubject = subject;
			subject.pipe(untilDestroyed(this)).subscribe(() => {
				this.doSave();
			});
		} else if (this.saveSubject) {
			this.saveSubject.unsubscribe();
		}

	}

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public productSirvoyStore: ProductSirvoyStore,
		private snackBarService: SnackBarService,
	) {
		this.productSirvoyStore.getAllOnProduct(this.bladeItem.id);
		this.productSirvoyStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.productSirvoy = items[0];
			this.itemForm.patchValue(this.productSirvoy);
		});
	}

	ngOnInit() {}

	ngOnDestroy() {}

	private doSave() {
		if (!this.productSirvoy) {
			this.productSirvoy = new ProductSirvoy();
			this.productSirvoy.productId = this.bladeItem.id;
		}

		this.productSirvoy.sirvoyId = this.itemForm.get('sirvoyId').value;
		this.productSirvoy.rooms = this.itemForm.get('rooms').value;
		this.saveOrUpdate().subscribe(item => {
			this.productSirvoy = item;
			this.itemForm.markAsPristine();
		});
	}

	private saveOrUpdate(): Observable<ProductSirvoy> {
		if (this.productSirvoy.id > 0) {
			return this.productSirvoyStore.update(this.productSirvoy);
		} else {
			return this.productSirvoyStore.create(this.productSirvoy);
		}
	}
}
