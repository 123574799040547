import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { CustomerTypeStore } from '../stores/customer-type.store.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomerStore } from '../stores/customer.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'customerName' })
export class CustomerNamePipe implements PipeTransform, OnDestroy {
	constructor(private customerStore: CustomerStore) {	}
	public transform(customerId: number): Observable<string> {
		if (!customerId) {
			return;
		}
		return this.customerStore.pipeGetName(customerId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
