<app-blade-item [canIClose]="true" [loading]="loading">
	<app-blade-header [title]="'Resurs på leverantör'"></app-blade-header>
	<app-blade-content>
		<p>Välj resurs och antal som denna leverantör tillhandahåller.</p>
		<p>Saknas din resurs i listan? <button mat-stroked-button color="accent" (click)="newResource()">Skapa ny här!</button></p>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<mat-label>Välj resurs</mat-label>
				<mat-select formControlName="supplierResourceType">
					<mat-option *ngFor="let type of (supplierResourceTypeStore.items$ | async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="amount" placeholder="Antal">
			</mat-form-field>
		</form>
		<p class="mt-3" *ngIf="!item?.id">När du lagt till resursen på leverantören så kommer du ha möjligheten att ändra tillgången på resursen för enskilda datum.</p>
		<mat-card class="mt-3" *ngIf="item?.id">
			<p class="mat-card-subtitle">Nedan kan du ändra tillgången för resursen på visa datum.</p>
			<mat-card-content>
				<form [formGroup]="dateForm">
					<mat-form-field class="w-100">
						<mat-label>Välj datum</mat-label>
						<input readonly matInput formControlName="date" [matDatepicker]="picker" (click)="picker.open()">
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
					<mat-form-field class="w-100">
						<input matInput type="number" formControlName="amount" placeholder="Antal">
					</mat-form-field>
				</form>
				<div class="d-flex" [ngClass]="{'justify-content-end': !dateForm.value.id, 'justify-content-between': dateForm.value.id}">
					<button mat-raised-button color="warn" (click)="removeDateResource()" *ngIf="dateForm.value.id">Ta bort</button>
					<button mat-raised-button (click)="resetDateResource()" *ngIf="dateForm.value.id">Avbryt</button>
					<button mat-raised-button color="accent" (click)="addDateResource()" [disabled]="!dateForm.valid">
						<span *ngIf="!dateForm.value.id">Lägg till datum</span>
						<span *ngIf="dateForm.value.id">Uppdatera datum</span>
					</button>
				</div>

				<div class="mat-elevation-z3 mt-3">
					<table mat-table [dataSource]="supplierResourceDateStore.items$" matSort class="w-100">
						<ng-container matColumnDef="date">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
							<td mat-cell *matCellDef="let item"><strong>{{item.date | date:'yyyy-MM-dd'}}</strong></td>
						</ng-container>
						<ng-container matColumnDef="amount">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Antal</th>
							<td mat-cell *matCellDef="let item">{{item.amount}}</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayedDateColumns"></tr>
						<tr mat-row *matRowDef="let item; columns: displayedDateColumns;" class="pointer" (click)="selectDateResource(item)" [class.selected]="bladeItem?.child?.id === item?.id"></tr>
					</table>
				</div>
			</mat-card-content>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<button mat-raised-button color="warn" (click)="delete()" *ngIf="item?.id">Ta bort</button>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Lägg till</span>
		</button>
	</app-blade-footer>
</app-blade-item>
