import { DashboardWidgetSizeRowsColumns } from './dashboard-widget-size-rows-columns.model';

export class DashboardWidgetSize {
	public xtraSmall: DashboardWidgetSizeRowsColumns = { name: 'xtraSmall', rows: 1, columns: 1 };
	public small: DashboardWidgetSizeRowsColumns = { name: 'small', rows: 2, columns: 2 };
	public smallVerticle: DashboardWidgetSizeRowsColumns = { name: 'smallVerticle', rows: 2, columns: 1 };
	public smallHorizontal: DashboardWidgetSizeRowsColumns = { name: 'smallHorizontal', rows: 1, columns: 2 };
	public medium: DashboardWidgetSizeRowsColumns = { name: 'medium', rows: 3, columns: 3 };
	public mediumVerticle: DashboardWidgetSizeRowsColumns = { name: 'mediumVerticle', rows: 3, columns: 2 };
	public mediumHorizontal: DashboardWidgetSizeRowsColumns = { name: 'mediumHorizontal', rows: 2, columns: 3 };
	public large: DashboardWidgetSizeRowsColumns = { name: 'large', rows: 4, columns: 4 };
	public largeVerticle: DashboardWidgetSizeRowsColumns = { name: 'largeVerticle', rows: 4, columns: 3 };
	public largeHorizontal: DashboardWidgetSizeRowsColumns = { name: 'largeHorizontal', rows: 3, columns: 4 };
	public xtraLarge: DashboardWidgetSizeRowsColumns = { name: 'xlarge', rows: 5, columns: 5 };
	public xtraLargeVerticle: DashboardWidgetSizeRowsColumns = { name: 'xlargeVerticle', rows: 5, columns: 4 };
	public xtraLargeHorizontal: DashboardWidgetSizeRowsColumns = { name: 'xlargeHorizontal', rows: 4, columns: 5 };
}
