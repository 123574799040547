import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { MediaBladeComponent } from 'app/admin/blades/media/media-blade/media-blade.component';
import { Media } from 'app/models/media.model';
import { CategoryImage } from 'app/models/category/category-image.model';
import { CategoryImageStore } from 'app/admin/stores/category/category-image.store.service';
import { CategoryImageTypeEnum } from 'app/enums/category-image-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-category-blade-images',
	templateUrl: './category-blade-images.component.html',
	styleUrls: ['./category-blade-images.component.scss'],
})
export class CategoryBladeImagesComponent implements OnInit {
	private categoryImages: CategoryImage[];
	public presentationImage: CategoryImage;
	public displayedColumns: string[] = ['id', 'media', 'prio', 'actions'];
	public dataSource: MatTableDataSource<CategoryImage>;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private categoryImageStore: CategoryImageStore,
	) {}

	ngOnInit() {
		this.bladeItem.callback = (items, payload) => { this.useFiles(items, payload.categoryImageType); };
		this.categoryImageStore.getAllOnCategory(this.bladeItem.id);
		this.categoryImageStore.items$.pipe(untilDestroyed(this)).subscribe(images => {
			this.presentationImage = _.find(images, img => img.categoryImageTypeId === CategoryImageTypeEnum.Presentation);
			this.categoryImages = _.filter(images, img => img.categoryImageTypeId === CategoryImageTypeEnum.Article);
			this.addImagesToTable();
		});
	}

	public selectPresentationImage() {
		this.bladeService.addBladeItem(
			{
				id: 0,
				component: MediaBladeComponent,
				minClass: BladeSize.m,
				payload: {
					mediaTypes: 'image',
					categoryImageType: CategoryImageTypeEnum.Presentation,
				},
			},
			this.bladeItem,
		);
	}

	public selectImage() {
		this.bladeService.addBladeItem(
			{
				id: 0,
				component: MediaBladeComponent,
				minClass: BladeSize.m,
				payload: {
					mediaTypes: 'image',
					categoryImageType: CategoryImageTypeEnum.Article,
				},
			},
			this.bladeItem,
		);
	}

	private useFiles(items: Media[], categoryType: CategoryImageTypeEnum) {
		if (categoryType === CategoryImageTypeEnum.Presentation) {
			if (!this.presentationImage) {
				this.presentationImage = new CategoryImage();
			}
			this.presentationImage.categoryImageTypeId = CategoryImageTypeEnum.Presentation;
			this.presentationImage.imageId = items[0].id;
			this.presentationImage.categoryId = this.bladeItem.id;

			if (this.presentationImage && this.presentationImage.id) {
				this.categoryImageStore.update(this.presentationImage).subscribe();
			} else {
				this.categoryImageStore.create(this.presentationImage).subscribe();
			}

		} else if (categoryType === CategoryImageTypeEnum.Article) {
			if (!this.categoryImages) {
				this.categoryImages = [];
			}
			_.each(items, (file, index) => {
				const image = {
					categoryImageTypeId: CategoryImageTypeEnum.Article,
					imageId: file.id,
					prio: this.categoryImages.length + index,
					categoryId: this.bladeItem.id,
				};
				this.categoryImageStore.create(image).subscribe();

			});
		}
	}

	public deleteImage(item: CategoryImage) {
		this.categoryImageStore.delete(item.id).subscribe();
	}

	private addImagesToTable() {
		this.dataSource = new MatTableDataSource<CategoryImage>(_.orderBy(this.categoryImages, img => img.prio));
	}
}
