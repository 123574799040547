<app-blade-item [canIClose]="true">
    <app-blade-header [title]="'Produkt funktioner'"></app-blade-header>
    <app-blade-content>
		<div class="mat-elevation-z1">
			<mat-table [dataSource]="dataSource" class="w-100">
				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
					<mat-cell *matCellDef="let item"><strong>{{item.name}}</strong></mat-cell>
				</ng-container>
				<ng-container matColumnDef="type">
					<mat-header-cell *matHeaderCellDef>Typ</mat-header-cell>
					<mat-cell mat-cell *matCellDef="let item">{{item.functionTypeId | functionTypeName | async}}</mat-cell>
				</ng-container>
				<ng-container matColumnDef="modified">
					<mat-header-cell *matHeaderCellDef>Modifierad</mat-header-cell>
					<mat-cell *matCellDef="let item">{{item.modified|date:'yyyy-MM-dd HH:mm'}}<br />{{item.modifiedBy | adminUserName | async}}</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.id === item?.id && bladeItem?.child?.payload === item?.functionTypeId"></mat-row>
			</mat-table>

			<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</div>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" [matMenuTriggerFor]="menu">Skapa ny</button>
		<mat-menu #menu="matMenu">
			<button mat-menu-item (click)="createNewOddFunction()">Skapa ny "Udda funktion"</button>
			<button mat-menu-item (click)="createNewMinFunction()">Skapa ny "Min funktion"</button>
		</mat-menu>
	</app-blade-footer>
</app-blade-item>
