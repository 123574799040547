import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { ProductSettingsStore } from 'app/admin/stores/product/product-settings.store.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProductSettings } from 'app/models/product/product-settings.model';
import { Subject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-product-blade-settings',
	templateUrl: './product-blade-settings.component.html',
	styleUrls: ['./product-blade-settings.component.scss'],
})
export class ProductBladeSettingsComponent implements OnInit, OnDestroy {
	public productSettings: ProductSettings;
	public itemForm: FormGroup = new FormGroup({
		canOrderOnline: new FormControl(false, [Validators.required]),
		showSpecialDiet: new FormControl(false, [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		private productSettingsStore: ProductSettingsStore,
	) {
		this.productSettingsStore.getOnProduct(this.bladeItem.id).subscribe(productSettings => {
			if (productSettings) {
				this.productSettings = productSettings;
				this.itemForm.get('canOrderOnline').setValue(this.productSettings.canOrderOnline);
				this.itemForm.get('showSpecialDiet').setValue(this.productSettings.showSpecialDiet);
			}
		});
	}
	ngOnInit() {}
	ngOnDestroy(): void { }

	public save() {
		if (!this.productSettings) {
			this.productSettings = new ProductSettings();
			this.productSettings.productId = this.bladeItem.id;
		}

		this.productSettings.canOrderOnline = this.itemForm.get('canOrderOnline').value;
		this.productSettings.showSpecialDiet = this.itemForm.get('showSpecialDiet').value;
		this.saveOrUpdate().subscribe(item => {
			this.productSettings = item;
			this.itemForm.markAsPristine();
		});
	}

	private saveOrUpdate(): Observable<ProductSettings> {
		if (this.productSettings.id > 0) {
			return this.productSettingsStore.update(this.productSettings);
		} else {
			return this.productSettingsStore.create(this.productSettings);
		}
	}
}
