import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Addon } from 'app/models/addon.model';
import { AddonStore } from 'app/admin/stores/addon.store.service';
import { BladeModalData } from 'app/admin/components/blade/models/bladeModalData.model';
import { AddonBladeService } from './service/addon-blade.service';

@Component({
	selector: 'app-addon-blade',
	templateUrl: './addon-blade.component.html',
	styleUrls: ['./addon-blade.component.scss'],
	providers: [AddonBladeService],
})
@IBladeComponent.register
export class AddonBladeComponent implements OnInit {

	public loading = true;
	public item: Addon;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		price: new FormControl('', [Validators.required]),
		isActive: new FormControl(false),
	});
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: AddonStore,
		private addonBladeService: AddonBladeService,
	) {
		if (bladeItem.id) {
			this.itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.itemForm.setValue({name: this.item.name, price: this.item.price, isActive: this.item.isActive});

				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new Addon();
		}
	 }

	ngOnInit() {
	}

	public save() {
		this.loading = true;
		this.item.name = this.itemForm.get('name').value;
		this.item.price = this.itemForm.get('price').value;
		this.item.isActive = this.itemForm.get('isActive').value;
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<Addon> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

	public remove() {
		this.loading = true;
		const bladeModalData: BladeModalData = {
			headline: 'Är du säker?',
			// tslint:disable-next-line: max-line-length
			content: 'Detta kommer radera alla koppling där detta tillägg används. Finns det kopplingar till köp så kommer det inte gå att radera tillägget. Inaktivera tillägget istället.',
			primaryButtonTitle: 'Nej',
			primaryButtonValue: false,
			secondaryButtonTitle: 'Ja',
			secondaryButtonValue: true,
		};
		this.bladeItem.bladeItemComponent.showModal(bladeModalData).then(value => {
			if (value){
				this.itemStore.delete(this.item.id).subscribe(isDeleted => {
					this.loading = false;
					if (isDeleted) {
						this.bladeItem.bladeItemComponent.closeMe();
					}
				});
			} else {
				this.loading = false;
			}
		});
	}
}
