import { Component, OnInit } from '@angular/core';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladeService } from 'app/admin/components/blade/blade.service';
import { ToursBladeComponent } from 'app/admin/blades/tour/tours-blade/tours-blade.component';

@Component({
  selector: 'app-tours-list',
  templateUrl: './tours-list.component.html',
  styleUrls: ['./tours-list.component.scss'],
})
export class ToursListComponent implements OnInit {

  constructor(private bladeService: BladeService) { }

  ngOnInit() {
	this.bladeService.init({
		id: 0,
		component: ToursBladeComponent,
		minClass: BladeSize.m,
		maxClass: BladeSize.l,
		payload: {
			init: true,
		},
	});

  }

}
