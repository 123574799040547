import { NavItem } from 'app/admin/models/nav-item.model';

export const navItems: NavItem[] = [
	{
		adminTextPrefix: 'dashboard',
		iconName: 'dashboard',
		route: '/',
	},
	{
		adminTextPrefix: 'wiki',
		iconName: 'menu_book',
		route: 'wiki',
	},
	{
		adminTextPrefix: 'homepage',
		iconName: 'home',
		route: 'homepage',
		roles: ['page', 'admin'],
		// children: [
		// 	{
		// 		adminTextPrefix: 'homepageSettings',
		// 		iconName: 'settings',
		// 		route: 'settings',
		// 	},
		// 	{
		// 		adminTextPrefix: 'homepagePages',
		// 		iconName: 'fa-file',
		// 		iconSet: 'far',
		// 		iconCustom: true,
		// 		route: 'pages',
		// 	},
		// 	{
		// 		adminTextPrefix: 'homepageWYSIWYG',
		// 		iconName: 'brush',
		// 		route: 'wysiwyg',
		// 	},
		// ],
	},
	{
		adminTextPrefix: 'guideTours',
		iconName: 'assignment',
		route: 'guide-tours/list',
	}, {
		adminTextPrefix: 'planner',
		iconName: 'event_note',
		preventGoToRoute: true,
		route: 'planner',
		roles: ['planner', 'admin'],
		children: [
			{
				adminTextPrefix: 'plannerResource',
				iconName: 'event',
				route: 'resource',
			},
			{
				adminTextPrefix: 'plannerGuides',
				iconName: 'event',
				route: 'guideTours',
			},
		],
	}, {
		adminTextPrefix: 'reports',
		iconName: 'analytics',
		preventGoToRoute: true,
		route: 'reports',
		roles: ['reports', 'admin'],
		children: [
			{
				adminTextPrefix: 'specialDietReports',
				iconName: 'restaurant',
				route: 'special-diet',
			},
		],
	}, {
		adminTextPrefix: 'products',
		iconName: 'fa-box-open',
		iconSet: 'fas',
		iconCustom: true,
		route: 'products/list',
		roles: ['product', 'admin'],
	}, {
		adminTextPrefix: 'productFunctions',
		iconName: 'psychology',
		route: 'product-functions',
		roles: ['master', 'admin'],
	}, {
		adminTextPrefix: 'addon',
		iconName: 'fa-puzzle-piece',
		iconSet: 'fas',
		iconCustom: true,
		route: 'addon/list',
		roles: ['addon', 'admin'],
	}, {
		adminTextPrefix: 'specialDiet',
		iconName: 'restaurant',
		route: 'special-diet/list',
		roles: ['specialDiet', 'admin'],
	}, {
		adminTextPrefix: 'categories',
		iconName: 'category',
		route: 'categories/list',
		roles: ['category', 'admin'],
	},
	{
		adminTextPrefix: 'tour',
		iconName: 'flight',
		route: 'tour',
		roles: ['tour', 'admin'],
	},
	// {
	// 	adminTextPrefix: 'orders',
	// 	iconName: 'credit_card',
	// 	route: 'orders/list',
	// 	roles: ['order', 'admin'],
	// },
	{
		adminTextPrefix: 'payments',
		iconName: 'payments',
		route: 'payments/list',
		roles: ['payment', 'admin'],
	},
	// {
	// 	adminTextPrefix: 'customers',
	// 	iconName: 'fa-users',
	// 	iconSet: 'fas',
	// 	iconCustom: true,
	// 	route: 'customers/list',
	// 	roles: ['customer', 'admin'],
	// },
	{
		adminTextPrefix: 'customer',
		iconName: 'fa-users',
		iconSet: 'fas',
		iconCustom: true,
		route: 'customer',
		roles: ['customer', 'admin'],
	},
	{
		adminTextPrefix: 'pickupLocations',
		iconName: 'place',
		route: 'pickup-loations',
		roles: ['pickupLocation', 'admin'],
	},
	{
		adminTextPrefix: 'analytics',
		iconName: 'insert_chart',
		route: 'analytics',
		roles: ['statistic', 'admin'],
	},
	{
		adminTextPrefix: 'email',
		iconName: 'email',
		route: 'email',
		roles: ['email', 'admin'],
	},
	{
		adminTextPrefix: 'suppliers',
		iconName: 'face',
		route: 'suppliers/list',
		roles: ['supplier', 'admin'],
	},
	{
		adminTextPrefix: 'travelAgencies',
		iconName: 'storefront',
		route: 'travel-agencies',
		roles: ['travel-agencies', 'admin'],
	},
	{
		adminTextPrefix: 'system',
		iconName: 'settings_system_daydream',
		route: 'system',
		roles: ['master', 'admin'],
	},
	{
		adminTextPrefix: 'users',
		iconName: 'fa-user',
		iconSet: 'fas',
		iconCustom: true,
		route: 'users/list',
		roles: ['user', 'admin'],
	},
	{
		adminTextPrefix: 'media',
		iconName: 'image',
		route: 'media',
		roles: ['media', 'admin'],
	}, {
		adminTextPrefix: 'term',
		iconName: 'gavel',
		route: 'terms',
		roles: ['term', 'admin'],
	},
];
