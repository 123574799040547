import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-customers',
	templateUrl: './customers.component.html',
	styleUrls: ['./customers.component.scss'],
})
export class CustomersPageComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
