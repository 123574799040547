<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem?.id">
	<app-blade-header [title]="'Rubrik'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content *ngIf="languages" [menuItems]="menuItems" (indexChange)="selectedIndex = $event; menuChange($event)">
		<mat-card>
			<app-element-blade-generally *ngIf="selectedIndex === 0 && element" [element]="element" [saving]="saving" [menuItems]="menuItems"></app-element-blade-generally>
			<div *ngIf="activeItem && selectedIndex !== 99 && selectedIndex > 0">
				<mat-form-field class="w-100">
					<input matInput [(ngModel)]="activeItem.headline" placeholder="Rubrik">
				</mat-form-field>
			</div>
			<app-element-blade-styles *ngIf="activeItem && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="activeItem?.id">Spara</span>
			<span *ngIf="!activeItem?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
