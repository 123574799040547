import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { SystemStore } from 'app/admin/stores/system.store.service';
import { System } from 'app/models/system.model';
import { SystemBladeComponent } from '../system-blade/system-blade.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-systems-blade',
	templateUrl: './systems-blade.component.html',
	styleUrls: ['./systems-blade.component.scss'],
})
@IBladeComponent.register
export class SystemsBladeComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['id', 'name', 'host'];
	public dataSource: MatTableDataSource<System>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: SystemStore,
	) {
		this.itemStore.getAll$().pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<System>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		if (this.bladeItem.parent && this.bladeItem.parent.callback) {
			this.bladeItem.parent.callback(row);
			this.bladeItem.bladeItemComponent.closeMe();
		} else {
			this.bladeService.addBladeItem({
				id: row.id,
				component: SystemBladeComponent,
				minClass: BladeSize.xs,
			}, this.bladeItem);
		}
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: SystemBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}
}
