
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Element } from 'app/models/element.model';
import { SnackBarService } from '../services/snack-bar.service';
import { PrioBaseStore } from './prio-base.store.service';

@Injectable({
	providedIn: 'root',
})
export class ElementStore extends PrioBaseStore<Element> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Elements', baseUrl, snackBarService);
	}

	public getAllOnColumn(columnId: number): Observable<Element[]> {
		return this.http.get<Element[]>(`${this.host}/column/${columnId}`).pipe(tap(items => {
			this._items = items || [];
			this._itemsSubject.next(this._items);
		}));
	}
}
