import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { OrderPayment } from '../../models/order-payment.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class OrderPaymentStore extends BaseStore<OrderPayment> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'OrderPayment', baseUrl, snackBarService);
	}

	public getAllOnOrder(orderId: number): Observable<OrderPayment[]> {
		this._items = [];
		this._itemsSubject.next(this._items);
		return this.http.get<OrderPayment[]>(`${this.host}/order/${orderId}`).pipe(tap(orderPayments => {
			this._items = orderPayments;
			this._itemsSubject.next(this._items);
		}));
	}
}
