import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';

import { OrderStore } from 'app/admin/stores/order.store.service';
import { BladeService } from 'app/admin/components/blade/blade.service';
import { OrderRestWidget } from 'app/models/order-rest-widget.model';
import { DashboardWidgetOrderRestService } from 'app/admin/components/dashboard/services/dashboard-widget-order-rest.service';

@UntilDestroy()
@Component({
	selector: 'app-dashboard-widget-orders-rest',
	templateUrl: './dashboard-widget-orders-rest.component.html',
	styleUrls: ['./dashboard-widget-orders-rest.component.scss'],
})
export class DashboardWidgetOrdersRestComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['customerId', 'travelAgencyId', 'date', 'payment', 'actions'];
	public dataSource: MatTableDataSource<OrderRestWidget>;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private router: Router,
		private orderStore: OrderStore,
		private bladeService: BladeService,
		public dashboardWidgetOrderRestService: DashboardWidgetOrderRestService,
	) {
	}

	ngOnInit() {
	}

	ngOnDestroy(): void { }

	public goToTour(orderRest: OrderRestWidget) {
		window.open(`/tour?tourId=${orderRest.tourId}`, '_blank');
	}

	public goToOrder(orderRest: OrderRestWidget) {
		window.open('/customer?customerId=' + orderRest.customerId + '&selectedOrder=' + orderRest.orderId, '_blank');
	}

	public goToCustomer(orderRest: OrderRestWidget) {
		window.open('/customer?customerId=' + orderRest.customerId, '_blank');
	}
}
