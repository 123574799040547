import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import {
	FormGroup,
	FormControl,
} from '@angular/forms';
import { ProductDate } from 'app/models/product/product-date.model';
import { ProductDateStore } from 'app/admin/stores/product/product-date.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProductDateTime } from 'app/models/product/product-date-time.model';
import { MatTableDataSource } from '@angular/material/table';
import { ProductDateTimeStore } from 'app/admin/stores/product/product-date-time.store.service';
import { ProductDateTimeCustomerTypeStore } from 'app/admin/stores/product/product-date-time-customer-type.store.service';
import { ProductDateTimeCustomerType } from 'app/models/product/product-date-time-customer-type.model';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { CustomerType } from 'app/models/customer-type.model';

@UntilDestroy()
@Component({
	selector: 'app-product-date-time-customer-type-blade',
	templateUrl: './product-date-time-customer-type-blade.component.html',
	styleUrls: ['./product-date-time-customer-type-blade.component.scss'],
})
@IBladeComponent.register
export class ProductDateTimeCustomerTypeBladeComponent implements OnInit, OnDestroy {
	public loading = true;
	public customerTypes: CustomerType[];
	public productDateTimeCustomerTypes: ProductDateTimeCustomerType[];
	public itemForm: FormGroup = new FormGroup({});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private productDateTimeCustomerTypeStore: ProductDateTimeCustomerTypeStore,
		private customerTypeStore: CustomerTypeStore,
	) {
		this.customerTypeStore.items$.pipe(untilDestroyed(this)).subscribe(customerTypes => {
			this.customerTypes = customerTypes;
			_.each(this.customerTypes, item => {
				this.itemForm.addControl(item.id.toString(), new FormControl(''));
			});

			if (this.bladeItem.payload && this.bladeItem.payload.productDateTimeId) {
				this.getProductDateTimeCustomerTypes();
			} else {
				this.loading = false;
			}
		});
		this.productDateTimeCustomerTypeStore.items$.pipe(untilDestroyed(this)).subscribe(productDateTimeCustomerTypes => {
			this.productDateTimeCustomerTypes = productDateTimeCustomerTypes;
			_.each(this.productDateTimeCustomerTypes, type => {
				this.itemForm.get(type.customerTypeId.toString()).setValue(type.price);
			});
			this.loading = false;
		});
	}

	ngOnInit() {
	}

	ngOnDestroy(): void { }

	public save() {
		this.loading = true;
		const objToSave = [];
		_.each(this.customerTypes, type => {
			const currentProductDateTimeCustomerTypes = _.find(this.productDateTimeCustomerTypes, obj => obj.customerTypeId === type.id);
			if (currentProductDateTimeCustomerTypes) {
				const oldValue = this.itemForm.get(currentProductDateTimeCustomerTypes.customerTypeId.toString()).value;
				if (oldValue !== currentProductDateTimeCustomerTypes.price) {
					currentProductDateTimeCustomerTypes.price = oldValue;
					objToSave.push(currentProductDateTimeCustomerTypes);
				}
			} else {
				const value = this.itemForm.get(type.id.toString()).value;
				if (value) {
					const newProductDateTimeCustomerTypes = new ProductDateTimeCustomerType();
					newProductDateTimeCustomerTypes.customerTypeId = type.id;
					newProductDateTimeCustomerTypes.productDateTimeId = +this.bladeItem.payload.productDateTimeId;
					newProductDateTimeCustomerTypes.price = value;
					objToSave.push(newProductDateTimeCustomerTypes);
				}
			}
		});

		let savedNum = 0;
		_.each(objToSave, obj => {
			this.saveOrUpdate(obj).subscribe(item => {
				savedNum++;
				if (savedNum >= objToSave.length) {
					this.loading = true;
					this.getProductDateTimeCustomerTypes();
					this.itemForm.markAsPristine();
				}
			});
		});
	}

	private saveOrUpdate(obj: ProductDateTimeCustomerType): Observable<ProductDateTimeCustomerType> {
		if (obj.id > 0) {
			return this.productDateTimeCustomerTypeStore.update(obj);
		} else {
			return this.productDateTimeCustomerTypeStore.create(obj);
		}
	}

	private getProductDateTimeCustomerTypes() {
		this.productDateTimeCustomerTypeStore.getAllOnProductDateTime(this.bladeItem.payload.productDateTimeId).subscribe();
	}
}
