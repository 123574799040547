import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { NotificationService } from 'app/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class OrderHubService {
	private orderLink = '/orders/list?bladeItems=id:{id},n:order,minc:blade-medium,maxc:blade-large';
	private hubConnection: signalR.HubConnection;
  	public startConnection = () => {
		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl('/orderHub')
			.build();

		this.hubConnection
			.start()
			.then(() => console.log('Connection started'))
			.catch(err => console.log('Error while starting connection: ' + err));
  	}

	constructor(
		private notificationService: NotificationService,
	) {
		// setTimeout(() => {
		// 	this.hubConnection.invoke('NewOrder', 547);
		// }, 10000);
	}


  	public addTransferChartDataListener = () => {
		this.hubConnection.on('NewOrder', (orderId) => {
			console.log(orderId);
			if (!isNaN(orderId)) {
				this.getOrderAndShowNotification(orderId);
			}
		});
  	}

	private getOrderAndShowNotification(orderId: number) {
		if (orderId) {
			this.notificationService.showSuccess(
				'Ny order från hemsidan',
				// tslint:disable-next-line: max-line-length
				`<a href="${this.orderLink.replace('{id}', orderId.toString())}" target="_blank">Order: ${orderId}. Klicka här <i class="fas fa-external-link-alt"></i></a>`,
			);
		}
	}
}
