<ng-container *ngIf="{
	adminText: 'blades.plannerGuidesTours' | adminText | async
} as source">
	<app-blade-item [canIClose]="true">
		<app-blade-header [title]="source.adminText?.bladeTitle"></app-blade-header>
		<app-blade-content *ngIf="tourStore.items$ | async as tours">
			<mat-card>
				<mat-card>
					<div>
						<form [formGroup]="searchFormGroup">
							<mat-form-field class="mr-3">
								<mat-label>{{source.adminText?.filter.dateLabel}}</mat-label>
								<mat-date-range-input
									[formGroup]="searchFormGroup"
									[rangePicker]="campaignOnePicker"
									[comparisonStart]="searchFormGroup.value.start"
									[comparisonEnd]="searchFormGroup.value.end">
									<input readonly matStartDate [placeholder]="source.adminText?.filter.fromDate" formControlName="start">
									<input readonly matEndDate [placeholder]="source.adminText?.filter.toDate" formControlName="end">
								</mat-date-range-input>
								<mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
								<mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
							</mat-form-field>
							<button mat-stroked-button color="accent" (click)="getTours()">{{source.adminText?.filter.dateSearch}}</button>
						</form>
					</div>
				</mat-card>
				<mat-card class="mt-3" *ngIf="isDone$ | async; else loading">
					<div>
						<span class="text-info d-inline-block">{{source.adminText?.missingTour}}</span>
						<span class="text-warning d-inline-block ml-3">{{source.adminText?.tourTimeWarning}}</span>
						<span class="text-danger d-inline-block ml-3">{{source.adminText?.missingGuide}}</span>
					</div>
					<table mat-table [dataSource]="userStore.items$" class="w-100">
						<ng-container matColumnDef="firstName">
							<th mat-header-cell *matHeaderCellDef class="username"></th>
							<td mat-cell *matCellDef="let item" (click)="openUser(item)" class="pointer">
								<ng-container *ngIf="item | plannerGuideGuideWarning: tours as className">
									<span class="{{className}}">
										<mat-icon>open_in_new</mat-icon>{{item.firstName}}
									</span>
								</ng-container>
							</td>
						</ng-container>
						<ng-container matColumnDef="time">
							<th mat-header-cell *matHeaderCellDef class="work-time">{{source.adminText?.workTime}} </th>
							<td mat-cell *matCellDef="let item">
								<app-planner-guides-tours-guide-total-time-item [user]="item"></app-planner-guides-tours-guide-total-time-item>
							</td>
						</ng-container>
						<ng-container matColumnDef="tour-{{tour.id}}" *ngFor="let tour of tours">
							<th mat-header-cell *matHeaderCellDef class="tour-header pointer" (click)="openTour(tour)">
								<ng-container *ngIf="tour | plannerGuideTourWarning as className">
									<span class="{{className}}">
										<mat-icon>open_in_new</mat-icon>{{tour.name}}<br>{{tour.date | date:'yyyy-MM-dd'}} {{tour.time}} ({{tour.timeLength || '-'}}h) ({{tour.id | totalCustomersOnTour | async}}st)
									</span>							
								</ng-container>
							</th>
							<td mat-cell *matCellDef="let item">
								<app-planner-guides-tours-guide-item [user]="item" [tour]="tour"></app-planner-guides-tours-guide-item>
							</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
					</table>
				</mat-card>
			</mat-card>
		</app-blade-content>
	</app-blade-item>
</ng-container>

<ng-template #loading>
	<mat-card class="mt-3 d-flex justify-content-around">
		<mat-spinner></mat-spinner>
	</mat-card>
</ng-template>