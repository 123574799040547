<ng-container *ngIf="{
	selectedPaymentType: selectedPaymentTypeReplay$ | async,
	item: itemReplay$ | async
} as source">
	<app-blade-item [canIClose]="true" [loading]="loading">
		<app-blade-header
			[title]="'Betalning'"
			[id]="source.item?.id"
		></app-blade-header>
		<app-blade-content>
			<ng-container [ngSwitch]="source.selectedPaymentType?.identifier">
				<ng-container *ngSwitchDefault>
					<mat-card>
						<mat-card-title>
							<span *ngIf="!source.item?.id">Ny betalning</span>
							<span *ngIf="source.item?.id">{{source.selectedPaymentType?.name}} betalning</span>
						</mat-card-title>
						<mat-card-subtitle *ngIf="orderItem">
							Denna betalning baseras på order {{orderItem.id}}
						</mat-card-subtitle>
						<mat-card-content>
							<form [formGroup]="itemForm">
								<mat-form-field class="w-100">
									<mat-label>Välj betalnings-typ</mat-label>
									<mat-select formControlName="paymentTypeId">
										<mat-option *ngFor="let type of (paymentTypeStore.items$|async)" [disabled]="type.identifier === 'nets'" [value]="type.id">
											{{type.name}}
										</mat-option>
									</mat-select>
								</mat-form-field>

								<mat-form-field class="w-100">
									<input type="number" matInput formControlName="amount" placeholder="Belopp">
								</mat-form-field>

								<mat-form-field class="w-100">
									<input type="text" matInput formControlName="paymentReference" placeholder="Betalningsreferens">
								</mat-form-field>

								<mat-form-field class="w-100">
									<mat-label>Meddelande om betalningen</mat-label>
									<textarea matInput formControlName="message"></textarea>
								</mat-form-field>
							</form>
						</mat-card-content>
					</mat-card>
				</ng-container>
				<ng-container *ngSwitchCase="'nets'">
					<app-payment-nets [item]="source.item"></app-payment-nets>
				</ng-container>
			</ng-container>
		</app-blade-content>
		<app-blade-footer *ngIf="source.selectedPaymentType?.identifier !== 'nets'">
			<button mat-raised-button *ngIf="source.item?.id" color="warn" [disabled]="isSavingSubject$ | async" (click)="deleteItem()">
				Radera
			</button>
			<span fxFlex></span>
			<button mat-raised-button color="primary" [disabled]="isSavingSubject$ | async" (click)="save()">
				<span *ngIf="source.item?.id">Spara</span>
				<span *ngIf="!source.item?.id">Skapa</span>
			</button>
		</app-blade-footer>
	</app-blade-item>
</ng-container>
