import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import {
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { PickupLocationPrice } from 'app/models/page-location-price.model';
import { PickupLocationPriceStore } from 'app/admin/stores/pickup-location-price.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-pickup-location-price-blade',
	templateUrl: './pickup-location-price-blade.component.html',
	styleUrls: ['./pickup-location-price-blade.component.scss'],
})
@IBladeComponent.register
export class PickupLocationPriceBladeComponent implements OnInit {
	public loading = true;
	public item: PickupLocationPrice;

	public itemForm: FormGroup = new FormGroup({
		price: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: PickupLocationPriceStore,
	) {
		if (bladeItem.id) {
			itemStore
				.get(bladeItem.id)
				.subscribe(item => {
					this.item = item;
					if (!this.item) {
						this.item = new PickupLocationPrice();
						this.item.pickupLocationId = +bladeItem.payload.pickupLocationId;
						this.item.customerTypeId = +bladeItem.payload.customerTypeId;
					}
					this.itemForm.setValue({
						price: this.item.price || 0,
					});

					this.loading = false;
				});
		} else {
			if (bladeItem.payload && bladeItem.payload.pickupLocationId && bladeItem.payload.customerTypeId) {
				this.item = new PickupLocationPrice();
				this.item.pickupLocationId = +bladeItem.payload.pickupLocationId;
				this.item.customerTypeId = +bladeItem.payload.customerTypeId;
			} else {
				console.error('Missing pickupLocationId and/or customerTypeId');
			}
			this.loading = false;
		}
	}

	ngOnInit() {
		this.itemForm.get('price').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.price = value;
		});
	}

	public save() {
		this.loading = true;
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<PickupLocationPrice> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

}
