import { Component, OnInit, Inject } from '@angular/core';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Supplier } from 'app/models/supplier.model';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { SupplierResourceBladeComponent } from '../../supplier-resource/supplier-resource-blade/supplier-resource-blade.component';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';

@Component({
	selector: 'app-supplier-blade',
	templateUrl: './supplier-blade.component.html',
	styleUrls: ['./supplier-blade.component.scss'],
})
@IBladeComponent.register
export class SupplierBladeComponent implements OnInit {

	public selectedIndex = 1;
	public menuItems: BladeMenuItem[] = [
		{ title: 'Generellt', index: 1 },
		{ title: 'Resurser', index: 2 },
		{ title: 'Turer', index: 3 },
		{ title: 'Turer program', index: 4 },
	];
	public loading = true;
	public item: Supplier;
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.loading = true;
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						} else {
							this.loading = false;
						}
					});
			},
		},
	];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: SupplierStore,
		private bladePositionService: BladePositionService,
		private bladeService: BladeService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new Supplier();
		}
	 }

	ngOnInit() {
	}

	public save() {
		this.loading = true;
		this.saveOrUpdate().subscribe(item => {
			this.item = _.assign(this.item, item);
			this.bladeItem.minClass = BladeSize.m;
			this.bladeItem.maxClass = BladeSize.m;
			this.bladeItem.activeClass = this.bladeItem.maxClass;
			this.bladePositionService.selectBladeItem(this.bladeItem, true);
			this.loading = false;
		});
	}

	public addNewSupplierResource() {
		this.bladeService.addBladeItem({
			id: 0,
			component: SupplierResourceBladeComponent,
			minClass: BladeSize.xs,
			payload: this.item.id,
		}, this.bladeItem);
	}

	private saveOrUpdate(): Observable<Supplier> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

	public deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
			this.loading = false;
		});
	}
}
