import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { Customer } from 'app/models/customer.model';

@Component({
	selector: 'app-settings-generally',
	templateUrl: './settings-generally.component.html',
	styleUrls: ['./settings-generally.component.scss'],
})
export class SettingsGenerallyComponent implements OnInit {
	@Input() customer: Customer;

	public itemForm: FormGroup = new FormGroup({
		firstName: new FormControl('', [Validators.required]),
		lastName: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required]),
		phone: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public supplierStore: SupplierStore,
	) {}

	ngOnInit() {
		// this.itemForm.get('firstName').setValue(this.customer.firstName);
		// this.itemForm.get('lastName').setValue(this.customer.lastName);
		// this.itemForm.get('email').setValue(this.customer.email);
		// this.itemForm.get('phone').setValue(this.customer.phone);

		// this.itemForm.get('firstName').valueChanges.subscribe(value => {
		// 	this.customer.firstName = value;
		// });
		// this.itemForm.get('lastName').valueChanges.subscribe(value => {
		// 	this.customer.lastName = value;
		// });
		// this.itemForm.get('email').valueChanges.subscribe(value => {
		// 	this.customer.email = value;
		// });
		// this.itemForm.get('phone').valueChanges.subscribe(value => {
		// 	this.customer.phone = value;
		// });
	}
}
