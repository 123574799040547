<h4>Presentationsbild</h4>
<p>Denna kommer visas i listor</p>
<div class="row">
	<div class="col-md-6">
		<app-image *ngIf="presentationImage?.imageId" [maxHeigth]="100" [maxWidth]="100" [mediaId]="presentationImage?.imageId"></app-image>
	</div>
	<div class="col-md-6">
		<button mat-icon-button color="warn" (click)="deleteImage(presentationImage)" class="float-right">
			<mat-icon>delete_forever</mat-icon>
		</button>
		<button mat-stroked-button color="accent" (click)="selectPresentationImage()" class="float-right">
			<span *ngIf="presentationImage?.imageId">Ändra presentationsbild</span>
			<span *ngIf="!presentationImage?.imageId">Välj presentationsbild</span>
		</button>
	</div>
</div>

<h4 class="mt-3">Bilder</h4>
<p>Dessa kommer visas i ett bildspel om det är fler än en bild</p>

<mat-table [dataSource]="dataSource" class="w-100" *ngIf="dataSource?.data?.length">
	<ng-container matColumnDef="id">
		<mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
		<mat-cell *matCellDef="let item">{{item.id || 'Ny'}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="media">
		<mat-header-cell *matHeaderCellDef>Media</mat-header-cell>
		<mat-cell *matCellDef="let item">
			<app-image [maxHeigth]="50" [maxWidth]="100" [mediaId]="item.imageId"></app-image>
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="prio">
		<mat-header-cell *matHeaderCellDef>Prio</mat-header-cell>
		<mat-cell *matCellDef="let item">{{item.prio}}</mat-cell>
	</ng-container>
	<ng-container matColumnDef="actions">
		<mat-header-cell *matHeaderCellDef></mat-header-cell>
		<mat-cell *matCellDef="let item" class="d-block text-right">
			<button mat-icon-button color="warn" (click)="deleteImage(item)">
				<mat-icon>delete_forever</mat-icon>
			</button>
		</mat-cell>
	</ng-container>
	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row *matRowDef="let item; columns: displayedColumns;"></mat-row>
</mat-table>

<button mat-stroked-button color="accent" (click)="selectImage()" class="float-right mt-2">
	Lägg till bild
</button>
