<ng-container *ngIf="{
	tourBookingMessages: tourBookingMessageService.tourBookingMessagesReplay$ | async
} as source">
	<h2 mat-dialog-title>Redigera meddelande på tourBooking</h2>
	<mat-dialog-content>
		<p *ngIf="!source.tourBookingMessages?.length">Det finns inget meddelande.</p>
		<form [formGroup]="tourBookingMessageService.formGroup">
			<mat-form-field class="w-100" appearance="outline">
				<textarea matInput formControlName="message" placeholder="Meddelande" rows="7"></textarea>
			</mat-form-field>
		</form>

		<div class="text-right mt-3 pt-3 border-top">
			<button mat-raised-button color="primary" [disabled]="tourBookingMessageService.isSaving$ | async" (click)="tourBookingMessageService.saveOrUpdateMessage()">Spara</button>
		</div>
	</mat-dialog-content>
</ng-container>
