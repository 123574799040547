import { BaseModel } from './base.model';
import { Media } from './media.model';

export class ImageElement extends BaseModel {
	public elementId?: number;
	public languageId?: number;
	public title?: string;
	public path?: string;
	public mediaId?: number;
	public media?: Media;
}
