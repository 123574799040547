import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EmailQueueService } from './services/email-queue.service';

@Component({
  selector: 'email-queue',
  templateUrl: './email-queue.component.html',
  styleUrls: ['./email-queue.component.scss'],
  providers: [EmailQueueService],
})
export class EmailQueueComponent implements OnInit, OnChanges {

	@Input() headline: string = 'Email';
	@Input() entityName: string;
	@Input() entityId: number;
	@Input() emailQueueType: string;

  	constructor(public emailQueueService: EmailQueueService) { }

	ngOnChanges(changes: SimpleChanges): void {
		this.emailQueueService.getQueue(this.entityName, this.entityId);
	}

  	ngOnInit() {
  	}

	public senNewEmail() {
		this.emailQueueService.senNewEmail(this.emailQueueType);
	}
}
