<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem?.id">
	<app-blade-header [title]="'Mat card'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content [menuItems]="menuItems" (indexChange)="selectedIndex = $event; menuChange($event)">
		<mat-card>
			<div *ngIf="activeItem && selectedIndex !== 99">
				<mat-card-title>Liten bild</mat-card-title>
				<div class="row">
					<div class="col-md-4">
						<app-image *ngIf="activeItem.smallMediaId" [maxHeigth]="100" [maxWidth]="100" [mediaId]="activeItem.smallMediaId"></app-image>
					</div>
					<div class="col-md-8">
						<button *ngIf="activeItem.smallMediaId" mat-flat-button color="warn" (click)="deleteImage(activeItem.smallMediaId, 'small')" class="float-right ml-2">
							Ta bort <mat-icon>delete_forever</mat-icon>
						</button>
						<button mat-flat-button [color]="'primary'" (click)="selectImage('small')" class="float-right">
							<span *ngIf="activeItem.smallMediaId">Ändra liten bild</span>
							<span *ngIf="!activeItem.smallMediaId">Välj liten bild</span>
						</button>
					</div>
				</div>

				<mat-card-title class="mt-3">Bild</mat-card-title>
				<div class="row">
					<div class="col-md-4">
						<app-image *ngIf="activeItem.mediaId" [maxHeigth]="100" [maxWidth]="100" [mediaId]="activeItem.mediaId"></app-image>
					</div>
					<div class="col-md-8">
						<button *ngIf="activeItem.mediaId" mat-flat-button color="warn" (click)="deleteImage(activeItem.mediaId, 'normal')" class="float-right ml-2">
							Ta bort <mat-icon>delete_forever</mat-icon>
						</button>
						<button mat-flat-button [color]="'primary'" (click)="selectImage('normal')" class="float-right">
							<span *ngIf="activeItem.mediaId">Ändra bild</span>
							<span *ngIf="!activeItem.mediaId">Välj bild</span>
						</button>
					</div>
				</div>

				<form class="mt-3" [formGroup]="itemForm">
					<mat-form-field class="w-100">
						<input matInput formControlName="title" placeholder="Titel">
					</mat-form-field>
					<mat-form-field class="w-100">
						<input matInput formControlName="subTitle" placeholder="Undertitel">
					</mat-form-field>
					<app-text-editor *ngIf="showEditor" [size]="'small'" [label]="'Text'" [textModel]="itemForm?.get('text')"></app-text-editor>
				</form>
			</div>
			<app-element-blade-styles *ngIf="activeItem && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="activeItem?.id">Spara</span>
			<span *ngIf="!activeItem?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
