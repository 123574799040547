
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from '../base.store.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CategoryProduct } from 'app/models/category/category-product.model';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class CategoryProductStore extends BaseStore<CategoryProduct> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'CategoryProducts', baseUrl, snackBarService);
	}

	public getAllOnParent(parentCategoryId: number): Observable<CategoryProduct[]> {
		return this.http.get<CategoryProduct[]>(`${this.host}/parent/${parentCategoryId}`).pipe(tap(categoryTexts => {
			this._items = categoryTexts;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnChild(childProductId: number): Observable<CategoryProduct[]> {
		return this.http.get<CategoryProduct[]>(`${this.host}/child/${childProductId}`).pipe(tap(categoryTexts => {
			this._items = categoryTexts;
			this._itemsSubject.next(this._items);
		}));
	}

	public addDelete(categoryId: number, addCategoryProducts: CategoryProduct[], deleteIds: number[]): Observable<void> {
		const addDeleteObj = {
			addCategoryProducts,
			deleteIds,
		};
		return this.http.post<void>(`${this.host}/addDelete/${categoryId}`, addDeleteObj);
	}
}
