<app-blade-item [canIClose]="true" *ngIf="{
	term: termBladeService.termReplay$ | async,
	isLoading: termBladeService.isLoadingBehavior$ | async
} as source" [loading]="source.isLoading">
	<app-blade-header [title]="source.term?.name||'Ny'" [subTitle]="'Villkor'"></app-blade-header>
	<app-blade-content *ngIf="source.term">
		<app-blade-content-tabs>
			<app-blade-content-tab [title]="'Generellt'" [template]="generally">
				<ng-template #generally>
					<app-term-blade-generally></app-term-blade-generally>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Texter'" [template]="texts" [disabled]="!source.term?.id">
				<ng-template #texts>
					<app-term-blade-texts></app-term-blade-texts>
				</ng-template>
			</app-blade-content-tab>
		</app-blade-content-tabs>
		<pre>{{source.term|json}}</pre>
	</app-blade-content>
</app-blade-item>
