import { Component, OnInit } from '@angular/core';

import { MinFunctionService } from '../../services/min-function.service';

@Component({
	selector: 'app-min-function-blade-messages',
	templateUrl: './function-blade-messages.component.html',
	styleUrls: ['./function-blade-messages.component.scss'],
})
export class MinFunctionBladeMessagesComponent implements OnInit {
	constructor(
		public productFunctionService: MinFunctionService,
	) {

	}

	ngOnInit(): void {
	}
}
