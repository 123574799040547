
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { Prio } from 'app/models/site/prio.model';
import { BasePrioModel } from 'app/models/base-prio.model';

@Injectable({
	providedIn: 'root',
})
export abstract class PrioBaseStore<T extends BasePrioModel> extends BaseStore<T> {

	constructor(protected http: HttpClient, path: string, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, path, baseUrl, snackBarService);
	}

	public updatePrio(elements: T[]): Observable<T> {
		const updateObjects: Prio[] = [];
		elements.forEach((element, index) => {
			if (element) {
				updateObjects.push({
					id: element.id,
					prio: element.prio,
				});
			}
		});
		return this.http.post<T>(`${this.host}/prio`, updateObjects);
	}
}
