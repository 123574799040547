import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as _ from 'lodash';

import { DashboardWidgetSizeRowsColumns } from '../../../../models/dashboard-widget-size-rows-columns.model';
import { DashboardWidget } from '../../../../models/dashboard-widget.model';
import { DashboardWidgetSize } from '../../../../models/dashboard-widget-size.model';


@Component({
	selector: 'app-dashboard-widget-modal',
	templateUrl: './dashboard-widget-modal.component.html',
	styleUrls: ['./dashboard-widget-modal.component.scss'],
})
export class DashboardWidgetModalComponent implements OnInit {
	public selectedSize: DashboardWidgetSizeRowsColumns;
	public workingWidget: DashboardWidget;
	private sizes = new DashboardWidgetSize();
	public widgetSizes: DashboardWidgetSizeRowsColumns[] = [
		this.sizes.xtraSmall,
		this.sizes.small,
		this.sizes.smallHorizontal,
		this.sizes.smallVerticle,
		this.sizes.medium,
		this.sizes.mediumHorizontal,
		this.sizes.mediumVerticle,
		this.sizes.large,
		this.sizes.largeHorizontal,
		this.sizes.largeVerticle,
		this.sizes.xtraLarge,
		this.sizes.xtraLargeHorizontal,
		this.sizes.xtraLargeVerticle,
	];

	constructor(
		public dialogRef: MatDialogRef<DashboardWidgetModalComponent>,
		@Inject(MAT_DIALOG_DATA) public dashboardWidget: DashboardWidget,
	) {
		this.workingWidget = _.cloneDeep(dashboardWidget);
	}

	ngOnInit() {
	}

	public save() {
		this.dashboardWidget.size = this.selectedSize;
		this.dialogRef.close();
	}
}
