import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AdminDataService } from '../services/admin-data.service';
import { AdminDataStore } from '../stores/admin-data.store';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Pipe({
  name: 'adminText',
})
export class AdminTextPipe implements PipeTransform {
	constructor(
		private adminDataStore: AdminDataStore,
		private adminDataService: AdminDataService,
	)
	{ }

	transform(property: string, languagePrefix?: string): Observable<any> {
		const adminText = this.adminDataStore.getAdminText();
		if (languagePrefix) {
			return of(_.get(adminText[languagePrefix], property));
		}
		return this.adminDataService.selectedLanguage$.pipe(map(selectedLanuage => {
			return _.get(adminText[selectedLanuage], property);
		}));
	}
}
