import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';


import { AnalyticsWidgetTourBookingService } from './services/tour-booking.service';
import { AnalyticsWidgetTourBookingStore } from './stores/tour-booking.store';
import { NumericDictionary } from 'lodash';

@Component({
	selector: 'app-analytics-widget-tour-booking',
	templateUrl: './tour-booking.component.html',
	styleUrls: ['./tour-booking.component.scss'],
	providers: [
		AnalyticsWidgetTourBookingService,
	],
})
export class AnalyticsWidgetTourBookingComponent implements OnInit {

	// @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

	@Input() mode: number;

	constructor(
		public analyticsWidgetTourBookingService: AnalyticsWidgetTourBookingService,
	) { }

	ngOnInit() {
		this.analyticsWidgetTourBookingService.init(this.mode);
	}
}
