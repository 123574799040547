import { BladeItem } from './bladeItem.model';

export class BladeQuery {

	/**
	 * Id
	 */
	public id?: number;

	/**
	 * Name
	 */
	public n?: string;

	/**
	 * Selected menu index
	 */
	public mi?: number;

	/**
	 * payload
	 */
	public p?: any;

	/**
	 * Min class
	 */
	public minc?: string;

	/**
	 * Max class
	 */
	public maxc?: string;

	constructor(bladeItem: BladeItem, routerName: string) {
		this.id = bladeItem.id;
		this.mi = bladeItem.menuIndex;
		this.n = routerName;
		this.p = bladeItem.payload;
		this.minc = bladeItem.minClass;
		this.maxc = bladeItem.maxClass;

	}
}
