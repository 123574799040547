import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ColumnLinkType } from 'app/models/column-link-type.model';

@Injectable({
	providedIn: 'root',
})
export class ColumnLinkTypeStore extends BaseStore<ColumnLinkType> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ColumnLinkType', baseUrl, snackBarService);
	}
}
