<app-blade-item [canIClose]="true">
	<app-blade-header [title]="'Leverantörer'"></app-blade-header>
	<app-blade-content>
		<form [formGroup]="searchForm">
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Filtrera på namn</mat-label>
				<input matInput formControlName="name">
			</mat-form-field>		
		</form>
		<div class="mat-elevation-z1">
			<table mat-table [dataSource]="dataSource" matSort class="w-100">
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
					<td mat-cell *matCellDef="let item"><strong>{{item.name}}</strong></td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.id === item?.id" [class.selected]="bladeItem?.child?.id === item?.id"></tr>
			</table>

			<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</div>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="createNew()">Skapa ny</button>
	</app-blade-footer>
</app-blade-item>
