<form [formGroup]="itemForm">
	<mat-form-field class="w-100">
		<mat-label>Hämtningsplats</mat-label>
		<mat-select [formControlName]="'pickupLocationId'">
			<mat-option *ngFor="let type of (pickupLocationStore.items$ | async)" [value]="type.id">
				{{type.name}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field class="w-100">
		<input matInput type="number" [min]="0" [max]="maxQuantity" formControlName="quantity" placeholder="Antal">
	</mat-form-field>
</form>
