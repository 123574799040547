import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { DashboardPageComponent } from './pages/dashboard/dashboard.component';
import { OrdersPageComponent } from './pages/orders/orders.component';
import { ToursPageComponent } from './pages/tours/tours.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { ToursNewComponent } from './pages/tours/children/tours-new/tours-new.component';
import { ToursStatisticsComponent } from './pages/tours/children/tours-statistics/tours-statistics.component';
import { ToursDashboardComponent } from './pages/tours/children/tours-dashboard/tours-dashboard.component';
import { OrdersDashboardComponent } from './pages/orders/children/orders-dashboard/orders-dashboard.component';
import { OrdersNewComponent } from './pages/orders/children/orders-new/orders-new.component';
import { OrdersStatisticsComponent } from './pages/orders/children/orders-statistics/orders-statistics.component';
import { BladePageComponent } from './components/blade/blade-system/components/blade-page-component/blade-page.component';
import { BladeSize } from './components/blade/models/bladeSize.model';
import { ToursBladeComponent } from './blades/tour/tours-blade/tours-blade.component';
import { ProductsBladeComponent } from './blades/product/products-blade/products-blade.component';
import { ProductsPageComponent } from './pages/products/products.component';
import { ProductsDashboardComponent } from './pages/products/children/products-dashboard/products-dashboard.component';
import { ProductsStatisticsComponent } from './pages/products/children/products-statistics/products-statistics.component';
import { ProductsNewComponent } from './pages/products/children/products-new/products-new.component';
import { SuppliersBladeComponent } from './blades/supplier/suppliers-blade/suppliers-blade.component';
import { CategoriesPageComponent } from './pages/categories/categories.component';
import { CategoriesDashboardComponent } from './pages/categories/children/categories-dashboard/categories-dashboard.component';
import { CategoriesBladeComponent } from './blades/category/categories-blade/categories-blade.component';
import { CategoriesNewComponent } from './pages/categories/children/categories-new/categories-new.component';
import { CategoriesStatisticsComponent } from './pages/categories/children/categories-statistics/categories-statistics.component';
import { HomepagePageComponent } from './pages/homepage/homepage.component';
import { HomepageDashboardComponent } from './pages/homepage/children/homepage-dashboard/homepage-dashboard.component';
import { PagesBladeComponent } from './blades/page/pages-blade/pages-blade.component';
import { SiteSettingsBladeComponent } from './blades/site-settings-blade/site-settings-blade.component';
import { OrdersBladeComponent } from './blades/order/orders-blade/orders-blade.component';
import { CustomersBladeComponent } from './blades/customer/customers-blade/customers-blade.component';
import { CustomersPageComponent } from './pages/customers/customers.component';
import { CustomersDashboardComponent } from './pages/customers/children/customers-dashboard/customers-dashboard.component';
import { CustomersProgramComponent } from './pages/customers/children/customers-program/customers-program.component';
import { CustomersNewComponent } from './pages/customers/children/customers-new/customers-new.component';
import { SettingsPageComponent } from './pages/settings/settings.component';
import { SettingsBladeComponent } from './blades/settings/settings-blade/settings-blade.component';
import { AddonsBladeComponent } from './blades/addon/addons-blade/addons-blade.component';
import { PickupLocationsBladeComponent } from './blades/pickup-location/pickup-locations-blade/pickup-locations-blade.component';
import { AuthGuard } from './gards/auth.gard';
import { AnalyticsPageComponent } from './pages/analytics/analytics.component';
import { SystemsPageComponent } from './pages/system/systems.component';
import { SystemsBladeComponent } from './blades/system/systems-blade/systems-blade.component';
import { PlannerPageComponent } from './pages/planner/planner.component';
import { PlannerResourceBladeComponent } from './blades/planner/planner-resource-blade/planner-resource-blade.component';
import { EmailsBladeComponent } from './blades/email/emails-blade/emails-blade.component';
import { TravelAgenciesBladeComponent } from './blades/travel-agency/travel-agencies-blade/travel-agencies-blade.component';
import { GuideToursBladeComponent } from './blades/guide/guide-tours-blade/guide-tours-blade.component';
import { GuideToursPageComponent } from './pages/guide/guide-tours.component';
import { ProductFunctionsBladeComponent } from './blades/product-functions/product-functions-blade/product-functions-blade.component';
import { UsersBladeComponent } from './blades/user/users-blade/users-blade.component';
import { UsersPageComponent } from './pages/users/users.component';
import { PaymentsPageComponent } from './pages/payments/payments.component';
import { PaymentsBladeComponent } from './blades/payment/payments-blade/payments-blade.component';
import { ToursProgramComponent } from './pages/suppliers/children/tours-program/tours-program.component';
import { ToursCustomersProgramComponent } from './pages/tours/children/tours-customers-program/tours-customers-program.component';
import { SpecialDietsBladeComponent } from './blades/special-diet/special-diets-blade/special-diets-blade.component';
import { PlannerGuidesToursBladeComponent } from './blades/planner/planner-guides-tours-blade/planner-guides-tours-blade.component';
import { ReportPageComponent } from './pages/report/report.component';
import { SpecialDietReportComponent } from './pages/report/pages/special-diet-report/special-diet-report.component';
import { TermsBladeComponent } from './blades/term/terms-blade/terms-blade.component';
import { CustomerProgramPage } from './pages/customers/children/customer-program/customer-program.component';


const routes: Routes = [
	{
		path: '',
		component: DashboardPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
	},
	{
		path: 'wiki',
		loadChildren: () => import('../modules/wiki/wiki.module').then(m => m.WikiModule),
	},
	{
		path: 'orders', component: OrdersPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{ path: '', component: OrdersDashboardComponent },
			{
				path: 'list', component: BladePageComponent,
				data: {
					bladeItem: {
						component: OrdersBladeComponent,
						minClass: BladeSize.xl,
						maxClass: BladeSize.xl,
					},
				},
			},
			{ path: 'new', component: OrdersNewComponent },
			{ path: 'statistics', component: OrdersStatisticsComponent },
		],
	},
	{
		path: 'analytics', component: AnalyticsPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
	},
	{
		path: 'tours', component: ToursPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{ path: '', component: ToursDashboardComponent },
			{
				path: 'list', component: BladePageComponent,
				data: {
					bladeItem: {
						component: ToursBladeComponent,
						minClass: BladeSize.xl,
						maxClass: BladeSize.xl,
					},
				},
			},
			{ path: 'new', component: ToursNewComponent },
			{ path: 'statistics', component: ToursStatisticsComponent },
			{ path: 'customer-program', component: ToursCustomersProgramComponent },
		],
	},
	{
		path: 'guide-tours', component: GuideToursPageComponent,
		runGuardsAndResolvers: 'always',
		canActivate: [AuthGuard],
		children: [
			{
				path: 'list', component: BladePageComponent,
				data: {
					bladeItem: {
						component: GuideToursBladeComponent,
						minClass: BladeSize.m,
						maxClass: BladeSize.l,
					},
				},
			},
		],
	},

	{
		path: 'products', component: ProductsPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{ path: '', component: ProductsDashboardComponent },
			{
				path: 'list', component: BladePageComponent,
				data: {
					bladeItem: {
						component: ProductsBladeComponent,
						minClass: BladeSize.m,
						maxClass: BladeSize.l,
					},
				},
			},
			{ path: 'new', component: ProductsNewComponent },
			{ path: 'statistics', component: ProductsStatisticsComponent },
		],
	},

	{
		path: 'reports', component: ReportPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{ path: 'special-diet', component: SpecialDietReportComponent },
		],
	},

	{
		path: 'addon/list', component: BladePageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		data: {
			bladeItem: {
				component: AddonsBladeComponent,
				minClass: BladeSize.s,
				maxClass: BladeSize.s,
			},
		},
	},
	{
		path: 'special-diet/list', component: BladePageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		data: {
			bladeItem: {
				component: SpecialDietsBladeComponent,
				minClass: BladeSize.s,
				maxClass: BladeSize.s,
			},
		},
	},
	{
		path: 'categories', component: CategoriesPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{ path: '', component: CategoriesDashboardComponent },
			{
				path: 'list', component: BladePageComponent,
				data: {
					bladeItem: {
						component: CategoriesBladeComponent,
						minClass: BladeSize.m,
						maxClass: BladeSize.l,
					},
				},
			},
			{ path: 'new', component: CategoriesNewComponent },
			{ path: 'statistics', component: CategoriesStatisticsComponent },
		],
	},
	{
		path: 'suppliers', component: CustomersPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{
				path: 'list', component: BladePageComponent,
				data: {
					bladeItem: {
						component: SuppliersBladeComponent,
						minClass: BladeSize.xs,
					},
				},
			},
			{ path: 'tour-program', component: ToursProgramComponent },
		],
	},
	{
		path: 'email', component: BladePageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		data: {
			bladeItem: {
				component: EmailsBladeComponent,
				minClass: BladeSize.m,
			},
		},
	},
	{
		path: 'pickup-loations', component: BladePageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		data: {
			bladeItem: {
				component: PickupLocationsBladeComponent,
				minClass: BladeSize.xs,
			},
		},
	},
	{
		path: 'travel-agencies', component: BladePageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		data: {
			bladeItem: {
				component: TravelAgenciesBladeComponent,
				minClass: BladeSize.xs,
			},
		},
	},
	{
		path: 'my-page',
		component: UserPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
	},
	{
		path: 'homepage', component: HomepagePageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{ path: '', redirectTo: 'wysiwyg', pathMatch: 'full' },
			// { path: 'settings', component: BladePageComponent,
			// 	data: {
			// 		bladeItem: {
			// 			component: SiteSettingsBladeComponent,
			// 			minClass: BladeSize.m,
			// 		},
			// 	},
			// },
			// { path: 'pages', component: BladePageComponent,
			// 	data: {
			// 		bladeItem: {
			// 			component: PagesBladeComponent,
			// 			minClass: BladeSize.s,
			// 		},
			// 	},
			// },
			{
				path: 'wysiwyg',
				loadChildren: () => import('../modules/homepage-wysiwyg/homepage-wysiwyg.module').then(m => m.HomepageWysiwygModule),
			},
		],
	},
	{
		path: 'customer',
		loadChildren: () => import('../modules/customer/customer.module').then(m => m.CustomerModule),
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
	},
	{
		path: 'tour',
		loadChildren: () => import('../modules/tour/tour.module').then(m => m.TourModule),
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
	},
	{
		path: 'customers', component: CustomersPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{ path: '', component: CustomersDashboardComponent },
			{
				path: 'list', component: BladePageComponent,
				data: {
					bladeItem: {
						component: CustomersBladeComponent,
						minClass: BladeSize.m,
						maxClass: BladeSize.l,
					},
				},
			},
			{ path: 'new', component: CustomersNewComponent },
			{ path: 'program', component: CustomersProgramComponent },
			{ path: 'program-v2', component: CustomerProgramPage },
		],
	},
	{
		path: 'users', component: UsersPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{
				path: 'list', component: BladePageComponent,
				data: {
					bladeItem: {
						component: UsersBladeComponent,
						minClass: BladeSize.m,
						maxClass: BladeSize.l,
					},
				},
			},
		],
	},
	{
		path: 'payments', component: PaymentsPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{
				path: 'list', component: BladePageComponent,
				data: {
					bladeItem: {
						component: PaymentsBladeComponent,
						minClass: BladeSize.m,
						maxClass: BladeSize.l,
					},
				},
			},
		],
	},
	{
		path: 'settings', component: SettingsPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{
				path: '', component: BladePageComponent,
				data: {
					bladeItem: {
						component: SettingsBladeComponent,
						minClass: BladeSize.m,
						maxClass: BladeSize.l,
					},
				},
			},
		],
	},
	{
		path: 'planner', component: PlannerPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [{
			path: 'resource', component: BladePageComponent,
			data: {
				bladeItem: {
					component: PlannerResourceBladeComponent,
					minClass: BladeSize.xl,
				},
			},
		}, {
			path: 'guideTours', component: BladePageComponent,
			data: {
				bladeItem: {
					component: PlannerGuidesToursBladeComponent,
					minClass: BladeSize.xxl,
				},
			},
		},
		],
	},
	{
		path: 'system', component: SystemsPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		children: [
			{
				path: '', component: BladePageComponent,
				data: {
					bladeItem: {
						component: SystemsBladeComponent,
						minClass: BladeSize.s,
					},
				},
			},
		],
	},
	{
		path: 'product-functions', component: BladePageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		data: {
			bladeItem: {
				component: ProductFunctionsBladeComponent,
				minClass: BladeSize.s,
			},
		},
	},
	{
		path: 'media',
		loadChildren: () => import('../modules/media/media.module').then(m => m.MediaModule),
	}, {
		path: 'terms', component: BladePageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
		data: {
			bladeItem: {
				component: TermsBladeComponent,
				minClass: BladeSize.s,
			},
		},
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
})
export class AdminRoutingModule { }
