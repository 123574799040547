import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { TourBookingAddonService } from './services/tour-booking-addon.service';

@Component({
  	selector: 'app-tour-booking-addon',
	templateUrl: './tour-booking-addon.component.html',
  	styleUrls: ['./tour-booking-addon.component.scss'],
	providers: [TourBookingAddonService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourBookingAddonComponent implements OnInit {


	constructor(
		public tourBookingAddonService: TourBookingAddonService,
	) { }

	ngOnInit() {
	}
}
