import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tours-new',
  templateUrl: './tours-new.component.html',
  styleUrls: ['./tours-new.component.scss'],
})
export class ToursNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
