
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from '../base.store.service';
import { ProductImage } from 'app/models/product/product-image.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ProductImageStore extends BaseStore<ProductImage> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductImages', baseUrl, snackBarService);
	}

	public getAllOnProduct(productId: number): Observable<ProductImage[]> {
		this._items = [];
		this._itemsSubject.next(this._items);
		return this.http.get<ProductImage[]>(`${this.host}/product/${productId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

}
