import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReplaySubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { BaseStore } from './base.store.service';
import { Language } from 'app/models/language.model';
import { SlideshowCategory } from 'app/models/slideshow-category.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class SlideshowCategoryStore extends BaseStore<SlideshowCategory> {
	// private allOnElement: SlideshowCategory[];
	// protected allOnElementSubject = new ReplaySubject<SlideshowCategory[]>(1);
	// public allOnElement$ = this.allOnElementSubject.asObservable();

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SlideshowCategory', baseUrl, snackBarService);
	}

	public GetOnSlideshowItem(slideshowItemId: number): Observable<SlideshowCategory> {
		return this.http.get<SlideshowCategory>(`${this.host}/slideshowItem/${slideshowItemId}`);
	}
}
