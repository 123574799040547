import { Component, Inject, OnDestroy } from '@angular/core';

import { combineLatest, of, race } from 'rxjs';
import { delay, switchMap, take } from 'rxjs/operators';
import * as _ from 'lodash';

import { CustomerTypeOrder } from '../../models/customer-type-order.model';
import { SelectProductToOrderBladeService } from '../../services/create-order-tour-booking-blade.service';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingCustomerTypeStore } from 'app/admin/stores/tour-booking-customer-type.store.service';
import { OrderItemTypeEnum } from '../../enums/order-item-type.enum';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import { OrderWrapper } from '../../models/order-wrapper.model';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { CustomerType } from 'app/models/customer-type.model';
import { CreateOrderModeEnum } from 'app/enums/create-order-mode.enum';
import { UserService } from 'app/admin/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProductDateTime } from 'app/models/product/product-date-time.model';
import { TravelAgencyProductDiscount } from 'app/models/travel-agency-product-discount.model';
import { ProductPrice } from 'app/models/product/product-price.model';
import { User } from 'app/admin/models/user.model';
import { ProductDateTimeCustomerTypeStore } from 'app/admin/stores/product/product-date-time-customer-type.store.service';
import { ProductDateTimeCustomerType } from 'app/models/product/product-date-time-customer-type.model';
import { OrderPart } from 'app/models/order/OrderPart';

@UntilDestroy()
@Component({
	selector: 'app-select-product-to-order-customer-types',
	templateUrl: './select-product-to-order-customer-types.component.html',
	styleUrls: ['./select-product-to-order-customer-types.component.scss'],
})
export class SelectProductToOrderCustomerTypesComponent implements OnDestroy {
	public displayedColumns: string[] = ['type', 'quantity', 'unitPrice'];
	private customerTypeOrder: CustomerTypeOrder[] = [];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public selectProductToOrderBladeService: SelectProductToOrderBladeService,
		private tourBookingCustomerTypeStore: TourBookingCustomerTypeStore,
		private customerTypeStore: CustomerTypeStore,
		public userService: UserService,
		private productDateTimeCustomerTypeStore: ProductDateTimeCustomerTypeStore,
	) {
		if (bladeItem.payload.mode === CreateOrderModeEnum.onTourBooking || bladeItem.payload.mode === CreateOrderModeEnum.onTour) {
			combineLatest([
				bladeItem.payload.mode === CreateOrderModeEnum.onTourBooking ? this.tourBookingCustomerTypeStore.getAllOnTourBooking(this.bladeItem.id) : of([]),
				this.selectProductToOrderBladeService.ordersBehavior$,
				this.customerTypeStore.getAll$(),
				this.selectProductToOrderBladeService.orderPartBehavior$,
			]).pipe(take(1), untilDestroyed(this)).subscribe(([tourBookingCustomerTypes, orders, customerTypes, orderPart]) => {
				this.handleCustomerTypesFromOrder(tourBookingCustomerTypes, orders, customerTypes, orderPart);
			});
		} else if (bladeItem.payload.mode === CreateOrderModeEnum.newOrder) {
			combineLatest([
				race(
					// When admin select a date that dont excist this will never fire. Therefor this race.
					this.selectProductToOrderBladeService.selectedTimeReplay$.pipe(switchMap(time => {
						if (time.id) {
							return this.productDateTimeCustomerTypeStore.getAllOnProductDateTime(time.id);
						} else {
							return of([]);
						}
					})),
					of([] as ProductDateTimeCustomerType[]).pipe(delay(500)),
				),
				// this.selectProductToOrderBladeService.selectedTimeReplay$.pipe(switchMap(time => {
				// 	return this.productDateTimeCustomerTypeStore.getAllOnProductDateTime(time.id);
				// })),
				this.selectProductToOrderBladeService.travelAgencyProductDiscountBehavior$,
				this.selectProductToOrderBladeService.productPricesReplay$,
				this.customerTypeStore.getAll$(),
				this.selectProductToOrderBladeService.orderPartBehavior$,
			]).pipe(untilDestroyed(this)).subscribe(([productDateTimeCustomerTypes, travelAgencyProductDiscount, productPrices, customerTypes, orderPart]) => {
				this.handleCustomerTypesFromProduct(productDateTimeCustomerTypes, travelAgencyProductDiscount, productPrices, customerTypes, orderPart);
			});
		}
	}

	ngOnDestroy(): void { }

	private handleCustomerTypesFromOrder(
		tourBookingCustomerTypes: TourBookingCustomerType[],
		orders: OrderWrapper[],
		customerTypes: CustomerType[],
		orderPart: OrderPart,
	) {
		const unitPriceCustomerType = {};

		this.selectProductToOrderBladeService.getOrderItemsOfType(orders, OrderItemTypeEnum.customer).forEach((orderItem) => {
			if (typeof unitPriceCustomerType[orderItem.customerTypeId] === 'undefined') {
				unitPriceCustomerType[orderItem.customerTypeId] = 0;
			}
			if (orderItem.unitPrice && !unitPriceCustomerType[orderItem.customerTypeId]) {
				unitPriceCustomerType[orderItem.customerTypeId] = orderItem.unitPrice;
			}
		});

		this.customerTypeOrder = [];
		customerTypes.forEach(customerType => {
			const customerPartFromOrderPart = this.getCustomerTypeFromOrderPart(customerType.id, orderPart);
			if (customerPartFromOrderPart) {
				this.customerTypeOrder.push(customerPartFromOrderPart);
			} else {
				this.customerTypeOrder.push({
					customerTypeId: customerType.id,
					unitPrice: unitPriceCustomerType[customerType.id] || 0, // The price is by two decimals
				});
			}
		});

		this.selectProductToOrderBladeService.setCustomerTypeOrders(this.customerTypeOrder);
	}

	private handleCustomerTypesFromProduct(
		productDateTimeCustomerTypes: ProductDateTimeCustomerType[],
		travelAgencyProductDiscount: TravelAgencyProductDiscount,
		productPrices: ProductPrice[],
		customerTypes: CustomerType[],
		orderPart: OrderPart,
	) {
		this.customerTypeOrder = [];
		customerTypes.forEach(customerType => {
			const customerPartFromOrderPart = this.getCustomerTypeFromOrderPart(customerType.id, orderPart);
			if (customerPartFromOrderPart) {
				this.customerTypeOrder.push(customerPartFromOrderPart);
			} else {
				const productPrice = _.find(productPrices, price => price.customerTypeId === customerType.id);
				const productDateTimeCustomerType = _.find(productDateTimeCustomerTypes, type => type.customerTypeId === customerType.id);

				let priceToUse = productPrice?.price ?? 0;

				if (productDateTimeCustomerType) {
					if (productDateTimeCustomerType.price < 0) {
						// Discount
						priceToUse += productDateTimeCustomerType.price;
					} else if (productDateTimeCustomerType.price > 0) {
						priceToUse = productDateTimeCustomerType.price;
					}
				}

				if (travelAgencyProductDiscount) {
					priceToUse -= (priceToUse * (travelAgencyProductDiscount.discount));
				}

				this.customerTypeOrder.push({
					customerTypeId: customerType.id,
					unitPrice: priceToUse,
				});
			}
		});
		this.selectProductToOrderBladeService.setCustomerTypeOrders(this.customerTypeOrder);
	}

	public change() {
		this.selectProductToOrderBladeService.setCustomerTypeOrders(this.customerTypeOrder);
	}

	private getCustomerTypeFromOrderPart(customerTypeId: number, orderPart: OrderPart): CustomerTypeOrder {
		const findCustomerType = _.find(orderPart.customerTypes, ct => ct.customerTypeId === customerTypeId);
		if (findCustomerType) {
			return {
				customerTypeId,
				unitPrice: findCustomerType.unitPrice,
				quantity: findCustomerType.quantity,
			};
		}
		return null;
	}
}
