import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ProductStore } from '../stores/product/product.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'productName' })
export class ProductNamePipe implements PipeTransform, OnDestroy {
	constructor(private productStore: ProductStore) {	}
	public transform(productId: number): Observable<string> {
		if (productId === 0) {
			return of('Ingen vald');
		}
		if (!productId) {
			return;
		}
		return this.productStore.pipeGetName(productId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
