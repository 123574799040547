import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { CategoryStore } from 'app/admin/stores/category/category.store.service';
import { Category } from 'app/models/category/category.model';
import * as _ from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable()
export class CategoryBladeService {
	public selectedIndex = 1;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl(null),
		isActive: new FormControl(false),
	});

	private category: Category;
	private categoryReplay = new ReplaySubject<Category>();
	public categoryReplay$ = this.categoryReplay.asObservable();

	private isLoadingBehavior = new BehaviorSubject<boolean>(true);
	public isLoadingBehavior$ = this.isLoadingBehavior.asObservable();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: CategoryStore,
		private bladePositionService: BladePositionService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.category = item;
				this.categoryReplay.next(this.category);
				this.itemForm.patchValue(this.category);
				this.isLoadingBehavior.next(false);
			});
		} else {
			this.category = new Category();
			this.categoryReplay.next(this.category);
			this.isLoadingBehavior.next(false);
		}
	}

	public save() {
		this.isLoadingBehavior.next(true);
		this.category = Object.assign(this.category, this.itemForm.value);

		if (this.category.id) {
				this.itemStore.update(this.category).subscribe(category => {
					this.isLoadingBehavior.next(false);
					this.category = _.assign(this.category, category);
				});
		} else {
			this.itemStore.create(this.category).subscribe(category => {
				this.isLoadingBehavior.next(false);
				this.category = _.assign(this.category, category);
				this.bladeItem.minClass = BladeSize.m;
				this.bladeItem.maxClass = BladeSize.m;
				this.bladeItem.activeClass = this.bladeItem.maxClass;
				this.bladePositionService.selectBladeItem(this.bladeItem, true);
			});
		}
	}

	public deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.isLoadingBehavior.next(true);
		this.itemStore.delete(this.category.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
			this.isLoadingBehavior.next(false);
		});
	}

	public setLoading(isLoading: boolean) {
		this.isLoadingBehavior.next(isLoading);
	}
}
