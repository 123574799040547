import { BaseModel } from './base.model';
import { SlideshowCategory } from './slideshow-category.model';
import { SlideshowImage } from './slideshow-image.model';

export class SlideshowItem extends BaseModel {
	public slideshowElementId?: number;
	public slideshowItemTypeId?: number;
	public prio?: number;
	public slideshowCategory?: SlideshowCategory;
	public slideshowImages?: SlideshowImage[];
	public slideshowItemTypeIdentifier?: string;
}
