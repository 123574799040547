import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { PageTextStore } from 'app/admin/stores/page-text.store.service';
import { FormGroup, FormControl } from '@angular/forms';
import { PageText } from 'app/models/page-text.model';
import { Language } from 'app/models/language.model';

@Component({
	selector: 'app-page-text-item',
	templateUrl: './page-text-item.component.html',
	styleUrls: ['./page-text-item.component.scss']
})
export class PageTextItemComponent implements AfterContentInit {

	public pageText: PageText;
	public saving: boolean;

	@Input() language: Language;
	@Input() pageId: number;

	public itemForm: FormGroup = new FormGroup({
		headline: new FormControl(''),
	});

	constructor(private pageTextStore: PageTextStore) { }

	ngAfterContentInit(): void {
		if (this.pageId && this.language.id) {
			this.pageTextStore.getOnPageAndLanguage(this.pageId, this.language.id).subscribe(pageText => {
				if (pageText) {
					this.pageText = pageText;
					this.itemForm.get('headline').setValue(pageText.headline);
				}
			});
		}
	}

	public save() {
		this.saving = true;
		if (this.pageText && this.pageText.id) {
			this.pageText.headline = this.itemForm.get('headline').value;
			this.pageTextStore.update(this.pageText).subscribe(pageText => {
				this.pageText = pageText;
				this.saving = false;
			});

		} else {
			this.pageText = new PageText();
			this.pageText.languageId = this.language.id;
			this.pageText.headline = this.itemForm.get('headline').value;
			this.pageTextStore.create(this.pageText).subscribe(pageText => {
				this.pageText = pageText;
				this.saving = false;
			});
		}
	}

	public delete() {
		this.pageTextStore.delete(this.pageText.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.pageText.id = 0;
				this.pageText.headline = '';
				this.itemForm.get('headline').setValue('');
			}
		});
	}
}
