<app-blade-item [canIClose]="true">
   <app-blade-header [title]="'System'"></app-blade-header>
   <app-blade-content>
		<div class="mat-elevation-z1">
			<table mat-table [dataSource]="dataSource" matSort class="w-100">
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
					<td mat-cell *matCellDef="let item">{{item.id}} </td>
				</ng-container>
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
					<td mat-cell *matCellDef="let item"><strong>{{item.name}}</strong></td>
				</ng-container>
				<ng-container matColumnDef="host">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Url/Host</th>
					<td mat-cell *matCellDef="let item">{{item.host}}</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
			</table>

			<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</div>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="createNew()">Skapa ny</button>
	</app-blade-footer>
</app-blade-item>
