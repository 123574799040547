import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import * as _ from 'lodash';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Subject } from 'rxjs';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { SlideshowElement } from 'app/models/slideshow-element.model';
import { SlideshowElementStore } from 'app/admin/stores/slideshow-element.store.service';
import { ElementStore } from 'app/admin/stores/element.store.service';
import { Element } from 'app/models/element.model';
import { FormGroup, FormControl } from '@angular/forms';
import { JsonEditorOptions } from "@maaxgr/ang-jsoneditor";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-element-slideshow-blade',
	templateUrl: './element-blade-slideshow.blade.html',
	styleUrls: ['./element-blade-slideshow.blade.scss'],
})
@IBladeComponent.register
export class ElementSlideshowBlade implements OnInit, OnDestroy {
	public saving: Subject<boolean> = new Subject<boolean>();
	public selectedIndex = 0;
	public slideshowElement: SlideshowElement;
	public loading = true;
	public element: Element;
	public formGroup = new FormGroup({
		config: new FormControl(''),
	});
	public editorOptions: JsonEditorOptions = new JsonEditorOptions();
	public menuItems: BladeMenuItem[] = [
		{
			index: 1,
			title: 'Inställningar',
		}, {
			index: 2,
			title: 'Lista',
		}, {
			index: 99,
			title: 'Style',
		},
	];
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.deleteItem();
						}
					});
			},
		},
	];

	constructor(
		private elementStore: ElementStore,
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public slideshowElementStore: SlideshowElementStore,
	) {
		this.editorOptions.modes = ['code', 'text', 'tree', 'view'];

		this.elementStore.get(this.bladeItem.id).subscribe(element => {
			this.element = element;
		});
	}

	ngOnInit() {
		this.slideshowElement = new SlideshowElement();
		this.slideshowElement.elementId = this.bladeItem.id;

		this.formGroup.get('config').valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.slideshowElement.config = JSON.stringify(value));

		this.slideshowElementStore.getOnElement(this.bladeItem.id).subscribe(slideshowElement => {
			if (slideshowElement) {
				this.slideshowElement = slideshowElement;
				this.formGroup.get('config').setValue(JSON.parse(this.slideshowElement.config));
			}
			this.loading = false;
		});
	}

	ngOnDestroy() { }

	public save() {
		if (this.selectedIndex === 99) {
			this.saving.next(true);
			return;
		}

		this.loading = true;

		if (this.slideshowElement.id) {
			this.slideshowElementStore.update(this.slideshowElement).subscribe(updatedItem => {
				this.loading = false;
				this.slideshowElement = _.assign(this.slideshowElement, updatedItem);
			});
		} else {
			this.slideshowElementStore.create(this.slideshowElement).subscribe(updatedItem => {
				this.loading = false;
				this.slideshowElement = _.assign(this.slideshowElement, updatedItem);
			});
		}
	}

	private deleteItem() {
		if (this.bladeItem.child) {
			this.bladeItem.child.bladeItemComponent.closeMe().then((isClosed) => {
				if (isClosed) {
					this.doDeleteItem();
				}
			});
		} else {
			this.doDeleteItem();
		}
	}

	private doDeleteItem() {
		this.loading = true;
		this.elementStore.delete(this.slideshowElement.elementId).subscribe(isDeleted => {
			this.loading = false;
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}
}
