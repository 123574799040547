import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeMenuItem } from 'app/admin/components/blade/models/bladeMenuItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { SettingsStore } from 'app/admin/stores/settings.store.service';
import { Settings } from 'app/models/site/settings.model';

@Component({
	selector: 'app-site-settings-blade',
	templateUrl: './site-settings-blade.component.html',
	styleUrls: ['./site-settings-blade.component.scss'],
})
@IBladeComponent.register
export class SiteSettingsBladeComponent implements OnInit {

	public selectedIndex = 1;
	public menuItems: BladeMenuItem[] = [
		{ title: 'Generellt', index: 1 },
		{ title: 'Övrigt', index: 2 },
	];
	public loading = true;
	public item: Settings;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: SettingsStore,
		private bladePositionService: BladePositionService,
	) {
		itemStore.getOnSite().subscribe(item => {
			this.item = item;
			this.loading = false;
		});
	 }

	ngOnInit() {
	}

	public save() {
		this.loading = true;
		this.saveOrUpdate().subscribe(item => {
			this.item = _.assign(this.item, item);
			this.bladeItem.minClass = BladeSize.m;
			this.bladeItem.maxClass = BladeSize.m;
			this.bladeItem.activeClass = this.bladeItem.maxClass;
			this.bladePositionService.selectBladeItem(this.bladeItem, true);
			this.loading = false;
		});
	}

	private saveOrUpdate(): Observable<Settings> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}
}
