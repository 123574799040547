import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SourceTypeStore } from '../stores/source-type.store.service';
import { SupplierStore } from '../stores/supplier.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'supplierName' })
export class SupplierNamePipe implements PipeTransform, OnDestroy {
	constructor(private supplierStore: SupplierStore) { }

	public transform(supplierId: number): Observable<string> {
		if (!supplierId) {
			return;
		}
		return this.supplierStore.pipeGet(supplierId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
