import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { Customer } from 'app/models/customer.model';
import { SourceTypeStore } from 'app/admin/stores/source-type.store.service';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { SettingsStore } from 'app/admin/stores/settings.store.service';
import { combineLatest } from 'rxjs';
import { UserService } from 'app/admin/services/user.service';
import { UserTypeEnum } from 'app/enums/user-type.enum';
import { TravelAgencyStore } from 'app/admin/stores/travel-agency.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-customer-generally',
	templateUrl: './customer-generally.component.html',
	styleUrls: ['./customer-generally.component.scss'],
})
export class CustomerGenerallyComponent implements OnInit {
	public UserTypeEnum = UserTypeEnum;
	@Input() customer: Customer;

	public itemForm: FormGroup = new FormGroup({
		firstName: new FormControl('', [Validators.required]),
		lastName: new FormControl('', []),
		email: new FormControl('', []),
		phone: new FormControl('', []),
		sourceTypeId: new FormControl({value: 2, disabled: true}, [Validators.required]),
		languageId: new FormControl('', [Validators.required]),
		travelAgencyId: new FormControl(0, []),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public supplierStore: SupplierStore,
		public sourceTypeStore: SourceTypeStore,
		public languageStore: LanguageStore,
		private settingsStore: SettingsStore,
		public userService: UserService,
		public travelAgencyStore: TravelAgencyStore,
	) {}

	ngOnInit() {
		if (this.customer?.id) {
			this.itemForm.patchValue(this.customer);
		} else {
			combineLatest([this.languageStore.getAll$(), this.settingsStore.getOnSite()])
				.pipe(untilDestroyed(this))
				.subscribe(([languages, settings]) => {
					if (settings.defaultLanguage && languages?.length) {
						const selectedlanguage = languages.find(language => language.identifier === settings.defaultLanguage);
						if (selectedlanguage) {
							this.itemForm.get('languageId').setValue(selectedlanguage.id);
						}
					}
				});
		}

		if (this.bladeItem.payload?.travelAgencyId) {
			this.itemForm.get('travelAgencyId').setValue(+this.bladeItem.payload.travelAgencyId);
			this.customer.travelAgencyId = +this.bladeItem.payload.travelAgencyId;
		}

		this.itemForm.get('firstName').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.customer.firstName = value;
		});
		this.itemForm.get('lastName').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.customer.lastName = value;
		});
		this.itemForm.get('email').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.customer.email = value;
		});
		this.itemForm.get('phone').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.customer.phone = value;
		});
		this.itemForm.get('languageId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.customer.languageId = value;
		});

		this.itemForm.get('travelAgencyId').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.customer.travelAgencyId = value;
		});
	}
}
