import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { DashboardWidgetTypeStore } from 'app/admin/stores/dashboard-widget-type.store.service';
import { DashboardWidgetTypeBladeComponent } from 'app/admin/blades/dashboard-widget-type/dashboard-widget-type-blade/dashboard-widget-type-blade.component';
import { DashboardWidgetType } from 'app/models/dashboard-widget-type.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-settings-dashboard-widget-type',
	templateUrl: './settings-dashboard-widget-type.component.html',
	styleUrls: ['./settings-dashboard-widget-type.component.scss'],
})
export class SettingsDashboardWidgetTypeComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['id', 'name', 'created', 'modified'];
	public dataSource: MatTableDataSource<DashboardWidgetType>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: DashboardWidgetTypeStore,
	) {
		this.itemStore.getAll();
		this.itemStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<DashboardWidgetType>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: DashboardWidgetTypeBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: DashboardWidgetTypeBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}
}
