import { Component, OnInit, Inject } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { TermTypeService } from '../../services/term-type.service';

@Component({
  selector: 'app-term-type-blade-generally',
  templateUrl: './term-type-generally.component.html',
  styleUrls: ['./term-type-generally.component.scss'],
})
export class TermTypeBladeGenerallyComponent implements OnInit {

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		public termTypeService: TermTypeService,
	) { }

	ngOnInit() {
	}
}
