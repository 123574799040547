
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PickupLocationText } from 'app/models/page-location-text.model';
import * as _ from 'lodash';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class PickupLocationTextStore extends BaseStore<PickupLocationText> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'PickupLocationText', baseUrl, snackBarService);
	}

	public getAllOnPickupLocation(pickupLocationId: number): Observable<PickupLocationText[]> {
		return this.http.get<PickupLocationText[]>(`${this.host}/pickupLocation/${pickupLocationId}`).pipe(tap(orders => {
			this._items = orders;
			this._itemsSubject.next(this._items);
		}));
	}

	public getOnPickupLocationAndLanguage(pickupLocationId: number, languageId: number): Observable<PickupLocationText> {
		return this.http.get<PickupLocationText>(`${this.host}/pickupLocation/${pickupLocationId}/language/${languageId}`);
	}

	public addPickupLocationText(pickupLocationText: PickupLocationText) {
		this._items.push(pickupLocationText);
		this._itemsSubject.next(this._items);
	}

	public removePickupLocationText(pickupLocationText: PickupLocationText) {
		_.remove(this._items, pickupLocationText);
		this._itemsSubject.next(this._items);
	}
}
