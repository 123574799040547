import { Injectable, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import * as _ from 'lodash';

import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { ProductImageTypeStore } from 'app/admin/stores/product/product-image-type.store.service';
import { CategoryImageTypeStore } from 'app/admin/stores/category/category-image-type.store.service';
import { DashboardWidgetTypeStore } from 'app/admin/stores/dashboard-widget-type.store.service';
import { ElementTypeStore } from 'app/admin/stores/element-type.store.service';
import { MediaTypeStore } from 'app/admin/stores/media-type.store.service';
import { PageTypeStore } from 'app/admin/stores/page-type.store.service';
import { PaymentTypeStore } from 'app/admin/stores/payment-type.store.service';
import { ProductTextTypeStore } from 'app/admin/stores/product/product-text-type.store.service';
import { ProductTypeStore } from 'app/admin/stores/product/product-type.store.service';
import { SlideshowItemTypeStore } from 'app/admin/stores/slideshow-item-type.store.service';
import { SourceTypeStore } from 'app/admin/stores/source-type.store.service';
import { TourGuideTypeStore } from 'app/admin/stores/tour-guide-type.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { User } from '../models/user.model';
import { BookingSourceStore } from '../stores/booking-source.store.service';
import { BookingStatusStore } from '../stores/booking-status.store.service';
import { LanguageStore } from '../stores/language.store.service';
import { UserStore } from '../stores/user.store.service';
import { SupplierResourceStore } from '../stores/supplier-resource.store.service';
import { SupplierResourceTypeStore } from '../stores/supplier-resource-type.store.service';
import { SupplierStore } from '../stores/supplier.store.service';
import { SupplierResourceProductTypeStore } from '../stores/supplier-resource-product-type.store.service';
import { UserTypeEnum } from 'app/enums/user-type.enum';
import { TourStatusStore } from '../stores/tour-status.store.service';
import { TravelAgencyStore } from '../stores/travel-agency.store.service';
import { PickupLocationStore } from '../stores/pickup-location.store.service';
import { ColumnLinkTypeStore } from '../stores/column-link-type.store.service';
import { OrderHubService } from 'app/hubs/order/order.hub.service';
import { PageStore } from '../stores/page.store.service';
import { SystemStore } from '../stores/system.store.service';
import { PageTextStore } from '../stores/page-text.store.service';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class UserService implements OnDestroy {
	private currentUser: User;
	private userSubscriber = new ReplaySubject<User>();
	public user$ = this.userSubscriber.asObservable();

	private travelagencyRoles = ['order'];

	constructor(
		private orderHubService: OrderHubService,
		private customerTypeStore: CustomerTypeStore,
		private productImageTypeStore: ProductImageTypeStore,
		private categoryImageTypeStore: CategoryImageTypeStore,
		private dashboardWidgetTypeStore: DashboardWidgetTypeStore,
		private elementTypeStore: ElementTypeStore,
		private mediaTypeStore: MediaTypeStore,
		private pageTypeStore: PageTypeStore,
		private paymentTypeStore: PaymentTypeStore,
		private productTextTypeStore: ProductTextTypeStore,
		private productTypeStore: ProductTypeStore,
		private slideshowItemTypeStore: SlideshowItemTypeStore,
		private sourceTypeStore: SourceTypeStore,
		private tourGuideTypeStore: TourGuideTypeStore,
		private bookingSourceStore: BookingSourceStore,
		private bookingStatusStore: BookingStatusStore,
		private tourStatusStore: TourStatusStore,
		private languageStore: LanguageStore,
		private supplierStore: SupplierStore,
		private supplierResourceStore: SupplierResourceStore,
		private supplierResourceTypeStore: SupplierResourceTypeStore,
		private supplierResourceProductTypeStore: SupplierResourceProductTypeStore,
		private travelAgencyStore: TravelAgencyStore,
		private userStore: UserStore,
		private pickupLocationStore: PickupLocationStore,
		public columnLinkTypeStore: ColumnLinkTypeStore,
		public pageStore: PageStore,
		public pageTextStore: PageTextStore,
		public systemStore: SystemStore,

	) {

		this.user$.pipe(untilDestroyed(this)).subscribe(user => {
			if (user && user.id) {
				// Get all types based on the new user
				setTimeout(() => {
					// Need to bee on the next thread to make TokenInterceptor init first
					this.customerTypeStore.getAll();
					this.productImageTypeStore.getAll();
					this.categoryImageTypeStore.getAll();
					this.dashboardWidgetTypeStore.getAll();
					this.elementTypeStore.getAll();
					this.mediaTypeStore.getAll();
					this.pageTypeStore.getAll();
					this.paymentTypeStore.getAll();
					this.productTextTypeStore.getAll();
					this.productTypeStore.getAll();
					this.slideshowItemTypeStore.getAll();
					this.sourceTypeStore.getAll();
					this.tourGuideTypeStore.getAll();
					this.bookingSourceStore.getAll();
					this.bookingStatusStore.getAll();
					this.tourStatusStore.getAll();
					this.languageStore.getAll();
					this.supplierStore.getAll();
					this.supplierResourceStore.getAll();
					this.supplierResourceTypeStore.getAll();
					this.supplierResourceProductTypeStore.getAll();
					this.travelAgencyStore.getAll();
					this.userStore.getAll();
					this.pickupLocationStore.getAll();
					this.columnLinkTypeStore.getAll();
					this.pageStore.getAll();
					this.pageTextStore.getAll(),
						this.systemStore.getCurrent();
				});
			}

			if (this.isAdminUser()) {
				this.orderHubService.startConnection();
				this.orderHubService.addTransferChartDataListener();
			}
		});
	}

	public ngOnDestroy(): void { }

	public setUser(token: string) {
		this.currentUser = jwt_decode(token);
		this.currentUser.roles = this.currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
		if (typeof this.currentUser.roles === 'string') {
			this.currentUser.roles = [this.currentUser.roles];
		}
		this.userSubscriber.next(this.currentUser);
	}

	public logutUser(): any {
		this.currentUser = undefined;
		this.userSubscriber.next(this.currentUser);
	}

	public isLoggedIn(): boolean {
		return !!this.currentUser;
	}

	public isTravelAgencyUser(): boolean {
		return this.currentUser && this.currentUser.userType === UserTypeEnum.travelagency;
	}

	public isAdminUser(): boolean {
		return this.currentUser && this.currentUser.userType === UserTypeEnum.admin;
	}

	public isMaster(): boolean {
		return this.currentUser ? !!_.find(this.currentUser.roles, x => _.includes(x.toLowerCase(), 'master_')) : false;
	}

	public checkPermission(roles: string[], level: number) {
		let hasRole = false;
		if (this.isAdminUser()) {
			if (this.isMaster() || ((!roles || !roles.length))) {
				hasRole = true;
			} else if (this.currentUser && this.currentUser.roles && this.currentUser.roles.length) {
				_.each(roles, role => {
					const userService = _.find(this.currentUser.roles, x => _.includes(x.toLowerCase(), `${role.toLowerCase()}_`));
					if (!hasRole && !!userService) {
						const roleLevel = +userService.split('_')[1];
						hasRole = roleLevel >= level;
					}
				});
			}
		} else if (this.isTravelAgencyUser()) {
			hasRole = !!_.find(this.travelagencyRoles, x => _.includes(roles, x.toLowerCase()));
		}
		return hasRole;
	}
}
