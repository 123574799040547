
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { Email } from 'app/models/email/email.model';
import { Observable } from 'rxjs';
import { EmailCompany } from 'app/models/email/email-company.model';

@Injectable({
	providedIn: 'root',
})
export class EmailCompanyStore extends BaseStore<EmailCompany> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Email', baseUrl, snackBarService);
		this.host = `${baseUrl}api/email/company`;
	}

	public getActive(): Observable<EmailCompany> {
		return this.http.get<EmailCompany>(`${this.host}/active`);
	}
}
