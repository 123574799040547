import { BaseModel } from './base.model';
import { ImageElement } from './image-element.model';
import { VideoElement } from './video-element.model';
import { TextElement } from './text-element.model';
import { HeadlineElement } from './headline-element.model';
import { ProductElement } from './product-element.model';
import { ProductBookingElement } from './product-booking-element.model';
import { CardElement } from './card-element.model';
import { ButtonElement } from './button-element.model';
import { ProductListElement } from './product-list-element.model';
import { ConfirmationElement } from './confirmation-element.model';
import { CartElement } from './cart-element.model';
import { SlideshowElement } from './slideshow-element.model';
import { FormElement } from './form-element.model';
import { CategoryElement } from './category-element.model';
import { SirvoyElement } from './sirvoy-element.model';

export class Element extends BaseModel {
	public columnId?: number;
	public elementTypeId?: number;
	public elementTypeIdentifier: string;
	public onProduct?: boolean;
	public onCategory?: boolean;
	public prio?: number;
	public imageElements?: ImageElement[];
	public videoElement?: VideoElement;
	public textElements?: TextElement[];
	public headlineElements?: HeadlineElement[];
	public productElement?: ProductElement;
	public categoryElement?: CategoryElement;
	public productListElement?: ProductListElement;
	public productBookingElement?: ProductBookingElement;
	public confirmationElement?: ConfirmationElement;
	public cardElement?: CardElement;
	public cartElement?: CartElement;
	public slideshowElement?: SlideshowElement;
	public buttonElements?: ButtonElement[];
	public formElements?: FormElement[];
	public sirvoyElement?: SirvoyElement;
	public classes?: string[];
	public style?: any;
}
