<mat-card *ngIf="{
	adminText: 'blades.tour.tabs.bookings' | adminText | async
} as source">
	<mat-card-title>{{source.adminText?.title}}</mat-card-title>
	<mat-card>
		<table mat-table [dataSource]="dataSource" matSort class="w-100">
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
				<td mat-cell *matCellDef="let item">{{item.id}} </td>
			</ng-container>
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.table.customer}}</th>
				<td mat-cell *matCellDef="let item">{{item.customerId | customerName | async}}</td>
			</ng-container>
			<ng-container matColumnDef="source">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.table.source}}</th>
				<td mat-cell *matCellDef="let item">{{item.sourceTypeId | sourceTypeName | async}}</td>
			</ng-container>
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.table.status}}</th>
				<td mat-cell *matCellDef="let item">{{item.bookingStatusId | bookingStatusName | async}}</td>
			</ng-container>
			<ng-container matColumnDef="created">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>{{source.adminText?.table.created}}</th>
				<td mat-cell *matCellDef="let item">{{item.created | date:'yyyy-MM-dd HH:mm'}}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
		</table>
		<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	</mat-card>
	<div class="pt-3 text-right">
		<button mat-raised-button (click)="createNew()">{{source.adminText?.createNewBtn}}</button>
	</div>
</mat-card>
