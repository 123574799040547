<ng-container *ngIf="{
	adminText: 'widget.modal' | adminText | async
} as source">
	<h2 mat-dialog-title>{{source.adminText?.title}}</h2>
	<mat-dialog-content>
		<mat-form-field>
			<mat-select placeholder="Favorite food" [(ngModel)]="selectedSize" name="size">
				<mat-option *ngFor="let size of widgetSizes" [value]="size">
					{{size.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-button [mat-dialog-close]="false">{{source.adminText?.closeBtn}}</button>
		<button mat-button color="primary" (click)="save()">{{source.adminText?.saveBtn}}</button>
	</mat-dialog-actions>
</ng-container>
