import { MinFunctionCustomerType } from './min-function-customer-type.model';
import { MinFunctionErrorMessage } from './min-function-error-message.model';
import { ProductFunctionBase } from './product-function-base.model';
import { ProductFunction } from './product-function.model';

export class MinFunction extends ProductFunctionBase {
	public name: string;
	public functionTypeId: number;
	public errorMessages: MinFunctionErrorMessage[];
	public customerTypes: MinFunctionCustomerType[];
	public products: ProductFunction[];
}
