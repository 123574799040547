import { Component, Inject, OnDestroy } from '@angular/core';
import {
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';

import { Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';

import { ProductExcludeDate } from 'app/models/product/product-exclude-date.model';
import { ProductExcludeDateStore } from 'app/admin/stores/product/product-exclude-date.store.service';

@UntilDestroy()
@Component({
	selector: 'app-product-exclude-date-blade',
	templateUrl: './product-exclude-date-blade.component.html',
	styleUrls: ['./product-exclude-date-blade.component.scss'],
})
@IBladeComponent.register
export class ProductExcludeDateBladeComponent implements OnDestroy {
	public loading = true;
	public productExcludeDate: ProductExcludeDate;

	public itemForm: FormGroup = new FormGroup({
		excludeDate: new FormControl({ value: '' }, [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private productExcludeDateStore: ProductExcludeDateStore,
		private bladeService: BladeService,
	) {
		if (bladeItem.id) {
			productExcludeDateStore.get(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(item => {
				this.productExcludeDate = item;
				this.itemForm.get('excludeDate').setValue(this.productExcludeDate.excludeDate);

				this.loading = false;
			});
		} else if (bladeItem.payload && bladeItem.payload.productId) {
			this.productExcludeDate = new ProductExcludeDate();
			this.productExcludeDate.productId = bladeItem.payload.productId;
			this.loading = false;
		}
	}

	ngOnDestroy(): void { }

	public save() {
		this.loading = true;
		this.productExcludeDate.excludeDate = this.itemForm.value.excludeDate;
		this.saveOrUpdate().subscribe(item => {
			this.productExcludeDate = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}

	private saveOrUpdate(): Observable<ProductExcludeDate> {
		if (this.productExcludeDate.id > 0) {
			return this.productExcludeDateStore.update(this.productExcludeDate);
		} else {
			return this.productExcludeDateStore.create(this.productExcludeDate);
		}
	}
}
