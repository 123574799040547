<ng-container *ngIf="{
	specialDiets: specialDietsReplay$ | async,
	specialDietMessages: specialDietMessagesReplay$ | async,
	adminText: 'blades.tour.tabs.summary.specialDiet' | adminText | async
} as source">
	<mat-card>
		<mat-card-title class="card-title-small align-items-center d-flex justify-content-between">
			{{source.adminText?.title}}
			<!-- <button mat-icon-button (click)="openEditSpecialDiet(tourBookingId)">
				<mat-icon>edit</mat-icon>
			</button> -->
		</mat-card-title>
		<mat-card *ngFor="let specialDiet of source.specialDiets; let i = index" class="m-0 ml-3 mb-3">
			<mat-card-subtitle>{{specialDiet.customerTypeId | customerTypeName | async}} {{i+1}}</mat-card-subtitle>
			<div *ngFor="let tourBookingSpecialDiet of specialDiet.tourBookingSpecialDiets" class="p-1" [class.border]="tourBookingSpecialDiet.extraInfo">
				<p class="m-0">{{tourBookingSpecialDiet.specialDietId | specialDietName | async}}</p>
				<p class="ml-2 m-0" *ngIf="tourBookingSpecialDiet.extraInfo" [innerHtml]="tourBookingSpecialDiet.extraInfo | newLine"></p>
			</div>
		</mat-card>
		<p *ngIf="!source.specialDiets?.length">{{source.adminText?.noSpecialDiet}}</p>
		<mat-card class="mt-3">
			<mat-card-title class="card-title-small">{{source.adminText?.messageTitle}}</mat-card-title>
			<p *ngIf="!source.specialDietMessages?.length">{{source.adminText?.noMessage}}</p>
			<p *ngFor="let message of source.specialDietMessages" [innerHtml]="message.message | newLine"></p>
		</mat-card>
	</mat-card>
</ng-container>

