import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ColumnLink } from 'app/models/column-link.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ColumnLinkStore extends BaseStore<ColumnLink> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ColumnLink', baseUrl, snackBarService);
	}

	public getOnColumn(columnId: number): Observable<ColumnLink[]> {
		return this.http.get<ColumnLink[]>(`${this.host}/column/${columnId}`);
	}
}
