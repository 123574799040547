import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';

import { Observable } from 'rxjs';
import { TourStore } from '../stores/tour.store.service';

/**
 * Print page type name
 */
@Pipe({ name: 'totalCustomersOnTour' })
export class TotalCustomersOnTourPipe implements PipeTransform, OnDestroy {
	constructor(private tourStore: TourStore) { }

	public transform(tourId: number): Observable<number> {
		if (!tourId) {
			return;
		}
		return this.tourStore.getTotalCustomersOnTour(tourId);
	}

	ngOnDestroy(): void { }
}
