import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from 'app/admin/models/user.model';
import { AuthService } from 'app/admin/services/auth.service';
import { UserService } from 'app/admin/services/user.service';
import { UserStore } from 'app/admin/stores/user.store.service';
import { ReplaySubject } from 'rxjs';
import { UserPageService } from '../../services/user-page.service';

@UntilDestroy()
@Component({
  selector: 'app-user-page-password',
  templateUrl: './user-page-password.component.html',
  styleUrls: ['./user-page-password.component.scss']
})
export class UserPagePasswordComponent implements OnInit {
	public errorMessageReplay$ = new ReplaySubject<string>(1);
	private user: User;

	public itemForm: FormGroup = new FormGroup({
		password: new FormControl('', [Validators.required]),
		newPassword: new FormControl('', [Validators.required]),
		newPassword2: new FormControl('', [Validators.required]),
	});
  	constructor(
		private userStore: UserStore,
		private userService: UserService,
		public userPageService: UserPageService,
		public authService: AuthService,
	) {
		this.userService.user$.pipe(untilDestroyed(this)).subscribe(user => this.user = user);
	}

  	ngOnInit() {}

	public changePassword(): void {
		this.errorMessageReplay$.next('');
		if (this.itemForm.value.password === this.itemForm.value.newPassword) {
			this.errorMessageReplay$.next('Du kan inte välja samma lösenord som innan.');
			return;
		}

		this.itemForm.disable();
		this.userStore.updatePassword(this.user.id, this.itemForm.value.password, this.itemForm.value.newPassword).pipe(untilDestroyed(this)).subscribe(isChanged => {
			this.itemForm.reset();
			this.itemForm.enable();
			this.authService.logout();
		}, error => {
			this.itemForm.enable();
			this.errorMessageReplay$.next('Något gick fel!');
		});
	}
}
