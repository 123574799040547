import { AddonText } from './addon-text.model';
import { BaseModel } from './base.model';

export class Addon extends BaseModel {
	public name: string;
	public imageId: number;
	public isActive: boolean;
	public price: number;
	public addonTexts: AddonText[];
}
