import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { TourStore } from '../stores/tour.store.service';

/**
 * Print page type name
 */
@Pipe({ name: 'tourName' })
export class TourNamePipe implements PipeTransform, OnDestroy {
	constructor(private tourStore: TourStore) { }

	public transform(pageTypeId: number, languageId?: number): Observable<string> {
		if (!pageTypeId) {
			return;
		}
		return this.tourStore.pipeGetName(pageTypeId, languageId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
