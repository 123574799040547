
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseStore } from '../base.store.service';
import { ProductSettings } from 'app/models/product/product-settings.model';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ProductSettingsStore extends BaseStore<ProductSettings> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ProductSettings', baseUrl, snackBarService);
	}

	public getOnProduct(productId: number): Observable<ProductSettings> {
		return this.http.get<ProductSettings>(`${this.host}/product/${productId}`);
	}
}
