import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class PageBladeService {

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		externalLink: new FormControl('', [Validators.nullValidator]),
		pageTypeId: new FormControl('', [Validators.required]),
		isActive: new FormControl(''),
		showOnMenu: new FormControl(true),
	});

	constructor() { }

}
