<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem.id">
	<app-blade-header [title]="'Produktbokining'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
		<mat-card>
			<div *ngIf="productBookingElement && selectedIndex !== 99">
				<h1>Produkt bokning</h1>
			</div>
			<app-element-blade-styles *ngIf="productBookingElement && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="productBookingElement?.id">Spara</span>
			<span *ngIf="!productBookingElement?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
