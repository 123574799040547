import { Component } from '@angular/core';

@Component({
	selector: 'app-settings-email-templates',
	templateUrl: './settings-email-templates.component.html',
	styleUrls: ['./settings-email-templates.component.scss'],
})
export class SettingsEmailTemplatesComponent {
	constructor() {	}
}
