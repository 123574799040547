import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { PageText } from 'app/models/page-text.model';

@Injectable({
	providedIn: 'root',
})
export class PageTextStore extends BaseStore<PageText> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'PageText', baseUrl, snackBarService);
	}

	public getOnPageAndLanguage(pageId: number, languageId: number): Observable<PageText> {
		return this.http.get<PageText>(`${this.host}/page/${pageId}/language/${languageId}`);
	}
}
