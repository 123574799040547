import { Injectable, OnDestroy } from '@angular/core';

import { ReplaySubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Dashboard } from 'app/models/dashboard.model';
import { DashboardStore } from '../store/dashboard.store.service';
import { DashboardWidgetType } from 'app/models/dashboard-widget-type.model';
import { DashboardWidget } from '../models/dashboard-widget.model';
import { DashboardWidgetStore } from '../store/dashboard-widget.store.service';
import { tap, map } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class DashboardService implements OnDestroy {
	private newName = 'Nytt dashboard';
	private dashboards: Dashboard[] = [];
	private dashboardsReplay = new ReplaySubject<Dashboard[]>(1);
	public dashboardsReplay$ = this.dashboardsReplay.asObservable();

	private selectedDashboard: Dashboard = new Dashboard();
	private selectedDashboardReplay = new ReplaySubject<Dashboard>(1);
	public selectedDashboardReplay$ = this.selectedDashboardReplay.asObservable();

	constructor(
		private dashboardStore: DashboardStore,
		private dashboardWidgetStore: DashboardWidgetStore,
	) {
		this.dashboardStore.getAllOnUser().pipe(untilDestroyed(this)).subscribe(dashboards => {
			this.dashboards = dashboards;
			this.dashboardsReplay.next(this.dashboards);
			this.getDashboardToSelect();
			if (this.selectedDashboard) {
				this.dashboardWidgetStore.getAllOnDashboard(this.selectedDashboard.id).subscribe();
			}
			this.selectedDashboardReplay.next(this.selectedDashboard);
		});
		this.dashboardWidgetStore.items$.pipe(untilDestroyed(this)).subscribe(widgets => {
			_.each(widgets, widget => {
				if (typeof widget.data === 'string') {
					widget.data = JSON.parse(widget.data);
				}
			});
			this.selectedDashboard.widgets = widgets;
			this.selectedDashboardReplay.next(this.selectedDashboard);
		});
	}

	ngOnDestroy(): void { }

	private getDashboardToSelect() {
		this.selectedDashboard = _.find(this.dashboards, d => !!d.isActive);
		if (!this.selectedDashboard) {
			this.selectedDashboard = _.first(this.dashboards);
		}
	}

	public selectDashboard(dashboard: Dashboard) {
		if (this.selectedDashboard !== dashboard) {
			this.selectedDashboard = dashboard;

			this.selectedDashboard.widgets = null;
			this.selectedDashboardReplay.next(this.selectedDashboard);

			this.dashboardWidgetStore.getAllOnDashboard(this.selectedDashboard.id).subscribe();
		}
	}
	public addNewDashboard(): Observable<Dashboard> {
		const dashboard = new Dashboard();
		dashboard.name = this.newName;

		const numOfNew = _.filter(this.dashboards, d => _.includes(d.name, this.newName));
		if (numOfNew && numOfNew.length) {
			dashboard.name += ` (${numOfNew.length})`;
		}
		this.selectedDashboard = dashboard;
		return this.saveDashboard().pipe(tap(createdDashboard => {
			this.selectedDashboard = createdDashboard;
			this.selectedDashboardReplay.next(this.selectedDashboard);

			this.dashboards.push(this.selectedDashboard);
			this.dashboardsReplay.next(this.dashboards);
		}));
	}
	public addDashboardWidget(dashboardWidgetType: DashboardWidgetType) {
		const newWidget = this.getNewWidget(dashboardWidgetType);
		newWidget.dashboardWidgetTypeId = dashboardWidgetType.id;
		this.saveDashboardWidget(newWidget).subscribe();
	}

	public setName(name: string) {
		this.selectedDashboard.name = name;
	}

	public setIsActive(isActive: boolean) {
		this.selectedDashboard.isActive = isActive ? 1 : 0;
		this.saveDashboard().subscribe();
	}

	public setIsGlobal(isGlobal: boolean) {
		this.selectedDashboard.isGlobal = isGlobal ? 1 : 0;
		this.saveDashboard().subscribe();
	}

	public saveDashboard(): Observable<Dashboard> {
		if (this.selectedDashboard.id) {
			return this.dashboardStore.update(this.selectedDashboard);
		} else {
			return this.dashboardStore.create(this.selectedDashboard);
		}
	}

	public saveDashboardWidget(widget: DashboardWidget): Observable<DashboardWidget> {
		widget.data = JSON.stringify(widget.data);
		if (widget.id) {
			return this.dashboardWidgetStore.update(widget).pipe(map(updatedWidget => {
				updatedWidget.data = JSON.parse(updatedWidget.data);
				_.assign(widget, updatedWidget);
				return updatedWidget;
			}));
		} else {
			return this.dashboardWidgetStore.create(widget);
		}
	}

	public deleteDashboardWidget(widget: DashboardWidget): Observable<boolean> {
		return this.dashboardWidgetStore.delete(widget.id);
	}

	public deleteActiveDashboard() {
		if (this.selectedDashboard.widgets && this.selectedDashboard.widgets.length) {
			const totalToDelete = this.selectedDashboard.widgets.length;
			const numDeleted = 0;
			_.each(this.selectedDashboard.widgets, widget => {
				this.dashboardWidgetStore.delete(widget.id).subscribe(isDeleted => {
					this.isAllDeleted(totalToDelete, numDeleted + 1);
				});
			});
		} else {
			this.isAllDeleted(0, 0);
		}
	}

	private isAllDeleted(totalToDelete: number, numDeleted: number) {
		if (totalToDelete >= numDeleted) {
			this.dashboardStore.delete(this.selectedDashboard.id).subscribe(isDeleted => {
				this.getDashboardToSelect();
				this.selectedDashboardReplay.next(this.selectedDashboard);
			});
		}
	}

	private getNewWidget(dashboardWidgetType: DashboardWidgetType): DashboardWidget {
		const newWidget = new DashboardWidget();
		newWidget.dashboardId = this.selectedDashboard.id;
		switch (dashboardWidgetType.identifier) {
			case 'orders':
				newWidget.name = 'Ordrar';
				newWidget.cols = 4;
				newWidget.rows = 3;
				newWidget.data = {};
				break;
			case 'tour_booking':
				newWidget.name = 'Tur bokningar';
				newWidget.cols = 5;
				newWidget.rows = 3;
				newWidget.data = {};
				break;
			case 'order_payment':
				newWidget.name = 'Order betalningar';
				newWidget.cols = 5;
				newWidget.rows = 3;
				newWidget.data = {};
				break;

			case 'order_amount':
				newWidget.name = 'Order belopp';
				newWidget.cols = 5;
				newWidget.rows = 3;
				newWidget.data = {};
				break;

			case 'order_with_rest':
				newWidget.name = 'Order med rest';
				newWidget.cols = 5;
				newWidget.rows = 3;
				newWidget.data = {};
				break;

			case 'order_draft':
				newWidget.name = 'Utkast Order';
				newWidget.cols = 5;
				newWidget.rows = 3;
				newWidget.data = {};
				break;
		}
		return newWidget;
	}
}
