import { Component, Inject, Input, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { ProductDateTime } from 'app/models/product/product-date-time.model';
import { ProductDateTimeStore } from 'app/admin/stores/product/product-date-time.store.service';
import { ProductDateTimeCustomerTypeStore } from 'app/admin/stores/product/product-date-time-customer-type.store.service';
import { ProductDateTimeCustomerType } from 'app/models/product/product-date-time-customer-type.model';

@UntilDestroy()
@Component({
	selector: 'app-product-blade-date-times',
	templateUrl: './product-blade-date-times.component.html',
	styleUrls: ['./product-blade-date-times.component.scss'],
})
export class ProductBladeDateTimesComponent implements OnDestroy {
	@Input() set productDateId(id: number) {
		this.productDateTimeStore.getAllOnProductDate$(id).subscribe(items => {
			this.productDateTimes = items;
		});
	}

	public productDateTimes: ProductDateTime[];
	public productDateTimeCustomerTypes: ProductDateTimeCustomerType[];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private productDateTimeStore: ProductDateTimeStore,
		private productDateTimeCustomerTypeStore: ProductDateTimeCustomerTypeStore,
	) {	}

		ngOnDestroy(): void { }
	}
