
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { PageClass } from 'app/models/page-class.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class PageClassStore extends BaseStore<PageClass> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'PageClasses', baseUrl, snackBarService);
	}

	public getAllOnPage(pageId: number): Observable<PageClass[]> {
		return this.http.get<PageClass[]>(`${this.host}/page/${pageId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public addDelete(categoryId: number, pageClasses: PageClass[], deleteIds: number[]): Observable<void> {
		const addDeleteObj = {
			pageClasses,
			deleteIds,
		};
		return this.http.post<void>(`${this.host}/addDelete/${categoryId}`, addDeleteObj);
	}
}
