import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBooking } from 'app/models/tour-booking.model';
import { BookingStatusStore } from 'app/admin/stores/booking-status.store.service';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { UtilStore } from 'app/admin/stores/util.store.service';
import { OrderBladeComponent } from 'app/admin/blades/order/order-blade/order-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourBladeComponent } from 'app/admin/blades/tour/tour-blade/tour-blade.component';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-tour-booking-blade-generally',
  templateUrl: './tour-booking-blade-generally.component.html',
  styleUrls: ['./tour-booking-blade-generally.component.scss'],
})
export class TourBookingBladeGenerallyComponent implements OnInit {

	@Input() item: TourBooking;
	public hashId: string;
	public changeTourId = false;
	public isChangeTourId = false;

	public itemForm: FormGroup = new FormGroup({
		bookingStatus: new FormControl('', [Validators.required]),
	});

	public moveTourForm: FormGroup = new FormGroup({
		tourId: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public bookingStatusStore: BookingStatusStore,
		private utilStore: UtilStore,
		private tourStore: TourStore,
		private itemStore: TourBookingStore,
	) { }

  	ngOnInit() {
		this.itemForm.get('bookingStatus').setValue(this.item.bookingStatusId);
		this.itemForm.get('bookingStatus').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.bookingStatusId = value;
		});

		this.moveTourForm.get('tourId').setValue(this.item.tourId);
	}

	public openOrder() {
		window.open('/customer?customerId=' + this.item.customerId + '&selectedOrder=' + this.item.orderId, '_blank');
		
		// this.bladeService.addBladeItem({
		// 	id: this.item.orderId,
		// 	component: OrderBladeComponent,
		// 	minClass: BladeSize.m,
		// 	maxClass: BladeSize.l,
		// }, this.bladeItem);
	}

	public openTour() {
		this.bladeService.addBladeItem({
			id: this.item.tourId,
			component: TourBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	// public changeTour() {
	// 	this.isChangeTourId = true;

	// 	const tourId = this.moveTourForm.get('tourId').value;
	// 	if (tourId && tourId !== this.item.tourId) {
	// 		this.itemStore.changeTour(this.item.id, tourId).subscribe(done => {
	// 			if (done) {
	// 				this.item.tourId = tourId;
	// 				this.changeTourId = false;
	// 			}
	// 			this.isChangeTourId = false;
	// 		});
	// 	}
	// }
}
