import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { CustomerStore } from '../stores/customer.store.service';
import { Customer } from 'app/models/customer.model';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'customer' })
export class CustomerPipe implements PipeTransform, OnDestroy {
	constructor(private customerStore: CustomerStore) {	}
	public transform(customerId: number): Observable<Customer> {
		if (!customerId) {
			return;
		}
		return this.customerStore.get(customerId);
	}

	ngOnDestroy(): void { }
}
