
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { Observable } from 'rxjs';
import { SystemDibs } from 'app/models/system-dibs.model';

@Injectable({
	providedIn: 'root',
})
export class SystemDibsStore extends BaseStore<SystemDibs> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'systemDibs', baseUrl, snackBarService);
	}

	public getActive(): Observable<SystemDibs> {
		return this.http.get<SystemDibs>(`${this.host}/active`);
	}
}
