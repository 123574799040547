import { Component, OnInit, Inject } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { CustomerTypeService } from '../../services/customer-type.service';

@Component({
  selector: 'app-customer-type-blade-generally',
  templateUrl: './customer-type-generally.component.html',
  styleUrls: ['./customer-type-generally.component.scss'],
})
export class CustomerTypeBladeGenerallyComponent implements OnInit {

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		public customerTypeService: CustomerTypeService,
	) { }

	ngOnInit() {
	}
}
