import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { ElementTypeStore } from '../stores/element-type.store.service';
import { Observable } from 'rxjs';

/**
 * Print element type name
 */
@Pipe({ name: 'elementTypeName' })
export class ElementTypeNamePipe implements PipeTransform, OnDestroy {
	constructor(private elementTypeStore: ElementTypeStore) {}

	public transform(elementTypeId: number): Observable<string> {
		if (!elementTypeId) {
			return;
		}
		return this.elementTypeStore.pipeGet(elementTypeId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
