<ng-container *ngIf="{item: productFunctionService.itemReplay$ | async} as source">
	<app-blade-item [canIClose]="true" [loading]="productFunctionService.loadingReplay$ | async" [id]="source.item?.id">
		<app-blade-header [title]="source.item?.name || 'Ny'" [subTitle]="'Produkt min-funktion'"></app-blade-header>
		<app-blade-content>
			<app-alert-card mode="info" label="Denna funktion hanterar minst antal kunden får boka. Ni kan även ange att kunden ska få boka fritt antal så fort det redan finns en bokning."></app-alert-card>
			<app-blade-content-tabs>
				<app-blade-content-tab [title]="'Hantering'">
					<app-min-function-blade-generally></app-min-function-blade-generally>
				</app-blade-content-tab>
				<app-blade-content-tab [title]="'Meddelande'">
					<app-min-function-blade-messages></app-min-function-blade-messages>
				</app-blade-content-tab>
				<app-blade-content-tab [title]="'Kopplade produkter'" [template]="products">
					<ng-template #products>
						<app-min-function-blade-products></app-min-function-blade-products>
					</ng-template>
				</app-blade-content-tab>
			</app-blade-content-tabs>
		</app-blade-content>
		<app-blade-footer>
			<span fxFlex></span>
			<button mat-raised-button color="primary" [disabled]="!productFunctionService.itemForm.valid" (click)="productFunctionService.save()">
				<span *ngIf="source.item?.id">Spara</span>
				<span *ngIf="!source.item?.id">Skapa</span>
			</button>
		</app-blade-footer>
	</app-blade-item>
</ng-container>
