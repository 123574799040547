import { BaseModel } from './base.model';
import { Media } from './media.model';

export class SlideshowImage extends BaseModel {
	public slideshowItemId?: number;
	public languageId: number;
	public mediaId?: number;
	public headline?: string;
	public description?: string;
	public path?: string;
	public media?: Media;
}
