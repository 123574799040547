import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { PageTypeStore } from '../stores/page-type.store.service';
import { Observable } from 'rxjs';
import { SlideshowItemTypeStore } from '../stores/slideshow-item-type.store.service';

/**
 * Print slideshow item type name
 */
@Pipe({ name: 'slideshowItemTypeName' })
export class SlideshowItemTypeNamePipe implements PipeTransform, OnDestroy {
	constructor(private slideshowItemTypeStore: SlideshowItemTypeStore) { }

	public transform(slideshowTypeId: number): Observable<string> {
		if (!slideshowTypeId) {
			return;
		}
		return this.slideshowItemTypeStore.pipeGet(slideshowTypeId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
