import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-admin-site-page',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SitePageComponent {

  constructor() {	}

}
