<mat-card>
	<mat-card-content>
		<mat-card-title>Email inställningar</mat-card-title>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="name" placeholder="Namn">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="url" placeholder="Url">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="replayToEmail" placeholder="Svara till email">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="senderEmail" placeholder="Avsändare email">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="sendCopyToEmail" placeholder="Mejl dit all kopia ska skickas till">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="logo" placeholder="Logga">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="sendinBlueApiKey" placeholder="SendinBlue Api nyckel">
			</mat-form-field>
			<div class="text-right">
				<button mat-raised-button color="primary" (click)="save()" type="submit" [disabled]="!itemForm.valid">
					<span *ngIf="!itemForm.value.id">Skapa</span>
					<span *ngIf="itemForm.value.id">Spara</span>
				</button>
			</div>
		</form>
	</mat-card-content>
</mat-card>
