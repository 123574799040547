<app-blade-item [canIClose]="true" [loading]="loading">
	<app-blade-header [title]="item?.name||'Ny'" [subTitle]="'Tillägg'"></app-blade-header>
	<app-blade-content *ngIf="item">
		<app-blade-content-tabs>
			<app-blade-content-tab [title]="'Generellt'" [template]="generally">
				<ng-template #generally>
					<app-addon-blade-generally></app-addon-blade-generally>
				</ng-template>
			</app-blade-content-tab>
			<app-blade-content-tab [title]="'Texter'" [template]="texts" [disabled]="!item?.id">
				<ng-template #texts>
					<app-addon-blade-texts></app-addon-blade-texts>
				</ng-template>
			</app-blade-content-tab>
		</app-blade-content-tabs>
	</app-blade-content>

</app-blade-item>
