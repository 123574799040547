import { Component, OnInit, Inject, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Product } from 'app/models/product/product.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { ProductTypeStore } from 'app/admin/stores/product/product-type.store.service';
import { SupplierResourceStore } from 'app/admin/stores/supplier-resource.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { SupplierResourceProductTypeStore } from 'app/admin/stores/supplier-resource-product-type.store.service';
import { SupplierResourceProductStore } from 'app/admin/stores/supplier-resource-product.store.service';
import { SupplierResourceProduct } from 'app/models/supplier-resource-product.model';
import { SupplierResourceProductType } from 'app/models/supplier-resource-product-type.model';

@UntilDestroy()
@Component({
	selector: 'app-product-blade-generally',
	templateUrl: './product-blade-generally.component.html',
	styleUrls: ['./product-blade-generally.component.scss'],
})
export class ProductBladeGenerallyComponent implements OnInit, OnDestroy {
	public displayedDateColumns: string[] = ['supplier', 'supplierResourceProductType', 'course'];
	private supplierResourceProductTypes: SupplierResourceProductType[];
	public supplierResourceProductTypeDescription = {
		tour: 'Ange hur många resurser det går åt per tur.',
		customer: 'Ange hur många kunder som går åt per resurs. Du kan ange ex 2 om varje resurs tar två kunder.',
	};

	private item: Product;

	@Input() set product(product: Product) {
		this.item = product;
		this.itemForm.get('name').setValue(this.item.name);
		this.itemForm.get('type').setValue(this.item.productTypeID);
		this.itemForm.get('supplier').setValue(this.item.supplierID);
		this.itemForm.get('active').setValue(this.item.isActive);
		this.itemForm.get('timeLength').setValue(this.item.timeLength);
		this.getResourcesOnSupplier(this.item.supplierID);
		this.getResourcesOnProduct(this.item.id);
	}
	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		type: new FormControl('', [Validators.required]),
		supplier: new FormControl('', [Validators.required]),
		timeLength: new FormControl('', [Validators.required]),
		active: new FormControl('', [Validators.required]),
	});

	public resourceForm: FormGroup = new FormGroup({
		id: new FormControl(null),
		supplierResource: new FormControl('', [Validators.required]),
		supplierResourceProductType: new FormControl('', [Validators.required]),
		course: new FormControl({value: null, disabled: true}, [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public productTypeStore: ProductTypeStore,
		public supplierStore: SupplierStore,
		public supplierResourceStore: SupplierResourceStore,
		public supplierResourceProductTypeStore: SupplierResourceProductTypeStore,
		public supplierResourceProductStore: SupplierResourceProductStore,
	) {
		this.itemForm.get('name').valueChanges.pipe(untilDestroyed(this)).subscribe(name => this.item.name = name);
		this.itemForm.get('type').valueChanges.pipe(untilDestroyed(this)).subscribe(type => this.item.productTypeID = type);
		this.itemForm.get('active').valueChanges.pipe(untilDestroyed(this)).subscribe(active => this.item.isActive = active);
		this.itemForm.get('timeLength').valueChanges.pipe(untilDestroyed(this)).subscribe(timeLength => this.item.timeLength = timeLength);
		this.itemForm.get('supplier').valueChanges.pipe(untilDestroyed(this)).subscribe(supplierId => this.item.supplierID = supplierId);

		this.supplierResourceStore.reset();
		this.supplierResourceProductStore.reset();


		this.resourceForm.get('supplierResourceProductType').valueChanges.subscribe(value => {
			if (value) {
				this.resourceForm.get('course').enable();
			}
		});

		this.supplierResourceProductTypeStore.items$.pipe(untilDestroyed(this))
			.subscribe(supplierResourceProductTypes => this.supplierResourceProductTypes = supplierResourceProductTypes);
	}

	public resetProductResource() {
		this.resourceForm.reset();
	}

	public removeProductResource(supplierResourceProductId: number) {
		if (supplierResourceProductId) {
			this.supplierResourceProductStore.delete(supplierResourceProductId).subscribe(item => {
				this.resourceForm.reset();
			});
		}
	}

	public addProductResource() {
		const supplierResourceProduct = new SupplierResourceProduct();
		supplierResourceProduct.productId = this.item.id;
		supplierResourceProduct.id = this.resourceForm.get('id').value;
		supplierResourceProduct.supplierResourceId = this.resourceForm.get('supplierResource').value;
		supplierResourceProduct.supplierResourceProductTypeId = this.resourceForm.get('supplierResourceProductType').value.id;
		supplierResourceProduct.course = this.resourceForm.get('course').value;

		if (supplierResourceProduct.id) {
			this.supplierResourceProductStore.update(supplierResourceProduct).subscribe(item => {
				this.resourceForm.reset();
			});
		} else {
			this.supplierResourceProductStore.create(supplierResourceProduct).subscribe(item => {
				this.resourceForm.reset();
			});
		}
	}

	public selectProductResource(supplierResourceProduct: SupplierResourceProduct) {
		this.resourceForm.get('id').setValue(supplierResourceProduct.id);
		this.resourceForm.get('supplierResource').setValue(supplierResourceProduct.supplierResourceId);
		this.resourceForm.get('course').setValue(supplierResourceProduct.course);
		const supplierResourceProductType = _.find(this.supplierResourceProductTypes, type => type.id === supplierResourceProduct.supplierResourceProductTypeId);
		if (supplierResourceProductType) {
			this.resourceForm.get('supplierResourceProductType').setValue(supplierResourceProductType);
		}
	}

	private getResourcesOnSupplier(suplierId: number) {
		this.supplierResourceStore.getAllOnSupplier(suplierId).subscribe();
	}

	private getResourcesOnProduct(productId: number) {
		this.supplierResourceProductStore.getAllOnProduct(productId).subscribe();
	}

	ngOnInit() {}
	ngOnDestroy() {}
}
