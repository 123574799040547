
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from '../base.store.service';
import { CategoryCategory } from 'app/models/category/category-category.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SnackBarService } from 'app/admin/services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class CategoryCategoryStore extends BaseStore<CategoryCategory> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'CategoryCategories', baseUrl, snackBarService);
	}

	public getAllOnParent(parentCategoryId: number): Observable<CategoryCategory[]> {
		return this.http.get<CategoryCategory[]>(`${this.host}/parent/${parentCategoryId}`).pipe(tap(categoryTexts => {
			this._items = categoryTexts;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnChild(childCategoryId: number): Observable<CategoryCategory[]> {
		return this.http.get<CategoryCategory[]>(`${this.host}/child/${childCategoryId}`).pipe(tap(categoryTexts => {
			this._items = categoryTexts;
			this._itemsSubject.next(this._items);
		}));
	}

	public addDelete(categoryId: number, addCategoryCategories: CategoryCategory[], deleteIds: number[]): Observable<void> {
		const addDeleteObj = {
			addCategoryCategories,
			deleteIds,
		};
		return this.http.post<void>(`${this.host}/addDelete/${categoryId}`, addDeleteObj);
	}
}
