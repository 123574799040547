import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { User } from 'app/admin/models/user.model';
import { UserStore } from 'app/admin/stores/user.store.service';
import { UserTypeStore } from 'app/admin/stores/user-type.store.service';
import { UserServiceStore } from 'app/admin/stores/user-service.store.service';
import { UserService } from 'app/admin/services/user.service';

@Component({
	selector: 'app-user-blade',
	templateUrl: './user-blade.component.html',
	styleUrls: ['./user-blade.component.scss'],
})
@IBladeComponent.register
export class UserBladeComponent implements OnInit {

	public loading$ = new BehaviorSubject<boolean>(true);
	public item: User;

	public itemForm: FormGroup = new FormGroup({
		firstName: new FormControl('', [Validators.required]),
		lastName: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, Validators.email]),
		phone: new FormControl(''),
		userType: new FormControl(0, [Validators.required]),
		requireChangePassword: new FormControl(false),
		newPassword: new FormControl(''),
	});
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: UserStore,
		public userTypeStore: UserTypeStore,
		public userServiceStore: UserServiceStore,
		public userService: UserService,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.setValue();
				this.loading$.next(false);
			});
		} else {
			this.loading$.next(false);
			this.item = new User();
		}
		this.userTypeStore.getAll();
	}

	ngOnInit() {
	}

	public save() {
		this.loading$.next(true);
		this.item.firstName = this.itemForm.value.firstName;
		this.item.lastName = this.itemForm.value.lastName;
		this.item.email = this.itemForm.value.email;
		this.item.phone = this.itemForm.value.phone;
		this.item.userTypeId = this.itemForm.value.userType;
		this.item.requireChangePassword = this.itemForm.value.requireChangePassword;

		if (!this.item.id) {
			this.item.password = this.itemForm.value.newPassword;
		}

		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.setValue();
			this.loading$.next(false);
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<User> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

	private setValue(): void {
		this.itemForm.setValue({
			firstName: this.item.firstName,
			lastName: this.item.lastName,
			email: this.item.email,
			phone: this.item.phone,
			userType: this.item.userTypeId,
			requireChangePassword: this.item.requireChangePassword,
			newPassword: '',
		});
	}
}
