import { Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { TourBookingSpecialDietMessageStore } from 'app/admin/stores/tour-booking-special-diet-message.store.service';
import { TourBookingSpecialDietStore } from 'app/admin/stores/tour-booking-special-diet.store.service';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { TourBookingSpecialDietExtended } from '../models/tour-booking-special-diet-extended.model';
import { TourBookingSpecialDietComponent } from '../tour-booking-special-diet.component';
import { CustomerType } from '../../../../../models/customer-type.model';

@UntilDestroy()
@Injectable()
export class TourBookingSpecialDietService {
	private customerTypes: CustomerType[];
	public isSaving$ = new BehaviorSubject<boolean>(false);
	public specialDiets: TourBookingSpecialDietExtended[];
	public specialDietsReplay$ = new ReplaySubject<TourBookingSpecialDietExtended[]>();

	private deleteTourBookingSpecialDietIds: number[] = [];

	constructor(
		public dialogRef: MatDialogRef<TourBookingSpecialDietComponent>,
		private tourBookingSpecialDietStore: TourBookingSpecialDietStore,
		private tourBookingSpecialDietMessageStore: TourBookingSpecialDietMessageStore,
		public customerTypeStore: CustomerTypeStore,
		@Inject(MAT_DIALOG_DATA) public tourBookingId: number,
	) {
		combineLatest([
			this.tourBookingSpecialDietStore.getAllOnTourBooking$(this.tourBookingId),
			this.customerTypeStore.items$,
		])
		.pipe(untilDestroyed(this))
		.subscribe(([items, customerTypes]) => {
			const list: TourBookingSpecialDietExtended[] = [];
			this.customerTypes = customerTypes;

			_.forOwn(_.groupBy(items, x => x.groupName), (value, key, object) => {
				const customerType = _.find(customerTypes, c => c.identifier === key.split('_')[0]);
				list.push({
					customerTypeId: customerType.id,
					tourBookingSpecialDiets: value,
				});
			});
			this.specialDiets = list;
			this.specialDietsReplay$.next(this.specialDiets);
		});
	}

	public removeGroup(group: TourBookingSpecialDietExtended): void {
		const ids = group.tourBookingSpecialDiets.map(x => x.id);
		this.deleteTourBookingSpecialDietIds.push(...ids);
		this.deleteTourBookingSpecialDietIds = _.uniq(this.deleteTourBookingSpecialDietIds);
		this.specialDiets = _.xor(this.specialDiets, [group]);
		this.specialDietsReplay$.next(this.specialDiets);
	}

	public removeTourBookingSpecialDiet(tourBookingSpecialDietExtended: TourBookingSpecialDietExtended, specialDietId: number): void {
		const tourBookingSpecialDiet = _.find(tourBookingSpecialDietExtended.tourBookingSpecialDiets, x => x.specialDietId === specialDietId);
		if (tourBookingSpecialDiet) {
			tourBookingSpecialDietExtended.tourBookingSpecialDiets = _.xor(tourBookingSpecialDietExtended.tourBookingSpecialDiets, [tourBookingSpecialDiet]);

			if (tourBookingSpecialDiet.id) {
				this.deleteTourBookingSpecialDietIds.push(tourBookingSpecialDiet.id);
				this.deleteTourBookingSpecialDietIds = _.uniq(this.deleteTourBookingSpecialDietIds);
			}
			this.specialDietsReplay$.next(this.specialDiets);
		}
	}

	public addGroup(customerTypeId: number) {
		this.specialDiets.push({
			customerTypeId,
			tourBookingSpecialDiets: [],
		});
		this.specialDietsReplay$.next(this.specialDiets);
	}

	public addTourBookingSpecialDiet(tourBookingSpecialDietExtended: TourBookingSpecialDietExtended, specialDietId: number) {
		let groupName = this.getRandomKey(tourBookingSpecialDietExtended.customerTypeId);
		const excisting = tourBookingSpecialDietExtended.tourBookingSpecialDiets[0];
		if (excisting?.groupName) {
			groupName = excisting?.groupName;
		}
		tourBookingSpecialDietExtended.tourBookingSpecialDiets.push({
			specialDietId,
			tourBookingId: this.tourBookingId,
			groupName,
		});
		this.specialDietsReplay$.next(this.specialDiets);
	}

	private getRandomKey(customerTypeId: number) {
		const find = _.find(this.customerTypes, x => x.id === customerTypeId);
		return find.identifier + '_' + Math.random().toString(36).slice(-6);
	}

	public save(): void {
		this.specialDiets.forEach(specialDiet => {
			specialDiet.tourBookingSpecialDiets?.forEach(tourBookingSpecialDiet => {
				if (tourBookingSpecialDiet.id) {
					this.tourBookingSpecialDietStore.update(tourBookingSpecialDiet).subscribe();
				} else {
					this.tourBookingSpecialDietStore.create(tourBookingSpecialDiet).subscribe();
				}
			});
		});
	}
}
