import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Element } from 'app/models/element.model';
import { ElementStore } from 'app/admin/stores/element.store.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { ElementService } from '../../../../../services/element.service';

@UntilDestroy()
@Component({
	selector: 'app-column-blade-elements',
	templateUrl: './column-blade-elements.component.html',
	styleUrls: ['./column-blade-elements.component.scss'],
})
export class ColumnBladeElementsComponent implements OnInit, OnDestroy {
	private pristinePrioList: number[];
	private elements: Element[];
	public prioIsChangedReplay$ = new ReplaySubject<boolean>();
	public savingPrioReplay$ = new ReplaySubject<boolean>();
	public displayedColumns: string[] = ['name', 'modified'];
	public dataSource: MatTableDataSource<Element>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public elementStore: ElementStore,
		public elementService: ElementService,
	) {
	}

	ngOnInit() {
		this.elementStore.reset();
		this.elementStore.getAllOnColumn(this.bladeItem.id).subscribe();
		this.elementStore.items$
			.pipe(untilDestroyed(this))
			.subscribe(elements => {
				this.elements = _.sortBy(elements, i => i.prio);
				this.pristinePrioList = this.elements.map(p => p.id);

				this.dataSource = new MatTableDataSource<Element>(this.elements);
				this.dataSource.paginator = this.paginator;
			});
	}

	ngOnDestroy(): void { }

	public selectItem(row: Element) {
		const component = this.elementService.getElementComponent(row.elementTypeIdentifier);

		this.bladeService.addBladeItem({
			id: row.id,
			component,
			minClass: BladeSize.m,
			payload: {
				columnId: this.bladeItem.id,
			},
		}, this.bladeItem);

	}

	public onListDrop(event: CdkDragDrop<MatTableDataSource<Element>, any>) {
		const previousIndex = this.elements.findIndex(row => row === event.item.data);
		moveItemInArray(this.elements, previousIndex, event.currentIndex);
		this.elements.forEach((element, index) => element.prio = index);
		this.dataSource = new MatTableDataSource<Element>(this.elements);
		this.dataSource.paginator = this.paginator;

		this.isPrioChanged();
	}

	public savePrio() {
		this.savingPrioReplay$.next(true);
		this.elementStore.updatePrio(this.elements).pipe(untilDestroyed(this)).subscribe(done => {
			this.pristinePrioList = this.elements.map(p => p.id);
			this.savingPrioReplay$.next(false);
			this.isPrioChanged();
		});
	}

	private isPrioChanged() {
		if (this.elements.map(p => p.id).toString() !== this.pristinePrioList.toString()) {
			this.prioIsChangedReplay$.next(true);
		} else {
			this.prioIsChangedReplay$.next(false);
		}
	}
}
