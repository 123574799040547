<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem?.id">
	<app-blade-header [title]="'Produkt'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content [menuItems]="menuItems" (indexChange)="selectedIndex = $event;">
		<mat-card>
			<div *ngIf="productElement && selectedIndex === 1">
				<app-alert-card [mode]="'info'" [label]="'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quibusdam sapiente cupiditate aspernatur?'"></app-alert-card>
				<div *ngIf="productElement">
					<form [formGroup]="itemForm">
						<mat-form-field class="w-100">
							<mat-label>Välj product</mat-label>
							<mat-select formControlName="product">
								<mat-option *ngFor="let product of (productStore.items$|async)" [value]="product.id">
									{{product.id}} {{product.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</form>
				</div>
			</div>
			<app-element-blade-styles *ngIf="productElement && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="productElement?.id">Spara</span>
			<span *ngIf="!productElement?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
