<mat-card>
	<mat-card-content>
		<mat-card-title>Admin användaretyper</mat-card-title>
		<mat-card-subtitle>
			<p class="mb-1">Detta är olika typer av användare.</p>
		</mat-card-subtitle>
		<mat-card-content>
			<table mat-table [dataSource]="dataSource" matSort class="w-100">
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
					<td mat-cell *matCellDef="let item">{{item.id}} </td>
				</ng-container>
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
					<td mat-cell *matCellDef="let item"><strong>{{item.name}}</strong><br /><em>{{item.identifier}}</em></td>
				</ng-container>
				<ng-container matColumnDef="created">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
					<td mat-cell *matCellDef="let item">{{item.created|date:'yyyy-MM-dd HH:mm'}}<br />{{item.createdBy}}</td>
				</ng-container>
				<ng-container matColumnDef="modified">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Modifierad</th>
					<td mat-cell *matCellDef="let item">{{item.modified|date:'yyyy-MM-dd HH:mm'}}<br />{{item.modifiedBy}}</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.id === item?.id"></tr>
			</table>

			<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</mat-card-content>
	</mat-card-content>
</mat-card>

<div class="mt-3">
	<span fxFlex></span>
	<button mat-raised-button (click)="createNew()">Lägg till</button>
</div>
