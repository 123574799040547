import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Tour } from 'app/models/tour.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TourBooking } from 'app/models/tour-booking.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourBookingBladeComponent } from 'app/admin/blades/tour-booking/tour-booking-blade/tour-booking-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TravelAgencyProductDiscountStore } from 'app/admin/stores/travel-agency-product-discount.store.service';
import { TravelAgencyProductDiscount } from 'app/models/travel-agency-product-discount.model';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { combineAll, switchMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-travel-agency-discounts',
	templateUrl: './travel-agency-discounts.component.html',
	styleUrls: ['./travel-agency-discounts.component.scss'],
})
export class TravelAgencyDiscountsComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['product', 'discount', 'action'];
	public dataSource: MatTableDataSource<TravelAgencyProductDiscount>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public productStore: ProductStore,
		public travelAgencyProductDiscountStore: TravelAgencyProductDiscountStore,
		private bladeService: BladeService,
	) {
		combineLatest([
			this.productStore.getAll$(),
			this.travelAgencyProductDiscountStore.getAllOnTravelAgency(bladeItem.id).pipe(switchMap(() => this.travelAgencyProductDiscountStore.items$)),
		])
		.pipe(untilDestroyed(this))
		.subscribe(([products, travelAgencyProductDiscounts]) => {
			const completedList: TravelAgencyProductDiscountView[] = [];
			products.forEach(product => {
				const excistingDescount = travelAgencyProductDiscounts.find(discount => discount.productId === product.id);
				if (excistingDescount) {
					completedList.push(excistingDescount as TravelAgencyProductDiscountView);
				} else {
					completedList.push({
						travelAgencyId: bladeItem.id,
						productId: product.id,
					} as TravelAgencyProductDiscountView);
				}
			});
			this.dataSource = new MatTableDataSource<TravelAgencyProductDiscountView>(completedList);
			this.dataSource.sort = this.sort;
			this.dataSource.paginator = this.paginator;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {}

	public setDiscount(item: TravelAgencyProductDiscountView) {
		item.loading = true;
		this.saveOrUpdate(item).subscribe(updatedItem => {
			Object.assign(item, updatedItem);
			item.loading = false;
		});
	}

	private saveOrUpdate(item: TravelAgencyProductDiscount): Observable<TravelAgencyProductDiscount> {
		if (item.id > 0) {
			return this.travelAgencyProductDiscountStore.update(item);
		} else {
			return this.travelAgencyProductDiscountStore.create(item);
		}
	}

}

interface TravelAgencyProductDiscountView extends TravelAgencyProductDiscount {
	loading: boolean;
}
