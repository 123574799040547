import { Component } from '@angular/core';

@Component({
  selector: 'app-guide-tours',
  templateUrl: './guide-tours.component.html',
  styleUrls: ['./guide-tours.component.scss'],
})
export class GuideToursPageComponent {
  constructor() { }
}
