<div class="container py-3" *ngIf="{
	tour: tour$ | async,
	tourMessages: tourMessages$ | async,
	settings: siteSettings$ | async,
	tourBookings: tourBookings$ | async,
	language: language$ | async,
	adminText: 'tourCustomerProgram' | adminText: ((language$ | async)?.prefix) | async
} as source">
	<mat-card>
		<div class="align-items-center d-flex justify-content-between mb-3 pb-3 border-bottom">
			<img class="logo" width="250" [src]="source.settings?.logo">
			<div>
				<p class="title">{{source.adminText?.title}}</p>
			</div>
		</div>
		<div class="mb-3 pb-3 border-bottom">
			<mat-card-title class="d-flex justify-content-between">
				<span>{{(source.tour?.id | tourName: source.language?.id | async)}}</span>
				<span *ngIf="source.tour?.tourStatusId === 3">{{source.adminText?.statusMapping[source.tour?.tourStatusId | tourStatusName | async]}}</span>
			</mat-card-title>
			<mat-card-subtitle>
				<span>{{source.tour?.date | date:'yyyy-MM-dd'}} {{source.tour?.time}} (<span *ngIf="source.tour?.isPrivate">{{source.adminText?.private}}</span><span *ngIf="!source.tour?.isPrivate">{{source.adminText?.noPrivate}}</span>)</span>
			</mat-card-subtitle>
			<div>
				<mat-card-subtitle class="m-0">
					<span *ngIf="source.tourMessages?.length">{{source.adminText?.messages}}</span>
					<span *ngIf="!source.tourMessages?.length">{{source.adminText?.noMessages}}</span>
				</mat-card-subtitle>
				<mat-card *ngFor="let message of source.tourMessages" class="mb-3">
					<span [innerHtml]="message.message | newLine"></span>
				</mat-card>
			</div>
		</div>

		<div>
			<p class="mat-h2">{{source.adminText?.participantTitle}}</p>
			<div *ngIf="source.tour?.id">
				<app-tours-customers-program-customer [language]="source.language" [tourBooking]="tourBooking" [tour]="source.tour" *ngFor="let tourBooking of source.tourBookings" class="d-block mb-3 avoid-page-break-inside"></app-tours-customers-program-customer>
			</div>
		</div>
	</mat-card>
</div>
