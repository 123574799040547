import { BaseModel } from './base.model';

export class CardElement extends BaseModel {
	public elementId: number;
	public languageId: number;
	public title: string;
	public subTitle: string;
	public mediaId: number;
	public smallMediaId: number;
	public text: string;
}
