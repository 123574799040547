import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { ChartsModule } from 'ng2-charts';
import { GridsterModule } from 'angular-gridster2';

import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

import { MinPipe } from '../../pipes/min.pipe';

import {
	DashboardWidgetModalComponent,
} from './components/dashboard-widget/components/dashboard-widget-modal/dashboard-widget-modal.component';
import { DashboardComponent } from './dashboard.component';
import { DashboardWidgetComponent } from './components/dashboard-widget/dashboard-widget.component';
import { DashboardWidgetOrdersComponent } from './components/dashboard-widget/components/dashboard-widget-orders/dashboard-widget-orders.component';
import { PipeModule } from 'app/admin/pipes/pipe.module';
import { DashboardWidgetTourBookingsComponent } from './components/dashboard-widget/components/dashboard-widget-tour-bookings/dashboard-widget-tour-bookings.component';
import { DashboardWidgetOrderPaymentsComponent } from './components/dashboard-widget/components/dashboard-widget-order-payments/dashboard-widget-order-payments.component';
import { DashboardWidgetOrderAmountComponent } from './components/dashboard-widget/components/dashboard-widget-order-amount/dashboard-widget-order-amount.component';
import { DashboardWidgetOrdersRestComponent } from './components/dashboard-widget/components/dashboard-widget-orders-rest/dashboard-widget-orders-rest.component';
import { DashboardWidgetDraftOrdersComponent } from './components/dashboard-widget/components/dashboard-widget-draft-orders/dashboard-widget-draft-orders.component';
import { AdminDataModule } from 'app/modules/admin-data/admin-data.module';
import { DashboardWidgetOrderRestService } from './services/dashboard-widget-order-rest.service';
import { DashboardWidgetOrderRestModalComponent } from './components/dashboard-widget/components/dashboard-widget-orders-rest/dialogs/dashboard-widget-modal/dashboard-widget-order-rest-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';




const materialModules = [
	DragDropModule,
	MatCardModule,
	MatButtonModule,
	MatGridListModule,
	MatMenuModule,
	MatIconModule,
	MatDialogModule,
	MatInputModule,
	MatSelectModule,
	MatSlideToggleModule,
	MatTableModule,
	MatSortModule,
	MatDatepickerModule,
	MatCheckboxModule,
	MatProgressBarModule,
];

const modals = [
	DashboardWidgetModalComponent,
	DashboardWidgetOrderRestModalComponent,
];

const components = [
	DashboardComponent,
	DashboardWidgetComponent,
	DashboardWidgetOrdersComponent,
	DashboardWidgetOrdersRestComponent,
	DashboardWidgetTourBookingsComponent,
	DashboardWidgetOrderPaymentsComponent,
	DashboardWidgetOrderAmountComponent,
	DashboardWidgetDraftOrdersComponent,
];

@NgModule({
	entryComponents: [
		...modals,
	],
	declarations: [
		...components,
		...modals,
		MinPipe,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ChartsModule,
		GridsterModule,
		PipeModule,
		AdminDataModule,
		...materialModules,
	],
	exports: [
		...components,
	],
	providers: [
		DashboardWidgetOrderRestService,
	],
})
export class DashboardModule { }
