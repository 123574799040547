<ng-container *ngIf="{
	specialDiets: tourBookingSpecialDietService.specialDietsReplay$ | async,
	customerTypes: tourBookingSpecialDietService.customerTypeStore.items$ | async
} as source">
	<h2 mat-dialog-title>Redigera upphämtningsplats på tourBooking</h2>
	<mat-dialog-content>
		<app-tour-booking-special-diet-item class="d-block mb-3" *ngFor="let item of source.specialDiets; let index = index" [tourBookingSpecialDietExtendedItem]="item" [index]="index"></app-tour-booking-special-diet-item>
		<mat-card>
			<button class="mr-3" *ngFor="let customerType of source.customerTypes" mat-button (click)="tourBookingSpecialDietService.addGroup(customerType.id)">
				Lägg till {{customerType.id | customerTypeName | async}}
			</button>
		</mat-card>
		<app-tour-booking-special-diet-message></app-tour-booking-special-diet-message>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-button (click)="tourBookingSpecialDietService.save()">Spara</button>
	</mat-dialog-actions>
</ng-container>
