import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import {
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { CategoryText } from 'app/models/category/category-text.model';
import { CategoryTextStore } from 'app/admin/stores/category/category-text.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-category-text-blade',
	templateUrl: './category-text-blade.component.html',
	styleUrls: ['./category-text-blade.component.scss'],
})
@IBladeComponent.register
export class CategoryTextBladeComponent implements OnInit {
	public loading = true;
	public item: CategoryText;

	public itemForm: FormGroup = new FormGroup({
		headline: new FormControl('', [Validators.required]),
		information: new FormControl('', [Validators.required]),
		description: new FormControl('', [Validators.required]),
		seoTitle: new FormControl('', []),
		seoDescription: new FormControl('', []),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: CategoryTextStore,
	) {
		if (
			bladeItem.payload &&
			bladeItem.payload.categoryId &&
			bladeItem.payload.languageId
		) {
			itemStore
				.getOnCategoryAndLanguage(
					+bladeItem.payload.categoryId,
					+bladeItem.payload.languageId,
				)
				.subscribe(item => {
					this.item = item;
					if (!this.item) {
						this.item = new CategoryText();
						this.item.categoryId = +bladeItem.payload.categoryId;
						this.item.languageId = +bladeItem.payload.languageId;
					}
					this.itemForm.patchValue(this.item);

					this.loading = false;
				});
		} else {
			console.error('Missing categoryId and/or languageId');
		}
	}

	ngOnInit() { }

	public save() {
		this.loading = true;
		this.item = _.assign(this.item, this.itemForm.value);

		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<CategoryText> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

}
