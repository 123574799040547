import { BaseModel } from '../base.model';
import { PageText } from '../page-text.model';
import { Etage } from './etage.model';

export class Page extends BaseModel {
	public name: string;
	public texts?: PageText[];
	public route?: string;
	public externalLink?: string;
	public pageTypeId?: number;
	public pageTypeIdentifier: string;
	public productTypeIdentifier?: string;
	public etages: Etage[];
	public isActive: number;
	public prio?: number;
	public showOnMenu?: boolean;
	public classes?: string[];
	public style?: any;
}
