import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { TourBookingAddon } from 'app/models/tour-booking-addon.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-tour-booking-addon-blade-generally',
  templateUrl: './tour-booking-addon-blade-generally.component.html',
  styleUrls: ['./tour-booking-addon-blade-generally.component.scss'],
})
export class TourBookingAddonBladeGenerallyComponent implements OnInit {

	public maxQuantity: number;

	@Input() set item(item: TourBookingAddon) {
		this.maxQuantity = item.quantity;
		this.itemForm.get('quantity').setValue(item.quantity);
		this.itemForm.get('quantity').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			item.quantity = value;
		});
	}

	public itemForm: FormGroup = new FormGroup({
		quantity: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
	) { }

  	ngOnInit() {
  	}
}
