import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { PickupLocationBladeComponent } from '../pickup-location-blade/pickup-location-blade.component';
import { PickupLocationStore } from 'app/admin/stores/pickup-location.store.service';
import { PickupLocation } from 'app/models/page-location.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-pickup-locations-blade',
	templateUrl: './pickup-locations-blade.component.html',
	styleUrls: ['./pickup-locations-blade.component.scss'],
})
@IBladeComponent.register
export class PickupLocationsBladeComponent implements OnInit, OnDestroy {

	public displayedColumns: string[] = ['name', 'relativeTime'];
	public dataSource: MatTableDataSource<PickupLocation>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: PickupLocationStore,
	) {
		this.itemStore.getAll$().pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<PickupLocation>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: PickupLocationBladeComponent,
			minClass: BladeSize.s,
			maxClass: BladeSize.m,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: PickupLocationBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}
}
