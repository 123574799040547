<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem.id">
	<app-blade-header [title]="'Profil undersida'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
		<mat-card>
			<div *ngIf="loginElement && selectedIndex !== 99">
				<app-alert-card [mode]="'info'" [label]="'I detta element kommer undersidorna för profilsidan att visas.'"></app-alert-card>
			</div>
			<app-element-blade-styles *ngIf="loginElement && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="loginElement?.id">Spara</span>
			<span *ngIf="!loginElement?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
