import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { OrderPaymentStore } from 'app/admin/stores/order-payment.store.service';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Order } from 'app/models/order.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourBooking } from 'app/models/tour-booking.model';
import { Observable } from 'rxjs/internal/Observable';
import { CreataOrderTourBookingBladeComponent } from '../../../create-order-tour-booking-blade/create-order-tour-booking-blade.component';
import { CreateOrderModeEnum } from 'app/enums/create-order-mode.enum';

@Component({
	selector: 'app-order-tour-bookings',
	templateUrl: './order-tour-bookings.component.html',
	styleUrls: ['./order-tour-bookings.component.scss'],
})
export class OrderTourBookingsComponent implements OnInit {
	public tourBookings$: Observable<TourBooking[]>;

	@Input() set order(order: Order) {
		if (order && order.id) {
			this.tourBookings$ = this.tourBookingStore.getAllOnOrder$(order.id);
		}
	}

	public displayedColumns: string[] = ['id', 'name', 'source', 'status', 'created'];
	public dataSource: MatTableDataSource<TourBooking>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	constructor(
		private bladeService: BladeService,
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public orderPaymentStore: OrderPaymentStore,
		public tourBookingStore: TourBookingStore,
	) {
		this.bladeItem.callback = () => {
			this.tourBookings$ = this.tourBookingStore.getAllOnOrder$(this.bladeItem.id);
		};
	}

	ngOnInit() {}

	public createNewTourBooking(): void {
		this.bladeService.addBladeItem({
			id: 0,
			component: CreataOrderTourBookingBladeComponent,
			minClass: BladeSize.m,
			payload: {
				mode: CreateOrderModeEnum.newOrder,
				orderId: this.bladeItem.id,
			},
		}, this.bladeItem);
	}
}
