import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';

import { Order } from 'app/models/order.model';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { BladeService } from 'app/admin/components/blade/blade.service';
import { OrderBladeComponent } from 'app/admin/blades/order/order-blade/order-blade.component';
import { OrdersBladeComponent } from 'app/admin/blades/order/orders-blade/orders-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';

@UntilDestroy()
@Component({
	selector: 'app-dashboard-widget-draft-orders',
	templateUrl: './dashboard-widget-draft-orders.component.html',
	styleUrls: ['./dashboard-widget-draft-orders.component.scss'],
})
export class DashboardWidgetDraftOrdersComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['id', 'customer', 'payment', 'source', 'created'];
	public dataSource: MatTableDataSource<Order>;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private router: Router,
		private orderStore: OrderStore,
		private bladeService: BladeService,
	) { }

	ngOnInit() {
		this.orderStore.getAllDraft().pipe(untilDestroyed(this)).subscribe(orders => {
			this.dataSource = new MatTableDataSource<Order>(_.orderBy(orders, o => o.created, 'desc'));
			this.dataSource.sort = this.sort;
		});
	}

	ngOnDestroy(): void { }

	public selectItem(order: Order) {
		this.bladeService.setInitOverride([
			{
				id: 0,
				component: OrdersBladeComponent,
				minClass: BladeSize.s,
				maxClass: BladeSize.m,
			}, {
				id: order.id,
				component: OrderBladeComponent,
				minClass: BladeSize.m,
				maxClass: BladeSize.l,
			},
		]);
		this.router.navigateByUrl(`orders/list`);
	}
}
