import { Component, OnInit } from '@angular/core';
import { AddonStore } from 'app/admin/stores/addon.store.service';

import { OddFunctionService } from '../../services/odd-function.service';

@Component({
	selector: 'app-odd-function-blade-addons',
	templateUrl: './function-blade-addons.component.html',
	styleUrls: ['./function-blade-addons.component.scss'],
})
export class OddFunctionBladeAddonsComponent implements OnInit {
	constructor(
		public productFunctionService: OddFunctionService,
		public addonStore: AddonStore,
	) {

	}

	ngOnInit(): void {
	}
}
