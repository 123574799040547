<app-blade-item [canIClose]="true" [loading]="loading" [id]="item?.id">
	<app-blade-header [title]="'Skapa nytt element'" [subTitle]="'Element'"></app-blade-header>
	<!-- New element content -->
	<app-blade-content *ngIf="!item?.id">
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<mat-label>Välj element-typ</mat-label>
				<mat-select formControlName="elementType">
					<mat-option *ngFor="let type of (elementTypeStore.items$|async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
