import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { SiteData } from 'app/models/site-data.model';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class SiteDataStore extends BaseStore<SiteData> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'SiteData', baseUrl, snackBarService);
	}

	public getOnLanguage(languageId: number): Observable<SiteData> {
		return this.http.get<SiteData>(`${this.host}/language/${languageId}`).pipe(tap(siteData => {
			if (siteData) {
				const findObj = _.find(this._items, type => type.id === siteData.id
									&& type.languageId === siteData.languageId);
				_.assign(findObj, siteData); // Keep object in sync
			}
		}));
	}

	public addSiteData(siteData: SiteData) {
		this._items.push(siteData);
		this._itemsSubject.next(this._items);
	}

	public removeSiteData(siteData: SiteData) {
		_.remove(this._items, siteData);
		this._itemsSubject.next(this._items);
	}
}
