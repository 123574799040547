<div class="mat-elevation-z1">
	<mat-table [dataSource]="dataSource" class="w-100">
		<ng-container matColumnDef="date">
			<mat-header-cell *matHeaderCellDef>Datum</mat-header-cell>
			<mat-cell *matCellDef="let item"><strong>{{item.created | date:'yyyy-MM-dd HH:mm'}}</strong></mat-cell>
		</ng-container>
		<ng-container matColumnDef="toEmail">
			<mat-header-cell *matHeaderCellDef>Till</mat-header-cell>
			<mat-cell *matCellDef="let item">{{item.toEmail}}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="latestEvent">
			<mat-header-cell *matHeaderCellDef>Senaste händelsen</mat-header-cell>
			<mat-cell *matCellDef="let item">
				<div class="text-right w-100">
					<span class="d-block"><b>{{item.latestEvent?.event}}</b></span>
					<span class="d-block">{{item.latestEvent?.created | date:'yyyy-MM-dd HH:mm'}}</span>
				</div>
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.id == item?.id"></mat-row>
	</mat-table>

	<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>
