<mat-card>
	<mat-card-title>Sirvoy</mat-card-title>
	<mat-card-subtitle>Ange ID för att koppla denna produkt med Sirvoy</mat-card-subtitle>

	<form [formGroup]="itemForm">
		<mat-form-field class="w-100">
			<input matInput formControlName="sirvoyId" placeholder="Sirvoy id">
		</mat-form-field>
		<mat-form-field class="w-100">
			<input matInput formControlName="rooms" placeholder="Sirvoy rum">
		</mat-form-field>
	</form>	
</mat-card>
