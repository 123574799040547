import { Component, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import * as _ from 'lodash';

import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { TourBooking } from 'app/models/tour-booking.model';
import { TourBookingAddonStore } from 'app/admin/stores/tour-booking-addon.store.service';
import { TourSummaryService } from '../../services/tour-summary.service';

@UntilDestroy()
@Component({
	selector: 'app-tour-summary-message',
	templateUrl: './tour-summary-message.component.html',
	styleUrls: ['./tour-summary-message.component.scss'],
})
export class TourSummaryMessageComponent implements OnDestroy {
	@ViewChild(MatAccordion) accordion: MatAccordion;
	private tourBookings: TourBooking[];
	public displayedColumns: string[] = ['name', 'quantity'];
	public tourBookingsReplay = new ReplaySubject<TourBooking[]>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private tourBookingAddonStore: TourBookingAddonStore,
		private tourSummaryService: TourSummaryService,
	) {
		this.tourSummaryService.tourBookingsBehavior$.pipe(untilDestroyed(this)).subscribe(tourBookings => {
			this.tourBookings = [];
			tourBookings.forEach(tourBooking => {
				if (tourBooking.message) {
					this.tourBookings.push(tourBooking);
				}
			});
			this.tourBookingsReplay.next(this.tourBookings);
		});
	}

	ngOnDestroy() { }
}
