<ng-container *ngIf="{
	loading: loading$ | async
} as source">
	<app-blade-item [canIClose]="true" [loading]="source.loading">
		<app-blade-header [title]="'Mina turer'"></app-blade-header>
		<app-blade-content>
			<mat-card class="align-items-center d-flex justify-content-between mb-2">
				<div>
					<mat-form-field>
						<mat-label>Välj från och till datum</mat-label>
						<mat-date-range-input
							[formGroup]="formGroup"
							[rangePicker]="campaignOnePicker"
							[comparisonStart]="formGroup.value.start"
							[comparisonEnd]="formGroup.value.end">
							<input matStartDate placeholder="Från datum" formControlName="start">
							<input matEndDate placeholder="Till datum" formControlName="end">
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
						<mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
					</mat-form-field>
					<mat-form-field class="ml-3" [formGroup]="searchFormGroup">
						<mat-label>Välj guide</mat-label>
						<input type="text"
							placeholder="Välj guide"
							matInput
							formControlName="guide"
							[matAutocomplete]="auto">
						<button mat-button *ngIf="searchFormGroup.value.guide" matSuffix mat-icon-button aria-label="Clear" (click)="clearGuide()">
							<mat-icon>close</mat-icon>
						</button>
						<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectTourGuide($event)">
						<mat-option *ngFor="let tourGuide of tourGuides | async" [value]="tourGuide">
							{{tourGuide.name}}
						</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>
				<button mat-stroked-button color="primary" (click)="getTours()" [disabled]="!formGroup.value.start || !selectedTourGuide">Sök</button>
			</mat-card>
			<div class="mat-elevation-z1">
				<table mat-table [dataSource]="dataSource" matSort class="w-100">
					<ng-container matColumnDef="date">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
						<td mat-cell *matCellDef="let item">
							<span *ngIf="_.includes(moment(item.date).format('YYYY-MM-DD'), '0001-01-01')">Saknar datum</span>
							<span *ngIf="!_.includes(moment(item.date).format('YYYY-MM-DD'), '0001-01-01')">{{item.date|date:'yyyy-MM-dd'}} {{item.time || '--:--'}}</span>
						</td>
					</ng-container>
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
						<td mat-cell *matCellDef="let item"><strong>{{item.name || (item.productId | productName | async)}}</strong></td>
					</ng-container>
					<ng-container matColumnDef="places">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Antal</th>
						<td mat-cell *matCellDef="let item">{{item.quantity}}/{{item.availablePlaces}}</td>
					</ng-container>
					<ng-container matColumnDef="status">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
						<td mat-cell *matCellDef="let item">{{item.tourStatusId | tourStatusName | async}}</td>
					</ng-container>
					<ng-container matColumnDef="isActive">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Aktiv</th>
						<td mat-cell *matCellDef="let item">
							<mat-icon *ngIf="item.isActive">check</mat-icon>
						</td>
					</ng-container>
					<ng-container matColumnDef="isPrivate">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Privat</th>
						<td mat-cell *matCellDef="let item">
							<mat-icon *ngIf="item.isPrivate">check</mat-icon>
						</td>
					</ng-container>
					<ng-container matColumnDef="open">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let item">
							<button mat-icon-button (click)="$event.stopPropagation(); openInNewTab(item)">
								<mat-icon>open_in_new</mat-icon>
							</button>
						</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
				</table>

				<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
			</div>
		</app-blade-content>
	</app-blade-item>
</ng-container>
