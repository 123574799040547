import { BaseModel } from './base.model';
import { TourBookingCustomerTypeAge } from './tour-booking-customer-type-age.model';
import { OrderItem } from './order-item.model';

export class TourBookingCustomerType extends BaseModel {
	public tourBookingId: number;
	public customerTypeId: number;
	public quantity: number;
	public orderItemId?: number;
	public tourBookingCustomerTypeAges?: TourBookingCustomerTypeAge[];
	public orderItem: OrderItem;
}
