import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { LanguageStore } from 'app/admin/stores/language.store.service';

@Component({
	selector: 'app-tour-customer-program',
	templateUrl: './tour-customer-program.component.html',
	styleUrls: ['./tour-customer-program.component.scss'],
})
export class TourCustomerProgramComponent implements OnInit {
	public itemForm: FormGroup = new FormGroup({
		languageId: new FormControl(null, [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public languageStore: LanguageStore,
	) {}

	ngOnInit() {}

	public showProgram() {
		window.open(`/tours/customer-program?hideMenuSideNav=true&languageId=${this.itemForm.value.languageId}&tourId=${this.bladeItem.id}`, '_blank');
	}
}
