import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BladeModalData } from '../../../models/bladeModalData.model';

@Component({
  selector: 'app-blade-modal',
  templateUrl: './blade-modal.component.html',
  styleUrls: ['./blade-modal.component.scss'],
})
export class BladeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public bladeModalData: BladeModalData) { }

  ngOnInit() {
  }

}
