import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { SlideshowItemTypeStore } from 'app/admin/stores/slideshow-item-type.store.service';
import { SlideshowItemTypeService } from 'app/admin/services/slideshow-item-type.service';
import { SlideshowItem } from 'app/models/slideshow-item.model';
import { SlideshowItemStore } from 'app/admin/stores/slideshow-item.store.service';

@Component({
	selector: 'app-slideshow-item-blade',
	templateUrl: './slideshow-item-blade.component.html',
	styleUrls: ['./slideshow-item-blade.component.scss'],
	providers: [SlideshowItemTypeService],
})
@IBladeComponent.register
export class SlideshowItemBladeComponent implements OnInit {
	public itemForm: FormGroup = new FormGroup({
		slideshowItemType: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public slideshowItemTypeStore: SlideshowItemTypeStore,
		private bladeService: BladeService,
		private slideshowItemTypeService: SlideshowItemTypeService,
		private itemStore: SlideshowItemStore,
	) {
	}

	ngOnInit() {}

	public save() {
		const slideshowItem = new SlideshowItem();
		slideshowItem.slideshowElementId = this.bladeItem.id;
		slideshowItem.slideshowItemTypeId = +this.itemForm.get('slideshowItemType').value;
		this.itemStore.create(slideshowItem).subscribe(item => {
			this.openElement(item);
		});
	}

	private openElement(slideshowItem: SlideshowItem) {
		this.bladeService.addBladeItem({
			id: slideshowItem.id,
			component: this.slideshowItemTypeService.getSlideshowItemComponent(this.itemForm.get('slideshowItemType').value),
			minClass: BladeSize.m,
		}, this.bladeItem.parent );
	}

}
