import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Customer } from 'app/models/customer.model';
import { EmailCompanyStore } from 'app/admin/stores/email-company.store.service';

@Component({
	selector: 'app-settings-email',
	templateUrl: './settings-email.component.html',
	styleUrls: ['./settings-email.component.scss'],
})
export class SettingsEmailComponent {
	@Input() customer: Customer;

	public itemForm: FormGroup = new FormGroup({
		id: new FormControl(0),
		name: new FormControl('', [Validators.required]),
		url: new FormControl('', [Validators.required]),
		logo: new FormControl('', [Validators.required]),
		sendinBlueApiKey: new FormControl('', [Validators.required]),
		replayToEmail: new FormControl('', [Validators.required]),
		senderEmail: new FormControl('', [Validators.required]),
		sendCopyToEmail: new FormControl(''),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public emailCompanyStore: EmailCompanyStore,
	) {
		this.emailCompanyStore.getActive().subscribe(emailCompany => this.itemForm.patchValue(emailCompany));
	}

	public save() {
		if (!this.itemForm.valid) {
			return;
		}
		if (this.itemForm.value.id) {
			this.emailCompanyStore.update(this.itemForm.value).subscribe(emailCompany => this.itemForm.patchValue(emailCompany));
		} else {
			this.emailCompanyStore.create(this.itemForm.value).subscribe(emailCompany => this.itemForm.patchValue(emailCompany));
		}
	}
}
