<mat-card>
	<mat-card-title>Order</mat-card-title>

	<table mat-table [dataSource]="dataSource" class="w-100">
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef>ID</th>
			<td mat-cell *matCellDef="let item">{{item.id}} </td>
		</ng-container>
		<ng-container matColumnDef="customer">
			<th mat-header-cell *matHeaderCellDef>Kund</th>
			<td mat-cell *matCellDef="let item">{{item.customerId | customerName | async}}</td>
		</ng-container>
		<ng-container matColumnDef="amount">
			<th mat-header-cell *matHeaderCellDef>Belopp</th>
			<td mat-cell *matCellDef="let item">{{item.amount | currency:'SEK':'code'}}</td>
		</ng-container>
		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef>Skapad</th>
			<td mat-cell *matCellDef="let item">{{item.created|date:'yyyy-MM-dd HH:mm'}}</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="openOrder(item)" [class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
	</table>
	<mat-card-actions>
		<span fxFlex></span>
		<!-- <button mat-raised-button color="primary" (click)="createOrder()">Skapa ny order</button> -->
	</mat-card-actions>
</mat-card>
