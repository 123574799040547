import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { OrderRestWidget } from 'app/models/order-rest-widget.model';
import { SelectionModel } from '@angular/cdk/collections';
import { PaymentTypeStore } from '../../../stores/payment-type.store.service';

@UntilDestroy()
@Injectable()
export class DashboardWidgetOrderRestService {
	public isLoading$ = new ReplaySubject<boolean>(1);
	public orders$ = new ReplaySubject<OrderRestWidget[]>(1);
	public formGroup = new FormGroup({
		fromDate: new FormControl(moment().add(-14, 'days'), [Validators.required]),
		toDate: new FormControl(moment(), [Validators.required]),
	});

	public selectedPaymentTypes = new SelectionModel(
		true,   // multiple selection or not
		[],
	);

	constructor(
		private orderStore: OrderStore,
		public paymentTypeStore: PaymentTypeStore,
	) {
		this.paymentTypeStore.items$.pipe(untilDestroyed(this)).subscribe(paymentTypes => {
			this.selectedPaymentTypes.select(...paymentTypes.map(x => x.id));
			this.selectedPaymentTypes.select(999);
			this.getOrders();
		});
	}

	public getOrders(): void {
		this.isLoading$.next(true);
		this.orderStore.getAllWithRest(this.formGroup.value.fromDate, this.formGroup.value.toDate, this.selectedPaymentTypes.selected)
		.pipe(untilDestroyed(this)).subscribe(orders => {
			this.isLoading$.next(false);
			this.orders$.next(orders);
		});
	}
}
