import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { PageType } from 'app/models/page-type.model';
import { PageTypeStore } from 'app/admin/stores/page-type.store.service';
import { PageTypeBladeComponent } from 'app/admin/blades/page-type/page-type-blade/page-type-blade.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-settings-page-type',
	templateUrl: './settings-page-type.component.html',
	styleUrls: ['./settings-page-type.component.scss'],
})
export class SettingsPageTypeComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = ['id', 'name', 'created', 'modified'];
	public dataSource: MatTableDataSource<PageType>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: PageTypeStore,
	) {
		this.itemStore.getAll$().pipe(untilDestroyed(this)).subscribe(items => {
			this.dataSource = new MatTableDataSource<PageType>(items);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: PageTypeBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: PageTypeBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}
}
