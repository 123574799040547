import { Component, OnInit, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeService } from '../blade.service';
import { BladeItem } from '../models/bladeItem.model';
import { BladePositionService } from '../blade-position.service';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BladeNavigationComponent } from './components/blade-navigation/blade-navigation.component';

@UntilDestroy()
@Component({
	selector: 'app-blade-system',
	templateUrl: './blade-system.component.html',
	styleUrls: ['./blade-system.component.scss'],
})
export class BladeSystemComponent implements OnInit, OnDestroy {

	public isHandset$: Observable<boolean> = this.breakpointObserver
	.observe([Breakpoints.XSmall])
	.pipe(map(result => {
		return result.matches;
	}));

	public isShowing = false;
	public isremoving = false;
	public bladeItems: BladeItem[] = [];
	public translateValue = 0;
	public bladeContainerWidth = 1000;
	constructor(
		public bladeService: BladeService,
		public bladePositionService: BladePositionService,
		private breakpointObserver: BreakpointObserver,
		private bottomSheet: MatBottomSheet,

	) {
		this.bladeService.bladeItems$.pipe(untilDestroyed(this)).subscribe(bladeItems => {
			this.bladeItems = bladeItems;
			setTimeout(() => {
				this.calculateBladeContainerWidth();
			}, 100);
		});
	}

	ngOnInit() {
	}

	ngOnDestroy(): void {
	}

	public openButtonNavigation() {
		this.bottomSheet.open(BladeNavigationComponent, {
			panelClass: 'p-1',
		});
	}

	private calculateBladeContainerWidth() {
		let bladeContainerWidth = 2000;
		_.each(this.bladeItems, item => {
			bladeContainerWidth += this.bladePositionService.getBladeItemClassWidth(item.activeClass);
		});
		this.bladeContainerWidth = bladeContainerWidth;
	}
}
