import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { Customer } from 'app/models/customer.model';
import * as _ from 'lodash';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class CustomerStore extends BaseStore<Customer> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Customer', baseUrl, snackBarService);
	}

	public getAllOnTravelAgency(travelAgencyId: number): void {
		this.http.get<Customer[]>(`${this.host}/travelAgency/${travelAgencyId}`).subscribe(etages => {
			this._items = etages || [];
			this._itemsSubject.next(this._items);
		});
	}
}
