<app-blade-item [canIClose]="true" [loading]="loading">
	<app-blade-header [title]="item?.name" subTitle="Orderrad" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content *ngIf="item">
		<mat-card>
			<mat-card-content>
				<form [formGroup]="itemForm" class="mb-2">
					<mat-form-field class="w-100">
						<mat-label>Form av orderrad</mat-label>
						<mat-select [formControlName]="'selectedOrderRowType'">
							<mat-option *ngFor="let type of orderRowTypes" [value]="type.orderRowTypeEnum">
								{{type.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>


					<mat-form-field class="w-100">
						<mat-label>Namn</mat-label>
						<input type="text" matInput formControlName="name" placeholder="Namn">
					</mat-form-field>

					<div class="row border-top mt-2 pt-3">
						<div class="col-12 col-md-6">
							<mat-form-field class="w-100">
								<mat-label>Product typ</mat-label>
								<mat-select [formControlName]="'productTypeId'">
									<mat-option *ngFor="let type of (productTypeStore.items$ | async)" [value]="type.id">
										{{type.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-12 col-md-6">
							<mat-form-field class="w-100">
								<mat-label>Produkt</mat-label>
								<mat-select [formControlName]="'productId'">
									<mat-option *ngFor="let type of (productStore.items$ | async)" [value]="type.id">
										{{type.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>

					<mat-form-field class="w-100" *ngIf="itemForm.value?.selectedOrderRowType === OrderRowTypeEnum.PickupLocation">
						<mat-label>Hämtningsplats</mat-label>
						<mat-select [formControlName]="'pickupLocationId'">
							<mat-option *ngFor="let type of (pickupLocationStore.items$ | async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field class="w-100" *ngIf="itemForm.value?.selectedOrderRowType === OrderRowTypeEnum.Addon">
						<mat-label>Tillägg</mat-label>
						<mat-select [formControlName]="'addonId'">
							<mat-option *ngFor="let type of (addonStore.items$ | async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<div class="row" *ngIf="itemForm.value?.selectedOrderRowType === OrderRowTypeEnum.CustomerType">
						<div class="col-12 col-md-6">
							<mat-form-field class="w-100">
								<mat-label>Datum</mat-label>
								<input type="date" matInput formControlName="date" placeholder="Datum">
							</mat-form-field>
						</div>
						<div class="col-12 col-md-6">
							<mat-form-field class="w-100">
								<mat-label>Tid</mat-label>
								<input type="time" matInput formControlName="time" placeholder="Tid">
							</mat-form-field>
						</div>
					</div>

					<div class="row border-bottom mb-3 pb-2">
						<div class="col-12 col-md-6">
							<mat-form-field class="w-100">
								<mat-label>Kundtyp</mat-label>
								<mat-select [formControlName]="'customerTypeId'">
									<mat-option *ngFor="let type of (customerTypeStore.items$ | async)" [value]="type.id">
										{{type.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-12 col-md-6">
							<mat-form-field class="w-100">
								<mat-label>Antal</mat-label>
								<input type="number" matInput formControlName="quantity" placeholder="Antal">
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="col-12 col-md-6">
							<mat-form-field class="w-100">
								<mat-label>Enhet (pcs)</mat-label>
								<mat-select formControlName="unit">
									<mat-option *ngFor="let unit of units" [value]="unit">
										{{unit}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-12 col-md-6">
							<mat-form-field class="w-100">
								<mat-label>Enhetspris</mat-label>
								<input type="text" matInput formControlName="unitPrice" placeholder="Enhetspris">
							</mat-form-field>
						</div>
					</div>

					<!--<div class="row">
						<div class="col-12 col-md-6">
							<mat-form-field class="w-100">
								<mat-label>Skattsats</mat-label>
								<input type="text" matInput formControlName="taxRate" placeholder="Skattsats">
							</mat-form-field>
						</div>
						<div class="col-12 col-md-6">
							<mat-form-field class="w-100">
								<mat-label>Skatt</mat-label>
								<input type="text" matInput formControlName="taxAmount" placeholder="Skatt">
							</mat-form-field>
						</div>
					</div>-->

					<div class="row">
						<div class="col-12 col-md-4">
							<mat-form-field class="w-100">
								<mat-label>Netto pris</mat-label>
								<input type="text" matInput formControlName="netTotalAmount" placeholder="Netto pris">
							</mat-form-field>
						</div>
						<div class="col-12 col-md-4">
							<mat-form-field class="w-100">
								<mat-label>Totalt</mat-label>
								<input type="text" matInput formControlName="grossTotalAmount" placeholder="Totalt">
							</mat-form-field>
						</div>
						<div class="col-12 col-md-4">
							<mat-form-field class="w-100">
								<mat-label>Betalat</mat-label>
								<input type="number" matInput formControlName="totalPaid" placeholder="Betalat">
							</mat-form-field>
						</div>
					</div>

					<div class="row">
						<div class="col-12 col-md-6">
						</div>
						<div class="col-12 col-md-6">
						</div>
					</div>
				</form>

				<p *ngIf="item.sourceTypeId">Källa: {{item.sourceTypeId | sourceTypeName | async}}</p>
				<p *ngIf="item.created">Skapad: {{item.created | date:'yyyy-MM-dd HH:mm'}}</p>
				<p *ngIf="item.createdBy > 0">Skapad av: {{item.createdBy | adminUserName | async}}</p>
				<p *ngIf="item.modified">Modifierad: {{item.modified | date:'yyyy-MM-dd HH:mm'}}</p>
				<p *ngIf="item.modifiedBy > 0">Modifierad av: {{item.modifiedBy | adminUserName | async}}</p>

				<button class="d-block w-100 mb-2" color="accent" *ngIf="item.orderId" mat-stroked-button (click)="openOrder()">Visa order</button>
				<button class="d-block w-100 mb-2" color="accent" *ngIf="item.productId" mat-stroked-button (click)="openProduct()">Visa produkt</button>
				<button class="d-block w-100 mb-2" color="accent" *ngIf="item.addonId" mat-stroked-button (click)="openAddon()">Visa tillägg</button>
				<button class="d-block w-100 mb-4" color="accent" *ngIf="item.pickupLocationId" mat-stroked-button (click)="openPickupLocation()">Visa hämtningsplats</button>
			</mat-card-content>
			<app-blade-footer>
				<button color="primary" *ngIf="itemForm.get('grossTotalAmount').value > itemForm.get('totalPaid').value" mat-stroked-button (click)="openPayment()">Skapa en betalning</button>
				<span fxFlex></span>
				<button mat-raised-button color="primary" (click)="save()" [disabled]="!itemForm.valid">
					<span *ngIf="!item?.id">Skapa</span>
					<span *ngIf="item?.id">Spara</span>
				</button>
			</app-blade-footer>
		</mat-card>
	</app-blade-content>
</app-blade-item>
