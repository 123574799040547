import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormElement } from '../../../../../../models/form-element.model';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { FormBuilderService } from './services/form-builder.service';

@UntilDestroy()
@Component({
	selector: 'app-form-builder',
	templateUrl: './form-builder.component.html',
	styleUrls: ['./form-builder.component.scss'],
	providers: [FormBuilderService],
})
export class FormBuilderComponent implements OnInit {

	@Input() set setFormElemet(formElement: FormElement) {
		this.formBuilderService.setFormElement(formElement);
	}

	constructor(public formBuilderService: FormBuilderService) { }

	ngOnInit() {
	}
}
