<div class="d-flex justify-content-between">
	<h3>Kundtyper</h3>
	<div>
		<button mat-icon-button (click)="accordion.openAll()">
			<mat-icon>unfold_more</mat-icon>
		</button>
		<button mat-icon-button (click)="accordion.closeAll()">
			<mat-icon>unfold_less</mat-icon>
		</button>
	</div>
</div>
<mat-accordion multi>
	<mat-expansion-panel *ngFor="let customerType of tourBookingCustomerTypesReplay$ | async | keyvalue">
		<mat-expansion-panel-header>
			<mat-panel-title>
				{{+customerType.key | customerTypeName| async}}
			</mat-panel-title>
			<mat-panel-description>

			</mat-panel-description>
		</mat-expansion-panel-header>
		<mat-card class="mat-elevation-z3">
			<mat-table [dataSource]="customerType.value" class="w-100">
				<ng-container matColumnDef="id">
					<mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
					<mat-cell *matCellDef="let item">{{item.id}}</mat-cell>
				</ng-container>
				<ng-container matColumnDef="quantity">
					<mat-header-cell *matHeaderCellDef>Antal</mat-header-cell>
					<mat-cell *matCellDef="let item" class="d-block">
						<p class="m-0">{{item.quantity}}</p>
						<span *ngFor="let age of item.tourBookingCustomerTypeAges; let last = last; let i = index;">
							{{item.customerTypeId | customerTypeName | async}} {{i + 1}}: {{age.birthDate | date:'yyyy-MM-dd'}} ({{moment().diff(age.birthDate, 'years', false)}}år)
							<span *ngIf="!last">, </span>
						</span>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="created">
					<mat-header-cell *matHeaderCellDef>Skapad</mat-header-cell>
					<mat-cell *matCellDef="let item">{{item.created|date:'yyyy-MM-dd HH:mm'}}</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" [class.selected]="item && bladeItem?.child?.id === item?.id"></mat-row>
			</mat-table>
		</mat-card>
	</mat-expansion-panel>
</mat-accordion>
