<mat-card class="mt-4 p-0" *ngIf="{
	specialDietOnTour: specialDietOnTour$ | async
} as source">
	<h2 class="p-3">Lista på kunder</h2>
	<mat-slide-toggle class="p-3" [(ngModel)]="showMessages">Visa meddelanden</mat-slide-toggle>
	<table mat-table [dataSource]="source.specialDietOnTour?.customers" class="w-100">
		<ng-container matColumnDef="customer">
			<th mat-header-cell *matHeaderCellDef>Kund</th>
			<td mat-cell *matCellDef="let item">
				{{item.customerType.name}} {{item.index}}
			</td>
		</ng-container>
		<ng-container matColumnDef="specialDiet-{{specialDietId}}" *ngFor="let specialDietId of source.specialDietOnTour?.specialDietIds">
			<th mat-header-cell *matHeaderCellDef class="special-diet-header">
				{{specialDietId | specialDietName | async}}
			</th>
			<td mat-cell *matCellDef="let item">
				<div class="d-flex align-items-center">
					<span class="d-block"><mat-icon *ngIf="item.specialDiets[specialDietId]">done</mat-icon></span>
					<div class="ml-2" *ngIf="showMessages">
						<p class="m-0" [class.border-top]="!first" *ngFor="let message of item.specialDiets[specialDietId]?.messages; let first = first;" [innerHtml]="message | newLine"></p>
					</div>
				</div>
			</td>
		</ng-container>
		<ng-container matColumnDef="tourName">
			<th mat-header-cell *matHeaderCellDef>Turer</th>
			<td mat-cell *matCellDef="let item">
				<p class="m-0 border-top" *ngFor="let tour of item.tours">
					{{tour.id | tourName | async}}<br>{{tour.date | date:'yyyy-MM-dd'}} {{tour.time}}
				</p>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
	</table>
</mat-card>