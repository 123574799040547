
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Element } from 'app/models/element.model';
import { BaseStore } from './base.store.service';
import { ElementClass } from 'app/models/element-class.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class ElementClassStore extends BaseStore<ElementClass> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'ElementClasses', baseUrl, snackBarService);
	}

	public getAllOnElement(elementId: number): Observable<ElementClass[]> {
		return this.http.get<ElementClass[]>(`${this.host}/element/${elementId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}

	public addDelete(categoryId: number, elementClasses: ElementClass[], deleteIds: number[]): Observable<void> {
		const addDeleteObj = {
			elementClasses,
			deleteIds,
		};
		return this.http.post<void>(`${this.host}/addDelete/${categoryId}`, addDeleteObj);
	}
}
