<mat-card>
	<mat-card-title>Tur-Bokningar</mat-card-title>
	<mat-card-content>
		<table mat-table [dataSource]="dataSource" matSort class="w-100">
			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
				<td mat-cell *matCellDef="let item">{{item.id}} </td>
			</ng-container>
			<ng-container matColumnDef="customer">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Kund</th>
				<td mat-cell *matCellDef="let item">{{item.customerId | customerName | async}}</td>
			</ng-container>
			<ng-container matColumnDef="tour">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Tur</th>
				<td mat-cell *matCellDef="let item">{{item.tourId | tourName | async}}</td>
			</ng-container>
			<ng-container matColumnDef="source">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Källa</th>
				<td mat-cell *matCellDef="let item">{{item.sourceTypeId | sourceTypeName | async}}</td>
			</ng-container>
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
				<td mat-cell *matCellDef="let item">{{item.bookingStatusId | bookingStatusName | async}}</td>
			</ng-container>
			<ng-container matColumnDef="created">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
				<td mat-cell *matCellDef="let item">{{item.created | date:'yyyy-MM-dd HH:mm'}}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)"
				[class.selected]="item && bladeItem?.child?.id === item?.id"></tr>
		</table>

		<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	</mat-card-content>
</mat-card>