import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class AdminDataService {
	private selectedLanguageName = 'selectedLanguage';

	private avaiableLanguages: AdminLanguage[] = [
		{
			id: 1,
			prefix: 'sv',
			name: 'Svenska',
		},
		{
			id: 2,
			prefix: 'en',
			name: 'Engelska',
		},
	];
	private avaiableLanguagesReplay = new ReplaySubject<AdminLanguage[]>(1);
	public avaiableLanguages$ = this.avaiableLanguagesReplay.asObservable();

	private selectedLanguage = 'sv';
	private selectedLanguageReplay = new ReplaySubject<string>(1);
	public selectedLanguage$ = this.selectedLanguageReplay.asObservable();
	constructor() {
		this.selectedLanguage = localStorage.getItem(this.selectedLanguageName) ?? this.selectedLanguage;
		this.selectLanguage(this.avaiableLanguages.find(lang => lang.prefix === this.selectedLanguage) ?? this.avaiableLanguages[0]);
	}

	public selectLanguage(adminLanguage: AdminLanguage) {
		this.selectedLanguage = adminLanguage.prefix;
		localStorage.setItem(this.selectedLanguageName, this.selectedLanguage);
		this.selectedLanguageReplay.next(this.selectedLanguage);
		this.avaiableLanguages.forEach(lang => lang.isSelected = false);
		adminLanguage.isSelected = true;
		this.avaiableLanguagesReplay.next(this.avaiableLanguages);
	}
}

interface AdminLanguage {
	id: number;
	prefix: string;
	name: string;
	isSelected?: boolean;
}
