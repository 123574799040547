<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem?.id">
	<app-blade-header [title]="'Bild'" [subTitle]="'Bildspel'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content>
		<mat-card>
			<div class="mat-elevation-z1">
				<table mat-table [dataSource]="dataSource" matSort class="w-100">
					<ng-container matColumnDef="select">
						<th mat-header-cell *matHeaderCellDef>
							Välj
						</th>
						<td mat-cell *matCellDef="let item">
							<mat-checkbox (click)="$event.stopPropagation()"
											(change)="$event ? selectCategory(item) : null"
											[checked]="selection?.isSelected(item)">
							</mat-checkbox>
						</td>
					</ng-container>
					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
						<td mat-cell *matCellDef="let item">{{item.id}} </td>
					</ng-container>
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
						<td mat-cell *matCellDef="let item"><strong>{{item.name}}</strong><br /><em>{{item.identifier}}</em></td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectCategory(item)" [class.selected]="bladeItem?.child?.id === item?.id"></tr>
				</table>

				<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
			</div>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" [disabled]="!selection?.selected?.length" (click)="save()">
			<span *ngIf="activeItem?.id">Spara</span>
			<span *ngIf="!activeItem?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
