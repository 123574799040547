import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { AbstractControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-text-editor',
	templateUrl: './ckEditor.component.html',
	styleUrls: ['./ckEditor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CKEditorComponent implements OnChanges {
	private isInit = false;
	public valueSubject$ = new ReplaySubject<string>(1);
	public isValidSubject$ = new ReplaySubject<boolean>();
	public isDoneSubject$ = new ReplaySubject<boolean>();

	@Input() label: string;
	@Input() textModel: AbstractControl;

	/**
	 * 'small' | 'medium' | 'large'
	 */
	@Input() size: 'small' | 'medium' | 'large';
	@Output() changed = new EventEmitter<ChangeEvent>();
	public Editor = ClassicEditor;

	/**
	 * Avalible toolbars: console.log(ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName ));
	 * ["Essentials", "CKFinderUploadAdapter", "Autoformat", "Bold", "Italic", "BlockQuote", "CKFinder",
	 *  "EasyImage", "Heading", "Image", "ImageCaption", "ImageStyle", "ImageToolbar", "ImageUpload",
	 * 	"Link", "List", "MediaEmbed", "Paragraph", "PasteFromOffice", "Table", "TableToolbar"]
	 *
	 * 		toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo', 'list' ],
	 */
	public config = {
		toolbar: {
			items: [ 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList', 'link' ],

			viewportTopOffset: 30,

			shouldNotGroupWhenFull: true,
		},
	};

	constructor() {	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.isInit && this.textModel) {
			this.isInit = true;

			this.valueSubject$.next(this.textModel.value);
			this.isValidSubject$.next(this.textModel.valid);

			this.textModel.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
				this.valueSubject$.next(value);
				this.isValidSubject$.next(this.textModel.valid);
			});

			setTimeout(() => {
				this.isDoneSubject$.next(true);
			}, 1000);
		}
	}

	public onChange(change: ChangeEvent) {
		const data = change.editor.getData();
		this.textModel.setValue(data);
		this.textModel.markAsDirty();
		this.changed.emit(change);
	}
}
