import { Component, Inject, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SiteDataBladeComponent } from 'app/admin/blades/site-data/site-data-blade/site-data-blade.component';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { SiteDataStore } from 'app/admin/stores/site-data.store.service';
import { Language } from 'app/models/language.model';
import { SiteData } from 'app/models/site-data.model';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-settings-site-data',
	templateUrl: './settings-site-data.component.html',
	styleUrls: ['./settings-site-data.component.scss'],
})
export class SettingsSiteDataComponent {
	private siteDatas: SiteData[] = [];
	private languages: Language[];

	public displayedColumns: string[] = ['id', 'language', 'created', 'modified'];
	public dataSource: MatTableDataSource<SiteData>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public siteDataStore: SiteDataStore,
		private bladeService: BladeService,
		public languageStore: LanguageStore,
	) {
		this.siteDataStore.getAll();

		combineLatest([
			this.siteDataStore.items$,
			this.languageStore.items$,
		]).pipe(untilDestroyed(this)).subscribe(([siteDatas, languages]) => {
			this.siteDatas = siteDatas;
			this.languages = languages;
			this.handleCategory();
		});
	}

	private handleCategory() {
		if (this.siteDatas && this.languages) {
			// Every language must have a customerTypeText
			_.each(this.languages, language => {
				const siteDatas = _.filter(this.siteDatas, text => text.languageId === language.id);
				let sitedata;
				if (siteDatas && siteDatas.length > 1) {
					sitedata = _.find(this.siteDatas, text => text.languageId === language.id && text.id > 0);
					this.siteDataStore.removeSiteData(_.find(this.siteDatas, text => text.languageId === language.id && !text.id));
				} else if (siteDatas && siteDatas.length) {
					sitedata = siteDatas[0];
				}

				if (!sitedata) {
					sitedata = new SiteData();
					sitedata.languageId = language.id;
					this.siteDataStore.addSiteData(sitedata);
				}
				sitedata.language = language.name;
			});
			this.dataSource = new MatTableDataSource<SiteData>(this.siteDatas);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}


	public selectItem(row: SiteData) {
		this.bladeService.addBladeItem({
			id: 0,
			component: SiteDataBladeComponent,
			minClass: BladeSize.m,
			payload: {
				customerTypeId: this.bladeItem.id,
				languageId: row.languageId,
			},
		}, this.bladeItem);
	}

}
