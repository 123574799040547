<app-blade-item [canIClose]="true" [loading]="loading" [id]="item?.id">
    <app-blade-header [title]="item?.name||'Ny'" [subTitle]="'Kolumn'" [menuItems]="headerMenuItems"></app-blade-header>
	<!-- Edit column content -->
    <app-blade-content *ngIf="item?.id" [menuItems]="menuItems" (indexChange)="selectedIndex = $event">
        <ng-container [ngSwitch]="selectedIndex">
            <ng-container *ngSwitchCase="1">
				<app-column-blade-generally [item]="item"></app-column-blade-generally>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                <app-column-blade-elements></app-column-blade-elements>
			</ng-container>
            <ng-container *ngSwitchCase="3">
                <app-column-blade-link></app-column-blade-link>
			</ng-container>
            <ng-container *ngSwitchCase="4">
                <app-column-blade-styles></app-column-blade-styles>
			</ng-container>
        </ng-container>
	</app-blade-content>
	<!-- New column content -->
	<app-blade-content *ngIf="!item?.id">
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="name" placeholder="Arbetsnamn">
			</mat-form-field>
		</form>
	</app-blade-content>
	<ng-container [ngSwitch]="selectedIndex">
		<app-blade-footer *ngSwitchCase="1">
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="save()">
				<span *ngIf="item?.id">Spara</span>
				<span *ngIf="!item?.id">Skapa</span>
			</button>
		</app-blade-footer>
		<app-blade-footer *ngSwitchCase="2">
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="addNewElement()">Lägg till ny</button>
		</app-blade-footer>
	</ng-container>
 </app-blade-item>
