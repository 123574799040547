<mat-card>
	<mat-card-title class="card-title-small">Tjänster</mat-card-title>
	<div *ngFor="let item of servicesReplay$ | async">
		<mat-checkbox  (click)="$event.stopPropagation()"
			(change)="$event ? selectService(item) : null"
			[checked]="selection?.isSelected(item)"
			[disabled]="(item.identifier === 'master' && !userService.isMaster()) || !userService.checkPermission(['admin'], 0)">
			{{item.name}} - {{item.description}}
		</mat-checkbox>
	</div>
</mat-card>
