import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { TourMessage } from 'app/models/tour-message.model';

@Injectable({
	providedIn: 'root',
})
export class TourMessageStore extends BaseStore<TourMessage> {
	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TourMessage', baseUrl, snackBarService);
	}

	public getAllOnTour(tourId: number): Observable<TourMessage[]> {
		return this.http.get<TourMessage[]>(`${this.host}/tour/${tourId}`).pipe(tap(tourMessages => {
			this._items = tourMessages;
			this._itemsSubject.next(this._items);
		}));
	}
}
