import { Injectable, InjectionToken, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { ReplaySubject, Subject } from 'rxjs';
import * as _ from 'lodash';

import { BladeItem } from './models/bladeItem.model';
import { IBladeComponent } from './interfaces/iBladeComponent.interface';
import { filter, take } from 'rxjs/operators';
import { BladeComponent } from './models/bladeComponent.model';
import { BladeQuery } from './models/bladeQuery.model';
import { PlatformLocation } from '@angular/common';
import { BladeSize } from './models/bladeSize.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export const BladeItemInjectToken = new InjectionToken<BladeItem>('BladeItem');

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class BladeService implements OnInit {
	private isInitoverride: boolean;
	private overrideBladeItems: BladeItem[];
	private navigationChanged = false;
	private activeQueryParams: string;
	private bladeItemQueryName = 'bladeItems';
	private firsRun = true;
	private isLoadingFromUrl = false;
	private bladeComponents: BladeComponent[] = [];
	private isInitDone = new ReplaySubject<boolean>(1);
	public isInitDone$ = this.isInitDone.asObservable();
	private bladeItems: BladeItem[];

	private bladeItemsReplaySubject = new ReplaySubject<BladeItem[]>(1);
	public bladeItems$ = this.bladeItemsReplaySubject.asObservable();

	private removeBladeItemReplaySubject = new Subject<BladeItem>();
	public removeBladeItem$ = this.removeBladeItemReplaySubject.asObservable();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: PlatformLocation,
	) {
		IBladeComponent.implementations$.pipe(untilDestroyed(this)).subscribe(bladeComponents => {
			this.bladeComponents = bladeComponents;
			this.handleComponentNames();
			router.events
				.pipe(filter(event => event instanceof NavigationEnd), untilDestroyed(this))
				.subscribe((event: NavigationEnd) => {

					this.isInitDone.next(false);
					this.isLoadingFromUrl = false;

					this.activatedRoute.queryParams.pipe(take(1)).pipe(untilDestroyed(this)).subscribe(params => {
						this.activeQueryParams = params[this.bladeItemQueryName];
						if (this.activeQueryParams && this.firsRun) {
							this.isLoadingFromUrl = true;
							this.loadFromUrl(false);
						} else if (this.activeQueryParams && this.navigationChanged) {
							this.navigationChanged = false;
							this.loadFromUrl(true);
						}
						if (this.firsRun) {
							this.firsRun = false;
						} else if (!this.activeQueryParams) {
							this.bladeItems = [];
							this.bladeItemsReplaySubject.next(this.bladeItems);
						}
						// Handle back navigation...
						this.isInitDone.next(true);
					});

				});
		});
		location.onPopState(() => {
			this.navigationChanged = true;
		});
		setTimeout(() => {
			// Try to fix that initOverride dont work until this is true.
			this.isInitDone.next(true);
		}, 2000);
	}

	ngOnInit(): void { }

	public removeBladeItem(bladeItem: BladeItem): Promise<boolean> {
		return bladeItem.bladeItemComponent.closeMe();
	}

	/**
	 * Internal use
	 */
	public deleteBladeItem(bladeItem: BladeItem) {
		const index = _.findIndex(this.bladeItems, bladeItem);
		if (index < 0) {
			console.error('BladeService cant find bladeItem in list');
		} else {
			bladeItem.isRemoving = true;
			if (bladeItem.parent) {
				bladeItem.parent.child = undefined;
			}
			setTimeout(() => {
				this.bladeItems.splice(index, 1);
				this.handleQueryParam();
				this.handleClasses();
				this.bladeItemsReplaySubject.next(this.bladeItems);
			}, 200);
		}

	}

	public addBladeItem(bladeItem: BladeItem, parentBladeItem: BladeItem) {
		if (!bladeItem) {
			console.error('No bladeItem is provided');
			return;
		}

		if (!bladeItem.component) {
			console.error('No blade component is provided on bladeItem');
			return;
		}

		if (parentBladeItem && parentBladeItem.child) {
			parentBladeItem.child.bladeItemComponent.closeMe().then(closed => {
				if (closed) {
					setTimeout(() => {
						this.doAddBladeItem(bladeItem, parentBladeItem);
					}, 200);
				}
			});
		} else {
			this.doAddBladeItem(bladeItem, parentBladeItem);
		}
	}


	private doAddBladeItem(bladeItem: BladeItem, parentBladeItem: BladeItem, handleQueryParam: boolean = true) {
		// this.listenToIdChange(bladeItem);

		if (!this.bladeItems) {
			this.bladeItems = [];
		}
		if (parentBladeItem) {
			bladeItem.parent = parentBladeItem;
			parentBladeItem.child = bladeItem;
		}
		this.bladeItems.push(bladeItem);
		if (handleQueryParam) {
			this.handleQueryParam();
			this.handleClasses();
		}
		setTimeout(() => {
			this.bladeItemsReplaySubject.next(this.bladeItems);
		});

		// setInterval(() => {
		// 	console.log('bladeItem.id', bladeItem.id);
		// }, 1000);
	}

	/**
	 * When call initOverride. It will not loade until isLoadingFromUrl is false.
	 * It will not be false under normal circumstances.
	 */
	public resetIsLoadingFromUrl() {
		this.isLoadingFromUrl = false;
	}

	public init(bladeItem: BladeItem) {
		if (!this.isInitoverride) {
			this.isInitDone.pipe(take(1), untilDestroyed(this)).subscribe(isDone => {
				if (isDone && !this.isLoadingFromUrl) {
					this.bladeItems = [];
					this.addBladeItem(bladeItem, null);
				}
			});
		} else {
			this.initOverride();
		}
	}

	public initOverride() {
		this.isInitoverride = false;
		this.isInitDone.pipe(take(1), untilDestroyed(this)).subscribe(isDone => {
			if (isDone && !this.isLoadingFromUrl) {
				this.bladeItems = [];
				_.each(this.overrideBladeItems, (bladeItem, index) => {
					let parent = null;
					if (index > 0) {
						parent = this.overrideBladeItems[index - 1];
					}
					this.addBladeItem(bladeItem, parent);
				});
			}
		});
	}

	public setInitOverride(bladeItems: BladeItem[]) {
		this.isInitoverride = true;
		this.overrideBladeItems = bladeItems;
	}

	private loadFromUrl(navigationChanged: boolean) {
		if (!navigationChanged) {
			this.bladeItems = [];
		}
		let queries = _.filter(this.activeQueryParams.split('§'), q => q !== '');
		if (queries.length < this.bladeItems.length) {
			this.bladeItems.pop();
		} else if (queries.length > this.bladeItems.length) {
			queries = _.takeRight(queries, queries.length - this.bladeItems.length);
			_.each(queries, item => {
				if (item) {
					const obj = this.fromUrl(item) as BladeQuery;
					const bladeComponent = _.find(this.bladeComponents, comp => comp.routeName === obj.n);
					if (bladeComponent) {
						this.doAddBladeItem(
							{
								id: obj.id,
								menuIndex: obj.mi,
								component: bladeComponent.component,
								minClass: obj.minc as BladeSize,
								maxClass: obj.maxc as BladeSize,
								payload: obj.p,
							},
							(this.bladeItems.length) ? this.bladeItems[this.bladeItems.length - 1] : null,
							false,
						);
					}
				}
			});
			this.handleClasses();
		}
	}

	public bladeItemsIsChanged() {
		this.handleQueryParam(true);
	}

	private handleQueryParam(replaceUrl: boolean = false) {
		const bladeItems = this.generateParams();
		if (bladeItems) {
			this.router.navigate([], { queryParams: { bladeItems }, replaceUrl });
		}
	}

	private generateParams() {
		let query = '';
		_.each(this.bladeItems, (item, index) => {
			const routerName = this.bladeComponentToRouterName(item);
			const bladeQuery = new BladeQuery(item, routerName);
			query += `${this.toUrl(bladeQuery)}${(index !== this.bladeItems.length - 1) ? '§' : ''}`;
		});
		return query;
	}

	private toUrl(obj: BladeQuery): string {
		let url = `id:${obj.id || 0}`;
		_.forOwn(obj, (value, key) => {
			if (key !== 'id' && key !== 'p' && value) {
				url += `,${key}:${value}`;
			} else if (key === 'p' && value) {
				url += ',p:(';
				let pIndex = 0;
				if (_.isObject(value)) {
					_.forOwn(value, (pValue, pKey) => {
						if (pIndex) {
							url += '|';
						}
						url += `${pKey}:${pValue}`;
						pIndex++;
					});
				} else {
					url += value;
				}
				url += ')';
			}
		});

		return url;
	}
	private fromUrl(obj: string): BladeQuery {
		const bladeQuery = {};
		const values = obj.split(',');
		_.each(values, value => {
			const property = value.split(':')[0];
			const propertyValue = value.split(':')[1];
			if (property !== 'p') {
				const parsed = parseInt(propertyValue, 10);
				if (isNaN(parsed)) {
					bladeQuery[property] = propertyValue;
				} else {
					bladeQuery[property] = parsed;
				}
			} else {
				bladeQuery['p'] = {};
				const payloadeValue = value.replace('p:(', '').replace(')', '').split('|');
				if (payloadeValue.length === 1 && payloadeValue[0].split(':').length === 1) {
					const val = payloadeValue[0].split(':')[0];
					bladeQuery['p'] = isNaN(val as any) ? val : parseInt(val, 10);
				} else {
					_.each(payloadeValue, pValue => {
						const pProperty = pValue.split(':')[0];
						let pPropertyValue = pValue.split(':')[1] as any;
						if (pPropertyValue) {
							pPropertyValue = isNaN(pPropertyValue) ? pPropertyValue : parseInt(pPropertyValue, 10);
							bladeQuery['p'][pProperty] = pPropertyValue;
						} else {
							bladeQuery['p'] = pProperty;
						}
					});
				}
			}
		});

		return bladeQuery;
	}

	private handleClasses() {
		_.each(this.bladeItems, (item, index) => {
			item.itemIndex = index;
			if (index === this.bladeItems.length - 1) {
				item.activeClass = item.maxClass || item.minClass;
			} else {
				item.activeClass = item.minClass;
			}
		});
	}

	private handleComponentNames() {
		_.each(this.bladeComponents, comp => {
			comp.routeName = this.bladeComponentToRouterName(comp);
		});
	}

	private bladeComponentToRouterName(comp: BladeComponent): string {
		const selector = comp.component['ɵcmp']?.selectors[0]?.[0];
		return selector?.replace('app-', '')?.replace('-blade', '');
	}
}
