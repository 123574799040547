import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FunctionTypeStore } from '../stores/function-type.store.service';

/**
 * Take min value
 * Example: *ngIf="(value | min:minValue) > 5"
 */
@Pipe({ name: 'functionTypeName' })
export class FunctionTypeNamePipe implements PipeTransform, OnDestroy {
	constructor(private functionTypeStore: FunctionTypeStore) { }

	public transform(functionTypeId: number): Observable<string> {
		if (!functionTypeId) {
			return;
		}
		return this.functionTypeStore.pipeGet(functionTypeId).pipe(map(type => type.name));
	}

	ngOnDestroy(): void { }
}
