import { Component, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import { combineLatest, ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';

import { TourBookingPickupLocationStore } from 'app/admin/stores/tour-booking-pickup-location.store.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { TourBooking } from 'app/models/tour-booking.model';
import { TourBookingPickupLocationSummary } from 'app/models/tour-booking-pickup-location-summary.model';
import { TourBookingBladeComponent } from 'app/admin/blades/tour-booking/tour-booking-blade/tour-booking-blade.component';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';

@UntilDestroy()
@Component({
	selector: 'app-tour-summary-pickup',
	templateUrl: './tour-summary-pickup.component.html',
	styleUrls: ['./tour-summary-pickup.component.scss'],
})
export class TourSummaryPickupComponent implements OnDestroy {
	@ViewChild(MatAccordion) accordion: MatAccordion;
	private tourBookings: TourBooking[];
	public displayedColumns: string[] = ['name', 'pickupLocation', 'customerType', 'quantity'];
	public pickupLocationWrappersReplay = new ReplaySubject<Dictionary<TourBookingPickupLocationSummary[]>>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		private tourBookingPickupLocationStore: TourBookingPickupLocationStore,
		public tourBookingStore: TourBookingStore,
	) {
		if (bladeItem.id) {
			this.tourBookingStore.getAllOnTour(bladeItem.id).pipe(untilDestroyed(this), switchMap(tourBookings => {
					this.tourBookings = tourBookings;
					const observables = [];
					_.each(tourBookings, tb => {
						observables.push(this.tourBookingPickupLocationStore.getAllOnTourBooking(tb.id));
					});
					return combineLatest(observables);
				})).subscribe(tourBookingPickupLocationList => {

					const tourBookingPickupLocations = _.flatten(tourBookingPickupLocationList) as TourBookingPickupLocationSummary[];
					_.each(tourBookingPickupLocations, tourBookingPickupLocation => {
						tourBookingPickupLocation.tourBooking = _.find(this.tourBookings, tBooking => tBooking.id === tourBookingPickupLocation.tourBookingId);
					});
					const tourBookingPickupLocationGroups = _.groupBy(tourBookingPickupLocations, pickup => pickup.pickupLocationId);
					this.pickupLocationWrappersReplay.next(tourBookingPickupLocationGroups);
				});
		}
	}

	ngOnDestroy() { }

	public selectItem(row: TourBookingPickupLocationSummary) {
		this.bladeService.addBladeItem({
			id: row.tourBookingId,
			component: TourBookingBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}
}
