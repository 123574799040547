import { Component, OnInit } from '@angular/core';

import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { MinFunctionService } from '../../services/min-function.service';

@Component({
	selector: 'app-min-function-blade-generally',
	templateUrl: './function-blade-generally.component.html',
	styleUrls: ['./function-blade-generally.component.scss'],
})
export class MinFunctionBladeGenerallyComponent implements OnInit {
	constructor(
		public productFunctionService: MinFunctionService,
		public customerTypeStore: CustomerTypeStore,
	) {
	}
	ngOnInit(): void {
	}
}
