

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from '../base.store.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { MinFunction } from 'app/models/product-function/min-function.model';

@Injectable({
	providedIn: 'root',
})
export class MinFunctionStore extends BaseStore<MinFunction> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'minFunction', baseUrl, snackBarService);
	}
}
