import { Component, Input } from '@angular/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { NgModel, FormControl, AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-alert-card',
	templateUrl: './alert-card.component.html',
	styleUrls: ['./alert-card.component.scss'],
})
export class AlertCardComponent {
	@Input() label: string;

	public hide = false;

	/**
	 * Can be 'success' | 'danger' | 'warning' | 'info'
	 */
	@Input() mode: 'success' | 'danger' | 'warning' | 'info';

	constructor() {}
}
