<mat-card>
	<mat-card-content>
		<mat-card-title>Ny order</mat-card-title>
		<mat-card-subtitle>
			När en order är flaggad med att mejl ska skickas så kommer template nedan användas. Viktigt att det finns en template för varje språk.
			Det är oxå viktigt att det finns en betalning på ordern innan mejlet kan skickas.
			Alla ordrar som är skapade från hemsidan kommer skickas så fort betalningen är slutförd.
			<br>
			Mejl skickas varje 2 minut.
		</mat-card-subtitle>
		<mat-card-content>
			<table mat-table [dataSource]="dataSource" matSort class="w-100">
				<ng-container matColumnDef="language">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Språk</th>
					<td mat-cell *matCellDef="let item">{{item.languageId | languageName | async}}</td>
				</ng-container>
				<ng-container matColumnDef="subject">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Ämne</th>
					<td mat-cell *matCellDef="let item">{{item.subject}}</td>
				</ng-container>
				<ng-container matColumnDef="modified">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Modifierad</th>
					<td mat-cell *matCellDef="let item">{{item.modified|date:'yyyy-MM-dd HH:mm'}}<br />{{item.modifiedBy}}</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let item; columns: displayedColumns;" class="pointer" (click)="selectItem(item)" [class.selected]="bladeItem?.child?.id === item?.id"></tr>
			</table>
			<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
		</mat-card-content>
		<mat-card-footer class="text-right">
			<button mat-button color="access" (click)="createNew()">Lägg till ny</button>
		</mat-card-footer>
	</mat-card-content>
</mat-card>
