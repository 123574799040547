import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import {
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { AddonText } from 'app/models/addon-text.model';
import { AddonTextStore } from 'app/admin/stores/addon-text.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-addon-text-blade',
	templateUrl: './addon-text-blade.component.html',
	styleUrls: ['./addon-text-blade.component.scss'],
})
@IBladeComponent.register
export class AddonTextBladeComponent implements OnInit {
	public loading = true;
	public item: AddonText;

	public itemForm: FormGroup = new FormGroup({
		title: new FormControl('', [Validators.required]),
		description: new FormControl('', []),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: AddonTextStore,
	) {
		if (
			bladeItem.payload &&
			bladeItem.payload.addonId &&
			bladeItem.payload.languageId
		) {
			itemStore
				.getOnAddonAndLanguage(
					+bladeItem.payload.addonId,
					+bladeItem.payload.languageId,
				)
				.pipe(untilDestroyed(this))
				.subscribe(item => {
					this.item = item;
					if (!this.item) {
						this.item = new AddonText();
						this.item.addonId = +bladeItem.payload.addonId;
						this.item.languageId = +bladeItem.payload.languageId;
					}
					this.itemForm.setValue({
						title: this.item.title || '',
						description: this.item.description || '',
					});

					this.loading = false;
				});
		} else {
			console.error('Missing addonId and/or languageId');
		}
	}

	ngOnInit() {
		this.itemForm.get('title').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.title = value;
		});
		this.itemForm.get('description').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.description = value;
		});
	}

	public save() {
		this.loading = true;
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<AddonText> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

}
