import { Component, OnInit, Inject } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { AddonBladeService } from '../../service/addon-blade.service';

@Component({
  selector: 'app-addon-blade-generally',
  templateUrl: './addon-blade-generally.component.html',
  styleUrls: ['./addon-blade-generally.component.scss'],
})
export class AddonBladeGenerallyComponent implements OnInit {

  constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		public addonBladeService: AddonBladeService,
	) { }

  ngOnInit() {
  }
}
