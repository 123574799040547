<mat-card *ngIf="{
	latestMessage: latestMessage$ | async,
	adminText: 'blades.tour.tabs.messages' | adminText | async
} as source">
	<mat-card-content>
		<mat-card-title>{{source.adminText?.title}}</mat-card-title>
		<mat-card class="mb-4" *ngIf="source.latestMessage && !editMessage">
			<mat-card-subtitle>
				<span>{{source.latestMessage.createdBy | adminUserName | async}}</span>
				<button mat-icon-button color="primary" class="float-right" (click)="editMessage = true">
					<mat-icon>edit</mat-icon>
				</button>
			</mat-card-subtitle>
			<mat-card-subtitle>{{source.latestMessage.created | date:'yyyy-MM-dd HH:mm'}}</mat-card-subtitle>
			<mat-card-content>
				{{source.latestMessage.message}}
			</mat-card-content>
		</mat-card>
		<form [formGroup]="itemForm" *ngIf="!source.latestMessage || editMessage">
			<h3>
				<span *ngIf="source.latestMessage">{{source.adminText?.editMessage}}</span>
				<span *ngIf="!source.latestMessage">{{source.adminText?.createMessage}}</span>
				<button mat-icon-button color="primary" class="float-right" (click)="editMessage = false">
					<mat-icon>close</mat-icon>
				</button>
			</h3>
			<mat-form-field class="w-100" appearance="outline">
				<textarea matInput formControlName="message" placeholder="Meddelande"></textarea>
			</mat-form-field>
			<div>
				<span fxFlex></span>
				<button mat-raised-button color="primary" (click)="createMessage()" [disabled]="isCreating">
					<span *ngIf="source.latestMessage">{{source.adminText?.updateBtn}}</span>
					<span *ngIf="!source.latestMessage">{{source.adminText?.createBtn}}</span>
				</button>
			</div>
		</form>
	</mat-card-content>
</mat-card>
