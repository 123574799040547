import { Directive, OnInit, Input, ElementRef, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { UserService } from 'app/admin/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from 'app/admin/models/user.model';
import * as _ from 'lodash';

@UntilDestroy()
@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[hasRoles]',
})
export class HasRolesDirective implements OnInit, OnDestroy {
	private currentUser: User;
	private roles: string[] = [];
	private rolesIsSet: boolean;
	private level = 0;

	private isRendered: boolean;

	@Input() set hasRoles(val: string[] | string) {
		if (typeof val === 'string') {
			this.roles = [val];
		} else {
			this.roles = val;
		}
		this.rolesIsSet = true;
		this.updateView();
	}

	@Input() set hasRolesMinLevel(val: number) {
		this.level = val;
		this.updateView();
	}

	constructor(
	  private element: ElementRef,
	  private templateRef: TemplateRef<any>,
	  private viewContainer: ViewContainerRef,
	  private userService: UserService,
	) {
	}

	ngOnInit() {
		this.userService.user$.pipe(untilDestroyed(this)).subscribe(user => {
			this.currentUser = user;
			this.updateView();
		});
	}

	ngOnDestroy(): void { }

	private updateView() {
		if (this.userService.checkPermission(this.roles, this.level)) {
			if (this.isRendered) {
				this.viewContainer.clear();
			} else {
				this.isRendered = true;
			}
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}


}
