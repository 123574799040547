import { Component, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import {
	FormGroup,
	FormControl,
} from '@angular/forms';
import { ProductPickupLocation } from 'app/models/product/product-pickup-location.model';
import { ProductPickupLocationStore } from 'app/admin/stores/product/product-pickup-location.store.service';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { ProductPickupLocationPrice } from 'app/models/product/product-pickup-location-price.model';
import { PickupLocationPriceStore } from 'app/admin/stores/pickup-location-price.store.service';
import { ProductPickupLocationPriceStore } from 'app/admin/stores/product/product-pickup-location-price.store.service';
import { ReplaySubject } from 'rxjs';
import { PickupLocationPrice } from 'app/models/page-location-price.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-product-pickup-locations-blade',
	templateUrl: './product-pickup-location-blade.component.html',
	styleUrls: ['./product-pickup-location-blade.component.scss'],
})
@IBladeComponent.register
export class ProductPickupLocationBladeComponent {
	public loading = true;
	public item: ProductPickupLocation;
	public pickupLocationPricesReplay$ = new ReplaySubject<PickupLocationPrice[]>(1);
	public productPickupLocationPricesReplay$ = new ReplaySubject<ProductPickupLocationPrice[]>(1);

	public itemForm: FormGroup = new FormGroup({
		relativeTime: new FormControl(null),
	});

	public saveReplay$ = new Subject<boolean>();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: ProductPickupLocationStore,
		public customerTypeStore: CustomerTypeStore,
		public pickupLocationPriceStore: PickupLocationPriceStore,
		public productPickupLocationPriceStore: ProductPickupLocationPriceStore,
	) {
		if (!bladeItem.payload?.productId) {
			console.error('Missing productId');
			return;
		}

		if (bladeItem.id) {
			this.itemStore
				.get(bladeItem.id)
				.subscribe(item => {
					this.item = item;
					this.pickupLocationPriceStore.getAllOnPickupLocation$(item.pickupLocationId)
						.pipe(untilDestroyed(this)).subscribe(x => this.pickupLocationPricesReplay$.next(x));
					this.productPickupLocationPriceStore.getAllOnProductPickupLocation$(item.id)
						.pipe(untilDestroyed(this)).subscribe(x => this.productPickupLocationPricesReplay$.next(x));

					if (!this.item) {
						this.item = new ProductPickupLocation();
						this.item.productId = +bladeItem.payload.productId;
					}

					this.itemForm.patchValue(this.item);
					this.loading = false;
				});
		} else {
			this.item = new ProductPickupLocation();
			this.item.productId = +bladeItem.payload.productId;
			this.loading = false;
		}
	}

	public save() {
		this.loading = true;
		this.item = Object.assign(this.item, this.itemForm.value);
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
		this.saveReplay$.next();
	}
	private saveOrUpdate(): Observable<ProductPickupLocation> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

}
