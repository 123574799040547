<form [formGroup]="itemForm">
	<mat-form-field class="w-100">
		<input matInput formControlName="headline" placeholder="Rubrik">
	</mat-form-field>
	<mat-form-field class="w-100">
		<textarea matInput formControlName="information" placeholder="Mindra beskrivning, ex till listor"></textarea>
	</mat-form-field>
	<mat-form-field class="w-100">
		<input matInput formControlName="description" placeholder="Beskrivning">
	</mat-form-field>
</form>

