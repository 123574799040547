import { BaseModel } from './base.model';
export class Customer extends BaseModel {
	public firstName: string;
	public lastName: string;
	public email: string;
	public phone: string;
	public sourceTypeId: number;
	public languageId: number;
	public travelAgencyId: number;
}
