<app-blade-item [canIClose]="true" [loading]="loading" [id]="item?.id">
    <app-blade-header [title]="item?.userId | adminUserName | async" [subTitle]="'Välj guide'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Guide</mat-label>
				<input type="text"
					placeholder="Välj guide"
					matInput
					formControlName="guide"
					[matAutocomplete]="guide">
				<mat-autocomplete #guide="matAutocomplete" [displayWith]="displayGuideFn.bind(this)">
					<mat-option *ngFor="let user of (filterGuides$ | async)" [value]="user.id">
						{{user.firstName}} {{user.lastName}} ({{user.email}})
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
			<mat-form-field class="w-100" appearance="outline">
				<mat-label>Guide typ</mat-label>
				<mat-select formControlName="guideType">
					<mat-option *ngFor="let user of (tourGuideTypeStore.items$ | async)" [value]="user.id">
						{{user.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="item?.id">Spara</span>
			<span *ngIf="!item?.id">Skapa</span>
		</button>
	</app-blade-footer>
 </app-blade-item>
