import { Injectable } from '@angular/core';
import { Tour } from 'app/models/tour.model';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import * as _ from 'lodash';
import { SupplierResource } from 'app/models/supplier-resource.model';
import { TourBookingStore } from '../stores/tour-booking.store.service';
import { SupplierResourceTourStore } from '../stores/supplier-resource-tour.store.service';
import { SupplierResourceStore } from '../stores/supplier-resource.store.service';
import { TourBookingCustomerTypeStore } from '../stores/tour-booking-customer-type.store.service';
import { SupplierResourceProductStore } from '../stores/supplier-resource-product.store.service';
import { SupplierResourceProductTypeStore } from '../stores/supplier-resource-product-type.store.service';
import { ResourceSummary } from '../models/resource-summary';
import { SupplierResourceProduct } from 'app/models/supplier-resource-product.model';
import { SupplierResourceTour } from 'app/models/supplier-resource-tour.model';

@Injectable({
  providedIn: 'root',
})
export class SupplierResourceService {
	constructor(
		private tourBookingStore: TourBookingStore,
		private supplierResourceTourStore: SupplierResourceTourStore,
		private supplierResourceStore: SupplierResourceStore,
		private tourBookingCustomerTypeStore: TourBookingCustomerTypeStore,
		private supplierResourceProductStore: SupplierResourceProductStore,
		private supplierResourceProductTypeStore: SupplierResourceProductTypeStore,
	) { }

	public calculateResourceOnTour(tour: Tour): Observable<ResourceSummary> {
		return combineLatest([
			this.tourBookingStore.getAllOnTour(tour.id).pipe(switchMap(tourBookings => {
				const observables: Observable<TourBookingCustomerType[]>[] = [];
				_.each(tourBookings, tb => {
					observables.push(this.tourBookingCustomerTypeStore.getAllOnTourBooking(tb.id));
				});
				if (!observables.length) {
					return of([]);
				}
				return combineLatest(observables);
			})),
			this.supplierResourceTourStore.items$,
			this.supplierResourceProductStore.getAllOnProduct(tour.productId).pipe(switchMap(supplierResourceProducts => {
				const observables: Observable<SupplierResource>[] = [];
				_.each(supplierResourceProducts, tb => {
					observables.push(this.supplierResourceStore.get(tb.supplierResourceId));
				});

				return combineLatest([of(supplierResourceProducts), combineLatest(observables)]);
			})),
			this.supplierResourceProductTypeStore.items$,
		])
		.pipe(
			switchMap((
				[tourBookingCustomerTypeList, supplierResourceTours, [supplierResourceProducts, supplierResources], supplierResourceProductTypes],
			) => {
				console.log('--------------',
					tourBookingCustomerTypeList, supplierResourceTours, supplierResourceProducts, supplierResources, supplierResourceProductTypes);
				const calculatedResource = new ResourceSummary();

				const tourBookingCustomerTypes = _.flatten(tourBookingCustomerTypeList);
				const sumCustomers = _.sumBy(tourBookingCustomerTypes, booking => booking.quantity);
				if (supplierResourceProducts && supplierResourceProducts.length) {
					// We have some supplierResourceProducts
					supplierResourceProducts.forEach(supplierResourceProduct => {
						let amount = 0;
						const supplierResourceProductType = _.find(
							supplierResourceProductTypes, productType => productType.id === supplierResourceProduct.supplierResourceProductTypeId);
						if (supplierResourceProductType.identifier === 'customer') {
							amount = Math.ceil(sumCustomers / supplierResourceProduct.course);
						} else if (supplierResourceProductType.identifier === 'tour') {
							amount = supplierResourceProduct.course;
						}
						this.addSummaryResource(
							calculatedResource,
							supplierResourceProduct,
							_.find(supplierResources, type => type.id === supplierResourceProduct.supplierResourceId).supplierResourceTypeId,
							amount,
						);
					});
				}
				if (supplierResourceTours && supplierResourceTours.length) {
					// We have some supplierResourceTours
					supplierResourceTours.forEach(supplierResourceTour => {
						this.addSummaryResource(calculatedResource, supplierResourceTour, supplierResourceTour.supplierResourceTypeId, supplierResourceTour.amount);
					});
				}
				return of(calculatedResource);
			}),
		);

	}

	private addSummaryResource(
		calculatedResource: ResourceSummary,
		source: SupplierResourceProduct | SupplierResourceTour,
		supplierResourceTypeId: number, amount: number,
	): void {
		if (!calculatedResource.items) {
			calculatedResource.items = [];
		}

		calculatedResource.items.push({
			source,
			supplierResourceTypeId,
			amount,
		});

		const summary = _.find(calculatedResource.summary, sum => sum.supplierResourceTypeId === supplierResourceTypeId);
		if (summary) {
			summary.amount += amount;
		} else {
			if (!calculatedResource.summary) {
				calculatedResource.summary = [];
			}

			calculatedResource.summary.push({
				supplierResourceTypeId,
				amount,
			});
		}
	}
}

