import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { PickupLocationStore } from 'app/admin/stores/pickup-location.store.service';
import { TourBookingPickupLocation } from 'app/models/tour-booking-pickup-location.model';
import { TourBookingPickupLocationService } from '../../services/tour-booking-pickup-location.service';
import { TourBookingPickupLocationItemService } from './services/tour-booking-pickup-location-item.service';

@UntilDestroy()
@Component({
  	selector: 'app-tour-booking-pickup-location-item',
  	templateUrl: './tour-booking-pickup-location-item.component.html',
  	styleUrls: ['./tour-booking-pickup-location-item.component.scss'],
	providers: [TourBookingPickupLocationItemService],
  	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourBookingPickupLocationItemComponent implements OnInit {
	@Input() set tourBookingPickupLocation(tourBookingPickupLocation: TourBookingPickupLocation) {
		this.tourBookingPickupLocationItemService.setTourBookingPickupLocationItem(tourBookingPickupLocation);
	}

  	constructor(
		public customerTypeStore: CustomerTypeStore,
		public tourBookingPickupLocationItemService: TourBookingPickupLocationItemService,
		public tourBookingPickupLocationService: TourBookingPickupLocationService,
		public pickupLocationStore: PickupLocationStore,
	) { }

  	ngOnInit() {
  	}

}
