import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';

import { Order } from 'app/models/order.model';
import { OrderBladeComponent } from '../order-blade/order-blade.component';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { FormGroup, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { CreataOrderTourBookingBladeComponent } from '../create-order-tour-booking-blade/create-order-tour-booking-blade.component';
import { CreateOrderModeEnum } from 'app/enums/create-order-mode.enum';

@UntilDestroy()
@Component({
	selector: 'app-orders-blade',
	templateUrl: './orders-blade.component.html',
	styleUrls: ['./orders-blade.component.scss'],
})
@IBladeComponent.register
export class OrdersBladeComponent implements OnInit, OnDestroy {
	public loading = false;
	public displayedColumns: string[] = ['id', 'customerId', 'sourceTypeId', 'payments', 'orderStatusId', 'created', 'isDraft'];
	public dataSource: MatTableDataSource<Order>;

	public dateList = [];

	public formGroup: FormGroup = new FormGroup({
		search: new FormControl(null),
	});
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: OrderStore,
	) {
		this.getOrders();

		this.itemStore.reset();
		this.bladeItem.callback = () => {
			this.getOrders();
		};
	}

	ngOnInit(): void { }

	ngOnDestroy(): void { }
	public selectItem(row) {
		this.bladeService.addBladeItem({
			id: row.id || row.orderId,
			component: OrderBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: this.bladeItem.id,
			component: CreataOrderTourBookingBladeComponent,
			minClass: BladeSize.m,
			payload: { mode: CreateOrderModeEnum.newOrder },
		}, this.bladeItem);
	}

	public getOrders() {
		this.loading = true;
		this.itemStore.getAll$().pipe(untilDestroyed(this)).subscribe(orders => this.setData(orders));
	}

	public getDrafts() {
		this.loading = true;
		this.itemStore.getAllDraft().pipe(untilDestroyed(this)).subscribe(orders => this.setData(orders));
	}
	public getRest() {
		this.loading = true;
		const fromDate = moment().add(-10, 'days').format('YYYY-MM-DD');
		const toDate = moment().add(3, 'months').format('YYYY-MM-DD');

		this.itemStore.getAllWithBadBalance(fromDate, toDate).pipe(untilDestroyed(this)).subscribe(orders => this.setData(orders));
	}

	public searchByIdOrReference() {
		if (!this.formGroup.value.search) {
			return;
		}
		this.loading = true;
		this.itemStore.searchByIdOrReference(this.formGroup.value.search).pipe(untilDestroyed(this)).subscribe(orders => {
			this.formGroup.get('search').setValue('');
			this.dataSource = new MatTableDataSource<Order>(orders);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.loading = false;
		});
	}

	private setData(orders: any[]) {
		this.formGroup.get('search').setValue('');
		this.dataSource = new MatTableDataSource<Order>(_.orderBy(orders, 'created', 'desc'));
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.loading = false;
	}
}
