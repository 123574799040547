<mat-toolbar [color]="bladeItem.isSelected ? 'primary' : ''">
	<div class="mat-card-header-text d-flex w-100">
		<div class="title-wrapper flex-grow-1">
			<mat-card-title *ngIf="mainTitle">{{mainTitle}}</mat-card-title>
			<mat-card-subtitle *ngIf="subTitle">{{subTitle}}</mat-card-subtitle>
		</div>
		<ng-content></ng-content>
		<span class="header-id" *ngIf="bladeItem.id">{{bladeItem.id}}</span>
		<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Toggle menu" *ngIf="menuItems?.length">
			<mat-icon>more_vert</mat-icon>
		</button>
		<button mat-icon-button *ngIf="menuItems?.length || bladeItem.itemIndex !== 0" (click)="closeMe()">
			<mat-icon>close</mat-icon>
		</button>
		<mat-menu #menu="matMenu" xPosition="before">
			<button mat-menu-item *ngFor="let item of menuItems" (click)="item.func()">
				<mat-icon>{{item.icon}}</mat-icon>
				<span>{{item.title}}</span>
			</button>
		</mat-menu>
	</div>
</mat-toolbar>
