import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { Language } from 'app/models/language.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SpecialDietText } from 'app/models/special-diet-text.model';
import { SpecialDietTextStore } from 'app/admin/stores/special-diet-text.store.service';
import { SpecialDietTextBladeComponent } from 'app/admin/blades/special-diet-text/special-diet-text-blade/special-diet-text-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-special-diet-blade-texts',
	templateUrl: './special-diet-blade-texts.component.html',
	styleUrls: ['./special-diet-blade-texts.component.scss'],
})
export class SpecialDietBladeTextsComponent implements OnInit, OnDestroy {
	public languages: Language[];
	public specialDietTexts: SpecialDietText[];
	public displayedColumns: string[] = ['id', 'title', 'language'];
	public dataSource: MatTableDataSource<SpecialDietText>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public languageStore: LanguageStore,
		private specialDietTextStore: SpecialDietTextStore,
	) {
		this.specialDietTextStore.reset();
	}

	ngOnInit() {
		this.specialDietTextStore.items$.pipe(untilDestroyed(this)).subscribe(specialDietTexts => {
			this.specialDietTexts = specialDietTexts;
			this.handleSpecialDiet();
		});
		this.specialDietTextStore.getAllOnSpecialDiet(this.bladeItem.id).subscribe(specialDietTexts => {
			this.specialDietTexts = specialDietTexts;
			this.handleSpecialDiet();
		});
		this.languageStore.items$.pipe(untilDestroyed(this)).subscribe(languages => {
			this.languages = languages;
			this.handleSpecialDiet();
		});
	}

	ngOnDestroy(): void { }

	private handleSpecialDiet() {
		if (this.specialDietTexts && this.languages) {
			// Every language must have a specialDietText
			_.each(this.languages, language => {
				const specialDietTexts = _.filter(this.specialDietTexts, text => text.languageId === language.id);
				let specialDietText;
				if (specialDietTexts && specialDietTexts.length > 1) {
					specialDietText = _.find(this.specialDietTexts, text => text.languageId === language.id && text.id > 0);
					this.specialDietTextStore.removeSpecialDietText(_.find(this.specialDietTexts, text => text.languageId === language.id && !text.id));
				} else if (specialDietTexts && specialDietTexts.length) {
					specialDietText = specialDietTexts[0];
				}

				if (!specialDietText) {
					specialDietText = new SpecialDietText();
					specialDietText.languageId = language.id;
					this.specialDietTextStore.addSpecialDietText(specialDietText);
				}
				specialDietText.language = language.name;
			});
			this.dataSource = new MatTableDataSource<SpecialDietText>(this.specialDietTexts);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}

	public selectItem(row: SpecialDietText) {
		this.bladeService.addBladeItem({
			id: 0,
			component: SpecialDietTextBladeComponent,
			minClass: BladeSize.m,
			payload: {
				specialDietId: this.bladeItem.id,
				languageId: row.languageId,
			},
		}, this.bladeItem);
	}
}
