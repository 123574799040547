import { Component } from '@angular/core';

import { AnalyticsService } from 'app/services/analytics.service';
import { AnalyticsStore } from './stores/analytics.service';
import { AnalyticsWidgetTourBookingStore } from './widgets/tour-booking/stores/tour-booking.store';

@Component({
  selector: 'app-analytics-page',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  providers: [
	AnalyticsStore,
	AnalyticsService,
  ],
})
export class AnalyticsPageComponent {
	constructor(public analyticsWidgetTourBookingStore: AnalyticsWidgetTourBookingStore) {
		this.analyticsWidgetTourBookingStore.getAll();
	}
}
