import { Component, Input } from '@angular/core';
import { MediaStore } from '../../stores/media.store.service';
import { Media } from 'app/models/media.model';

@Component({
	selector: 'app-video',
	templateUrl: './video-element.component.html',
	styleUrls: ['./video-element.component.scss'],
})
export class VideoElementComponent {
	public showVideo: boolean;
	public video: Media;
	@Input()
	set mediaId(id: number) {
		if (id) {
			this.showVideo = false;
			this.mediaStore.get(id).subscribe(media => {
				this.video = media;
				this.showVideo = true;
			});
		}
	}

	constructor(public mediaStore: MediaStore) {
	}
}
