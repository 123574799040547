<ng-container *ngIf="{
	adminText: 'sideNav' | adminText | async
} as source">
	<a mat-list-item
		[matTooltip]="source.adminText?.menu[item.adminTextPrefix]"
		matTooltipPosition="right"
		[matTooltipDisabled]="isExpanded"
		[ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
		(click)="onItemSelected(item)"
		[ngClass]="{
			active: item.route ? router.isActive(item.routeMatch, false) : false,
			expanded: expanded
		}"
		class="menu-list-item">
		<mat-icon
			[fontSet]="item.iconCustom ? item.iconSet : ''"
			[fontIcon]="item.iconCustom ? item.iconName : ''"
			class="route-icon">
			{{!item.iconCustom ? item.iconName : ''}}
		</mat-icon>
		<span *ngIf="isExpanded" fxFlex>{{source.adminText?.menu[item.adminTextPrefix]}} </span>
		<span *ngIf="item.children && item.children.length && isExpanded" (click)="expandSelect($event, item)">
			<mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
				expand_more
			</mat-icon>
		</span>
	</a>
	<div *ngIf="expanded">
		<app-menu-list-item
			*ngFor="let child of item.children"
			[item]="child"
			[depth]="depth + 1"
			[isExpanded]="isExpanded">
		</app-menu-list-item>
	</div>
</ng-container>
