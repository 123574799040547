import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as moment from 'moment';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SupplierStore } from 'app/admin/stores/supplier.store.service';
import { TravelAgency } from 'app/models/travel-agency.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-travel-agency-generally',
	templateUrl: './travel-agency-generally.component.html',
	styleUrls: ['./travel-agency-generally.component.scss'],
})
export class TravelAgencyGenerallyComponent implements OnInit {
	@Input() travelAgency: TravelAgency;

	public itemForm: FormGroup = new FormGroup({
		shortName: new FormControl('', [Validators.required]),
		name: new FormControl('', [Validators.required]),
		phone: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required]),
		homepage: new FormControl('', [Validators.required]),
		description: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public supplierStore: SupplierStore,
	) {}

	ngOnInit() {
		this.itemForm.patchValue(this.travelAgency);
		this.itemForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.travelAgency = Object.assign(this.travelAgency, value));
		this.itemForm.get('description').setValue(this.travelAgency.description);
	}
}
