import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { Language } from 'app/models/language.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddonText } from 'app/models/addon-text.model';
import { AddonTextStore } from 'app/admin/stores/addon-text.store.service';
import { AddonTextBladeComponent } from 'app/admin/blades/addon-text/addon-text-blade/addon-text-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-addon-blade-texts',
	templateUrl: './addon-blade-texts.component.html',
	styleUrls: ['./addon-blade-texts.component.scss'],
})
export class AddonBladeTextsComponent implements OnInit, OnDestroy {
	public languages: Language[];
	public addonTexts: AddonText[];
	public displayedColumns: string[] = ['id', 'title', 'language'];
	public dataSource: MatTableDataSource<AddonText>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public languageStore: LanguageStore,
		private addonTextStore: AddonTextStore,
	) {
		this.addonTextStore.reset();
	}

	ngOnInit() {
		this.addonTextStore.items$.pipe(untilDestroyed(this)).subscribe(addonTexts => {
			this.addonTexts = addonTexts;
			this.handleAddon();
		});
		this.addonTextStore.getAllOnAddon(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(addonTexts => {
			this.addonTexts = addonTexts;
			this.handleAddon();
		});
		this.languageStore.items$.pipe(untilDestroyed(this)).subscribe(languages => {
			this.languages = languages;
			this.handleAddon();
		});
	}

	ngOnDestroy(): void { }

	private handleAddon() {
		if (this.addonTexts && this.languages) {
			// Every language must have a addonText
			_.each(this.languages, language => {
				const addonTexts = _.filter(this.addonTexts, text => text.languageId === language.id);
				let addonText;
				if (addonTexts && addonTexts.length > 1) {
					addonText = _.find(this.addonTexts, text => text.languageId === language.id && text.id > 0);
					this.addonTextStore.removeAddonText(_.find(this.addonTexts, text => text.languageId === language.id && !text.id));
				} else if (addonTexts && addonTexts.length) {
					addonText = addonTexts[0];
				}

				if (!addonText) {
					addonText = new AddonText();
					addonText.languageId = language.id;
					this.addonTextStore.addAddonText(addonText);
				}
				addonText.language = language.name;
			});
			this.dataSource = new MatTableDataSource<AddonText>(this.addonTexts);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}

	public selectItem(row: AddonText) {
		this.bladeService.addBladeItem({
			id: 0,
			component: AddonTextBladeComponent,
			minClass: BladeSize.m,
			payload: {
				addonId: this.bladeItem.id,
				languageId: row.languageId,
			},
		}, this.bladeItem);
	}
}
