import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseStore } from './base.store.service';
import { TourBooking } from 'app/models/tour-booking.model';
import { OrderItem } from 'app/models/order-item.model';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class TourBookingStore extends BaseStore<TourBooking> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'TourBooking', baseUrl, snackBarService);
	}

	public getAllOnTour(tourId: number): Observable<TourBooking[]> {
		return this.http.get<TourBooking[]>(`${this.host}/tour/${tourId}`).pipe(tap(tourBookings => {
			this._items = tourBookings;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnTour$(tourId: number): Observable<TourBooking[]> {
		return this.http.get<TourBooking[]>(`${this.host}/tour/${tourId}`);
	}

	public getAllOnCustomer(customerId: number): Observable<TourBooking[]> {
		return this.http.get<TourBooking[]>(`${this.host}/customer/${customerId}`).pipe(tap(tourBookings => {
			this._items = tourBookings;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnCustomerAndTour(customerId: number, tourId: number): Observable<TourBooking[]> {
		return this.http.get<TourBooking[]>(`${this.host}/customer/${customerId}/tour/${tourId}`).pipe(tap(tourBookings => {
			this._items = tourBookings;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnOrder(orderId: number): Observable<TourBooking[]> {
		return this.http.get<TourBooking[]>(`${this.host}/order/${orderId}`).pipe(tap(tourBookings => {
			this._items = tourBookings;
			this._itemsSubject.next(this._items);
		}));
	}

	public getAllOnOrder$(orderId: number): Observable<TourBooking[]> {
		return this.http.get<TourBooking[]>(`${this.host}/order/${orderId}`);
	}

	public createOrder(tourId: number, customerId: number, orderItems: OrderItem[]): Observable<TourBooking> {
		const obj = {
			tourId,
			customerId,
			orderItems,
		};
		return this.http.put<TourBooking>(`${this.host}/order`, obj).pipe(tap(tourBooking => {
			if (!this._items) {
				this._items = [];
			}
			this._items.push(tourBooking);
			this._itemsSubject.next(this._items);
		}));
	}

	public changeTour(tourBookingId: number, tourId: number): Observable<boolean> {
		return this.http.put<boolean>(`${this.host}/tour/change`, {tourBookingId, tourId});
	}

	public getAllOnTravelAgency(travelAgencyId: number): Observable<TourBooking[]> {
		return this.http.get<TourBooking[]>(`${this.host}/travelAgency/${travelAgencyId}`).pipe(tap(items => {
			this._items = items;
			this._itemsSubject.next(this._items);
		}));
	}
}
