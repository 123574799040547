import { Component, OnInit, Inject, Input } from '@angular/core';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Page } from 'app/models/site/page.model';
import { LanguageStore } from 'app/admin/stores/language.store.service';

@Component({
	selector: 'app-page-blade-text',
	templateUrl: './page-blade-text.component.html',
	styleUrls: ['./page-blade-text.component.scss'],
})
export class PageBladeTextComponent implements OnInit {

	@Input() item: Page;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public languageStore: LanguageStore,
	) { }

	ngOnInit() {
	}
}
