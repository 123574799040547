<mat-card>
	<mat-card-title>Generellt</mat-card-title>
	<mat-card-content>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput formControlName="shortName" placeholder="Förkortning">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="name" placeholder="Namn">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="phone" placeholder="Telefonnummer">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="email" placeholder="Epost">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput formControlName="homepage" placeholder="Hemsida">
			</mat-form-field>
			<mat-form-field class="w-100">
				<textarea matInput formControlName="description" placeholder="Beskrivning"></textarea>
			</mat-form-field>
		</form>
		<div class="row" *ngIf="travelAgency.id">
			<div class="col-6">
				<b>Skapad:</b><br>
				{{travelAgency.created | date:'yyyy-MM-dd HH:mm'}}<br>
				<span *ngIf="travelAgency.createdBy > 0"><small>{{travelAgency.createdBy | adminUserName | async}}</small></span>
			</div>
			<div class="col-6">
				<b>Ändrad:</b><br>
				{{travelAgency.modified | date:'yyyy-MM-dd HH:mm'}}<br>
				<span *ngIf="travelAgency.modifiedBy > 0"><small>{{travelAgency.modifiedBy | adminUserName | async}}</small></span>
			</div>
		</div>
	</mat-card-content>
</mat-card>
