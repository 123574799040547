import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseStore } from './base.store.service';
import { SnackBarService } from '../services/snack-bar.service';
import { System } from 'app/models/system.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class SystemStore extends BaseStore<System> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'System', baseUrl, snackBarService);
	}

	public getCurrent$(): Observable<System> {
		return this.http.get<System>(`${this.host}/current`);
	}

	public getCurrent(): void {
		this.http.get<System>(`${this.host}/current`).pipe(tap(items => {
			this._items = [items];
			this._itemsSubject.next(this._items);
		})).subscribe();
	}
}
