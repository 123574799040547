import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Language } from 'app/models/language.model';
import { LanguageStore } from 'app/admin/stores/language.store.service';

@Component({
	selector: 'app-language-blade',
	templateUrl: './language-blade.component.html',
	styleUrls: ['./language-blade.component.scss'],
})
@IBladeComponent.register
export class LanguageBladeComponent implements OnInit {

	public loading = true;
	public item: Language;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		identifier: new FormControl('', [Validators.required]),
		isActive: new FormControl(false, [Validators.required]),
	});
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: LanguageStore,
	) {
		if (bladeItem.id) {
			itemStore.get(bladeItem.id).subscribe(item => {
				this.item = item;
				this.itemForm.setValue({name: this.item.name, identifier: this.item.identifier, isActive: this.item.isActive});

				this.loading = false;
			});
		} else {
			this.loading = false;
			this.item = new Language();
		}
	 }

	ngOnInit() {
	}

	public createIdentifier() {
		this.itemForm.get('identifier').setValue(_.snakeCase(this.itemForm.get('name').value));
	}

	public save() {
		this.loading = true;
		this.item.name = this.itemForm.get('name').value;
		this.item.identifier = this.itemForm.get('identifier').value;
		this.item.isActive = this.itemForm.get('isActive').value;
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}
	private saveOrUpdate(): Observable<Language> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}
}
