<app-blade-item [canIClose]="true">
	<app-blade-header [title]="'Ny order'"></app-blade-header>
	<app-blade-content>
		<form [formGroup]="itemForm">
			<mat-form-field class="w-100">
				<input matInput placeholder="Ämne" formControlName="subject">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput placeholder="Rubrik" formControlName="headline">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput placeholder="Titel" formControlName="title">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput placeholder="Förhandsgransknings text" formControlName="previewText">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput placeholder="Text på knappen" formControlName="buttonText">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput placeholder="Länk på knappen" formControlName="buttonUrl">
			</mat-form-field>
			<mat-form-field class="w-100">
				<input matInput placeholder="Taggar" formControlName="tags">
			</mat-form-field>

			<app-text-editor [size]="'small'" [label]="'Meddelande'" [textModel]="itemForm?.get('message')"></app-text-editor>
			<app-text-editor [size]="'small'" [label]="'Text på antal'" [textModel]="itemForm?.get('customerTypeText')"></app-text-editor>
			<app-text-editor [size]="'small'" [label]="'Rubrik på tillägg'" [textModel]="itemForm?.get('addonTitle')"></app-text-editor>
			<app-text-editor [size]="'small'" [label]="'Text på tillägg'" [textModel]="itemForm?.get('addonText')"></app-text-editor>
			<app-text-editor [size]="'small'" [label]="'Rubrik på upphämtning'" [textModel]="itemForm?.get('pickupTitle')"></app-text-editor>
			<app-text-editor [size]="'small'" [label]="'Text på upphämtning'" [textModel]="itemForm?.get('pickupText')"></app-text-editor>
			<app-text-editor [size]="'small'" [label]="'Total belopp'" [textModel]="itemForm?.get('totalAmountText')"></app-text-editor>
			<app-text-editor [size]="'small'" [label]="'Kundmeddelande'" [textModel]="itemForm?.get('customerMessageTitle')"></app-text-editor>
			<app-text-editor [size]="'small'" [label]="'Info text'" [textModel]="itemForm?.get('infoText')"></app-text-editor>
			<app-text-editor [size]="'small'" [label]="'Footer text'" [textModel]="itemForm?.get('footerText')"></app-text-editor>

			<mat-form-field class="w-100">
				<input matInput placeholder="Template id på Sendinblue" formControlName="sendinBlueTemplateId">
			</mat-form-field>

			<mat-form-field class="w-100">
				<mat-label>Språk</mat-label>
				<mat-select formControlName="languageId">
					<mat-option *ngFor="let type of (languageStore.items$|async)" [value]="type.id">
						{{type.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>

		</form>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()" [disabled]="itemForm.invalid">
			<span *ngIf="!item?.id">Skapa</span>
			<span *ngIf="item?.id">Spara</span>
		</button>
	</app-blade-footer>
</app-blade-item>
