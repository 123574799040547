import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettingsStore } from 'app/admin/stores/settings.store.service';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { TourMessageStore } from 'app/admin/stores/tour-message.store.service';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { Settings } from 'app/models/site/settings.model';
import { TourBooking } from 'app/models/tour-booking.model';
import { TourMessage } from 'app/models/tour-message.model';
import { Tour } from 'app/models/tour.model';
import { AdminDataService } from 'app/modules/admin-data/services/admin-data.service';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import _find from 'lodash-es/find';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-tours-customers-program',
  templateUrl: './tours-customers-program.component.html',
  styleUrls: ['./tours-customers-program.component.scss'],
})
export class ToursCustomersProgramComponent implements OnInit {
	public language$: Observable<any>;
	public siteSettings$ = new ReplaySubject<Settings>(1);
	public tour$ = new ReplaySubject<Tour>(1);
	public tourMessages$ = new ReplaySubject<TourMessage[]>(1);
	public tourBookings$ = new ReplaySubject<TourBooking[]>(1);


  	constructor(
		private tourStore: TourStore,
		private tourMessageStore: TourMessageStore,
		private tourBookingStore: TourBookingStore,
		private settingsStore: SettingsStore,
		private route: ActivatedRoute,
		private adminDataService: AdminDataService,
		) {
		this.settingsStore.getOnSite().pipe(untilDestroyed(this)).subscribe(item => {
			this.siteSettings$.next(item);
		});

		this.language$ = this.route.queryParams.pipe(untilDestroyed(this), switchMap(params => {
			return this.adminDataService.avaiableLanguages$.pipe(map(allLanguages => {
				return _find(allLanguages, lang => lang.id === +params.languageId);
			}));
		}));
  	}

	ngOnInit() {
		this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
			if (params?.tourId) {
				this.tourStore.get(params?.tourId).pipe(untilDestroyed(this)).subscribe(tour => this.tour$.next(tour));
				this.tourMessageStore.getAllOnTour(params?.tourId).pipe(untilDestroyed(this)).subscribe(tourMessages => this.tourMessages$.next(tourMessages));
				this.tourBookingStore.getAllOnTour(params?.tourId).pipe(untilDestroyed(this)).subscribe(tourBookings => this.tourBookings$.next(tourBookings));
			}
		});
  	}
}
