import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TourBookingCustomerTypeService } from './services/tour-booking-customer-type.service';

@Component({
  	selector: 'app-tour-booking-customer-type',
	templateUrl: './tour-booking-customer-type.component.html',
  	styleUrls: ['./tour-booking-customer-type.component.scss'],
	providers: [TourBookingCustomerTypeService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourBookingCustomerTypeComponent implements OnInit {


	constructor(
		public tourBookingCustomerTypeService: TourBookingCustomerTypeService,
	) { }

	ngOnInit() {
	}
}
