import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import * as _ from 'lodash';

import { navItems } from '../menu-items';
import { NavItem } from 'app/admin/models/nav-item.model';


@Injectable()
export class MenuItemsService {
	private root = '';
	private menuItemsReplay = new ReplaySubject<NavItem[]>();
	public menuItemsReplay$ = this.menuItemsReplay.asObservable();

	constructor() {
		_.each(navItems, item => {
			this.setRouteMatch(item, this.root);
		});
		this.menuItemsReplay.next(navItems);
	}

	private setRouteMatch(item: NavItem, breadcrumbs: string) {
		if (item.route) {
			item.routeMatch = `${breadcrumbs}${(breadcrumbs) ? '/' : ''}${item.route}`;
		}
		if (item.children) {
			_.each(item.children, navItem => {
				this.setRouteMatch(navItem, item.routeMatch);
			});
		}
	}
}
