import { Component, Inject } from '@angular/core';

import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { CustomerType } from 'app/models/customer-type.model';
import { CustomerTypeStore } from 'app/admin/stores/customer-type.store.service';
import { CustomerTypeService } from './services/customer-type.service';

@Component({
	selector: 'app-customer-type-blade',
	templateUrl: './customer-type-blade.component.html',
	styleUrls: ['./customer-type-blade.component.scss'],
	providers: [CustomerTypeService],
})
@IBladeComponent.register
export class CustomerTypeBladeComponent {

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: CustomerTypeStore,
		public customerTypeService: CustomerTypeService,
	) { }
}
