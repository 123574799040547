import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';

import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { LanguageStore } from 'app/admin/stores/language.store.service';
import { Language } from 'app/models/language.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TermText } from 'app/models/term-text.model';
import { TermTextStore } from 'app/admin/stores/term-text.store.service';
import { TermTextBladeComponent } from '../../../term-text-blade/term-text-blade.component';

@UntilDestroy()
@Component({
	selector: 'app-term-blade-texts',
	templateUrl: './term-blade-texts.component.html',
	styleUrls: ['./term-blade-texts.component.scss'],
})
export class TermBladeTextsComponent implements OnInit, OnDestroy {
	public languages: Language[];
	public termTexts: TermText[];
	public displayedColumns: string[] = ['id', 'headline', 'language'];
	public dataSource: MatTableDataSource<TermText>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public languageStore: LanguageStore,
		private termTextStore: TermTextStore,
	) {
		this.termTextStore.reset();
	}

	ngOnInit() {
		this.termTextStore.items$.pipe(untilDestroyed(this)).subscribe(termTexts => {
			this.termTexts = termTexts;
			this.handleTerm();
		});
		this.termTextStore.getAllOnTerm(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(termTexts => {
			this.termTexts = termTexts;
			this.handleTerm();
		});
		this.languageStore.items$.pipe(untilDestroyed(this)).subscribe(languages => {
			this.languages = languages;
			this.handleTerm();
		});
	}

	ngOnDestroy(): void { }

	private handleTerm() {
		if (this.termTexts && this.languages) {
			// Every language must have a termText
			_.each(this.languages, language => {
				const termTexts = _.filter(this.termTexts, text => text.languageId === language.id);
				let termText;
				if (termTexts && termTexts.length > 1) {
					termText = _.find(this.termTexts, text => text.languageId === language.id && text.id > 0);
					this.termTextStore.removeTermText(_.find(this.termTexts, text => text.languageId === language.id && !text.id));
				} else if (termTexts && termTexts.length) {
					termText = termTexts[0];
				}

				if (!termText) {
					termText = new TermText();
					termText.languageId = language.id;
					this.termTextStore.addTermText(termText);
				}
				termText.language = language.name;
			});
			this.dataSource = new MatTableDataSource<TermText>(this.termTexts);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}

	public selectItem(row: TermText) {
		this.bladeService.addBladeItem({
			id: 0,
			component: TermTextBladeComponent,
			minClass: BladeSize.m,
			payload: {
				termId: this.bladeItem.id,
				languageId: row.languageId,
			},
		}, this.bladeItem);
	}
}
