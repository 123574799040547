import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { ColumnLinkTypeStore } from 'app/admin/stores/column-link-type.store.service';
import { ColumnLinkStore } from 'app/admin/stores/column-link.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ColumnLink } from 'app/models/column-link.model';
import { ReplaySubject } from 'rxjs';
import { PageStore } from 'app/admin/stores/page.store.service';
import { CategoryStore } from 'app/admin/stores/category/category.store.service';
import { ProductStore } from 'app/admin/stores/product/product.store.service';

@UntilDestroy()
@Component({
  selector: 'app-column-blade-link',
  templateUrl: './column-blade-link.component.html',
  styleUrls: ['./column-blade-link.component.scss'],
})
export class ColumnBladeLinkComponent {
	public isSaving$ = new ReplaySubject<boolean>(1);
	public columnLink: ColumnLink;

	public itemForm: FormGroup = new FormGroup({
		columnLinkTypeId: new FormControl('', [Validators.required]),
		value: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		public columnLinkTypeStore: ColumnLinkTypeStore,
		public columnLinkStore: ColumnLinkStore,
		public pageStore: PageStore,
		public productStore: ProductStore,
		public categoryStore: CategoryStore,
	) {
		this.categoryStore.getAll();
		this.columnLinkStore.getOnColumn(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(columnLinks => {
			if (columnLinks?.length) {
				this.columnLink = columnLinks[0];
				this.itemForm.patchValue(this.columnLink);
			} else {
				this.columnLink = new ColumnLink();
				this.columnLink.columnId = this.bladeItem.id;
			}
		});
	}

	public save(): void {
		this.isSaving$.next(true);
		this.columnLink = Object.assign(this.columnLink, this.itemForm.value);

		if (this.columnLink.id) {
			this.columnLinkStore.update(this.columnLink).subscribe(columnLink => {
				this.columnLink = Object.assign(this.columnLink, columnLink);
				this.isSaving$.next(false);
			});
		} else {
			this.columnLinkStore.create(this.columnLink).subscribe(columnLink => {
				this.columnLink = Object.assign(this.columnLink, columnLink);
				this.isSaving$.next(false);
			});
		}
	}
}
