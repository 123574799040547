import { BaseModel } from '../base.model';
import { Element } from '../element.model';
import { Link } from './link.model';
import { Style } from './style.model';

export class Column extends BaseModel {
	public name: string;
	public etageId?: number;
	public elements?: Element[];
	public prio?: number;
	public style?: any;
	public link?: Link;
	public classes?: string[];
}
