import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-tours-dashboard',
	templateUrl: './tours-dashboard.component.html',
	styleUrls: ['./tours-dashboard.component.scss'],
})
export class ToursDashboardComponent implements OnInit {
	constructor() { }

	ngOnInit() { }
}
