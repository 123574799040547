<app-blade-item *ngIf="{
	selectedCustomer: createOrderTourBookingBladeService.selectedCustomerReplay$ | async,
	selectedProduct: createOrderTourBookingBladeService.selectedProductReplay$ | async,
	user: userService.user$ | async,
	orderParts: createOrderTourBookingBladeService.orderPartsReplay$ | async,
	errorMessage: createOrderTourBookingBladeService.errorMessageBehavior$ | async
} as source" [canIClose]="true" [loading]="createOrderTourBookingBladeService.isLoadingBehavior$ | async">
	<app-blade-header title="Ny order" [subTitle]="bladeItem.payload.mode ? (bladeItem.payload.mode === 1 ? 'Tur bokning' : 'Tur') : ''"></app-blade-header>
	<app-blade-content>
		<form [formGroup]="createOrderTourBookingBladeService.formGroup">
			<mat-card class="my-3">
				<mat-card-title>Information till kund</mat-card-title>
				<mat-card-subtitle>
					Ska denna order skickas till kunden så kommer informationen nedan att visas på kundens betalsidan.
					<br>
					<i>Denna information är frivillig.</i>
				</mat-card-subtitle>
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>Rubrik</mat-label>
					<input matInput formControlName="name" placeholder="Rubrik">
				</mat-form-field>
				<mat-form-field class="w-100" appearance="outline">
					<mat-label>Meddelande</mat-label>
					<textarea matInput formControlName="message" placeholder="Meddelande"></textarea>
				</mat-form-field>
			</mat-card>

			<mat-card class="mt-3" *ngIf="source.user?.userType === UserTypeEnum.admin">
				<mat-card-title>Resebyrå</mat-card-title>
				<mat-card-subtitle>Ska ordern läggas på en resebyrå? Ange resebyråen nedan. Annars kan detta lämnas tom.</mat-card-subtitle>
				<mat-card-content>
					<mat-form-field class="w-100">
						<mat-label>Resebyrå</mat-label>
						<mat-select formControlName="travelAgencyId">
							<mat-option value="0">Ingen resebyrå vald</mat-option>
							<mat-option *ngFor="let type of (travelAgencyStore.items$ | async)" [value]="type.id">
								{{type.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</mat-card-content>
			</mat-card>
		</form>

		<mat-card class="mt-3">
			<mat-card-title>Kund</mat-card-title>
			<mat-card-subtitle>Kund som ska kopplas på denna order.</mat-card-subtitle>
			<mat-card-content>
				<p *ngIf="!source.selectedCustomer">Ingen vald</p>
				<div *ngIf="source.selectedCustomer">
					<p class="mb-0">Id: {{source.selectedCustomer?.id}} - {{source.selectedCustomer?.firstName}} {{source.selectedCustomer?.lastName}} <span *ngIf="source.selectedCustomer?.email">&lt;{{source.selectedCustomer?.email}}&gt;</span></p>
				</div>
			</mat-card-content>
			<mat-card-actions class="text-right">
				<button mat-stroked-button color="accent" (click)="selectCustomer()">
					<span *ngIf="!source.selectedCustomer">Välj kund</span>
					<span *ngIf="source.selectedCustomer">Ändra kund</span>
				</button>
			</mat-card-actions>
		</mat-card>

		<mat-card class="mt-3">
			<mat-card-title>Innehåll</mat-card-title>
			<mat-card class="mb-3" *ngFor="let orderPart of source.orderParts">
				<mat-card-subtitle *ngIf="orderPart.productId">{{orderPart.productId | productName | async}} {{orderPart.date | date:'yyyy-MM-dd'}} {{orderPart.time}}</mat-card-subtitle>
				<mat-card-content>
					<mat-card class="mb-2">
						<mat-card-subtitle>Message</mat-card-subtitle>
						<mat-card-content>
							<span *ngIf="orderPart.message" [innerHTML]="orderPart.message | newLine"></span>
							<span *ngIf="!orderPart.message">Inget meddelande</span>
						</mat-card-content>
					</mat-card>
					<mat-card class="mb-2" *ngIf="orderPart.customerTypes?.length">
						<mat-card-subtitle>Kundtyper</mat-card-subtitle>
						<mat-card-content>
							<p *ngFor="let customerType of orderPart.customerTypes">
								{{customerType.quantity}} st {{customerType.customerTypeId | customerTypeName | async}} á {{customerType.unitPrice | currency:'SEK':'code'}}
							</p>
						</mat-card-content>
					</mat-card>
					<mat-card class="mb-2" *ngIf="orderPart.addons?.length">
						<mat-card-subtitle>Tillägg</mat-card-subtitle>
						<mat-card-content>
							<p *ngFor="let addon of orderPart.addons">
								{{addon.quantity}} st {{addon.addonId | addonName | async}} á {{addon.unitPrice | currency:'SEK':'code'}}
							</p>
						</mat-card-content>
					</mat-card>
					<mat-card class="mb-2" *ngIf="orderPart.pickupLocations?.length">
						<mat-card-subtitle>Hämtningar</mat-card-subtitle>
						<mat-card-content>
							<p *ngFor="let pickup of orderPart.pickupLocations">
								{{pickup.quantity}} st {{pickup.customerTypeId | customerTypeName | async}} á {{pickup.unitPrice | currency:'SEK':'code'}} hos {{pickup.pickupLocationId | pickupLocationName | async}}
							</p>
						</mat-card-content>
					</mat-card>
					<mat-card-actions class="d-flex justify-content-between pr-2">
						<button mat-stroked-button (click)="createOrderTourBookingBladeService.removeOrderPart(orderPart)">Ta bort</button>
						<button mat-stroked-button color="accent" (click)="editOrderPart(orderPart)">Ändra</button>
					</mat-card-actions>
				</mat-card-content>
			</mat-card>
			<mat-card-actions class="text-right pr-2">
				<button mat-stroked-button color="accent" (click)="selectProduct()" *ngIf="!(bladeItem.payload.mode !== CreateOrderModeEnum.newOrder && source.orderParts?.length)">
					<span *ngIf="bladeItem.payload.mode === CreateOrderModeEnum.newOrder">Lägg till produkt och antal</span>
					<span *ngIf="bladeItem.payload.mode === CreateOrderModeEnum.onTourBooking">Välj antal till bokningen</span>
					<span *ngIf="bladeItem.payload.mode === CreateOrderModeEnum.onTour">Välj antal til turen</span>
				</button>
			</mat-card-actions>
		</mat-card>

		<div *ngIf="source.errorMessage" class="p-3 mt-3 bg-danger text-white rounded">
			<p *ngIf="source.errorMessage?.message">{{source.errorMessage.message}}</p>
			<p *ngIf="source.errorMessage?.error?.message" class="m-0">{{source.errorMessage?.error?.message}}</p>
		</div>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<span *ngIf="source.errorMessage" class="align-self-center mr-3 text-danger">Finns fel!</span>
		<button mat-flat-button
			color="primary"
			[disabled]="createOrderTourBookingBladeService.formGroup.invalid || !source.selectedCustomer?.id || !source.orderParts?.length" (click)="save()"
		>
			Skapa order
		</button>
	</app-blade-footer>
</app-blade-item>
