<ng-container *ngIf="{
	adminText: 'blades.tour' | adminText | async
} as source">
	<app-blade-item [canIClose]="true" [loading]="loading">
		<app-blade-header [title]="item?.name || (item?.productId ? (item?.productId | productName | async) : (item?.id ? source.adminText?.bladeTitleNew : source.adminText?.bladeSubtitle)) || source.adminText?.bladeTitleNew + '-' + source.adminText?.bladeSubtitle" [subTitle]="source.adminText?.bladeSubtitle"></app-blade-header>
		<ng-container *ngIf="item?.id; else newTour">
			<app-blade-content>
				<app-blade-content-tabs>
					<app-blade-content-tab [title]="source.adminText?.tabs.generally.title" [template]="generally">
						<ng-template #generally>
							<app-tour-generally *ngIf="item" [tour]="item"></app-tour-generally>
						</ng-template>
					</app-blade-content-tab>
					<app-blade-content-tab [title]="source.adminText?.tabs.bookings.title" [template]="bookings">
						<ng-template #bookings>
							<app-tour-bookings></app-tour-bookings>
						</ng-template>
					</app-blade-content-tab>
					<app-blade-content-tab [title]="source.adminText?.tabs.guides.title" [template]="guides">
						<ng-template #guides>
							<app-tour-guides></app-tour-guides>
						</ng-template>
					</app-blade-content-tab>
					<app-blade-content-tab [title]="source.adminText?.tabs.summary.title" [template]="summary">
						<ng-template #summary>
							<app-tour-summary></app-tour-summary>
						</ng-template>
					</app-blade-content-tab>
					<app-blade-content-tab [title]="source.adminText?.tabs.pickup.title" [template]="pickup">
						<ng-template #pickup>
							<app-tour-pickup></app-tour-pickup>
						</ng-template>
					</app-blade-content-tab>
					<app-blade-content-tab [title]="source.adminText?.tabs.messages.title" [template]="messages">
						<ng-template #messages>
							<app-tour-messages></app-tour-messages>
						</ng-template>
					</app-blade-content-tab>
					<app-blade-content-tab [title]="source.adminText?.tabs.resource.title" [template]="resource">
						<ng-template #resource>
							<app-tour-resource [tour]="item"></app-tour-resource>
						</ng-template>
					</app-blade-content-tab>
					<app-blade-content-tab [title]="source.adminText?.tabs.program.title" [template]="program">
						<ng-template #program>
							<app-tour-customer-program></app-tour-customer-program>
						</ng-template>
					</app-blade-content-tab>
				</app-blade-content-tabs>
			</app-blade-content>
			<app-blade-footer>
				<span fxFlex></span>
				<button mat-raised-button color="primary" (click)="save()">{{source.adminText?.saveBtn}}</button>
			</app-blade-footer>
		</ng-container>
	</app-blade-item>

	<ng-template #newTour>
		<app-blade-content>
			<app-tour-generally *ngIf="item" [tour]="item"></app-tour-generally>
			<form [formGroup]="messageForm">
				<mat-card class="mt-3">
					<!-- Include internal message when creating a new tour -->
					<mat-card-title class="card-title-small">{{source.adminText?.internalMessage}}</mat-card-title>
					<mat-form-field class="w-100" appearance="outline">
						<textarea matInput formControlName="message" [placeholder]="source.adminText?.internalMmessageLabelessage"></textarea>
					</mat-form-field>
				</mat-card>
			</form>
		</app-blade-content>
		<app-blade-footer>
			<span fxFlex></span>
			<button mat-raised-button color="primary" (click)="save()">{{source.adminText?.createBtn}}</button>
		</app-blade-footer>
	</ng-template>
</ng-container>
