import { DashboardWidgetSizeRowsColumns } from './dashboard-widget-size-rows-columns.model';
import { DashboardWidgetType } from './dashboard-widget-type.model';
import { GridsterItem } from 'angular-gridster2';
import { BaseModel } from 'app/models/base.model';

export class DashboardWidget extends BaseModel implements GridsterItem {
	public type: string;
	public dashboardId?: number;
	public x: number;
	public y: number;
	public rows: number;
	public cols: number;
	public name: string;
	public size: DashboardWidgetSizeRowsColumns;
	public dashboardWidgetTypeId: number;
	public data?: any;
}
