import { Component, OnInit, Inject, ViewChild } from '@angular/core';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { TourBookingStore } from 'app/admin/stores/tour-booking.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatAccordion } from '@angular/material/expansion';
import { TourBooking } from 'app/models/tour-booking.model';
import { combineLatest, ReplaySubject } from 'rxjs';
import { PaymentBalance } from 'app/models/paymanet-balance.model';
import { OrderStore } from 'app/admin/stores/order.store.service';
import { TourBookingCustomerTypeStore } from 'app/admin/stores/tour-booking-customer-type.store.service';
import { TourBookingCustomerType } from 'app/models/tour-booking-customer-type.model';
import * as moment from 'moment';

@UntilDestroy()
@Component({
	selector: 'app-guide-tour-customers',
	templateUrl: './tour-customers.component.html',
	styleUrls: ['./tour-customers.component.scss'],
})
export class GuideTourCustomersComponent implements OnInit {
	public moment = moment;
	@ViewChild(MatAccordion) accordion: MatAccordion;

	private tourBookings: TourBookingWithPayment[];
	public tourBookingsReplay$ = new ReplaySubject<TourBookingWithPayment[]>(1);

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public tourBookingStore: TourBookingStore,
		public orderStore: OrderStore,
		private tourBookingCustomerTypeStore: TourBookingCustomerTypeStore,
	) {
		this.tourBookingStore.getAllOnTour(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(tourBookings => {
			this.tourBookings = [];
			tourBookings.forEach(tourBooking => {
				combineLatest([
					this.orderStore.getPaymentBalanceOnOrder(tourBooking.orderId),
					this.tourBookingCustomerTypeStore.getAllOnTourBooking(tourBooking.id),
				])
				.pipe(untilDestroyed(this)).subscribe(([paymentBalance, tourBookingCustomerTypes]) => {
					this.tourBookings.push({
						paymentBalance,
						tourBooking,
						tourBookingCustomerTypes,
					})
					this.tourBookingsReplay$.next(this.tourBookings);
				});
			});

		});
	}

	ngOnInit() {
	}
}

interface TourBookingWithPayment {
	tourBooking: TourBooking;
	paymentBalance: PaymentBalance;
	tourBookingCustomerTypes: TourBookingCustomerType[];
}
