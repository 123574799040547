import { Component, OnInit, Inject, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { Category } from 'app/models/category/category.model';
import { CategoryCategory } from 'app/models/category/category-category.model';
import { CategoryStore } from 'app/admin/stores/category/category.store.service';
import { CategoryCategoryStore } from 'app/admin/stores/category/category-category.store.service';
import { CategoryBladeService } from '../../services/category-blade.service';

@UntilDestroy()
@Component({
	selector: 'app-category-blade-category',
	templateUrl: './category-blade-category.component.html',
	styleUrls: ['./category-blade-category.component.scss'],
})
export class CategoryBladeCategoryComponent implements OnInit, OnDestroy {
	private saveSubject: Subject<void>;
	private categories: Category[];

	public displayedColumns: string[] = ['select', 'id', 'name'];
	public dataSource: MatTableDataSource<Category>;
	public selection: SelectionModel<Category>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	private connectedCategories: CategoryCategory[];
	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public categoryStore: CategoryStore,
		public categoryCategoryStore: CategoryCategoryStore,
		private snackBarService: SnackBarService,
		private categoryBladeService: CategoryBladeService,
	) {
		this.categoryStore.getAll();
		this.categoryStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.categories = _.filter(items, cat => cat.id !== this.bladeItem.id);
			this.dataSource = new MatTableDataSource<Category>(this.categories);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getAllOnParent();
		});
	}

	ngOnInit() {}

	ngOnDestroy() {}

	public selectCategory(category: Category) {
		this.selection.toggle(category);
	}

	private getAllOnParent() {
		this.categoryCategoryStore.getAllOnParent(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(connectedCategories => {
			this.connectedCategories = connectedCategories;
			this.handleSelectedCategories();
		});
	}

	private handleSelectedCategories() {
		const selectedCategories = [];
		_.each(_.map(this.connectedCategories, 'category'), category => {
			const prod = _.find(this.categories, p => p.id === category.id);
			if (prod && !_.find(selectedCategories, p => p.id === prod.id)) {
				selectedCategories.push(prod);
			}
		});
		this.selection = new SelectionModel<Category>(true, selectedCategories);
	}

	public save() {
		this.categoryBladeService.setLoading(true);
		const add: CategoryCategory[] = [];
		_.each(_.differenceWith(this.selection.selected, _.map(this.connectedCategories, 'category'), (a, b) => a.id === b.id), category => {
			const cp = new CategoryCategory();
			cp.categoryId = this.bladeItem.id;
			cp.categoryId = category.id;
			add.push(cp);
		});

		const removed = _.differenceWith(this.connectedCategories, this.selection.selected, (a, b) => a.categoryId === b.id);
		if (add || removed) {
			this.categoryCategoryStore.addDelete(this.bladeItem.id, add, _.map(removed, 'id')).pipe(untilDestroyed(this)).subscribe(() => {
				this.getAllOnParent();
				this.snackBarService.showSuccess('Kopplingarna har sparats!');
				this.categoryBladeService.setLoading(false);
			});
		}
	}
}
