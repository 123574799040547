<mat-card *ngIf="{
	tourBookingCustomerType: tourBookingPickupLocationItemService.tourBookingPickupLocationReplay$ | async
} as source">
	<form [formGroup]="tourBookingPickupLocationItemService.itemForm">
		<mat-form-field class="w-100" appearance="outline">
			<mat-label>Typ</mat-label>
			<mat-select formControlName="customerTypeId">
				<mat-option *ngFor="let type of (customerTypeStore.items$ | async)" [value]="type.id">
					{{type.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="w-100" appearance="outline">
			<mat-label>Plats</mat-label>
			<mat-select formControlName="pickupLocationId">
				<mat-option *ngFor="let type of (pickupLocationStore.items$ | async)" [value]="type.id">
					{{type.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="w-100" appearance="outline">
			<input matInput type="number" [min]="0" formControlName="quantity" placeholder="Antal">
		</mat-form-field>
	</form>

	<div class="align-items-baseline d-flex justify-content-between mt-3">
		<button mat-button color="warn" class="mb-3" (click)="tourBookingPickupLocationService.removePickupLocation(source.tourBookingCustomerType)">
			Radera <mat-icon>delete_forever</mat-icon>
		</button>
	</div>
</mat-card>
