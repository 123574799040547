import { Component, Input, OnInit } from '@angular/core';
import { TourBooking } from 'app/models/tour-booking.model';
import { OrderTourBookingItemService } from './services/order-tour-booking-item.service';

@Component({
	selector: 'app-order-tour-booking-item',
	templateUrl: './order-tour-booking-item.component.html',
	styleUrls: ['./order-tour-booking-item.component.scss'],
	providers: [
		OrderTourBookingItemService,
	],
})
export class OrderTourBookingItemComponent implements OnInit {
	@Input() isOrder = false;

	@Input() isCustomer = false;

	@Input() set tourBooking(tourBooking: TourBooking) {
		this.orderTourBookingItemService.setTourtBooking(tourBooking);
	}

	constructor(public orderTourBookingItemService: OrderTourBookingItemService) { }

	ngOnInit() {
	}
}
