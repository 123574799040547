<app-blade-item [canIClose]="true" [loading]="loading" [id]="bladeItem?.id">
	<app-blade-header [title]="'Produktlista'" [subTitle]="'Element'" [menuItems]="headerMenuItems"></app-blade-header>
	<app-blade-content [menuItems]="menuItems" (indexChange)="selectedIndex = $event;">
		<app-element-blade-generally class="mb-3 d-block" *ngIf="selectedIndex === 0 && element" [element]="element" [saving]="saving" [menuItems]="menuItems"></app-element-blade-generally>
		<mat-card>
			<div *ngIf="productListElement && selectedIndex === 0">
				<div *ngIf="productListElement">
					<form [formGroup]="itemForm">
						<mat-form-field class="w-100">
							<mat-label>Välj kategori</mat-label>
							<mat-select formControlName="category">
								<mat-option [value]="0">Alla</mat-option>
								<mat-option *ngFor="let category of (categoryStore.items$|async)" [value]="category.id">
									{{category.id}} {{category.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</form>
				</div>
			</div>
			<app-element-blade-styles *ngIf="productListElement && selectedIndex === 99" [saving]="saving"></app-element-blade-styles>
		</mat-card>
	</app-blade-content>
	<app-blade-footer>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="save()">
			<span *ngIf="productListElement?.id">Spara</span>
			<span *ngIf="!productListElement?.id">Skapa</span>
		</button>
	</app-blade-footer>
</app-blade-item>
