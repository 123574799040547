import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { HeaderMenuItem } from 'app/admin/components/blade/models/headerMenuItem.model';
import { Subject } from 'rxjs';
import { CategoryBladeService } from './services/category-blade.service';

@Component({
	selector: 'app-category-blade',
	templateUrl: './category-blade.component.html',
	styleUrls: ['./category-blade.component.scss'],
	providers: [CategoryBladeService],
})
@IBladeComponent.register
export class CategoryBladeComponent implements OnInit {
	public saveProductSubject = new Subject<void>();
	public saveCategorySubject = new Subject<void>();
	public canIClose: () => {} | boolean;
	public headerMenuItems: HeaderMenuItem[] = [
		{
			title: 'Ta bort',
			icon: 'delete_forever',
			func: () => {
				this.bladeItem.bladeItemComponent
					.showModal({
						headline: 'Är du säker?',
						content:
							'Är du säker på att du vill ta bort "' +
							this.bladeItem.title +
							'"?',
						primaryButtonTitle: 'Nej',
						primaryButtonValue: false,
						secondaryButtonTitle: 'Ja',
						secondaryButtonValue: true,
					})
					.then(doDelete => {
						if (doDelete) {
							this.categoryBladeService.deleteItem();
						}
					});
			},
		},
	];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public categoryBladeService: CategoryBladeService,
	) {	}

	ngOnInit() {}


}
