import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable()
export class LoadingService {
	public loadingText$ = new ReplaySubject<string>();
	public show$ = new ReplaySubject<boolean>();

	constructor() { }

	public show(loadingText: string): void {
		this.loadingText$.next(loadingText);
		this.show$.next(true);
	}

	public hide(): void {
		this.loadingText$.next('');
		this.show$.next(false);
	}
}
