import { Component, OnInit, Inject } from '@angular/core';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { ProductStore } from 'app/admin/stores/product/product.store.service';
import { Product } from 'app/models/product/product.model';

@Component({
	selector: 'app-select-product-blade',
	templateUrl: './select-product-blade.component.html',
	styleUrls: ['./select-product-blade.component.scss'],
})
@IBladeComponent.register
export class SelectProductBladeComponent implements OnInit {
	public searchWord: string;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public productStore: ProductStore,
	) {
		this.productStore.getAll();
	}

	ngOnInit() {
	}

	public select(product: Product) {
		this.bladeItem.parent.callback(product, 'product');
		this.bladeItem.bladeItemComponent.closeMe();
	}

	public selectNone() {
		this.bladeItem.parent.callback({id: 0, name: 'Ingen vald'}, 'product');
		this.bladeItem.bladeItemComponent.closeMe();
	}
}
