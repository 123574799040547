import { Component, OnInit, Inject, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { SnackBarService } from 'app/admin/services/snack-bar.service';
import { EtageClass } from 'app/models/etage-class.model';
import { EtageClassStore } from 'app/admin/stores/etage-class.store.service';
import { EtageStyle } from 'app/models/etage-style.model';
import { EtageStyleStore } from 'app/admin/stores/etage-style.store.service';

@UntilDestroy()
@Component({
	selector: 'app-etage-blade-styles',
	templateUrl: './etage-blade-styles.component.html',
	styleUrls: ['./etage-blade-styles.component.scss'],
})
export class EtageBladeStylesComponent implements OnInit, OnDestroy {
	public editorOptions = {theme: 'vs', language: 'json'};

	public code = '';
	private etageStyle: EtageStyle;

	private pristineEtageClasses: EtageClass[];
	private etageClasses: EtageClass[];

	public className: string;
	public displayedColumns: string[] = ['id', 'name', 'actions'];
	public dataSource: MatTableDataSource<EtageClass>;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public etageClassStore: EtageClassStore,
		public etageStyleStore: EtageStyleStore,
		private snackBarService: SnackBarService,
	) {
		this.etageClassStore.getAllOnEtage(this.bladeItem.id).subscribe();
		this.etageClassStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
			this.pristineEtageClasses = _.cloneDeep(items);
			this.etageClasses = items;
			this.addToDataSource();
		});

		this.etageStyleStore.getOnEtage(this.bladeItem.id).pipe(untilDestroyed(this)).subscribe(etageStyle => {
			this.etageStyle = etageStyle;
			if (!this.etageStyle) {
				this.etageStyle = new EtageStyle();
				this.etageStyle.etageId = this.bladeItem.id;
			}
			this.code = etageStyle.style;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {}

	public saveStyle() {
		this.etageStyle.style = this.code;
		if (this.etageStyle.id) {
			this.etageStyleStore.update(this.etageStyle).subscribe(etageStyle => {
				this.etageStyle = etageStyle;
			});
		} else {
			this.etageStyleStore.create(this.etageStyle).subscribe(etageStyle => {
				this.etageStyle = etageStyle;
			});
		}
	}

	public addClass(): void {
		if (this.className) {
			const etageClass = new EtageClass();
			etageClass.class = this.className;
			etageClass.etageId = this.bladeItem.id;
			if (!this.etageClasses) {
				this.etageClasses = [];
			}
			this.etageClasses.push(etageClass);
			this.className = '';
			this.addToDataSource();
			this.doSave();
		}
	}

	public deleteClass(etageClass: EtageClass) {
		_.remove(this.etageClasses, etageClass);
		this.addToDataSource();
		this.doSave();
	}

	private addToDataSource() {
		this.dataSource = new MatTableDataSource<EtageClass>(this.etageClasses);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	private doSave() {
		const add: EtageClass[] = _.differenceWith(this.etageClasses, this.pristineEtageClasses, (a, b) => a.id === b.id);

		const removed = _.differenceWith(this.pristineEtageClasses, this.etageClasses, (a, b) => a.id === b.id);

		if (add || removed) {
			this.etageClassStore.addDelete(this.bladeItem.id, add, _.map(removed, 'id')).subscribe(() => {
				this.etageClassStore.getAllOnEtage(this.bladeItem.id).subscribe();
				this.snackBarService.showSuccess('Kopplingarna har sparats!');
			});
		}
	}
}
