import { Component, OnInit, ViewChild, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Tour } from 'app/models/tour.model';
import { TourBladeComponent } from '../tour-blade/tour-blade.component';
import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken, BladeService } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladeSize } from 'app/admin/components/blade/models/bladeSize.model';
import { TourStore } from 'app/admin/stores/tour.store.service';
import { TourStatusStore } from 'app/admin/stores/tour-status.store.service';
import { TourStatus } from 'app/models/tour-status.model';
import { combineLatest, Observable } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-tours-blade',
	templateUrl: './tours-blade.component.html',
	styleUrls: ['./tours-blade.component.scss'],
})
@IBladeComponent.register
export class ToursBladeComponent implements OnInit, OnDestroy {
	private defaultSelectedTourStatus = ['new', 'handled'];
	public initSearch = false;
	public selectedTourStatus: TourStatus[];
	public _ = _;
	public moment = moment;
	public loading = false;
	// tslint:disable-next-line: max-line-length
	public displayedColumns: string[] = ['id', 'productId', 'tourGuides', 'supplierId', 'date', 'quantity', 'tourStatusId', 'isActive', 'isPrivate'];
	public dataSource: MatTableDataSource<Tour>;
	public dateList = [];

	public formGroup: FormGroup = new FormGroup({
		start: new FormControl(moment()),
		end: new FormControl(moment().add(10, 'days'), []),
	});
	public idFormGroup: FormGroup = new FormGroup({
		id: new FormControl(null),
	});

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	private allTours: Tour[];

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladeService,
		public itemStore: TourStore,
		public tourStatusStore: TourStatusStore,
		private cdr: ChangeDetectorRef,
	) {
		this.search();
	}

	ngOnInit() { }
	ngOnDestroy() { }

	public search(): void {
		if (!this.formGroup.value.start) {
			return;
		}

		this.initSearch = true;
		this.getTours(this.formGroup.value.start.format('YYYY-MM-DD'), this.formGroup.value.end ? this.formGroup.value.end?.format('YYYY-MM-DD') : null);
	}

	public getById(): void {
		if (!this.idFormGroup.value.id) {
			return;
		}

		this.initSearch = true;
		this.itemStore.get(this.idFormGroup.value.id).pipe(untilDestroyed(this)).subscribe(tour => {
			this.idFormGroup.get('id').setValue('');
			this.dataSource = new MatTableDataSource<Tour>(_.orderBy([tour], 'datum', 'asc'));
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.loading = false;
		});
	}

	public selectItem(row, menuIndex: number = 3) {
		this.bladeService.addBladeItem({
			id: row.id,
			component: TourBladeComponent,
			minClass: BladeSize.m,
			maxClass: BladeSize.l,
			menuIndex,
		}, this.bladeItem);
	}

	public createNew() {
		this.bladeService.addBladeItem({
			id: 0,
			component: TourBladeComponent,
			minClass: BladeSize.xs,
		}, this.bladeItem);
	}

	public getTours(start: string, end: string) {
		this.loading = true;
		combineLatest([
			this.doGetToursFromDates(start, end),
			this.tourStatusStore.items$,
		]).pipe(untilDestroyed(this)).subscribe(([tours, tourStatus]) => {
			_.each(tours, item => {
				item.date = new Date(item.date);
			});
			if (!this.selectedTourStatus) {
				this.selectedTourStatus = _.filter(tourStatus, status => this.defaultSelectedTourStatus.includes(status.identifier));
			}
			this.allTours = tours;
			this.setData();
		});
	}

	public changeTourStatusFilter(event: TourStatus) {
		this.selectedTourStatus = _.xor(this.selectedTourStatus, [event]);
		this.setData();
	}

	private doGetToursFromDates(start: string, end: string): Observable<Tour[]> {
		if (start && end) {
			return this.itemStore.getAllBetweenDates(start, end);
		}
		return this.itemStore.getAllFromDate(start);
	}

	private setData() {
		this.idFormGroup.get('id').setValue('');
		const tours = _.filter(this.allTours, tour => !!_.find(this.selectedTourStatus, status => status.id === tour.tourStatusId));
		this.dataSource = new MatTableDataSource<Tour>(_.orderBy(tours, ['date', 'time'], ['asc', 'asc']));
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.loading = false;
		this.cdr.markForCheck();
	}
}
