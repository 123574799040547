import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { Column } from 'app/models/site/column.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-column-blade-generally',
  templateUrl: './column-blade-generally.component.html',
  styleUrls: ['./column-blade-generally.component.scss'],
})
export class ColumnBladeGenerallyComponent implements OnInit {

	@Input() item: Column;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
	});

	constructor(@Inject(BladeItemInjectToken) public bladeItem: BladeItem, private bladeService: BladePositionService) { }

  	ngOnInit() {
		this.itemForm.get('name').setValue(this.item.name);
		this.itemForm.get('name').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.name = value;
		});
  	}
}
