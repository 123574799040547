import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class UtilsService {

	private throttles: { [key: string]: any } = {};

	constructor() { }

	public debounceThrottle(key: string, callback: Function, delay?: number) {
		if (this.throttles[key]) {
			clearTimeout(this.throttles[key]);
		}
		this.throttles[key] = setTimeout(() => {
			callback();
		}, delay || 500);
	}

	public uuidv4() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		  const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		  return v.toString(16);
		});
	  }
}
