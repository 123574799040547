import { BaseModel } from './base.model';
import { PickupLocationPrice } from './page-location-price.model';
import { PickupLocationText } from './page-location-text.model';
import { ProductPickupLocation } from './product/product-pickup-location.model';

export class PickupLocation extends BaseModel {
	public name: string;
	public latitude: string;
	public longitude: string;
	public relativeTime: number;
	public pickupLocationPrices?: PickupLocationPrice[];
	public pickupLocationTexts?: PickupLocationText[];
	public productPickupLocations?: ProductPickupLocation[];
}
