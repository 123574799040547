import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';

import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';

import { BladeSystemComponent } from './blade-system/blade-system.component';
import { BladeItemComponent } from './blade-item/blade-item.component';
import { BladeHeaderComponent } from './blade-item/components/blade-header/blade-header.component';
import { BladeContentComponent } from './blade-item/components/blade-content/blade-content.component';
import { BladeFooterComponent } from './blade-item/components/blade-footer/blade-footer.component';
import { BladeComponentComponent } from './blade-system/components/blade-component/blade-component.component';

import { BladeModalComponent } from './blade-item/components/blade-modal/blade-modal.component';
import { BladeNavigationComponent } from './blade-system/components/blade-navigation/blade-navigation.component';
import { BladePageComponent } from './blade-system/components/blade-page-component/blade-page.component';

import { BladeContentTabComponent } from './blade-item/components/blade-content/components/blade-content-tabs/components/blade-content-tab/blade-content-tab.component';
import { BladeContentTabsComponent } from './blade-item/components/blade-content/components/blade-content-tabs/blade-content-tabs.component';
import { PipeModule } from 'app/admin/pipes/pipe.module';

const materialModules = [
	MatToolbarModule,
	PortalModule,
	MatCardModule,
	MatButtonModule,
	MatGridListModule,
	MatMenuModule,
	MatIconModule,
	MatSidenavModule,
	MatListModule,
	MatDialogModule,
	MatChipsModule,
	MatBottomSheetModule,
];

const modals = [
	BladeModalComponent,
	BladeNavigationComponent,
];

const bladeComponents = [
	BladeSystemComponent,
	BladeItemComponent,
	BladeHeaderComponent,
	BladeContentComponent,
	BladeFooterComponent,
	BladeComponentComponent,
	BladePageComponent,

	BladeContentTabsComponent,
	BladeContentTabComponent,
];

@NgModule({
	entryComponents: [
		...modals,
	],
	declarations: [
		...bladeComponents,
		...modals,
	],
	imports: [
		CommonModule,
		NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.circleSwish,
		}),
		...materialModules,
		PipeModule,
	],
	exports: [
		...bladeComponents,
	],
})
export class BladeModule { }
