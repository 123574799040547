import { Component, OnInit, Inject } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { TermBladeService } from '../../service/term-blade.service';
import { TermTypeStore } from 'app/admin/stores/term-type.store.service';

@Component({
  selector: 'app-term-blade-generally',
  templateUrl: './term-blade-generally.component.html',
  styleUrls: ['./term-blade-generally.component.scss'],
})
export class TermBladeGenerallyComponent implements OnInit {

  constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private bladeService: BladePositionService,
		public termBladeService: TermBladeService,
		public termTypeStore: TermTypeStore,
	) {
		this.termTypeStore.getAll();
	}

	ngOnInit() { }
}
