import { Component, OnInit, Inject, Input } from '@angular/core';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { BladePositionService } from 'app/admin/components/blade/blade-position.service';
import { Supplier } from 'app/models/supplier.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-supplier-blade-generally',
	templateUrl: './supplier-blade-generally.component.html',
	styleUrls: ['./supplier-blade-generally.component.scss'],
})
export class SupplierBladeGenerallyComponent implements OnInit {

	@Input() item: Supplier;

	public itemForm: FormGroup = new FormGroup({
		name: new FormControl('', [Validators.required]),
		phone: new FormControl(''),
		email: new FormControl(''),
		homepage: new FormControl(''),
		description: new FormControl(''),
	});

	constructor(@Inject(BladeItemInjectToken) public bladeItem: BladeItem, private bladeService: BladePositionService) { }

	ngOnInit() {
		this.itemForm.patchValue(this.item);
		this.itemForm.get('name').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.name = value;
		});
		this.itemForm.get('phone').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.phone = value;
		});
		this.itemForm.get('email').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.email = value;
		});
		this.itemForm.get('homepage').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.homepage = value;
		});
		this.itemForm.get('description').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.description = value;
		});
	}
}
