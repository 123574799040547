import { Component } from '@angular/core';

@Component({
	selector: 'app-admin-report-page',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class ReportPageComponent {

	constructor() {	}

}
