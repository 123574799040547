import { Injectable, Inject, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import { Tour } from 'app/models/tour.model';
import { TourStore } from 'app/admin/stores/tour.store.service';

@UntilDestroy()
@Injectable()
export class GuideTourService implements OnDestroy {
	private tourBehavior = new ReplaySubject<Tour>(1);
	public tourBehavior$ = this.tourBehavior.asObservable();

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		public tourStore: TourStore,
	) {
		if (bladeItem.id) {
			this.tourStore.get(bladeItem.id).pipe(untilDestroyed(this)).subscribe(tour => {
				this.tourBehavior.next(tour);
			});
		}
	}

	ngOnDestroy(): void { }
}
