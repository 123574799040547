<mat-card>
	<mat-card-title>Upphämtningsplatser</mat-card-title>
	<mat-card-subtitle>Alla kopplade upphämtningsplatser kommer visas för kunden</mat-card-subtitle>

	<div class="mat-elevation-z1">
		<table mat-table [dataSource]="dataSource" matSort class="w-100"
			cdkDropList
			(cdkDropListDropped)="onListDrop($event)"
			[cdkDropListData]="dataSource">

			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Vald</th>
				<td mat-cell *matCellDef="let item">
					<mat-checkbox class="mr-3 d-block"
						[checked]="!!item.productPickupLocation"
						(change)="toggleProductPickupLocation(item)">
					</mat-checkbox>
				</td>
			</ng-container>
			<ng-container matColumnDef="pickupLocation">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Upphämtningsplats</th>
				<td mat-cell *matCellDef="let item">{{item.pickupLocation.id | pickupLocationName | async}}</td>
			</ng-container>
			<ng-container matColumnDef="relativeTime">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Relativ tid</th>
				<td mat-cell *matCellDef="let item">
					<div class="align-items-center d-flex justify-content-between">
						<span>
							<del class="mr-2" *ngIf="item.productPickupLocation && (item.productPickupLocation?.relativeTime !== '' && item.productPickupLocation?.relativeTime !== undefined && item.productPickupLocation?.relativeTime !== null)">
								{{item.pickupLocation.relativeTime}}
							</del>
							{{
								item.productPickupLocation && (item.productPickupLocation?.relativeTime !== '' && item.productPickupLocation?.relativeTime !== undefined && item.productPickupLocation?.relativeTime !== null)
								? item.productPickupLocation?.relativeTime
								: item.pickupLocation.relativeTime
							}}
						</span>
					</div>
				</td>
			</ng-container>
			<ng-container matColumnDef="pickupLocationPrice">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Priser</th>
				<td mat-cell *matCellDef="let item">
					<app-product-pickup-location-price
						*ngFor="let customerType of customerTypeStore.items$ | async"
						class="mr-1 d-inline-block"
						[pickupLocationPrices]="item.pickupLocation?.pickupLocationPrices"
						[productPickupLocationPrices]="item.productPickupLocation?.productPickupLocationPrices"
						[customerType]="customerType">
					</app-product-pickup-location-price>
				</td>
			</ng-container>
			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
				<td mat-cell *matCellDef="let item">
					<button mat-button color="accent" *ngIf="item?.productPickupLocation" (click)="selectItem(item?.productPickupLocation)">Ändra</button>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row cdkDrag [cdkDragData]="item" [cdkDragDisabled]="(savingPrioReplay$ | async) || !item?.productPickupLocation" *matRowDef="let item; columns: displayedColumns;" [class.selected]="item?.productPickupLocation && bladeItem?.child?.id === item?.productPickupLocation?.id"></tr>		</table>

		<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
	</div>
	<div class="mt-2 text-right">
		<button mat-raised-button color="accent" *ngIf="prioIsChangedReplay$ | async" (click)="savePrio()" [disabled]="savingPrioReplay$ | async">Spara sorteringen</button>
	</div>
</mat-card>

