<mat-card>
	<mat-card-title>Generellt</mat-card-title>
	<form [formGroup]="addonBladeService.itemForm">
		<mat-form-field class="w-100">
			<input matInput formControlName="name" placeholder="Namn">
		</mat-form-field>
		<mat-form-field class="w-100">
			<input matInput formControlName="price" placeholder="Pris">
		</mat-form-field>
		<section>
			<mat-slide-toggle
				color="accent"
				formControlName="isActive">
				Aktiv
			</mat-slide-toggle>
		</section>
	</form>
</mat-card>

<div class="mt-3 text-right">
	<button mat-stroked-button color="accent" (click)="addonBladeService.save()">
		Spara
	</button>
</div>
