import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-suppliers',
	templateUrl: './suppliers.component.html',
	styleUrls: ['./suppliers.component.scss'],
})
export class SuppliersPageComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
