import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

import { IBladeComponent } from 'app/admin/components/blade/interfaces/iBladeComponent.interface';
import { BladeItemInjectToken } from 'app/admin/components/blade/blade.service';
import { BladeItem } from 'app/admin/components/blade/models/bladeItem.model';
import {
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';
import { ProductPrice } from 'app/models/product/product-price.model';
import { ProductPriceStore } from 'app/admin/stores/product/product-price.store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-product-price-blade',
	templateUrl: './product-price-blade.component.html',
	styleUrls: ['./product-price-blade.component.scss'],
})
@IBladeComponent.register
export class ProductPriceBladeComponent implements OnInit {
	public loading = true;
	public item: ProductPrice;

	public itemForm: FormGroup = new FormGroup({
		price: new FormControl('', [Validators.required]),
	});

	constructor(
		@Inject(BladeItemInjectToken) public bladeItem: BladeItem,
		private itemStore: ProductPriceStore,
	) {
		if (
			bladeItem.payload &&
			bladeItem.payload.productId &&
			bladeItem.payload.customerTypeId
		) {
			itemStore
				.getOnProductAndCustomerType(
					+bladeItem.payload.productId,
					+bladeItem.payload.customerTypeId,
				)
				.subscribe(item => {
					this.item = item;
					if (!this.item) {
						this.item = new ProductPrice();
						this.item.productId = +bladeItem.payload.productId;
						this.item.customerTypeId = +bladeItem.payload.customerTypeId;
					}
					this.itemForm.setValue({
						price: this.item.price || 0,
					});

					this.loading = false;
				});
		} else {
			console.error('Missing productId and/or customerTypeId');
		}
	}

	ngOnInit() {
		this.itemForm.get('price').valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
			this.item.price = value;
		});
	}

	public save() {
		this.loading = true;
		this.saveOrUpdate().subscribe(item => {
			this.item = item;
			this.loading = false;
			this.itemForm.markAsPristine();
		});
	}

	public remove() {
		this.loading = true;
		this.itemStore.delete(this.item.id).subscribe(isDeleted => {
			if (isDeleted) {
				this.bladeItem.bladeItemComponent.closeMe();
			}
		});
	}

	private saveOrUpdate(): Observable<ProductPrice> {
		if (this.item.id > 0) {
			return this.itemStore.update(this.item);
		} else {
			return this.itemStore.create(this.item);
		}
	}

}
