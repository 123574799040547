
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Column } from 'app/models/site/column.model';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SnackBarService } from '../services/snack-bar.service';
import { PrioBaseStore } from './prio-base.store.service';

@Injectable({
	providedIn: 'root',
})
export class ColumnStore extends PrioBaseStore<Column> {

	constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string, protected snackBarService: SnackBarService) {
		super(http, 'Columns', baseUrl, snackBarService);
	}

	public getAllOnEtage(etageId: number): Observable<Column[]> {
		return this.http.get<Column[]>(`${this.host}/etage/${etageId}`).pipe(tap(columns => {
			this._items = columns;
			this._itemsSubject.next(this._items);
		}));
	}

}
