<mat-card>
	<mat-card-title class="card-title-small">Tillägg</mat-card-title>

	<mat-table [dataSource]="selectProductToOrderBladeService.addonOrdersReplay$" class="w-100">
		<ng-container matColumnDef="type">
			<mat-header-cell *matHeaderCellDef>Kund-typ</mat-header-cell>
			<mat-cell *matCellDef="let item" [class.text-line-through]="item.avaiableQuantity === 0">{{item.addonId | addonName | async}} </mat-cell>
			<mat-footer-cell *matFooterCellDef color="accent">
				Lägg till tillägg
			</mat-footer-cell>
		</ng-container>
		<ng-container matColumnDef="quantity">
			<mat-header-cell *matHeaderCellDef>Antal</mat-header-cell>
			<mat-cell *matCellDef="let item">
				<mat-form-field class="w-100 pr-2">
					<input type="number" min="0" matInput [(ngModel)]="item.quantity" [disabled]="item.avaiableQuantity === 0">
				</mat-form-field>
			</mat-cell>
			<mat-footer-cell *matFooterCellDef>
				<mat-form-field class="w-100">
					<mat-select [(ngModel)]="selectedAddon">
						<mat-option *ngFor="let addon of addonStore.items$ | async" [value]="addon" [disabled]="isAddonSelected(addon.id)">
							{{addon.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</mat-footer-cell>
		</ng-container>
		<ng-container matColumnDef="unitPrice">
			<mat-header-cell *matHeaderCellDef>á pris</mat-header-cell>
			<mat-cell *matCellDef="let item">
				<mat-form-field class="w-100">
					<input type="number" min="0" matInput [(ngModel)]="item.unitPrice" [disabled]="item.avaiableQuantity === 0 || userService.isTravelAgencyUser()">
				</mat-form-field>
			</mat-cell>
			<mat-footer-cell *matFooterCellDef class="justify-content-end">
				<button mat-stroked-button color="accent" [disabled]="!selectedAddon" (click)="addAddon(selectedAddon, 0)">Lägg till</button>
			</mat-footer-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let item; columns: displayedColumns;"></mat-row>
		<mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
	</mat-table>
</mat-card>

