import { Component, Input, OnInit } from '@angular/core';
import { CustomerProgramTourService } from './services/customer-program-tour.service';
import * as moment from 'moment';

@Component({
	selector: 'app-customer-program-tour',
	templateUrl: './customer-program-tour.component.html',
	styleUrls: ['./customer-program-tour.component.scss'],
	providers: [CustomerProgramTourService],
})
export class CustomerProgramTourComponent implements OnInit {
	public moment = moment;
	@Input() set tourId(tourId: number) {
		this.customerProgramTourService.setTourId(tourId);
	}

	constructor(public customerProgramTourService: CustomerProgramTourService) { }

	ngOnInit() {
	}
}
