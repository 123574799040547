import { NgModule } from '@angular/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';

import { CKEditorComponent } from './ckEditor.component';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [
		CKEditorComponent,
	],
	imports: [
		CommonModule,
		CKEditorModule,
		FormsModule,
	],
	exports: [
		CKEditorModule,
		CKEditorComponent,
	],
})
export class TextEditorModule { }
